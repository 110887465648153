import { useQuery } from "react-query";
import { getDepartment, getActivity } from "../../../api/timesheetActivity";

const useDepartment = (
  QueryParam,
  keyword = "",
  limit = 10,
  offset = 1,
  toggleFetch
) => {
  return useQuery(
    ["get-departments", keyword, limit, offset, toggleFetch],
    () => getDepartment(QueryParam)
  );
};

const useActivity = (
  QueryParam,
  keyword = "",
  status = 1,
  filter,
  limit = 10,
  offset = 1,
  fetch
) => {
  return useQuery(
    ["get-activity", keyword, status, filter, limit, offset, fetch],
    () => getActivity(QueryParam)
  );
};

const queries = {
  useDepartment,
  useActivity,
};

export default queries;
