import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  activeInactive,
  addHoliday,
  getHolidays,
  reflectHoliday,
  removeHoliday,
  undoReflectHoliday,
  updateHoliday,
} from "../../../../api/settings/time";

export const useGetHoliday = (per_page, year, search, page, status) => {
  return useQuery(["get-holiday", per_page, year, search, page, status], () =>
    getHolidays(per_page, year, search, page, status)
  );
};

export const useAddHoliday = () => {
  const queryClient = useQueryClient();
  return useMutation(addHoliday, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["get-holiday"]);
      return res;
    },
  });
};

export const useReflect = (params, name) => {
  const queryClient = useQueryClient();
  return useMutation(() => reflectHoliday(params, name), {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["get-holiday"]);
      return res;
    },
  });
};

export const useUndoReflect = (params, name) => {
  const queryClient = useQueryClient();
  return useMutation(() => undoReflectHoliday(params, name), {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["get-holiday"]);
      return res;
    },
  });
};

export const useActiveInActive = () => {
  const queryClient = useQueryClient();
  return useMutation(activeInactive, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["get-holiday"]);
      return res;
    },
  });
};

export const useRemove = () => {
  const queryClient = useQueryClient();
  return useMutation(removeHoliday, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["get-holiday"]);
      return res;
    },
  });
};

export const useUpdateHoliday = () => {
  const queryClient = useQueryClient();
  return useMutation(updateHoliday, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["get-holiday"]);
      return res;
    },
  });
};
