import apiService from "../axios";
import format from "date-fns/format";

import {
  downloadFile,
  formatDateForQuery,
} from "../../helper/Reports/PerActivity/";
import { EXPORT_TYPE } from "../../lib/reports/perActivity";

export const getActivityReport = async (
  pageSize = 10,
  page = 1,
  fromDate = Date,
  toDate = Date,
  weekend = true,
  inActive = true,
  search = ""
) => {
  try {
    const from = formatDateForQuery(fromDate);
    const to = formatDateForQuery(toDate);

    const { data } = await apiService.get(
      `/v5/timesheet/activity-report?page_size=${pageSize}&page=${page}&fromDate=${from}&toDate=${to}&weekEnd=${weekend}&inActive=${inActive}&search=${search}`
    );

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getActivityReportPerUser = async (
  objectIds,
  pageSize,
  page,
  fromDate,
  toDate,
  weekend = true,
  inActive = true
) => {
  try {
    const { activityId } = objectIds;

    const from = formatDateForQuery(fromDate);
    const to = formatDateForQuery(toDate);

    const { data } = await apiService.get(
      `/v5/timesheet/activity-report/${activityId}/users?page_size=${pageSize}&page=${page}&fromDate=${from}&toDate=${to}&weekEnd=${weekend}&inActive=${inActive}`
    );

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getActivityReportPerProject = async (
  objectIds,
  pageSize,
  page,
  fromDate,
  toDate,
  weekend,
  inActive
) => {
  try {
    const { activityId, userId } = objectIds;

    const from = formatDateForQuery(fromDate);
    const to = formatDateForQuery(toDate);

    const { data } = await apiService.get(
      `/v5/timesheet/activity-report/${activityId}/users/${userId}/code?page_size=${pageSize}&page=${page}&fromDate=${from}&toDate=${to}&weekEnd=${weekend}&inActive=${inActive}`
    );

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getActivityReportPerTimesheet = async (
  objectIds,
  pageSize,
  page,
  fromDate,
  toDate,
  weekend,
  inActive
) => {
  try {
    const { activityId, userId, timesheetId } = objectIds;

    const from = formatDateForQuery(fromDate);
    const to = formatDateForQuery(toDate);

    const { data } = await apiService.get(
      `/v5/timesheet/activity-report/${activityId}/users/${userId}/code/${timesheetId}/timesheet?page_size=${pageSize}&page=${page}&fromDate=${from}&toDate=${to}&weekEnd=${weekend}&inActive=${inActive}`
    );
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getDownloadableActivityReport = async (payload) => {
  const { type, fromDate, toDate } = payload;
  // ! Double check for API endpoint ! /////////////
  try {
    const response = await apiService.post(
      "v1/timesheet/export/generate-excel-code-user-report",
      { ...payload, api: true },
      {
        "Content-Type": "application/json",
        body: JSON.stringify(payload),
        responseType: "blob",
      }
    );

    const formattedFromDate = format(new Date(fromDate), "MMM d, yyyy");
    const formattedToDate = format(new Date(toDate), "MMM d, yyyy");
    const fileExtension = EXPORT_TYPE[type].extension;
    const fileName = `${formattedFromDate} - ${formattedToDate} Breakdown Per Activity${fileExtension}`;

    downloadFile(response.data, fileName);

    return true;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
