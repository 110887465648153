import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import useDebounce from "../../../../hooks/customHooks/debounce";
import { TextField, InputAdornment, IconButton } from "@mui/material";
const SearchComponent = ({
  setSearch,
  setPage,
  isFetching,
  keyword,
  setKeyword,
  onPagination = (e) => {},
}) => {
  const debounceValue = useDebounce(keyword, 700);

  useEffect(() => {
    setSearch(keyword);
    setPage(1);
    onPagination();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue]);

  return (
    <>
      <TextField
        onChange={(e) => {
          setKeyword(e.target.value);
        }}
        size="small"
        id="input-with-sx"
        label="Search"
        variant="standard"
        value={keyword}
        sx={{ mb: 2, mt: 1 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                sx={{ p: 0.5 }}
                aria-label="toggle password visibility"
                onClick={(e) => setKeyword("")}
              >
                <CloseIcon sx={{ fontSize: 15 }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <SearchOutlinedIcon sx={{ color: "#00C292", mr: 1, mt: 2, mb: 0.5 }} />
    </>
  );
};

export default SearchComponent;
