import { useContext } from "react";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import StyledTableCell from "./StyledTableCell";
import LeaveConversionApplicationsContext from "../../../../globalstate/LeaveManagement/LeaveConversionApplications/LeaveConversionApplicationsContext";
import {
  ORDER,
  HEADER_CELL_ID,
  HEADER_CELLS,
} from "../../../../lib/leaveManagement/leaveConversionApplications";

const headerCells = Object.keys(HEADER_CELLS);

const TableHeader = () => {
  const {
    state: { order, orderBy },
    handler: { changeOrder, changeOrderBy },
  } = useContext(LeaveConversionApplicationsContext);

  const leftAligned = [HEADER_CELL_ID.name];

  const handleChangeOrder = (newOrderBy, isSortable) => {
    if (!isSortable) return;
    const isAsc = orderBy === newOrderBy && order === ORDER.ascending;
    changeOrder(isAsc ? ORDER.descending : ORDER.ascending);
    changeOrderBy(newOrderBy);
  };

  return (
    <TableHead>
      <TableRow>
        {headerCells.map((headerCellElement) => {
          const { label, isSortable } = HEADER_CELLS[headerCellElement];
          const align = leftAligned.includes(headerCellElement)
            ? "left"
            : "center";
          const sortDirection = orderBy === headerCellElement ? order : false;
          const direction =
            orderBy === headerCellElement ? order : ORDER.ascending;
          const isActiveTableSortLabel = orderBy === headerCellElement;

          return (
            <StyledTableCell
              key={headerCellElement}
              sortDirection={sortDirection}
              align={align}
            >
              <TableSortLabel
                sx={{ width: "max-content", fontWeight: 700 }}
                active={isActiveTableSortLabel}
                direction={direction}
                hideSortIcon
                onClick={handleChangeOrder.bind(
                  null,
                  headerCellElement,
                  isSortable
                )}
              >
                {label}
              </TableSortLabel>
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
