import { useContext, useEffect, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";

import LeaveConversionApplicationsContext from "../../../../globalstate/LeaveManagement/LeaveConversionApplications/LeaveConversionApplicationsContext";
import useDebounce from "../../../../hooks/customHooks/debounce";
import useWindowSize from "../../../../hooks/customHooks/useWindowSize";
import StyledTextField from "./StyledTextField";
import ClearFieldButton from "../button/ClearFieldButton";

const SearchBar = () => {
  const {
    handler: { changeSearchFilter },
  } = useContext(LeaveConversionApplicationsContext);

  const [searchField, setSearchField] = useState("");

  const searchFilter = useDebounce(searchField);

  const { width } = useWindowSize();

  useEffect(() => {
    changeSearchFilter(searchFilter);
  }, [searchFilter, changeSearchFilter]);

  const handleSearchFieldChange = (event) => {
    setSearchField(event.target.value);
  };

  const handleSearchFieldReset = () => {
    setSearchField("");
  };

  const maxWidth = width < 420 ? "100%" : "420px";

  return (
    <StyledTextField
      variant="outlined"
      id="search"
      placeholder="Search..."
      size="small"
      value={searchField}
      onChange={handleSearchFieldChange}
      autoComplete="off"
      sx={{ maxWidth }}
      inputProps={{
        style: {
          fontSize: "14px",
        },
      }}
      InputProps={{
        startAdornment: (
          <SearchIcon
            sx={{
              marginRight: "8px",
              color: "#495057",
            }}
          />
        ),
        endAdornment: (
          <ClearFieldButton
            show={searchField.length > 0}
            onClear={handleSearchFieldReset}
          />
        ),
      }}
    />
  );
};

export default SearchBar;
