import { useQuery, useMutation, useQueryClient } from "react-query";
import apiService from "../../../api/axios";
import { getProjectType } from "../../../api/projectType";
import { toast } from "react-toastify";

const useProjectType = (params) => {
  return useQuery(["get-project-type", params.criteria], () =>
    getProjectType(params.payload)
  );
};

const useAddProjectType = () => {
  const queryClient = new useQueryClient();
  return useMutation(
    "get-project-type",
    async (data) => {
      try {
        let response = await apiService.post(
          `/v1/settings/timesheet/types/add`,
          {
            Accept: `application/json`,
            "Content-Type": `multipart/form-data`,
            api: true,
            ...data,
          }
        );
        return response.data;
      } catch (error) {
        queryClient.refetchQueries(["get-project-type"]);
        throw toast.error("The name has already been taken.");
      }
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(["get-project-type"]);
        toast.success("New project type added.");
      },
    }
  );
};

const useDeleteProjectType = () => {
  const queryClient = new useQueryClient();
  return useMutation(
    "get-project-type",
    async (data) => {
      try {
        let response = await apiService.post(
          `v1/settings/timesheet/types/delete`,
          {
            Accept: `application/json`,
            "Content-Type": `multipart/form-data`,
            api: true,
            ...data,
          }
        );
        return response.data;
      } catch (error) {
        queryClient.refetchQueries(["get-project-type"]);
      }
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(["get-project-type"]);
        toast.success("Project type deleted.");
      },
    }
  );
};

const useEditTypes = () => {
  const queryClient = new useQueryClient();
  return useMutation(
    "get-project-type",
    async (data) => {
      try {
        let response = await apiService.post(
          `v1/settings/timesheet/types/update`,
          {
            Accept: `application/json`,
            "Content-Type": `multipart/form-data`,
            api: true,
            ...data,
          }
        );
        return response.data;
      } catch (error) {
        queryClient.refetchQueries(["get-project-type"]);
      }
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(["get-project-type"]);
        toast.success("Successfully updated project type.");
      },
    }
  );
};

const hooks = {
  useProjectType,
  useAddProjectType,
  useDeleteProjectType,
  useEditTypes,
};

export default hooks;
