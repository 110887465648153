import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CardContent from "@mui/material/CardContent";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import ReactionSelect from "./reactionComponent/reactionSelect";
import DateSelect from "./reactionComponent/dateSelect";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import TablePagination from "@mui/material/TablePagination";
import { BiLike, BiStar, BiHeart } from "react-icons/bi";
import hooks from "../../../hooks/react-query/queryHooks";
import { startOfWeek, endOfWeek, parseISO, format, addDays } from "date-fns";
import ReactionCsvArray from "./reactionHelper/reactionCsvArray";
import Progress from "./reactionComponent/progress";
import { Tooltip } from "@mui/material";
const tableHeader = [
  { name: "Name", align: "left" },
  { name: "Action", align: "center" },
  { name: "Target employee", align: "center" },
  { name: "Date", align: "center" },
  { name: "Remarks", align: "center" },
];
const customStyle = {
  filterCardHead: {
    borderRadius: "10px",
    p: "25px",
    m: "15px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    position: "sticky",
    top: "0",
    zIndex: "2",
    "@media(max-width: 768px)": {
      position: "relative",
    },
    display: "flex",
    justifyContent: "space-between",
  },
  filterCard: {
    borderRadius: "10px",
    p: "25px",
    m: "15px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    position: "sticky",
    top: "0",
    zIndex: "1",
    "@media(max-width: 768px)": {
      position: "relative",
    },
    display: "flex",
    justifyContent: "space-between",
  },
  boxDisplay: {
    display: "flex",
  },
  boxContent: {
    width: "100%",
  },
  boxHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    height: "35px",
    m: "5px",
    bgcolor: "#212A3C",
    fontSize: "0.75rem",
  },
  h6: {
    fontSize: "0.85rem",
    fontWeight: 700,
  },
  tableDate: {
    color: "rgb(142 138 138)",
  },
  reactionButton: {
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.87)",
    pointerEvents: "none",
  },
};
const generalSelectArr = [
  {
    value: "all",
    name: "All",
  },
  {
    value: "like",
    name: "Likes",
  },
  {
    value: "love",
    name: "Hearts",
  },
  {
    value: "commend",
    name: "Commends",
  },
];
const dateSelectArr = [
  {
    value: "this week",
    name: "This week",
  },
  {
    value: "this month",
    name: "This month",
  },
  {
    value: "this year",
    name: "This year",
  },
  {
    value: "last year",
    name: "Last year",
  },
];

const ReactionReport = () => {
  const keyRef = React.useRef("");
  const [from, setFrom] = React.useState(new Date());
  const [to, setTo] = React.useState(new Date());
  const [current, setCurrent] = React.useState(0);
  const [keyword, setKeyword] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filter, setFilter] = React.useState("all");
  const [date1, setDate1] = React.useState(
    startOfWeek(new Date(), { weekStartsOn: 1 })
  );
  const [date2, setDate2] = React.useState(endOfWeek(new Date()));
  const { data: tableData, isFetching } = hooks.useReactionReports(
    from,
    to,
    rowsPerPage,
    filter,
    current
  );
  const reactionType = (action) => {
    switch (action) {
      case "like":
        return <BiLike style={{ fontSize: "1rem", color: "#9DB4E9" }} />;
      case "love":
        return <BiHeart style={{ fontSize: "1rem", color: "#F4A6BC" }} />;
      case "commend":
        return <BiStar style={{ fontSize: "1rem", color: "#C4A0EC" }} />;
      default:
      // intentionally empty
    }
  };
  const csvData = tableData?.pages[0]?.export.map((td) => {
    return {
      employee: td?.employee?.full_name,
      action: td?.action,
      target: td?.target_employee?.full_name,
      date: format(parseISO(td?.time), "dd/MM/yyyy"),
      remark: td?.remarks,
    };
  });

  const filteredTableData = tableData?.pages[0]?.reaction.data.filter(
    (data) => {
      if (keyword == null) return data;
      else if (
        data.action
          .toLowerCase()
          .includes(keyRef.current.value.toLowerCase()) ||
        data.employee?.firstname
          .toLowerCase()
          .includes(keyRef.current.value.toLowerCase()) ||
        data.employee?.lastname
          .toLowerCase()
          .includes(keyRef.current.value.toLowerCase()) ||
        data.target_employee?.firstname
          .toLowerCase()
          .includes(keyRef.current.value.toLowerCase()) ||
        data.target_employee?.lastname
          .toLowerCase()
          .includes(keyRef.current.value.toLowerCase())
      ) {
        return data;
      }
    }
  );
  const dateSetter = (param1, param2) => {
    console.log(param2);
    setDate1(param1);
    setDate2(addDays(param1, 1));
  };
  return (
    <Box
      className="myDiv"
      style={{ padding: "5px 10px", width: "100%", height: "fit-content" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          cursor: "context-menu",
        }}
      >
        <Tooltip
          title={"View employee reacts report"}
          placement={"right"}
          arrow
        >
          <Typography variant="h4" component="div">
            Reacts Report
          </Typography>
        </Tooltip>
      </Box>
      <Card sx={customStyle.filterCardHead}>
        <Box sx={customStyle.boxDisplay}>
          <ReactionSelect
            itemSelect={generalSelectArr}
            title={"Type"}
            setFilter={setFilter}
          />
          <ReactionSelect
            itemSelect={dateSelectArr}
            title={"Range"}
            setFrom={setFrom}
            setTo={setTo}
            setDate1={setDate1}
            setDate2={setDate2}
          />
        </Box>
        <Box sx={customStyle.boxDisplay}>
          <DateSelect
            label="From"
            date={date1}
            setDate1={setDate1}
            startDate={date1}
            endDate={date2}
            dateSetter={dateSetter}
          />
          <DateSelect
            label="To"
            date={date2}
            setDate2={setDate2}
            startDate={date1}
            endDate={date2}
            dateSetter={dateSetter}
          />
          <Button
            variant="contained"
            size="small"
            sx={customStyle.button}
            onClick={() => {
              setFrom(date1);
              setTo(date2);
            }}
          >
            Filter
          </Button>
        </Box>
      </Card>
      <Card sx={customStyle.filterCard}>
        <Box sx={customStyle.boxContent}>
          {isFetching && <Progress />}
          <Box sx={customStyle.boxHeader}>
            <Box>
              {tableData?.pages[0]?.reaction.data.length > 0 && (
                <ReactionCsvArray
                  buttonStyl={customStyle.button}
                  tableData={csvData}
                />
              )}
            </Box>
            <Box>
              <TextField
                type="search"
                size="small"
                label="Search"
                placeholder="Search for actions"
                variant="outlined"
                sx={{ m: "5px" }}
                inputRef={keyRef}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setKeyword(null);
                  }
                }}
              />
              <Button
                variant="contained"
                size="small"
                sx={customStyle.button}
                startIcon={<SearchRoundedIcon />}
                onClick={() => {
                  setKeyword(keyRef.current.value);
                }}
              >
                Search
              </Button>
            </Box>
          </Box>
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  {tableHeader.map((header, index) => (
                    <TableCell align={header.align} key={index}>
                      <Typography variant="h6" sx={customStyle.h6}>
                        {header.name}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTableData?.length > 0 ? (
                  filteredTableData?.map((td, index) => (
                    <TableRow key={index}>
                      <TableCell>{td?.employee?.full_name}</TableCell>
                      <TableCell align="center">
                        <Button
                          sx={customStyle.reactionButton}
                          startIcon={reactionType(td?.action)}
                        >
                          {td?.action}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        {td?.target_employee?.full_name}
                      </TableCell>
                      <TableCell align="center" sx={customStyle.tableDate}>
                        {format(parseISO(td?.time), "MM/dd/yyyy")}
                      </TableCell>
                      <TableCell align="center">
                        {td?.remarks === null ? "N/A" : td?.remarks}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">
                      <Typography> No records to display</Typography>
                    </TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </CardContent>
          {tableData?.pages[0]?.reaction.data.length > 0 && (
            <TablePagination
              component="div"
              showFirstButton
              showLastButton
              count={
                tableData?.pages[0]?.reaction.total
                  ? tableData?.pages[0]?.reaction.total
                  : 10
              }
              page={current}
              rowsPerPage={rowsPerPage}
              onPageChange={(_, page) => {
                setCurrent(page);
              }}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setCurrent(0);
              }}
            />
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default ReactionReport;
