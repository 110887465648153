import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import useDebounce from "../../hooks/customHooks/debounce";

const SearchComponent = ({
  setSearched,
  setQuery,
  searched,
  isFetching,
  placeholder,
}) => {
  const debounceValue = useDebounce(searched, 800);

  useEffect(() => {
    setQuery(searched);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue]);

  return (
    <div>
      <TextField
        onChange={(e) => {
          if (isFetching) return;
          setSearched(e.target.value);
        }}
        id="outlined-search"
        size={"small"}
        sx={{ width: "200px", minWidth: "200px" }}
        label={placeholder}
        value={searched}
        InputProps={{
          endAdornment: (
            <InputAdornment sx={{ cursor: "pointer" }} position="end">
              {searched.length === 0 ? (
                <SearchRoundedIcon />
              ) : (
                <IconButton
                  sx={{ p: 0.5 }}
                  aria-label="toggle password visibility"
                  onClick={(e) => setSearched("")}
                >
                  <CloseIcon sx={{ fontSize: 15 }} />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default SearchComponent;
