import { createContext, useEffect, useState } from "react";
import useDebounce from "../../../hooks/customHooks/debounce";
import { useGetSkills } from "../../../hooks/react-query/SettingsSytem/Skills";

export const SystemSkillContext = createContext({});

const initSkillParam = {
  search: "",
  status: 1,
};
export const SystemSkillProvider = (props) => {
  const [skillsParams, setSkillsParams] = useState(initSkillParam);
  const delaySearch = useDebounce(skillsParams.search, 500);
  const { data: listOfskills, isFetching: fetchingSkills } = useGetSkills({
    ...skillsParams,
    search: delaySearch,
  });
  useEffect(() => {
    return () => {
      setSkillsParams(initSkillParam);
    };
  }, []);

  return (
    <SystemSkillContext.Provider
      value={{ listOfskills, fetchingSkills, setSkillsParams, initSkillParam }}
    >
      {props.children}
    </SystemSkillContext.Provider>
  );
};
