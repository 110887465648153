import React from "react";
import {
  TextField,
  Modal,
  Box,
  Typography,
  Grid,
  Button,
  CircularProgress,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: 800,
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const RemarksModal = ({
  open,
  handleClose,
  setRemarks,
  handleEdit,
  isLoading,
  remarks,
}) => {
  return (
    <Modal
      hideBackdrop
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h6" mb={3}>
          Project Code Budget
        </Typography>
        <Typography mb={4}>
          All negative values will be considered 0. Are you sure you want to
          update budget and balance for this project?{" "}
        </Typography>
        <TextField
          fullWidth
          variant="standard"
          label="Remarks"
          multiline
          rows={2}
          sx={{ marginBottom: "10px", minWidth: 260 }}
          inputProps={{ maxLength: 100 }}
          onChange={(e) =>
            setRemarks((oldState) => ({ ...oldState, remarks: e.target.value }))
          }
        />
        <Grid container sx={{ justifyContent: "end" }} spacing={3}>
          <Grid item>
            <Button
              className="adjust-w cancel-button"
              variant="contained"
              sx={{ padding: "1px 10px 1px 10px" }}
              onClick={handleClose}
            >
              {" "}
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              className="adjust-w timest-button"
              variant="contained"
              sx={{ padding: "1px 15px 1px 15px" }}
              onClick={handleEdit}
              disabled={isLoading || remarks === ""}
              endIcon={isLoading ? <CircularProgress size={20} /> : <></>}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default RemarksModal;
