import * as React from "react";
import Modal from "@mui/material/Modal";
import { Box, Button } from "@mui/material";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Grow from "@mui/material/Grow";
import CircularProgress from "@mui/material/CircularProgress";
import hooks from "../../../hooks/react-query/queryHooks";
import { useForm } from "react-hook-form";
import InputController from "../../InputController";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { feedbackSchema } from "../../../helper/Yup/feedbackSchema";

const concernsOptions = [
  { label: "Compensation and Benefits", value: "Compensation and Benefits" },
  { label: "Employee Engagement", value: "Employee Engagement" },
  { label: "Employee Relations", value: "Employee Relations" },
  { label: "Finance", value: "Finance" },
  { label: "HR/HRIS", value: "HR/HRIS" },
  { label: "Office Environment", value: "Office Environment" },
  { label: "Operations", value: "Operations" },
  { label: "Personal Concern", value: "Personal Concern" },
  { label: "Safety and Security", value: "Safety and Security" },
];

export default function AddFeedbackModal({ feedbackOpen, handleFeedback }) {
  const [status, setStatus] = React.useState("");
  const [formFeedback, setFormFeedback] = React.useState(true);
  const [isSending, setIsSending] = React.useState(false);

  const { mutate: addFeedbackMutation } = hooks.useAddSubmitFeedback();

  const timeRef = React.useRef(null);
  const onMutationTimer = () => {
    setTimeout(() => {
      if (timeRef.current === null) {
        setStatus("success");
        setIsSending(false);
      }
    }, 3000);
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(feedbackSchema),
  });

  const onSubmit = (data) => {
    setIsSending(true);
    setFormFeedback(false);
    onMutationTimer();
    const feedbackEntries = {
      subject: data.subject,
      category: data.category,
      content: data.content,
    };

    addFeedbackMutation(feedbackEntries, {
      onSuccess: (request) => {
        timeRef.current = request.status;
        setIsSending(false);
        setStatus(request.status);
        setValue("subject", "");
        setValue("category", "");
        setValue("content", "");
      },
      onError: (request) => {
        timeRef.current = request.response.data.status;
        setStatus(request.response.data.status);
      },
    });
  };

  return (
    <Modal open={feedbackOpen}>
      <Grow
        in={feedbackOpen}
        style={{ transformOrigin: "0 0 0" }}
        {...(feedbackOpen ? { timeout: 500 } : {})}
        unmountOnExit
      >
        <Card
          className="feedback-modal leave1-modal"
          sx={{
            minWidth: "350px",
            maxHeight: "calc(100vh - 75px)",
            overflowY: "scroll",
          }}
        >
          <CardHeader
            title={
              <Typography variant="h3" className="h3-bar">
                Feedback
              </Typography>
            }
            action={
              <IconButton onClick={handleFeedback}>
                <CloseOutlinedIcon sx={{ width: "15px", height: "15px" }} />
              </IconButton>
            }
          />
          <CardContent>
            {status && (
              <Box sx={{ textAlign: "center", margin: "0px 20px 10px 20px" }}>
                {status && (
                  <Chip
                    label={<Typography variant="overline">{status}</Typography>}
                    color={status === "duplicate" ? "error" : "success"}
                  />
                )}
                {status === "duplicate" ? (
                  <Typography sx={{ m: 1 }}>Duplicate application</Typography>
                ) : (
                  <Typography sx={{ m: 1 }}>
                    Successfully added feedback
                  </Typography>
                )}
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  {status && <Button onClick={handleFeedback}>Close</Button>}
                </Box>
              </Box>
            )}
            {isSending && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            )}
            {formFeedback && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent sx={{ margin: "10px" }}>
                  <Grid container rowSpacing={3} columnSpacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <InputController
                        type="text"
                        control={control}
                        label="Subject"
                        name="subject"
                        errors={errors}
                        rows={1}
                        rules={{ required: true }}
                        isDefaultErrorAlert={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <InputController
                        type="selectNoErrorMessage"
                        control={control}
                        label="Concern"
                        name="category"
                        errors={errors}
                        size="small"
                        options={concernsOptions}
                        rules={{
                          required: true,
                        }}
                        isDefaultErrorAlert={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <InputController
                        type="text"
                        control={control}
                        label="Message"
                        name="content"
                        errors={errors}
                        rows={7}
                        rules={{ required: true }}
                        isDefaultErrorAlert={true}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    className="adjust-w cancel-button"
                    disableElevation
                    variant="contained"
                    sx={{ marginLeft: "auto", minWidth: "100px" }}
                    onClick={handleFeedback}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    className="adjust-w timest-button"
                    disableElevation
                    variant="contained"
                    type="submit"
                    sx={{ minWidth: "100px", mr: "17px" }}
                  >
                    Submit
                  </Button>
                </CardActions>
              </form>
            )}
          </CardContent>
        </Card>
      </Grow>
    </Modal>
  );
}
