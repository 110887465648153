export const addHolidayVar = {
  holiday_name: "",
  holiday_type: "",
  holiday_start: new Date(),
  holiday_end: new Date(),
  holiday_length: "",
  includes: "",
  holiday_remarks: "",
};

export const HolidayTypeDropDown = [
  { id: "Regular" },
  { id: "Special" },
  { id: "Company Event" },
];

export const dayLengthDropDown = [{ id: "Whole Day" }, { id: "Half Day" }];

export const IncludeDropDown = [
  { id: "All Staffs" },
  { id: "Regular Staffs" },
  { id: "Insource Staffs" },
];

export const headerContent = [
  {
    name: "Name",
    minW: "",
    align: "left",
  },
  {
    name: "Type",
    minW: "",
    align: "center",
  },
  {
    name: "Includes",
    minW: "",
    align: "center",
  },
  {
    name: "Date",
    minW: "",
    align: "center",
  },
  {
    name: "Resume on",
    minW: "",
    align: "center",
  },
  {
    name: "Length",
    minW: "",
    align: "center",
  },
  {
    name: "Remarks",
    minW: "",
    align: "center",
  },
  {
    name: "Created",
    minW: "",
    align: "center",
  },
  {
    name: "Action",
    minW: "",
    align: "center",
  },
];

export const tableCol = [
  { field: "name", align: "left", minW: "" },
  { field: "type", align: "center", minW: "" },
  { field: "includes", align: "center", minW: "" },
  { field: "start", align: "center", minW: "" },
  { field: "end", align: "center", minW: "" },
  { field: "length", align: "center", minW: "" },
  { field: "remarks", align: "center", minW: "" },
  { field: "created_at", align: "center", minW: "" },
];

export const years = () => {
  const start = 2017;
  const now = new Date().getFullYear();
  const range = now - start + 1;

  let years = [];

  for (let i = 0; i < range; i++) {
    years.push({ id: new Date().getFullYear() - i });
  }
  return years;
};

export const addIdtoYears = (years) => {
  let mutateObject = [];
  years.forEach((years) => mutateObject.push({ id: years.year }));
  return mutateObject;
};
