import SearchComponent from "../../../component/TreeView/SearchComponent";
import hooks from "../../../hooks/react-query/PolicyQuery";
import { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ViewLogRows from "./Rows/ViewLogRows";
import Pagination from "../../../component/TreeView/table/Pagination";

const ViewLogTable = ({ header, queryParams, id }) => {
  const [keyword, setKeyword] = useState("");
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(1);
  const { data: viewLogs, isFetching: viewLogFetching } = hooks.useLogDocument(
    getFetchUrl(),
    keyword,
    rows,
    page,
    fetch
  );

  function getFetchUrl() {
    queryParams = queryParams ? `&${queryParams}` : "";
    return (
      "?search=" +
      keyword +
      "&id=" +
      id +
      "&page_size=" +
      rows +
      "&page=" +
      page +
      queryParams
    );
  }

  return (
    <>
      <SearchComponent
        setKeyword={setKeyword}
        setOffset={setPage}
        isFetching={viewLogFetching}
      />
      <TableContainer sx={{ mt: 1.5 }} component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {header &&
                header.map((column, index) => (
                  <TableCell key={index} component="th">
                    <b>{column?.label}</b>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>

          {viewLogFetching && (
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{ height: "90px" }}
                  align="center"
                  colSpan={"100%"}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {!viewLogFetching && viewLogs.total === 0 && (
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{ height: "90px" }}
                  align="center"
                  colSpan={"100%"}
                >
                  <Typography>No Records Found.</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {!viewLogFetching && viewLogs.total > 0 && (
            <TableBody>
              <ViewLogRows data={viewLogs.data} header={header} />
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <Pagination
              onPageChange={setPage}
              limit={rows}
              offset={page}
              total={viewLogs ? viewLogs.total : 0}
              onChange={setRows}
            />
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default ViewLogTable;
