import { memo } from "react";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import UserApplicationsHeader from "../../../component/UserApplicationMgmt/UserApplications/UserApplicationsHeader";
import DetailsTableRow from "../../../component/UserApplicationMgmt/UserApplications/tableComponents/DetailsTableRow";
import TableHeader from "../../../component/UserApplicationMgmt/UserApplications/tableComponents/TableHeader";
import UserApplicationError from "../../../component/UserApplicationMgmt/UserApplications/tableComponents/UserApplicationError";
import UserApplicationActionBar from "../../../component/UserApplicationMgmt/UserApplications/headerComponents/UserApplicationActionBar";
import TableRowSkeletonLoader from "../../../component/UserApplicationMgmt/UserApplications/skeletonComponents/TableRowSkeletonLoader";
import StyledTableCell from "../../../component/UserApplicationMgmt/UserApplications/tableComponents/StyledTableCell";
import {
  usePrefetchUserApplication,
  useUserApplication,
} from "../../../hooks/react-query/UserApplications";
import {
  ROWS_PER_PAGE_OPTIONS,
  ORDER,
} from "../../../lib/UserAppMgmt/UserApplications";
import {
  generateSkeletonLoader,
  getApplicationType,
} from "../../../helper/UserApplicationMgmt/UserApplications";

const CONTAINER_STYLES = {
  margin: "14px 18px 0",
  display: "flex",
  flexDirection: "column",
  gap: "18px",
};

const TABLE_WRAPPER_STYLES = {
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#FFF",
  borderRadius: "14px",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.05)",
  width: "100%",
  margin: "auto",
};

const TABLE_STYLES = {
  tableLayout: "auto",
  minWidth: "max-content",
  width: "95%",
  margin: "10px 30px",
};

const TABLE_PAGINATION_STYLES = {
  display: "flex",
  justifyContent: "end",
  minHeight: "53px",
  borderTop: "1px solid #dee2e6",
  borderBottom: "none",
};

const UserApplications = () => {
  const {
    data,
    isLoading,
    isError,
    isSuccess,
    searchString,
    page,
    pageSize,
    order,
    orderBy,
    applicationType,
    typeQueryObj,
    leaveType,
    underOvertimeType,
    leaveSelection,
    overtimeSelection,
    changeApplicationType,
    changeOrder,
    changeOrderBy,
    changePage,
    changePageSize,
    searchFilter,
    changeLeaveType,
    changeUnderOvertimeType,
  } = useUserApplication();

  usePrefetchUserApplication(
    data?.next_page_url,
    page,
    pageSize,
    searchString,
    typeQueryObj
  );

  const { isLeave } = getApplicationType(applicationType);

  const changeRowsPageHandler = (event) => {
    changePageSize(+event.target.value);
  };

  const changePageHandler = (_, page) => {
    changePage(page + 1);
  };

  const requestSortHandler = (newOrderBy) => {
    const isAsc = orderBy === newOrderBy && order === ORDER.ASCENDING;
    changeOrder(isAsc ? ORDER.DESCENDING : ORDER.ASCENDING);
    changeOrderBy(newOrderBy);
  };

  const selectionToUse = isLeave ? leaveSelection : overtimeSelection;

  return (
    <Box sx={CONTAINER_STYLES}>
      {/* TITLE & SEARCH FILTER */}
      <UserApplicationsHeader isError={isError} onSearch={searchFilter} />

      {/* ACTION BAR */}
      {!isError && (
        <UserApplicationActionBar
          data={data}
          onSearch={searchFilter}
          onChange={changeApplicationType}
          type={applicationType}
          leaveType={leaveType}
          underOvertimeType={underOvertimeType}
          changeLeaveType={changeLeaveType}
          changeUnderOvertimeType={changeUnderOvertimeType}
          selection={selectionToUse}
        />
      )}

      {/* ERROR COMPONENT */}
      {isError && <UserApplicationError />}

      {/* TABLE */}
      {(isLoading || isSuccess) && !isError ? (
        <Box sx={TABLE_WRAPPER_STYLES}>
          {/* TABLE PROPER */}
          <TableContainer>
            <Table stickyHeader sx={TABLE_STYLES}>
              {/* TABLE HEAD */}
              <TableHeader
                order={order}
                orderBy={orderBy}
                onChangeOrder={requestSortHandler}
                type={applicationType}
              />

              {/* TABLE BODY */}
              <TableBody>
                {!isLoading
                  ? data.data.map((application) => (
                      <DetailsTableRow
                        key={application.id}
                        details={application}
                        type={applicationType}
                      />
                    ))
                  : generateSkeletonLoader(
                      pageSize,
                      <TableRowSkeletonLoader type={applicationType} />
                    )}

                {!isLoading && !data?.data.length ? (
                  <TableRow>
                    <StyledTableCell colSpan="100%">
                      No results found.
                    </StyledTableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>

          {/* PAGINATION */}
          <TablePagination
            component={Box}
            sx={TABLE_PAGINATION_STYLES}
            showFirstButton={true}
            showLastButton={true}
            count={data?.total || 0}
            page={data?.current_page ? data?.current_page - 1 : 0}
            rowsPerPage={pageSize}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onPageChange={changePageHandler}
            onRowsPerPageChange={changeRowsPageHandler}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default memo(UserApplications);
