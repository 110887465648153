import apiService from "../../axios";
import { toast } from "react-toastify";

export const getHolidays = async (per_page, year, search, page, status) => {
  try {
    const req = await apiService.get(
      `v5/holidays?per_page=${per_page}&year=${year}&search=${search}&page=${page}&status=${status}`
    );
    return req;
  } catch (error) {
    throw error;
  }
};

export const addHoliday = async (payload) => {
  try {
    const { data } = await apiService.post(`v5/holidays/add-holiday`, payload, {
      Accept: "Application/json",
      body: JSON.stringify(payload),
    });
    return data.data;
  } catch (error) {
    throw error;
  }
};

export const reflectHoliday = async (id, name) => {
  try {
    const { data } = await apiService.post(
      `v5/holidays/reflect-to-timesheet?id=${id}`
    );
    toast.success("Successfully reflect holiday: " + name);
    return data.data;
  } catch (error) {
    throw error;
  }
};
export const undoReflectHoliday = async (id, name) => {
  try {
    const { data } = await apiService.post(
      `v5/holidays/undo-timesheet?id=${id}`
    );
    toast.success("Successfully undo reflect holiday: " + name);
    return data.data;
  } catch (error) {
    throw error;
  }
};

export const removeHoliday = async (payload) => {
  try {
    const { data } = await apiService.post(
      `v5/holidays/do-remove-holiday`,
      payload,
      {
        Accept: "Application/json",
        body: JSON.stringify(payload),
      }
    );
    return data.data;
  } catch (error) {
    throw error;
  }
};

export const activeInactive = async (payload) => {
  try {
    const { data } = await apiService.post(`v5/holidays/toogle`, payload, {
      Accept: "Application/json",
      body: JSON.stringify(payload),
    });
    toast.success(
      `Successfully ${
        payload.status === 1 ? "activated" : "deactivated"
      } holiday: ` + payload.name
    );
    return data.data;
  } catch (error) {
    toast.error("Something went wrong. Please try again");
    throw error;
  }
};

export const updateHoliday = async () => {
  try {
    const data = await apiService.post(`v5/holidays/update-holiday`);
    return data;
  } catch (error) {
    throw error;
  }
};
