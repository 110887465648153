import React, { useEffect } from "react";
import { useState, useCallback } from "react";
import Pagination from "./Pagination";
import SearchComponent from "../SearchComponent";
import {
  Paper,
  Table as MuiTable,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const Table = ({
  header,
  order,
  sort,
  queryParams,
  toggleFetch,
  fetch,
  Row,
  handleEdit,
  handleClick,
  handleRemove,
  handleReport,
  date,
  path,
  limit,
  setLimit,
  setOffset,
  offset,
}) => {
  const permission = JSON.parse(localStorage.getItem("user"));
  const [keyword, setKeyword] = useState("");
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);

  const { data: list, isFetching: listFetching } = fetch(
    getFetchUrl(),
    path,
    date,
    keyword,
    limit,
    offset,
    toggleFetch
  );

  const initList = useCallback(() => {
    if (list) {
      setData(list.data);
      setTotal(list.total);
    }
  }, [list]);

  useEffect(() => {
    initList();
  }, [initList]);

  function getFetchUrl() {
    queryParams = queryParams ? `&${queryParams}` : "";
    return (
      "?search=" +
      keyword +
      "&page_size=" +
      limit +
      "&page=" +
      offset +
      "&fromDate=" +
      date +
      "&path=" +
      path +
      "&order=" +
      order +
      "&sort=" +
      (sort || "") +
      queryParams
    );
  }

  return (
    <>
      <SearchComponent
        setKeyword={setKeyword}
        setOffset={setOffset}
        isFetching={listFetching}
      />
      <TableContainer sx={{ mt: 1.5 }} component={Paper}>
        <MuiTable size="small">
          <TableHead>
            <TableRow>
              {header?.map((column, index) => (
                <TableCell key={index} component="th">
                  <b>{column?.label}</b>
                </TableCell>
              ))}
              <TableCell component="th">
                <b>Action</b>
              </TableCell>
            </TableRow>
          </TableHead>

          {listFetching ? (
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{ height: "90px" }}
                  align="center"
                  colSpan={"100%"}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : data.length === 0 ? (
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{ height: "90px" }}
                  align="center"
                  colSpan={"100%"}
                >
                  <Typography>No Records Found.</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              <Row
                data={data}
                header={header}
                handleClick={handleClick}
                handleRemove={handleRemove}
                handleEdit={handleEdit}
                permission={permission}
                handleReport={handleReport}
              />
            </TableBody>
          )}
        </MuiTable>
      </TableContainer>
      <MuiTable>
        <TableBody>
          <TableRow>
            <Pagination
              onPageChange={setOffset}
              limit={limit}
              offset={offset}
              total={total}
              onChange={setLimit}
            />
          </TableRow>
        </TableBody>
      </MuiTable>
    </>
  );
};

export default Table;
