import React, { useRef, useEffect, useState, useContext } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  CardHeader,
  Grow,
  Card,
  Grid,
  Avatar,
  Snackbar,
  Alert,
  AlertTitle,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import IconButton from "@mui/material/IconButton";
import { useForm } from "react-hook-form";
import InputController from "../InputController";
import WfhDropzone from "./wfhDropzone";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import { format, isAfter, isBefore } from "date-fns";
import { GlobalContext } from "../../App";
import hooks from "../../hooks/react-query/queryHooks";
import ForgotTimeInOutResponse from "./ForgotTimeInOutResponse";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";

const computeForHoursMinutes = (start, end) => {
  const msInMinute = 60 * 1000;
  const diffMin = Math.round(Math.abs(end - start) / msInMinute);
  const minutes = (diffMin % 60) / 60;
  const hours = Math.floor(diffMin / 60);
  const hourMin = hours + "." + minutes.toFixed(2).toString().replace("0.", "");

  return hourMin;
};

const TimeInOutRequestModal = ({ forgotTimeInOutOpen, handleTimeInOut }) => {
  const imgUrl = useRef([]);
  const { user } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  const [convertStatus, setConvertStatus] = useState("");
  const [convertConfirm, setConvertConfirm] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [location, setLocation] = useState("Office");
  const [typeOfRequest, setTypeOfRequest] = useState("in");
  const queryClient = useQueryClient();

  useEffect(() => {
    imgUrl.current = [];
  }, []);

  const refetchMyQuery = () => {
    queryClient.refetchQueries(["get-employee-request-data-with-page"]);
  };

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      startDate: new Date(),
      endDate: new Date(),
      reason: "",
      location: "Office",
      type: "in",
    },
  });

  const onClose = () => {
    setOpen(false);
  };
  const onClose2 = () => {
    setOpen2(false);
  };
  const onClose3 = () => {
    setOpen3(false);
  };
  const onClose4 = () => {
    setOpen4(false);
  };
  const handleDelte = () => {
    imgUrl.current = [];
    setOpen3(true);
  };
  const indiDelete = (indexParam) => {
    imgUrl.current.splice(indexParam, 1);
    setOpen4(true);
  };

  const hourValueSetter = (date1, date2) => {
    setValue("hours", computeForHoursMinutes(date1, date2));
  };

  const handleDate = (event, dayParam) => {
    const start = getValues("startTime");
    const end = getValues("endTime");

    if (dayParam === 1) {
      setValue("startTime", event);
      hourValueSetter(event, end);
      if (isAfter(event, end)) {
        setValue("endTime", event);
        hourValueSetter(event, event);
      }
    }

    if (dayParam === 2) {
      setValue("endTime", event);
      hourValueSetter(start, event);
      if (isBefore(event, start)) {
        setValue("startTime", event);
        hourValueSetter(event, event);
      }
    }
  };

  const hoursChecker = (event, dayParam) => {
    const start = getValues("startTime");
    const end = "endTime";

    if (dayParam === 1) {
      setValue("startTime", event);
      hourValueSetter(event, end);
    }

    if (dayParam === 2) {
      setValue("endTime", event);
      hourValueSetter(start, event);
    }
  };
  const { mutate: addForgotTimeInOutRequest } = hooks.useForgotTimeInOut();

  const onSubmit = async (data) => {
    const dataParam = {
      api: true,
      requesting_ee: user?.employee.id,
      location: location,
      time: format(data.startDate, "h:mm aa"),
      date: format(data.startDate, "LLL dd, yyyy"),
      reason: data.reason,
      images: imgUrl.current,
      type: typeOfRequest,
    };
    addForgotTimeInOutRequest(dataParam, {
      onSuccess: (request) => {
        setConvertStatus(request.status);
        setConvertConfirm(true);
        if (request.status === "fail") {
          typeOfRequest === "in"
            ? toast.error(
                "There is already an existing time in request with the chosen date."
              )
            : toast.error(
                "There is already an existing time out request with the chosen date."
              );
        }
      },
    });
  };

  const handleLocation = (event) => {
    setLocation(event.target.value);
  };

  const handleRequestType = (event) => {
    setTypeOfRequest(event.target.value);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={forgotTimeInOutOpen}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Grow
        in={forgotTimeInOutOpen}
        style={{ transformOrigin: "0 0 0" }}
        {...(forgotTimeInOutOpen ? { timeout: 500 } : {})}
        unmountOnExit
      >
        <Card
          className="login-modal leave1-modal"
          sx={{
            minWidth: "350px",
            overflowY: "scroll",
            maxHeight: "calc(100vh - 40px)",
          }}
        >
          <CardHeader
            title={
              <Typography variant="h3" className="h3-bar">
                Forgot Time In/Out Request
              </Typography>
            }
            action={
              <IconButton onClick={handleTimeInOut}>
                <CloseOutlinedIcon sx={{ width: "15px", height: "15px" }} />
              </IconButton>
            }
          />
          {convertConfirm ? (
            <ForgotTimeInOutResponse
              status={convertStatus}
              handleConversion={handleTimeInOut}
              refetchMyQuery={refetchMyQuery}
            />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                columnSpacing={2}
                rowSpacing={3}
                sx={{ padding: "20px" }}
              >
                <Grid item xs={12} lg={4}>
                  <Box className="typ-over">
                    <Typography
                      variant="overline"
                      sx={{ paddingLeft: "10px", alignSelf: "center" }}
                    >
                      Select TYPE OF REQUEST
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <InputController
                    type="select"
                    control={control}
                    label="Type"
                    name="type"
                    errors={errors}
                    size="small"
                    options={[
                      { value: "in", label: "Time In" },
                      { value: "out", label: "Time Out" },
                    ]}
                    handleSurveySelect={(event) => handleRequestType(event)}
                    timeNum={2}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <InputController
                    type="select"
                    control={control}
                    label="Location"
                    name="location"
                    errors={errors}
                    size="small"
                    options={[
                      { value: "Office", label: "Office" },
                      { value: "Home/Remote", label: "Home/Remote" },
                    ]}
                    handleSurveySelect={(event) => handleLocation(event)}
                    timeNum={2}
                  />
                </Grid>
                <Grid item xs={12} lg={4} xsOffset={4}>
                  <InputController
                    type="date"
                    control={control}
                    label="Start Date"
                    name="startDate"
                    errors={errors}
                    size="small"
                    handleDateFunc={handleDate}
                    dateNum={1}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <InputController
                    type="timePicker"
                    control={control}
                    label={typeOfRequest === "in" ? "Time In" : "Time out"}
                    name="startDate"
                    errors={errors}
                    size="small"
                    handleTimePickFunc={hoursChecker}
                    timeNum={1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputController
                    type="text"
                    control={control}
                    label="Reason"
                    name="reason"
                    errors={errors}
                    rows={7}
                    rules={{ required: true }}
                    isDefaultErrorAlert={true}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    disableElevation
                    disabled={imgUrl.current.length > 0 ? false : true}
                    startIcon={<DeleteRoundedIcon />}
                    sx={{
                      textTransform: "none",
                    }}
                    onClick={handleDelte}
                  >
                    Delete all images
                  </Button>
                  <Box
                    sx={{
                      "> p": {
                        fontSize: "0.7rem",
                        color: "#C05621",
                        mt: 1,
                      },
                    }}
                  >
                    <Typography sx={{ mt: "15px !important" }}>
                      Note:
                    </Typography>
                    <Typography>
                      <CircleRoundedIcon fontSize="0.5rem" />
                      &nbsp;To upload Images/Files, click the "Drag or drop
                      images/files or Click to upload" and select the images you
                      wish to upload or drag and drop your images.
                    </Typography>
                    <Typography>
                      <CircleRoundedIcon fontSize="0.5rem" />
                      &nbsp;Upload atleast one image.
                    </Typography>
                    <Typography>
                      <CircleRoundedIcon fontSize="0.5rem" />
                      &nbsp;File size up to 5mb
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <WfhDropzone
                    setOpen={setOpen}
                    setOpen2={setOpen2}
                    imgUrl={imgUrl}
                  />
                </Grid>
                {imgUrl.current.length > 0 &&
                  imgUrl.current.map((imgrl, index) => (
                    <Grid
                      item
                      xs={12}
                      lg={6}
                      key={index}
                      sx={{ position: "relative" }}
                    >
                      <IconButton
                        sx={{
                          position: "absolute",
                          color: "#fff",
                          bgcolor: "red",
                          mt: "-10px",
                          mr: "-10px",
                          zIndex: 1,
                          right: 0,
                        }}
                        onClick={() => {
                          indiDelete(index);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <Avatar
                        src={imgrl}
                        sx={{
                          height: "235px",
                          width: "100%",
                          borderRadius: "5px",
                        }}
                      />
                    </Grid>
                  ))}
              </Grid>
              {open && (
                <Snackbar
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  open={true}
                  autoHideDuration={2500}
                  onClose={onClose}
                >
                  <Alert
                    onClose={onClose}
                    elevation={6}
                    variant="filled"
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    <AlertTitle>Error</AlertTitle>
                    {open}
                  </Alert>
                </Snackbar>
              )}
              {open2 && (
                <Snackbar
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  open={true}
                  autoHideDuration={1000}
                  onClose={onClose2}
                >
                  <Alert
                    onClose={onClose2}
                    elevation={6}
                    variant="filled"
                    severity="success"
                    sx={{ width: "100%" }}
                  >
                    <AlertTitle>Success</AlertTitle>
                    Successfully uploaded file.
                  </Alert>
                </Snackbar>
              )}
              {open3 && (
                <Snackbar
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  open={true}
                  autoHideDuration={2500}
                  onClose={onClose3}
                >
                  <Alert
                    onClose={onClose3}
                    elevation={6}
                    variant="filled"
                    severity="success"
                    sx={{ width: "100%" }}
                  >
                    <AlertTitle>Success</AlertTitle>
                    Successfully deleted all file
                  </Alert>
                </Snackbar>
              )}
              {open4 && (
                <Snackbar
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  open={true}
                  autoHideDuration={2500}
                  onClose={onClose4}
                >
                  <Alert
                    onClose={onClose4}
                    elevation={6}
                    variant="filled"
                    severity="success"
                    sx={{ width: "100%" }}
                  >
                    <AlertTitle>Success</AlertTitle>
                    Successfully deleted file
                  </Alert>
                </Snackbar>
              )}
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  color="inherit"
                  onClick={handleTimeInOut}
                  sx={{ marginRight: "10px" }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  disabled={imgUrl.current.length > 0 ? false : true}
                  type="submit"
                >
                  Submit
                </Button>
              </Box>
            </form>
          )}
        </Card>
      </Grow>
    </Modal>
  );
};

export default TimeInOutRequestModal;
