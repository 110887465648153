import { Typography, Box, Tooltip } from "@mui/material";
import { useState, useCallback, useEffect } from "react";
import Table from "../../component/Schedule/Table";
import { TableHeader } from "../../lib/schedule/TableSettings";
import hooks from "../../hooks/react-query/Schedule";
import Row from "./Row";
import Add from "./Add";
import AddRow from "./AddRow";
import { addSchedule, deleteSchedule, editSchedule } from "../../api/schedule";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import Confirm from "../../component/Schedule/Confirm";
import EditRow from "./EditRow";
import ScheduleRow from "./ScheduleRow";

const Schedule = () => {
  const [limit, setLimit] = useState(10);
  const [schedule, setSchedule] = useState();
  const [shift, setShift] = useState();
  const [offset, setOffset] = useState(1);
  const [keyword, setKeyword] = useState(0);
  const [fetch, toggleFetch] = useState(true);
  const [total, setTotal] = useState(0);
  const [add, showAdd] = useState(false);
  const [errors, setErrors] = useState("");
  const [confirm, showConfirm] = useState(false);
  const [id, setId] = useState();
  const [edit, setEdit] = useState();

  const { data: schedules, isFetching: scheduleFetching } = hooks.useSchedule(
    getFetchUrl(),
    keyword,
    limit,
    offset,
    fetch
  );

  const { data: shifts } = hooks.useShift(getFetchUrl());

  const initSchedule = useCallback(() => {
    if (schedules) {
      setSchedule(schedules.data.data);
      setTotal(schedules.data.total);
    }
  }, [schedules]);

  const initShift = useCallback(() => {
    if (shifts) {
      const options =
        shifts?.data?.map(function (shift) {
          return { label: shift?.shift_name, value: shift?.id };
        }) || [];
      var defaultOption = [{ label: "Rest Day", value: 0 }, ...options];
      setShift(defaultOption);
    }
  }, [shifts]);

  useEffect(() => {
    initShift();
  }, [initShift]);

  useEffect(() => {
    initSchedule();
  }, [initSchedule]);

  function getFetchUrl() {
    return "?search=" + keyword + "&page_size=" + limit + "&page=" + offset;
  }

  const removeSchedule = useMutation((formData) => deleteSchedule(formData), {
    onSuccess: async (response) => {
      toast.success("Schedule removed.");
      toggleFetch(!fetch);
      showConfirm(false);
    },
    onError: (response) => {
      toast.error(response.message);
    },
  });

  const createSchedule = useMutation((formData) => addSchedule(formData), {
    onSuccess: async (response) => {
      toast.success("Schedule successfully added.");
      showAdd(false);
      toggleFetch(!fetch);
    },
    onError: (response) => {
      setErrors(response.response.data || {});
    },
  });

  const updateSchedule = useMutation((formData) => editSchedule(formData), {
    onSuccess: async (response) => {
      toast.success("Record Updated.");
      setEdit();
      toggleFetch(!fetch);
    },
    onError: (response) => {
      setErrors(response.response.data || {});
    },
  });

  const formFields = [
    {
      label: "Schedule Name",
      payload: "sched_name",
      type: "text",
      value: "",
      helperText: errors.sched_name,
    },
    {
      label: "Monday",
      payload: "mon",
      type: "select",
      options: shift,
      value: "",
      helperText: errors.mon,
    },
    {
      label: "Tuesday",
      payload: "tue",
      type: "select",
      options: shift,
      value: "",
      helperText: errors.tue,
    },
    {
      label: "Wednesday",
      payload: "wed",
      type: "select",
      options: shift,
      value: "",
      helperText: errors.wed,
    },
    {
      label: "Thursday",
      payload: "thu",
      type: "select",
      options: shift,
      value: "",
      helperText: errors.thu,
    },
    {
      label: "Friday",
      payload: "fri",
      type: "select",
      options: shift,
      value: "",
      helperText: errors.fri,
    },
    {
      label: "Saturday",
      payload: "sat",
      type: "select",
      options: shift,
      value: "",
      helperText: errors.sat,
    },
    {
      label: "Sunday",
      payload: "sun",
      type: "select",
      options: shift,
      value: "",
      helperText: errors.sun,
    },
  ];

  const handleConfirm = (payload) => {
    createSchedule.mutate(payload);
  };

  const handleCancelAdd = () => {
    showAdd(false);
    setErrors({});
  };

  const handleCancelEdit = () => {
    setEdit();
    setErrors({});
  };

  const handleDelete = (id) => {
    setId({ id: id });
    showConfirm(true);
  };

  const handleConfirmDelete = () => {
    removeSchedule.mutate(id);
  };

  const handleEdit = (id) => {
    setEdit(id);
  };

  const handleSave = (payload) => {
    updateSchedule.mutate(payload);
  };

  const Buttons = [
    { Icon: <Add key="add-key-1" handleClick={(e) => showAdd(!add)} /> },
  ];
  const hiddenRows = [
    {
      row: (
        <AddRow
          formFields={formFields}
          key="add-row-1"
          header={TableHeader}
          hidden={add}
          handleCancel={(e) => handleCancelAdd()}
          handleConfirm={handleConfirm}
        />
      ),
    },
  ];

  return (
    <Box sx={{ margin: "0px", pr: "25px" }}>
      <Confirm
        open={confirm}
        handleConfirm={handleConfirmDelete}
        handleClose={(e) => showConfirm(false)}
        title="Are you sure you want to delete this record?"
        message="*All employee schedules using this schedule will be deleted."
        submitting={false}
      />
      <Box
        sx={{
          pt: "5px",
          pl: "25px",
          display: "flex",
          justifyContent: "flex-start",
          cursor: "context-menu",
        }}
      >
        <Tooltip title="View employees schedule" placement={"right"} arrow>
          <Typography variant="h4">Schedule</Typography>
        </Tooltip>
      </Box>
      <Box sx={{ pt: "5px", pl: "25px" }}>
        <Table
          header={TableHeader}
          Row={
            <Row
              data={schedule}
              header={TableHeader}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              isFetching={scheduleFetching}
              hiddenRows={hiddenRows}
              EditRow={EditRow}
              Row={ScheduleRow}
              options={shift}
              edit={edit}
              handleCancel={(e) => handleCancelEdit()}
              handleSave={handleSave}
              formFields={formFields}
            />
          }
          limit={limit}
          setLimit={setLimit}
          offset={offset}
          setOffset={setOffset}
          setKeyword={setKeyword}
          total={total}
          Buttons={Buttons}
          onPagination={(e) => handleCancelEdit()}
        />
      </Box>
    </Box>
  );
};
export default Schedule;
