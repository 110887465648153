import React, { useContext, useState } from "react";
import {
  Backdrop,
  Modal,
  Fade,
  Box,
  Typography,
  Button,
  TextField,
  Grid,
  IconButton,
  InputAdornment,
  Divider,
} from "@mui/material";
import { UserListContext } from ".";
import { useForm, Controller } from "react-hook-form";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useMutation } from "react-query";
import { addUser } from "../../../api/userList";
import { toast } from "react-toastify";
import { useDepartmentDropdowns } from "../../../hooks/react-query/profileQuery";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputController from "../../../component/InputController";
import { generateErrorObj } from "../../../helper/UserLists";

const AddUser = () => {
  const addUserSchema = yup
    .object({
      email: yup
        .string()
        .email("Invalid email format")
        .required("Please enter your Email"),
      firstname: yup.string().required("Please enter your First Name"),
      lastname: yup.string().required("Please enter your Last Name"),
      gender: yup.string().required("Please select your gender"),
    })
    .required();

  const { data: department } = useDepartmentDropdowns();
  const { isOpenAddUser, setIsOpenAddUser, refetch } =
    useContext(UserListContext);
  const [show_pass, setShowPass] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addUserSchema),
    defaultValues: {
      firstname: "",
      lastname: "",
      middlename: "",
      extension: "",
      gender: "",
      dept: "",
      dateHired: new Date(),
      email: "",
      password: "",
      wfh_require: false,
      min_hour: 0.0,
    },
    mode: "onBlur",
  });
  const onSubmit = (data) => {
    updateData.mutate(data);
  };

  const handleClose = () => {
    reset();
    setIsOpenAddUser(!isOpenAddUser);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const generatePassword = () => {
    const tempPassword = Math.random().toString(36).slice(-8);
    setValue("password", tempPassword);
  };

  const updateData = useMutation((newData) => addUser(newData), {
    onSuccess: async () => {
      toast.success("User Successfully Added");
      handleClose();
      refetch();
    },
    onError: (error) => {
      const { title, message } = generateErrorObj(error);

      if (title && getValues(title)) {
        setError(title, { type: "validate", message });
        return;
      }

      toast.error(message);
    },
  });

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpenAddUser}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpenAddUser}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Add User
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)} style={{ margin: "1em" }}>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <InputController
                  type="text"
                  control={control}
                  label="First Name"
                  name="firstname"
                  errors={errors}
                  isDefaultErrorAlert={true}
                />
              </Grid>
              <Grid item sm={6}>
                <InputController
                  type="text"
                  control={control}
                  label="Last Name"
                  name="lastname"
                  errors={errors}
                  isDefaultErrorAlert={true}
                />
              </Grid>
              <Grid item sm={6}>
                <InputController
                  type="text"
                  control={control}
                  label="Middle Name"
                  name="middlename"
                  errors={errors}
                  isDefaultErrorAlert={true}
                />
              </Grid>
              <Grid item sm={6}>
                <InputController
                  type="text"
                  control={control}
                  label="Extension"
                  name="extension"
                  errors={errors}
                  isDefaultErrorAlert={true}
                />
              </Grid>
              <Divider sx={{ width: "100%", marginY: "1em" }} />
              <Grid item sm={6}>
                <InputController
                  type="select"
                  options={[
                    { value: "male", label: "Male" },
                    { value: "female", label: "Female" },
                    { value: "other", label: "Other..." },
                  ]}
                  control={control}
                  label="Gender"
                  name="gender"
                  rules={{ required: true }}
                  errors={errors}
                  isDefaultErrorAlert={true}
                />
              </Grid>
              <Grid item sm={6}>
                <InputController
                  type="select"
                  options={department}
                  control={control}
                  label="Department"
                  name="dept"
                  errors={errors}
                  isDefaultErrorAlert={true}
                />
              </Grid>
              <Grid item sm={6}>
                <InputController
                  type="date"
                  control={control}
                  label="Date Hired"
                  name="dateHired"
                  errors={errors}
                  isDefaultErrorAlert={true}
                />
              </Grid>
              <Grid item sm={6}>
                <InputController
                  type="check"
                  control={control}
                  label="Requires WFH Application Approval"
                  name="wfh_require"
                  errors={errors}
                  isDefaultErrorAlert={true}
                />
              </Grid>
              <Divider sx={{ width: "100%", marginY: "1em" }} />
              <Grid item sm={6}>
                <InputController
                  type="text"
                  control={control}
                  label="Email"
                  name="email"
                  errors={errors}
                  isDefaultErrorAlert={true}
                />
              </Grid>
              <Grid item sm={6}>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Grid container spacing={2}>
                        <Grid item sm={8}>
                          <TextField
                            fullWidth
                            {...field}
                            type={show_pass ? "text" : "password"}
                            variant="outlined"
                            label="Password"
                            InputProps={{
                              autoComplete: "new-password",
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                      setShowPass((prev) => !prev);
                                    }}
                                    onMouseDown={() => {
                                      setShowPass((prev) => !prev);
                                    }}
                                    edge="end"
                                  >
                                    {show_pass ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item sm={4}>
                          <Button
                            size="small"
                            variant="contained"
                            fullWidth
                            color="info"
                            onClick={generatePassword}
                          >
                            <Typography sx={{ fontSize: "0.78rem" }}>
                              GENERATE
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>

                      <Typography
                        style={{
                          fontSize: ".7em",
                          fontStyle: "italic",
                          marginTop: ".5em",
                        }}
                      >
                        *Note: Leave blank if no changes implemented.
                      </Typography>
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputController
                  type="t-number"
                  inputType="number"
                  control={control}
                  label="Minimum hours to render"
                  name="min_hour"
                  errors={errors}
                  isDefaultErrorAlert={true}
                />
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                margin: "1em 0em",
              }}
            >
              <Button
                size="small"
                sx={{ marginX: "5px" }}
                variant="contained"
                onClick={handleClose}
              >
                <Typography sx={{ fontSize: "0.78rem" }}>CANCEL</Typography>
              </Button>
              <Button
                type="submit"
                sx={{ marginX: "5px", backgroundColor: "#00c292" }}
                size="small"
                variant="contained"
              >
                <Typography sx={{ fontSize: "0.78rem" }}>SAVE</Typography>
              </Button>
            </div>
          </form>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AddUser;
