import React from "react";
import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";

const EmptyData = () => {
  return (
    <Box component={Paper} sx={{ padding: "1rem" }}>
      <Typography sx={{ textAlign: "center", width: "100%" }}>
        No Records Found.
      </Typography>
    </Box>
  );
};

export default EmptyData;
