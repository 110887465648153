import { useQuery } from "react-query";
import { document, logDocument } from "../../../api/policy";

const useDocument = (
  QueryParam,
  path,
  date,
  keyword,
  limit,
  offset,
  toggleFetch
) => {
  return useQuery(
    ["get-documents", path, date, keyword, limit, offset, toggleFetch],
    () => document(QueryParam)
  );
};

const useLogDocument = (
  QueryParam,
  id,
  keyword,
  limit,
  offset,
  toggleFetch
) => {
  return useQuery(
    ["get-documents", id, keyword, limit, offset, toggleFetch],
    () => logDocument(QueryParam)
  );
};

const queries = {
  useDocument,
  useLogDocument,
};

export default queries;
