export const sliderMarks = [
  {
    value: 0,
    label: "0 min",
  },
  {
    value: 0.25,
    label: "15 mins",
  },
  {
    value: 0.5,
    label: "30 mins",
  },
  {
    value: 0.75,
    label: "45 mins",
  },
  {
    value: 1,
    label: "1hr",
  },
  {
    value: 1.25,
    label: "1 hr 15 mins",
  },
  {
    value: 1.5,
    label: "1 hr 30 mins",
  },
  {
    value: 1.75,
    label: "1 hr 45 mins",
  },
  {
    value: 2,
    label: "2 hrs",
  },
  {
    value: 2.25,
    label: "2 hrs 15 mins",
  },
  {
    value: 2.5,
    label: "2 hrs 30 mins",
  },
  {
    value: 2.75,
    label: "2 hrs 45 mins",
  },
  {
    value: 3,
    label: "3 hrs",
  },
  {
    value: 3.25,
    label: "3 hrs 15 mins",
  },
  {
    value: 3.5,
    label: "3 hrs 30 mins",
  },
  {
    value: 3.75,
    label: "3 hrs 45 mins",
  },
  {
    value: 4,
    label: "4 hrs ",
  },
  {
    value: 4.25,
    label: "4 hrs 15 mins",
  },
  {
    value: 4.5,
    label: " 4 hrs 30 mins",
  },
  {
    value: 4.75,
    label: "4 hrs 45 mins",
  },
  {
    value: 5,
    label: "5hrs",
  },
  {
    value: 5.25,
    label: "5 hrs 15 mins",
  },
  {
    value: 5.5,
    label: "5 hrs 30 mins",
  },
  {
    value: 5.75,
    label: "5 hrs 45 mins",
  },
  {
    value: 6,
    label: "6 hrs",
  },
  {
    value: 6.25,
    label: "6 hrs 15 mins",
  },
  {
    value: 6.5,
    label: "6 hrs 30 mins",
  },
  {
    value: 6.75,
    label: "6 hrs 45 mins",
  },
  {
    value: 7,
    label: "7 hrs",
  },
  {
    value: 7.25,
    label: "7 hrs 15 mins",
  },
  {
    value: 7.5,
    label: "7 hrs 30 mins",
  },
  {
    value: 7.75,
    label: "7 hrs 45 mins",
  },
  {
    value: 8,
    label: "8 hrs",
    name: "8 hrs",
  },
];
