import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";

import PerActivityStyledTable from "../table/PerActivityStyledTable";
import PerActivityTableHeader from "../table/perActivity/PerActivityTableHeader";
import { ORDER } from "../../../../lib/reports/perActivity/";

const TABLE_CONTAINER_STYLES = {
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  backgroundColor: "#FFF",
};

const PerActivityTableContainer = ({ children, state, handler }) => {
  const { order, orderBy } = state;
  const { handleOrderChange, handleOrderChangeBy } = handler;

  const requestSortHandler = (newOrderBy) => {
    const isAsc = orderBy === newOrderBy && order === ORDER.ascending;
    handleOrderChange(isAsc ? ORDER.descending : ORDER.ascending);
    handleOrderChangeBy(newOrderBy);
  };

  return (
    <TableContainer sx={TABLE_CONTAINER_STYLES}>
      <PerActivityStyledTable>
        <PerActivityTableHeader
          order={order}
          orderBy={orderBy}
          handleOrderChange={requestSortHandler}
        />

        <TableBody>{children}</TableBody>
      </PerActivityStyledTable>
    </TableContainer>
  );
};

export default PerActivityTableContainer;
