import React from "react";
import BackupRoundedIcon from "@mui/icons-material/BackupRounded";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useDropzone } from "react-dropzone";

const maxSizeValidator = (file) => {
  if (file.size > 5242880)
    return {
      code: "file-size-too-large",
      message: "File size is larger than 5mb",
    };

  return null;
};

const WfhDropzone = ({ setOpen, setOpen2, imgUrl }) => {
  const onDrop = React.useCallback((files, fileRejections) => {
    if (fileRejections.length > 0) {
      setOpen(fileRejections[0].errors[0].message);
    } else {
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        let reader = new FileReader();
        reader.onloadend = () => {
          imgUrl.current.push(reader.result);
        };
        reader.readAsDataURL(file);
      }
      setOpen2(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    },
    acceptFiles: "image/png",
    onDrop,
    validator: maxSizeValidator,
  });

  return (
    <Box
      {...getRootProps()}
      sx={{
        border: "dashed",
        height: "238px",
        borderColor: "rgba(0, 0, 0, 0.23)",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        cursor: "pointer",
        "> p": {
          fontSize: "0.8rem",
          p: "20px 10px 40px 10px",
          m: "35px",
        },
      }}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <Typography>Drop the image here ...</Typography>
      ) : (
        <Typography>
          Drag 'n' drop image here, or Click to select files
        </Typography>
      )}
      {/* {imgUrl.current.length > 0 && (
        <Avatar
          src={imgUrl.current[0]}
          alt="wfh-image"
          sx={{
            position: "absolute",
            height: "235px",
            width: "100%",
            borderRadius: "5px",
            zIndex: 1,
          }}
        />
      )} */}
      <BackupRoundedIcon
        sx={{
          position: "absolute",
          mt: "100px",
          fontSize: "3.5rem",
          opacity: "0.2",
        }}
      />
    </Box>
  );
};

export default WfhDropzone;
