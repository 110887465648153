import apiService from "../axios";

export const getMOTD = async ({ per_page = 10, search, page = 1 }) => {
  try {
    let response = await apiService.get(
      `v5/settings/list-motd?per_page=${per_page}&search=${search}&page=${page}`,
      {
        Accept: `application/json`,
        api: true,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
