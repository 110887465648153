import { useLayoutEffect, useRef } from "react";

import TableRow from "@mui/material/TableRow";

import StyledTableCell from "../../../../UserApplicationMgmt/UserApplications/tableComponents/StyledTableCell";
import { formatDateForQuery } from "../../../../../helper/Reports/PerActivity";

const PerProjectTableRow = ({ timesheet }) => {
  const elementRef = useRef();

  const { date, milestone, details, efforts } = timesheet;

  const formattedDate = formatDateForQuery(date);

  useLayoutEffect(() => {
    if (elementRef.current) {
      elementRef.current.childNodes.forEach((el) => {
        el.style.margin = "0px";
      });
    }
  });

  return (
    <TableRow hover>
      {/* Date */}
      <StyledTableCell>{formattedDate}</StyledTableCell>

      {/* Milestone */}
      <StyledTableCell>{milestone || "-"}</StyledTableCell>

      {/* Details */}
      <StyledTableCell
        ref={elementRef}
        dangerouslySetInnerHTML={{ __html: details }}
      />

      {/* Total efforts */}
      <StyledTableCell>{efforts}</StyledTableCell>
    </TableRow>
  );
};

export default PerProjectTableRow;
