import {
  Modal,
  Typography,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import AutoCompleteDescription from "../../../component/CustomElement/AutoCompleteDescription";
import { ModalStyleGlobal } from "../../../helper/ModalStyle";
import {
  useAddReminder,
  useGetEmployees,
} from "../../../hooks/react-query/SettingsTimesheet/EmployeeToEmailTimesheetReport";
import { ExcludeEmployeeIfInTable } from "../../../helper/SettingsTimesheet/EmployeeToEmailTimesheetReport";

const AddModal = ({ open, handleClose, excludeEmp }) => {
  const [addEmployee, setAddEmployee] = useState([]);
  const { data: employees, isFetching } = useGetEmployees(2000);
  const { mutate: AddReminder, isLoading } = useAddReminder();

  const handleSelectEmployee = (e, value) => {
    setAddEmployee(value.map((data) => ({ user_id: data.id })));
  };

  const handleSubmit = () => {
    AddReminder(addEmployee, {
      onSuccess: () => closeModal(),
    });
  };

  const closeModal = () => {
    handleClose();
    setAddEmployee([]);
  };
  const OptionData = ExcludeEmployeeIfInTable(employees?.data, excludeEmp);
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={ModalStyleGlobal}>
        <Typography variant="h5" mb={5}>
          Add Employee
        </Typography>
        <AutoCompleteDescription
          data={{ id: "id", name: "full_name", desc: "description" }}
          optionData={OptionData}
          loading={isFetching || OptionData === undefined}
          multiple={true}
          label={"Select employee"}
          onChange={handleSelectEmployee}
        />
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}
        >
          <Button onClick={closeModal} sx={{ marginRight: "5px" }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={isLoading || addEmployee.length === 0}
            endIcon={isLoading ? <CircularProgress size={20} /> : null}
          >
            Submit
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default AddModal;
