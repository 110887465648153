import { Button, Switch } from "@mui/material";
import React, { useContext, useRef } from "react";
import Search from "../../../component/AdminTables/Search";
import AddIcon from "@mui/icons-material/Add";
import { SystemSkillContext } from "../../../globalstate/SystemSettings/Skill";

const SkillHeader = ({ onClickAddSkill }) => {
  const { setSkillsParams } = useContext(SystemSkillContext);
  const handleSearch = (e) => {
    setSkillsParams((data) => ({ ...data, search: e.target.value }));
  };
  const textInput = useRef(null);

  const ClearSearch = () => {
    textInput.current.value = "";
    setSkillsParams((data) => ({ ...data, search: "" }));
  };
  const handleShowInactive = (e) => {
    setSkillsParams((data) => ({ ...data, status: e.target.checked ? 0 : 1 }));
  };
  return (
    <div
      style={{
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        gap: "10px",
      }}
    >
      Show inactive :
      <Switch onChange={handleShowInactive} />
      <Button
        startIcon={<AddIcon fontSize="small" />}
        size="small"
        sx={{ textTransform: "none" }}
        style={{
          backgroundColor: "rgb(0, 194, 146)",
          color: "white",
          padding: "6px 12px 6px 8px",
        }}
        onClick={onClickAddSkill}
      >
        Add Skill
      </Button>
      <Search
        textInput={textInput}
        setSearch={handleSearch}
        ClearSearch={ClearSearch}
        variant={"outlined"}
      />
    </div>
  );
};

export default SkillHeader;
