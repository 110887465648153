export const Ranges = [
  {
    value: "This Week",
    name: "This Week",
  },
  {
    value: "Last Week",
    name: "Last Week",
  },
  {
    value: "Last 2 Weeks",
    name: "Last 2 Weeks",
  },
  {
    value: "This month",
    name: "This month",
  },
  {
    value: "Last month",
    name: "Last month",
  },
  {
    value: "Last 2 months",
    name: "Last 2 months",
  },
  {
    value: "Fiscal Year",
    name: "Fiscal Year",
  },
  {
    value: "Custom",
    name: "Custom",
  },
];
