import React from "react";
import {
  DialogTitle,
  DialogActions,
  Dialog,
  DialogContent,
  DialogContentText,
  Button,
  TextField,
} from "@mui/material";

const Password = ({
  open,
  handleClose,
  handleSubmit,
  param,
  formfield,
  errors,
}) => {
  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <DialogTitle>Commend Staff</DialogTitle>
        <DialogContent sx={{ pb: 1 }}>
          <DialogContentText>
            Write something here, your feedback or comment
          </DialogContentText>
          <TextField
            sx={{ width: "100%" }}
            multiline
            value={formfield.value}
            onChange={(e) => formfield.setFormfield(e.target.value)}
            minRows={3}
            maxRows={10}
            placeholder="Comments*"
            error={errors ? true : false}
          />
        </DialogContent>
        <DialogActions sx={{ pr: 3, pl: 3, pb: 2 }}>
          <Button
            size="small"
            className="adjust-w timest-button"
            disableElevation
            variant="contained"
            onClick={() => handleSubmit(param.action, param.id, param.page)}
          >
            Submit
          </Button>
          <Button
            size="small"
            className="adjust-w cancel-button"
            disableElevation
            variant="contained"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Password;
