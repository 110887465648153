import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import quoteImg from "../../images/quote.png";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    "&::-webkit-scrollbar": {
      width: 7,
      opacity: 0.2,
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
      opacity: 0.2,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      outline: `.5px solid slategrey`,
      opacity: 0.2,
    },
    "&:hover::-webkit-scrollbar-track": {
      backgroundColor: "#ebecf0",
      opacity: 0.3,
    },
    "&:hover::-webkit-scrollbar-thumb": {
      backgroundColor: "#d3d3d3",
      opacity: 0.3,
    },
  },
}));

const Quote = ({ companyName }) => {
  const [qoutesData, setQoutesData] = useState([]);
  const [qoute, setQoute] = useState("");
  const [author, setAuthor] = useState("");

  const fetchQoutes = async () => {
    fetch("https://quotes.rest/qod")
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setQoutesData(data);
      });
  };

  useEffect(() => {
    fetchQoutes();
  }, []);

  const setQoutes = async () => {
    if (qoutesData.length !== 0) {
      setQoute(qoutesData.contents.quotes[0].quote);
      setAuthor(qoutesData.contents.quotes[0].author);
    }
  };

  useEffect(() => {
    setQoutes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qoutesData]);

  let cmpnyName =
    companyName.slice(0, 1).toUpperCase() +
    companyName.slice(1, companyName.length).toLowerCase();

  const classes = useStyles();

  return (
    <Card className="qt-card">
      <CardHeader
        title={
          <Typography variant="h3" className="h3-bar">
            Daily Quote
          </Typography>
        }
      />
      <CardContent sx={{ paddingTop: "5px" }}>
        <CardMedia
          component="img"
          height="170px"
          image={quoteImg}
          alt="Daily Quote"
        />
        <Typography variant="h3" className="h3-bar" sx={{ paddingTop: "24px" }}>
          {cmpnyName} daily quote of the day
        </Typography>
        <Typography variant="h6" color="text.secondary" className="auth-h6">
          By {author}
        </Typography>
        <Typography
          variant="body1"
          paragraph
          color="text.secondary"
          className={`${classes.root} auth-b1 qoute-body`}
          style={{ wordWrap: "break-word" }}
        >
          "{qoute}"
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Quote;
