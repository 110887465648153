import React from "react";

export const RequestContext = React.createContext({});

export const RequestProvider = (props) => {
  const [leaveOpen, setLeaveopen] = React.useState(false);
  const [wfhOpen, setWfhopen] = React.useState(false);
  const [overTime, setOvertiem] = React.useState(false);
  const [leaveConversion, setLeaveconversion] = React.useState(false);
  const [myRequest, setMyRequest] = React.useState(false);
  const [shiftOpen, setShift] = React.useState(false);
  const [feedbackOpen, setFeedbackOpen] = React.useState(false);
  const [pulseSurvey, setPulseSurvey] = React.useState(false);
  const [psychButton, setPsychButton] = React.useState(true);
  const [forgotTimeInOutOpen, setForgotTimeInOutOpen] = React.useState(false);
  const [documentOpen, setDocumentOpen] = React.useState(false);

  const handleLeave = () => {
    leaveOpen ? setLeaveopen(false) : setLeaveopen(true);
  };

  const handleFeedback = () => {
    feedbackOpen ? setFeedbackOpen(false) : setFeedbackOpen(true);
  };

  const handleWFH = () => {
    wfhOpen ? setWfhopen(false) : setWfhopen(true);
  };

  const handleOT = () => {
    overTime ? setOvertiem(false) : setOvertiem(true);
  };

  const handleMyRequest = () => {
    myRequest ? setMyRequest(false) : setMyRequest(true);
  };
  const handleShift = () => {
    shiftOpen ? setShift(false) : setShift(true);
  };
  const handleConversion = () => {
    leaveConversion ? setLeaveconversion(false) : setLeaveconversion(true);
  };
  const handleViewSurvey2 = () => {
    pulseSurvey ? setPulseSurvey(false) : setPulseSurvey(true);
    psychButton ? setPsychButton(false) : setPsychButton(true);
  };
  const handlePopUpSurvey = () => {
    psychButton ? setPsychButton(false) : setPsychButton(true);
    pulseSurvey ? setPulseSurvey(false) : setPulseSurvey(true);
  };

  const handleTimeInOut = () => {
    forgotTimeInOutOpen
      ? setForgotTimeInOutOpen(false)
      : setForgotTimeInOutOpen(true);
  };

  const handleDocument = () => {
    documentOpen ? setDocumentOpen(false) : setDocumentOpen(true);
  };

  return (
    <RequestContext.Provider
      value={{
        leaveOpen,
        forgotTimeInOutOpen,
        handleLeave,
        wfhOpen,
        handleWFH,
        handleMyRequest,
        overTime,
        handleOT,
        leaveConversion,
        handleConversion,
        shiftOpen,
        handleShift,
        feedbackOpen,
        handleFeedback,
        pulseSurvey,
        handleViewSurvey2,
        psychButton,
        handlePopUpSurvey,
        handleTimeInOut,
        handleDocument,
        documentOpen,
      }}
    >
      {props.children}
    </RequestContext.Provider>
  );
};
