import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Typography,
  TextField,
  InputAdornment,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import { Download } from "@mui/icons-material";

import Checkbox from "@mui/material/Checkbox";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { RangeMenuItem } from "../../../lib/reports/perUser";
import { PerClientV5Context } from ".";
import useDebounce from "../../../hooks/customHooks/debounce";
import { isAfter, isBefore } from "date-fns";
import { toast } from "react-toastify";
import FilterHeader from "../../../component/Reports/FilterHeader";

const PerClientFilter = ({ handleRangeChange, handleRefresh }) => {
  const {
    setIsExportModalOpen,
    isWeekends,
    setIsWeekends,
    isInactiveUsers,
    setIsInactiveUsers,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    search,
    setSearch,
    range,
    setRange,
    isFetching,
    setPage,
  } = useContext(PerClientV5Context);

  const [searchString, setSearchString] = useState(search);
  const debounceValue = useDebounce(searchString, 700);

  useEffect(() => {
    setPage(1);
    setSearch(debounceValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue]);

  return (
    <FilterHeader>
      <FormControl size={"small"} sx={{ margin: "5px" }}>
        <InputLabel id="range-label">Range</InputLabel>
        <Select
          labelId="range-label"
          id="demo-simple-select"
          size="small"
          value={range}
          onChange={handleRangeChange}
          label="Range"
        >
          {RangeMenuItem.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={startDate}
          label={"Start"}
          onChange={(newValue) => {
            if (isAfter(newValue, new Date(endDate))) {
              toast.error("Start date should not be after end date.");
              return;
            }
            setStartDate(newValue);
            setRange("Custom");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              sx={{
                width: "10em",
                margin: "5px",
              }}
            />
          )}
          className="datepicker"
          inputProps={{ readOnly: true }}
        />
        <DatePicker
          value={endDate}
          label={"End"}
          onChange={(newValue) => {
            if (isBefore(newValue, new Date(startDate))) {
              toast.error("End date should not be before start date.");
              return;
            }
            setEndDate(newValue);
            setRange("Custom");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              sx={{
                width: "10em",
                margin: "5px",
              }}
            />
          )}
          className="datepicker"
          inputProps={{ readOnly: true }}
        />
      </LocalizationProvider>
      <TextField
        value={searchString}
        onChange={(e) => {
          if (isFetching) return;
          setSearchString(e.target.value);
        }}
        sx={{ margin: "5px" }}
        size="small"
        placeholder="Search"
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              {" "}
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <FormControlLabel
        label="Weekends"
        control={
          <Checkbox
            label={"Weekends"}
            value={isWeekends}
            onChange={() => setIsWeekends(!isWeekends)}
            defaultChecked
          />
        }
      />
      <FormControlLabel
        label="Inactive Users"
        control={
          <Checkbox
            value={isInactiveUsers}
            onChange={() => setIsInactiveUsers(!isInactiveUsers)}
            defaultChecked
          />
        }
      />
      <Button
        sx={{ marginX: "5px", backgroundColor: "#00c292" }}
        startIcon={<Download />}
        variant="contained"
        onClick={() => setIsExportModalOpen(true)}
      >
        <Typography sx={{ fontSize: "0.78rem" }}>Export</Typography>
      </Button>
      <Button
        sx={{ marginX: "5px", backgroundColor: "#00c292" }}
        onClick={() => handleRefresh()}
        startIcon={<RefreshIcon />}
        variant="contained"
      >
        <Typography sx={{ fontSize: "0.78rem" }}>Refresh</Typography>
      </Button>
    </FilterHeader>
  );
};

export default PerClientFilter;
