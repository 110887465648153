import { Fragment, useState } from "react";

import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import StyledTableCell from "../../../../UserApplicationMgmt/UserApplications/tableComponents/StyledTableCell";
import CollapsedTableCell from "../CollapsedTableCell";
import PerTimesheetTable from "../PerTimesheetTable";

const PerProjectTableRow = ({ activityId, userId, project, state }) => {
  const {
    name: projectCode,
    description,
    efforts,
    row_id: timesheetId,
  } = project;

  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandCollapse = () => {
    setIsExpanded((previousState) => !previousState);
  };

  return (
    <Fragment>
      <TableRow hover>
        {/* Expand/Collapse button */}
        <StyledTableCell>
          <IconButton onClick={handleExpandCollapse}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>

        {/* Project code */}
        <StyledTableCell>{projectCode}</StyledTableCell>

        {/* Description */}
        <StyledTableCell>{description}</StyledTableCell>

        {/* Total efforts */}
        <StyledTableCell>{efforts}</StyledTableCell>
      </TableRow>

      <TableRow>
        <CollapsedTableCell colSpan={4}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <PerTimesheetTable
              activityId={activityId}
              userId={userId}
              timesheetId={timesheetId}
              state={state}
            />
          </Collapse>
        </CollapsedTableCell>
      </TableRow>
    </Fragment>
  );
};

export default PerProjectTableRow;
