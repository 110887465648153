import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";

const Skills = ({ skillSet }) => {
  return (
    <Grid container sx={{ marginTop: "10px" }}>
      {skillSet.map((ss, index) => (
        <Grid item xs={4} lg={4} key={index}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ position: "relative", display: "inline-flex" }}>
              <CircularProgress
                variant="determinate"
                value={ss.value * 10}
                sx={{
                  color: (theme) =>
                    // theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
                    ss.has_skill?.color,
                  animationDuration: "550ms",
                  left: 0,
                  [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: "round",
                  },
                }}
                size={40}
                thickness={4}
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="text.secondary"
                >
                  {`${ss.value * 10}%`}
                </Typography>
              </Box>
            </Box>

            <Typography
              variant="caption"
              sx={{
                fontSize: "0. 7rem",
                fontWeight: "500",
              }}
            >
              {ss.has_skill?.name}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default Skills;
