export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export const PerClientTableHeader = [
  {
    label: "Client",
    key: "name",
    isSortable: true,
  },
  {
    label: "Description",
    key: "description",
    isSortable: true,
  },
  {
    label: "Total Effort",
    key: "efforts",
    isSortable: true,
    align: "right",
    type: "number",
  },
];

export const FirstCollapseHeader = [
  {
    label: "Project",
    key: "name",
    isSortable: true,
  },
  {
    label: "Description",
    key: "description",
    isSortable: true,
  },
  {
    label: "Total Effort",
    key: "efforts",
    isSortable: true,
    align: "right",
    type: "number",
  },
];

export const SecondCollapseHeader = [
  {
    label: "User",
    key: "full_name",
    parentKey: "time_employee",
    isSortable: true,
  },
  {
    label: "Total Effort",
    key: "hrs",
    isSortable: true,
    align: "right",
    type: "number",
  },
];

export const ThirdCollapseHeader = [
  {
    label: "Date",
    key: "created_at",
    isSortable: true,
  },
  {
    label: "Activity",
    key: "activity",
    isSortable: true,
  },
  {
    label: "Milestone",
    key: "milestone",
    isSortable: true,
  },
  {
    label: "Details",
    key: "comment",
    isSortable: true,
  },
  {
    label: "Total Effort",
    key: "hours",
    isSortable: true,
    align: "right",
    type: "number",
  },
];
