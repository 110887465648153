import apiService from "../axios";
import moment from "moment";

export const generateDownloadableActivityReport = async (payload) => {
  try {
    const response = await apiService.post(
      `v1/timesheet/export/generate-excel-code-user-report`,
      payload,
      {
        "Content-Type": "application/json",
        body: JSON.stringify(payload),
        responseType: "blob",
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    const fileName =
      moment(payload.fromDate).format("ll") +
      " - " +
      moment(payload.toDate).format("ll") +
      " Breakdown Per Code";
    const ext = payload.type === "excel" ? ".xlsx" : ".csv";
    link.href = url;
    link.setAttribute("download", fileName + ext);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

    return true;
  } catch (error) {
    throw error;
  }
};

/* v5 */
export const getReportPerCodeReport = async ({
  page_size = 10,
  fromDate,
  toDate,
  weekends = true,
  inActives = true,
  search,
  page = 1,
}) => {
  try {
    let response = await apiService.get(
      `v5/timesheet/get-per-code-report?page_size=${page_size}&fromDate=${fromDate}&toDate=${toDate}&weekEnd=${weekends}&inActive=${inActives}&search=${search}&page=${page}`,
      {
        Accept: `application/json`,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getReportPerCodeReportStaff = async ({
  id = "",
  page_size = 10,
  fromDate,
  toDate,
  page = 1,
  weekends = true,
  inActives = true,
}) => {
  try {
    let response = await apiService.get(
      `v5/timesheet/get-per-code-report/staff/${id}?page_size=${page_size}&fromDate=${fromDate}&toDate=${toDate}&page=${page}&weekEnd=${weekends}&inActive=${inActives}`,
      {
        Accept: `application/json`,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getReportPerCodeReportActivity = async ({
  id = "",
  id2 = "",
  page_size = 10,
  fromDate,
  toDate,
  page = 1,
  weekends = true,
  inActives = true,
}) => {
  try {
    //activity/{code}/{empId}
    let response = await apiService.get(
      `/v5/timesheet/get-per-code-report/activity/${id2}/${id}?page_size=${page_size}&fromDate=${fromDate}&toDate=${toDate}&page=${page}&weekEnd=${weekends}&inActive=${inActives}`,
      {
        Accept: `application/json`,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getReportPerCodeReportTimesheet = async ({
  id = "",
  id2 = "",
  id3 = "",
  page_size = 10,
  fromDate,
  toDate,
  page = 1,
  weekends = true,
  inActives = true,
}) => {
  try {
    //timesheet/{code}/{empId}/{actId}
    let response = await apiService.get(
      `v5/timesheet/get-per-code-report/timesheet/${id3}/${id}/${id2}?page_size=${page_size}&fromDate=${fromDate}&toDate=${toDate}&page=${page}&weekEnd=${weekends}&inActive=${inActives}`,
      {
        Accept: `application/json`,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
