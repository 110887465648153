import apiService from "../axios";
import { APP_TYPE } from "../../lib/UserAppMgmt/UserApplications";
import {
  pickupAndPopulateApplicationsData,
  getApplicationActionEndpoint,
} from "../../helper/UserApplicationMgmt/UserApplications";

export const getEmployeeApplications = async (
  page = 1,
  pageSize = 10,
  search = "",
  typeQueryObj = { applicationType: APP_TYPE.leave.id, queryType: "" }
) => {
  const { applicationType, queryType } = typeQueryObj;
  const type = queryType === "all" ? "" : queryType;

  try {
    const {
      data: { totalApplicationCounts },
    } = await apiService.post("v5/leave/get-application-counts");

    const { data: responseData } = await apiService.get(
      `v5/${
        APP_TYPE[applicationType].uri === "document" ? "applications" : "leave"
      }/${
        APP_TYPE[applicationType].uri === "document"
          ? "get-pending-document"
          : APP_TYPE[applicationType].uri
      }?page=${page}&page_size=${pageSize}&search=${search}&type=${type}`
    );

    const newResponseData = {
      ...responseData.data,
      types: responseData?.types,
      totalApplicationCounts,
    };

    newResponseData.data = pickupAndPopulateApplicationsData(
      responseData.data.data
    );

    return newResponseData;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const submitApplicationAction = async ({
  actionType,
  applicationType,
  data,
}) => {
  // ? For v1 api
  // const baseURL = process.env.USER_APP_URL
  //   ? process.env.USER_APP_URL + "/api/"
  //   : window.location.toString().includes("stg")
  //   ? "https://hris.stg.fligno.com/api/"
  //   : window.location.toString().includes("dev")
  //   ? "https://hris.stg.fligno.com/api/"
  //   : "https://hris.fligno.com/api/";

  const endpoint = getApplicationActionEndpoint(actionType, applicationType);
  try {
    // ? For v1 api
    // await apiService.post(endpoint, { ...data, api: true }, { baseURL });

    await apiService.post(endpoint, { ...data, api: true });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getWorkplaceAttachmentImage = async (
  applicationId,
  employeeId,
  type
) => {
  try {
    if (type === "forgotTimeInOut") {
      const response = await apiService.post(
        "v5/employee/application/view-image-forgot-timeinout",
        {
          forgotTimeInOut_id: applicationId,
          employee_id: employeeId,
        }
      );
      return response.data.images;
    } else {
      const response = await apiService.post(
        "v5/employee/application/view-workplace-wfh",
        {
          wfh_id: applicationId,
          employee_id: employeeId,
        }
      );
      return response.data.images;
    }
  } catch (error) {
    throw error;
  }
};
