import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const FilterBarContainer = styled(Box)(() => ({
  width: "100%",
  height: "min-content",
  padding: "16px",
  display: "flex",
  flexWrap: "wrap",
  gap: "14px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  boxShadow: "0 2px 5px rgba(0,0,0,0.05)",
}));

export default FilterBarContainer;
