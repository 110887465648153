import React, { useContext } from "react";
import {
  Backdrop,
  Modal,
  Fade,
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
} from "@mui/material";
import { PerUserV5Context } from ".";
import { generateDownloadableActivityReport } from "../../../api/peruser";
import moment from "moment";

const ExportModal = () => {
  const {
    isExportModalOpen,
    setIsExportModalOpen,
    isWeekends,
    isInactiveUsers,
    startDate,
    endDate,
    search,
  } = useContext(PerUserV5Context);

  const [optionSelected, setOptionSelected] = React.useState("csv");
  const [beyond2Months, setBeyond2Months] = React.useState(null);

  const handleClose = () => {
    setIsExportModalOpen(!isExportModalOpen);
  };

  const handleCloseBeyond2Mon = () => {
    setIsExportModalOpen(false);
    setTimeout(setBeyond2Months(null), 600);
  };

  const handleExports = async () => {
    const payload = {
      fromDate: moment(startDate).format("YYYY-MM-DD"),
      toDate: moment(endDate).format("YYYY-MM-DD"),
      inActives: isInactiveUsers,
      weekends: isWeekends,
      type: optionSelected,
      api: true,
      search: search,
    };
    const exportResponse = await generateDownloadableActivityReport(payload);
    if (exportResponse) {
      setBeyond2Months(exportResponse);
    } else {
      handleClose();
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  // useEffect(() => {
  //   const numOfDaysInMillisec =
  //     new Date(endDate).getTime() - new Date(startDate).getTime();
  //   const day = 1000 * 60 * 60 * 24;
  //   const numberOfDays = Math.round(numOfDaysInMillisec / day);
  //   if (isExportModalOpen && numberOfDays >= 61) {
  //     setBeyond2Months(true);
  //   } else {
  //     setTimeout(() => setBeyond2Months(false), 500);
  //   }
  // }, [endDate, isExportModalOpen, startDate]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isExportModalOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isExportModalOpen}>
        <Box sx={style}>
          {!beyond2Months ? (
            <>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Export As
              </Typography>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="csv"
                  name="controlled-radio-buttons-group"
                  value={optionSelected}
                  onChange={(e) => setOptionSelected(e.target.value)}
                >
                  <FormControlLabel
                    value="csv"
                    control={<Radio />}
                    label="CSV"
                  />

                  <FormControlLabel
                    value="excel"
                    control={<Radio />}
                    label="Excel"
                  />
                </RadioGroup>
              </FormControl>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  size="small"
                  sx={{ marginX: "5px" }}
                  variant="contained"
                  onClick={() => setIsExportModalOpen(false)}
                >
                  <Typography sx={{ fontSize: "0.78rem" }}>CANCEL</Typography>
                </Button>
                <Button
                  sx={{ marginX: "5px", backgroundColor: "#00c292" }}
                  size="small"
                  onClick={handleExports}
                  variant="contained"
                >
                  <Typography sx={{ fontSize: "0.78rem" }}>EXPORT</Typography>
                </Button>
              </div>
            </>
          ) : (
            <>
              <div style={{ textAlign: "center", fontWeight: "bold" }}>
                {beyond2Months}
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  size="small"
                  onClick={handleCloseBeyond2Mon}
                  variant="contained"
                >
                  <Typography sx={{ fontSize: "0.78rem" }}>Okay</Typography>
                </Button>
              </div>
            </>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default ExportModal;
