import { useState, Fragment } from "react";

import TableRow from "@mui/material/TableRow";

import { ACTION_TYPE } from "../../../../lib/UserAppMgmt/UserApplications";
import { useFetchWorkplaceAttachment } from "../../../../hooks/react-query/UserApplications";
import {
  formatDate,
  getApplicationType,
} from "../../../../helper/UserApplicationMgmt/UserApplications";
import WorkplaceImageModal from "../modalComponents/WorkplaceImageModal";
import ApplicationActionModal from "../modalComponents/ApplicationActionModal";
import NameCell from "./cellComponents/NameCell";
import DateFiledCell from "./cellComponents/DateFiledCell";
import PurposeCell from "./cellComponents/PurposeCell";
import WorkFromHomeCell from "./cellComponents/WorkFromHomeCell";
import StatusCell from "./cellComponents/StatusCell";
import ActionsCell from "./cellComponents/ActionsCell";

const DetailsTableRow = ({ details, type }) => {
  const {
    dateFiled,
    purpose,
    workplace,
    status,
    id,
    name,
    leaveType,
    startDate,
    endDate,
    duration,
    reason,
    location,
    wfh_template,
    attr,
    forgotType,
    time,
    date,
    employeeId,
    tag: overTimeType,
  } = details;
  const [showImageModal, setShowImageModal] = useState(false);
  const [showActionModal, setShowActionModal] = useState(false);
  const [action, setAction] = useState("");

  const { isOvertime, isWfh, isDocument } = getApplicationType(type);

  const formattedDateFiled = formatDate(dateFiled);
  const formattedStartDate = isDocument
    ? null
    : formatDate(startDate, isOvertime);
  const formattedEndDate = isDocument ? null : formatDate(endDate, isOvertime);

  const {
    data,
    refetch: fetchAttachment,
    status: fetchStatus,
    isError,
  } = useFetchWorkplaceAttachment(id, employeeId, type);

  const actionDetailsProps = {
    type,
    name,
    date,
    time,
    forgotType,
    location,
    from: startDate,
    to: endDate,
    id,
  };

  const isAttachmentFetching = !isError && fetchStatus !== "success";
  const leaveCredits = attr ? JSON.parse(attr).credits_left : null;

  const toggleImageModalHandler = () => {
    !showImageModal && fetchAttachment();
    setShowImageModal((prev) => !prev);
  };

  const closeModalHandler = () => {
    setShowActionModal(false);
  };

  const approveActionHandler = () => {
    setShowActionModal(true);
    setAction(ACTION_TYPE.APPROVE);
  };

  const denyActionHandler = () => {
    setShowActionModal(true);
    setAction(ACTION_TYPE.DENY);
  };

  return (
    <Fragment>
      <TableRow hover>
        {/* Name */}
        <NameCell
          type={type}
          name={isDocument ? details.emp : name}
          leaveType={leaveType}
          leaveCredits={leaveCredits}
          overTimeType={overTimeType}
        />

        {/* Date Filed */}
        <DateFiledCell
          type={type}
          formattedDateFiled={formattedDateFiled}
          formattedStartDate={formattedStartDate}
          formattedEndDate={formattedEndDate}
          duration={duration}
          forgotType={forgotType}
          startDate={startDate}
          endDate={endDate}
          location={location}
          time={time}
          toggleImageModalHandler={toggleImageModalHandler}
        />

        {/* Purpose */}
        <PurposeCell
          type={type}
          purpose={purpose || reason || details.document}
        />

        {isDocument && (
          <PurposeCell type={type} purpose={details.description} />
        )}

        {/* (WFH) Workplace */}
        {isWfh && (
          <WorkFromHomeCell
            workplace={workplace || location}
            wfh_template={wfh_template}
            toggleImageModalHandler={toggleImageModalHandler}
          />
        )}

        {/* Status */}
        <StatusCell status={status} type={type} />

        {/* Actions */}
        <ActionsCell
          approveActionHandler={approveActionHandler}
          denyActionHandler={denyActionHandler}
        />
      </TableRow>

      {/* IMAGE ATTACHMENT MODAL */}
      <WorkplaceImageModal
        isWfh={isWfh}
        open={showImageModal}
        onClose={toggleImageModalHandler}
        id={id}
        attachment={data}
        isLoading={isAttachmentFetching}
      />

      {/* APPLICATION ACTION MODAL */}

      <ApplicationActionModal
        open={showActionModal}
        onClose={closeModalHandler}
        details={actionDetailsProps}
        action={action}
      />
    </Fragment>
  );
};

export default DetailsTableRow;
