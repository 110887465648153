import React from "react";
import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";

const DashboardEmptyData = () => {
  return (
    <Box
      component={Paper}
      sx={{
        borderRadius: "10px",
        height: "103px",
      }}
    >
      <Typography sx={{ textAlign: "center", width: "100%", pt: 5 }}>
        No Records Found.
      </Typography>
    </Box>
  );
};

export default DashboardEmptyData;
