import React from "react";
import { Grid, Box, Paper, Typography, Slider } from "@mui/material";
import moment from "moment";

import { capitalizeString } from "../../helper/UserInformation";

const STYLES = {
  SEPARATOR: {
    LINE: {
      borderBottom: 2,
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
      borderStyle: "solid",
      borderColor: "#808080",
    },
  },
  PROFILE: {
    LABEL: {
      fontSize: ".9rem",
      marginLeft: "2rem",
      fontWeight: "700",
    },
  },
};
const GridView = ({ gridData, schedule, department }) => {
  const userID = JSON.parse(localStorage.getItem("user"))?.employee.id;
  const skills = gridData.Skills.map((skill) =>
    skill.emp_skill.find((emp) => emp.employee === userID)
      ? {
          label: skill.name,
          value: skill.emp_skill.find((emp) => emp.employee === userID)?.value,
          typename: "skill",
          color: skill.color,
        }
      : {}
  );
  const EmployeeProfile = [
    {
      title: "Personal Information",
      profile: [
        {
          label: "Work Email: ",
          value: gridData?.email_work,
          typename: "name",
        },
        { label: "First Name: ", value: gridData?.firstname, typename: "name" },
        { label: "Last Name: ", value: gridData?.lastname, typename: "name" },
        { label: "Extension: ", value: gridData?.extension, typename: "name" },
        { label: "Nickname: ", value: gridData?.nickname, typename: "name" },
        {
          label: "Gender: ",
          value: gridData?.gender ? capitalizeString(gridData.gender) : "",
          typename: "name",
        },
        { label: "Birthdate: ", value: gridData?.dob, typename: "date" },
        {
          label: "Marital Status: ",
          value: gridData?.marital ? capitalizeString(gridData.marital) : "",
          typename: "name",
        },
        {
          label: "Department: ",
          value: gridData?.dept,
          typename: "department",
        },
        { label: "Mobile Number: ", value: gridData?.mobile, typename: "name" },
        { label: "Address: ", value: gridData?.address, typename: "name" },
        {
          label: "Personal Email: ",
          value: gridData?.email_personal,
          typename: "name",
        },
      ],
    },
    {
      title: "Skills",
      profile: skills,
    },
    {
      title: "Employee Information",
      profile: [
        {
          label: "Employee ID: ",
          value: gridData?.emp_company_id,
          typename: "name",
        },
        {
          label: "Date Hired: ",
          value: gridData?.details?.date_hired,
          typename: "date",
        },
        {
          label: "Employment Status: ",
          value: gridData?.details?.employment_status
            ? capitalizeString(gridData.details.employment_status)
            : "",
          typename: "name",
        },
        {
          label: "Type of Schedule: ",
          value: gridData?.details?.schedule,
          typename: "name",
        },
        {
          label: "Work Schedule: ",
          value: gridData?.details?.sched_id,
          typename: "schedule",
        },
        {
          label: "WFH maximum days per week: ",
          value: gridData?.details?.wfh_days,
          typename: "name",
        },
      ],
    },
    {
      title: "Messaging App",
      profile: [
        { label: "Skype: ", value: gridData?.details?.skype, typename: "name" },
        { label: "Slack: ", value: gridData?.details?.slack, typename: "name" },
      ],
    },
  ];

  // const handleChangeSkill = (ind) => {
  //   skill[index].value
  //   setSkill()
  // }
  return (
    <>
      {EmployeeProfile.map((row, index) => {
        return (
          <React.Fragment key={index}>
            <Paper
              ml={5}
              sx={{
                margin: 2,
                borderRadius: 5,
              }}
            >
              <Box p={4} mb={1} sx={STYLES.SEPARATOR.LINE}>
                <Typography variant="h5" sx={{ fontWeight: 700 }}>
                  {row.title}
                </Typography>
              </Box>
              <Box>
                <Grid container spacing={0}>
                  {row.profile.map((row, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Grid item xs={5}>
                          <Box p={1}>
                            <Box p={1} ml={1} sx={STYLES.PROFILE.LABEL}>
                              {row.label}
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box p={1}>
                            <Box p={1}>
                              {row.typename === "department" &&
                                department &&
                                department.length > 0 &&
                                department.find((e) => {
                                  return e.value.toString() === row.value;
                                })?.label}

                              {row.typename === "schedule" &&
                                schedule &&
                                schedule.length > 0 &&
                                schedule.find((e) => {
                                  return e.value === row.value;
                                })?.label}
                              {row.typename === "date" &&
                                row.value &&
                                moment(row.value).format("MM/DD/YYYY") !==
                                  "Invalid date" &&
                                moment(row.value).format("MM/DD/YYYY")}

                              {row.typename === "name" && row.value}
                              {row.typename === "skill" && (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Slider
                                    value={row.value}
                                    step={1}
                                    marks
                                    min={0}
                                    max={10}
                                    color="primary"
                                    sx={{ color: row.color }}
                                    valueLabelDisplay={"auto"}
                                    className="time-slider"
                                    size="small"
                                  />
                                  <div
                                    style={{
                                      marginLeft: "18px",
                                      textAlign: "center",
                                      fontWeight: "bold",
                                      fontSize: ".75rem",
                                      paddingTop: "3px",
                                      color: "whitesmoke",
                                      width: "25px",
                                      height: "25px",
                                      borderRadius: "100%",
                                      backgroundColor: row.color,
                                    }}
                                  >
                                    {row.value}
                                  </div>
                                </div>
                              )}
                            </Box>
                          </Box>
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                </Grid>
              </Box>
            </Paper>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default GridView;
