import Typography from "@mui/material/Typography";

const AllPendingTimesheetTitle = () => {
  return (
    <Typography
      variant="h4"
      sx={{ fontSize: "clamp(20px, 5vw, 28px)", color: "#21293C" }}
    >
      All Pending Entries
    </Typography>
  );
};

export default AllPendingTimesheetTitle;
