import React, { useState } from "react";
import {
  Backdrop,
  Modal,
  Fade,
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
} from "@mui/material";

const ExportModal = ({ isExportModalOpen, handleClose, handleExport }) => {
  const [optionSelected, setOptionSelected] = useState();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isExportModalOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isExportModalOpen}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Export As
          </Typography>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="csv"
              name="controlled-radio-buttons-group"
              value={optionSelected}
              onChange={(e) => setOptionSelected(e.target.value)}
            >
              <FormControlLabel value="csv" control={<Radio />} label="CSV" />

              <FormControlLabel value="pdf" control={<Radio />} label="PDF" />
            </RadioGroup>
          </FormControl>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              size="small"
              sx={{ marginX: "5px" }}
              variant="contained"
              onClick={handleClose}
            >
              <Typography sx={{ fontSize: "0.78rem" }}>CANCEL</Typography>
            </Button>
            <Button
              sx={{ marginX: "5px", backgroundColor: "#00c292" }}
              size="small"
              onClick={(e) => handleExport(optionSelected)}
              variant="contained"
            >
              <Typography sx={{ fontSize: "0.78rem" }}>EXPORT</Typography>
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ExportModal;
