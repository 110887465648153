import { Typography, Box, Tooltip } from "@mui/material";
import { useState, useCallback, useEffect } from "react";
import Table from "../../component/TimesheetActivity/Table";
import { TableHeaderActivity } from "../../lib/timesheetActivity/TableSettings";
import hooks from "../../hooks/react-query/TimesheetActivity";
import Row from "./Row";
import {
  addActivity,
  deleteActivity,
  editActivity,
} from "../../api/timesheetActivity";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import Confirm from "../../component/TimesheetActivity/Confirm";
import EditRow from "./EditRow";
import ActivityRow from "./ActivityRow";
import TableOptions from "./TableOptions";
import AddActivity from "./AddActivity";
import { isJson } from "../../helper/TimesheetProjectActivity";

const Schedule = () => {
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("");
  const [activity, setActivity] = useState();
  const [department, setDepartment] = useState();
  const [departmentFilter, setDepartmentFilter] = useState();
  const [offset, setOffset] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [fetch, toggleFetch] = useState(true);
  const [total, setTotal] = useState(0);
  const [add, showAdd] = useState(false);
  const [errors, setErrors] = useState("");
  const [confirm, showConfirm] = useState(false);
  const [id, setId] = useState();
  const [edit, setEdit] = useState();
  const [status, setStatus] = useState(1);

  const [form, setForm] = useState();

  const { data: activities, isFetching: activitiesFetching } =
    hooks.useActivity(
      getFetchUrl(),
      keyword,
      status,
      filter,
      limit,
      offset,
      fetch
    );

  const { data: departments } = hooks.useDepartment(getFetchUrl());

  const initActivity = useCallback(() => {
    if (activities) {
      setActivity(activities.data);
      setTotal(activities.total);
    }
  }, [activities]);

  const initDepartment = useCallback(() => {
    if (departments) {
      const options = departments.map(function (department) {
        return {
          label: department.name,
          value: department.id,
          short_name: department.short_name,
          status: department.status,
        };
      });

      const filterOptions = options.filter((option) => option.status === 1);
      setDepartment(options);
      setDepartmentFilter(filterOptions);
    } else {
      var defaultOption = [{ label: "All", value: 0 }];
      setDepartment(defaultOption);
      setDepartmentFilter(defaultOption);
    }
  }, [departments]);

  useEffect(() => {
    initDepartment();
  }, [initDepartment]);

  useEffect(() => {
    initActivity();
  }, [initActivity]);

  function getFetchUrl() {
    return (
      "?search=" +
      keyword +
      "&status=" +
      status +
      "&filter=" +
      filter +
      "&page_size=" +
      limit +
      "&page=" +
      offset
    );
  }

  const removeActivity = useMutation((formData) => deleteActivity(formData), {
    onSuccess: async (response) => {
      toast.success("Activity removed.");
      toggleFetch(!fetch);
      showConfirm(false);
    },
    onError: (response) => {
      toast.error(response.message);
    },
  });

  const createActivity = useMutation((formData) => addActivity(formData), {
    onSuccess: async (response) => {
      toast.success("Activity successfully added.");
      showAdd(false);
      toggleFetch(!fetch);
      setErrors({});
      setForm([]);
    },
    onError: (response) => {
      setErrors(response.response.data || {});
    },
  });

  const updateActivity = useMutation((formData) => editActivity(formData), {
    onSuccess: async (response) => {
      toast.success("Record Updated.");
      setEdit();
      toggleFetch(!fetch);
      setErrors({});
    },
    onError: (response) => {
      setErrors(response.response.data || {});
    },
  });

  const options = {
    department: department,
    status: [
      { label: "Active", value: 1 },
      { label: "Inactive", value: 0 },
    ],
  };

  const formFieldsActivity = [
    {
      label: "Name",
      payload: "name",
      type: "text",
      value: "",
      helperText: errors.name,
    },
    {
      label: "Description",
      payload: "description",
      type: "text",
      value: "",
      helperText: errors.description,
    },
    {
      label: "Department",
      payload: "key",
      type: "chipselect",
      options: departmentFilter,
      options_edit: departmentFilter,
      value: "",
      helperText: errors.key,
    },
    {
      label: "Status",
      payload: "status",
      type: "select",
      options: [
        { label: "Active", value: 1 },
        { label: "Inactive", value: 0 },
      ],
      options_edit: [
        { label: "Active", value: 1 },
        { label: "Inactive", value: 0 },
      ],
      value: "",
      helperText: errors.status,
    },
  ];

  const handleConfirm = (payload) => {
    createActivity.mutate(payload);
  };

  const handleCancelAdd = () => {
    showAdd(false);
    setForm([]);
    setErrors({});
  };

  const handleDepartment = (department) => {
    setOffset(1);
    setFilter(department);
  };

  const handleCancelEdit = () => {
    setEdit();
    setErrors({});
  };

  const handleDelete = (id) => {
    setId({ id: id });
    showConfirm(true);
  };

  const handleConfirmDelete = () => {
    removeActivity.mutate(id);
  };

  const handleEdit = (id) => {
    setEdit(id);
  };

  const handleSave = (payload) => {
    if (isJson(payload.key)) {
      payload = { ...payload, key: JSON.parse(payload.key) };
    }
    updateActivity.mutate(payload);
  };

  const Buttons = [];
  const hiddenRows = [];

  return (
    <Box sx={{ margin: "0px", pr: "25px" }}>
      <Confirm
        open={confirm}
        handleConfirm={handleConfirmDelete}
        handleClose={(e) => showConfirm(false)}
        title="Are you sure you want to delete this record?"
        submitting={removeActivity.isLoading}
      />
      <AddActivity
        formFields={formFieldsActivity}
        handleConfirm={handleConfirm}
        open={add}
        handleClose={(e) => handleCancelAdd()}
        submitting={createActivity.isLoading}
        form={form}
        setForm={setForm}
      ></AddActivity>
      <Box
        sx={{
          pt: "5px",
          pl: "25px",
          cursor: "context-menu",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Tooltip
          title={"Manage companys project activity"}
          placement={"right"}
          arrow
        >
          <Typography variant="h4">Project Activity</Typography>
        </Tooltip>
      </Box>
      <Box sx={{ pt: "5px", pl: "25px" }}>
        <Table
          header={TableHeaderActivity}
          Row={
            <Row
              data={activity}
              header={TableHeaderActivity}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              isFetching={activitiesFetching}
              hiddenRows={hiddenRows}
              EditRow={EditRow}
              Row={ActivityRow}
              options={options}
              edit={edit}
              handleCancel={(e) => handleCancelEdit()}
              handleSave={handleSave}
              formFields={formFieldsActivity}
            />
          }
          limit={limit}
          setLimit={setLimit}
          offset={offset}
          setOffset={setOffset}
          setKeyword={setKeyword}
          total={total}
          Buttons={Buttons}
          onPagination={(e) => handleCancelEdit()}
          tableOptions={
            <TableOptions
              handleClick={(e) => showAdd(!add)}
              options={departmentFilter}
              handleDepartment={handleDepartment}
              handleActive={(e) => {
                setStatus(status === 1 ? "" : 1);
                setOffset(1);
              }}
            />
          }
        />
      </Box>
    </Box>
  );
};
export default Schedule;
