import apiService from "../axios";

export const getEmployeeSchedule = async (payload) => {
  try {
    const response = await apiService.post(
      `v1/empsched/get-employee-sched`,
      {
        ...payload,
        api: true,
      },
      {
        Accept: `application/json`,
        "Content-Type": `multipart/form-data`,
        body: JSON.stringify(payload),
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addEmployeeSchedule = async (payload) => {
  return apiService.post(
    "v1/empsched/new-employee-sched",
    { ...payload, api: true },
    {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(payload),
    }
  );
};

export const deleteEmployeeSchedule = async (payload) => {
  return apiService.post(
    "v1/empsched/delete-employee-sched",
    {
      ...payload,
      api: true,
    },
    {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(payload),
    }
  );
};

export const updateEmployeeSchedule = async (payload) => {
  return apiService.post(
    "v1/empsched/update-employee-sched",
    {
      ...payload,
      api: true,
    },
    {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(payload),
    }
  );
};
