import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import PaletteRoundedIcon from "@mui/icons-material/PaletteRounded";

const PopperComponent = ({ children }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const popOpen = Boolean(anchorEl);

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        sx={{
          textTransform: "none",
          minWidth: "142px",
        }}
        variant="contained"
        disableElevation
        fullWidth
        startIcon={<PaletteRoundedIcon />}
        onClick={handleClick}
      >
        Color palette
      </Button>
      <Popper
        placement="bottom-end"
        open={popOpen}
        anchorEl={anchorEl}
        className="poper-pop"
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box>{children}</Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default PopperComponent;
