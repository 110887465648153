import React, { useState } from "react";
import {
  Backdrop,
  Modal,
  Fade,
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
} from "@mui/material";

import { toast } from "react-toastify";
import { ExportPayRoll } from "../../api/reports/payroll";
import { dateFormat } from "../../helper/Reports/Payroll";
import { getPayrollReportAPI } from "../../api/reports/payroll";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ExportModal = ({ openModal, handleClose, data }) => {
  const [optionSelected, setOptionSelected] = useState("csv");
  const [loading, setLoading] = useState(false);

  const handleExports = async () => {
    setLoading(true);
    const res = await getPayrollReportAPI(
      dateFormat(data.fromDate),
      dateFormat(data.toDate),
      dateFormat(data.payrollFromDate),
      dateFormat(data.payrollToDate),
      data.total,
      1,
      data.debounceValue
    );
    if (res) {
      const response = await ExportPayRoll({
        ...data,
        data: res?.data?.data,
        type: optionSelected,
      });
      if (!response) {
        toast.error("There was a probleming exporting the file.");
        return;
      }
    }
    setLoading(false);
    handleClose();
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Export As
          </Typography>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="csv"
              name="controlled-radio-buttons-group"
              value={optionSelected}
              onChange={(e) => setOptionSelected(e.target.value)}
            >
              <FormControlLabel value="csv" control={<Radio />} label="CSV" />

              <FormControlLabel
                value="excel"
                control={<Radio />}
                label="Excel"
              />
            </RadioGroup>
          </FormControl>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              size="small"
              sx={{ marginX: "5px" }}
              variant="contained"
              onClick={handleClose}
            >
              <Typography sx={{ fontSize: "0.78rem" }}>CANCEL</Typography>
            </Button>
            <Button
              sx={{ marginX: "5px", backgroundColor: "#00c292" }}
              size="small"
              onClick={handleExports}
              variant="contained"
              disabled={loading}
            >
              <Typography sx={{ fontSize: "0.78rem" }}>EXPORT</Typography>
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ExportModal;
