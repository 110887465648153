import React from "react";
import { TableRow, TableCell, Typography } from "@mui/material";

const ScheduleRow = ({
  header,
  row,
  handleEdit,
  handleDelete,
  options,
  edit,
  formFields,
}) => {
  return (
    <TableRow sx={{ backgroundColor: edit && "#FFF" }}>
      {header &&
        header.map((column, index) => (
          <TableCell key={index} scope="row">
            {column.type === "text" && (
              <Typography
                sx={{
                  py: 2,
                  color: edit && "#B2BEB5",
                  fontSize: 14,
                  wordWrap: "break-word",
                  whitepace: "normal",
                  textTransform: "none",
                }}
              >
                {row[column.data]}
              </Typography>
            )}

            {column.type === "select" && (
              <Typography
                sx={{
                  fontSize: 14,
                  color: edit && "#B2BEB5",
                  wordWrap: "break-word",
                  whitepace: "normal",
                  textTransform: "none",
                }}
              >
                {
                  options[column.options].find((option) => {
                    return option.value === row[column.data];
                  })?.label
                }
              </Typography>
            )}
          </TableCell>
        ))}
    </TableRow>
  );
};

export default ScheduleRow;
