import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function DocumentsAlert({ handleClose, openALert }) {
  return (
    <div>
      <Dialog
        open={openALert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ margin: "5px" }}>
          <DialogTitle id="alert-dialog-title" sx={{ fontSize: "1.1rem" }}>
            {"The COE will be claimed through HR every Friday of the week."}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ fontSize: ".90rem" }}
            >
              COE requests submitted from Monday to Wednesday of the week will
              be released on Friday of the same week, while requests submitted
              from Thursday to Friday will be released on Friday of the
              following week.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              Got it!
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
