import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

import { generateRandomNum } from "../../../../helper/UserApplicationMgmt/UserApplications";

const TextSkeleton = ({ minWidth, maxWidth, justifyContent = "center" }) => {
  return (
    <TableCell>
      <Box sx={{ width: "100%", display: "grid" }}>
        <Skeleton
          variant="text"
          width={generateRandomNum(minWidth, maxWidth)}
          sx={{ fontSize: "24px", justifySelf: justifyContent }}
        />
      </Box>
    </TableCell>
  );
};

export default TextSkeleton;
