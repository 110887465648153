import {
  downloadCsv,
  downloadPdf,
} from "../../../helper/SettingsTimesheet/PendingTimesheetEntries";

export const PENDING_ENTRIES_QUERY_KEY = "pending-timesheet-entries";

export const HEADER_CELL_ID = {
  DATE: "date",
  NAME: "name",
  TYPE: "type",
  CODE: "code",
  ACTIVITY: "activity",
  PROJECT_CODE_STATUS: "code_status",
  PROJECT_CODE_MONTHLY_LIMIT: "code_limit",
  CURRENTLY_USED: "used",
  DETAILS: "details",
  HOURS_RENDERED: "hours",
  ACTION: "action",
};

export const ORDER = {
  ASCENDING: "asc",
  DESCENDING: "desc",
};

export const headerCells = [
  {
    id: HEADER_CELL_ID.NAME,
    label: "Name",
    isSortable: true,
  },
  {
    id: HEADER_CELL_ID.DATE,
    label: "Date Filed",
    isSortable: true,
  },
  // {
  //   id: HEADER_CELL_ID.TYPE,
  //   label: "Type",
  //   isSortable: true,
  // },
  // {
  //   id: HEADER_CELL_ID.CODE,
  //   label: "Code",
  //   isSortable: true,
  // },
  // {
  //   id: HEADER_CELL_ID.ACTIVITY,
  //   label: "Activity",
  //   isSortable: true,
  // },
  // {
  //   id: HEADER_CELL_ID.PROJECT_CODE_STATUS,
  //   label: "Project Code",
  //   isSortable: true,
  // },
  // {
  //   id: HEADER_CELL_ID.PROJECT_CODE_MONTHLY_LIMIT,
  //   label: "Project Code Monthly Limit",
  //   isSortable: true,
  // },
  {
    id: HEADER_CELL_ID.CURRENTLY_USED,
    label: "Project",
    isSortable: true,
  },
  {
    id: HEADER_CELL_ID.DETAILS,
    label: "Details",
    isSortable: false,
  },
  {
    id: HEADER_CELL_ID.HOURS_RENDERED,
    label: "Hours Rendered",
    isSortable: true,
  },
  {
    id: HEADER_CELL_ID.ACTION,
    label: "Action",
    isSortable: false,
  },
];

export const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100];

export const ACTION_TYPE = {
  APPROVE: "approve",
  DENY: "disapprove",
};

export const CONFIRMATION_TEXT = {
  APPROVE: "Please confirm that you want to approve this timesheet entry.",
  DISAPPROVE: `Disapproving a timesheet will completely remove the entry in the user's end. Please notify them so that they can correct their entry.`,
};

export const EXPORT_TYPE = {
  csv: {
    id: "csv",
    exportFn: (data) => {
      downloadCsv(data);
    },
  },
  pdf: {
    id: "pdf",
    exportFn: (data) => {
      downloadPdf(data);
    },
  },
};
