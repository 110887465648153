import React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  InputLabel,
  Dialog,
  DialogContent,
  TextField,
  Select,
  MenuItem,
  Button,
  Slider,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState } from "react";

const scrollYear = createTheme({
  components: {
    MuiYearPicker: {
      styleOverrides: {
        root: {
          display: "block",
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          align: "center",
        },
      },
    },
  },
});

function DateIcon() {
  return <label></label>;
}

const Profile = ({ validation, formSchedule, formDepartment, open, close }) => {
  const [permission] = useState(JSON.parse(localStorage.getItem("user")));

  const ProfileField = [
    {
      title: "Personal Information",
      fields: [
        {
          label: "First Name: ",
          fieldname: "firstname",
          type: "field",
          allowedEdit: false,
        },
        {
          label: "Last Name: ",
          fieldname: "lastname",
          type: "field",
          allowedEdit: false,
        },
        {
          label: "Nickname: ",
          fieldname: "nickname",
          type: "field",
          allowedEdit: false,
        },
        {
          label: "Extension: ",
          fieldname: "extension",
          type: "field",
          allowedEdit: false,
        },
        {
          label: "Gender: ",
          fieldname: "gender",
          id: "gender",
          type: "select",
          item: [
            { value: "male", label: "Male" },
            { value: "female", label: "Female" },
            { value: "other", label: "Other" },
          ],
          allowedEdit: false,
        },
        {
          label: "BirthDate: ",
          fieldname: "dob",
          type: "date",
          allowedEdit: false,
        },
        {
          label: "Martial Status: ",
          fieldname: "marital",
          id: "marital",
          type: "select",
          item: [
            { value: "unmarried", label: "Unmarried" },
            { value: "married", label: "Married" },
            { value: "divorce", label: "Divorced" },
            { value: "widowed", label: "Widowed" },
          ],
          allowedEdit: false,
        },
        {
          label: "Departments: ",
          fieldname: "dept",
          id: "dept",
          type: "select",
          item: formDepartment,
          allowedEdit: ["isAdmin"],
        },
        {
          label: "Mobile No ",
          fieldname: "mobile",
          type: "field",
          allowedEdit: false,
        },
        {
          label: "Address: ",
          fieldname: "address",
          type: "field",
          allowedEdit: false,
        },
        {
          label: "Personal Email: ",
          fieldname: "email_personal",
          type: "field",
          allowedEdit: false,
        },
        {
          label: "Work Email: ",
          fieldname: "email_work",
          type: "field",
          allowedEdit: false,
        },
      ],
    },
    // {
    //   title: "Skills",
    //   fields: [
    //     {
    //       label: "Coding",
    //       value: 10,
    //       typename: "skill",
    //     },
    //     {
    //       label: "Design",
    //       value: 7,
    //       typename: "skill",
    //     },
    //     {
    //       label: "Infrastructure",
    //       value: 10,
    //       typename: "skill",
    //     },
    //     {
    //       label: "Project Management",
    //       value: 8,
    //       typename: "skill",
    //     },
    //     {
    //       label: "Quality Controller",
    //       value: 9,
    //       typename: "skill",
    //     },
    //     {
    //       label: "Marketing",
    //       value: 8,
    //       typename: "skill",
    //     },
    //   ],
    // },
    {
      title: "Employee Information",
      fields: [
        {
          label: "Employee ID ",
          fieldname: "emp_company_id",
          type: "field",
          allowedEdit: ["isAdmin"],
        },
        {
          label: "Date Hired: ",
          fieldname: "date_hired",
          type: "date",
          allowedEdit: ["isAdmin"],
        },
        {
          label: "Employment Status: ",
          fieldname: "employment_status",
          id: "employment_status",
          type: "select",
          item: [
            { value: "probationary", label: "Probationary" },
            { value: "regular", label: "Regular" },
            { value: "terminated", label: "Terminated" },
          ],
          allowedEdit: ["isAdmin"],
        },
        {
          label: "WFH Number of Days: ",
          fieldname: "wfh_days",
          type: "field",
          allowedEdit: ["isAdmin"],
        },
        {
          label: "Type of Schedule: ",
          fieldname: "schedule",
          id: "schedule",
          type: "select",
          item: [
            { value: "Flexi", label: "Flexi" },
            { value: "Fixed", label: "Fixed" },
          ],
          allowedEdit: ["isAdmin"],
        },
        {
          label: "Work Schedule: ",
          fieldname: "sched_id",
          id: "sched_id",
          type: "select",
          item: formSchedule,
          allowedEdit: ["isAdmin"],
        },
      ],
    },
    {
      title: "Messaging App",
      fields: [
        {
          label: "Skype: ",
          fieldname: "skype",
          type: "field",
          allowedEdit: false,
        },
        {
          label: "Slack: ",
          fieldname: "slack",
          type: "field",
          allowedEdit: false,
        },
      ],
    },
  ];

  return (
    <>
      <Dialog maxWidth="md" open={open} fullWidth={true}>
        <form>
          <DialogContent sx={{ bgcolor: blue[50] }}>
            <IconButton
              aria-label="close"
              onClick={close}
              sx={{
                position: "absolute",
                right: 30,
                top: 25,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            {ProfileField.map((row, index) => {
              return (
                <Card key={index} sx={{ mb: 2 }}>
                  <CardContent>
                    <Typography variant="h5">{row.title}</Typography>
                    <Grid
                      container
                      style={{ padding: "0.5em" }}
                      spacing={2}
                      mt={1}
                    >
                      {row.fields.map((row, index) => {
                        return (
                          <React.Fragment key={index}>
                            <Grid item xs={6}>
                              {row.type === "field" && (
                                <TextField
                                  disabled={
                                    row.allowedEdit &&
                                    row.allowedEdit.every((role) => {
                                      return permission[role] === false;
                                    })
                                  }
                                  variant="standard"
                                  name={row.fieldname}
                                  id={row.fieldname}
                                  label={row.label}
                                  value={
                                    validation.values[row.fieldname]
                                      ? validation.values[row.fieldname]
                                      : ""
                                  }
                                  fullWidth
                                  onChange={(e) =>
                                    validation.setFieldValue(
                                      row.fieldname,
                                      e.target.value,
                                      true
                                    )
                                  }
                                  error={
                                    validation.touched[row.fieldname] &&
                                    !!validation.errors[row.fieldname]
                                  }
                                  helperText={
                                    validation.touched[row.fieldname] &&
                                    validation.errors[row.fieldname]
                                  }
                                />
                              )}

                              {row.type === "date" && (
                                <ThemeProvider theme={scrollYear}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DatePicker
                                      openTo={"year"}
                                      disabled={
                                        row.allowedEdit &&
                                        row.allowedEdit.every((role) => {
                                          return permission[role] === false;
                                        })
                                      }
                                      disableToolbar
                                      format="MM/DD/YYYY"
                                      label={row.label}
                                      components={{
                                        SwitchViewIcon: DateIcon,
                                      }}
                                      value={validation.values[row.fieldname]}
                                      onChange={(e) =>
                                        validation.setFieldValue(
                                          row.fieldname,
                                          e,
                                          true
                                        )
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          sx={{ mt: 1 }}
                                          fullWidth
                                          variant="standard"
                                          {...params}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </ThemeProvider>
                              )}
                              {row.type === "select" && (
                                <>
                                  <InputLabel htmlFor="select-gender">
                                    {row.label}
                                  </InputLabel>
                                  <Select
                                    variant="standard"
                                    id={row.fieldname}
                                    fullWidth
                                    disabled={
                                      row.allowedEdit &&
                                      row.allowedEdit.every((role) => {
                                        return permission[role] === false;
                                      })
                                    }
                                    value={
                                      validation.values[row.fieldname] &&
                                      row.item.some(
                                        (element) =>
                                          element.value.toString() ===
                                          validation.values[
                                            row.fieldname
                                          ].toString()
                                      )
                                        ? validation.values[row.fieldname]
                                        : ""
                                    }
                                    onChange={(e) =>
                                      validation.setFieldValue(
                                        row.fieldname,
                                        e.target.value,
                                        true
                                      )
                                    }
                                  >
                                    {row.item.map((row, index) => (
                                      <MenuItem key={index} value={row.value}>
                                        {row.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </>
                              )}
                              {row.typename === "skill" && (
                                <>
                                  <div style={{ paddingLeft: "7px" }}>
                                    {row.label}
                                  </div>
                                  <Slider
                                    value={row.value}
                                    valueLabelDisplay="on"
                                    step={1}
                                    marks
                                    min={0}
                                    max={10}
                                    color="primary"
                                    className="time-slider"
                                    size="small"
                                  />
                                </>
                              )}
                            </Grid>
                          </React.Fragment>
                        );
                      })}
                    </Grid>
                  </CardContent>
                </Card>
              );
            })}
            <Grid container mt={1}>
              <Grid item xs={12}>
                <Box display="flex" p={1} justifyContent="flex-end">
                  <Button
                    type="submit"
                    onClick={validation.handleSubmit}
                    className="ml-5"
                    variant="contained"
                    color="primary"
                    sx={{ textTransform: "none", mr: 1 }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ textTransform: "none" }}
                    onClick={close}
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </form>
      </Dialog>
    </>
  );
};

export default Profile;
