import { useQuery } from "react-query";
import { ResetPassToken } from "../../../api/ResetPassToken";

export const useResetPassToken = (data) => {
  return useQuery(["get-valid-token"], () => ResetPassToken(data));
};

const queries = {
  useResetPassToken,
};

export default queries;
