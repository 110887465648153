import apiService from "../axios";

export const getReportsDashboard = async ({
  fromDate,
  toDate,
  inactiveUsers = false,
  weekends = true,
}) => {
  try {
    let response = await apiService.post(
      `v5/timesheet/get-dashboard-report?fromDate=${fromDate}&inactiveUsers=${inactiveUsers}&toDate=${toDate}&weekends=${weekends}`,
      {
        Accept: `application/json`,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getActivityReport = async ({
  page_size = 10,
  fromDate,
  toDate,
  page = 1,
  inActives = false,
  weekends = true,
}) => {
  try {
    let response = await apiService.post(
      `v5/timesheet/get-per-activity-report?page_size=${page_size}&fromDate=${fromDate}&toDate=${toDate}&page=${page}&weekends=${weekends}&inActives=${inActives}`,
      {
        Accept: `application/json`,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getStaffReport = async ({
  page_size = 10,
  fromDate,
  toDate,
  page = 1,
  inActives = false,
  weekends = true,
}) => {
  try {
    let response = await apiService.get(
      `v5/timesheet/get-per-staff-report?page_size=${page_size}&fromDate=${fromDate}&toDate=${toDate}&page=${page}&weekends=${weekends}&inActives=${inActives}`,
      {
        Accept: `application/json`,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
