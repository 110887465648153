import React from "react";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Grow from "@mui/material/Grow";
import hooks from "../../hooks/react-query/queryHooks";
import { useForm } from "react-hook-form";
import { format, differenceInBusinessDays, getDay, isAfter } from "date-fns";
import LeaveResponse from "./leaveResponse";
import InputController from "../InputController";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { leaveSchema } from "../../helper/Yup/leaveSchema";
import { useQueryClient } from "react-query";
import { isJsonString } from "../../helper/ProjectCodeSettings";

const LeaveModal = ({ leaveOpen, handleLeave, leaveCredits }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const dept = user?.employee?.department?.name;
  const deptObj = user?.employee?.department;
  const isWeekend = (date) => {
    const day = getDay(date);

    if (dept === "RSKYE") return day === 1;
    return day === 0 || day === 6;
  };
  const queryClient = useQueryClient();
  const leaveOptions = leaveCredits.credits?.map((lc) => {
    const dept = leaveCredits.type.data.find(
      (typ) => typ.id === lc.leave_id
    ).department;
    return {
      id: lc.leave_id,
      name: lc.leave_name,
      description: "",
      credits: lc.credits,
      department: dept,
    };
  });
  const SLCredit = leaveOptions?.find((leave) => leave.id === 1)?.credits;
  const VLCredit = leaveOptions?.find((leave) => leave.id === 2)?.credits;
  const filterByDepartment = leaveOptions.filter((fd) =>
    isJsonString(fd.department)?.includes(deptObj.id)
  );

  const leaveOptionsFilter = filterByDepartment.filter((fd) =>
    SLCredit || VLCredit > 0 ? fd.id !== 8 : fd
  );

  const [currentRes, setCurrentres] = React.useState(null);
  const [resLoader, setResloader] = React.useState(false);
  const { mutate: addLeaveMutation } = hooks.useAddSubmitLeave();
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(leaveSchema),
    defaultValues: {
      leaveType: "",
      startDate: new Date(),
      endDate: new Date(),
      credits: 0,
      paid: "Paid",
      daysRequested: 1,
      dayTime: "Whole Day",
      purpose: "",
      typeName: "",
    },
  });
  const refetchMyQuery = () => {
    queryClient.refetchQueries(["get-employee-request-data-with-page"]);
  };
  const timeRef = React.useRef(null);
  const onMutationTimer = () => {
    setTimeout(() => {
      if (timeRef.current === null) {
        setCurrentres("success");
      }
    }, 3000);
  };
  const onSubmit = (data) => {
    setResloader(true);
    onMutationTimer();
    const leaveEntries = {
      api: true,
      id: null,
      from: format(data.startDate, "LLL dd, yyyy"),
      to: format(data.endDate, "LLL dd, yyyy"),
      leave: data.leaveType,
      days: data.daysRequested,
      paid: data.paid,
      credits: data.credits,
      duration: data.dayTime,
      purpose: data.purpose,
      type: data.leaveType,
      typeName: data.typeName,
    };
    addLeaveMutation(leaveEntries, {
      onSuccess: (request) => {
        timeRef.current = request.status;
        setCurrentres(request.status);
      },
      onError: (request) => {
        timeRef.current = request.response.data.status;
        setCurrentres(request.response.data.status);
      },
    });
  };

  const daysRequestedChecker = (event, dayParam) => {
    const dayTimeValue = getValues("dayTime");
    const currentStartDate = getValues("startDate");
    const currentEndDate = getValues("endDate");

    let days;

    if (dayParam === 1) {
      days = differenceInBusinessDays(currentEndDate, event) + 1;
      if (isAfter(event, currentEndDate)) {
        setValue("endDate", event);
        days = 1;
      }
    }

    if (dayParam === 2) {
      days = differenceInBusinessDays(event, currentStartDate) + 1;
      if (isAfter(currentStartDate, event)) {
        setValue("startDate", event);
        days = 1;
      }
    }

    if (dayTimeValue === "Whole Day") {
      setValue("daysRequested", days);
    } else {
      const floatDay = days - 0.5;
      setValue("daysRequested", parseFloat(floatDay));
    }
  };

  const handleLeaveType = (credParam) => {
    setValue("typeName", credParam.name);
    if (credParam?.credits === 0) {
      setValue("paid", "Not paid");
    } else {
      setValue("paid", "Paid");
    }
    setValue("credits", credParam?.credits);
  };
  const handleDuration = (durParam) => {
    const newValue = getValues("daysRequested");
    const setter = () => {
      if (Number.isInteger(newValue)) {
        setValue("daysRequested", getValues("daysRequested") - 0.5);
      }
    };
    switch (durParam.id) {
      case "Half Day - AM":
        setter();
        break;
      case "Half Day - PM":
        setter();
        break;
      default:
        if (Number.isInteger(newValue)) {
          setValue("daysRequested", getValues("daysRequested"));
        } else {
          setValue(
            "daysRequested",
            parseFloat(getValues("daysRequested") + 0.5)
          );
        }
        break;
    }
  };
  console.log(leaveCredits);
  return (
    <Modal open={leaveOpen}>
      <Grow
        in={leaveOpen}
        style={{ transformOrigin: "0 0 0" }}
        {...(leaveOpen ? { timeout: 500 } : {})}
        unmountOnExit
      >
        <Card className="login-modal leave1-modal" sx={{ minWidth: "350px" }}>
          <CardHeader
            title={
              <Typography variant="h3" className="h3-bar">
                Leave Application
              </Typography>
            }
            action={
              <IconButton onClick={handleLeave}>
                <CloseOutlinedIcon sx={{ width: "15px", height: "15px" }} />
              </IconButton>
            }
          />
          {resLoader ? (
            <LeaveResponse
              status={currentRes}
              handleLeave={handleLeave}
              refetchMyQuery={refetchMyQuery}
            />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <CardContent sx={{ margin: "10px" }}>
                <Grid container rowSpacing={3} columnSpacing={1}>
                  <Grid item xs={12} lg={6}>
                    <Box className="typ-over">
                      <Typography
                        variant="overline"
                        sx={{ paddingLeft: "10px", alignSelf: "center" }}
                      >
                        Select Leave Type
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <InputController
                      type="autoComplete"
                      control={control}
                      label="Leave type"
                      name="leaveType"
                      errors={errors}
                      size="small"
                      options={leaveOptionsFilter}
                      rules={{
                        required: true,
                      }}
                      isDefaultErrorAlert={true}
                      handleAutoFunc={handleLeaveType}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputController
                      type="date"
                      control={control}
                      name="startDate"
                      // disableDate={isWeekend}
                      errors={errors}
                      label="Start Date"
                      size="small"
                      handleDateFunc={daysRequestedChecker}
                      dateNum={1}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputController
                      type="date"
                      control={control}
                      name="endDate"
                      // disableDate={isWeekend}
                      errors={errors}
                      label="End Date"
                      size="small"
                      handleDateFunc={daysRequestedChecker}
                      dateNum={2}
                    />
                  </Grid>
                  <Grid item xs={5} lg={2}>
                    <InputController
                      type="text"
                      control={control}
                      label="Credits"
                      name="credits"
                      errors={errors}
                      InputProps={{
                        readOnly: true,
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={7} lg={4}>
                    <InputController
                      type="text"
                      control={control}
                      label="Paid"
                      name="paid"
                      errors={errors}
                      InputProps={{
                        readOnly: true,
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={5} lg={2.5}>
                    <InputController
                      type="text"
                      control={control}
                      label="Days Requested"
                      name="daysRequested"
                      errors={errors}
                      InputProps={{
                        readOnly: true,
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={7} lg={3.5}>
                    <InputController
                      type="autoComplete"
                      control={control}
                      label="Day"
                      name="dayTime"
                      errors={errors}
                      size="small"
                      options={[
                        { id: "Whole Day", name: "Whole Day" },
                        { id: "Half Day - AM", name: "Half Day - AM" },
                        { id: "Half Day - PM", name: "Half Day - PM" },
                      ]}
                      handleAutoFunc={handleDuration}
                      defaultValue={{ id: "Whole Day", name: "Whole Day" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputController
                      type="text"
                      control={control}
                      label="Purpose"
                      name="purpose"
                      errors={errors}
                      rows={7}
                      rules={{ required: true }}
                      isDefaultErrorAlert={true}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  className="adjust-w cancel-button"
                  disableElevation
                  variant="contained"
                  sx={{ marginLeft: "auto", minWidth: "100px" }}
                  onClick={handleLeave}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  className="adjust-w timest-button"
                  disableElevation
                  variant="contained"
                  type="submit"
                  sx={{ minWidth: "100px", mr: "17px" }}
                >
                  Apply
                </Button>
              </CardActions>
            </form>
          )}
        </Card>
      </Grow>
    </Modal>
  );
};

export default LeaveModal;
