import React from "react";
import { Box, Typography } from "@mui/material";

const Content = ({ data }) => {
  return (
    <Box sx={{ minHeight: "25vw" }}>
      <Typography
        dangerouslySetInnerHTML={{ __html: data ? data.content : "" }}
      />
    </Box>
  );
};

export default Content;
