import { useState, Fragment } from "react";

import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import isDate from "date-fns/isDate";

import { UserInformationEditCard, UserInformationEditButtons } from "./";
import {
  generateEditFields,
  generateDefaultFormValues,
} from "../../helper/UserInformation";
import { updateUserInformation } from "../../api/user";

const BOX_STYLE = {
  height: "fit-content",
  maxHeight: "80vh",
  width: "90%",
  maxWidth: "1000px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "34px 36px",
  borderRadius: "4px",
  backgroundColor: "#FFF",
};

const FORM_STYLE = { height: "100%", overflowY: "auto", padding: "2px 10px" };

const schema = yup.object({
  firstname: yup.string().required("Please enter first name."),
  lastname: yup.string().required("Please enter last name."),
  email_work: yup
    .string()
    .required("Please enter an email address.")
    .email("Please enter a valid email address."),
  gender: yup.string().required("Please select a gender."),
  emp_company_id: yup.string().required("Please enter a company ID."),
  dept: yup.string().required("Please select a department."),
  email_personal: yup.string().email("Please enter a valid email address."),
});

const UserInformationEditModal = ({
  onClose,
  userData,
  departments,
  schedules,
}) => {
  const [field] = useState(generateEditFields(schedules, departments));
  const queryClient = useQueryClient();
  const formDefaultValues = generateDefaultFormValues(field, userData);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: formDefaultValues,
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const updateData = useMutation(updateUserInformation, {
    onSuccess: () => {
      queryClient.invalidateQueries("get-user-information");
      onCloseModalHandler();
      toast.success("User Information Changed");
    },
    onError: (error) => {
      const errorMessage = error?.message || "Something went wrong";
      toast.error(errorMessage);
    },
  });

  const onSubmitHandler = (data) => {
    Object.entries(data).forEach((item) => {
      if (isDate(item[1])) {
        data[item[0]] = item[1].toISOString();
      }
    });

    data.api = true;
    data.id = userData.id;

    const update = { ...data };

    const add = {
      firstname: data.firstname,
      lastname: data.lastname,
      gender: data.gender,
      email: data.email_work,
      middlename: data.middlename,
      id: userData.user,
      password: data.password,
      wfh_require: +data.wfh_require,
      extension: data.extension,
      dept: data.dept,
      api: true,
      dateHired: data.date_hired,
    };

    updateData.mutate({ update, add });
  };

  const submitHandler = handleSubmit(onSubmitHandler);

  const onCloseModalHandler = () => {
    queryClient.cancelMutations(["get-user-information"]);
    reset();
    onClose();
  };

  return (
    <Stack spacing={5} sx={BOX_STYLE}>
      <Fragment>
        <Typography variant="h5">Edit Employee Information</Typography>

        <IconButton
          onClick={onCloseModalHandler}
          sx={{
            position: "absolute",
            top: "-20px",
            right: "18px",
          }}
        >
          <CloseIcon />
        </IconButton>

        <Stack
          component="form"
          spacing={5}
          sx={FORM_STYLE}
          onSubmit={submitHandler}
        >
          {field.map((item) => (
            <UserInformationEditCard
              key={item.title}
              data={item}
              control={control}
              variant="outlined"
              size="medium"
              errors={errors}
            />
          ))}
        </Stack>

        <UserInformationEditButtons
          onCancelForm={onCloseModalHandler}
          onSubmitForm={submitHandler}
          isSubmitting={!!queryClient.isMutating()}
        />
      </Fragment>
    </Stack>
  );
};

export default UserInformationEditModal;
