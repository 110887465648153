import React from "react";
import { Dialog, DialogContent, Grid, Typography } from "@mui/material";

const LeaveCalendarDialog = ({ closeDialog, dialogData }) => {
  return (
    <Dialog onClose={closeDialog} open={dialogData.open}>
      <DialogContent sx={{ margin: "10px" }}>
        {dialogData.calendar_type === "leave" && (
          <div>
            <Typography variant="h4">{dialogData.title}</Typography>
            <Grid container mt={2}>
              <Grid item xs={12} m={1}>
                <Typography variant="h5">{dialogData.type}</Typography>
              </Grid>
              <Grid item xs={12} m={1}>
                Date Filed: {dialogData.filed}
              </Grid>
              <Grid item xs={12} m={1}>
                Date Approved: {dialogData.approved}
              </Grid>
              <Grid item xs={12} m={1}>
                Start: {dialogData.start}
              </Grid>
              <Grid item xs={12} m={1}>
                End: {dialogData.end}
              </Grid>
              <Grid item xs={12} m={1}>
                Purpose: {dialogData.purpose}
              </Grid>
            </Grid>
          </div>
        )}
        {dialogData.calendar_type === "holiday" && (
          <div>
            <strong>{dialogData.holiday}</strong>
            <Grid container>
              <Grid item xs={12} mb={2}>
                <strong>{dialogData.type}</strong>
              </Grid>
              <Grid item xs={12} m={1}>
                Date Created: {dialogData.filed}
              </Grid>
              <Grid item xs={12} m={1}>
                Start: {dialogData.start}
              </Grid>
              <Grid item xs={12} m={1}>
                Resume on: {dialogData.end}
              </Grid>
            </Grid>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default LeaveCalendarDialog;
