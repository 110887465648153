import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import RowStack from "./RowStack";

const CreditsRow = ({ earned, converted }) => {
  return (
    <RowStack direction="row">
      <Stack alignItems="center" sx={{ width: "100%" }}>
        <Typography>Earned Credits</Typography>

        <Typography>{earned}</Typography>
      </Stack>

      <Stack alignItems="center" sx={{ width: "100%" }}>
        <Typography>Converted Credits</Typography>

        <Typography>{converted}</Typography>
      </Stack>
    </RowStack>
  );
};

export default CreditsRow;
