export const TradinessTableHeaderV5 = [
  {
    label: "Name",
    key: "name",
    isSortable: true,
  },
  {
    label: "Time Logs",
    key: "logs",
    isSortable: true,
    type: "number",
  },
  {
    label: "Lates",
    key: "lates",
    isSortable: true,
    type: "number",
  },
  {
    label: "Punctuality (%)",
    key: "percentage",
    isSortable: true,
    align: "right",
    type: "number",
  },
];
