import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";

import StyledTableCell from "../StyledTableCell";

const ActionsCell = ({ approveActionHandler, denyActionHandler }) => {
  return (
    <StyledTableCell sx={{ verticalAlign: "top" }}>
      <Stack direction="row" spacing={1} justifyContent="center">
        {/* APPROVE BUTTON */}
        <Tooltip title="Approve">
          <IconButton onClick={approveActionHandler}>
            <ThumbUpIcon
              sx={{
                color: "#099269ce",
              }}
            />
          </IconButton>
        </Tooltip>

        {/* DENY BUTTON */}
        <Tooltip title="Deny">
          <IconButton onClick={denyActionHandler}>
            <ThumbDownIcon sx={{ color: "#fa5252ce" }} />
          </IconButton>
        </Tooltip>
      </Stack>
    </StyledTableCell>
  );
};

export default ActionsCell;
