import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getNotification,
  readAllNotification,
  readNotification,
} from "../../../api/Notification";

export const useGetNotification = () => {
  return useQuery(["get-notif"], () => getNotification());
};

export const useReadNotification = () => {
  const queryClient = useQueryClient();

  return useMutation(readNotification, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["get-notif"]);
    },
  });
};

export const useReadAllNotification = () => {
  const queryClient = useQueryClient();
  return useMutation(readAllNotification, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["get-notif"]);
    },
  });
};
