import React from "react";
import hooks from "../../hooks/react-query/queryHooks";
import SurfingOutlinedIcon from "@mui/icons-material/SurfingOutlined";
import SickOutlinedIcon from "@mui/icons-material/SickOutlined";
import IconButton from "@mui/material/IconButton";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";

const leaveCredits = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { data } = hooks.useLeaveType(user.employee.id);
  return (
    <>
      <Grid item xs={6} lg={6}>
        <Box sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.1)" }}>
          <CardHeader
            action={
              <IconButton className="sick-button">
                <SickOutlinedIcon />
              </IconButton>
            }
            title={<Typography variant="span">Sick</Typography>}
          />
          <CardContent sx={{ padding: "13px 16px 16px 16px" }}>
            {data && (
              <Typography variant="body1" className="s-body1">
                {data.credits[0].credits.toFixed(2)}
              </Typography>
            )}
          </CardContent>
        </Box>
      </Grid>
      <Grid item xs={6} lg={6}>
        <Box>
          <CardHeader
            action={
              <IconButton className="vacation-button">
                <SurfingOutlinedIcon />
              </IconButton>
            }
            title={<Typography variant="span">Vacation</Typography>}
          />
          <CardContent sx={{ padding: "13px 16px 16px 16px" }}>
            {data && (
              <Typography variant="body1" className="v-body1">
                {data.credits[1].credits.toFixed(2)}
              </Typography>
            )}
          </CardContent>
        </Box>
      </Grid>
    </>
  );
};

export default leaveCredits;
