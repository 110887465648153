import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import DashboardApexBar from "./dashboardApexbar";
import Skeleton from "@mui/material/Skeleton";

const DashboardBarchart = ({ user, handleEntryModal, isFetching, reports }) => {
  return (
    <Card className="card-bar" sx={{ minHeight: "494px" }}>
      <CardHeader
        className="ch-action"
        title={
          <Typography variant="h3" className="h3-bar">
            {reports ? reports : "My entries"}
          </Typography>
        }
        action={
          <Grid container>
            <Grid
              item
              xs={6}
              sx={{
                paddingLeft: "10px",
                borderRight: "1px solid rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography variant="h4" className="timest-h4">
                Today
              </Typography>
              <Chip
                size="small"
                label={
                  <Typography variant="h3" className="timest-h3 timest-h4">
                    {user.today_total_hours !== "0"
                      ? `${parseFloat(user.today_total_hours).toFixed(2)} hrs`
                      : "0h 0m"}
                  </Typography>
                }
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                paddingLeft: "10px",
              }}
            >
              <Typography variant="h4" className="timest-h4">
                This week
              </Typography>
              <Chip
                size="small"
                label={
                  <Typography variant="h3" className="timest-h3 timest-h4">
                    {user.weekly_total_hours !== "0"
                      ? `${parseFloat(user.weekly_total_hours).toFixed(2)} hrs`
                      : "0h 0m"}
                  </Typography>
                }
                variant="outlined"
              />
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Box sx={{ position: "relative" }}>
          {isFetching ? (
            <Skeleton variant="rounded" height={300} />
          ) : (
            <DashboardApexBar user={user} handleEntryModal={handleEntryModal} />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default DashboardBarchart;
