import {
  Dialog,
  DialogContent,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  IconButton,
} from "@mui/material";
import React from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const ViewLog = ({ handleClose, open, title, subject, Content }) => {
  return (
    <>
      <Dialog
        disableEnforceFocus
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        maxWidth="md"
        fullWidth
        open={open}
      >
        <DialogContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 0,
          }}
        >
          <Card sx={{ width: "100%", m: 0 }}>
            <CardHeader
              title={<Typography variant="h5">{title}</Typography>}
              action={
                <IconButton onClick={handleClose} sx={{ mr: "5px" }}>
                  <CloseOutlinedIcon
                    sx={{ color: "#00C292", width: "22px", height: "22px" }}
                  />
                </IconButton>
              }
              sx={{ pb: "0!important", ml: "5px" }}
            />
            <CardContent
              sx={{ ml: "5px", pt: "0!important", pb: "15px!important" }}
            >
              <Typography color="text.secondary">{subject}</Typography>
            </CardContent>

            <CardContent sx={{ pt: 0, pr: 2, pl: 2 }} className="cc-static-dp">
              {Content}
            </CardContent>
            <CardActions sx={{ margin: "0px 10px 10px" }}></CardActions>
          </Card>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewLog;
