import { useState, useCallback } from "react";

import { useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";

import {
  getActivityReport,
  getDownloadableActivityReport,
} from "../../../api/perActivity";
import {
  ROWS_PER_PAGE_DEFAULT,
  RANGE_DATES_DEFAULT,
  RANGE_DATES,
  PER_ACTIVITY_QUERY_KEYS,
  NESTED_QUERIES_API_GETTER_FUNCTIONS,
  ORDER,
  PER_ACTIVITY_HEADER_CELL_ID,
} from "../../../lib/reports/perActivity";
import {
  getRangeDates,
  getComparator,
} from "../../../helper/Reports/PerActivity";

export const useActivityReport = () => {
  const [pageSize, setPageSize] = useState(ROWS_PER_PAGE_DEFAULT);
  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [range, setRange] = useState(RANGE_DATES_DEFAULT.name);
  const [fromDate, setFromDate] = useState(
    getRangeDates(RANGE_DATES_DEFAULT.name).fromDate
  );
  const [toDate, setToDate] = useState(
    getRangeDates(RANGE_DATES_DEFAULT.name).toDate
  );
  const [isWeekendShown, setIsWeekendShown] = useState(true);
  const [isInactiveUsersShown, setIsInactiveUsersShown] = useState(true);
  const [order, setOrder] = useState(ORDER.descending);
  const [orderBy, setOrderBy] = useState(PER_ACTIVITY_HEADER_CELL_ID.name);

  const sortFunction = useCallback(
    (data) => {
      const sortedData = [...data.data].sort(getComparator(order, orderBy));

      return { ...data, data: sortedData };
    },
    [order, orderBy]
  );

  const activityReportsQuery = useQuery(
    [
      PER_ACTIVITY_QUERY_KEYS.getActivityReport,
      pageSize,
      page,
      searchString,
      fromDate,
      toDate,
      isWeekendShown,
      isInactiveUsersShown,
    ],
    getActivityReport.bind(
      null,
      pageSize,
      page,
      fromDate,
      toDate,
      isWeekendShown,
      isInactiveUsersShown,
      searchString
    ),
    {
      select: sortFunction,
    }
  );

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangePageSize = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const handleSearchString = (string) => {
    setSearchString(string);
  };

  const handleRangeChange = (newRange) => {
    const { fromDate, toDate } = getRangeDates(newRange);
    setFromDate(fromDate);
    setToDate(toDate);
    setRange(newRange);
  };

  const handleFromDateChange = (newFromDate) => {
    setRange(RANGE_DATES.custom.name);
    setFromDate(newFromDate);
  };

  const handleToDateChange = (newToDate) => {
    setRange(RANGE_DATES.custom.name);
    setToDate(newToDate);
  };

  const handleIsWeekendShownChange = () => {
    setIsWeekendShown((previous) => !previous);
  };

  const handleIsInactiveUsersShownChange = () => {
    setIsInactiveUsersShown((previous) => !previous);
  };

  const handleRefetch = () => {
    activityReportsQuery.refetch();
  };

  const handleOrderChange = (newOrder) => {
    setOrder(newOrder);
  };

  const handleOrderChangeBy = (newOrderBy) => {
    setOrderBy(newOrderBy);
  };

  return {
    query: activityReportsQuery,
    state: {
      pageSize,
      page,
      searchString,
      range,
      fromDate,
      toDate,
      isWeekendShown,
      isInactiveUsersShown,
      order,
      orderBy,
    },
    handler: {
      handleChangePage,
      handleChangePageSize,
      handleSearchString,
      handleRangeChange,
      handleFromDateChange,
      handleToDateChange,
      handleIsWeekendShownChange,
      handleIsInactiveUsersShownChange,
      handleRefetch,
      handleOrderChange,
      handleOrderChangeBy,
    },
  };
};

export const useNestedActivityReport = (
  queryKey,
  objectIds,
  fromDate,
  toDate,
  isWeekendShown,
  isInactiveUsersShown,
  initialOrder
) => {
  const [pageSize, setPageSize] = useState(ROWS_PER_PAGE_DEFAULT);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState(ORDER.descending);
  const [orderBy, setOrderBy] = useState(initialOrder);

  const sortFunction = useCallback(
    (data) => {
      const sortedData = [...data.data].sort(getComparator(order, orderBy));

      return { ...data, data: sortedData };
    },
    [order, orderBy]
  );

  const reportsQuery = useQuery(
    [
      queryKey,
      objectIds,
      pageSize,
      page,
      fromDate,
      toDate,
      isWeekendShown,
      isInactiveUsersShown,
    ],
    NESTED_QUERIES_API_GETTER_FUNCTIONS[queryKey].bind(
      null,
      objectIds,
      pageSize,
      page,
      fromDate,
      toDate,
      isWeekendShown,
      isInactiveUsersShown
    ),
    {
      select: sortFunction,
    }
  );

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangePageSize = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const handleOrderChange = (newOrder) => {
    setOrder(newOrder);
  };

  const handleOrderChangeBy = (newOrderBy) => {
    setOrderBy(newOrderBy);
  };

  return {
    query: reportsQuery,
    state: {
      pageSize,
      page,
      order,
      orderBy,
    },
    handler: {
      handleChangePageSize,
      handleChangePage,
      handleOrderChange,
      handleOrderChangeBy,
    },
  };
};

export const useExportActivityReport = () => {
  const activityReportFile = useMutation(getDownloadableActivityReport, {
    onError: () => {
      toast.error("There was a probleming exporting the file.");
    },
  });

  return activityReportFile;
};
