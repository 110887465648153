import "./App.css";
import { Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./routes/privateroute";
import PublicRoutes from "./routes/publicroute";
import Login from "./pages/Auth/login";
import Pagenotfound from "./pages/Error/pagenotfound";
import Privacypolicy from "./pages/Error/privacypolicy";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ResetPassword from "./pages/Auth/ResetPassword";
import { RequestProvider } from "./globalstate/UserContext/RequestContext";
import { createContext, useState } from "react";
import { useUser } from "./hooks/react-query/Auth";
import Loader from "./component/Loader";
import { useUserDashboard } from "./hooks/react-query/queryHooks";
import { useSettings } from "./hooks/react-query/Settings";
import Reminder from "./component/Reminder";
import { initialReminder } from "./helper/Reminder";
const theme = createTheme({
  palette: {
    FlignoGreen: {
      main: "#444",
    },
  },
});

export const GlobalContext = createContext({});

function App() {
  const { data: user, isFetching } = useUser();
  const { data: settings, isFetching: isSettingsFetching } = useSettings();
  const {
    data: userDashboard,
    isFetching: isFetchingUserDashboard,
    isFetched,
  } = useUserDashboard();
  const [reminder, setReminder] = useState(initialReminder);
  const [projectActivity, setProjectActivity] = useState(null);
  const [projectCode, setProjectCode] = useState(null);
  const [setting, setSetting] = useState("");

  return (
    <ThemeProvider theme={theme}>
      <Loader
        isLoading={isFetching || isFetchingUserDashboard || isSettingsFetching}
      />
      <RequestProvider>
        <GlobalContext.Provider
          value={{
            user: user?.data || null,
            userDashboard: userDashboard || null,
            settings: settings || [],
            projectActivity,
            setProjectActivity,
            projectCode,
            setProjectCode,
            reminder,
            setReminder,
            setSetting,
            setting,
          }}
        >
          <Routes>
            <Route path="/*" element={<ProtectedRoutes />} />
            <Route path="/" element={<PublicRoutes />}>
              <Route path="/" index element={<Login />} />
              <Route
                path="/password/reset/*"
                index
                element={<ResetPassword />}
              />
              <Route path="/privacy-policy" element={<Privacypolicy />} />
            </Route>
            <Route path="/unauthorized" element={<Pagenotfound />} />
            <Route path="*" element={<Pagenotfound />} />
          </Routes>
          <ToastContainer position="bottom-right" theme="colored" />
          <Reminder
            userDashboard={userDashboard}
            setReminder={setReminder}
            isFetched={isFetched}
            reminder={reminder}
          />
        </GlobalContext.Provider>
      </RequestProvider>
    </ThemeProvider>
  );
}

export default App;
