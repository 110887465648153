import { useQuery } from "react-query";
import { getReportPerCodeProject } from "../../../api/perProjectActivity";

const usePerCodeProject = (params) => {
  return useQuery(["get-report-per-code-project", params.criteria], () =>
    getReportPerCodeProject(params.payload)
  );
};

const queries = {
  usePerCodeProject,
};

export default queries;
