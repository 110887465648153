import { useContext } from "react";

import LeaveConversionApplicationsContext from "../../../../globalstate/LeaveManagement/LeaveConversionApplications/LeaveConversionApplicationsContext";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";

import { ROWS_PER_PAGE_OPTIONS } from "../../../../lib/leaveManagement/leaveConversionApplications";

const TABLE_PAGINATION_STYLES = {
  display: "flex",
  justifyContent: "end",
  minHeight: "53px",
  borderTop: "1px solid #dee2e6",
  borderBottom: "none",
};

const Pagination = () => {
  const {
    query: { data },
    state: { pageSize },
    handler: { changePage, changePageSize },
  } = useContext(LeaveConversionApplicationsContext);

  const count = data?.total || 0;
  const page = data?.current_page ? data?.current_page - 1 : 0;

  const handlePageRowsChange = (event) => {
    changePageSize(+event.target.value);
  };

  const handlePageChange = (_, page) => {
    changePage(page + 1);
  };

  return (
    <TablePagination
      component={Box}
      sx={TABLE_PAGINATION_STYLES}
      showFirstButton={true}
      showLastButton={true}
      count={count}
      page={page}
      rowsPerPage={pageSize}
      rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handlePageRowsChange}
    />
  );
};

export default Pagination;
