import React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

const UserInformationEditButtons = ({
  onCancelForm,
  onSubmitForm,
  isSubmitting,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        gap: "18px",
      }}
    >
      <Button type="button" variant="outlined" onClick={onCancelForm}>
        Cancel
      </Button>
      <LoadingButton
        variant="contained"
        loading={isSubmitting}
        loadingPosition="start"
        startIcon={<SaveIcon />}
        onClick={onSubmitForm}
      >
        Update
      </LoadingButton>
    </Box>
  );
};

export default UserInformationEditButtons;
