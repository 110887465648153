import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import {
  Card,
  TextField,
  InputAdornment,
  Button,
  Typography,
  // FormControlLabel,
  // Checkbox
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { LeaveTypesContext } from ".";
import useDebounce from "../../../hooks/customHooks/debounce";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import AddLeaveTypeModal from "./AddLeaveTypeModal";
export const LeaveTypesFilterContext = createContext({});

const LeaveTypesFilter = () => {
  const {
    search,
    setSearch,
    refetch,
    setLoad,
    //  toggleActive,
    //  setToggleActive
  } = useContext(LeaveTypesContext);
  const [searchString, setSearchString] = useState(search);
  const [open, setOpen] = useState(false);
  const debounceValue = useDebounce(searchString, 700);
  useEffect(() => {
    setSearch(debounceValue);
  }, [debounceValue, setSearch]);
  const openModalHandler = () => {
    setOpen(true);
  };
  const value = useMemo(
    () => ({
      setOpen,
      open,
    }),
    [open, setOpen]
  );

  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "10px",
        padding: "14px",
        alignItems: "center",
        margin: "15px",
        boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
        position: "sticky",
        top: "0",
        zIndex: "1",
        "@media(max-width: 768px)": {
          position: "relative",
        },
      }}
    >
      <LeaveTypesFilterContext.Provider value={value}>
        {open && <AddLeaveTypeModal />}
        <Button
          sx={{ backgroundColor: "#00c292", height: 35 }}
          onClick={openModalHandler}
          variant="contained"
          startIcon={<AddCircleOutlineIcon />}
        >
          <Typography sx={{ fontSize: "0.78rem" }}>Add New</Typography>
        </Button>
        <div>
          <TextField
            value={searchString}
            onChange={(e) => {
              setLoad(true);
              refetch();
              setSearchString(e.target.value);
            }}
            sx={{ margin: "5px" }}
            size="small"
            align={"right"}
            placeholder="Search"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CloseIcon
                    style={{ color: "#000000", cursor: "pointer" }}
                    onClick={() => setSearchString("")}
                  />
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {/* <FormControlLabel
            label="Inactive Leave Types"
            sx={{ marginLeft: "10px" }}
            control={
              <Checkbox
                value={toggleActive}
                onChange={() => setToggleActive(!toggleActive)}
              />
            }
          /> */}
        </div>
      </LeaveTypesFilterContext.Provider>
    </Card>
  );
};

export default LeaveTypesFilter;
