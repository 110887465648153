import React from "react";
import TableRow from "@mui/material/TableRow";
import { TableCell } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import NoDataFound from "../SvgImage/NoDataFound";
import { Box } from "@mui/system";
const EmptyTableComponent = ({ columns }) => {
  return (
    <TableBody>
      <TableRow>
        <TableCell
          sx={{ textAlign: "center", height: "400px" }}
          colSpan={columns?.length}
        >
          <NoDataFound />
          <Box sx={{ m: "20px" }}>Sorry, no data was found!</Box>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};
export default EmptyTableComponent;
