import React from "react";
import ReusableModal from "../ReusableModal";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth } from "../../hooks/customHooks/auth";
import { initialModalStatus } from "../../helper/Reminder";

const Reminder = ({ userDashboard, setReminder, isFetched, reminder }) => {
  const role = getAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = React.useState(initialModalStatus);
  React.useMemo(() => {
    let timeInStatus = userDashboard?.time_inout?.timeIn_status;
    if (
      !timeInStatus &&
      isFetched &&
      !role.isAdmin &&
      location.pathname !== "/leave/ot/"
    ) {
      setOpenModal({
        title: "Hi, " + userDashboard.time_inout.details.firstname,
        message: 'You have not time in yet. Click "Proceed to Time In"',
        confirmMessage: "Proceed",
        open: isFetched,
        type: "confirm",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDashboard, isFetched]);

  React.useMemo(() => {
    let timeOutStatus = userDashboard?.time_inout?.timeOut_status;
    if (!timeOutStatus && isFetched && reminder.timeOutTrigger) {
      setOpenModal({
        title: "Oops,",
        message:
          "You have not time out yet, please time out first before logging out.",
        proceedToTimeout: "PROCEED TO TIMEOUT",
        logout: "LOGOUT",
        open: isFetched,
        type: "confirmLogout",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reminder.timeOutTrigger]);

  const submit = () => {
    if (location.pathname !== "/dashboard") {
      navigate("/dashboard");
    }
    setReminder({ timein: true });
  };

  const submitTimeOut = () => {
    if (location.pathname !== "/dashboard") {
      navigate("/dashboard");
    }
    setReminder({ timeout: true });
  };

  return (
    <>
      <ReusableModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        submitForMutation={reminder.timeOutTrigger ? submitTimeOut : submit}
      />
    </>
  );
};

export default Reminder;
