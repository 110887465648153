import React, { useContext, useEffect } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar/index";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import content from "../../routes/index";
import { Routes, Route } from "react-router-dom";
import RoleBasedRoutes from "../../routes/roleBasedRoute";
import "../../api/auth/auth";
import LeaveModal from "../Dashboard/leaveModal";
import { RequestContext } from "../../globalstate/UserContext/RequestContext";
import hooks from "../../hooks/react-query/queryHooks";
import WfhModal from "../Dashboard/wfhModal";
import Overtime from "../Dashboard/overtimeModal";
import LeaveConversion from "../Dashboard/convertion";
import { GlobalContext } from "../../App";
import { useThemeColor } from "../../hooks/customHooks/useThemeColor";
import FeedbackModal from "../Feedback/AddFeedbackModal/index";
import ViewSurvey from "../PulseSurvey/component/viewComponents/viewSurvey";
import { customStyle } from "../PulseSurvey/component/customStyle";
import Button from "@mui/material/Button";
import FaceIcon from "@mui/icons-material/Face";
import RotateRightOutlinedIcon from "@mui/icons-material/RotateRightOutlined";
import TimeInOutRequestModal from "../Dashboard/TimeInOutRequestModal";
import Documents from "../Requests/Documents";

const Content = () => {
  const { setting, settings, setProjectCode, setProjectActivity } =
    useContext(GlobalContext);

  const {
    leaveOpen,
    handleLeave,
    forgotTimeInOutOpen,
    wfhOpen,
    handleWFH,
    handleMyRequets,
    overTime,
    handleOT,
    leaveConversion,
    handleConversion,
    feedbackOpen,
    handleFeedback,
    pulseSurvey,
    handleViewSurvey2,
    psychButton,
    handlePopUpSurvey,
    handleTimeInOut,
    handleDocument,
    documentOpen,
  } = React.useContext(RequestContext);
  const [collapsed, setCollapsed] = React.useState(false);
  const [toggled, setToggled] = React.useState(false);
  const handleToggleSidebar = (value) => {
    setToggled(value);
  };
  const sidebarCollapsed = () => {
    collapsed ? setCollapsed(false) : setCollapsed(true);
  };
  const { data: getPsychSurvey } = hooks.useGetSettings();
  const user = JSON.parse(localStorage.getItem("user"));
  const { data: leaveCredits } = hooks.useLeaveType(user?.employee?.id);
  const { data: projectType } = hooks.useGetProjectTypes();
  const { data: projectCode } = hooks.useProjectCode();
  const { data: projectActivity } = hooks.useProjectActivity();
  const { data: userTimeIn } = hooks.useUserDashboard();
  // * hook
  const { bgcolorPrimaryLocal, bgcolorSecondaryLocal } = useThemeColor();

  useEffect(() => {
    setProjectActivity(projectActivity);
    setProjectCode(projectCode);
  }, [projectActivity, projectCode, setProjectActivity, setProjectCode]);
  const currentYear = new Date().getFullYear();

  return (
    <Box sx={{ height: "100vh" }}>
      <Box sx={{ width: "100vw" }}>
        <Header
          setting={setting}
          settings={settings}
          handleToggleSidebar={handleToggleSidebar}
        />
      </Box>
      <Box
        sx={{
          width: "100vw",
          marginTop: "5px",
          overflow: "hidden",
          display: "flex",
          flex: "1 1 auto",
        }}
      >
        <Sidebar
          collapsed={collapsed}
          sidebarCollapsed={sidebarCollapsed}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
          secondaryColor={
            bgcolorSecondaryLocal
              ? bgcolorSecondaryLocal
              : setting?.bgcolor_secondary
              ? setting?.bgcolor_secondary
              : settings.length > 0
              ? settings[2].value
              : "#fff"
          }
          handleLeave={handleLeave}
          handleWFH={handleWFH}
          handleTimeInOut={handleTimeInOut}
          handleMyRequets={handleMyRequets}
          handleOT={handleOT}
          handleConversion={handleConversion}
          handleFeedback={handleFeedback}
          handleDocument={handleDocument}
        />

        <Box
          style={{
            backgroundColor: bgcolorPrimaryLocal
              ? bgcolorPrimaryLocal
              : setting?.bgcolor_primary
              ? setting.bgcolor_primary
              : settings.length > 0 && settings[1].value,
          }}
          className="Allcontent"
          sx={{
            width: "100%",
            height: "calc(100vh - 75px)",
            overflowY: "scroll",
          }}
        >
          <Routes>
            {content.map((route, index) => (
              <Route key={index} element={<RoleBasedRoutes route={route} />}>
                <Route path={route.path} element={<route.component />} />
              </Route>
            ))}
          </Routes>
          <Box
            sx={{
              padding: "24px",
              textAlign: "center",
            }}
          >
            <Typography variant="body1">
              © {currentYear} All rights reserved by Fligno.com
            </Typography>
          </Box>
        </Box>
        {leaveOpen && (
          <LeaveModal
            leaveOpen={leaveOpen}
            handleLeave={handleLeave}
            leaveCredits={leaveCredits}
          />
        )}
        {wfhOpen && <WfhModal wfhOpen={wfhOpen} handleWFH={handleWFH} />}
        {overTime && (
          <Overtime
            overTime={overTime}
            handleOT={handleOT}
            user={user}
            projectType={projectType}
            projectCode={projectCode}
            projectActivity={projectActivity}
          />
        )}
        {forgotTimeInOutOpen && (
          <TimeInOutRequestModal
            forgotTimeInOutOpen={forgotTimeInOutOpen}
            handleTimeInOut={handleTimeInOut}
          />
        )}
        {leaveConversion && (
          <LeaveConversion
            leaveConversion={leaveConversion}
            handleConversion={handleConversion}
          />
        )}
        {feedbackOpen && (
          <FeedbackModal
            feedbackOpen={feedbackOpen}
            handleFeedback={handleFeedback}
          />
        )}
        {getPsychSurvey && pulseSurvey && (
          <ViewSurvey
            open={pulseSurvey}
            handleViewSurvey2={handleViewSurvey2}
            viewData={getPsychSurvey?.data?.survey}
            customStyle={customStyle}
            dashboard={true}
          />
        )}
        {getPsychSurvey?.data?.enabled !== false &&
          getPsychSurvey?.data?.done === false &&
          psychButton &&
          userTimeIn?.time_inout?.timein_id > 0 && (
            <Button
              className="drawer-app"
              endIcon={<RotateRightOutlinedIcon className="draw-end" />}
              onClick={handlePopUpSurvey}
            >
              <Chip
                icon={<FaceIcon sx={{ color: "#fff !important" }} />}
                label="Psych Survey"
                className="pop-up-pulse"
              />
            </Button>
          )}
        {documentOpen ? (
          <Documents
            documentOpen={documentOpen}
            handleDocument={handleDocument}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default Content;
