import React from "react";
import Modal from "@mui/material/Modal";
import Grow from "@mui/material/Grow";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import hooks from "../../hooks/react-query/queryHooks";
import { useForm } from "react-hook-form";
import InputController from "../InputController";
import { yupResolver } from "@hookform/resolvers/yup";
import { shiftModalSchema } from "../../helper/Yup/shiftModalSchema";
import { format } from "date-fns";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { useQueryClient } from "react-query";
import TextField from "@mui/material/TextField";

const handleDateTime = (timeData) => {
  const thisDay = format(new Date(), "E LLL d yyyy") + " " + timeData;
  const dateTimeString = thisDay.toString();
  const newDateInstance = new Date(dateTimeString);
  return newDateInstance;
};

const AddShiftModal = ({
  tableStyle,
  addShift,
  handleAddModal,
  rowDetails,
}) => {
  const queryClient = useQueryClient();
  const [status, setStatus] = React.useState(null);
  const [formStatus, setFormstatus] = React.useState(true);
  const [resLoader, setResloader] = React.useState(false);
  const { mutate: addShiftMutation } = hooks.useAddSubmitShift();
  const { mutate: updateShiftMutation } = hooks.useUpdateShift();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(shiftModalSchema),
    defaultValues: {
      in: rowDetails ? handleDateTime(rowDetails.time_in) : new Date(),
      out: rowDetails ? handleDateTime(rowDetails.time_out) : new Date(),
      shift: rowDetails ? rowDetails.shift_name : "",
      grace: rowDetails ? rowDetails.grace_period : "",
    },
  });

  const onSubmit = async (data) => {
    setResloader(true);
    setFormstatus(false);
    if (rowDetails) {
      const dataParam = {
        id: rowDetails.id,
        grace_period: data.grace,
        shift_name: data.shift,
        time_in: format(data.in, "HH:mm:ss"),
        time_out: format(data.out, "HH:mm:ss"),
      };
      updateShiftMutation(dataParam, {
        onSuccess: (request) => {
          setStatus(request.status);
          setResloader(false);
          queryClient.refetchQueries(["shifts"]);
        },
        onError: (request) => {
          setStatus(request.status);
          setResloader(false);
        },
      });
    } else {
      const dataParam = {
        grace_period: data.grace,
        shift_name: data.shift,
        time_in: format(data.in, "HH:mm:ss"),
        time_out: format(data.out, "HH:mm:ss"),
      };
      addShiftMutation(dataParam, {
        onSuccess: (request) => {
          setStatus(request.status);
          setResloader(false);
          queryClient.refetchQueries(["shifts"]);
        },
        onError: (request) => {
          setStatus(request.status);
          setResloader(false);
        },
      });
    }
  };

  return (
    <Modal open={addShift}>
      <Grow
        in={addShift}
        style={{ transformOrigin: "0 0 0" }}
        {...(addShift ? { timeout: 500 } : {})}
        unmountOnExit
      >
        <Card
          className="login-modal leave1-modal"
          sx={{
            maxWidth: "700px",
            overflowY: "scroll",
            maxHeight: "calc(100vh - 40px)",
          }}
        >
          <CardHeader
            title={
              <Typography variant="h3" className="h3-bar">
                {rowDetails ? " Edit Shift" : "Add Shift"}
              </Typography>
            }
            action={
              <IconButton
                onClick={() => {
                  handleAddModal(null);
                }}
              >
                <CloseOutlinedIcon sx={tableStyle.iconS} />
              </IconButton>
            }
          />
          <Box sx={{ textAlign: "center", margin: "0px 20px 10px 20px" }}>
            {status && (
              <>
                <Chip
                  label={
                    <Typography variant="overline">
                      {status === "success"
                        ? rowDetails
                          ? "Updated"
                          : "Success"
                        : "Error"}
                    </Typography>
                  }
                  color={status === "success" ? "success" : "error"}
                />
                <Typography sx={{ m: 1 }}>
                  {status === "success"
                    ? rowDetails
                      ? "Successfully Updated Shift"
                      : "Successfully Created Shift"
                    : "Something went wrong...!"}
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  {status && (
                    <Button
                      onClick={() => {
                        handleAddModal(null);
                      }}
                    >
                      Close
                    </Button>
                  )}
                </Box>
              </>
            )}
            {resLoader && <CircularProgress />}
            {formStatus && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent sx={{ mt: "10px", mb: "10px" }}>
                  <Grid container rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={12} lg={12}>
                      <InputController
                        type="text"
                        control={control}
                        label="Shift Name"
                        name="shift"
                        errors={errors}
                        size="small"
                        rules={{ required: true }}
                        isDefaultErrorAlert={true}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4.5}>
                      <InputController
                        type="timePicker"
                        control={control}
                        label="Time In"
                        name="in"
                        errors={errors}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} lg={4.5}>
                      <InputController
                        type="timePicker"
                        control={control}
                        label="Time Out"
                        name="out"
                        errors={errors}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                      <InputController
                        type="t-number"
                        control={control}
                        label="Grace Time"
                        name="grace"
                        errors={errors}
                        size="small"
                        rules={{
                          required: true,
                        }}
                        isDefaultErrorAlert={true}
                        inputType="number"
                        InputProps={{
                          inputMode: "numeric",
                          pattern: "^[0-9\b]+$",
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions sx={tableStyle.cAction}>
                  <Button
                    size="small"
                    className="adjust-w cancel-button"
                    disableElevation
                    variant="contained"
                    onClick={() => {
                      handleAddModal(null);
                    }}
                  >
                    Cancel
                  </Button>
                  {rowDetails ? (
                    <Button
                      size="small"
                      className="adjust-w timest-button"
                      disableElevation
                      variant="contained"
                      type="submit"
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      className="adjust-w timest-button"
                      disableElevation
                      variant="contained"
                      type="submit"
                    >
                      Apply
                    </Button>
                  )}
                </CardActions>
              </form>
            )}
          </Box>
        </Card>
      </Grow>
    </Modal>
  );
};

export default AddShiftModal;
