import React from "react";
import { Modal, Box, Grid, Button } from "@mui/material";
import { ModalStyle } from "../../../../helper/ModalStyle";
import CircularProgress from "@mui/material/CircularProgress";

const ConfirmationModal = ({ handleClose, data }) => {
  return (
    <Modal
      hideBackdrop
      open={data.open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={ModalStyle}>
        <div style={{ marginTop: "20px", fontWeight: "bold" }}>{data.msg}</div>
        <Grid container sx={{ justifyContent: "end" }} spacing={3} mt={2}>
          <Grid item>
            <Button
              className="adjust-w cancel-button"
              variant="contained"
              sx={{ padding: "1px 10px 1px 10px" }}
              onClick={handleClose}
            >
              {" "}
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              className="adjust-w timest-button"
              variant="contained"
              sx={{ padding: "1px 15px 1px 15px" }}
              onClick={data.submit}
              endIcon={data.isLoading ? <CircularProgress size={20} /> : <></>}
              disabled={data.isLoading}
            >
              {data.submitLabel}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
