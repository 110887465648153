import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

const WfhSubmit = ({ handleBack, submitWfh }) => {
  const [loading, setLoading] = React.useState(false);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box className="log-box1">
            <Typography component="span" className="log-span">
              Review
            </Typography>
          </Box>
          <Button variant="contained" disableElevation onClick={handleBack}>
            Back
          </Button>
          <Box className="log-box1">
            <Typography component="span" className="log-span">
              Satisfied with your changes?
            </Typography>
          </Box>
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              submitWfh();
              setLoading(true);
            }}
          >
            Submit
          </Button>
        </>
      )}
    </Box>
  );
};

export default WfhSubmit;
