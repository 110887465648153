import apiService from "../axios";

export const ResetPassToken = async (data) => {
  try {
    const request = await apiService.post(
      `/v5/token-check`,
      {
        token: data,
      },
      {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    );
    console.log(request);
    return request.data;
  } catch (error) {
    throw error;
  }
};
