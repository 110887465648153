import React from "react";
import { useState } from "react";
import moment from "moment";
import { Grid, Paper, Box, Button as MuiButton } from "@mui/material";
import Table from "./table/Table";
import TreeView from "./TreeView";

const DataTable = ({
  TableHeader,
  TreeHeader,
  Tree,
  fetch,
  TableRow,
  handleClick,
  handleRemove,
  handleEdit,
  handleReport,
  Button,
  toggleFetch,
  TreeType,
  TableSettings = { limit: 25 },
}) => {
  const permission = JSON.parse(localStorage.getItem("user"));
  const [date, setDate] = useState("");
  const [path, setPath] = useState("");
  const [limit, setLimit] = useState(TableSettings.limit);
  const [offset, setOffset] = useState(1);

  const selectNode = (e, tags, type, name) => {
    if (type === "date") {
      let split = tags.split("/");
      if (!tags) {
        setDate("");
        return;
      }
      if (split[2]) {
        setDate(moment(split[1] + "-" + split[2]).format("YYYY-MM"));
      } else {
        setDate(moment(split[1]).format("YYYY"));
      }
    } else {
      setPath(tags);
    }
    setOffset(1);
    return;
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={9} lg={9} order={{ xs: 2, md: 1, lg: 1 }}>
          <Box ml={1}>
            <Paper
              hidden={
                Button &&
                permission.permissions.find(
                  (column) => column === Button[0].viewPermission
                )
                  ? false
                  : true
              }
              sx={{ py: 1.5, px: 1.3, mb: 1, borderRadius: "10px" }}
            >
              <Box>
                {Button?.map((column, index) => (
                  <MuiButton
                    key={index}
                    size={"small"}
                    variant={column.type}
                    onClick={column.onClick}
                    color={column.color}
                    className="timest-button"
                  >
                    {column.label}{" "}
                  </MuiButton>
                ))}
              </Box>
            </Paper>
          </Box>
          <Box ml={1}>
            <Paper sx={{ borderRadius: "10px" }}>
              <Table
                header={TableHeader}
                order={""}
                sort={""}
                fetch={fetch}
                Row={TableRow}
                handleClick={handleClick}
                handleRemove={handleRemove}
                handleEdit={handleEdit}
                handleReport={handleReport}
                toggleFetch={toggleFetch}
                date={date}
                path={path}
                limit={limit}
                setLimit={setLimit}
                offset={offset}
                setOffset={setOffset}
              />
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={12} md={3} lg={3} order={{ xs: 1, md: 2, lg: 2 }}>
          <Box ml={1} mb={1}>
            <Paper sx={{ px: 2, overflow: "hidden", borderRadius: "10px" }}>
              <TreeView
                header={TreeHeader}
                tree={Tree}
                selectNode={selectNode}
                type={TreeType}
              />
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default DataTable;
