import React from "react";
import Pagination from "./Pagination";
import SearchComponent from "./SearchComponent";
import {
  Paper,
  Box,
  Table as MuiTable,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
} from "@mui/material";

const Table = ({
  header,
  Row,
  limit = 10,
  setLimit,
  setOffset,
  offset = 1,
  setKeyword,
  total = 0,
  Buttons,
  isFetching = false,
  onPagination,
}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "end",
          pr: 2,
        }}
      >
        <SearchComponent
          setKeyword={setKeyword}
          setOffset={setOffset}
          isFetching={isFetching}
          onPagination={onPagination}
        />

        {Buttons &&
          Buttons.map((Button) => {
            return Button.Icon;
          })}
      </Box>
      <TableContainer sx={{ mt: 1.5 }} component={Paper}>
        <MuiTable size="small">
          <TableHead>
            <TableRow>
              {header?.map((column, index) => (
                <TableCell key={index} component="th">
                  <b>{column?.label}</b>
                </TableCell>
              ))}
              <TableCell component="th">
                <b>Action</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{Row}</TableBody>
        </MuiTable>
      </TableContainer>
      <TableContainer component={Paper}>
        <MuiTable>
          <TableBody>
            <TableRow>
              <Pagination
                onPageChange={setOffset}
                limit={limit}
                offset={offset}
                total={total}
                onChange={setLimit}
                onPagination={onPagination}
              />
            </TableRow>
          </TableBody>
        </MuiTable>
      </TableContainer>
    </>
  );
};

export default Table;
