import React from "react";
import { useParams } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Grow from "@mui/material/Grow";
import convImg from "../../images/conversion.svg";
import LinearProgress from "@mui/material/LinearProgress";
import hooks from "../../hooks/react-query/queryHooks";
const LeaveConversionHelper = () => {
  const { id, conversion, status } = useParams();
  const fetchHandler = React.useRef(true);
  const [loader, setLoader] = React.useState(true);
  const [data, setData] = React.useState(null);
  const { mutate: SubmiApprove } = hooks.useApproveConversion();
  const { mutate: SubmiDisapprove } = hooks.useDisapproveConversion();

  React.useEffect(() => {
    if (fetchHandler.current) {
      console.log(id, conversion, status);

      const payloadData = {
        conversion: conversion,
        id: id,
      };
      if (status === "approve") {
        //
        SubmiApprove(payloadData, {
          onSuccess: (request) => {
            setLoader(false);
            const newData = {
              approver: request.appr_name,
              employee: request.name,
              title: request.title,
            };
            setData(newData);
          },
          onError: (request) => {
            console.log(request);
          },
        });
      }
      if (status === "disapprove") {
        //
        SubmiDisapprove(payloadData, {
          onSuccess: (request) => {
            setLoader(false);
            const newData = {
              approver: request.appr_name,
              employee: request.name,
              title: request.title,
            };
            setData(newData);
          },
          onError: (request) => {
            console.log(request);
          },
        });
      }
    }
    fetchHandler.current = false;
  }, []);

  return (
    <Box
      sx={{
        width: "800px",
        margin: "auto",
        padding: "20px",
      }}
    >
      <Card
        className="card-leave"
        sx={{
          minWidth: "350px",
          margin: "15px",
          padding: "14px",
          position: "relative",
        }}
      >
        <CardMedia
          component="img"
          height="194"
          image={convImg}
          alt="conversion"
          sx={{
            height: "500px",
            position: "absolute",
          }}
        />
        <CardContent
          sx={{
            mt: "120px",
            position: "relative",
            zIndex: 1,
            padding: "16px 80px",
          }}
        >
          <Typography
            variant="h4"
            sx={{ textAlign: "center", fontSize: "1.75rem" }}
          >
            Leave Conversion Request
          </Typography>
          <Box sx={{ color: "#00C292" }}>
            <Avatar
              alt="Mail"
              src="https://i.ibb.co/MBNtqgB/newmail.png"
              sx={{ width: "340px", m: "auto", height: "350px" }}
            />
            {loader && <LinearProgress color="inherit" />}
          </Box>
          {data && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                disableElevation
                variant="contained"
                sx={{
                  minHeight: "50px",
                  minWidth: "190px",
                  color:
                    status === "approve"
                      ? "rgb(0, 194, 146)"
                      : "rgb(228, 106, 118)",
                  bgcolor:
                    status === "approve"
                      ? "rgb(235, 250, 242)"
                      : "rgb(253, 243, 245)",
                  pointerEvents: "none",
                }}
              >
                {status === "approve" ? "Approve" : "Disapprove"}
              </Button>
            </Box>
          )}
          <Box>
            <Divider sx={{ m: "15px 0px" }} />
          </Box>
          {data && (
            <Box>
              <Typography variant="h6" sx={{ textAlign: "center", mb: "20px" }}>
                Good day {data.approver},{data.employee} {data.title} Request
                has been {status}.
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default LeaveConversionHelper;
