import React from "react";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import hooks from "../../hooks/react-query/queryHooks";
import { ascOrderByDate } from "../../helper/Dashboard";
const { format, parseISO } = require("date-fns");
const color_pallete = [
  "#9FEA93",
  "#98ECA6",
  "#7CD5B3",
  "#66C3BD",
  "#47A5C0",
  "rgb(0, 194, 146)",
  "rgb(251, 150, 120)",
  "rgb(3, 201, 215)",
  "rgb(254, 201, 15)",
  "rgb(228, 106, 118)",
  "#212A3C",
  "#22A9D8",
  "red",
];
const getRandomColor = () => {
  const randomElement =
    color_pallete[Math.floor(Math.random() * color_pallete.length)];
  return randomElement;
};

const Activity = () => {
  const { status, data } = hooks.useUpcomingEvents();

  return (
    <Card className="activity-card">
      <CardHeader
        title={
          <Typography variant="h3" className="h3-bar">
            Events
          </Typography>
        }
        action={
          <CakeOutlinedIcon
            sx={{ color: `${getRandomColor()}` }}
            onClick={() => {
              console.log(getRandomColor());
            }}
          />
        }
        className="event-head"
      />
      <Box className="time-box">
        <CardContent sx={{ paddingTop: "0px" }}>
          <Typography variant="body2" color="text.secondary">
            Events this week
          </Typography>
          <Divider />
          {status === "success" &&
            ascOrderByDate(data.data).map((upEvent, index) => (
              <Timeline className="tl-comp" key={index}>
                {upEvent.thisWeek && (
                  <TimelineItem sx={{ minHeight: "55px" }}>
                    <TimelineOppositeContent className="tl-oc" variant="body2">
                      {format(parseISO(upEvent.date), "LLL dd")}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot
                        variant="outlined"
                        sx={{
                          borderColor: `${getRandomColor()}`,
                        }}
                      />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography
                        variant="h6"
                        component="span"
                        className="birth-h6"
                      >
                        {upEvent.type.charAt(0).toUpperCase() +
                          upEvent.type.slice(1)}
                      </Typography>
                      <Typography
                        variant="body2"
                        className="birth-name"
                        color="text.secondary"
                      >
                        {upEvent.name}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                )}
              </Timeline>
            ))}
        </CardContent>
        <CardContent sx={{ paddingTop: "0px" }}>
          <Typography variant="body2" color="text.secondary">
            Upcoming events
          </Typography>
          <Divider />
          {status === "success" &&
            ascOrderByDate(data.data).map((upEvent, index) => (
              <Timeline className="tl-comp" key={index}>
                {upEvent.thisWeek === false && (
                  <TimelineItem sx={{ minHeight: "55px" }}>
                    <TimelineOppositeContent className="tl-oc" variant="body2">
                      {format(parseISO(upEvent.date), "LLL dd")}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot
                        variant="outlined"
                        sx={{
                          borderColor: `${getRandomColor()}`,
                        }}
                      />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography
                        variant="h6"
                        component="span"
                        className="birth-h6"
                      >
                        {upEvent.type.charAt(0).toUpperCase() +
                          upEvent.type.slice(1)}
                      </Typography>
                      <Typography
                        variant="body2"
                        className="birth-name"
                        color="text.secondary"
                      >
                        {upEvent.name}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                )}
              </Timeline>
            ))}
        </CardContent>
      </Box>
    </Card>
  );
};
export default Activity;
