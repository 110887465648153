export const UserListTableHeader = [
  {
    label: "ID",
    key: "emp_company_id",
    isSortable: true,
  },
  {
    label: "Name",
    key: "mappedName",
    isSortable: true,
  },
  {
    label: "Email",
    key: "email",
    isSortable: true,
  },
  {
    label: "Department",
    key: "mappedDepartmentName",
    isSortable: true,
  },
  {
    label: "Status",
    key: "mappedStatus",
    isSortable: true,
  },
  {
    label: "Schedule",
    key: "mappedSchedule",
    isSortable: true,
  },
  {
    label: "Actions",
    isSortable: false,
  },
];
