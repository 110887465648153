import React from "react";
import { CSVLink } from "react-csv";
import Button from "@mui/material/Button";
import ProjectCodeToExcel from "./ProjectCodeToExcel";
import { isJsonString } from "../../../../helper/ProjectCodeSettings";
const ProjectCodeExport = ({ tableData }) => {
  const filteredData = tableData.map((td) => {
    let department = "";
    if (
      td.key === "web" ||
      td.key === "sre" ||
      td.key === "d-sre" ||
      td.key === "r&d"
    ) {
      department = td.key;
    } else {
      const departmentKey = isJsonString(td.key);
      if (departmentKey.length === 0) {
        department = "N/A";
      }
      if (departmentKey.length === 1) {
        department = departmentKey[0];
      }
      if (departmentKey.length > 1) {
        department = departmentKey.join();
      }
    }
    return {
      Code: td.name,
      Description: td.description,
      Client: td.client?.company_code,
      Type: td.codetype?.name,
      Department: department,
      Status: td.status === 1 ? "Active" : "In-Active",
      "Budget(hours)": td.budget === "0.00" ? "open" : `${td.budget}`,
      Balance: td.balance === "0.00" ? "open" : `${td.balance}`,
      Restricted: td.restricted === 0 ? "No" : "Yes",
      "Monthly Budget(hours)":
        td.limit_per_month === 0 ? "No limit" : `${td.limit_per_month}`,
    };
  });
  const headers = [
    { label: "Code", key: "Code" },
    { label: "Description", key: "Description" },
    { label: "Client", key: "Client" },
    { label: "Type", key: "Type" },
    { label: "Department", key: "Department" },
    { label: "Status", key: "Status" },
    { label: "Budget(hours)", key: "Budget(hours)" },
    { label: "Balance", key: "Balance" },
    { label: "Restricted", key: "Restricted" },
    { label: "Monthly Budget(hours)", key: "Monthly Budget(hours)" },
  ];

  return (
    <>
      <CSVLink
        data={filteredData}
        headers={headers}
        filename={"project-codes.csv"}
        target="_blank"
        style={{
          textDecoration: "none",
        }}
      >
        <Button
          className="adjust-w timest-button"
          variant="contained"
          sx={{ padding: "1px 5px 1px 5px", m: "5px" }}
        >
          Export CSV
        </Button>
      </CSVLink>
      <ProjectCodeToExcel excelData={filteredData} fileName={"project-codes"} />
    </>
  );
};

export default ProjectCodeExport;
