import { Paper, TablePagination, Tooltip, Typography } from "@mui/material";
import React from "react";
import TableContainerPaginatedAPI from "../../component/CustomTableContainer/TableContainerPaginatedAPI";
import moment from "moment";
import { addObject } from "../../helper/LeaveAndAbsences";
import { useLeaveAndAbsences } from "../../hooks/react-query/LeaveAndAbsences";
import InputElements from "./InputElements";
import { Box } from "@mui/system";

const LeaveAndAbsencesSummary = () => {
  const [query, setQuery] = React.useState("");
  const [searched, setSearched] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [range, setRange] = React.useState({
    startDate: moment().startOf("week"),
    endDate: moment().endOf("week"),
  });

  React.useMemo(
    () => setPage(0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowsPerPage, query]
  );

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  let payload = {
    api: true,
    search: query,
    end_date: moment(range.endDate).format("YYYY-MM-DD"),
    start_date: moment(range.startDate).format("YYYY-MM-DD"),
    per_page: rowsPerPage,
    page: page + 1,
  };
  const {
    refetch: refreshFunction,
    isFetching: LAAFetching,
    data: leaveAndAbsencesData,
  } = useLeaveAndAbsences(payload);
  const leaveAndAbsencesSummary = ["name"].concat(
    leaveAndAbsencesData?.headers
  );

  return (
    <Paper
      sx={{
        padding: 2,
        borderRadius: 5,
        marginLeft: 2,
        marginRight: 2,
        width: "97%",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          cursor: "context-menu",
        }}
      >
        <Tooltip
          title={"View employees leave and absences summary"}
          placement={"right"}
          arrow
        >
          <Typography
            variant="h5"
            component="h4"
            sx={{ padding: 1, marginBottom: 1 }}
          >
            Leave and Absences Summary
          </Typography>
        </Tooltip>
      </Box>{" "}
      <InputElements
        refreshFunction={refreshFunction}
        searched={searched}
        setRange={setRange}
        setSearched={setSearched}
        range={range}
        setQuery={setQuery}
      />
      <TableContainerPaginatedAPI
        columnhead={addObject(leaveAndAbsencesSummary)}
        filtereditems={leaveAndAbsencesData?.data?.data || Array(10).fill([""])}
        isFetching={LAAFetching}
        type="leave-absences-summary"
      />
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={leaveAndAbsencesData?.data?.total || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showFirstButton
        showLastButton
      />
    </Paper>
  );
};

export default LeaveAndAbsencesSummary;
