import { useState, useEffect, memo } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";

import useDebounce from "../../../../hooks/customHooks/debounce";
import useWindowSize from "../../../../hooks/customHooks/useWindowSize";
import ClearFieldButton from "./ClearFieldButton";
import { EXPORT_TYPE } from "../../../../lib/settingsTimesheet/PendingTimesheetEntries";

const HEADER_STYLES = {
  backgroundColor: "#FFF",
  borderRadius: "10px",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.05)",
  childHeader: {
    display: "flex",
    m: "14px",
    p: "16px",
    justifyContent: "space-between",
  },
};

const INPUT_FIELD_STYLES = {
  width: "100%",
  ".MuiInputBase-root": {
    padding: " 3px 2px 3px 7px",
    borderRadius: "6px",
    backgroundColor: "#f1f3f5",
    "&.Mui-disabled": {
      backgroundColor: "#E6E6E6",
    },
    "&.Mui-disabled fieldset": {
      border: "2px solid transparent",
    },
    "&.Mui-disabled:hover fieldset": {
      border: "2px solid transparent",
    },
    "& fieldset": {
      border: "2px solid transparent",
    },
    "&:hover fieldset": {
      borderColor: "#576c9f",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#21293C",
    },
  },
};

const ICON_EXPORT_BUTTON_STYLES = {
  width: "43.13px",
  backgroundColor: "#21293C",
  borderRadius: "6px",
  "&:hover": {
    backgroundColor: "#576c9f",
    boxShadow: "0 2px 5px #3b4a6c",
  },
  "&:disabled": {
    backgroundColor: "#E0E0E0",
  },
};

const EXPORT_BUTTON_STYLES = {
  color: "fff",
  borderRadius: "6px",
  boxShadow: "unset",
  backgroundColor: "#21293C",
  flexShrink: 0,
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#3b4a6c",
    boxShadow: "0 2px 5px rgba(33, 41, 60, 0.155)",
  },
};

const PendingTimesheetHeader = ({ isLoading, data, onSearch }) => {
  const [searchField, setSearchField] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const searchFilter = useDebounce(searchField);

  const { width } = useWindowSize();

  useEffect(() => {
    onSearch(searchFilter);
  }, [searchFilter, onSearch]);

  const changeSearchFieldHandler = (event) => {
    setSearchField(event.target.value);
  };

  const clearSearchFieldHandler = () => {
    setSearchField("");
  };

  const openMenuHandler = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenuHandler = () => {
    setAnchorEl(null);
  };

  const exportFileHandler = (event) => {
    const id = event.target.id;
    EXPORT_TYPE[`${id}`].exportFn(data);
  };

  const isMenuOpen = !!anchorEl;
  const isDisabled = isLoading || !data?.length;
  const showExportIcon = width < 487;
  const maxWidth = showExportIcon ? "100%" : "420px";

  return (
    <Box sx={{ ...HEADER_STYLES }}>
      <Box sx={HEADER_STYLES.childHeader}>
        {/* SEARCH FILTER */}
        <TextField
          variant="outlined"
          id="search"
          placeholder="Search"
          size="small"
          value={searchField}
          onChange={changeSearchFieldHandler}
          autoComplete="off"
          sx={{ ...INPUT_FIELD_STYLES, maxWidth }}
          inputProps={{
            style: {
              fontSize: "14px",
            },
          }}
          InputProps={{
            startAdornment: (
              <SearchIcon
                sx={{
                  marginRight: "8px",
                  color: "#495057",
                }}
              />
            ),
            endAdornment: (
              <ClearFieldButton
                show={searchField.length > 0}
                onClear={clearSearchFieldHandler}
              />
            ),
          }}
        />

        {/* EXPORT BUTTON */}
        {showExportIcon ? (
          <IconButton
            disabled={isDisabled}
            sx={ICON_EXPORT_BUTTON_STYLES}
            onClick={openMenuHandler}
          >
            <DownloadIcon
              sx={{
                color: isDisabled ? "#a6a6a6" : "#fff",
              }}
            />
          </IconButton>
        ) : (
          <Button
            variant="contained"
            disabled={isDisabled}
            startIcon={<DownloadIcon />}
            sx={EXPORT_BUTTON_STYLES}
            onClick={openMenuHandler}
          >
            Export
          </Button>
        )}

        {/* MENU */}
        <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={closeMenuHandler}>
          <MenuItem id={EXPORT_TYPE.csv.id} onClick={exportFileHandler}>
            Export as .csv
          </MenuItem>

          <Divider />

          <MenuItem id={EXPORT_TYPE.pdf.id} onClick={exportFileHandler}>
            Export as .pdf
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default memo(PendingTimesheetHeader);
