import React, { useContext } from "react";
import {
  TableRow,
  TableCell,
  Button,
  Typography,
  Grid,
  Paper,
} from "@mui/material";
import { exportSummaryApi } from "../../../api/perProjectSummary";
import { toast } from "react-toastify";
import { ProjectCodeSummaryContext } from ".";

const ProjectList = ({ item }) => {
  const { startDate, endDate } = useContext(ProjectCodeSummaryContext);

  const exportSummary = async (type) => {
    const payload = {
      api: true,
      end: endDate,
      export_type: type,
      id: item.id,
      project_code: item.name,
      start: startDate,
    };
    const response = await exportSummaryApi(payload);
    if (!response) {
      toast.error("There was a probleming exporting the file.");
      return;
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.name,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.description,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item?.client?.company_code || "",
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item?.codetype?.name || "",
            }}
          />
        </TableCell>
        <TableCell>
          <Paper sx={{ padding: "0.5em", background: "#E0E0E0" }}>
            <div
              style={{ fontSize: "0.7rem", textAlign: "center" }}
              dangerouslySetInnerHTML={{
                __html: item.key,
              }}
            />
          </Paper>
        </TableCell>
        <TableCell align="right">
          <Grid rowSpacing={2}>
            <Grid item xs={12}>
              <Button
                sx={{
                  margin: "5px",
                  backgroundColor: "#00c292",
                }}
                size="small"
                variant="contained"
                onClick={() => exportSummary("date")}
              >
                <Typography sx={{ fontSize: "0.78rem" }}>
                  EXPORT DATE SUMMARY
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                sx={{ margin: "5px", backgroundColor: "#00c292" }}
                size="small"
                variant="contained"
                onClick={() => exportSummary("lifetime")}
              >
                <Typography sx={{ fontSize: "0.78rem" }}>
                  EXPORT LIFETIME SUMMARY
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ProjectList;
