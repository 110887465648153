import React, { createContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import Loader from "../../../component/Loader";
import { getRangeDates, sortArrayTable } from "../../../helper/Reports";
import reportsQuery from "../../../hooks/react-query/PerType";
import { TableBody, TableRow, TablePagination, Tooltip } from "@mui/material";
import PerTypeFilter from "./PerTypeFilter";
import ExportModal from "./ExportModal";
import ProjectCode from "./ProjectCode";
import { PerTypeTableHeaderV5 } from "../../../lib/reports/perTypeV5";
import HeaderReports from "../../../component/Reports/Header";
import EmptyData from "../../../component/Reports/EmptyData";
import TableHeader from "../../../component/Reports/TableHeader";
import ReportTableContainter from "../../../component/Reports/TableContainter";
import { Box } from "@mui/system";

export const PerTypeV5Context = createContext({});

const PerTypeV5 = () => {
  const [range, setRange] = useState("This Week");
  const [isWeekends, setIsWeekends] = useState(true);
  const [isInactiveUsers, setIsInactiveUsers] = useState(true);
  const [startDate, setStartDate] = useState(moment().startOf("week"));
  const [endDate, setEndDate] = useState(moment().endOf("week"));
  const [search, setSearch] = useState("");
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [limit, setLimit] = useState(30);
  const [page, setPage] = useState(1);
  const [orderDirection, setOrderDirection] = useState("desc");
  const [tableData, setTableData] = useState([]);

  const handleRangeChange = (e) => {
    const date = getRangeDates(e.target.value);
    setRange(e.target.value);
    setStartDate(date.fromDate);
    setEndDate(date.toDate);
  };

  const handleChangeRowsPerPage = (e) => {
    setPage(1);
    setLimit(e.target.value);
  };

  const handleRefresh = () => {
    if (page === 1 && limit === 30) {
      refetch();
      return;
    }
    setPage(1);
    setLimit(30);
  };

  const {
    data: typeList,
    refetch,
    isFetching,
  } = reportsQuery.usePerType({
    criteria: { page, limit, search },
    payload: {
      fromDate: moment(startDate).format("YYYY-MM-DD"),
      toDate: moment(endDate).format("YYYY-MM-DD"),
      inActives: isInactiveUsers,
      weekends: isWeekends,
      search: search,
      page_size: limit,
      page: page,
    },
  });

  useEffect(() => {
    if (typeList) {
      setTableData(typeList?.data || []);
    }
  }, [typeList]);

  const handleChangePage = (page) => {
    setPage(page + 1);
  };

  const handleSortRequest = (key) => {
    let direction = "asc";
    direction = orderDirection === "desc" ? "asc" : "desc";
    setOrderDirection(direction);
    setTableData((prev) => sortArrayTable(prev, direction, key));
  };

  const value = useMemo(
    () => ({
      isExportModalOpen,
      setIsExportModalOpen,
      isWeekends,
      setIsWeekends,
      isInactiveUsers,
      setIsInactiveUsers,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      search,
      setSearch,
      refetch,
      range,
      setRange,
      isFetching,
      setPage,
    }),
    [
      isExportModalOpen,
      setIsExportModalOpen,
      range,
      isWeekends,
      setIsWeekends,
      isInactiveUsers,
      setIsInactiveUsers,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      search,
      setSearch,
      refetch,
      setRange,
      isFetching,
    ]
  );

  return (
    <div>
      <Loader isLoading={isFetching} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          cursor: "context-menu",
        }}
      >
        <Tooltip
          title={"View company timesheet per type"}
          placement={"right"}
          arrow
        >
          <Box>
            <HeaderReports text={"Timesheet Per Type"} />
          </Box>
        </Tooltip>
      </Box>
      <PerTypeV5Context.Provider value={value}>
        {/* Filter */}
        <PerTypeFilter
          handleRangeChange={handleRangeChange}
          handleRefresh={handleRefresh}
        />
        <ExportModal />
        {/* End of  Filter */}

        {!tableData?.length && !isFetching ? (
          <EmptyData />
        ) : (
          <ReportTableContainter>
            <TableHeader
              isCollapsable={true}
              headerList={PerTypeTableHeaderV5}
              handleSortRequest={handleSortRequest}
              orderDirection={orderDirection}
            />
            <TableBody>
              {tableData?.map((item) => {
                return (
                  <ProjectCode
                    key={item.row_id}
                    item={item}
                    typeId={item.row_id}
                  />
                );
              })}
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                  count={typeList?.total || 0}
                  page={typeList?.current_page ? typeList.current_page - 1 : 0}
                  onPageChange={(e, page) => {
                    handleChangePage(page);
                  }}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  showFirstButton={true}
                  showLastButton={true}
                />
              </TableRow>
            </TableBody>
          </ReportTableContainter>
        )}
      </PerTypeV5Context.Provider>
    </div>
  );
};

export default PerTypeV5;
