import * as yup from "yup";

export const overTimeSchema = yup
  .object({
    type: yup.string().required("Please select type."),
    code: yup.string().required("Please select code."),
    activity: yup.string().required("Please select activity."),
    purpose: yup.string().required("Please state your purpose."),
  })
  .required();
