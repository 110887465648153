import FilterBarContainer from "../container/FilterBarContainer";
import RangeSelection from "./RangeSelection";
import DateSelection from "./DateSelection";
import SearchField from "./SearchField";
import CheckboxFilter from "./CheckboxFilter";
import ActionButtons from "./ActionButtons";

const FilterBar = ({ state, handler }) => {
  const {
    searchString,
    range,
    fromDate,
    toDate,
    isWeekendShown,
    isInactiveUsersShown,
  } = state;

  const {
    handleSearchString,
    handleRangeChange,
    handleFromDateChange,
    handleToDateChange,
    handleIsWeekendShownChange,
    handleIsInactiveUsersShownChange,
    handleRefetch,
  } = handler;

  return (
    <FilterBarContainer>
      <RangeSelection range={range} handleRangeChange={handleRangeChange} />

      <DateSelection
        fromDate={fromDate}
        toDate={toDate}
        handleFromDateChange={handleFromDateChange}
        handleToDateChange={handleToDateChange}
      />

      <SearchField
        searchString={searchString}
        handleSearchString={handleSearchString}
      />

      <CheckboxFilter
        isWeekendShown={isWeekendShown}
        isInactiveUsersShown={isInactiveUsersShown}
        handleIsWeekendShownChange={handleIsWeekendShownChange}
        handleIsInactiveUsersShown={handleIsInactiveUsersShownChange}
      />

      <ActionButtons
        fromDate={fromDate}
        toDate={toDate}
        handleRefresh={handleRefetch}
        isWeekendShown={isWeekendShown}
        isInactiveUsersShown={isInactiveUsersShown}
      />
    </FilterBarContainer>
  );
};

export default FilterBar;
