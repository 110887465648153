import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import InputController from "../InputController";

const UserInformationEditCard = ({
  data: { title, fields },
  control,
  size = "small",
  errors = {},
}) => (
  <Grid container>
    <Typography variant="h6" mb="18px">
      {title}
    </Typography>

    <Grid container spacing={3}>
      {fields.map((field) => (
        <Grid item xs={12} md={6} key={field.fieldName}>
          <InputController
            type={field.type}
            control={control}
            name={field.fieldName}
            label={field.label}
            errors={errors}
            isDefaultErrorAlert={true}
            size={size}
            options={field.selection}
          />
        </Grid>
      ))}
    </Grid>
  </Grid>
);

export default UserInformationEditCard;
