import React from "react";
import { aioFilter } from "../../component/Colleagues/DeptColor";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const TimeInOut = ({ inOutFilter, setInOutFilter }) => {
  const handleChange = (event) => {
    setInOutFilter(event.target.value);
  };

  return (
    <FormControl
      sx={{
        minWidth: "110px",
      }}
    >
      <InputLabel>Filter IN / OUT</InputLabel>
      <Select
        size="small"
        sx={{ margin: "5px" }}
        value={inOutFilter}
        onChange={handleChange}
      >
        {aioFilter.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TimeInOut;
