import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

import { generateRandomNum } from "../../../../helper/LeaveManagement/LeaveConversionApplications";
import StyledTableCell from "../table/StyledTableCell";

const TextSkeleton = ({ minWidth, maxWidth, justifyContent = "center" }) => {
  return (
    <StyledTableCell>
      <Box sx={{ width: "100%", display: "grid" }}>
        <Skeleton
          variant="text"
          width={generateRandomNum(minWidth, maxWidth)}
          sx={{ fontSize: "24px", justifySelf: justifyContent }}
        />
      </Box>
    </StyledTableCell>
  );
};

export default TextSkeleton;
