import React from "react";
import {
  Backdrop,
  Modal,
  Fade,
  Box,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import hooks from "../../hooks/react-query/ManageFeedback";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const FeedbackStatus = ({ item, open, close, type }) => {
  const { mutate } = hooks.useUpdateStatus();

  const updateStatusHandler = () => {
    const data = {
      id: item?.id,
      value: item?.status,
    };
    mutate(data);
    close(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      // onClose={close(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box className="typ-over" sx={{ marginBottom: "10px" }}>
            <Typography
              variant="overline"
              sx={{ paddingLeft: "10px", alignSelf: "center" }}
            >
              {item?.status === "Open"
                ? "Are you sure to close this feedback?"
                : "Are you sure to open this feedback?"}
            </Typography>
          </Box>
          <TextField
            sx={{ margin: "10px 0px" }}
            id="outlined-basic"
            label="Category"
            variant="outlined"
            fullWidth
            readOnly
            value={item?.category}
          />
          <TextField
            sx={{ margin: "10px 0px" }}
            id="outlined-basic"
            label="Subject"
            variant="outlined"
            readOnly
            fullWidth
            value={item?.subject}
          />
          <TextField
            sx={{ margin: "10px 0px" }}
            id="outlined-basic"
            label="Content"
            variant="outlined"
            fullWidth
            readOnly
            value={item?.content}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Button
              fullWidth
              size="large"
              sx={{ marginX: "5px" }}
              variant="contained"
              onClick={() => close(false)}
            >
              <Typography sx={{ fontSize: "0.78rem" }}>CANCEL</Typography>
            </Button>
            <Button
              fullWidth
              sx={{ marginX: "5px", backgroundColor: "#00c292" }}
              size="large"
              variant="contained"
              onClick={updateStatusHandler}
            >
              <Typography sx={{ fontSize: "0.78rem" }}>CONFIRM</Typography>
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default FeedbackStatus;
