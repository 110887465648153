import React, { useState, useEffect, useContext } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import moment from "moment";
import Datepicker from "../../component/Dashboard/datepicker";
import TimesheetV5 from "../../component/timesheet";
import { IsMobile } from "../../helper/isMobile";
import { TimesheetContext } from "../../globalstate/UserContext/timesheetcontext";
import { AlertComponent } from "../../component/Alerts";
import Entry from "../../component/timesheet/entry";
import DashboardBarchart from "../../component/Dashboard/apexChart/dashboardBarchart";
import Stack from "@mui/material/Stack";
import {
  getWeeklyConsoUI,
  getWeeklyTimesheet,
  getHours,
} from "../../helper/timesheet";
import { useGetTimesheet } from "../../hooks/react-query/timesheet";

const { getDay } = require("date-fns");
const TimesheetPage = () => {
  const {
    setTimesheet,
    setRefetchMonthlyTimesheet,
    setRefetchWeeklyTimesheet,
    setWeeklyDataTimesheet,
    setWeeks,
    apiResponse,
    setAPIResponse,
    setWeekDates,
    weekDates,
    entry,
    setEntry,
    setUserDepartment,
    setOpenBarChartEntryModal,
  } = useContext(TimesheetContext);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [monthDate, setMonthDate] = useState(new Date());
  // const [isInWeek, setIsInWeek] = useState(true);
  const [isInMonth, setIsInMonth] = useState(true);
  const [barChartData, setBarChartData] = useState([]);
  const [totalHours, setTotalHours] = useState([]);
  const [timesheetTotalWeek, setTimesheetTotalWeek] = useState([]);

  const getUser = JSON.parse(localStorage.getItem("user"));
  const id = getUser?.id;
  const year = moment(selectedDate, "DD/MM/YYYY").format("YYYY");
  const month = moment(selectedDate, "DD/MM/YYYY").format("MM");
  const timesheetMonthPayload = { id, year, month };

  // const {
  //   status: statusMonthlyTimesheet,
  //   data: dataMonthlyTimesheet,
  //   refetch: refetchMonthlyTimesheet,
  // } = useQuery(
  //   ["monthly-timesheet"],
  //   () => api.GetTimesheetMonth(timesheetMonthPayload),
  //   { enabled: isInMonth && selectedDate instanceof Date }
  // );

  const {
    status: statusMonthlyTimesheet,
    data: dataMonthlyTimesheet,
    isFetching,
  } = useGetTimesheet(timesheetMonthPayload, selectedDate, isInMonth);
  const handleEntryModal = () => {
    entry ? setEntry(false) : setEntry(true);
  };

  useEffect(() => {
    const getWeekDates = () => {
      let weekDates = [];

      for (let i = 0; i < 7; i++)
        weekDates.push(
          moment(selectedDate).startOf("isoWeek").add(i, "d").format("ll")
        );
      return weekDates;
    };
    setWeekDates(getWeekDates());
    setWeeks(getWeekDates());
  }, [isInMonth, selectedDate, setWeekDates, setWeeks]);

  useEffect(() => {
    //check if selectedDate is in Month
    if (
      moment(selectedDate).isSame(monthDate, "month") &&
      statusMonthlyTimesheet === "success"
    ) {
      setIsInMonth(false);
    } else if (!statusMonthlyTimesheet === "success") {
      setIsInMonth(true);
    } else if (!moment(selectedDate).isSame(monthDate, "month")) {
      setMonthDate(selectedDate);
      setIsInMonth(true);
    }
  }, [monthDate, selectedDate, statusMonthlyTimesheet, weekDates]);

  useEffect(() => {
    if (statusMonthlyTimesheet === "success") {
      setWeeklyDataTimesheet(
        getWeeklyConsoUI(dataMonthlyTimesheet?.time_data_conso, weekDates)
      );
      setBarChartData({
        time_data: getWeeklyTimesheet(
          dataMonthlyTimesheet.time_data,
          weekDates
        ),
        today_total_hours: getHours(dataMonthlyTimesheet.time_data, weekDates)
          .today,
        weekly_total_hours: getHours(dataMonthlyTimesheet.time_data, weekDates)
          .weeklyTotal,
      });
      setTimesheet(dataMonthlyTimesheet.time_data);
      setUserDepartment(dataMonthlyTimesheet?.employee?.department?.name);

      setTimesheetTotalWeek(
        getHours(dataMonthlyTimesheet.time_data, weekDates).weeklyTotal
      );
      setTotalHours(getHours(dataMonthlyTimesheet.time_data, weekDates).weekly);
      setOpenBarChartEntryModal(
        getWeeklyTimesheet(dataMonthlyTimesheet.time_data, weekDates)
      );
    }
  }, [
    statusMonthlyTimesheet,
    setTimesheet,
    dataMonthlyTimesheet,
    setRefetchMonthlyTimesheet,
    setRefetchWeeklyTimesheet,
    setWeeklyDataTimesheet,
    setUserDepartment,
    setOpenBarChartEntryModal,
    weekDates,
  ]);
  const CloseAlert = () => {
    setAPIResponse();
  };

  const weeklyHoursArray = totalHours.map((data) => parseFloat(data.hours));
  const timesheetTotalToday =
    weeklyHoursArray[
      +getDay(selectedDate) === 0 ? 6 : +getDay(selectedDate) - 1
    ];

  const handleChangeDate = (e) => {
    setSelectedDate(e);
  };

  return (
    <Grid container={!IsMobile()} sx={{ padding: "0px 20px 0px 20px" }}>
      <Grid container>
        <Grid item lg={8}>
          <DashboardBarchart isFetching={isFetching} user={barChartData} />
        </Grid>
        <Grid item lg={4}>
          <Datepicker
            onChange={handleChangeDate}
            value={selectedDate}
            isFetching={isFetching}
          />

          <Paper
            style={{
              display: "flex",
              margin: "20px",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <div style={{ padding: "8px", display: "flex", margin: "5px" }}>
              <Typography variant="h4" className="timest-h4">
                Today: &nbsp;{" "}
                {isNaN(timesheetTotalToday)
                  ? "0.00"
                  : parseFloat(timesheetTotalToday).toFixed(2)}
                &nbsp;{timesheetTotalToday > 1 ? "hrs" : "hr"}
              </Typography>
            </div>
            <div style={{ padding: "8px", display: "flex", margin: "5px" }}>
              <Typography variant="h4" className="timest-h4">
                This Week: &nbsp;{" "}
                {isNaN(parseFloat(timesheetTotalWeek))
                  ? "0.00"
                  : parseFloat(timesheetTotalWeek).toFixed(2)}
                &nbsp;{parseFloat(timesheetTotalWeek) > 1 ? "hrs" : "hr"}
              </Typography>
            </div>
          </Paper>
        </Grid>
      </Grid>

      <Grid item lg={12}>
        <TimesheetV5
          weekDates={weekDates}
          handleEntryModal={handleEntryModal}
          totalHours={totalHours}
          isFetching={isFetching}
        />
      </Grid>
      {apiResponse !== undefined && (
        <Stack
          sx={{
            width: "fit-content",
            position: "sticky",
            bottom: 0,
            zIndex: 1000,
          }}
        >
          <AlertComponent
            severity={apiResponse.status}
            message={apiResponse.msg}
            handleOpenReaction={CloseAlert}
          />
        </Stack>
      )}
      <Entry />
    </Grid>
  );
};

export default TimesheetPage;
