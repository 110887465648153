import Box from "@mui/material/Box";

import sampleCsvImage from "../../../images/sample_csv.png";

const SampleCsv = () => {
  return (
    <Box
      sx={{
        display: "grid",
        placeItems: "center",
        overflow: "auto",
        minHeight: "200px",
        border: "3px solid #cbd5e0",
      }}
    >
      <img
        component="img"
        alt="Sample CSV format"
        src={sampleCsvImage}
        style={{ minWidth: "912px", width: "100%" }}
      />
    </Box>
  );
};

export default SampleCsv;
