import { Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const ApproverSkeleton = () => {
  return (
    <Box sx={{ display: "flex", padding: "10px 0 0" }}>
      <Skeleton
        variant="rounded"
        width={150}
        height={40}
        sx={{ margin: "0 10px" }}
      />
      <Skeleton
        variant="rounded"
        width={150}
        height={40}
        sx={{ margin: "0 10px" }}
      />
      <Skeleton
        variant="rounded"
        width={150}
        height={40}
        sx={{ margin: "0 10px" }}
      />
    </Box>
  );
};
export default ApproverSkeleton;
