import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";

const BaseDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    borderRadius: "6px",
  },
  "& .MuiDialogTitle-root": {
    padding: "18px 24px 24px",
  },
  "& .MuiDialogContent-root": {
    padding: "6px 24px",
  },
  "& .MuiDialogActions-root": {
    padding: "32px 24px 24px",
  },
}));

export default BaseDialog;
