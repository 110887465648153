export const leaveTypesHeader = [
  {
    label: "Name",
    key: "name",
    isSortable: true,
  },
  {
    label: "Code",
    key: "shortname",
    isSortable: true,
  },
  {
    label: "Description",
    key: "description",
    isSortable: true,
    type: "number",
  },
  {
    label: "Credits",
    key: "credits",
    isSortable: true,
    type: "number",
  },
  {
    label: "Department",
    key: "department",
  },
  {
    label: "Actions",
    key: "actions",
    isSortable: true,
    align: "center",
  },
];
