import { useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import apiService from "../../../api/axios";

// /page, rowsPerPage, query, batchYear
const useLeaveCredit = (batchYear, page, rowsPerPage, query, inactive) => {
  return useQuery(
    ["leave-credit", batchYear, page, rowsPerPage, query],
    async () => {
      try {
        let response = await apiService.get(
          `v1/leave/get-active-credits?&api=true&batch=${batchYear}&year=${batchYear}&page=${page}&per_page=${rowsPerPage}&search=${query}`,
          {
            Accept: `application/json`,
            "Content-Type": `multipart/form-data`,
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    {
      enabled: inactive,
      keepPreviousData: true,
      staleTime: 500 * 1000,
    },

    {
      onSuccess: async () => {
        toast.success("Updated list", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      },
      onError: async () => {
        toast.error("Oops, something went wrong.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      },
    }
  );
};

const useShowAllLeaveCredit = (
  batchYear,
  page,
  rowsPerPage,
  query,
  inactive
) => {
  return useQuery(
    ["show-all-leave-credit", batchYear, page, rowsPerPage, query],
    async () => {
      try {
        let response = await apiService.get(
          `v1/leave/get-all-credits?&api=true&batch=${batchYear}&year=${batchYear}&page=${page}&per_page=${rowsPerPage}&search=${query}`,
          {
            Accept: `application/json`,
            "Content-Type": `multipart/form-data`,
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    {
      enabled: !inactive,
      keepPreviousData: true,
      staleTime: 500 * 1000,
    },

    {
      onSuccess: async () => {
        toast.success("Updated list", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      },
      onError: async () => {
        toast.error("Oops, something went wrong.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      },
    }
  );
};

const useUpdateLeaveCredit = (inactive) => {
  return useMutation(
    "update-leave-credit",
    async (data) => {
      let param = data[0];
      try {
        let response = await apiService.post(`v1/leave/update-credit`, {
          Accept: `application/json`,
          "Content-Type": `multipart/form-data`,
          api: true,
          ...param,
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: async () => {
        // inactive
        //   ? queryClient.refetchQueries(["leave-credit"])
        //   : queryClient.refetchQueries(["show-all-leave-credit"]);
        toast.success("Leave credits successfully updated.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      },
    }
  );
};

const useLeaveCreditHead = () => {
  return useQuery("leave-credit-head", async () => {
    try {
      let response = await apiService.get(`v1/leave/get-credit-headers`, {
        Accept: `application/json`,
        "Content-Type": `multipart/form-data`,
        api: true,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  });
};

const leaveCredit = {
  useLeaveCreditHead,
  useLeaveCredit,
  useUpdateLeaveCredit,
  useShowAllLeaveCredit,
};

export default leaveCredit;
