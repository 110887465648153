export const ALL_PENDING_ENTRIES_QUERY_KEY = "all_pending-timesheet-entries";

export const HEADER_CELL_ID = {
  DATE: "date",
  NAME: "name",
  TYPE: "type",
  CODE: "code",
  ACTIVITY: "activity",
  PROJECT_CODE_STATUS: "code_status",
  PROJECT_CODE_MONTHLY_LIMIT: "code_limit",
  DETAILS: "details",
  HOURS_RENDERED: "hours",
  ACTION: "action",
};

export const ORDER = {
  ASCENDING: "asc",
  DESCENDING: "desc",
};

export const headerCells = [
  {
    id: HEADER_CELL_ID.NAME,
    label: "Name",
    isSortable: true,
  },
  {
    id: HEADER_CELL_ID.DATE,
    label: "Date",
    isSortable: true,
  },
  {
    id: HEADER_CELL_ID.TYPE,
    label: "Type",
    isSortable: true,
  },
  {
    id: HEADER_CELL_ID.CODE,
    label: "Code",
    isSortable: true,
  },
  {
    id: HEADER_CELL_ID.ACTIVITY,
    label: "Activity",
    isSortable: true,
  },
  {
    id: HEADER_CELL_ID.PROJECT_CODE_STATUS,
    label: "Project Code Status",
    isSortable: true,
  },
  {
    id: HEADER_CELL_ID.PROJECT_CODE_MONTHLY_LIMIT,
    label: "Project Code Monthly Limit",
    isSortable: true,
  },
  {
    id: HEADER_CELL_ID.DETAILS,
    label: "Details",
    isSortable: false,
  },
  {
    id: HEADER_CELL_ID.HOURS_RENDERED,
    label: "Hours Rendered",
    isSortable: true,
  },
  {
    id: HEADER_CELL_ID.ACTION,
    label: "Action",
    isSortable: false,
  },
];

export const ACTION_TYPE = {
  update: "update",
  delete: "delete",
};

export const CONFIRMATION_TEXT = {
  [ACTION_TYPE.update]:
    "Please confirm that you want to update this timesheet entry.",
  [ACTION_TYPE.delete]:
    "Please confirm that you want to delete this timesheet entry.",
};

export const ENDPOINT = {
  [ACTION_TYPE.update]: "v5/timesheet/time-update",
  [ACTION_TYPE.delete]: "v5/timesheet/time-remove",
};

export const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100];

export const CURRENT_STATUS_OPTIONS = [
  { value: 0, name: "inactive" },
  {
    value: 1,
    name: "active",
  },
];
