import { startOfWeek, endOfWeek, format } from "date-fns";

export const formatDate = (date) =>
  format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");

export const getStartAndEndDate = (date) => ({
  start_date: formatDate(startOfWeek(date)),
  end_date: formatDate(endOfWeek(date)),
});

export const isFutureDate = (date1, date2) => {
  return new Date(date2) > new Date(date1);
};

export const isSameDate = (date1, date2) =>
  new Date(date1).toString() === new Date(date2).toString();
