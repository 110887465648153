export const customStyle = {
  surveyHead: {
    borderRadius: "10px",
    p: "25px",
    m: "15px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    position: "sticky",
    top: "0",
    zIndex: "2",
    "@media(max-width: 768px)": {
      position: "relative",
    },
    display: "flex",
    flexDirection: "column",
  },
  filterCard: {
    borderRadius: "10px",
    p: "25px",
    m: "15px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    "@media(max-width: 768px)": {
      position: "relative",
    },
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    height: "35px",
    m: "5px",
    bgcolor: "#212A3C",
    fontSize: "0.75rem",
  },
  boxDisplay: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  chip: {
    borderRadius: "5px",
    color: "#fff",
    bgcolor: "#00C292",
    width: "fit-content",
  },
  title: {
    margin: "15px 0px 0px",
    fontWeight: 600,
  },
  desc: {
    margin: "10px 0px 0px",
    color: " rgb(153, 171, 180)",
    maxHeight: "100px",
    minHeight: "100px",
    overflow: "auto",
  },
  quest: {
    margin: "5px",
    fontSize: "0.875rem",
    lineHeight: 1.5,
    display: "block",
  },
  boxQ: {
    display: "flex",
    alignItems: "center",
  },
  qq: {
    height: "13px",
    width: "13px",
    borderRadius: "100%",
    bgcolor: "#FBAF01",
    m: "2px",
  },

  button2: {
    height: "35px",
    m: "5px",
    bgcolor: "#7BD28B",
    fontSize: "0.75rem",
  },
  button3: {
    height: "35px",
    m: "5px",
    bgcolor: "#898DFB",
    fontSize: "0.75rem",
  },
  button4: {
    height: "35px",
    m: "5px",
    bgcolor: "#BF89FB",
    fontSize: "0.75rem",
  },
  button5: {
    height: "35px",
    m: "5px",
    bgcolor: "#FFAC30",
    fontSize: "0.75rem",
  },
  button6: {
    height: "35px",
    m: "5px",
    bgcolor: "#F44335",
    fontSize: "0.75rem",
  },
  desc2: {
    margin: "10px 0px 0px",
    color: " rgb(153, 171, 180)",
    overflow: "auto",
  },
  vv: {
    p: "12px",
  },
  chippy: {
    color: "#fff",
    bgcolor: "#21293C",
    borderRadius: "5px",
  },
  boxChippy: {
    display: "flex",
    justifyContent: "space-between",
  },
};
