import { Popover, Button, IconButton, Typography } from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
// import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { useDeleteSkill } from "../../../hooks/react-query/SettingsSytem/Skills";
const initDataState = {
  deleteMode: false,
};
const SkillPopper = ({ PopOver, handleClose, handleClickEdit }) => {
  const [data, setData] = useState(initDataState);
  const open = Boolean(PopOver);
  const id = PopOver ? "simple-popover" : undefined;

  const { mutate } = useDeleteSkill();
  // const handleClickDelete = () => {
  //   setData({ ...data, deleteMode: true });
  // };
  const handleCancelDelete = () => {
    setData({ ...data, deleteMode: false });
  };

  const handleDelete = () => {
    mutate();
  };
  return (
    <Popover
      open={open}
      id={id}
      anchorEl={PopOver}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      {!data.deleteMode ? (
        <div>
          <div>
            <Button
              sx={{
                textTransform: "none",
                color: "#808080",
                "&:hover": {
                  backgroundColor: "rgb(0, 0, 255, 0.2)",
                  color: "rgb(0, 0, 255)",
                  // boxShadow: 'none',
                },
                width: "100%",
                justifyContent: "start",
              }}
              size={"small"}
              startIcon={<CreateIcon fontSize="small" />}
              onClick={handleClickEdit}
            >
              Edit
            </Button>
          </div>
          {/* <div>
            <Button
              sx={{
                textTransform: "none",
                color: "#808080",
                "&:hover": {
                  backgroundColor: "rgb(0, 0, 255, 0.2)",
                  color: "rgb(0, 0, 255)",
                  // boxShadow: 'none',
                },
                width: "100%",
                justifyContent: "start",
              }}
              size={"small"}
              startIcon={<DeleteIcon fontSize="small" />}
              onClick={handleClickDelete}
            >
              Delete
            </Button>
          </div> */}
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "center", margin: "8px" }}>
          <Typography variant="caption" mr={1}>
            Delete skill?
          </Typography>
          <IconButton onClick={handleCancelDelete}>
            <ClearIcon style={{ color: "red", fontSize: "1rem" }} />
          </IconButton>
          <IconButton onClick={handleDelete}>
            <CheckIcon style={{ color: "green", fontSize: "1rem" }} />
          </IconButton>
        </div>
      )}
    </Popover>
  );
};
export default SkillPopper;
