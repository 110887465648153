import React, { useState } from "react";
import { Modal, Box, Typography, TextField, Grid, Button } from "@mui/material";
import DropDownSelect from "../../../../component/CustomElement/DropDownSelect";
import { ModalStyle } from "../../../../helper/ModalStyle";
import {
  HolidayTypeDropDown,
  addHolidayVar,
  IncludeDropDown,
  dayLengthDropDown,
} from "../../../../helper/Settings/Time";
import DatePickerComponent from "../../../../component/DatePicker";
import { useAddHoliday } from "../../../../hooks/react-query/Settings/time";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";

const Addholiday = ({ open, handleClose }) => {
  const [add, setAdd] = useState(addHolidayVar);
  const handleType = (e) => {
    setAdd({ ...add, holiday_type: e.target.value });
  };

  const HandleIncludes = (e) => {
    setAdd({ ...add, includes: e.target.value });
  };
  const HandleLength = (e) => {
    setAdd({ ...add, holiday_length: e.target.value });
  };

  const { mutate, isLoading } = useAddHoliday();

  const handleSubmit = () => {
    mutate(add, {
      onSuccess: () => {
        toast.success("Successfully added holiday: " + add.holiday_name);
        closeModal();
      },
      onError: () => toast.error("Something went wrong. Please try again"),
    });
  };

  const handleDate = (date) => {
    setAdd({ ...add, holiday_start: date });
  };
  const handleResume = (date) => {
    setAdd({ ...add, holiday_end: date });
  };

  const closeModal = () => {
    setAdd(addHolidayVar);
    handleClose();
  };

  return (
    <Modal
      hideBackdrop
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={ModalStyle}>
        <Typography variant="h5" mb={5}>
          Add Holiday / Event
        </Typography>
        <Grid container>
          <Grid
            container
            style={{
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <Grid item m={1}>
              <TextField
                variant="outlined"
                label="Name"
                size="small"
                sx={{ minWidth: 310 }}
                inputProps={{ maxLength: 100 }}
                onChange={(e) =>
                  setAdd({ ...add, holiday_name: e.target.value })
                }
              />
            </Grid>
            <Grid item m={1}>
              <DropDownSelect
                DropDownData={HolidayTypeDropDown}
                objectName={{ val: "id", name: "id" }}
                value={add.holiday_type}
                onChange={handleType}
                size={"small"}
                label={"Type"}
                minWidth={230}
              />
            </Grid>
          </Grid>

          <Grid container m={1}>
            <Grid item m={0.5}>
              <DatePickerComponent
                dateValue={add.holiday_start}
                handleDatePicker={handleDate}
                label={"Start"}
                margin={"0px"}
              />
            </Grid>
            <Grid item m={0.5}>
              <DatePickerComponent
                dateValue={add.holiday_end}
                handleDatePicker={handleResume}
                label={"Resume"}
                margin={"0px"}
              />
            </Grid>
            <Grid item m={0.5}>
              <DropDownSelect
                DropDownData={dayLengthDropDown}
                onChange={HandleLength}
                value={add.holiday_length}
                objectName={{ val: "id", name: "id" }}
                label={"Length"}
                size={"small"}
                minWidth={100}
              />
            </Grid>
            <Grid item m={0.5}>
              <DropDownSelect
                DropDownData={IncludeDropDown}
                objectName={{ val: "id", name: "id" }}
                value={add.includes}
                onChange={HandleIncludes}
                label={"Include staff"}
                minWidth={130}
                size={"small"}
              />
            </Grid>
          </Grid>
          <Grid container m={1}>
            <TextField
              variant="outlined"
              label="Remarks"
              size="small"
              multiline
              rows={4}
              inputProps={{ maxLength: 100 }}
              onChange={(e) =>
                setAdd({ ...add, holiday_remarks: e.target.value })
              }
              fullWidth
            />
          </Grid>

          <Grid container sx={{ justifyContent: "end" }} spacing={3} mt={2}>
            <Grid item>
              <Button
                className="adjust-w cancel-button"
                variant="contained"
                sx={{ padding: "1px 10px 1px 10px" }}
                onClick={closeModal}
              >
                {" "}
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                className="adjust-w timest-button"
                variant="contained"
                sx={{ padding: "1px 15px 1px 15px" }}
                onClick={handleSubmit}
                disabled={
                  isLoading ||
                  add.name === "" ||
                  add.type === "" ||
                  add.length === "" ||
                  add.includes === ""
                }
                endIcon={isLoading ? <CircularProgress size={20} /> : <></>}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default Addholiday;
