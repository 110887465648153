import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  TableRow,
  TableCell,
  Grid,
  Tooltip,
  IconButton,
  CircularProgress,
} from "@mui/material";
import {
  ModeEditOutline,
  ScheduleOutlined,
  PersonOutline,
  NotInterested,
  RestoreOutlined,
} from "@mui/icons-material";
import { useMutation } from "react-query";
import { toggleUserStatus } from "../../../api/userList";
import { toast } from "react-toastify";
import { UserListContext } from ".";
import EditUserModal from "./EditUserModal";
import EditUserInformationModal from "../../../component/UserInformation/modal/TransitionedModal";

const UserLists = ({ item }) => {
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const { refetch } = useContext(UserListContext);
  const toggleBtnTitle = !!item.status ? `Deactivate User` : "Activate User";

  const { isFetchedDepartments } = useContext(UserListContext);

  const toggleStatus = useMutation((newData) => toggleUserStatus(newData), {
    onSuccess: async (updateData) => {
      toast.success(
        `User Successfully ${
          !!updateData.data.status ? "Activated" : "Deactivated"
        }`
      );
      refetch();
    },
    onError: (error) => {
      /* temporary */
      toast.error("ERROR");
    },
  });

  const toggleEditModalHandler = () => {
    setIsOpenEditModal((prev) => !prev);
  };

  return (
    <>
      <TableRow sx={{ height: "4em" }}>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item?.emp_company_id,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.mappedName,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.email,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.mappedDepartmentName,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.mappedStatus,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.mappedSchedule,
            }}
          />
        </TableCell>
        <TableCell>
          <div>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Tooltip title="View User Schedule" placement="top">
                  <IconButton
                    component={Link}
                    to={`/employee-schedule/${item.employee?.id}`}
                    target="_blank"
                  >
                    <ScheduleOutlined color="success" aria-label="twitter" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="View User Information" placement="top">
                  <IconButton
                    component={Link}
                    to={`${item.employee?.id}`}
                    target="_blank"
                  >
                    <PersonOutline color="success" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Edit User Information" placement="top">
                  <IconButton onClick={toggleEditModalHandler}>
                    <ModeEditOutline color="success" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title={toggleBtnTitle} placement="top">
                  <IconButton
                    onClick={() =>
                      toggleStatus.mutate({
                        id: item.id,
                        status: !!item.status ? 0 : 1,
                      })
                    }
                  >
                    {!!item.status ? (
                      <NotInterested color="warning" />
                    ) : (
                      <RestoreOutlined color="action" />
                    )}
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </div>
        </TableCell>
      </TableRow>

      <EditUserInformationModal
        open={isOpenEditModal}
        onClose={toggleEditModalHandler}
      >
        {isFetchedDepartments ? (
          <EditUserModal
            userData={item}
            open={isOpenEditModal}
            onClose={toggleEditModalHandler}
          />
        ) : (
          <CircularProgress />
        )}
      </EditUserInformationModal>
    </>
  );
};

export default UserLists;
