import React, { useState } from "react";
import moment from "moment";

export const TimesheetContext = React.createContext({});

export const TimesheetProvider = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [timesheet, setTimesheet] = useState();
  const [refetchMonthlyTimesheet, setRefetchMonthlyTimesheet] = useState(
    () => () => {}
  );
  const [refetchWeeklyTimesheet, setRefetchWeeklyTimesheet] = useState(
    () => () => {}
  );

  const [entry, setEntry] = useState(false);
  const [isEntry, setIsEntry] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState();
  const [selectedCode, setSelectedCode] = useState();
  const [selectedDate, setDate] = useState();
  const [timeSheetID, setTimeSheetID] = useState();
  const [myDetails, setMyDetails] = useState();
  const [myDuration, setMyDuration] = useState(0);
  const [weeklyDataTimesheet, setWeeklyDataTimesheet] = useState([]);
  const [weekDates, setWeekDates] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [apiResponse, setAPIResponse] = useState();
  const [mySprint, setMySprint] = useState();
  const [totalHours, setTotalHours] = useState([]);
  const [userDepartment, setUserDepartment] = useState([]);
  const [OpenBarChartEntryModal, setOpenBarChartEntryModal] = useState([]);

  const handleEntryModal = (code, act, time, index, id, comment, sprint) => {
    selectedCode === undefined && setSelectedCode(code[0]);
    selectedActivity === undefined && setSelectedActivity(act[0]);
    id?.map((id) => id && setTimeSheetID(id));
    comment?.map((data) => data && setMyDetails(data));
    sprint?.map((data) => data && setMySprint(data));
    time?.map((data) => data && setMyDuration(parseFloat(data)));
    const date = moment(weekDates[index]).format("llll");
    index !== undefined && setDate(date);
    if (entry) {
      setEntry(false);
      setSelectedActivity();
      setSelectedCode();
      setDate();
      setTimeSheetID();
      setMyDetails("");
      setMyDuration(0);
      setMySprint();
      setIsEntry(false);
    } else {
      setEntry(true);
    }
  };
  const popEntryModal = (ind) => {
    setSelectedCode(OpenBarChartEntryModal[ind]?.time_code);
    setSelectedActivity(OpenBarChartEntryModal[ind]?.time_activity);
    setTimeSheetID(OpenBarChartEntryModal[ind]?.id);
    setMyDetails(OpenBarChartEntryModal[ind]?.comment);
    setMyDuration(OpenBarChartEntryModal[ind]?.hours);
    setDate(moment(OpenBarChartEntryModal[ind]?.created).format("llll"));
    setMySprint(OpenBarChartEntryModal[ind]?.milestone);

    if (entry) {
      setEntry(false);
      setSelectedActivity();
      setSelectedCode();
      setDate();
      setTimeSheetID();
      setMyDetails("");
      setMyDuration(0);
      setIsEntry(false);
    } else {
      setEntry(true);
      setIsEntry(true);
    }
  };

  return (
    <TimesheetContext.Provider
      value={{
        timesheet,
        user,
        setTimesheet,
        refetchMonthlyTimesheet,
        setRefetchMonthlyTimesheet,
        setRefetchWeeklyTimesheet,
        refetchWeeklyTimesheet,
        entry,
        setEntry,
        selectedActivity,
        setSelectedActivity,
        selectedCode,
        setSelectedCode,
        selectedDate,
        setDate,
        timeSheetID,
        setTimeSheetID,
        myDetails,
        setMyDetails,
        myDuration,
        setMyDuration,
        weeklyDataTimesheet,
        setWeeklyDataTimesheet,
        weeks,
        setWeeks,
        setIsEntry,
        isEntry,
        setAPIResponse,
        apiResponse,
        setMySprint,
        mySprint,
        setWeekDates,
        weekDates,
        setTotalHours,
        totalHours,
        handleEntryModal,
        userDepartment,
        setUserDepartment,
        setOpenBarChartEntryModal,
        popEntryModal,
      }}
    >
      {props.children}
    </TimesheetContext.Provider>
  );
};
