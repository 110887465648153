import React, { useContext, useState, useEffect } from "react";
import {
  TextField,
  InputAdornment,
  Typography,
  Button,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import ImportContactsOutlined from "@mui/icons-material/ImportContactsOutlined";
import { UserListContext } from ".";
import useDebounce from "../../../hooks/customHooks/debounce";
import Checkbox from "@mui/material/Checkbox";
import { CloseRounded, Download } from "@mui/icons-material";
import FilterHeader from "../../../component/Reports/FilterHeader";

const UserListFilter = () => {
  const {
    search,
    setSearch,
    isFetching,
    setIsOpenAddUser,
    setIsExportModalOpen,
    isInactiveUsers,
    setIsInactiveUsers,
    setPage,
    setIsImportModalOpen,
    userList,
  } = useContext(UserListContext);

  const [searchString, setSearchString] = useState(search);
  const debounceValue = useDebounce(searchString, 700);

  useEffect(() => {
    setPage(1);
    setSearch(debounceValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue]);

  const openImportModalHandler = () => {
    setIsImportModalOpen(true);
  };

  return (
    <FilterHeader>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            sx={{ marginX: "5px", backgroundColor: "#00c292" }}
            onClick={() => setIsOpenAddUser(true)}
            variant="contained"
            startIcon={<AddCircleOutline />}
          >
            <Typography sx={{ fontSize: "0.78rem" }}>ADD USER</Typography>
          </Button>
          <Button
            sx={{ marginX: "5px", backgroundColor: "#00c292" }}
            onClick={openImportModalHandler}
            startIcon={<ImportContactsOutlined />}
            variant="contained"
          >
            <Typography sx={{ fontSize: "0.78rem" }}>
              IMPORT CSV - USER
            </Typography>
          </Button>
          <TextField
            value={searchString}
            onChange={(e) => {
              if (isFetching) return;
              setSearchString(e.target.value);
              // setSearch(e.target.value);
            }}
            sx={{ marginX: "5px", width: "20em" }}
            size="small"
            placeholder="Search"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setSearchString("")}
                    sx={{ margin: 0, padding: 0 }}
                  >
                    <CloseRounded />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div>
          <FormControlLabel
            label="Show Inactive"
            control={
              <Checkbox
                value={isInactiveUsers}
                onChange={() => setIsInactiveUsers((prev) => !prev)}
              />
            }
          />
          <Button
            startIcon={<Download />}
            onClick={() => setIsExportModalOpen(true)}
            variant="contained"
            disabled={!userList}
          >
            <Typography sx={{ fontSize: "0.78rem" }}>EXPORT</Typography>
          </Button>
        </div>
      </div>
    </FilterHeader>
  );
};

export default UserListFilter;
