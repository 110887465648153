import TableRow from "@mui/material/TableRow";

import StyledTableCell from "../../../UserApplicationMgmt/UserApplications/tableComponents/StyledTableCell";

const NoEmployeeTableCell = () => {
  return (
    <TableRow>
      <StyledTableCell colSpan="100%">No employee selected.</StyledTableCell>
    </TableRow>
  );
};

export default NoEmployeeTableCell;
