import { useContext, Fragment, useState } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import isDate from "date-fns/isDate";

import InputController from "../../../component/InputController";
import { UserListContext } from ".";
import { updateUserInformation } from "../../../api/user";

const BOX_STYLE = {
  height: "fit-content",
  width: "90%",
  maxWidth: "1000px",
  maxHeight: "80vh",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "34px 36px",
  borderRadius: "4px",
  backgroundColor: "#FFF",
};

const FORM_STYLE = {
  height: "100%",
  overflowY: "auto",
  padding: "6px 10px",
};

const schema = yup.object({
  email_work: yup
    .string()
    .email("Please enter a valid email address.")
    .required("Please enter an email address."),
  firstname: yup.string().required("Please enter first name."),
  lastname: yup.string().required("Please enter last name."),
  gender: yup.string().required("Please select a gender."),
  dept: yup.string().required("Please select a department."),
});

const EditUserModal = ({ userData, onClose }) => {
  const { departments } = useContext(UserListContext);

  const queryClient = useQueryClient();

  const [showPassword, setShowPassword] = useState(false);

  const generatePassword = () => {
    const tempPassword = Math.random().toString(36).slice(-8);
    setValue("password", tempPassword);
  };

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstname: userData.employee?.firstname || "",
      lastname: userData.employee?.lastname || "",
      middlename: userData.employee?.middlename || "",
      extension: userData.employee?.extension || "",
      gender: userData.employee?.gender || "",
      dept: userData.employee?.dept | "",
      date_hired: new Date(userData.employee?.details?.date_hired || ""),
      email_work: userData?.email || "",
      password: "",
      wfh_require: !!userData.wfh_require,
      min_hour: userData.employee?.details.min_hour,
    },
    mode: "onBlur",
  });

  const updateData = useMutation(updateUserInformation, {
    onSuccess: () => {
      queryClient.invalidateQueries("get-user-list");
      onCloseModalHandler();
      toast.success("User information updated.");
    },
    onError: () => {
      toast.error("An error occured.");
    },
  });

  const onSubmitHandler = (data) => {
    Object.entries(data).forEach((item) => {
      if (isDate(item[1])) {
        data[item[0]] = item[1].toISOString();
      }
    });

    data.nickname = userData.employee.nickname;
    data.dob = userData.employee.dob;
    data.marital = userData.employee.marital;
    data.mobile = userData.employee.mobile;
    data.address = userData.employee.address;
    data.email_personal = userData.employee.email_personal;
    data.emp_company_id = userData.emp_company_id;
    data.employment_status = userData.employee.details.employment_status;
    data.wfh_days = userData.employee.details.wfh_days;
    data.schedule = userData.employee.details.schedule;
    data.sched_id = userData.employee.details.sched_id;
    data.skype = userData.employee.details.skype;
    data.slack = userData.employee.details.slack;

    data.id = userData.employee.id;
    data.api = true;

    const forUpdate = {
      ...data,
    };

    delete forUpdate.password;
    delete forUpdate.wfh_require;

    const forAdd = {
      firstname: forUpdate.firstname,
      lastname: forUpdate.lastname,
      gender: forUpdate.gender,
      email: forUpdate.email_work,
      middlename: forUpdate.middlename,
      id: userData.id,
      password: data.password,
      wfh_require: +data.wfh_require,
      extension: forUpdate.extension,
      dept: forUpdate.dept,
      api: true,
      dateHired: forUpdate.date_hired,
      min_hour: forUpdate.min_hour,
    };

    const payload = {
      add: forAdd,
      update: forUpdate,
    };

    updateData.mutate(payload);
  };

  const submitHandler = handleSubmit(onSubmitHandler);

  const onCloseModalHandler = () => {
    reset();
    onClose();
  };

  return (
    <Stack spacing={3} sx={BOX_STYLE}>
      <Fragment>
        <Typography variant="h5">Edit Employee Information</Typography>

        <IconButton
          onClick={onCloseModalHandler}
          sx={{
            position: "absolute",
            top: "-6px",
            right: "18px",
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box component="form" sx={FORM_STYLE} onSubmit={submitHandler}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <InputController
                type="text"
                control={control}
                label="First Name"
                name="firstname"
                errors={errors}
                isDefaultErrorAlert={true}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputController
                type="text"
                control={control}
                label="Last Name"
                name="lastname"
                errors={errors}
                isDefaultErrorAlert={true}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputController
                type="text"
                control={control}
                label="Middle Name"
                name="middlename"
                errors={errors}
                isDefaultErrorAlert={true}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputController
                type="text"
                control={control}
                label="Extension"
                name="extension"
                errors={errors}
                isDefaultErrorAlert={true}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputController
                type="select"
                options={[
                  { value: "male", label: "Male" },
                  { value: "female", label: "Female" },
                  { value: "other", label: "Other..." },
                ]}
                control={control}
                label="Gender"
                name="gender"
                rules={{ required: true }}
                errors={errors}
                isDefaultErrorAlert={true}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputController
                type="select"
                options={departments.map((item) => ({
                  ...item,
                  value: `${item.value}`,
                }))}
                control={control}
                label="Department"
                name="dept"
                errors={errors}
                isDefaultErrorAlert={true}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputController
                type="date"
                control={control}
                label="Date Hired"
                name="date_hired"
                errors={errors}
                isDefaultErrorAlert={true}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputController
                type="check"
                control={control}
                label="Requires WFH Application Approval"
                name="wfh_require"
                isDefaultErrorAlert={true}
                errors={errors}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputController
                type="text"
                control={control}
                label="Email"
                name="email_work"
                errors={errors}
                isDefaultErrorAlert={true}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <Grid container columnSpacing={1}>
                    <Grid item xs={12} sm={8}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-password">
                          Password
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={showPassword ? "text" : "password"}
                          autoComplete="off"
                          {...field}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                  setShowPassword((prev) => !prev);
                                }}
                                onMouseDown={(event) => {
                                  event.preventDefault();
                                }}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                        />

                        <FormHelperText>
                          Note: Leave blank if no changes required
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs>
                      <Button
                        size={"small"}
                        sx={{ height: "52px" }}
                        variant="contained"
                        fullWidth
                        onClick={generatePassword}
                      >
                        Generate
                      </Button>
                    </Grid>
                  </Grid>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputController
                type="t-number"
                inputType="number"
                control={control}
                label="Minimum hours to render"
                name="min_hour"
                errors={errors}
                isDefaultErrorAlert={true}
              />
            </Grid>
          </Grid>
        </Box>

        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button
            type="button"
            variant="outlined"
            onClick={onCloseModalHandler}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={!!queryClient.isMutating()}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            onClick={submitHandler}
          >
            Update
          </LoadingButton>
        </Stack>
      </Fragment>
    </Stack>
  );
};

export default EditUserModal;
