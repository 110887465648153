export const RangeMenuItem = [
  {
    value: "This Week",
    name: "This Week",
  },
  {
    value: "Last Week",
    name: "Last Week",
  },
  {
    value: "Last 2 Weeks",
    name: "Last 2 Weeks",
  },
  {
    value: "This month",
    name: "This month",
  },
  {
    value: "Last month",
    name: "Last month",
  },
  {
    value: "Last 2 months",
    name: "Last 2 months",
  },
  {
    value: "Custom",
    name: "Custom",
  },
];

export const DashboardActivityTableHeader = [
  {
    label: "Activity",
    key: "name",
    isSortable: true,
  },
  {
    label: "Total Effort",
    key: "effort",
    isSortable: true,
    type: "number",
  },
];

export const DashboardStaffTableHeader = [
  {
    label: "Name",
    key: "name",
    isSortable: true,
  },
  {
    label: "Department",
    key: "department",
    isSortable: true,
  },
  {
    label: "Total Effort",
    key: "effort",
    isSortable: true,
    type: "number",
  },
];
