import React from "react";
import { MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { getRoles } from "../../hooks/customHooks/auth";

import { grey } from "@mui/material/colors";
import { Box } from "@mui/material";

const SidebarSubMenuItem = ({ sidebarItem, collapsed, index }) => {
  const roles = getRoles();

  return (
    <SubMenu key={index} title={sidebarItem.title} icon={sidebarItem.icon}>
      {collapsed && <b style={{ padding: ".3rem" }}>{sidebarItem.title}</b>}
      {sidebarItem.children.map((child, index) => {
        if (
          roles.some((role) => {
            if (child.roles.includes("all")) {
              return true;
            } else {
              return child.roles.includes(role);
            }
          })
        ) {
          return (
            <Box
              key={index}
              sx={{
                width: "500px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{
                  width: "20px",
                  borderBottom: 3,
                  borderLeft: 3,
                  borderColor: grey[300],
                  marginLeft: "-25px",
                  marginTop: "-25px",
                  marginBottom: "17.5px",
                }}
              ></Box>

              <MenuItem key={index}>
                {child.linkto ? (
                  <Link to={child.linkto} style={{ fontSize: "0.8em" }}>
                    {child.title}
                  </Link>
                ) : (
                  <Typography
                    style={{ fontSize: "0.8em" }}
                    onClick={child.action}
                  >
                    {child.title}
                  </Typography>
                )}
              </MenuItem>
            </Box>
          );
        }

        return <React.Fragment key={index}></React.Fragment>;
      })}
    </SubMenu>
  );
};

export default SidebarSubMenuItem;
