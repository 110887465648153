import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const TitleTypography = styled(Typography)(() => ({
  color: "#21293C",
  fontSize: "14px",
  fontWeight: "600",
  lineHeight: "1.8",
}));

export default TitleTypography;
