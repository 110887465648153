import { useState, useContext } from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import TransitionedModal from "../../../component/UserInformation/modal/TransitionedModal";
import ImportNotes from "../../../component/UserList/ImportUserCsv/ImportNotes";
import SampleCsv from "../../../component/UserList/ImportUserCsv/SampleCsv";
import { UserListContext } from "./";
import { uploadUserCsv } from "../../../api/userList";
import { toBase64 } from "../../../helper/UserLists";
import ImportBox from "../../../component/UserList/ImportUserCsv/ImportBox";
import ActionButtons from "../../../component/UserList/ImportUserCsv/ActionButtons";

const MODAL_STYLE = {
  width: "90%",
  maxWidth: "992px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "32px",
  backgroundColor: "#FFF",
  borderRadius: "4px",
  height: "fit-content",
  maxHeight: "90vh",
  overflowY: "auto",
};

const ImportUserCsv = () => {
  const [csvFile, setCsvFile] = useState(null);

  const { isImportModalOpen, setIsImportModalOpen } =
    useContext(UserListContext);

  const closeModalHandler = () => {
    setCsvFile(null);
    setIsImportModalOpen(false);
  };

  const inputChangeHandler = (e) => {
    setCsvFile(e.target.files[0]);
  };

  const formSubmitHandler = async () => {
    const result = await toBase64(csvFile);
    mutate([result]);
  };

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(uploadUserCsv, {
    onSuccess: () => {
      queryClient.invalidateQueries("get-user-list");
      toast.success("Import success");
      closeModalHandler();
    },
    onError: (error) => {
      toast.error(error?.message || "Import failed");
    },
  });

  return (
    <TransitionedModal open={isImportModalOpen} onClose={closeModalHandler}>
      <Stack sx={MODAL_STYLE} spacing={4}>
        <Typography sx={{ fontSize: "22px" }}>
          Import CSV File of Employees
        </Typography>

        <ImportNotes />

        <SampleCsv />

        <ImportBox csvFile={csvFile} onChange={inputChangeHandler} />

        <ActionButtons
          isLoading={isLoading}
          onClose={closeModalHandler}
          csvFile={csvFile}
          onFormSubmit={formSubmitHandler}
        />

        <IconButton
          onClick={closeModalHandler}
          sx={{
            position: "absolute",
            top: "-18px",
            right: "14px",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
    </TransitionedModal>
  );
};

export default ImportUserCsv;
