import { createContext } from "react";

const LeaveConversionApplicationsContext = createContext({
  query: {
    data: {},
    isLoading: false,
    isFetching: false,
    isSuccess: false,
    isError: false,
  },
  state: {
    page: 0,
    pageSize: 0,
    searchString: "",
    order: "",
    orderBy: "",
  },
  handler: {
    changePage: () => {},
    changePageSize: () => {},
    changeSearchFilter: () => {},
    changeOrder: () => {},
    changeOrderBy: () => {},
  },
});

export default LeaveConversionApplicationsContext;
