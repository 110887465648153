import { useQuery } from "react-query";
import { getPerTypeReport } from "../../../api/perType";

const usePerType = (params) => {
  return useQuery(["get-report-per-type", params.criteria], () =>
    getPerTypeReport(params.payload)
  );
};

const queries = {
  usePerType,
};

export default queries;
