import React from "react";
import { TableRow, TableCell } from "@mui/material";

const UserLists = ({ item }) => {
  return (
    <>
      <TableRow sx={{ height: "4em" }}>
        <TableCell></TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.name,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.logs,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.lates,
            }}
          />
        </TableCell>
        <TableCell align={"right"}>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: `${item.percentage}%`,
            }}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default UserLists;
