export const initialModalStatus = {
  zone: "",
  type: "",
  open: false,
  title: "",
  confirmMessage: "",
  message: "",
};

export const initialReminder = {
  logOut: false,
  timein: false,
  timeout: false,
  timeOutTrigger: false,
};
