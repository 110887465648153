import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";

import StyledTableCell from "../../../../UserApplicationMgmt/UserApplications/tableComponents/StyledTableCell";
import {
  PER_TIMESHEET_HEADER_CELL,
  ORDER,
} from "../../../../../lib/reports/perActivity";

const PerTimesheetTableHeader = ({ order, orderBy, handleOrderChange }) => {
  const changeOrderHandler = (id, isSortable) => {
    if (!isSortable) return;
    handleOrderChange(id);
  };

  return (
    <TableHead>
      <TableRow>
        {PER_TIMESHEET_HEADER_CELL.map((headerCellEl) => {
          const { id, isSortable, label } = headerCellEl;

          return (
            <StyledTableCell key={id}>
              <TableSortLabel
                sx={{ width: "max-content" }}
                active={orderBy === id}
                direction={orderBy === id ? order : ORDER.ascending}
                onClick={changeOrderHandler.bind(null, id, isSortable)}
              >
                <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                  {label}
                </Typography>
              </TableSortLabel>
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default PerTimesheetTableHeader;
