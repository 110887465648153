import React, { useState, useEffect, useContext } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { TimesheetContext } from "../../globalstate/UserContext/timesheetcontext";
const SelectDropDownUI = ({
  menuItem,
  select,
  label,
  fullWidth,
  minWidth,
  size,
  value,
  onClick,
  disabled,
  date,
}) => {
  const { setIsEntry } = useContext(TimesheetContext);
  const [selected, setSelected] = useState(menuItem[0].value);
  const [restrictedCode, setRestrictedCode] = useState();
  const handleChange = (event) => {
    setSelected(event.target.value);
    select(event.target.value, date);
  };
  useEffect(() => {
    setSelected(menuItem[0].value);
    setRestrictedCode();
    value?.map(
      (d) =>
        d &&
        (setSelected(parseFloat(d.hours).toFixed(2)),
        setRestrictedCode(d.status))
    );
  }, [value, menuItem]);

  return (
    <FormControl
      fullWidth={fullWidth}
      style={{ minWidth: minWidth }}
      size={size}
    >
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selected ? selected : menuItem[0].value}
        disabled={disabled}
        onChange={handleChange}
        readOnly={selected !== parseFloat(0).toFixed(2)}
        onClick={() => {
          selected !== parseFloat(0).toFixed(2) && !disabled && onClick();
        }}
        size="small"
        sx={{ margin: "5px", height: "30px" }}
        style={{
          backgroundColor:
            restrictedCode === 2
              ? "#ff5252"
              : selected !== "0.00"
              ? "#00C292"
              : "",
          color: restrictedCode === 2 || selected !== "0.00" ? "white" : "",
        }}
      >
        {menuItem.map((item, index) => {
          return (
            <MenuItem
              key={index}
              value={item.value}
              onClick={() => {
                onClick();
                setSelected(menuItem[0].value);
                setIsEntry(false);
              }}
            >
              {item.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SelectDropDownUI;
