export const replaceLabel = (item) => {
  switch (item) {
    case "logs":
      return "Time Logs";
    case "wfh":
      return "WFH";
    default:
      return item.replace(/_/g, " ");
  }
};

export const addObject = (data, replace) =>
  data.map((item) =>
    Object.fromEntries([
      ["name", replace(item)],
      ["shortname", item || ""],
    ])
  );

export const wfhSummaryHeader = ["name", "logs", "wfh"];
