import { useState, useCallback } from "react";
import { useParams } from "react-router-dom";

import Box from "@mui/material/Box";

import {
  useSchedule,
  useUserInformation,
} from "../../hooks/react-query/profileQuery";
import {
  UserInformationButtons,
  UserInformationViewCard,
  UserInformationSkeletonLoader,
  UserInformationEditModal,
} from "../../component/UserInformation";
import { CircularProgress } from "@material-ui/core";
import EditUserInformationModal from "../../component/UserInformation/modal/TransitionedModal";

const BOX_STYLE = {
  margin: "20px 24px auto 24px",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
};

const UserInformation = () => {
  const { id } = useParams();

  const [showEditModal, setShowEditModal] = useState(false);

  const {
    data,
    isFetched: isFetchedUserInfo,
    error,
    isFetching,
  } = useUserInformation({ id });

  const { data: schedules, isFetched: isFetchedSchedules } = useSchedule();

  const toggleEditModalHandler = useCallback(() => {
    setShowEditModal((previous) => !previous);
  }, []);

  return (
    <Box sx={BOX_STYLE}>
      <UserInformationButtons
        disabled={!isFetchedUserInfo || !isFetchedSchedules}
        onClick={toggleEditModalHandler}
      />

      {isFetchedUserInfo &&
        !error &&
        !isFetching &&
        data.userInfo.map((data) => (
          <UserInformationViewCard key={data.title} payload={data} />
        ))}

      {isFetching && <UserInformationSkeletonLoader number={3} />}

      <EditUserInformationModal
        open={showEditModal && isFetchedUserInfo && isFetchedSchedules}
        onClose={toggleEditModalHandler}
      >
        <>
          {isFetchedUserInfo && isFetchedSchedules ? (
            <UserInformationEditModal
              userData={data.userData}
              departments={data.departments}
              schedules={schedules}
              onClose={toggleEditModalHandler}
            />
          ) : (
            <CircularProgress />
          )}
        </>
      </EditUserInformationModal>
    </Box>
  );
};

export default UserInformation;
