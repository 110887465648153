import React, { useState } from "react";
import {
  Modal,
  Typography,
  Paper,
  TextField,
  IconButton,
  Button,
  CircularProgress,
} from "@mui/material";
import { ModalStyle } from "../../../helper/ModalStyle";
import CloseIcon from "@mui/icons-material/Close";
import ColorPicker from "../../Settings/CompanySettings/containers/general-identity/component/color-picker";
import DropDownSelect from "../../../component/CustomElement/DropDownSelect";
import { useAddSkills } from "../../../hooks/react-query/SettingsSytem/Skills";
const initValState = {
  name: "",
  color: "",
  status: "",
  sequence: 0,
};
const ActiveInactive = [
  { id: 0, name: "Inactive" },
  { id: 1, name: "Active" },
];
const AddSkillModal = ({ open, handleClose }) => {
  const [data, setData] = useState(initValState);

  const { mutate, isLoading } = useAddSkills();

  const handleCloseModal = () => {
    setData(initValState);
    handleClose();
  };
  const handleName = (e) => {
    setData({ ...data, name: e.target.value });
  };
  const handleColor = (e) => {
    setData({ ...data, color: e });
  };
  // const handleSequence = (e) => {
  //   setData({ ...data, sequence: parseInt(e.target.value) });
  // };
  const handleStatus = (e) => {
    setData({ ...data, status: e.target.value });
  };
  const handleSubmit = () => {
    mutate(data, {
      onSuccess: () => {
        handleCloseModal();
      },
    });
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Paper style={ModalStyle} sx={{ minWidth: "25%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            margin: "5px 10px 0px 10px",
          }}
        >
          <IconButton onClick={handleCloseModal}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <Typography variant="h5" marginLeft={5}>
          Add Skill
        </Typography>
        <div style={{ margin: "15px 20px 15px 20px", padding: "15px" }}>
          <TextField
            size="small"
            label="Name"
            fullWidth
            onChange={handleName}
          />
          <div style={{ marginTop: "15px" }}>
            <ColorPicker
              color={data.color}
              onChange={handleColor}
              reused={"Skill"}
            />
          </div>
          {/* <div style={{ marginTop: "15px" }}>
            <TextField
              size="small"
              label="Sequence"
              type={"number"}
              fullWidth
              onChange={handleSequence}
            />
          </div> */}
          <div style={{ marginTop: "15px" }}>
            <DropDownSelect
              objectName={{ val: "id", name: "name" }}
              DropDownData={ActiveInactive}
              label={"Status"}
              minWidth={"100%"}
              size={"small"}
              value={data.status}
              onChange={handleStatus}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "20px",
              gap: "10px",
            }}
          >
            <Button size="small" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={handleSubmit}
              disabled={
                data.name === "" ||
                data.color === "" ||
                // data.sequence === null ||
                data.status === "" ||
                isLoading
              }
              endIcon={isLoading ? <CircularProgress size={20} /> : <></>}
            >
              Save
            </Button>
          </div>
        </div>
      </Paper>
    </Modal>
  );
};

export default AddSkillModal;
