import { TextField, Grid } from "@mui/material";
import React from "react";
import pulseHelper from "./pulseHelper/pulseHelper";
const Checkboxs = ({ register, ind }) => {
  register(`answerType${ind}`, { value: "checkbox" });
  const opt1 = `optChkA${ind}`;
  const opt2 = `optChkB${ind}`;
  const opt3 = `optChkC${ind}`;
  const opt4 = `optChkD${ind}`;

  const ans1 = `ansChkA${ind}`;
  const ans2 = `ansChkB${ind}`;
  const ans3 = `ansChkC${ind}`;
  const ans4 = `ansChkD${ind}`;

  const distributeOptions = (index) => {
    if (index === 0) {
      return opt1;
    }
    if (index === 1) {
      return opt2;
    }
    if (index === 2) {
      return opt3;
    }
    if (index === 3) {
      return opt4;
    }
  };
  const distributeAnswer = (index) => {
    if (index === 0) {
      return ans1;
    }
    if (index === 1) {
      return ans2;
    }
    if (index === 2) {
      return ans3;
    }
    if (index === 3) {
      return ans4;
    }
  };
  return (
    <Grid container rowSpacing={2} columnSpacing={2}>
      {[0, 1, 2, 3].map((tt, i) => (
        <Grid item xs={12} key={i} container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              label="Option"
              defaultValue={pulseHelper.disOption(i)}
              fullWidth
              size="small"
              name={distributeOptions(i)}
              required
              {...register(distributeOptions(i))}
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              variant="outlined"
              label="Enter answer here"
              defaultValue={pulseHelper.disAnswer(i)}
              fullWidth
              size="small"
              name={distributeAnswer(i)}
              required
              {...register(distributeAnswer(i))}
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default Checkboxs;
