import React from "react";
import { TableBody, TableCell, TableRow } from "@mui/material";

function getValue(obj, path) {
  if (!path || !obj) return obj;
  const properties = path.split(".");
  return getValue(obj[properties.shift()], properties.join("."));
}

const BodyUI = ({ cols, rows, Action }) => {
  return (
    <TableBody>
      {rows.map((row, key) => (
        <TableRow key={key}>
          {cols.map((col, key) => (
            <TableCell key={key} sx={{ fontSize: "0.75rem" }} align={col.align}>
              {getValue(row, col.field)}
            </TableCell>
          ))}
          {Action && (
            <TableCell align="center">
              <Action val={row} ind={key} />
            </TableCell>
          )}
        </TableRow>
      ))}
      {rows.length === 0 && (
        <TableRow>
          <TableCell colSpan={cols.length + 1} align="left">
            No records found
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

export default BodyUI;
