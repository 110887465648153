import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import LoopRoundedIcon from "@mui/icons-material/LoopRounded";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import SettingsSuggestRoundedIcon from "@mui/icons-material/SettingsSuggestRounded";
import hooks from "../../hooks/react-query/queryHooks";
import CreateSurvey from "./component/createSurvey";
import Progress from "../../lib/reports/reactions/reactionComponent/progress";
import ViewSurvey from "./component/viewComponents/viewSurvey";
import PulseSettings from "./component/viewComponents/PulseSettings";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import { useQueryClient } from "react-query";
import UpdateSurvey from "./component/updateSurvey";
import PulseResult from "./component/resultComponent/pulseResult";
import { customStyle } from "./component/customStyle";
import FaceIcon from "@mui/icons-material/Face";
import { Tooltip } from "@mui/material";
const PulseSurvey = () => {
  const queryClient = useQueryClient();
  const [create, setCreate] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [view, setView] = React.useState(false);
  const [result, setResult] = React.useState(false);
  const [resultID, setResultId] = React.useState(null);
  const [settings, setSettings] = React.useState(false);
  const [delStat, setDelstat] = React.useState(false);
  const [dataPlaceholder, setDataplaceholder] = React.useState(null);
  const [viewData, setViewdata] = React.useState(null);
  const handleCreateSurvey = () => {
    create ? setCreate(false) : setCreate(true);
  };
  const handleUpdateSurvey = (dt) => {
    setDataplaceholder(dt);
    update ? setUpdate(false) : setUpdate(true);
  };
  const handleViewSurvey = (dt) => {
    setViewdata(dt);
    view ? setView(false) : setView(true);
  };
  const handleViewSurvey2 = () => {
    view ? setView(false) : setView(true);
  };
  const handlePulseSettings = () => {
    settings ? setSettings(false) : setSettings(true);
  };
  const handlePulseResult = (idParam) => {
    setResultId(idParam);
    result ? setResult(false) : setResult(true);
  };
  const { data, isFetching } = hooks.useGetPulseSurvey();
  const { data: surveySett } = hooks.useGetSettings();
  const { data: surveyAnswer } = hooks.useGetPulseAnswer(resultID);

  const { mutate: deletePulseMutation } = hooks.useDeletePulse();
  const qqCount = (total) =>
    total.map((nm, i) => {
      return <Chip key={i} label="" sx={customStyle.qq} />;
    });
  const deleteSurvey = (psID) => {
    setDelstat(true);
    deletePulseMutation(psID, {
      onSuccess: () => {
        setDelstat(false);
        queryClient.invalidateQueries(["pulse-survey"]);
      },
    });
  };
  return (
    <Box
      className="myDiv"
      style={{ padding: "5px 10px", width: "100%", height: "fit-content" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          cursor: "context-menu",
        }}
      >
        <Tooltip
          title={"Manage company pulse survey"}
          placement={"right"}
          arrow
        >
          <Typography variant="h4" component="div">
            Pulse Survey
          </Typography>
        </Tooltip>
      </Box>
      <Card sx={customStyle.surveyHead}>
        <Box sx={customStyle.boxDisplay}>
          <Button
            variant="contained"
            size="small"
            sx={customStyle.button}
            onClick={handleCreateSurvey}
          >
            Create Survey
          </Button>
          <Box>
            <IconButton
              onClick={() => {
                queryClient.invalidateQueries(["pulse-survey"]);
              }}
            >
              <LoopRoundedIcon />
            </IconButton>
            <Button
              variant="contained"
              disableElevation
              size="small"
              startIcon={<SettingsSuggestRoundedIcon />}
              sx={customStyle.button}
              onClick={handlePulseSettings}
            >
              <Typography variant="overline">Settings</Typography>
            </Button>
          </Box>
        </Box>
        {isFetching && <Progress />}
      </Card>
      <Grid container>
        {data &&
          data.map((dt, i) => {
            return (
              <Grid item lg={6} xs={12} key={i}>
                <Card sx={customStyle.filterCard}>
                  <Box>
                    <Box sx={customStyle.boxChippy}>
                      <Chip label={dt.type?.name} sx={customStyle.chip} />
                      {surveySett?.data?.survey?.id === dt?.id && (
                        <Chip
                          icon={<FaceIcon sx={{ color: "#fff !important" }} />}
                          label="Psych Survey"
                          sx={customStyle.chippy}
                        />
                      )}
                    </Box>
                    <Typography sx={customStyle.title}>{dt.name}</Typography>
                    <Typography sx={customStyle.desc}>
                      {dt.description}
                    </Typography>
                    <Box sx={customStyle.boxQ}>
                      <Typography variant="h6" sx={customStyle.quest}>
                        No. of question
                      </Typography>
                      <Box>
                        {qqCount(new Array(dt.questions.length).fill(true))}
                      </Box>
                    </Box>
                    <Stack direction="row" sx={{ mt: "5px" }}>
                      <Button
                        startIcon={<VisibilityOutlinedIcon />}
                        disableElevation
                        variant="contained"
                        size="small"
                        sx={customStyle.button2}
                        onClick={() => {
                          handleViewSurvey(dt);
                        }}
                      >
                        View / Preview
                      </Button>
                      <Button
                        startIcon={<EditOutlinedIcon />}
                        disableElevation
                        variant="contained"
                        size="small"
                        sx={customStyle.button3}
                        onClick={() => {
                          handleUpdateSurvey(dt);
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        startIcon={<PlagiarismOutlinedIcon />}
                        disableElevation
                        variant="contained"
                        size="small"
                        sx={customStyle.button5}
                        onClick={() => {
                          handlePulseResult(dt.id);
                        }}
                      >
                        Result
                      </Button>
                      <Button
                        startIcon={<DeleteOutlineOutlinedIcon />}
                        disabled={delStat}
                        disableElevation
                        variant="contained"
                        size="small"
                        sx={customStyle.button6}
                        onClick={() => {
                          deleteSurvey(dt.id);
                        }}
                      >
                        Delete
                      </Button>
                    </Stack>
                  </Box>
                </Card>
              </Grid>
            );
          })}
      </Grid>
      {create && (
        <CreateSurvey open={create} handleCreateSurvey={handleCreateSurvey} />
      )}
      {update && dataPlaceholder && (
        <UpdateSurvey
          open={update}
          handleUpdateSurvey={handleUpdateSurvey}
          dataPlaceholder={dataPlaceholder}
        />
      )}
      {view && viewData && (
        <ViewSurvey
          open={view}
          handleViewSurvey2={handleViewSurvey2}
          viewData={viewData}
          customStyle={customStyle}
          dashboard={false}
        />
      )}
      {settings && surveySett && data && (
        <PulseSettings
          open={settings}
          handlePulseSettings={handlePulseSettings}
          data={data}
          surveySett={surveySett}
        />
      )}
      {result && surveyAnswer && (
        <PulseResult
          open={result}
          handlePulseResult={handlePulseResult}
          resultID={resultID}
          surveyAnswer={surveyAnswer}
        />
      )}
    </Box>
  );
};

export default PulseSurvey;
