import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const menuItem = [
  {
    value: "earliest",
    name: "Earliest",
  },
  {
    value: "late",
    name: "Late In",
  },
  {
    value: "A-Z",
    name: "A-Z",
  },
  {
    value: "Z-A",
    name: "Z-A",
  },
  {
    value: "likes",
    name: "Likes",
  },
  {
    value: "hearts",
    name: "Hearts",
  },
];

const SelectDropDownUI = ({ sorts, setSorts }) => {
  const handleChange = (event) => {
    setSorts(event.target.value);
  };

  return (
    <FormControl
      sx={{
        minWidth: "110px",
      }}
    >
      <InputLabel>Order by</InputLabel>
      <Select
        size="small"
        sx={{ margin: "5px" }}
        value={sorts}
        onChange={handleChange}
      >
        {menuItem.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectDropDownUI;
