import React, { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import IconButton from "@mui/material/IconButton";

import Box from "@mui/material/Box";
import CustomBadgeMenu from "./customBadgeMenu";
import {
  useReadAllNotification,
  useReadNotification,
} from "../../hooks/react-query/Notification";
import { useNavigate } from "react-router-dom";
import { NotificationContext } from "../../globalstate/UserContext/Notification";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const NotificationButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { mutate: ReadNotification } = useReadNotification();
  const { mutate: ReadlAllNotification } = useReadAllNotification();
  const { data: notif, isLoading: loadingNotif } =
    useContext(NotificationContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let navigate = useNavigate();

  const handleReadNotif = (notif) => {
    navigate(notif.link);
    ReadNotification(notif);
  };

  return (
    <Box>
      <IconButton
        sx={{ paddingRight: "10px", color: "#fff" }}
        onClick={handleClick}
      >
        {" "}
        <Badge badgeContent={notif?.data.length} color="error">
          <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            variant="dot"
          >
            <NotificationsOutlinedIcon />
          </StyledBadge>
        </Badge>
      </IconButton>
      <CustomBadgeMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        open={open}
        notif={{ data: notif?.data, loading: loadingNotif }}
        handleClick={handleReadNotif}
        handleRead={ReadNotification}
        handleReadAll={ReadlAllNotification}
      />
    </Box>
  );
};

export default NotificationButton;
