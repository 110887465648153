import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const TimesheetWeeklyReportContainer = styled(Box)(() => ({
  margin: "14px 18px 0",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  height: "100%",
  maxHeight: "calc(100% - 90px)",
}));

export default TimesheetWeeklyReportContainer;
