import { useQuery } from "react-query";
import { getPayrollReportAPI } from "../../../../api/reports/payroll";
export const useGetReportPayroll = (
  start_date,
  end_date,
  ot_start,
  ot_end,
  page_size,
  page,
  search
) => {
  return useQuery(
    [
      "get-report-payroll",
      start_date,
      end_date,
      ot_start,
      ot_end,
      page_size,
      page,
      search,
    ],
    () =>
      getPayrollReportAPI(
        start_date,
        end_date,
        ot_start,
        ot_end,
        page_size,
        page,
        search
      )
  );
};
