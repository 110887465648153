import React from "react";
import { useState, useRef, useEffect, useCallback } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import DataTable from "../../component/TreeView";
import Dialog from "../../component/TreeView/Dialog";
import Visit from "../../component/TreeView/table/rows/Visit";
import Form from "../../component/TreeView/Form";
import FormEdit from "../../component/TreeView/EditForm";
import Content from "../../component/TreeView/Content";
import { addVisit, deleteVisit, updateVisit } from "../../api/visit";
import hooks from "../../hooks/react-query/VisitQuery";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import "react-toastify/dist/ReactToastify.css";
import Confirm from "../../component/TreeView/Confirm";

const CustomerVisit = () => {
  const formData = useRef(null);
  const [edit, setEdit] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [form, setForm] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [content, setContent] = useState();
  const [date, setDate] = useState();
  const [errors, setErrors] = useState({});
  const [fetch, toggleFetch] = useState(false);
  const [contentField, setContentField] = useState();
  const [confirm, setConfirm] = useState(false);
  const [deleteForm, setDeleteForm] = useState();

  const [tree, setTree] = useState([]);
  const { data: treeData } = hooks.useTree(fetch);

  const initTree = useCallback(() => {
    if (treeData) {
      if (typeof treeData != "object") {
        setTree(JSON.parse(treeData));
      }
    }
  }, [treeData]);

  useEffect(() => {
    initTree();
  }, [initTree]);

  const createVisit = useMutation((formData) => addVisit(formData), {
    onSuccess: async (response) => {
      toast.success("Log successfully added.");
      toggleFetch(!fetch);
      setForm(false);
      setContentField("");
      setErrors({});
    },
    onError: (response) => {
      setErrors(response.response.data || {});
      if (!response.response.data.content && !response.response.data.name) {
        toast.error("Something Went Wrong");
      }
    },
  });

  const editVisit = useMutation((formData) => updateVisit(formData), {
    onSuccess: async (response) => {
      toast.success("Record Updated");
      toggleFetch(!fetch);
      setEdit(false);
      setErrors({});
    },
    onError: (response) => {
      setErrors(response.response.data || {});
      if (!response.response.data.content && !response.response.data.name) {
        toast.error("Something Went Wrong");
      }
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    let form = new FormData(formData.current);
    form.append("content", contentField ? contentField : "");
    createVisit.mutate(form);
  };

  const handleSubmitEdit = (e) => {
    e.preventDefault();
    editVisit.mutate(editForm);
  };

  const TableSettings = { limit: 10 };

  const TableHeader = [
    { label: "Name", data: "name" },
    { label: "Date", data: "on", type: "date" },
    { label: "Remarks", data: "remarks" },
  ];
  const formField = [
    {
      label: "Name",
      data: "name",
      helperText: errors.name,
      update: (e) => {
        setEditForm({ ...editForm, name: e });
      },
    },
    {
      label: "Date",
      data: "on",
      type: "date",
      helperText: errors.on,
      value: date,
      onChange: setDate,
      update: (e) => {
        setEditForm({ ...editForm, on: e });
      },
    },
    {
      label: "Remarks",
      data: "remarks",
      type: "TextArea",
      helperText: errors.remarks,
      update: (e) => {
        setEditForm({ ...editForm, remarks: e });
      },
    },
    {
      label: "Content",
      type: "CkEditor",
      data: "content",
      helperText: errors.content,
      form: contentField,
      setData: setContentField,
      update: (e) => {
        setEditForm({ ...editForm, content: e });
      },
    },
  ];

  const handleRemove = (id, path) => {
    let form = new FormData();
    form.append("id", id);
    form.append("path", path);
    setDeleteForm(form);
    setConfirm(true);
  };

  const handleConfirm = () => {
    removeVisit.mutate(deleteForm);
  };

  const handleEdit = (id, path, data) => {
    setEditForm(data);
    setEdit(true);
  };

  const openContent = (name, remarks, content) => {
    setContent({
      remarks: remarks,
      title: name,
      content: content,
    });
    setDialog(true);
  };

  const removeVisit = useMutation((formData) => deleteVisit(formData), {
    onSuccess: async (response) => {
      toast.success("Customer Visit Log Removed");
      toggleFetch(!fetch);
      setConfirm(false);
    },
    onError: (response) => {
      toast.error(response.message);
    },
  });

  const Button = [
    {
      label: "Add Visit Log",
      type: "contained",
      onClick: (e) => setForm(true),
      viewPermission: "manage customer visits",
    },
  ];

  const handleClose = (e) => {
    setDialog(false);
    setEdit(false);
    setForm(false);
    setContentField("");
    setErrors({});
  };

  return (
    <>
      <Box sx={{ margin: "0px", pr: "25px" }}>
        <Box
          sx={{
            pt: "5px",
            pl: "25px",
            display: "flex",
            justifyContent: "flex-start",
            cursor: "context-menu",
          }}
        >
          <Tooltip title="View customer visit log" placement={"right"} arrow>
            <Typography variant="h4">Customer Visit Log</Typography>
          </Tooltip>
        </Box>
        <Box sx={{ minWidth: "75vw", pl: "17px", pt: "15px" }}>
          <DataTable
            TableHeader={TableHeader}
            TreeHeader={"Folder List"}
            Tree={tree}
            fetch={hooks.useVisit}
            TableRow={Visit}
            handleClick={openContent}
            handleRemove={handleRemove}
            handleEdit={handleEdit}
            Button={Button}
            toggleFetch={fetch}
            TreeType={"date"}
            TableSettings={TableSettings}
          />
        </Box>
        <Content />
        <Box>
          <Dialog
            dialog={dialog}
            handleClose={handleClose}
            title={content && content.title}
            subject={content && content.remarks}
            Content={<Content data={content} />}
            type="content"
            size="md"
          />
        </Box>
        <Box>
          <Dialog
            size="md"
            dialog={form}
            submitting={createVisit.isLoading}
            handleClose={handleClose}
            title={"Add Visit Log"}
            handleSubmit={handleSubmit}
            formData={formData}
            Content={
              <Form
                formfield={formField}
                formData={formData}
                handleSubmit={handleSubmit}
              />
            }
          />
        </Box>
        <Box>
          <Dialog
            size="md"
            dialog={edit}
            submitting={editVisit.isLoading}
            handleClose={handleClose}
            title={"Edit Visit Log"}
            handleSubmit={handleSubmitEdit}
            formData={formData}
            Content={
              <FormEdit
                data={editForm}
                update={setEditForm}
                formfield={formField}
                formData={formData}
                handleSubmit={handleSubmitEdit}
              />
            }
          />
        </Box>
        <Confirm
          submitting={removeVisit.isLoading}
          childOpen={confirm}
          message={"Are you sure you want to delete this visit log?"}
          handleClose={(e) => setConfirm(false)}
          handleConfirm={handleConfirm}
        />
      </Box>
    </>
  );
};

export default CustomerVisit;
