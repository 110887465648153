import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  AddSkillsAPI,
  GetSkillsAPI,
  EditSkillsAPI,
  DeleteSkillsAPI,
  GetEmployeeSkillAPI,
  AssignSkillAPI,
} from "../../../../api/settingsSystem/skills";

export const useGetSkills = (payload) => {
  return useQuery(["get-skill", payload], () => GetSkillsAPI(payload));
};

export const useAddSkills = () => {
  const queryClient = useQueryClient();
  return useMutation(AddSkillsAPI, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["get-skill"]);
      return res;
    },
  });
};

export const useEditSkills = () => {
  const queryClient = useQueryClient();
  return useMutation(EditSkillsAPI, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["get-skill"]);
      return res;
    },
  });
};

export const useDeleteSkill = () => {
  const queryClient = useQueryClient();
  return useMutation(DeleteSkillsAPI, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["get-skill"]);
      return res;
    },
  });
};

export const useGetEmployeeSkill = (payload) => {
  return useQuery(["get-employee-skill", payload], () =>
    GetEmployeeSkillAPI(payload)
  );
};

export const useAssignSkill = () => {
  const queryClient = useQueryClient();
  return useMutation(AssignSkillAPI, {
    onSuccess: () => {
      queryClient.invalidateQueries(["get-employee-skill"]);
      queryClient.invalidateQueries(["get-skill"]);
    },
  });
};
