import React, { createContext, useState, useMemo, useEffect } from "react";
import moment from "moment";
import reactQuery from "../../../hooks/react-query/PerProjectSummary";
import ProjectCodeSummaryFilter from "./ProjectCodeSummaryFilter";
import { TableRow, TableBody, TablePagination, Tooltip } from "@mui/material";
import { ProjectCodeSummaryTableHeaderV5 } from "../../../lib/reports/projectCodeSummary";
import { sortArrayTable } from "../../../helper/Reports";
import ProjectList from "./ProjectList";
import Loader from "../../../component/Loader";
import HeaderReports from "../../../component/Reports/Header";
import EmptyData from "../../../component/Reports/EmptyData";
import TableHeader from "../../../component/Reports/TableHeader";
import ReportTableContainter from "../../../component/Reports/TableContainter";
import { Box } from "@mui/system";

export const ProjectCodeSummaryContext = createContext({});

const ProjectCodeSummary = () => {
  const [isInactive, setIsInactive] = useState(false);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(30);
  const [page, setPage] = useState(1);
  const [orderDirection, setOrderDirection] = useState("desc");
  const [tableData, setTableData] = useState([]);

  const {
    data: projectSummaryData,
    refetch,
    isFetching,
  } = reactQuery.usePerProjectSummary({
    criteria: { page, limit, startDate, endDate, isInactive, search },
    payload: {
      fromDate: moment(startDate).format("YYYY-MM-DD"),
      toDate: moment(endDate).format("YYYY-MM-DD"),
      inactive: isInactive,
      search: search,
      page_size: limit,
      page: page,
    },
  });

  const departmentToArray = (data) => {
    const newString = data?.key
      ? data?.key === "[]"
        ? "n/a"
        : data.key.replace(/["[\]"]/g, "")
      : "n/a";
    return newString;
  };

  useEffect(() => {
    if (projectSummaryData) {
      const mappedProjectSummaryData = projectSummaryData?.data.map((data) => {
        return {
          ...data,
          ...(!data?.client &&
            !data?.client?.company_code && {
              client: { id: null, company_code: "N/A" },
            }),
          ...{ key: departmentToArray(data) },
        };
      });
      setTableData(mappedProjectSummaryData || []);
    }
  }, [projectSummaryData]);

  const value = useMemo(
    () => ({
      isInactive,
      setIsInactive,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      search,
      setSearch,
      refetch,
      setPage,
    }),
    [
      isInactive,
      setIsInactive,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      search,
      setSearch,
      refetch,
      setPage,
    ]
  );

  const handleSortRequest = (key, parentKey) => {
    let direction = "asc";
    direction = orderDirection === "desc" ? "asc" : "desc";
    setOrderDirection(direction);
    setTableData((prev) => sortArrayTable(prev, direction, key, parentKey));
  };

  const handleChangePage = (page) => {
    setPage(page + 1);
  };

  const handleChangeRowsPerPage = (e) => {
    setPage(1);
    setLimit(e.target.value);
  };

  return (
    <div>
      <Loader isLoading={isFetching} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          cursor: "context-menu",
        }}
      >
        <Tooltip
          title={"View company project code summary"}
          placement={"right"}
          arrow
        >
          <Box>
            <HeaderReports text={"Project Code Summary"} />
          </Box>
        </Tooltip>
      </Box>
      <ProjectCodeSummaryContext.Provider value={value}>
        {/* Filter */}
        <ProjectCodeSummaryFilter />
        {/* End of  Filter */}

        {!tableData?.length && !isFetching ? (
          <EmptyData />
        ) : (
          <ReportTableContainter style={{ padding: "1em" }}>
            <TableHeader
              isCollapsable={false}
              headerList={ProjectCodeSummaryTableHeaderV5}
              handleSortRequest={handleSortRequest}
              orderDirection={orderDirection}
            />
            <TableBody>
              {tableData?.map((item) => {
                return <ProjectList key={item.id} item={item} />;
              })}
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[1, 5, 10, 30]}
                  count={projectSummaryData?.total || 0}
                  page={
                    projectSummaryData?.current_page
                      ? projectSummaryData.current_page - 1
                      : 0
                  }
                  onPageChange={(e, page) => {
                    handleChangePage(page);
                  }}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  showFirstButton={true}
                  showLastButton={true}
                />
              </TableRow>
            </TableBody>
          </ReportTableContainter>
        )}
      </ProjectCodeSummaryContext.Provider>
    </div>
  );
};

export default ProjectCodeSummary;
