import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

export const downloadFile = ({ data, fileName, fileType }) => {
  const blob = new Blob([data], { type: fileType });

  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);

  const clickEvent = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  a.dispatchEvent(clickEvent);
  a.remove();
};

export const downloadCsv = (usersData) => {
  const headers = ["ID,Name,Email,Department,Status,Schedule"];

  const content = usersData.map((user) =>
    [
      user.employee?.emp_company_id || "-",
      `"${user.employee?.lastname || null}, ${
        user.employee?.firstname || null
      }"`,
      user.employee?.email_work || "-",
      user.employee.department?.name || "-",
      !!user.status ? "Active" : "Inactive",
      user.employee.details?.schedule || "-",
    ].join(",")
  );

  const csvData = [...headers, ...content].join("\n");

  downloadFile({
    data: csvData,
    fileName: "employees.csv",
    fileType: "text/csv",
  });
};

export const downloadPdf = (usersData) => {
  const head = [["ID", "Name", "Email", "Department", "Status", "Schedule"]];

  const body = usersData.map((user) => [
    user.employee?.emp_company_id || "-",
    `${user.employee?.lastname || null}, ${user.employee?.firstname || null}`,
    user.employee?.email_work || "-",
    user.employee.department?.name || "-",
    !!user.status ? "Active" : "Inactive",
    user.employee.details?.schedule || "-",
  ]);

  const doc = new jsPDF("landscape");

  autoTable(doc, { head, body });

  doc.save("employees.pdf");
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const downloadSampleCsv = () => {
  const headers = [
    "First Name,Last Name,Middle Name,Name Extension,Email,Gender,Department,Date Hired,WFH Required",
  ];

  const content = [
    "Justin,Case,Run,Jr.,justin@gmail.com,Male,ADMIN,07/11/2021,Yes",
    "Jason,Response,Log,II,jason@gmail.com,Female,ADMIN,24/08/2022,Yes",
  ];

  const csvData = [...headers, ...content].join("\n");

  downloadFile({
    data: csvData,
    fileName: "sample.csv",
    fileType: "text/csv",
  });
};

export const generateErrorObj = (error) => {
  const status = error.response.status;

  if (status === 422) {
    return {
      title: Object.entries(error.response.data)[0][0],
      message: Object.entries(error.response.data)[0][1],
    };
  }

  return { title: null, message: "An error occured!" };
};
