import { useContext } from "react";

import Body from "@mui/material/TableBody";

import { generateSkeletonLoader } from "../../../../helper/LeaveManagement/LeaveConversionApplications";
import LeaveConversionApplicationsContext from "../../../../globalstate/LeaveManagement/LeaveConversionApplications/LeaveConversionApplicationsContext";
import NoResults from "./NoResults";
import TableRowSkeletonLoader from "../loader/TableRowSkeletonLoader";
import ApplicationTableRow from "./ApplicationTableRow";

const TableBody = () => {
  const {
    query: { isLoading, data },
    state: { pageSize },
  } = useContext(LeaveConversionApplicationsContext);

  return (
    <Body>
      {/* Render skeleton loader if query is still fetching
       */}
      {!isLoading
        ? data?.data?.map((application) => (
            <ApplicationTableRow key={application.id} data={application} />
          ))
        : generateSkeletonLoader(pageSize, <TableRowSkeletonLoader />)}

      {/* Render 'No results' component if no results found */}
      {!isLoading && !data?.data.length && <NoResults />}
    </Body>
  );
};

export default TableBody;
