import apiService from "../axios";

export const getUserAppSettingsData = async ({
  page_size = 10,
  search,
  page = 1,
}) => {
  try {
    let response = await apiService.get(
      `v5/leave/get-settings?page_size=${page_size}&search=${search}&page=${page}`,
      {
        Accept: `application/json`,
        api: true,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getManagers = async () => {
  try {
    let response = await apiService.post(`v1/employee/get-managers`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
