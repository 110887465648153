import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

const SearchComponent = ({ search, setSearch, setPage }) => {
  const setSearching = (event) => {
    setSearch(event.target.value);
    setPage(1);
  };

  return (
    <TextField
      type="search"
      id="outlined-search"
      label="Search"
      size={"small"}
      sx={{ width: "200px", minWidth: "200px" }}
      InputProps={{
        endAdornment: search?.length === 0 && (
          <InputAdornment
            sx={{ cursor: "pointer", height: "200px !important" }}
            position="end"
          >
            <SearchRoundedIcon color={search?.length === 0 ? "" : "info"} />
          </InputAdornment>
        ),
      }}
      value={search}
      onChange={setSearching}
    />
  );
};
export default SearchComponent;
