import React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  Box,
  Typography,
  Dialog,
  DialogContent,
  TextField,
  Button,
} from "@mui/material";

const Password = ({ form, open, close, validation }) => {
  return (
    <>
      <Dialog maxWidth="md" open={open} onClose={close} fullWidth={true}>
        <DialogContent>
          <form
            ref={form}
            className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
          >
            <Typography variant="h4">Change Password</Typography>
            <IconButton
              aria-label="close"
              onClick={close}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  name="current_password"
                  id="current-password"
                  label="Current Password"
                  fullWidth
                  type="password"
                  value={validation.values.current_password}
                  onChange={(e) =>
                    validation.setFieldValue(
                      "current_password",
                      e.target.value,
                      true
                    )
                  }
                  error={
                    validation.touched.current_password &&
                    !!validation.errors.current_password
                  }
                  helperText={
                    validation.touched.current_password &&
                    validation.errors.current_password
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  name="new_password"
                  id="new-password"
                  label="New Password"
                  fullWidth
                  type="password"
                  value={validation.values.new_password}
                  onChange={(e) =>
                    validation.setFieldValue(
                      "new_password",
                      e.target.value,
                      true
                    )
                  }
                  error={
                    validation.touched.new_password &&
                    !!validation.errors.new_password
                  }
                  helperText={
                    validation.touched.new_password &&
                    validation.errors.new_password
                  }
                />
              </Grid>
              <Grid item xs={12}>
                {}
                <TextField
                  variant="standard"
                  name="confirm_password"
                  id="confirm-password"
                  label="Confirm New Password"
                  fullWidth
                  type="password"
                  value={validation.values.confirm_password}
                  onChange={(e) =>
                    validation.setFieldValue(
                      "confirm_password",
                      e.target.value,
                      true
                    )
                  }
                  error={
                    validation.touched.confirm_password &&
                    !!validation.errors.confirm_password
                  }
                  helperText={
                    validation.touched.confirm_password &&
                    validation.errors.confirm_password
                  }
                />
              </Grid>
            </Grid>
            <Grid container mt={1}>
              <Grid item xs={12}>
                <Box display="flex" p={1} justifyContent="flex-end">
                  <Box>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={validation.handleSubmit}
                      color="primary"
                      sx={{ textTransform: "none", mr: 1 }}
                    >
                      Save
                    </Button>
                  </Box>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ textTransform: "none" }}
                    onClick={close}
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Password;
