import React, { createContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import Loader from "../../../component/Loader";
import { getRangeDates, sortArrayTable } from "../../../helper/Reports";
import reportsQuery from "../../../hooks/react-query/PunctualityTradiness";
import { TableBody, TableRow, TablePagination, Tooltip } from "@mui/material";
import TardinessFilter from "./TardinessFilter";
import UserLists from "./UserLists";
import { TradinessTableHeaderV5 } from "../../../lib/reports/tardiness";
import HeaderReports from "../../../component/Reports/Header";
import EmptyData from "../../../component/Reports/EmptyData";
import TableHeader from "../../../component/Reports/TableHeader";
import ReportTableContainter from "../../../component/Reports/TableContainter";
import { Box } from "@mui/system";
export const TardinessContext = createContext({});

const PunctualityTardiness = () => {
  const [range, setRange] = useState("This Week");
  const [startDate, setStartDate] = useState(moment().startOf("week"));
  const [endDate, setEndDate] = useState(moment().endOf("week"));
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(30);
  const [page, setPage] = useState(1);
  const [orderDirection, setOrderDirection] = useState("desc");
  const [tableData, setTableData] = useState([]);

  const handleRangeChange = (e) => {
    const date = getRangeDates(e.target.value);
    setRange(e.target.value);
    setStartDate(date.fromDate);
    setEndDate(date.toDate);
  };

  const handleChangeRowsPerPage = (e) => {
    setPage(1);
    setLimit(e.target.value);
  };

  const handleRefresh = () => {
    if (page === 1 && limit === 30) {
      refetch();
      return;
    }
    setPage(1);
    setLimit(30);
  };

  const {
    data: userLists,
    refetch,
    isFetching,
  } = reportsQuery.usePunctualityTardiness({
    criteria: { page, limit, search },
    payload: {
      fromDate: moment(startDate).format("YYYY-MM-DD"),
      toDate: moment(endDate).format("YYYY-MM-DD"),
      search: search,
      page_size: limit,
      page: page,
    },
  });

  useEffect(() => {
    if (userLists) {
      const mappedUserLists =
        userLists?.data?.map((item) => {
          return {
            ...item,
            percentage: (item.percentage * 100).toFixed(2),
          };
        }) || [];
      setTableData(mappedUserLists);
    }
  }, [userLists]);

  const handleChangePage = (page) => {
    setPage(page + 1);
  };

  const handleSortRequest = (key) => {
    let direction = "asc";
    direction = orderDirection === "desc" ? "asc" : "desc";
    setOrderDirection(direction);
    setTableData((prev) => sortArrayTable(prev, direction, key));
  };

  const value = useMemo(
    () => ({
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      search,
      setSearch,
      refetch,
      range,
      setRange,
      isFetching,
      setPage,
    }),
    [
      range,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      search,
      setSearch,
      refetch,
      setRange,
      isFetching,
    ]
  );

  return (
    <div>
      <Loader isLoading={isFetching} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          cursor: "context-menu",
        }}
      >
        <Tooltip
          title={"View company punctuality and tardiness report"}
          placement={"right"}
          arrow
        >
          <Box>
            <HeaderReports text={"Punctuality / Tardiness"} />
          </Box>
        </Tooltip>
      </Box>
      <TardinessContext.Provider value={value}>
        <TardinessFilter
          handleRangeChange={handleRangeChange}
          handleRefresh={handleRefresh}
        />

        {!tableData?.length && !isFetching ? (
          <EmptyData />
        ) : (
          <ReportTableContainter>
            <TableHeader
              isCollapsable={true}
              headerList={TradinessTableHeaderV5}
              handleSortRequest={handleSortRequest}
              orderDirection={orderDirection}
            />
            <TableBody>
              {tableData?.map((item, index) => {
                return <UserLists key={`${index}_${item.name}`} item={item} />;
              })}
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[1, 5, 10, 30]}
                  count={userLists?.total || 0}
                  page={
                    userLists?.current_page ? userLists.current_page - 1 : 0
                  }
                  onPageChange={(e, page) => {
                    handleChangePage(page);
                  }}
                  showFirstButton={true}
                  showLastButton={true}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableBody>
          </ReportTableContainter>
        )}
      </TardinessContext.Provider>
    </div>
  );
};

export default PunctualityTardiness;
