import * as yup from "yup";

export const feedbackSchema = yup
  .object({
    subject: yup
      .string()
      .required("Subject is required.")
      .matches(/^(.*\S.*)/g, "Subject is required."),
    category: yup
      .string()
      .required("Concern is required.")
      .matches(/^(.*\S.*)/g, "Concern is required."),
    content: yup
      .string()
      .required("Message is required.")
      .matches(/^(.*\S.*)/g, "Message is required."),
  })
  .required();
