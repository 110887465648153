import React from "react";
import NoData from "./NoData";
import Fetching from "./Fetching";

const Row = ({
  data = [],
  header,
  handleDelete,
  handleEdit,
  isFetching = true,
  hiddenRows,
  EditRow,
  Row,
  options,
  edit,
  handleSave,
  handleCancel,
  formFields = [],
}) => {
  return (
    <>
      {hiddenRows &&
        hiddenRows.map((hiddenRow) => {
          return hiddenRow.row;
        })}
      {isFetching ? (
        <Fetching />
      ) : data && data.length === 0 ? (
        <NoData />
      ) : (
        data &&
        data.map((row, index) =>
          edit === row.id ? (
            <EditRow
              key={index}
              header={header}
              row={row}
              handleSave={handleSave}
              handleCancel={handleCancel}
              formFields={formFields}
              options={options}
            />
          ) : (
            <Row
              key={index}
              header={header}
              edit={edit}
              row={row}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              options={options}
            />
          )
        )
      )}
    </>
  );
};

export default Row;
