import { useQuery } from "react-query";
import { getCompliance } from "../../../../api/settingsTimesheet/compliance";

const useCompliance = (
  QueryParam,
  keyword = "",
  status = 1,
  filter,
  limit = 10,
  offset = 1,
  fetch,
  startDate,
  endDate
) => {
  return useQuery(
    [
      "get-activity",
      keyword,
      status,
      filter,
      limit,
      offset,
      fetch,
      startDate,
      endDate,
    ],
    () => getCompliance(QueryParam)
  );
};

const queries = {
  useCompliance,
};

export default queries;
