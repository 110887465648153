import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export default function DeleteRequestModal({
  select,
  open,
  handleClose,
  cancelDeleteFunc,
  confirmDeleteFunc,
}) {
  return (
    <div>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="keep-mounted-modal-title"
            align={"center"}
            variant="h6"
            component="h2"
          >
            {select === "LeaveConversion" ? "Cancel" : "Delete"} request
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to{" "}
            {select === "LeaveConversion" ? "cancel" : "delete"} the request?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {<LinearProgress />}
            <Button
              onClick={confirmDeleteFunc}
              variant="contained"
              color="success"
              sx={{ margin: "10px 10px 0" }}
            >
              CONFIRM
            </Button>
            <Button
              onClick={cancelDeleteFunc}
              variant="outlined"
              color="error"
              sx={{ margin: "10px 10px 0" }}
            >
              CANCEL
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
