import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { ArrowBack, Edit } from "@mui/icons-material";

const UserInformationButtons = ({ disabled, onClick }) => {
  return (
    <Box display="flex" justifyContent="flex-end" gap="18px">
      <Button
        component={Link}
        to={`/settings/users`}
        variant="contained"
        startIcon={<ArrowBack />}
      >
        Back to User List
      </Button>

      <Button
        disabled={disabled}
        variant="contained"
        startIcon={<Edit />}
        onClick={onClick}
      >
        Edit
      </Button>
    </Box>
  );
};

export default UserInformationButtons;
