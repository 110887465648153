import { useQuery } from "react-query";
import { getDepartment } from "../../../../api/user";
import timesheetAPI from "../../../../api/projects/index";
import { getEmployeeList } from "../../../../api/evaluation/timesheetWeeklyReport";

export const useGetDepartment = () => {
  return useQuery(["get-departments"], () => getDepartment());
};

export const useGetUserList = () => {
  return useQuery(["get-userlist"], () => getEmployeeList());
};

export const useGetType = () => {
  return useQuery(["get-types"], () => timesheetAPI.getType());
};

export const useGetCode = () => {
  return useQuery(["get-code"], () => timesheetAPI.getProjectCode());
};

export const useGetActivity = () => {
  return useQuery(["get-activity"], () => timesheetAPI.getProjectActivity());
};
