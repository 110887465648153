import React, { useContext } from "react";
import { Button, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { useQueryClient } from "react-query";
import useWindowSize from "../../../../hooks/customHooks/useWindowSize";
import { findSetting } from "../../../../helper/UserApplicationMgmt/UserApplications";
import { GlobalContext } from "../../../../App";

const RequestTabination = ({
  setType,
  setPage,
  select,
  setSelect,
  notInclude,
}) => {
  const queryClient = useQueryClient();
  const size = useWindowSize();
  const { settings } = useContext(GlobalContext);
  const medium = 950;
  const buttonBackgroundColor =
    findSetting("bgcolor_appbar", settings) || "#21293C";

  const unSelected = {
    border: "0px",
    borderRadius: "10px",
    width: "100%",
    py: "12px",
    color: "#757575",
    "&:hover": {
      border: "0px",
      borderRadius: "10px",
    },
  };

  const selected = {
    border: "0px",
    borderRadius: "10px",
    width: "100%",
    py: "12px",
    color: "#fff",
    backgroundColor: buttonBackgroundColor,
    "&:hover": {
      border: "0px",
      borderRadius: "10px",
      backgroundColor: buttonBackgroundColor,
    },
  };

  const handleChangeTab = async (select) => {
    setSelect(select);
    setType("All");
    await queryClient.refetchQueries("get-employee-request-headers", select);
    setPage(1);
  };

  return (
    <Box sx={{ margin: "15px 20px" }}>
      <Paper
        sx={
          size.width > 670
            ? {
                borderRadius: "10px",
                // maxWidth: "900px",
                display: "flex",
                justifyContent: "center",
              }
            : {
                borderRadius: "10px",
                //  maxWidth: "900px",
              }
        }
      >
        <Button
          onClick={() => handleChangeTab("Leave")}
          sx={select === "Leave" ? selected : unSelected}
        >
          LEAVE
        </Button>
        <Button
          onClick={() => handleChangeTab("Overtime")}
          sx={select === "Overtime" ? selected : unSelected}
        >
          {size.width > medium ? "OVERTIME/UNDERTIME" : "OT/UT"}
        </Button>
        <Button
          onClick={() => handleChangeTab("WFH")}
          sx={select === "WFH" ? selected : unSelected}
        >
          {size.width > medium ? "WORK FROM HOME" : "WFH"}
        </Button>
        {!notInclude && (
          <Button
            onClick={() => handleChangeTab("LeaveConversion")}
            sx={select === "LeaveConversion" ? selected : unSelected}
          >
            LEAVE CONVERSION
          </Button>
        )}
        <Button
          onClick={() => handleChangeTab("ForgotTimeInOut")}
          sx={select === "ForgotTimeInOut" ? selected : unSelected}
        >
          FORGOT TIME IN/OUT
        </Button>
        <Button
          onClick={() => handleChangeTab("Document")}
          sx={select === "Document" ? selected : unSelected}
        >
          Document
        </Button>
      </Paper>
    </Box>
  );
};
export default RequestTabination;
