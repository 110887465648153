import React from "react";
import { Typography, Avatar, IconButton, CardHeader, Box } from "@mui/material";

const SkillHeader = ({ colleague, timeInAvatar }) => {
  return (
    <CardHeader
      title={
        <Box sx={{ display: "flex", justifyContent: "left", m: "auto" }}>
          <IconButton>
            <Avatar
              src={timeInAvatar}
              sx={{
                width: 55,
                height: 55,
              }}
            />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="caption"
              sx={{
                fontSize: "0.87rem",
                fontWeight: "500",
              }}
            >
              {colleague?.full_name}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                fontSize: "0.7rem",
                fontWeight: "400",
              }}
            >
              {colleague?.department?.description}
            </Typography>
          </Box>
        </Box>
      }
      sx={{ padding: "15px 15px 0px 15px" }}
    />
  );
};

export default SkillHeader;
