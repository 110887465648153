import * as React from "react";
import Modal from "@mui/material/Modal";
import { Box, Button, FormGroup, TextField } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";
import { editCompliance } from "../../../api/compliance";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export default function EditComplianceModal({
  editId,
  editItem,
  editStatus,
  openEdit,
  handleCloseEdit,
}) {
  const queryClient = useQueryClient();
  const [id, setId] = useState("");
  const [item, setItem] = useState("");
  const [status, setStatus] = useState("");

  const editMutation = useMutation(() => editCompliance(id, item, status), {
    onSuccess: async (response) => {
      await queryClient.refetchQueries("get-compliance");
      handleCloseEdit();
    },
  });

  useEffect(() => {
    setId(editId);
    setItem(editItem);
    setStatus(editStatus);
  }, [editId, editItem, editStatus]);

  const editFunc = () => {
    setItem(item ? item : editItem);
    setStatus(status);
    editMutation.mutate();
  };

  return (
    <div>
      <Modal
        keepMounted
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <FormGroup>
            <Box sx={{ fontWeight: "bold", fontSize: 25, mb: "25px" }}>
              EDIT COMPLIANCE ITEM
            </Box>
            <TextField
              value={item}
              onChange={(e) => setItem(e.target.value)}
              sx={{ mb: "10px" }}
              label="Item"
              id="itemCompliance"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="success"
                  checked={status === "active"}
                  onChange={(e) =>
                    setStatus(status === "active" ? "inactive" : "active")
                  }
                />
              }
              label="Active"
            />
            <Box sx={{ mt: "10px", display: "flex", justifyContent: "center" }}>
              <Button
                onClick={editFunc}
                sx={{ mx: "10px" }}
                variant="contained"
                color={"success"}
              >
                CONFIRM
              </Button>
              <Button
                onClick={handleCloseEdit}
                sx={{ mx: "10px" }}
                variant="outlined"
                color={"error"}
              >
                CANCEL
              </Button>
            </Box>
          </FormGroup>
        </Box>
      </Modal>
    </div>
  );
}
