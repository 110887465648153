import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const StyledTextField = styled(TextField)(() => ({
  width: "100%",
  marginBottom: "4px",
  padding: "8px",
  alignSelf: "flex-end",
  ".MuiInputBase-root": {
    padding: "3px 2px 3px 7px",
    borderRadius: "6px",
    backgroundColor: "#F6F6F6",
    "&.Mui-disabled": {
      backgroundColor: "#E6E6E6",
    },
    "&.Mui-disabled fieldset": {
      border: "2px solid transparent",
    },
    "&.Mui-disabled:hover fieldset": {
      border: "2px solid transparent",
    },
    "& fieldset": {
      border: "2px solid transparent",
    },
    "&:hover fieldset": {
      borderColor: "#576c9f",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#21293C",
    },
  },
}));

export default StyledTextField;
