import React, { useContext } from "react";
import {
  Paper,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import { NotificationContext } from "../../globalstate/UserContext/Notification";
import {
  useReadAllNotification,
  useReadNotification,
} from "../../hooks/react-query/Notification";
import { useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import format from "date-fns/format";

const NotificationPage = () => {
  const { data: notif } = useContext(NotificationContext);
  const { mutate: ReadNotification } = useReadNotification();
  const { mutate: ReadlAllNotification } = useReadAllNotification();
  let navigate = useNavigate();

  const handleReadNotif = (notif) => {
    navigate(notif.link);
    ReadNotification(notif);
  };
  const handleReadAllNotif = () => {
    ReadlAllNotification();
    navigate("/dashboard");
  };
  return (
    <Paper
      sx={{
        height: "87%",
        margin: "10px 20px 0px 20px",
        padding: "20px",
        borderRadius: "10px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Notifications
        </Typography>
        <Button
          onClick={handleReadAllNotif}
          variant="contained"
          size="small"
          sx={{ textTransform: "none" }}
        >
          Mark all notification as seen
        </Button>
      </div>
      <List
        sx={{
          maxHeight: "90%",
          overflow: "hidden",
          overflowY: "scroll",
          marginTop: "20px",
        }}
      >
        {notif?.data?.map((notif, key) => (
          <div key={key}>
            <ListItem
              button
              secondaryAction={
                <Tooltip title="Mark as read">
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => ReadNotification(notif)}
                  >
                    <CancelIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              }
            >
              <ListItemText
                secondary={format(new Date(notif.created_at), "PPP")}
                // sx={{ fontSize: "0.75rem" }}
                onClick={() => handleReadNotif(notif)}
                inset
              >
                {notif.message}
              </ListItemText>
            </ListItem>
            <Divider />
          </div>
        ))}
      </List>
    </Paper>
  );
};

export default NotificationPage;
