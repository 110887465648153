import React, { useState, useContext } from "react";
import {
  TableRow,
  TableCell,
  TextField,
  IconButton,
  Chip,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RestoreIcon from "@mui/icons-material/Restore";
import CheckIcon from "@mui/icons-material/Check";
import hooks from "../../../hooks/react-query/queryHooks";
import { LeaveTypesContext } from ".";
import DisableLeaveTypeModal from "./DisableLeaveTypeModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  isJsonString,
  FindDataByID,
  findDataByArrID,
} from "../../../helper/ProjectCodeSettings";
import AutoCompleteDescription from "../../../component/CustomElement/AutoCompleteDescription";

const LeaveTypesList = ({ item, index, departments }) => {
  const deptArr = isJsonString(item.department)
    ? isJsonString(item.department).map((dept) => dept)
    : [];
  const deptValue = findDataByArrID({ data: departments }, deptArr);
  const [edit, setEdit] = useState(true);
  const [name, setName] = useState("");
  const [shortname, setShortName] = useState("");
  const [description, setDescription] = useState("");
  const [credits, setCredits] = useState("");
  const [dept, setDept] = useState([]);
  const { refetch, setLoad } = useContext(LeaveTypesContext);
  const [openDisableModal, setOpenDisableModal] = useState(false);
  const [creditLimit, setCreditLimit] = useState(false);
  const { mutate: editLeaveType, isLoading: loadingEdit } = hooks.useEditTypes({
    edit,
    setEdit,
  });
  const { mutate: disableLeaveType, isLoading: loadingDel } =
    hooks.useDisabledLeaveType();
  const { mutate: restoreLeaveType } = hooks.useRestoreLeaveType();
  const editHandler = () => {
    const deptID = dept?.map((data) => data.id);
    if (!creditLimit) {
      const leaveTypes = {
        id: item?.id,
        name: name !== "" ? name : item.name,
        shortname: shortname !== "" ? shortname : item.shortname,
        description: description !== "" ? description : item.description,
        credits: credits !== "" ? String(credits) : String(item?.credits),
        department: dept.length > 0 ? deptID : item.department,
      };
      editLeaveType(leaveTypes);
    } else if (creditLimit === true) {
      toast.error("Max credits reached!");
    }
  };

  const deleteHandler = () => {
    const data = {
      id: item?.id,
    };
    disableLeaveType(data);
    setOpenDisableModal(false);
  };
  const restoreHandler = () => {
    const data = {
      id: item?.id,
    };
    restoreLeaveType(data);
    setLoad(true);
    refetch();
  };
  const closeHandler = () => {
    setOpenDisableModal(false);
  };

  const cancelHandler = () => {
    setEdit(true);
  };
  const handleDeptSelect = (event, newValue) => {
    setDept(newValue);
  };
  return (
    <>
      {openDisableModal && (
        <DisableLeaveTypeModal
          deleteHandler={deleteHandler}
          openDisableModal={openDisableModal}
          closeHandler={closeHandler}
        />
      )}
      <TableRow key={index}>
        <TableCell>
          <TextField
            required
            defaultValue={item.name}
            InputProps={{
              readOnly: edit,
              disableUnderline: edit,
            }}
            onChange={(e) => setName(e.target.value)}
            variant="standard"
            multiline
          />
        </TableCell>
        <TableCell>
          <TextField
            required
            defaultValue={item.shortname}
            InputProps={{
              readOnly: edit,
              disableUnderline: edit,
            }}
            variant="standard"
            onChange={(e) => setShortName(e.target.value)}
          />
        </TableCell>
        <TableCell>
          <TextField
            required
            defaultValue={item.description}
            InputProps={{
              readOnly: edit,
              disableUnderline: edit,
            }}
            variant="standard"
            onChange={(e) => setDescription(e.target.value)}
            multiline
          />
        </TableCell>
        <TableCell>
          <TextField
            required
            type="number"
            defaultValue={item?.credits}
            InputProps={{
              readOnly: edit,
              disableUnderline: edit,
            }}
            variant="standard"
            onInput={(e) => {
              e.target.value > 99.99
                ? setCreditLimit(true)
                : setCreditLimit(false);
            }}
            onChange={(e) => setCredits(e.target.value)}
          />
        </TableCell>
        <TableCell>
          {edit ? (
            item.department === null ? (
              "N/A"
            ) : (
              isJsonString(item.department).map((data, key) => (
                <Chip
                  key={key}
                  label={FindDataByID({ data: departments }, data)?.name}
                  size="small"
                  style={{
                    fontSize: "0.65rem",
                    color: FindDataByID({ data: departments }, data)?.color
                      ? "white"
                      : "black",
                    backgroundColor: FindDataByID({ data: departments }, data)
                      ?.color,
                  }}
                />
              ))
            )
          ) : (
            <AutoCompleteDescription
              data={{ id: "id", name: "name", desc: "description" }}
              label={"Department"}
              multiple={true}
              optionData={departments}
              value={deptValue}
              onChange={handleDeptSelect}
            />
          )}
        </TableCell>
        {item.status === 1 ? (
          <TableCell align="center">
            {loadingDel || loadingEdit ? (
              <CircularProgress />
            ) : edit === true ? (
              <>
                <IconButton
                  onClick={() => setEdit(false)}
                  sx={{ marginRight: "5px" }}
                >
                  <EditIcon style={{ color: "#00C292" }} />
                </IconButton>
                <IconButton
                  onClick={() => setOpenDisableModal(true)}
                  sx={{ marginRight: "5px" }}
                >
                  <DeleteOutlineIcon style={{ color: "#000000" }} />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton onClick={editHandler} sx={{ marginRight: "5px" }}>
                  <CheckIcon style={{ color: "#00C292" }} />
                </IconButton>
                <IconButton onClick={cancelHandler} sx={{ marginRight: "5px" }}>
                  <CloseIcon style={{ color: "#ff0000" }} />
                </IconButton>
              </>
            )}
          </TableCell>
        ) : (
          <TableCell align="center">
            <IconButton onClick={restoreHandler}>
              <RestoreIcon style={{ color: "#00C292" }} />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
    </>
  );
};

export default LeaveTypesList;
