import React from "react";
import { Card, Grid } from "@mui/material";

const FilterHeader = ({ children }) => {
  return (
    <Card
      sx={{
        borderRadius: "10px",
        padding: "14px",
        margin: "15px",
        boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
        position: "sticky",
        top: "0",
        zIndex: "1",
        "@media(max-width: 768px)": {
          position: "relative",
        },
      }}
    >
      <Grid container rowSpacing={2}>
        <Grid
          item
          lg={12}
          container
          sx={{
            justifyContent: "left",
            alignItems: "center",
          }}
        >
          {children}
        </Grid>
      </Grid>
    </Card>
  );
};

export default FilterHeader;
