import { IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import TableCell from "@mui/material/TableCell";
import Chip from "@mui/material/Chip";
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded";
import { Box } from "@mui/system";
import { useMutation } from "react-query";
import { imageView } from "../../../../../api/userrequest";
import Dialog from "@mui/material/Dialog";
import { CircularProgress } from "@material-ui/core";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import MapsHomeWorkRoundedIcon from "@mui/icons-material/MapsHomeWorkRounded";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import CommentComponentModal from "../../CommentComponentModal";
import { getAuth } from "../../../../../hooks/customHooks/auth";
import { format, parseISO } from "date-fns";
import { AttachFileOutlined } from "@mui/icons-material";

const TableBodyData = ({ row, colId, id, align, formatt, value }) => {
  const user = getAuth();
  const [image, setImage] = useState();
  const [openImage, setOpenImage] = useState(false);
  const [commentOpen, setCommentOpen] = useState(false);
  const [comment, setComment] = useState();
  let comments = [];

  if (colId === "comments") {
    comments = value.filter((approver) => {
      return approver.remarks && approver.remarks !== null;
    });
  }

  const getImage = useMutation(
    () => imageView(row.id, user.employee.id, row.type),
    {
      onSuccess: async (response) => {
        const image_bucket = [];
        response?.forEach((img) => {
          image_bucket.push({
            original: img,
            thumbnail: img,
          });
        });
        setImage(image_bucket);
      },
    }
  );

  const handleOpenImage = () => {
    setOpenImage(true);
    getImage.mutate();
  };

  const handleClose = () => {
    setOpenImage(false);
  };

  const handleOpenComment = () => {
    setComment(value);
    setCommentOpen(true);
  };

  const handleCloseComment = () => {
    setCommentOpen(false);
  };

  return (
    <TableCell
      key={id}
      align="left"
      style={{
        minWidth:
          colId === "created_at"
            ? "250px"
            : colId === "leave_credits"
            ? "100px"
            : colId === "status"
            ? "200px"
            : "100px",
        padding: "16px 10px",
      }}
    >
      {colId === "status" ? (
        value?.toLowerCase() === "approved" ? (
          <>
            {row.type !== "Leave Conversion" && (
              <>
                <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                  Approved date :
                </Typography>
                <Typography sx={{ fontSize: "13px", mb: "15px" }}>
                  {format(parseISO(row.updated_at), "PPp")}
                </Typography>
              </>
            )}
            <Chip label={value?.toLowerCase()} color="success" size={"small"} />
          </>
        ) : value?.toLowerCase() === "pending" ? (
          <Chip label={value?.toLowerCase()} color="warning" size={"small"} />
        ) : value?.toLowerCase() === "disapproved" ? (
          <Chip label={value?.toLowerCase()} color="error" size={"small"} />
        ) : (
          <Chip label={value?.toLowerCase()} color="default" size={"small"} />
        )
      ) : (
        <Typography variant={"caption"}>
          {formatt && typeof value === "number" ? (
            formatt(value)
          ) : colId === "comments" ? (
            comments.length > 0 ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <IconButton onClick={() => handleOpenComment()}>
                  <CommentRoundedIcon />
                </IconButton>
              </Box>
            ) : (
              "No comment"
            )
          ) : colId === "created_at" ? (
            row.type === "Leave Conversion" ? (
              <Box>
                <Typography sx={{ fontSize: "13px" }}>
                  {value.created_at}
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                  Filed on {value.created_at}
                </Typography>
                <Typography sx={{ fontSize: "13px" }}>
                  {row.type === "in" || row.type === "out"
                    ? format(parseISO(row.date), "LLL dd, yyyy h:mm aa")
                    : `${value.from} to`}
                </Typography>
                <Typography sx={{ fontSize: "13px" }}>{value.to}</Typography>
                {row.type === "in" || row.type === "out" ? (
                  <Typography sx={{ fontSize: "13px" }}>
                    {row.type === "in" ? "Time In" : "Time Out"}
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: "13px" }}>
                    {row.hours
                      ? (value.duration || 0) + " hour(s)"
                      : (value.duration || 0) + " day(s)"}
                  </Typography>
                )}
              </Box>
            )
          ) : colId === "workplace" || colId === "location" ? (
            <Box>
              <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                {colId === "workplace" ? "(WFH) Description" : "Location"}
              </Typography>
              <Typography sx={{ fontSize: "13px", marginBottom: "12px" }}>
                {value}
              </Typography>
              <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                {colId === "workplace" ? "(WFH) Attachment" : "Attachment"}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <IconButton
                  onClick={handleOpenImage}
                  aria-label="view-image"
                  sx={{ color: "#000000DE" }}
                >
                  <InsertPhotoRoundedIcon />
                </IconButton>
              </Box>
            </Box>
          ) : colId === "Document" ? (
            row.document
          ) : colId === "docuDescription" ? (
            row.description
          ) : (
            value
          )}
        </Typography>
      )}
      <Dialog
        PaperProps={{
          sx: image
            ? {
                width: "70%",
                height: "80%",
                borderRadius: "10px",
              }
            : {
                width: "70%",
                height: "80%",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
        }}
        open={openImage}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullScreen
      >
        {image ? (
          <>
            <DialogTitle
              id="customized-dialog-title"
              sx={{ padding: 0, position: "fixed", zIndex: "1", width: "70%" }}
            >
              <Paper
                elevation={"6"}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Box sx={{ display: "flex", padding: "10px" }}>
                  {row.type === "in" || row.type === "out" ? (
                    <AttachFileOutlined />
                  ) : (
                    <MapsHomeWorkRoundedIcon />
                  )}
                  <Typography
                    variant={"h6"}
                    sx={{ fontSize: "20px", paddingLeft: "2px" }}
                  >
                    {row.type === "in" || row.type === "out"
                      ? "Attachment"
                      : "Workplace"}
                  </Typography>
                </Box>
                <IconButton onClick={handleClose} aria-label="close">
                  <CloseRoundedIcon />
                </IconButton>
              </Paper>
            </DialogTitle>
            <ImageGallery showPlayButton={false} items={image} />
          </>
        ) : (
          <CircularProgress />
        )}
      </Dialog>
      <CommentComponentModal
        comment={comment}
        commentOpen={commentOpen}
        handleCloseComment={handleCloseComment}
      />
    </TableCell>
  );
};
export default TableBodyData;
