import { useQuery } from "react-query";
import { getAnnouncement } from "../../../api/announcement";

const useAnnouncement = (QueryParam) => {
  return useQuery(["get-announcements"], () => getAnnouncement());
};

const queries = {
  useAnnouncement,
};

export default queries;
