import React from "react";
import { TableRow, TableCell, Typography, Tooltip } from "@mui/material";
import moment from "moment";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";

const Document = ({
  data,
  header,
  handleClick,
  handleRemove,
  handleEdit,
  permission,
  handleReport,
}) => {
  return (
    <>
      {data?.map((row, index) => (
        <TableRow key={index}>
          {header?.map((column, index) => (
            <TableCell key={index} scope="row">
              {column?.type === "date" ? (
                <Typography sx={{ fontSize: 14 }}>
                  {moment(row[column?.data]).format("ll")}
                </Typography>
              ) : column?.data === "remarks" ? (
                <Typography
                  sx={{
                    fontSize: 14,
                    maxWidth: "150px",
                    wordWrap: "break-word",
                    whitepace: "normal",
                  }}
                >
                  {row[column?.data]}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontSize: 14,
                    wordWrap: "break-word",
                    whitepace: "normal",
                    textTransform: "none",
                  }}
                >
                  {row[column?.data]}
                </Typography>
              )}
            </TableCell>
          ))}
          <TableCell scope="row" align="left" nowrap="true">
            <Tooltip title="Open Document">
              <OpenInNewIcon
                path={row.path}
                ext={row.ext}
                sx={{ "&:hover": { cursor: "pointer" }, color: "#00C292" }}
                name={row.name.split(" ").join("_")}
                onClick={(e) =>
                  handleClick({
                    path: row.path,
                    name: row.name.split(" ").join("_"),
                    ext: row.ext,
                    id: row.id,
                  })
                }
              />
            </Tooltip>

            {permission &&
              permission["permissions"].find(
                (column) => column === "manage hrfolder"
              ) && (
                <>
                  <Tooltip title="Edit Document">
                    <EditOutlinedIcon
                      sx={{
                        "&:hover": { cursor: "pointer" },
                        color: "#0083c9",
                      }}
                      name={row["name"]}
                      remarks={row["remarks"]}
                      content={row["content"]}
                      onClick={(e) => handleEdit(row)}
                    />
                  </Tooltip>
                  <Tooltip title="View Log Report">
                    <PlaylistAddCheckIcon
                      sx={{
                        "&:hover": { cursor: "pointer" },
                        color: "#00C292",
                      }}
                      onClick={(e) => handleReport(row)}
                    />
                  </Tooltip>
                  <Tooltip title="Delete Document">
                    <DeleteOutlineIcon
                      name={row["name"]}
                      sx={{
                        "&:hover": { cursor: "pointer" },
                        color: "#D06A76",
                      }}
                      onClick={(e) => handleRemove(row["id"], row["path"])}
                    />
                  </Tooltip>
                </>
              )}
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default Document;
