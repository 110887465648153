import React from "react";
import { TableRow, TableCell } from "@mui/material";

const ActivityLists = ({ item }) => {
  return (
    <>
      <TableRow sx={{ height: "4em" }}>
        <TableCell></TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.name,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.efforts,
            }}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default ActivityLists;
