import React from "react";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import UpQuestion from "./updateComponents/upQuestion";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { pulseSurveySchema } from "../../../helper/Yup/pulseSurveySchema";
import InputController from "../../InputController";
import hooks from "../../../hooks/react-query/queryHooks";
import { useQueryClient } from "react-query";
import pulseHelper from "./pulseHelper/pulseHelper";
const surveysArr = [
  {
    value: 2,
    label: "Customer Feedback",
  },
  {
    value: 3,
    label: "Customer Satisfaction",
  },
  {
    value: 1,
    label: "One Single Random Question",
  },
  {
    value: 4,
    label: "Quiz",
  },
];
const UpdateSurvey = ({ open, handleUpdateSurvey, dataPlaceholder }) => {
  const queryClient = useQueryClient();
  const [questions, setQuestions] = React.useState(
    new Array(dataPlaceholder.questions.length).fill(true)
  );
  const [updateStat, setUpdateStat] = React.useState(false);
  const { mutate: updatePulseSurvey } = hooks.useUpdatePulseSurvey();
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    register,
    unregister,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(pulseSurveySchema),
    defaultValues: {
      surveyName: dataPlaceholder.name,
      surveyDescription: dataPlaceholder.description,
      surveyType: dataPlaceholder.survey_type_id,
    },
  });

  const handleQuestions = () => {
    const newArr = [...questions, true];
    setQuestions(newArr);
  };

  const QuestionOnSubmit = (data) => {
    const newQuestionArray = [];
    questions.map((qt, i) => {
      const questType = getValues(`selectTypeAnswer${i}`);
      switch (questType) {
        case "Multiple Choice":
          const MC = pulseHelper.createMulti(data, i, getValues);
          newQuestionArray.push(MC);
          break;
        case "Single Text Box":
          const ST = pulseHelper.createSingle(data, i, getValues);
          newQuestionArray.push(ST);
          break;
        case "Checkboxes":
          const CB = pulseHelper.createCheckbox(data, i, getValues);
          newQuestionArray.push(CB);
          break;
        case "Rating":
          const RT = pulseHelper.createRating(data, i, getValues);
          newQuestionArray.push(RT);
          break;
      }
    });
    return newQuestionArray;
  };
  const onSubmit = (data) => {
    setUpdateStat(true);
    const QuestionArr = QuestionOnSubmit(data);
    const payLoadData = {
      name: data.surveyName,
      description: data.surveyDescription,
      type: data.surveyType,
      questions: QuestionArr,
    };
    updatePulseSurvey(
      { id: dataPlaceholder.id, data: payLoadData },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["pulse-survey"]);
          handleUpdateSurvey(null);
        },
      }
    );
  };
  const isFalse = (array) => {
    return array.every((value) => value === false);
  };

  const handleQuestionArr = (position, val, qtID) => {
    pulseHelper.handleUnregister(position, val, qtID, setValue, unregister);

    if (qtID) {
      const updatetOptions = questions.map((item, index) =>
        index === position ? !item : item
      );
      setQuestions(updatetOptions);
      if (isFalse(updatetOptions)) {
        setQuestions([]);
      }
    } else {
      const updatedArr = questions.splice(position, 1);
    }
  };

  return (
    <Modal open={open}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card
          className="login-modal leave1-modal"
          sx={{
            minWidth: "550px",
            maxHeight: "calc(100vh - 75px)",
            overflow: "auto",
          }}
        >
          <CardHeader
            title={
              <Typography variant="h3" className="h3-bar">
                Update Survey
              </Typography>
            }
            action={
              <IconButton
                onClick={() => {
                  handleUpdateSurvey(null);
                }}
              >
                <CloseOutlinedIcon sx={{ width: "15px", height: "15px" }} />
              </IconButton>
            }
          />
          <CardContent>
            <Grid container rowSpacing={2} columnSpacing={2}>
              <Grid item lg={6} xs={6}>
                <InputController
                  type="select"
                  control={control}
                  label="Survey Type"
                  name="surveyType"
                  errors={errors}
                  size="small"
                  options={surveysArr}
                  rules={{
                    required: true,
                  }}
                  isDefaultErrorAlert={true}
                />
              </Grid>
              <Grid item lg={6} xs={6}>
                <InputController
                  type="text"
                  control={control}
                  label="Name"
                  name="surveyName"
                  errors={errors}
                  rules={{ required: true }}
                  isDefaultErrorAlert={true}
                  size="small"
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <InputController
                  type="text"
                  control={control}
                  label="Description"
                  name="surveyDescription"
                  errors={errors}
                  rows={4}
                  rules={{ required: true }}
                  isDefaultErrorAlert={true}
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <Button
                  fullWidth
                  disableElevation
                  variant="contained"
                  onClick={handleQuestions}
                >
                  Add question
                </Button>
              </Grid>
              <Grid item lg={12} xs={12}>
                {questions.length > 0 &&
                  questions.map((qt, i) => {
                    if (qt === true) {
                      return (
                        <UpQuestion
                          key={i}
                          ind={i}
                          register={register}
                          errors={errors}
                          control={control}
                          handleQuestionArr={handleQuestionArr}
                          unregister={unregister}
                          qt={dataPlaceholder.questions[i]}
                          isGreater={
                            questions.length > dataPlaceholder.questions.length
                          }
                          getValues={getValues}
                        />
                      );
                    }
                  })}
              </Grid>
            </Grid>
          </CardContent>
          {questions.length > 0 && (
            <CardActions
              sx={{
                display: "flex",
                justifyContent: "right",
                mt: "5px",
                mb: "10px",
              }}
            >
              <Button
                variant="contained"
                disableElevation
                type="submit"
                disabled={updateStat}
              >
                Update Survey
              </Button>
            </CardActions>
          )}
        </Card>
      </form>
    </Modal>
  );
};

export default UpdateSurvey;
