import React, { useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Slider,
  TextField,
  Button,
  CircularProgress,
  Backdrop,
  Tooltip,
} from "@mui/material";
import DatePickerComponent from "../../../../component/DatePicker";
import AutoCompleteDescription from "../../../../component/CustomElement/AutoCompleteDescription";
import {
  useGetActivity,
  useGetCode,
  useGetDepartment,
  useGetType,
  useGetUserList,
} from "../../../../hooks/react-query/SettingsTimesheet/TimesheetForAll";
import { filterActiveDept } from "../../../../helper/SettingsTimesheet/TimesheetForAll";
import CkEditor from "../../../../component/CustomElement/CKEditor";
import { SortAZ } from "../../../../helper/sort";
import { AddTimesheet } from "../../../../api/settingsTimesheet/TimesheetForAll";

const { format } = require("date-fns");

const initData = {
  action: "all",
  activity: null,
  code: null,
  date: format(new Date(), "PP"),
  department: null,
  details: null,
  except: [],
  hours: 0.25,
  id: null,
  sprint: null,
  type: null,
};
const TimesheetForAll = () => {
  const [data, setData] = useState(initData);
  const [loading, setLoading] = useState(false);
  const { data: dept, isFetching: loadingDept } = useGetDepartment();
  const { data: userList, isFetching: loadingUserList } = useGetUserList();
  const { data: types, isFetching: loadingTypes } = useGetType();
  const { data: codes, isFetching: loadingCode } = useGetCode();
  const { data: activity, isFetching: loadingActiviity } = useGetActivity();
  const [value, setValue] = useState([]);

  const onChangeDept = (evnt, value) => {
    setData({ ...data, department: value });
  };

  const onChangeUserList = (e, newValue) => {
    setValue(newValue);
    const newIds = newValue.map((option) => option.id);
    setData({ ...data, except: newIds });
  };
  const onChangeDate = (date) => {
    setData({ ...data, date: format(date, "PP") });
  };

  const onChangeType = (evnt, value) => {
    setData({ ...data, type: value });
  };

  const onChangeCode = (evnt, value) => {
    setData({ ...data, code: value });
  };

  const onChangeActivity = (evnt, value) => {
    setData({ ...data, activity: value });
  };

  const handleDetails = (event, editor) => {
    const details = editor.getData();
    setData({ ...data, details: details });
  };
  const handleSubmit = async () => {
    const payload = {
      ...data,
      type: data.type.id,
      code: data.code.id,
      activity: data.activity.id,
    };
    setLoading(true);
    await AddTimesheet(payload);
    setLoading(false);
  };
  return (
    <Grid container sx={{ padding: "15px 40px 15px 40px" }}>
      <Grid
        item
        mb={3}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          cursor: "context-menu",
        }}
      >
        <Tooltip
          title={"Add company timesheet entry for all"}
          placement={"right"}
          arrow
        >
          <Typography variant="h4">Timesheet entry for all</Typography>
        </Tooltip>
      </Grid>
      <Grid container>
        <Paper style={{ width: "100%", padding: "25px" }}>
          <Grid
            container
            sx={{
              alignItems: "center",
              alignContent: "center",
            }}
            spacing={{ xs: 1, md: 2 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item md={2}>
              <DatePickerComponent
                dateValue={data.date}
                label="Date Entry"
                handleDatePicker={onChangeDate}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4}>
              <AutoCompleteDescription
                data={{ id: "id", name: "name", desc: "description" }}
                label={"Department"}
                optionData={SortAZ(filterActiveDept(dept), "name")}
                field={"Department"}
                onChange={onChangeDept}
                loading={loadingDept}
                multiple={false}
                value={data.department}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <AutoCompleteDescription
                data={{ id: "id", name: "full_name", desc: "description" }}
                label={"Except Employee"}
                optionData={SortAZ(userList, "full_name")}
                field={"Except Employee"}
                onChange={onChangeUserList}
                loading={loadingUserList}
                multiple={true}
                value={value}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <AutoCompleteDescription
                data={{ id: "id", name: "name", desc: "description" }}
                label={"Type"}
                optionData={types}
                loading={loadingTypes}
                onChange={onChangeType}
                value={data.type}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <AutoCompleteDescription
                data={{ id: "id", name: "name", desc: "description" }}
                label={"Code"}
                optionData={codes}
                loading={loadingCode}
                onChange={onChangeCode}
                value={data.code}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <AutoCompleteDescription
                data={{ id: "id", name: "name", desc: "description" }}
                label={"Activity"}
                optionData={activity}
                loading={loadingActiviity}
                onChange={onChangeActivity}
                value={data.activity}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Slider
                value={data.hours}
                valueLabelDisplay="on"
                onChange={(activeThumb) => {
                  var a = activeThumb.target.value;
                  setData({ ...data, hours: parseFloat(a) });
                }}
                step={0.25}
                marks
                min={0.25}
                max={8}
                color="FlignoGreen"
                className="time-slider"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                size="small"
                fullWidth
                label="Milestone"
                required={true}
                onChange={(e) => setData({ ...data, sprint: e.target.value })}
                value={data.sprint}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <CkEditor onChange={handleDetails} myDetails={data.details} />
            </Grid>
            <Grid container sx={{ justifyContent: "end" }} mt={1.5}>
              <Button
                variant="contained"
                disabled={
                  loading ||
                  data.department === null ||
                  data.activity === null ||
                  data.code === null ||
                  data.type === null ||
                  data.hours === 0 ||
                  data.sprint === null ||
                  data.details === null
                }
                endIcon={loading ? <CircularProgress size={20} /> : <></>}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};

export default TimesheetForAll;
