import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { InputLabel } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function DropDownMultiple({
  DropDownData,
  minWidth,
  onChange,
  value,
  label,
  variant,
  size,
}) {
  const theme = useTheme();

  return (
    <div>
      <FormControl
        sx={{ m: 1, minWidth: minWidth }}
        size={size}
        variant={variant}
      >
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          size={size}
          label={label}
          variant={variant}
          value={value}
          onChange={onChange}
          // input={
          //   variant === "outlined" ? <OutlinedInput /> : <Input fullWidth />
          // }
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  size="small"
                  style={{
                    fontSize: "0.65rem",
                    color: "white",
                    backgroundColor: value.color ? value.color : "black",
                  }}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {DropDownData.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, value, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
