import apiService from "../axios";
export const getNotification = async () => {
  try {
    const { data } = await apiService.get(
      "v1/notification/get-all-notifications",
      { Accept: "application/json" }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const readNotification = async (payload) => {
  try {
    const { data } = await apiService.post(
      "v1/notification/mark-seen-notification",
      payload,
      {
        Accept: "application/json",
        body: JSON.stringify(payload),
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const readAllNotification = async () => {
  try {
    const { data } = await apiService.get(
      "v1/notification/mark-seen-all-notifications",
      { Accept: "application/json" }
    );
    return data;
  } catch (error) {
    throw error;
  }
};
