import { Typography } from "@mui/material";
import React from "react";
import { deptColor } from "./DeptColor";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import api from "../../api/Department/index";
import { useQuery } from "react-query";
const DeptFilter = ({
  setFilters,
  setInOutFilter,
  tabValue,
  handleTabChange,
}) => {
  const { status: StatusGetDept, data: DataGetDept } = useQuery(
    ["get-dept"],
    () => api.GetDepartments()
  );
  return (
    <Tabs
      value={tabValue}
      onChange={handleTabChange}
      variant="scrollable"
      scrollButtons="auto"
      allowScrollButtonsMobile
      sx={{
        width: "100%",
        "div.MuiTabs-scrollButtons": {
          height: "30px",
          width: "17px",
        },
        "span.MuiTabs-indicator": {
          bgcolor: deptColor(tabValue),
        },
        "div.MuiTabs-flexContainer": {
          justifyContent: "space-between",
        },
        "div.MuiTabs-scroller": {
          height: "40px",
        },
      }}
    >
      <Tab
        value={"ALL"}
        label={
          <Typography
            className="ovrLine"
            variant="overline"
            sx={{
              color: "#fff",
              fontSize: "0.6rem",
              lineHeight: "1.8",
              p: "2px",
            }}
          >
            ALL
          </Typography>
        }
        sx={{
          margin: "0px 3px 0px 3px",
          bgcolor: "#22A9D8",
          minWidth: "50px",
          "&.Mui-selected": {
            "span.ovrLine": {
              color: "rgba(0, 0, 0, 0.87)",
            },
          },
          borderRadius: "5px",
          minHeight: "30px",
          height: "10px",
        }}
        onClick={() => {
          setInOutFilter("all");
          setFilters("ALL");
        }}
      />
      {StatusGetDept &&
        DataGetDept &&
        DataGetDept.data.map(
          (dept, index) =>
            dept.status === 1 && (
              <Tab
                key={index}
                value={dept}
                label={
                  <Typography
                    className="ovrLine"
                    variant="overline"
                    sx={{
                      color: "#fff",
                      fontSize: "0.6rem",
                      lineHeight: "1.8",
                      p: "2px",
                    }}
                  >
                    {dept.name}
                  </Typography>
                }
                sx={{
                  margin: "0px 3px 0px 3px",
                  bgcolor: dept.color ? dept.color : "#22A9D8",
                  minWidth: "50px",
                  "&.Mui-selected": {
                    "span.ovrLine": {
                      color: "rgba(0, 0, 0, 0.87)",
                    },
                  },
                  borderRadius: "5px",
                  minHeight: "30px",
                  height: "10px",
                }}
                onClick={() => {
                  setInOutFilter("all");
                  setFilters(dept.name);
                }}
              />
            )
        )}
    </Tabs>
  );
};

export default DeptFilter;
