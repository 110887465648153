import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Paper,
  Skeleton,
  Tooltip,
} from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useGetLeaveCalendar } from "../../../hooks/react-query/LeaveCalendar";
import RefreshIcon from "@mui/icons-material/Refresh";
import { leaveObjectData } from "../../../helper/LeaveManagement/LeaveCalendar";
import LeaveCalendarDialog from "./LeaveCalendarDialog";
import { format } from "date-fns";
import { Box } from "@mui/system";

const initVal = {
  open: false,
  title: "",
  start: "",
  end: "",
  purpose: "",
  type: "",
  filed: "",
};
const LeaveCalendar = () => {
  const { data, refetch, isFetching } = useGetLeaveCalendar();
  const [dialogData, setDialogData] = useState(initVal);

  const handleEventClick = (args) => {
    const {
      calendar_type,
      holiday,
      employee,
      from,
      to,
      purpose,
      leave_type,
      date_filed,
      date_approved,
    } = args.event.extendedProps.modal_content;
    let obj = {
      open: true,
      calendar_type: calendar_type,
      holiday: holiday,
      title: employee,
      start: format(from, "PP"),
      end: format(to, "PP"),
      purpose: purpose,
      type: leave_type,
      filed: date_filed && format(date_filed, "PP"),
      approved: date_approved,
    };

    setDialogData(obj);
  };

  const closeDialog = () => {
    setDialogData(initVal);
  };
  return (
    <Grid container sx={{ padding: "15px" }}>
      <Grid container mb={3} sx={{ justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            cursor: "context-menu",
          }}
        >
          <Tooltip
            title={"View company leave calendar"}
            placement={"right"}
            arrow
          >
            <Typography variant="h4">Leave Calendar</Typography>
          </Tooltip>
        </Box>
        <Button
          startIcon={<RefreshIcon />}
          variant="text"
          sx={{ padding: "5px", textTransform: "none", color: "#00C292" }}
          onClick={refetch}
        >
          <Typography sx={{ fontSize: "0.78rem" }}>Refresh</Typography>
        </Button>
        <Paper style={{ width: "100%", padding: "20px", marginTop: "20px" }}>
          {!isFetching ? (
            <FullCalendar
              // eventClick={handleEventClick}
              defaultView="dayGridMonth"
              plugins={[dayGridPlugin, interactionPlugin]}
              events={leaveObjectData(
                data?.data.leave,
                data?.data.holiday,
                data?.data.birthdays
              )}
              eventClick={handleEventClick}
            />
          ) : (
            <Skeleton height={100} />
          )}
        </Paper>
      </Grid>
      <LeaveCalendarDialog closeDialog={closeDialog} dialogData={dialogData} />
    </Grid>
  );
};

export default LeaveCalendar;
