export function paddedString(string, mul, width) {
  const numSpaces = (width - string?.length * mul) / 2;
  const paddedString = numSpaces;
  return paddedString;
}
export const determineSeason = (() => {
  const northernHemisphereCountries = [
    "US",
    "CA",
    "MX",
    "GB",
    "FR",
    "DE",
    "RU",
    "CN",
    "JP",
    "KR",
    "TW",
  ];
  const southernHemisphereCountries = ["AU", "BR", "AR", "CL", "ZA", "NZ"];
  const tropicalCountries = [
    "PH",
    "TH",
    "MY",
    "ID",
    "SG",
    "VN",
    "IN",
    "BD",
    "LK",
    "MM",
  ];

  const specialMonths = {
    1: ["Happy New Year"],
    2: ["Month of Love"],
    3: ["Employee Appreciation Month", "International Women's Month"],
    4: ["It's Summer Time", "Earth Month"],
    5: ["Mental Health Awareness Month"],
    6: ["Pride Month"],
    7: ["Nutrition Month", "International Friendship Month"],
    8: ["Language Month"],
    9: ["International Month of Peace"],
    10: ["Oktober Fest", "Halloween"],
    11: ["National-Stress Awareness Month"],
    12: ["Christmas Season"],
  };

  const memoizedSeason = (date, countryCode) => {
    const countryIsInNorthernHemisphere =
      northernHemisphereCountries.includes(countryCode);
    const countryIsInSouthernHemisphere =
      southernHemisphereCountries.includes(countryCode);
    const countryIsTropical = tropicalCountries.includes(countryCode);

    const month = date.getMonth() + 1;

    if (countryIsInNorthernHemisphere) {
      switch (month) {
        case 3:
        case 4:
        case 5:
          return "spring";
        case 6:
        case 7:
        case 8:
          return "summer";
        case 9:
        case 10:
        case 11:
          return "fall";
        case 12:
        case 1:
        case 2:
          return "winter";
        default:
          // Invalid date
          return "";
      }
    } else if (countryIsInSouthernHemisphere) {
      switch (month) {
        case 3:
        case 4:
        case 5:
          return "autumn";
        case 6:
        case 7:
        case 8:
          return "winter";
        case 9:
        case 10:
        case 11:
          return "spring";
        case 12:
        case 1:
        case 2:
          return "summer";
        default:
          // Invalid date
          return "";
      }
    } else if (countryIsTropical) {
      return getSpecialMonthMessage(month) || "tropical";
    } else {
      // Country not found
      return "";
    }
  };
  function getRandomItemFromArray(array) {
    return array[Math.floor(Math.random() * array.length)];
  }

  function getSpecialMonthMessage(month) {
    const selectedMonth = specialMonths[month];
    if (selectedMonth) {
      return getRandomItemFromArray(selectedMonth);
    } else {
      return "";
    }
  }
  const cache = {};
  return (date, countryCode) => {
    const key = `${date}-${countryCode}`;
    if (cache[key]) {
      return cache[key];
    } else {
      const result = memoizedSeason(date, countryCode);
      cache[key] = result;
      return result;
    }
  };
})();

export const generateHolidayNameArray = (countryCode, date, holidayData) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();

  // Check if the current date matches any of the static holidays
  const staticHolidays = [
    { name: "Happy Mother's Day", month: 5, day: 14 }, // need to be 3 objects
    { name: "Happy Father's Day", month: 6, day: 18 },
    { name: "National Trivia Day", month: 1, day: 4 },
    { name: "Thank-Your Mentor Day ", month: 3, day: 2 },
    { name: "National Trivia Day", month: 1, day: 2 },
    { name: "Fun At Work Day", month: 1, day: 28 },
    { name: "Random-Act-of Kindness Day", month: 2, day: 17 },
    { name: "World Engineers Day", month: 3, day: 4 },
    { name: "Employee Appreciation Day", month: 3, day: 6 },
    { name: "International Women Day", month: 3, day: 8 },
    { name: "International Happiness Day", month: 3, day: 28 },
    { name: "World Health Day", month: 4, day: 7 },
    { name: "Administrative Professionals Day", month: 4, day: 24 },
    { name: "World Environment Day", month: 6, day: 25 },
    { name: "Working Parents Day", month: 9, day: 16 },
    { name: "National Coffee Day", month: 10, day: 11 },
    { name: "National Boss Day", month: 10, day: 16 },
  ];
  const matchingHoliday = staticHolidays.find((holiday) => {
    return holiday.month === month && holiday.day === day;
  });

  if (matchingHoliday) {
    return matchingHoliday.name.split(" ");
  } else {
    if (Array.isArray(holidayData) && holidayData.length === 0) {
      const season = determineSeason(date, countryCode);
      const seasonWords = season.split(" ");
      switch (seasonWords.length) {
        case 1:
          return ["Happy", seasonWords[0].toUpperCase(), "Y'all"];
        case 2:
          return ["", seasonWords[0], seasonWords[1]];

        default:
          return seasonWords;
      }
    } else {
      const holidayName = holidayData?.[0]?.name;
      if (holidayName) {
        const words = holidayName.split(" ");
        if (words.length === 1) {
          return ["", words[0], "Time"];
        } else if (words.length === 2) {
          return ["", words[0], words[1]];
        } else if (words.length >= 4 && words.length <= 5) {
          return [words[0] + " " + words[1], ...words.slice(2)];
        } else {
          return words.slice(0, 3);
        }
      } else {
        // No holiday data available
        return [];
      }
    }
  }
};
export const ascOrderByDate = (arr) => {
  const sorted = arr.sort(
    (a, b) => new Date(a.date).getDate() - new Date(b.date).getDate()
  );
  return sorted;
};
