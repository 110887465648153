import { TableHead, TableRow, TableSortLabel } from "@mui/material";
import StyledTableCell from "./StyledTableCell";

import {
  ORDER,
  HEADER_CELL_ID,
} from "../../../../lib/UserAppMgmt/UserApplications";
import { filterHeaderByType } from "../../../../helper/UserApplicationMgmt/UserApplications";

const TableHeader = ({ order, orderBy, onChangeOrder, type }) => {
  const changeOrderHandler = (id, isSortable) => {
    if (!isSortable) return;
    onChangeOrder(id);
  };

  const filteredHeaderCells = filterHeaderByType(type);
  return (
    <TableHead>
      <TableRow>
        {filteredHeaderCells.map((headerCellEl) => {
          const { id, isSortable, label } = headerCellEl;

          const centerAligned = [HEADER_CELL_ID.ACTION];

          const align = centerAligned.includes(id) ? "center" : "left";

          return (
            <StyledTableCell
              key={id}
              sortDirection={orderBy === id ? order : false}
              align={align}
            >
              <TableSortLabel
                sx={{
                  width: "max-content",
                }}
                active={orderBy === id}
                direction={orderBy === id ? order : ORDER.ASCENDING}
                hideSortIcon
                onClick={changeOrderHandler.bind(null, id, isSortable)}
              >
                {type === "document" && label === "Purpose"
                  ? "Document"
                  : type === "document" && label === "Reason"
                  ? "Description"
                  : label}
              </TableSortLabel>
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
