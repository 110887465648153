import { deleteDocument } from "../../../api/announcement";
import { Modal, Box, Button, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const DeleteDocument = ({ id, setMOTD, open, handleClose, setUpdate }) => {
  const queryClient = new useQueryClient();
  const useDeleteDocument = useMutation(
    (formData) => deleteDocument(formData),
    {
      onSuccess: async (response) => {
        setUpdate(response.data);
        handleClose(false);
        toast.success("Document succesfully deleted.");
        queryClient.refetchQueries(["get-message-of-the-day"]);
      },
      onError: (response) => {},
    }
  );

  const deleteHandler = (id) => {
    const payload = {
      id: id,
    };
    useDeleteDocument.mutate(payload);
  };

  return (
    <div>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="keep-mounted-modal-title"
            align={"center"}
            variant="h6"
            component="h2"
          >
            Delete Document
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to delete this Document?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {<LinearProgress />}
            <Button
              onClick={(e) => deleteHandler(id)}
              variant="contained"
              color="success"
              sx={{ margin: "10px 10px 0" }}
            >
              CONFIRM
            </Button>
            <Button
              onClick={(e) => handleClose(false)}
              variant="outlined"
              color="error"
              sx={{ margin: "10px 10px 0" }}
            >
              CANCEL
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default DeleteDocument;
