import React from "react";
import { TableRow, TableCell } from "@mui/material";

const TimekeepingList = ({ item }) => {
  return (
    <>
      <TableRow sx={{ height: "4em" }}>
        <TableCell></TableCell>
        <TableCell>
          <div
            style={{
              fontSize: "0.7rem",
              maxWidth: 200,
              wordWrap: "break-word",
            }}
            dangerouslySetInnerHTML={{
              __html: item.name,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{
              fontSize: "0.7rem",
              display: "flex",
              justifyContent: "center",
            }}
            dangerouslySetInnerHTML={{
              __html: item?.avgTimeIn,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{
              fontSize: "0.7rem",
              display: "flex",
              justifyContent: "center",
            }}
            dangerouslySetInnerHTML={{
              __html: item.avgTimeOut,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{
              fontSize: "0.7rem",
              display: "flex",
              justifyContent: "center",
            }}
            dangerouslySetInnerHTML={{
              __html: item.countLates,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{
              fontSize: "0.7rem",
              display: "flex",
              justifyContent: "center",
            }}
            dangerouslySetInnerHTML={{
              __html:
                item?.sumLates === null ? "00:00" : item?.sumLates.slice(0, 5),
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{
              fontSize: "0.7rem",
              display: "flex",
              justifyContent: "center",
            }}
            dangerouslySetInnerHTML={{
              __html:
                item?.avgLates === null ? "00:00" : item?.avgLates.slice(0, 5),
            }}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default TimekeepingList;
