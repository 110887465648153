import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const BOX_STYLE = {
  padding: "28px 34px",
  backgroundColor: "#FFF",
  boxShadow: "0 0 20px rgba(0,0,0,0.09)",
};

const TITLE_STYLE = {
  fontWeight: "600",
  marginBottom: "16px",
};

const LABEL_STYLE = {
  textTransform: "uppercase",
  color: "#adb5bd",
  fontWeight: 600,
  fontSize: "14px",
  letterSpacing: ".6px",
  wordSpacing: "2px",
};

const UserInformationViewCard = ({ payload }) => {
  const { title, profile } = payload;

  return (
    <Box sx={BOX_STYLE}>
      <Typography variant="h6" sx={TITLE_STYLE}>
        {title}
      </Typography>

      {profile.map((element) => (
        <Grid
          container
          key={element.label}
          columnSpacing="28px"
          sx={{
            marginBottom: "12px",
          }}
        >
          <Grid item xs={5} md={4} lg={3}>
            <Typography sx={LABEL_STYLE}>{element.label}</Typography>
          </Grid>

          <Grid item xs={7} md={8} lg={9}>
            <Typography sx={{ wordBreak: "break-word" }}>
              {element.value || "-"}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

export default UserInformationViewCard;
