import NestedTableContainer from "../../container/NestedTableContainer";
import PerUserTableHeader from "./PerUserTableHeader";
import PerUserTableRow from "./PerUserTableRow";
import PerActivityTablePagination from "../PerActivityTablePagination";
import GenericTableLoader from "../../loader/GenericTableLoader";
import NestedTableError from "../../error/NestedTableError";
import NoResults from "../NoResults";
import { useNestedActivityReport } from "../../../../../hooks/react-query/PerActivity";
import {
  PER_ACTIVITY_QUERY_KEYS,
  PER_USER_HEADER_CELL_ID,
} from "../../../../../lib/reports/perActivity";

const PerUserTable = ({ activityId, state }) => {
  const { fromDate, toDate, isWeekendShown, isInactiveUsersShown } = state;

  const {
    query: { data, isLoading, isError },
    state: userActivityTableState,
    handler,
  } = useNestedActivityReport(
    PER_ACTIVITY_QUERY_KEYS.getActivityReportPerUser,
    { activityId },
    fromDate,
    toDate,
    isWeekendShown,
    isInactiveUsersShown,
    PER_USER_HEADER_CELL_ID.name
  );

  if (isError) return <NestedTableError />;

  return (
    <NestedTableContainer
      state={userActivityTableState}
      handler={handler}
      TableHeader={PerUserTableHeader}
      Pagination={
        <PerActivityTablePagination
          count={data?.total || 0}
          page={data?.current_page ? data.current_page - 1 : 0}
          pageSize={userActivityTableState.pageSize}
          onPageChange={handler.handleChangePage}
          onRowsPerPageChange={handler.handleChangePageSize}
        />
      }
    >
      {!isLoading ? (
        data.data.map((user) => (
          <PerUserTableRow
            activityId={activityId}
            key={user.row_id}
            user={user}
            state={state}
          />
        ))
      ) : (
        <GenericTableLoader />
      )}

      {!isLoading && !data?.data.length && <NoResults />}
    </NestedTableContainer>
  );
};

export default PerUserTable;
