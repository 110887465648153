import { useState, useContext, useEffect } from "react";

import { Card, TextField, InputAdornment } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useDebounce from "../../hooks/customHooks/debounce";
import SearchIcon from "@mui/icons-material/Search";
import { mngFeedbackCntxt } from ".";

const ManageFeedbackFilter = () => {
  const { search, setSearch } = useContext(mngFeedbackCntxt);
  const [searchString, setSearchString] = useState(search);
  const debounceValue = useDebounce(searchString, 700);

  useEffect(() => {
    setSearch(debounceValue);
  }, [debounceValue, setSearch]);

  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "right",
        borderRadius: "10px",
        padding: "14px",
        alignItems: "center",
        margin: "15px",
        boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
        position: "sticky",
        top: "0",
        zIndex: "1",
        "@media(max-width: 768px)": {
          position: "relative",
        },
      }}
    >
      <div>
        <TextField
          value={searchString}
          onChange={(e) => {
            setSearchString(e.target.value);
          }}
          sx={{ margin: "5px" }}
          size="small"
          align="right"
          placeholder="Search"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CloseIcon
                  style={{ color: "#000000", cursor: "pointer" }}
                  onClick={() => setSearchString("")}
                />
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
    </Card>
  );
};

export default ManageFeedbackFilter;
