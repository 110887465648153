import apiService from "../axios";
import moment from "moment";
import { toast } from "react-toastify";

export const peruser = async (data) => {
  try {
    let response = await apiService.post(
      `v1/timesheet/get-per-staff-report`,
      data,
      {
        Accept: `application/json`,
        "Content-Type": `multipart/form-data`,
        body: JSON.stringify(data),
      }
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const generateDownloadableActivityReport = async (payload) => {
  try {
    const response = await apiService.post(
      `v5/timesheet/export/generate-excel-staff-report`,
      payload,
      {
        "Content-Type": "application/json",
        body: JSON.stringify(payload),
        responseType: "blob",
      }
    );
    const getBlobText = await response.data.text();
    try {
      const moreThan2Months = JSON.parse(getBlobText).message;
      return moreThan2Months;
    } catch (error) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      const fileName =
        moment(payload.fromDate).format("ll") +
        " - " +
        moment(payload.toDate).format("ll") +
        " Breakdown Per Code";
      const ext = payload.type === "excel" ? ".xlsx" : ".csv";
      link.href = url;
      link.setAttribute("download", fileName + ext);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  } catch (error) {
    toast.error("There was a problem exporting the file.");
    throw error;
  }
};

/* v5 */
export const getReportPerUser = async ({
  page_size = 10,
  fromDate,
  toDate,
  weekends = true,
  inActives = true,
  search,
  page = 1,
}) => {
  try {
    let response = await apiService.get(
      `/v5/timesheet/get-per-staff-report?page_size=${page_size}&fromDate=${fromDate}&toDate=${toDate}&weekends=${weekends}&inActives=${inActives}&search=${search}&page=${page}`,
      {
        Accept: `application/json`,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getReportPerUserProject = async ({
  id = "",
  page_size = 10,
  fromDate,
  toDate,
  page = 1,
  weekends = true,
  inActives = true,
}) => {
  try {
    let response = await apiService.get(
      `v5/timesheet/get-per-staff-report/project/${id}?page_size=${page_size}&fromDate=${fromDate}&toDate=${toDate}&page=${page}&weekends=${weekends}&inActives=${inActives}`,
      {
        Accept: `application/json`,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getReportPerUserCode = async ({
  id = "",
  id2 = "",
  page_size = 10,
  fromDate,
  toDate,
  page = 1,
  weekends = true,
  inActives = true,
}) => {
  try {
    let response = await apiService.get(
      `v5/timesheet/get-per-staff-report/code/${id}/${id2}?page_size=${page_size}&fromDate=${fromDate}&toDate=${toDate}&page=${page}&weekends=${weekends}&inActives=${inActives}`,
      {
        Accept: `application/json`,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getReportPerUserActivity = async ({
  id = "",
  id2 = "",
  id3 = "",
  page_size = 10,
  fromDate,
  toDate,
  page = 1,
  weekends = true,
  inActives = true,
}) => {
  try {
    let response = await apiService.get(
      `v5/timesheet/get-per-staff-report/activity/${id}/${id2}/${id3}?page_size=${page_size}&fromDate=${fromDate}&toDate=${toDate}&page=${page}&weekends=${weekends}&inActives=${inActives}`,
      {
        Accept: `application/json`,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getReportPerUserTimesheet = async ({
  id = "",
  id2 = "",
  id3 = "",
  id4 = "",
  page_size = 10,
  fromDate,
  toDate,
  page = 1,
  weekends = true,
  inActives = true,
}) => {
  try {
    let response = await apiService.get(
      `v5/timesheet/get-per-staff-report/timesheet/${id}/${id2}/${id3}/${id4}?page_size=${page_size}&fromDate=${fromDate}&toDate=${toDate}&page=${page}&weekends=${weekends}&inActives=${inActives}`,
      {
        Accept: `application/json`,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
