import React from "react";
import {
  Typography,
  TableRow,
  TableCell,
  Skeleton,
  TableBody,
} from "@mui/material";
const SkeletonLoader = ({ colSpan }) => {
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={colSpan}>
          <Typography variant="h4">
            {[0, 1, 2, 3, 4, 5, 6].map((key) => (
              <Skeleton key={key} />
            ))}
          </Typography>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default SkeletonLoader;
