import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import useDebounce from "../../hooks/customHooks/debounce";
import { Box, TextField, InputAdornment, IconButton } from "@mui/material";
const SearchComponent = ({ setKeyword, setOffset, isFetching }) => {
  const [search, setSearch] = useState("");
  const debounceValue = useDebounce(search, 700);

  useEffect(() => {
    setKeyword(search);
    setOffset(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "end",
          pr: 2,
        }}
      >
        <SearchOutlinedIcon sx={{ color: "#00C292", mr: 1, mt: 1, mb: 0.5 }} />

        <TextField
          onChange={(e) => {
            if (isFetching) return;
            setSearch(e.target.value);
          }}
          size="small"
          id="input-with-sx"
          label="Search"
          variant="standard"
          value={search}
          sx={{ mr: 1, mb: 0.6, mt: 1.1 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  sx={{ p: 0.5 }}
                  aria-label="toggle password visibility"
                  onClick={(e) => setSearch("")}
                >
                  <CloseIcon sx={{ fontSize: 15 }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </>
  );
};

export default SearchComponent;
