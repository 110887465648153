import React from "react";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import InputLabel from "@mui/material/InputLabel";
import { useQueryClient } from "react-query";
import hooks from "../../../../hooks/react-query/queryHooks";
const surveysArr = [
  {
    value: 2,
    label: "Customer Feedback",
  },
  {
    value: 3,
    label: "Customer Satisfaction",
  },
  {
    value: 1,
    label: "One Single Random Question",
  },
  {
    value: 4,
    label: "Quiz",
  },
];
const PulseSettings = ({ open, handlePulseSettings, data, surveySett }) => {
  const { mutate: enableSetting } = hooks.useEnableSurveySetting();
  const { mutate: setSetting } = hooks.useSetSurveySetting();

  // const SingleQuestions = data.filter((dt) => {
  //   if (dt.questions.length === 1) {
  //     console.log(dt)
  //     return dt;
  //   }
  // });

  const queryClient = useQueryClient();
  const [sett, setSett] = React.useState(surveySett.data.survey.id);

  const handleChange = (event) => {
    setSett(event);
    setSetting(
      { survey_id: event },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["pulse-survey"]);
          queryClient.invalidateQueries(["survey-settings"]);
        },
      }
    );
  };

  const handleEnableSetting = (e) => {
    enableSetting(
      { enable: e },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["pulse-survey"]);
          queryClient.invalidateQueries(["survey-settings"]);
        },
      }
    );
  };
  return (
    <Modal open={open}>
      <Card
        className="login-modal leave1-modal"
        sx={{
          minWidth: "550px",
          maxHeight: "calc(100vh - 75px)",
          overflow: "auto",
        }}
      >
        <CardHeader
          title={
            <Typography variant="h3" className="h3-bar">
              Survey Settings
            </Typography>
          }
          action={
            <IconButton onClick={handlePulseSettings}>
              <CloseOutlinedIcon sx={{ width: "15px", height: "15px" }} />
            </IconButton>
          }
        />
        <CardContent>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item lg={9} xs={9}>
              <Typography>
                Enable Psych Survey After Time-in Show a psych survey for each
                employees after they time-in.
              </Typography>
            </Grid>
            <Grid item lg={3} xs={3}>
              <Box
                sx={{
                  border: " 1px #C4C4C4 solid",
                  borderRadius: " 5px",
                  padding: "5px",
                  mt: "5px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {surveySett && (
                  <Switch
                    defaultChecked={surveySett.data.enabled}
                    size="small"
                    onChange={(e) => {
                      handleEnableSetting(e.target.checked);
                    }}
                  />
                )}
              </Box>
            </Grid>
            <Grid item lg={12} xs={12}>
              <Divider sx={{ margin: "5px 0px 5px 0px" }} />
            </Grid>
            <Grid item lg={9} xs={9}>
              <Typography>
                Start-up Psych Survey A question, after an employee has timed
                in.
              </Typography>
            </Grid>
            <Grid item lg={3} xs={3}>
              <FormControl fullWidth>
                <InputLabel>Survey Type Answer</InputLabel>
                <Select
                  size="small"
                  value={sett}
                  label="Survey Type Answer"
                  onChange={(e) => {
                    handleChange(e.target.value);
                  }}
                >
                  {data?.map((it, i) => (
                    <MenuItem value={it.id} key={i}>
                      {it.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "right",
            mt: "5px",
            mb: "10px",
          }}
        >
          <Button
            variant="contained"
            disableElevation
            type="submit"
            onClick={handlePulseSettings}
          >
            Exit
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default PulseSettings;
