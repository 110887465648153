export const projectTypeHeader = [
  {
    label: "Name",
    key: "name",
    isSortable: true,
  },
  {
    label: "Description",
    key: "description",
    isSortable: true,
  },
  {
    label: "Status",
    key: "status",
    isSortable: true,
  },
  {
    label: "Actions",
    key: "actions",
    align: "center",
  },
];
