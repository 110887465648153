import React from "react";
import { Box, Autocomplete, TextField, Typography } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

const AutoCompleteMUI = ({
  data,
  Label,
  fullWidth,
  width,
  selectedValue,
  errorInput,
  value,
}) => {
  return (
    <Autocomplete
      disableClearable
      blurOnSelect
      size="small"
      fullWidth={fullWidth}
      value={value}
      options={data}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          label={Label}
          style={{ width: width }}
          error={errorInput}
          fullWidth={fullWidth}
        />
      )}
      renderOption={(props, option) => (
        <Box
          component="li"
          {...props}
          sx={{
            flexDirection: "column",
            alignItems: "flex-start !important",
          }}
        >
          <Typography variant="h6" sx={{ fontSize: "0.875rem" }}>
            {option.name}
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontSize: "0.775rem" }}
            color="text.secondary"
          >
            {" "}
            {option.description}
          </Typography>
        </Box>
      )}
      onChange={(event, value) => {
        selectedValue(value);
      }}
      popupIcon={<KeyboardArrowDownRoundedIcon />}
    />
  );
};

export default AutoCompleteMUI;
