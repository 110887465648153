import { memo } from "react";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import {
  usePendingEntries,
  usePrefetchPendingEntries,
} from "../../../hooks/react-query/SetttingsTimesheet/PendingTimesheetEntries";
import PendingTimesheetTitle from "../../../component/SettingsTimesheet/PendingTimesheetEntries/Title";
import PendingTimesheetHeader from "../../../component/SettingsTimesheet/PendingTimesheetEntries/Header";
import TableHeader from "../../../component/SettingsTimesheet/PendingTimesheetEntries/Table/TableHeader";
import PendingEntryTableRow from "../../../component/SettingsTimesheet/PendingTimesheetEntries/Table/PendingEntryTableRow";
import PendingEntryTableSkeletonLoader from "../../../component/SettingsTimesheet/PendingTimesheetEntries/Table/PendingEntryTableSkeletonLoader";
import StyledTableCell from "../../../component/UserApplicationMgmt/UserApplications/tableComponents/StyledTableCell";
import PendingTimesheetEntriesError from "../../../component/SettingsTimesheet/PendingTimesheetEntries/Error/";
import {
  ORDER,
  ROWS_PER_PAGE_OPTIONS,
} from "../../../lib/settingsTimesheet/PendingTimesheetEntries";
import { generateSkeletonLoader } from "../../../helper/UserApplicationMgmt/UserApplications";

const CONTAINER_STYLES = {
  margin: "14px 18px 0",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
};

const TABLE_WRAPPER_STYLES = {
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  backgroundColor: "#FFF",
  borderRadius: "12px",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.05)",
};

const TABLE_STYLES = {
  tableLayout: "auto",
  minWidth: "max-content",
  width: "100%",
};

const TABLE_PAGINATION_STYLES = {
  display: "flex",
  justifyContent: "end",
  minHeight: "53px",
  borderTop: "1px solid #dee2e6",
  borderBottom: "none",
};

const PendingTimesheetEntries = () => {
  const {
    data,
    isLoading,
    isError,
    isSuccess,
    searchString,
    page,
    pageSize,
    order,
    orderBy,
    changeOrder,
    changeOrderBy,
    changePage,
    changePageSize,
    searchFilter,
  } = usePendingEntries();

  usePrefetchPendingEntries(data?.next_page_url, page, pageSize, searchString);

  const changeRowsPageHandler = (event) => {
    changePageSize(+event.target.value);
  };

  const changePageHandler = (_, page) => {
    changePage(page + 1);
  };

  const requestSortHandler = (newOrderBy) => {
    const isAsc = orderBy === newOrderBy && order === ORDER.ASCENDING;
    changeOrder(isAsc ? ORDER.DESCENDING : ORDER.ASCENDING);
    changeOrderBy(newOrderBy);
  };

  return (
    <Box sx={CONTAINER_STYLES}>
      {/* TITLE */}
      <PendingTimesheetTitle />

      {/* SEARCH FILTER AND EXPORT COMPONENT */}
      {!isError && (
        <PendingTimesheetHeader
          isLoading={isLoading}
          data={data?.data}
          onSearch={searchFilter}
        />
      )}

      {/* ERROR COMPONENT */}
      {isError && <PendingTimesheetEntriesError />}

      {/* TABLE */}
      {(isLoading || isSuccess) && !isError && (
        <Box sx={TABLE_WRAPPER_STYLES}>
          <TableContainer>
            <Table stickyHeader sx={TABLE_STYLES}>
              {/* TABLE HEAD */}
              <TableHeader
                order={order}
                orderBy={orderBy}
                onChangeOrder={requestSortHandler}
              />

              {/* TABLE BODY */}
              <TableBody>
                {!isLoading
                  ? data.data.map((pendingEntry) => (
                      <PendingEntryTableRow
                        key={pendingEntry.id}
                        data={pendingEntry}
                      />
                    ))
                  : generateSkeletonLoader(
                      pageSize,
                      <PendingEntryTableSkeletonLoader />
                    )}

                {!isLoading && !data?.data.length ? (
                  <TableRow>
                    <StyledTableCell colSpan="100%">
                      No results found.
                    </StyledTableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>

          {/* PAGINATION */}
          <TablePagination
            component={Box}
            sx={TABLE_PAGINATION_STYLES}
            showFirstButton={true}
            showLastButton={true}
            count={data?.total || 0}
            page={data?.current_page ? data?.current_page - 1 : 0}
            rowsPerPage={pageSize}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onPageChange={changePageHandler}
            onRowsPerPageChange={changeRowsPageHandler}
          />
        </Box>
      )}
    </Box>
  );
};

export default memo(PendingTimesheetEntries);
