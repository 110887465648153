import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Tooltip } from "@mui/material";

const TITLE_STYLES = {
  fontSize: "clamp(20px, 5vw, 28px)",
  color: "#21293C",
};

const PerActivityTitle = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        cursor: "context-menu",
      }}
    >
      <Tooltip
        title="View company timesheet per activity report"
        placement={"right"}
        arrow
      >
        <Typography sx={TITLE_STYLES}>Timesheet Per Activity</Typography>
      </Tooltip>
    </Box>
  );
};

export default PerActivityTitle;
