import { useState } from "react";

import Tooltip from "@mui/material/Tooltip";

import StyledTableCell from "../../../UserApplicationMgmt/UserApplications/tableComponents/StyledTableCell";
import { removeHtmlElement } from "../../../../helper/SettingsTimesheet/PendingTimesheetEntries";

const LONG_TEXT_COUNT = 400;

const EllipsesButton = ({ onClick }) => {
  const dotStyle = {
    height: "2px",
    width: "2px",
    backgroundColor: "#2E3954",
    borderRadius: "50%",
    display: "inline-block",
  };

  const containerStyle = {
    display: "inline-flex",
    width: "fit-content",
    gap: "2px",
    border: "none",
    padding: "4px 6px",
    borderRadius: "100px",
    cursor: "pointer",
    backgroundColor: "#ced4da",
  };

  return (
    <Tooltip title="Show more">
      <button style={containerStyle} onClick={onClick}>
        <span style={dotStyle}></span>
        <span style={dotStyle}></span>
        <span style={dotStyle}></span>
      </button>
    </Tooltip>
  );
};

const ShowLessButton = ({ onClick }) => (
  <button
    onClick={onClick}
    style={{
      border: "none",
      display: "flex",
      cursor: "pointer",
      borderRadius: "100px",
      fontFamily: "inherit",
      fontWeight: "600",
      fontSize: "12px",
      marginTop: "8px",
      color: "#2E3954",
      backgroundColor: "#ced4da",
    }}
  >
    show less
  </button>
);

const LongTextTableCell = ({ children }) => {
  const [showMoreText, setShowMoreText] = useState(false);

  // * This removes html elements in string
  const processedText = removeHtmlElement(children);

  const textLength = processedText.length;
  const isTextLong = textLength > LONG_TEXT_COUNT;
  const text =
    isTextLong && !showMoreText
      ? processedText.slice(0, LONG_TEXT_COUNT)
      : processedText;

  const handleToggleShowMoreText = () => {
    setShowMoreText((prev) => !prev);
  };

  return (
    <StyledTableCell sx={{ minWidth: "256px", maxWidth: "750px" }}>
      {text}{" "}
      {isTextLong && !showMoreText && (
        <EllipsesButton onClick={handleToggleShowMoreText} />
      )}
      {showMoreText && <ShowLessButton onClick={handleToggleShowMoreText} />}
    </StyledTableCell>
  );
};

export default LongTextTableCell;
