import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import PendingActionsOutlinedIcon from "@mui/icons-material/PendingActions";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import BarChartOutlined from "@mui/icons-material/BarChartOutlined";
import { Settings, DateRange } from "@mui/icons-material";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import CardTravelOutlinedIcon from "@mui/icons-material/CardTravelOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import TuneIcon from "@mui/icons-material/Tune";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import FeedbackIcon from "@mui/icons-material/Feedback";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";

export const SidebarItems = ({
  handleWFH,
  handleLeave,
  handleOT,
  handleMyRequests,
  handleConversion,
  handleShift,
  handleTimeInOut,
  handleFeedback,
  handleDocument,
}) => {
  return [
    {
      title: "Home",
      roles: ["all"],
      linkto: "/dashboard",
      icon: <DashboardOutlinedIcon />,
    },
    {
      title: "Colleagues",
      roles: ["view colleagues"],
      linkto: "/colleagues",
      icon: <GroupsOutlinedIcon />,
    },
    {
      title: "Timesheet",
      roles: ["time entry"],
      linkto: "/timesheet",
      icon: <PendingActionsOutlinedIcon />,
    },
    {
      title: "Shift/Schedule",
      roles: ["manage schedules"],
      icon: <EventNoteOutlinedIcon />,
      children: [
        {
          title: "Holiday",
          roles: ["manage holiday"],
          linkto: "/settings/holiday",
        },
        {
          title: "Shift",
          roles: ["manage schedules"],
          linkto: "/settings/shift",
        },
        {
          title: "Schedule",
          roles: ["manage schedules"],
          linkto: "/settings/schedule",
        },
      ],
    },
    {
      title: "Requests",
      roles: ["file requests"],
      isAdmin: false,
      icon: <CalendarTodayOutlinedIcon />,
      children: [
        {
          title: "Apply WFH",
          roles: ["file requests"],
          action: handleWFH,
        },
        {
          title: "Apply Leave",
          roles: ["file requests"],
          action: handleLeave,
        },
        {
          title: "Forgot Time In/Out",
          roles: ["file requests"],
          action: handleTimeInOut,
        },
        {
          title: "Apply Overtime/Undertime",
          roles: ["file requests"],
          action: handleOT,
        },
        {
          title: "Apply Leave Conversion",
          roles: ["file requests"],
          action: handleConversion,
        },
        {
          title: "Request Document",
          roles: ["file requests"],
          action: handleDocument,
        },
        {
          title: "My Requests",
          roles: ["file requests"],
          linkto: "/my-requests",
          action: handleMyRequests,
        },
      ],
    },
    {
      title: "Audit Log",
      roles: ["access audit logs"],
      linkto: "/audit-logs",
      icon: <VerifiedUserIcon />,
    },

    {
      title: "Customer Visit Log",
      roles: ["view customer visits", "manage customer visits"],
      linkto: "/customer-visit-log",
      icon: <DescriptionOutlinedIcon />,
    },
    {
      title: "Policy and Documents",
      roles: ["view hrfolder"],
      linkto: "/hr-folder",
      icon: <FolderOutlinedIcon />,
    },
    {
      title: "Compliance",
      roles: ["access reports"],
      linkto: "/dashboard",
      icon: <AssignmentTurnedInIcon />,
      children: [
        {
          title: "Compliance Check",
          roles: ["manage compliance"],
          linkto: "/compliance/compliance-check",
        },
        {
          title: "Compliance Item",
          roles: ["manage compliance"],
          linkto: "/compliance/compliance-item",
        },
        {
          title: "Compliance Report",
          roles: ["manage compliance"],
          linkto: "/compliance/compliance-report",
        },
      ],
    },
    {
      title: "Reports",
      roles: ["access reports"],
      linkto: "/dashboard",
      icon: <BarChartOutlined />,
      children: [
        {
          title: "Dashboard",
          roles: ["access reports"],
          linkto: "/reports/dashboard",
        },
        {
          title: "Per Activity",
          roles: ["access reports"],
          linkto: "/reports/per-activity",
        },
        {
          title: "Per User",
          roles: ["access reports"],
          linkto: "/reports/per-user",
        },
        {
          title: "Per Project-User",
          roles: ["access reports"],
          linkto: "/reports/per-project-user",
        },
        {
          title: "Per Type",
          roles: ["access reports"],
          linkto: "/reports/per-type",
        },
        {
          title: "Per Client",
          roles: ["access reports"],
          linkto: "/reports/per-client-activity",
        },
        {
          title: "Per Project-Activity",
          roles: ["access reports"],
          linkto: "/reports/per-project-activity",
        },
        {
          title: "Project Code Summary",
          roles: ["access reports"],
          linkto: "/reports/project-code-summary",
        },
        {
          title: "Punctuality / Tardiness",
          roles: ["access reports"],
          linkto: "/reports/tardiness",
        },
        {
          title: "Timekeeping",
          roles: ["access reports"],
          linkto: "/reports/timekeeping",
        },
        {
          title: "WFH Summary",
          roles: ["access reports"],
          linkto: "/reports/wfh-summary",
        },
        {
          title: "Payroll Report",
          roles: ["access reports"],
          linkto: "/payroll/report",
        },
        {
          title: "Reacts Report",
          roles: ["access reports"],
          linkto: "/reports/reaction-reports",
        },
        {
          title: "Time in/out Report",
          roles: ["access reports"],
          linkto: "/reports/time-in-out-report",
        },
        {
          title: "Timesheet Analytics",
          roles: ["access reports"],
          linkto: "/reports/timesheet-analytics",
        },
      ],
    },
    {
      title: "System Settings ",
      roles: ["manage general settings"],
      icon: <Settings />,
      children: [
        {
          title: "Company Settings",
          roles: ["manage general settings"],
          linkto: "/settings/general",
        },
        {
          title: "Users List",
          roles: ["manage accounts"],
          linkto: "/settings/users",
        },
        {
          title: "Department",
          roles: ["manage departments"],
          linkto: "/settings/department",
        },
        {
          title: "Permission",
          roles: ["manage permissions"],
          linkto: "/settings/permission",
        },
        {
          title: "WFH Template",
          roles: ["manage general settings"],
          linkto: "/settings/wfh-template",
        },
        {
          title: "Announcement",
          roles: ["manage general settings"],
          linkto: "/settings/announcement",
        },
        {
          title: "Skills",
          roles: ["manage accounts"],
          linkto: "/settings/skills",
        },
      ],
    },
    {
      title: "Timesheet Settings",
      roles: ["manage timesheet codes", "manage clients", "manage leave types"],
      icon: <TuneIcon />,
      children: [
        {
          title: "Client Code",
          roles: ["manage clients"],
          linkto: "/settings-timesheet/client-code",
        },
        {
          title: "Project Code",
          roles: ["manage timesheet codes"],
          linkto: "/settings/timesheet-code",
        },
        {
          title: "Project Activity",
          roles: ["manage general settings"],
          linkto: "/settings/timesheet-activity",
        },
        {
          title: "Project Type",
          roles: ["manage timesheet codes"],
          linkto: "/settings/project-type",
        },
        {
          title: "Compliance",
          roles: ["manage timesheet codes"],
          linkto: "/settings/compliance",
        },
        {
          title: "Pending Entries",
          roles: ["access pending timesheet entries"],
          linkto: "/settings-timesheet/pending-timesheet-entries",
        },
        {
          title: "Timesheet for all",
          roles: ["manage timesheet codes"],
          linkto: "/settings-timesheet/timesheet-entry-all",
        },
        {
          title: "Employee to email timesheet report",
          roles: ["access reports"],
          linkto: "/settings-timesheet/employee-to-email-timesheet-report",
        },
      ],
    },
    {
      title: "Leave Management",
      roles: [
        "manage employee leaves",
        "manage employee leave credits",
        "manage leave types",
      ],
      icon: <DateRange />,
      children: [
        {
          title: "Leave Types",
          roles: ["manage leave types"],
          linkto: "/leave-management/leave-types",
        },
        {
          title: "Leave Credits",
          roles: ["manage employee leave credits"],
          linkto: "/leave-management/leave-credits",
        },
        {
          title: "Leave Calendar",
          roles: ["manage leave types"],
          linkto: "/leave-management/calendar",
        },
        {
          title: "Leave Conversion Logs",
          roles: ["manage leave types"],
          linkto: "/leave-management/leave-conversion-logs",
        },
        {
          title: "Leave Conversion Applications",
          roles: ["manage leave types"],
          linkto: "/leave-management/leave-conversion-applications",
        },
      ],
    },
    {
      title: "Approvals",
      roles: ["manage employee leave settings"],
      icon: <CardTravelOutlinedIcon />,
      children: [
        {
          title: "Applications",
          roles: ["manage employee leaves"],
          linkto: "/user-application-mgmt/user-applications?application=leave",
        },
        {
          title: "Summary",
          roles: ["manage employee leaves"],
          linkto: "/user-application-mgmt/user-application-summary",
        },
        {
          title: "User Application Settings",
          roles: ["manage employee leave settings"],
          linkto: "/user-application-mgmt/user-application-settings",
        },
        {
          title: "Leave and Absences",
          roles: ["manage employee leaves"],
          linkto: "/evaluation/leave-and-absences",
        },
      ],
    },
    {
      title: "Manage Feedback",
      roles: ["manage feedback"],
      linkto: "/manage-feedback",
      icon: <FeedbackIcon />,
    },
    {
      title: "Feedback",
      roles: ["view feedback"],
      isAdmin: false,
      isButton: "true",
      action: handleFeedback,
      icon: <FeedbackIcon />,
    },
    {
      title: "Evaluation",
      roles: ["manage employee leaves"],
      icon: <FactCheckIcon />,
      children: [
        {
          title: "Leave and Absences",
          roles: ["manage employee leaves"],
          linkto: "/evaluation/leave-and-absences",
        },
        {
          title: "Timesheet Weekly Report",
          roles: ["access reports"],
          linkto: "/evaluation/timesheet-weekly-report",
        },
        {
          title: "Punctuality/Tardiness Report",
          roles: ["access reports"],
          linkto: "/reports/tardiness",
        },
        {
          title: "Compliance Report",
          roles: ["manage compliance"],
          linkto: "/compliance/compliance-report",
        },
      ],
    },
    {
      title: "Pulse Survey",
      roles: ["manage surveys"],
      linkto: "/pulse-survey",
      icon: <SummarizeOutlinedIcon />,
    },
  ];
};
