import { useQuery, useMutation, useQueryClient } from "react-query";
import apiService from "../../../api/axios";
import { getFeedback } from "../../../api/manageFeedback";
import { toast } from "react-toastify";

const useGetFeedBack = (params) => {
  return useQuery(["get-feedback", params.criteria], () =>
    getFeedback(params.payload)
  );
};

const useUpdateStatus = () => {
  const queryClient = new useQueryClient();
  return useMutation(
    "get-feedback",
    async (data) => {
      try {
        let request = await apiService.post(`v5/admin/feedback/toggle-status`, {
          api: true,
          ...data,
        });
        return request.data;
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(["get-feedback"]);
        toast.success("Status successfully updated");
      },
    }
  );
};

const useAddEditFeedback = () => {
  const queryClient = new useQueryClient();
  return useMutation(
    "get-feedback",
    async (data, id) => {
      try {
        let request = await apiService.post(`/v1/admin/feedback/add/comment`, {
          api: true,
          ...data,
        });
        return request.data;
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(["get-feedback"]);
      },
    }
  );
};

const hooks = {
  useGetFeedBack,
  useUpdateStatus,
  useAddEditFeedback,
};

export default hooks;
