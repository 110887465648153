import apiService from "../axios";

import {
  modifyData,
  formatDateForQuery,
} from "../../helper/SettingsTimesheet/AllPendingTimesheetEntries";
import { ENDPOINT } from "../../lib/settingsTimesheet/allPendingTimesheetEntries";

export const getAllPendingTimesheetEntries = async (
  page,
  pageSize,
  search = "",
  status = 0,
  fromDate,
  toDate
) => {
  status = status ? 1 : 0;

  const from = formatDateForQuery(fromDate);
  const to = formatDateForQuery(toDate);

  const endpoint = `v5/timesheet/time-all?page=${page}&per_page=${pageSize}&search=${search}&status=${status}&fromDate=${from}&toDate=${to}`;

  try {
    const { data: responseData } = await apiService.get(endpoint);

    responseData.data = modifyData(responseData.data);

    return responseData;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const submitAllStatusPendingTimesheetEntryAction = async ({
  id,
  status,
  action,
  startDate,
}) => {
  const endpoint = ENDPOINT[action];
  const payload = {
    id,
    status: status ? 1 : 0,
    startdate: formatDateForQuery(startDate),
  };

  try {
    const response = await apiService.post(endpoint, payload);

    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
