import React from "react";
import Modal from "@mui/material/Modal";
import Grow from "@mui/material/Grow";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import Timechildloader from "./timechildloader";
const TimeinChild = ({
  childOpen,
  url,
  handleChild,
  setUrl,
  handleSubmitEntry,
  timeinoutloader,
  handleChildLoader,
  handleTimeInOut,
  navColleagues,
  navDashboard,
}) => {
  React.useEffect(() => {
    handleChildLoader(true);
    handleSubmitEntry();
  }, []);
  return (
    <Modal open={childOpen}>
      <Grow
        in={childOpen}
        style={{ transformOrigin: "0 0 0" }}
        {...(childOpen ? { timeout: 500 } : {})}
        unmountOnExit
      >
        <Card className="login-modal">
          <CardHeader
            sx={{ paddingTop: "5px" }}
            title={
              <Typography variant="h3" className="h3-bar">
                Captured image
              </Typography>
            }
          />
          <CardContent
            sx={{
              paddingTop: "0px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Avatar
              src={url}
              alt="time-in-photo"
              sx={{
                width: "300px",
                height: "300px",
              }}
            />
          </CardContent>
          <CardActions>
            <Grid container columnSpacing={2}>
              <Grid item xs={12}>
                <Button
                  size="small"
                  className="timest-button adjust-w"
                  startIcon={<PhotoCameraOutlinedIcon />}
                  disableElevation
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    handleChild();
                    setUrl(null);
                  }}
                >
                  Re-take
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Box className="cap-box">
                  <Typography
                    variant="h6"
                    component="span"
                    className="cap-span"
                  >
                    Or go to:
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Button
                  size="small"
                  className="timest-button adjust-w"
                  startIcon={<DashboardOutlinedIcon />}
                  disableElevation
                  variant="contained"
                  fullWidth
                  onClick={navDashboard}
                >
                  Dashboard
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  size="small"
                  className="timest-button adjust-w"
                  startIcon={<GroupsOutlinedIcon />}
                  disableElevation
                  variant="contained"
                  fullWidth
                  onClick={navColleagues}
                >
                  Colleagues
                </Button>
              </Grid>
            </Grid>
          </CardActions>
          <Timechildloader timeinoutloader={timeinoutloader} />
        </Card>
      </Grow>
    </Modal>
  );
};

export default TimeinChild;
