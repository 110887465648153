import { useState, createContext, useEffect, useMemo } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Paper,
  TableBody,
  TablePagination,
  Tooltip,
} from "@mui/material";
import MotdFilter from "./MotdFilter";
import { motdHead } from "../../../lib/motd/motdHead";
import { sortArrayTable } from "../../../helper/UserAppSettings";
import hooks from "../../../hooks/react-query/MOTD";

import MotdList from "./MotdList";
import Loader from "../../../component/Loader";
import AddNewMotd from "./AddNewMotd";
import EmptyData from "../../../component/Reports/EmptyData";
export const MotdCntxt = createContext({});

const MessageOfTheDay = () => {
  const [orderDirection, setOrderDirection] = useState("desc");
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [open, setOpen] = useState(false);
  const { data, isFetching, refetch } = hooks.useMOTD({
    criteria: { page, rowsPerPage, search },
    payload: {
      per_page: rowsPerPage,
      page: page,
      search: search,
    },
  });

  useEffect(() => {
    if (data) {
      setTableData(data?.data.data);
    }
  }, [data]);

  const handleSortRequest = (key) => {
    let direction = "asc";
    direction = orderDirection === "desc" ? "asc" : "desc";
    setOrderDirection(direction);
    setTableData((prev) => sortArrayTable(prev, direction, key));
  };

  const value = useMemo(
    () => ({
      search,
      setSearch,
      isFetching,
      refetch,
      open,
      setOpen,
    }),
    [search, setSearch, isFetching, refetch, open, setOpen]
  );

  const handleChangePage = (page) => {
    setPage(page + 1);
  };

  const handleChangeRowsPerPage = (e) => {
    setPage(1);
    setRowsPerPage(e.target.value);
  };

  const newData = tableData.filter((el) => {
    return el;
  });

  return (
    <div className="box">
      {open && <AddNewMotd open={open} setOpen={setOpen} />}
      <Loader isLoading={isFetching} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "0px 15px",
          cursor: "context-menu",
        }}
      >
        <Tooltip
          title={"Manage companys announcements"}
          placement={"right"}
          arrow
        >
          <Typography variant="h4" component="div" sx={{ padding: "1rem" }}>
            Announcement
          </Typography>
        </Tooltip>
      </Box>
      <MotdCntxt.Provider value={value}>
        <MotdFilter />
        {!newData?.length && !isFetching ? (
          <EmptyData />
        ) : (
          <TableContainer component={Paper} sx={{ padding: "1em" }}>
            <Table>
              <TableHead>
                <TableRow>
                  {motdHead.map((header, i) => {
                    return (
                      <TableCell
                        onClick={() =>
                          handleSortRequest(
                            header.key,
                            header?.parentKey ?? null
                          )
                        }
                        key={i}
                        align={header.align ? header.align : undefined}
                      >
                        <TableSortLabel
                          direction={orderDirection}
                          sx={{ fontWeight: "700", fontSize: "0.8rem" }}
                        >
                          {header.label}
                        </TableSortLabel>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {newData.map((item, index) => {
                  return <MotdList item={item} key={item.id} />;
                })}
              </TableBody>
            </Table>
            <TablePagination
              sx={{ display: "flex", justifyContent: "end" }}
              rowsPerPageOptions={[25, 50, 100]}
              count={data?.data.total || 0}
              page={data?.data.current_page ? data?.data.current_page - 1 : 0}
              onPageChange={(e, page) => {
                handleChangePage(page);
              }}
              showFirstButton={true}
              showLastButton={true}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
      </MotdCntxt.Provider>
    </div>
  );
};

export default MessageOfTheDay;
