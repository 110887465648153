import { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";

import ClearFieldButton from "../../../SettingsTimesheet/PendingTimesheetEntries/Header/ClearFieldButton";
import useDebounce from "../../../../hooks/customHooks/debounce";

const INPUT_FIELD_STYLES = {
  ".MuiInputBase-root": {
    padding: "0 2px 0 7px",
  },
};

const SearchField = ({ searchString, handleSearchString }) => {
  const [searchField, setSearchField] = useState(searchString);

  const searchFilter = useDebounce(searchField);
  useEffect(() => {
    handleSearchString(searchFilter);
  }, [searchFilter, handleSearchString]);

  const changeSearchFieldHandler = (event) => {
    setSearchField(event.target.value);
  };

  const clearSearchFieldHandler = () => {
    setSearchField("");
  };
  return (
    <TextField
      variant="outlined"
      id="search"
      placeholder="Search"
      size="small"
      value={searchField}
      onChange={changeSearchFieldHandler}
      autoComplete="off"
      sx={INPUT_FIELD_STYLES}
      inputProps={{
        style: {
          fontSize: "14px",
        },
      }}
      InputProps={{
        startAdornment: (
          <SearchIcon
            sx={{
              marginRight: "8px",
              color: "#495057",
            }}
          />
        ),
        endAdornment: (
          <ClearFieldButton
            show={searchField.length > 0}
            onClear={clearSearchFieldHandler}
          />
        ),
      }}
    />
  );
};

export default SearchField;
