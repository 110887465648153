import React from "react";
import Webcam from "react-webcam";

const videoConstraints = {
  facingMode: "user",
};

const Camera = ({ onUserMedia, webcamRef }) => {
  return (
    <Webcam
      ref={webcamRef}
      screenshotFormat="image/png"
      videoConstraints={videoConstraints}
      onUserMedia={onUserMedia}
      mirrored={true}
    />
  );
};

export default Camera;
