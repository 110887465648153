export const PerProjectUserTableHeaderV5 = [
  {
    label: "Project",
    key: "name",
    isSortable: true,
  },
  {
    label: "Description",
    key: "name",
    isSortable: true,
  },
  {
    label: "Total Efforts",
    key: "efforts",
    isSortable: true,
    align: "right",
    type: "number",
  },
];

export const PerProjectUserProjectsTableHeaderV5 = [
  {
    label: "User",
    key: "name",
    isSortable: true,
  },
  {
    label: "Total Efforts",
    key: "efforts",
    isSortable: true,
    align: "right",
    type: "number",
  },
];

export const PerProjectUserActivityTableHeaderV5 = [
  {
    label: "Activity",
    key: "name",
    isSortable: true,
  },
  {
    label: "Description",
    key: "description",
    isSortable: true,
  },
  {
    label: "Total Efforts",
    key: "efforts",
    isSortable: true,
    align: "right",
    type: "number",
  },
];

export const PerProjectUserTimesheetTableHeaderV5 = [
  {
    label: "Date",
    key: "date",
    isSortable: true,
  },
  {
    label: "Milestone",
    key: "milestone",
    isSortable: true,
  },
  {
    label: "Details",
    key: "details",
    isSortable: true,
  },
  {
    label: "Total Efforts",
    key: "efforts",
    isSortable: true,
    align: "right",
    type: "number",
  },
];
