import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

const HeaderReports = ({ text }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "0px 15px",
      }}
    >
      <Typography variant="h4" component="div" sx={{ padding: "1rem" }}>
        {text}
      </Typography>
    </Box>
  );
};

export default HeaderReports;
