import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  lastDayOfMonth,
  subMonths,
  subWeeks,
  format,
} from "date-fns";

// export const Params = () => {
//   const date = new Date();
//   const params = {
//     start_date: date,
//     end_date: date,
//     ot_start: date,
//     ot_end: date,
//     page_size: 1,
//     page: 1,
//     search:''
//   };
//   return params;
// };

export const TableHeaderContents = () => {
  const data = [
    { name: "Name", field: "name", align: "left", minW: "" },
    { name: "Absences", field: "absences", align: "center", minW: "" },
    { name: "Undertime (Mins)", field: "undertime", align: "center", minW: "" },
    { name: "Ord-OT", field: "Ord-OT", align: "center", minW: "" },
    { name: "Ord-ND", field: "Ord-ND", align: "center", minW: "" },
    { name: "Ord-ND-OT", field: "Ord-ND-OT", align: "center", minW: "" },
    { name: "RegNDExcess", field: "RegNDExcess", align: "center", minW: "" },
    { name: "RD", field: "RD", align: "center", minW: "" },
    { name: "RD-OT", field: "RD-OT", align: "center", minW: "" },
    { name: "RD-ND", field: "RD-ND", align: "center", minW: "" },
    { name: "RD-ND-OT", field: "RD-ND-OT", align: "center", minW: "" },
    { name: "SunNDExcess", field: "SunNDExcess", align: "center", minW: "" },
    { name: "LH", field: "LH", align: "center", minW: "" },
    { name: "LH-OT", field: "LH-OT", align: "center", minW: "" },
    { name: "LH-ND", field: "LH-ND", align: "center", minW: "" },
    { name: "LH-ND-OT", field: "LH-ND-OT", align: "center", minW: "" },
    { name: "LHNDExcess", field: "LHNDExcess", align: "center", minW: "" },
    { name: "SH", field: "SH", align: "center", minW: "" },
    { name: "SH-OT", field: "SH-OT", align: "center", minW: "" },
    { name: "SH-ND", field: "SH-ND", align: "center", minW: "" },
    { name: "SH-ND-OT", field: "SH-ND-OT", align: "center", minW: "" },
    { name: "SHNDExcess", field: "SHNDExcess", align: "center", minW: "" },
    { name: "LH-RD", field: "LH-RD", align: "center", minW: "" },
    { name: "LH-RD-OT", field: "LH-RD-OT", align: "center", minW: "" },
    { name: "SH-RD", field: "SH-RD", align: "center", minW: "" },
    { name: "SH-RD-OT", field: "SH-RD-OT", align: "center", minW: "" },
    { name: "SH-RD-ND", field: "SH-RD-ND", align: "center", minW: "" },
    { name: "SH-RD-ND-OT", field: "SH-RD-ND-OT", align: "center", minW: "" },
    { name: "SHRNDExcess", field: "SHRNDExcess", align: "center", minW: "" },
    { name: "DH", field: "DH", align: "center", minW: "" },
    { name: "DH-OT", field: "DH-OT", align: "center", minW: "" },
    { name: "DH-ND", field: "DH-ND", align: "center", minW: "" },
    { name: "DH-ND-OT", field: "DH-ND-OT", align: "center", minW: "" },
    { name: "DHNDExccess", field: "DHNDExccess", align: "center", minW: "" },
    { name: "DH-RD", field: "DH-RD", align: "center", minW: "" },
    { name: "DH-RD-OT", field: "DH-RD-OT", align: "center", minW: "" },
    { name: "DH-RD-ND", field: "DH-RD-ND", align: "center", minW: "" },
    { name: "DH-RD-ND-OT", field: "DH-RD-ND-OT", align: "center", minW: "" },
    { name: "DHRNDExcess", field: "DHRNDExcess", align: "center", minW: "" },
  ];
  return data;
};

export const findCol = (arr, col) => {
  arr.find((data) => data.field === col);
};

export const ranges = [
  {
    name: "This week",
    id: 1,
  },
  {
    name: "Last week",
    id: 2,
  },
  {
    name: "Last 2 weeks",
    id: 3,
  },
  {
    name: "This month",
    id: 4,
  },
  {
    name: "Last month",
    id: 5,
  },
  {
    name: "Last 2 months",
    id: 6,
  },
  {
    name: "Custom",
    id: 7,
  },
];

export const Params = (id) => {
  const currentday = new Date();
  switch (id) {
    case 1:
      return {
        start_date: startOfWeek(currentday),
        end_date: endOfWeek(currentday),
        ot_start: startOfWeek(currentday),
        ot_end: endOfWeek(currentday),
        page_size: 25,
        page: 1,
        search: "",
      };
    case 2:
      return {
        start_date: startOfWeek(subWeeks(currentday, 1)),
        end_date: endOfWeek(subWeeks(currentday, 1)),
        ot_start: startOfWeek(subWeeks(currentday, 1)),
        ot_end: endOfWeek(subWeeks(currentday, 1)),
        page_size: 25,
        page: 1,
        search: "",
      };
    case 3:
      return {
        start_date: startOfWeek(subWeeks(currentday, 2)),
        end_date: endOfWeek(subWeeks(currentday, 1)),
        ot_start: startOfWeek(subWeeks(currentday, 2)),
        ot_end: endOfWeek(subWeeks(currentday, 1)),
        page_size: 25,
        page: 1,
        search: "",
      };
    case 4:
      return {
        start_date: startOfMonth(currentday),
        end_date: lastDayOfMonth(currentday),
        ot_start: startOfMonth(currentday),
        ot_end: lastDayOfMonth(currentday),
        page_size: 25,
        page: 1,
        search: "",
      };
    case 5:
      return {
        start_date: startOfMonth(subMonths(currentday, 1)),
        end_date: lastDayOfMonth(subMonths(currentday, 1)),
        ot_start: startOfMonth(subMonths(currentday, 1)),
        ot_end: lastDayOfMonth(subMonths(currentday, 1)),
        page_size: 25,
        page: 1,
        search: "",
      };
    case 6:
      return {
        start_date: startOfMonth(subMonths(currentday, 2)),
        end_date: lastDayOfMonth(currentday),
        ot_start: startOfMonth(subMonths(currentday, 2)),
        ot_end: lastDayOfMonth(currentday),
        page_size: 25,
        page: 1,
        search: "",
      };
    case 7:
      return {
        start_date: currentday,
        end_date: currentday,
        ot_start: currentday,
        ot_end: currentday,
        page_size: 25,
        page: 1,
        search: "",
      };
    default:
      break;
  }
};

export const dateFormat = (date) => {
  return format(new Date(date), "yyyy-MM-dd");
};
