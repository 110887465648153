import React, { useState } from "react";
import { Modal, Box, Typography, TextField, Grid, Button } from "@mui/material";
import DropDownSelect from "../../../../component/CustomElement/DropDownSelect";
import { ModalStyle } from "../../../../helper/ModalStyle";
import {
  HolidayTypeDropDown,
  IncludeDropDown,
  dayLengthDropDown,
} from "../../../../helper/Settings/Time";

import CircularProgress from "@mui/material/CircularProgress";
import { useActiveInActive } from "../../../../hooks/react-query/Settings/time";

const UpdateHoliday = ({ open, handleClose, data }) => {
  const [edit, setEdit] = useState({
    name: data.name,
    id: data.id,
    type: data.type,
    length: data.length,
    includes: data.includes,
    remarks: data.remarks,
    status: data.status === 1 ? 0 : 1,
  });

  const { mutate: activateInactive, isLoading: loadingActiveInactive } =
    useActiveInActive();

  const requestActiveInactive = () => {
    activateInactive(edit);
  };
  const handleType = (e) => {
    setEdit({ ...edit, type: e.target.value });
  };

  const HandleIncludes = (e) => {
    setEdit({ ...edit, includes: e.target.value });
  };
  const HandleLength = (e) => {
    setEdit({ ...edit, length: e.target.value });
  };

  return (
    <Modal
      hideBackdrop
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={ModalStyle}>
        <Typography variant="h5" mb={5}>
          Update holiday / event
        </Typography>

        <Typography variant="h6" m={1.5}>
          {data.name}
        </Typography>
        <Grid container>
          <Grid
            container
            m={1}
            style={{
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <Grid item m={1}>
              <DropDownSelect
                DropDownData={HolidayTypeDropDown}
                objectName={{ val: "id", name: "id" }}
                value={edit.type}
                onChange={handleType}
                size={"small"}
                label={"Type"}
                minWidth={230}
              />
            </Grid>

            <Grid item m={0.5}>
              <DropDownSelect
                DropDownData={dayLengthDropDown}
                onChange={HandleLength}
                value={edit.length}
                objectName={{ val: "id", name: "id" }}
                label={"Length"}
                size={"small"}
                minWidth={100}
              />
            </Grid>
            <Grid item m={0.5}>
              <DropDownSelect
                DropDownData={IncludeDropDown}
                objectName={{ val: "id", name: "id" }}
                value={edit.includes}
                onChange={HandleIncludes}
                label={"Include staff"}
                minWidth={130}
                size={"small"}
              />
            </Grid>
          </Grid>
          <Grid container m={2}>
            <TextField
              variant="outlined"
              label="Remarks"
              size="small"
              multiline
              rows={4}
              inputProps={{ maxLength: 100 }}
              value={edit.remarks}
              onChange={(e) => setEdit({ ...edit, remarks: e.target.value })}
              fullWidth
            />
          </Grid>

          <Grid container sx={{ justifyContent: "end" }} spacing={3} mt={2}>
            <Grid item></Grid>
            <Grid item>
              <Button
                className="adjust-w cancel-button"
                variant="contained"
                sx={{ padding: "1px 10px 1px 10px" }}
                onClick={handleClose}
              >
                {" "}
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                className="adjust-w timest-button"
                variant="contained"
                sx={{ padding: "1px 15px 1px 15px" }}
                onClick={requestActiveInactive}
                disabled={
                  loadingActiveInactive ||
                  edit.type === "" ||
                  edit.includes === "" ||
                  edit.length === ""
                }
                endIcon={
                  loadingActiveInactive ? <CircularProgress size={20} /> : <></>
                }
              >
                {data.status === 1 ? "Deactivate" : "Activate"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default UpdateHoliday;
