import apiService from "../../axios";

export const getEmployeeList = async () => {
  try {
    const { data } = await apiService.get(
      "v5/timesheet/timesheet-week/employees"
    );

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getTimesheetWeeklyReport = async (id) => {
  try {
    const { data } = await apiService.post(
      `v5/timesheet/timesheet-week/employee-report?id=${id}`
    );

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
