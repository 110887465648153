import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import SettingCard from "./containers/general-identity/settingCard";
import "./styles.scss";
import { GlobalContext } from "../../../App";
/**
 * @returns Company Settings Components based on route /settings/general
 */
const CompanySettings = () => {
  const { settings } = React.useContext(GlobalContext);
  return (
    <div
      className="myDiv"
      style={{ padding: "20px 20px", width: "100%", height: "fit-content" }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "0px 15px",
          cursor: "context-menu",
        }}
      >
        <Tooltip title={"Manage company settings"} placement={"right"} arrow>
          <Typography variant="h5" component="div">
            Company Settings
          </Typography>
        </Tooltip>
      </Box>
      {settings.length > 0 && <SettingCard settings={settings} />}
    </div>
  );
};

export default CompanySettings;
