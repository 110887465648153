import apiService from "../axios";

export const document = async (url) => {
  try {
    let response = await apiService.get(`v5/document/get-all` + url, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(url),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const logDocument = async (url) => {
  try {
    let response = await apiService.post(`v5/document/view-log` + url, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(url),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const viewDocument = async (data) => {
  try {
    let response = await apiService.post(`v5/document/view-document`, data, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(data),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addDocument = async (data) => {
  try {
    await apiService.post(`v1/document/add`, data, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(data),
    });
  } catch (error) {
    throw error;
  }
};

export const deleteDocument = async (data) => {
  try {
    await apiService.post(`v1/document/delete`, data, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(data),
    });
  } catch (error) {
    throw error;
  }
};

export const updateDocument = async (data) => {
  try {
    await apiService.post(`v5/document/update`, data, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(data),
    });
  } catch (error) {
    throw error;
  }
};
