export const LEAVE_CONVERSION_APPLICATIONS_QUERY_KEY =
  "leave-conversion-applications";

export const ORDER = {
  ascending: "asc",
  descending: "desc",
};

export const HEADER_CELL_ID = {
  name: "name",
  request: "request",
  earned: "earned",
  status: "status",
  date_request: "date_request",
  actions: "actions",
};

export const HEADER_CELLS = {
  [HEADER_CELL_ID.name]: {
    label: "Name",
    isSortable: true,
  },
  [HEADER_CELL_ID.request]: {
    label: "Request",
    isSortable: true,
  },
  [HEADER_CELL_ID.earned]: {
    label: "Earned",
    isSortable: true,
  },
  [HEADER_CELL_ID.status]: {
    label: "Status",
    isSortable: true,
  },
  [HEADER_CELL_ID.date_request]: {
    label: "Date Requested",
    isSortable: true,
  },
  [HEADER_CELL_ID.actions]: {
    label: "Actions",
    isSortable: false,
  },
};

export const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100];

export const ROWS_PER_PAGE_DEFAULT = ROWS_PER_PAGE_OPTIONS[0];

export const ACTION_TYPE = {
  default: "",
  approve: "approve",
  decline: "decline",
};
