import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import Multiple from "./multiple";
import Single from "./single";
import Checkboxs from "./checkboxs";
import Rating from "./rating";
import pulseHelper from "./pulseHelper/pulseHelper";
const choicesArr = [
  {
    value: "Multiple Choice",
    name: "Multiple Choice",
  },
  {
    value: "Single Text Box",
    name: "Single Text Box",
  },
  {
    value: "Checkboxes",
    name: "Checkboxes",
  },
  {
    value: "Rating",
    name: "Rating",
  },
];
const Questions = ({
  ind,
  register,
  errors,
  handleQuestionArr,
  unregister,
}) => {
  const [value, setValue] = React.useState("Multiple Choice");
  const name = `selectTypeAnswer${ind}`;
  const name2 = `selectTypeQuestion${ind}`;

  return (
    <Card sx={{ minWidth: "550px", m: "5px 0px 5px 0px", p: "10px" }}>
      <CardHeader
        title={
          <Typography variant="h3" className="h3-bar">
            Question Number {ind + 1}
          </Typography>
        }
        action={
          <IconButton
            onClick={() => {
              handleQuestionArr(ind, value);
            }}
          >
            <DeleteIcon sx={{ width: "20px", height: "20px", color: "red" }} />
          </IconButton>
        }
      />
      <CardContent>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item lg={6} xs={6}>
            <FormControl fullWidth>
              <InputLabel>Survey Type Answer</InputLabel>
              <Select
                size="small"
                value={value}
                label="Survey Type Answer"
                name={name}
                {...register(name)}
                onChange={(e) => {
                  pulseHelper.handleUnregisterCreate(
                    ind,
                    e.target.value,
                    unregister,
                    setValue
                  );
                }}
                renderValue={(value) => {
                  const item = choicesArr.find(({ value: v }) => v === value);
                  return item.name;
                }}
              >
                {choicesArr.map((it, i) => (
                  <MenuItem value={it.value} key={i}>
                    {it.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={6} xs={6}>
            <TextField
              variant="outlined"
              label="Enter Question Here"
              fullWidth
              multiline
              size="small"
              required
              name={name2}
              {...register(name2)}
            />
          </Grid>
          <Grid item lg={12} xs={12} sx={{ mt: "5px", mb: "5px" }}>
            {value === "Multiple Choice" && (
              <Multiple register={register} ind={ind} />
            )}
            {value === "Single Text Box" && (
              <Single register={register} ind={ind} />
            )}
            {value === "Checkboxes" && (
              <Checkboxs register={register} ind={ind} />
            )}
            {value === "Rating" && <Rating register={register} ind={ind} />}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Questions;
