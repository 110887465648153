import apiService from "../axios";

export const getUsersLists = async ({
  page_size = 10,
  inActives = false,
  search,
  page = 1,
}) => {
  try {
    let response = await apiService.post(
      `/v5/user/get-users?page_size=${page_size}&inActives=${inActives}&page=${page}`,
      {
        search,
      },
      {
        Accept: `application/json`,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addUser = async (payload) => {
  try {
    let response = await apiService.post(
      `/v1/employee/add`,
      { ...payload, api: true },
      {
        Accept: `application/json`,
        "Content-Type": `multipart/form-data`,
        body: JSON.stringify(payload),
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const toggleUserStatus = async (payload) => {
  try {
    let response = await apiService.post(`v5/user/toggle-status`, payload, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(payload),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadUserCsv = async (payload) => {
  try {
    const response = await apiService.post(
      "v1/import/import-employees",
      payload,
      {
        Accept: `application/json`,
        "Content-Type": `application/json`,
        body: JSON.stringify(payload),
      }
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};
