export const employeeSkillsHeader = [
  {
    align: "left",
    name: "Name",
  },
  {
    align: "left",
    name: "Department",
  },
  {
    align: "left",
    name: "Status",
  },
  {
    align: "center",
    name: "Action",
  },
];

export const employeeSkillBody = [
  { field: "full_name", align: "left", minW: "" },
  { field: "department.name", align: "left", minW: "" },
  { field: "employee_skill", align: "left", minW: "" },
];

export const initEmpSkillsPayload = {
  search: "",
};
