import { useState, useEffect, useRef, useMemo } from "react";
import apiService from "../../api/axios";

export const useHolidayData = (countryCode, date) => {
  const [holidayData, setHolidayData] = useState(null);
  const fetchedDataRef = useRef(false);

  const fetchHolidayData = useMemo(
    () => async () => {
      try {
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // add 1 to month since it is zero-based
        const day = date.getDate();
        const response = await apiService.get(
          `v5/holidays?per_page=10&year=${year}&month=${month}&day=${day}&status=1`
        );
        // console.log("responseJSON", response.data.data.data[0].name);
        if (!response.statusText) {
          throw new Error(response.statusText);
        }
        const data = await response.data.data.data;
        setHolidayData(data);
        fetchedDataRef.current = true;
      } catch (error) {
        console.error(error);
        setHolidayData([]);
      }
    },
    [date]
  );

  useEffect(() => {
    if (!fetchedDataRef.current) {
      fetchHolidayData();
    }
  }, [fetchHolidayData]);

  return holidayData;
};
