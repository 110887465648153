import React, { useContext } from "react";
import {
  Backdrop,
  Modal,
  Fade,
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
} from "@mui/material";
import { PerProjectActivityV5Context } from ".";
import { generateDownloadableActivityReport } from "../../../api/perProjectActivity";
import moment from "moment";
import { toast } from "react-toastify";

const ExportModal = () => {
  const {
    isExportModalOpen,
    setIsExportModalOpen,
    isWeekends,
    isInactiveUsers,
    startDate,
    endDate,
    search,
  } = useContext(PerProjectActivityV5Context);

  const [optionSelected, setOptionSelected] = React.useState("csv");

  const handleClose = () => {
    setIsExportModalOpen(!isExportModalOpen);
  };

  const handleExports = async () => {
    const payload = {
      fromDate: moment(startDate).format("YYYY-MM-DD"),
      toDate: moment(endDate).format("YYYY-MM-DD"),
      inActives: isInactiveUsers,
      weekends: isWeekends,
      type: optionSelected,
      api: true,
      search: search,
    };
    const response = await generateDownloadableActivityReport(payload);
    if (!response) {
      toast.error("There was a probleming exporting the file.");
      return;
    }

    handleClose();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isExportModalOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isExportModalOpen}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Export As
          </Typography>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="csv"
              name="controlled-radio-buttons-group"
              value={optionSelected}
              onChange={(e) => setOptionSelected(e.target.value)}
            >
              <FormControlLabel value="csv" control={<Radio />} label="CSV" />

              <FormControlLabel
                value="excel"
                control={<Radio />}
                label="Excel"
              />
            </RadioGroup>
          </FormControl>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              size="small"
              sx={{ marginX: "5px" }}
              variant="contained"
              onClick={() => setIsExportModalOpen(false)}
            >
              <Typography sx={{ fontSize: "0.78rem" }}>CANCEL</Typography>
            </Button>
            <Button
              sx={{ marginX: "5px", backgroundColor: "#00c292" }}
              size="small"
              onClick={handleExports}
              variant="contained"
            >
              <Typography sx={{ fontSize: "0.78rem" }}>EXPORT</Typography>
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ExportModal;
