import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Paper,
  IconButton,
  Tooltip,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { AlertComponent } from "../../../../component/Alerts";
import AddDeptModal from "./AddDeptModal";
import api from "../../../../api/Department";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { useQuery } from "react-query";
import CheckIcon from "@mui/icons-material/Check";
import CustomPagination from "../../../../component/Pagination/CustomPagination";
import DropDownSelect from "../../../../component/Select/DropdownSelect";
import CircularProgress from "@mui/material/CircularProgress";
import ColorPicker from "../../../Settings/CompanySettings/containers/general-identity/component/color-picker";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import EditActionIcon from "../../../../component/Department/EditActionIcon";
const ActiveOrNot = [
  { name: "Active", value: 1 },
  { name: "Inactive", value: 0 },
];

const DepartmentSettings = () => {
  const [open, setOpen] = useState(false);
  const [apiResponseMsg, setApiResponseMsg] = useState("");
  const [editInd, setEditInd] = useState(null);
  const [delInd, setDelInd] = useState(null);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const textInput = useRef(null);

  const [edit, setEdit] = useState({
    status: null,
    description: "",
    short_name: "",
    name: "",
    color: null,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    status: StatusGetDept,
    data: DataGetDept,
    refetch: RefetchGetDept,
  } = useQuery(["get-dept"], () => api.GetDepartments(page));

  const HandleDeleteDept = async (id) => {
    setLoading(true);
    const deleteID = { id: id };
    const request = await api.DeleteDepartment(deleteID);
    if (request.status === "success") {
      RefetchGetDept();
      setApiResponseMsg("Deleted Successfully");
      setDelInd();
    }
    setDelInd(null);
    setLoading(false);
  };

  const HandleEditData = async (id) => {
    setLoading(true);
    const input = {
      id: id,
      name: edit.name,
      short_name: edit.short_name,
      color: edit.color,
      description: edit.description,
      status: edit.status,
    };

    const request = await api.EditDepartment(input);
    if (request.status === "success") {
      RefetchGetDept();
      setApiResponseMsg("Department Edited Successfully");
    }
    CloseEditMode();
    setLoading(false);
  };

  const CloseAlert = () => {
    setApiResponseMsg("");
  };

  const CloseEditMode = () => {
    setEditInd(null);
    setEdit({
      ...edit,
      editStatus: null,
      editDescription: "",
      editShortName: "",
      editName: "",
    });
  };
  return (
    <Grid container sx={{ padding: "15px" }}>
      <Grid container mb={3} sx={{ cursor: "context-menu" }}>
        <Tooltip
          title={"Manage companys departments"}
          placement={"right"}
          arrow
        >
          <Typography variant="h4">Department</Typography>
        </Tooltip>
      </Grid>
      <Paper sx={{ width: "100%", padding: "20px 15px 0px 20px" }}>
        <Grid
          container
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
          mb={4}
        >
          <Grid item>
            <Button
              className="adjust-w timest-button"
              variant="contained"
              sx={{ padding: "1px 5px 1px 5px" }}
              onClick={handleOpen}
            >
              Add Department
            </Button>
          </Grid>
          <Grid item>
            <TextField
              variant="standard"
              label="Search"
              onChange={(e) => setSearch(e.target.value)}
              inputRef={textInput}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      textInput.current.value = "";
                      setSearch("");
                    }}
                    size="small"
                  >
                    <CloseIcon fontSize="small" sx={{ fontSize: "0.85rem" }} />
                  </IconButton>
                ),
              }}
            />
          </Grid>
        </Grid>
        <TableContainer sx={{ height: 355, padding: "0px 0px 20px 0px" }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Short name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Color</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Description</TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align="center">
                  Status
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {StatusGetDept &&
                DataGetDept?.data
                  .filter((fd) => {
                    if (search === "") {
                      return fd;
                    } else {
                      return (
                        fd.name.toLowerCase().includes(search.toLowerCase()) ||
                        (fd.description !== null &&
                          fd.description
                            .toLowerCase()
                            .includes(search.toLowerCase())) ||
                        (fd.short_name !== null &&
                          fd.short_name
                            .toLowerCase()
                            .includes(search.toLowerCase()))
                      );
                    }
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data, key) => (
                    <TableRow key={key}>
                      {editInd === key ? (
                        <>
                          <TableCell>
                            <TextField
                              defaultValue={data.name}
                              variant="standard"
                              size="small"
                              InputProps={{ style: { fontSize: "0.75rem" } }}
                              fullWidth
                              onChange={(e) =>
                                setEdit({ ...edit, name: e.target.value })
                              }
                              inputProps={{ maxLength: 30 }}
                              helperText={
                                edit.name.length >= 30 &&
                                "the max length of 30 characters is reached"
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              defaultValue={data.short_name}
                              variant="standard"
                              size="small"
                              InputProps={{ style: { fontSize: "0.75rem" } }}
                              fullWidth
                              onChange={(e) =>
                                setEdit({ ...edit, short_name: e.target.value })
                              }
                              inputProps={{ maxLength: 15 }}
                              helperText={
                                edit.short_name.length >= 15 &&
                                "the max length of 15 characters is reached"
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <ColorPicker
                              color={edit.color ? edit.color : data.color}
                              onChange={(e) => setEdit({ ...edit, color: e })}
                              onChangeText={(e) =>
                                setEdit({ ...edit, color: e.input.target })
                              }
                              isDepartmentComponent={true}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              defaultValue={data.description}
                              variant="standard"
                              size="small"
                              InputProps={{ style: { fontSize: "0.75rem" } }}
                              fullWidth
                              onChange={(e) =>
                                setEdit({
                                  ...edit,
                                  description: e.target.value,
                                })
                              }
                              inputProps={{ maxLength: 80 }}
                              helperText={
                                edit.description.length >= 80 &&
                                "the max length of 80 characters is reached"
                              }
                            />
                          </TableCell>
                          <TableCell align="right">
                            <DropDownSelect
                              label={"status"}
                              DropDownData={ActiveOrNot}
                              minWidth={80}
                              setSelected={setEdit}
                              defValue={data.status}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {loading ? (
                              <CircularProgress />
                            ) : (
                              <>
                                <EditActionIcon
                                  HandleEditData={HandleEditData}
                                  CloseEditMode={CloseEditMode}
                                  editInput={edit}
                                  data={data}
                                  setEdit={setEdit}
                                />
                              </>
                            )}
                          </TableCell>
                        </>
                      ) : delInd === key ? (
                        <>
                          <TableCell colSpan={4} align="center">
                            Are you sure you want to delete this department?
                          </TableCell>
                          <TableCell align="center">
                            {loading ? (
                              <CircularProgress />
                            ) : (
                              <>
                                <IconButton
                                  size="small"
                                  onClick={() => HandleDeleteDept(data.id)}
                                  sx={{ color: "#61a644" }}
                                >
                                  <CheckIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={() => setDelInd(null)}
                                  sx={{ color: "rgb(228, 106, 118)" }}
                                >
                                  <CloseIcon fontSize="small" />
                                </IconButton>
                              </>
                            )}
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell sx={{ fontSize: "0.75rem" }}>
                            {data.name}
                          </TableCell>
                          <TableCell sx={{ fontSize: "0.75rem" }}>
                            {data.short_name}
                          </TableCell>
                          <TableCell>
                            <SupervisedUserCircleIcon
                              style={{ color: data.color }}
                              fontSize="small"
                            />
                          </TableCell>
                          <TableCell sx={{ fontSize: "0.75rem" }}>
                            {data.description}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ fontSize: "0.75rem" }}
                          >
                            {data.status === 1 ? "Active" : "Inactive"}
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              size="small"
                              onClick={() => setEditInd(key)}
                              disabled={delInd !== null || editInd !== null}
                              sx={{ color: "#008a00" }}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={() => setDelInd(key)}
                              disabled={delInd !== null || editInd !== null}
                              sx={{ color: "#db3700" }}
                            >
                              <DeleteOutlineIcon fontSize="small" />
                            </IconButton>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        {StatusGetDept && DataGetDept && (
          <CustomPagination
            pageInfo={DataGetDept.data}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        )}
      </Paper>
      {StatusGetDept && DataGetDept && (
        <AddDeptModal
          open={open}
          handleClose={handleClose}
          setApiRequest={setApiResponseMsg}
          refetch={RefetchGetDept}
          departments={DataGetDept.data}
        />
      )}
      {apiResponseMsg && (
        <AlertComponent
          severity={"success"}
          message={apiResponseMsg}
          handleOpenReaction={CloseAlert}
        />
      )}
    </Grid>
  );
};

export default DepartmentSettings;
