import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import { format } from "date-fns";

const customStyle = {
  borderRadius: "5px",
  color: "rgba(0, 0, 0, 0.53)",
  borderColor: "rgba(0, 0, 0, 0.23)",
};

const handleDateTime = (timeData) => {
  const thisDay = format(new Date(), "E LLL d yyyy") + " " + timeData;
  const dateTimeString = thisDay.toString();
  const newDateInstance = new Date(dateTimeString);
  return format(newDateInstance, "h:mm a");
};

const ShiftRow = ({ row, handleAddModal, handleDeleteModal }) => {
  const handleEdit = (data) => {
    handleAddModal(data);
  };
  const time_in = handleDateTime(row?.time_in);
  const time_out = handleDateTime(row?.time_out);
  return (
    <TableRow>
      <TableCell>{row.shift_name}</TableCell>
      <TableCell align="center">
        <Chip variant="outlined" label={time_in?.toString()} sx={customStyle} />
      </TableCell>
      <TableCell align="center">
        <Chip
          variant="outlined"
          label={time_out?.toString()}
          sx={customStyle}
        />
      </TableCell>
      <TableCell align="center">
        <Chip variant="outlined" label={row.grace_period} sx={customStyle} />
      </TableCell>
      <TableCell align="center">
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <IconButton
            color="info"
            onClick={() => {
              handleEdit(row);
            }}
          >
            <EditRoundedIcon />
          </IconButton>
          <IconButton
            color="error"
            onClick={() => {
              handleDeleteModal(row);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default ShiftRow;
