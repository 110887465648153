import { useState, Fragment } from "react";

import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import StyledTableCell from "../../../UserApplicationMgmt/UserApplications/tableComponents/StyledTableCell";
import LongTextTableCell from "./LongTextTableCell";
import ActionModal from "../Modal/ActionModal";
import { ACTION_TYPE } from "../../../../lib/settingsTimesheet/allPendingTimesheetEntries";

const PendingEntryTableRow = ({ data }) => {
  const {
    id,
    name,
    type,
    code,
    activity,
    code_status,
    code_limit,
    hours,
    details,
    date,
    formatted_date,
    status,
  } = data;

  const [showActionModal, setShowActionModal] = useState(false);
  const [action, setAction] = useState("");

  const closeModalHandler = () => {
    setShowActionModal(false);
  };

  const updateActionHandler = () => {
    setShowActionModal(true);
    setAction(ACTION_TYPE.update);
  };

  const deleteActionHandler = () => {
    setShowActionModal(true);
    setAction(ACTION_TYPE.delete);
  };

  return (
    <Fragment>
      <TableRow hover>
        {/* Name */}
        <StyledTableCell>{name}</StyledTableCell>

        {/* Date */}
        <StyledTableCell align="center">{formatted_date}</StyledTableCell>

        {/* Type */}
        <StyledTableCell align="center">{type}</StyledTableCell>

        {/* Code */}
        <StyledTableCell align="center">{code}</StyledTableCell>

        {/* Activity */}
        <StyledTableCell align="center">{activity}</StyledTableCell>

        {/* Project Code Status */}
        <StyledTableCell align="center">{code_status}</StyledTableCell>

        {/* Project Code Monthly Limit */}
        <StyledTableCell align="center">{code_limit}</StyledTableCell>

        {/* Details */}
        <LongTextTableCell>{details}</LongTextTableCell>

        {/* Hours Rendered */}
        <StyledTableCell align="center">{hours}</StyledTableCell>

        {/* Actions */}
        <StyledTableCell align="center">
          <Stack
            direction="row"
            spacing={1}
            divider={<Divider orientation="vertical" flexItem />}
            justifyContent="center"
          >
            {/* APPROVE BUTTON */}
            <Tooltip title="Edit">
              <IconButton onClick={updateActionHandler}>
                <EditIcon />
              </IconButton>
            </Tooltip>

            {/* DENY BUTTON */}
            <Tooltip title="Delete">
              <IconButton onClick={deleteActionHandler}>
                <DeleteIcon sx={{ color: "#fa5252ce" }} />
              </IconButton>
            </Tooltip>
          </Stack>
        </StyledTableCell>
      </TableRow>

      <ActionModal
        open={showActionModal}
        onClose={closeModalHandler}
        id={id}
        status={status}
        action={action}
        startDate={date}
      />
    </Fragment>
  );
};

export default PendingEntryTableRow;
