export const PerProjectUserTableHeaderV5 = [
  {
    label: "Project",
    key: "name",
    isSortable: true,
  },
  {
    label: "Description",
    key: "name",
    isSortable: true,
  },
  {
    label: "Total Efforts",
    key: "efforts",
    isSortable: true,
    align: "right",
    type: "number",
  },
];

export const PerTypeTableHeaderV5 = [
  {
    label: "Project Type",
    key: "name",
    isSortable: true,
  },
  {
    label: "Description",
    key: "name",
    isSortable: true,
  },
  {
    label: "Total Efforts",
    key: "efforts",
    isSortable: true,
    align: "right",
    type: "number",
  },
];

export const PerProjectTypeCodesTableHeaderV5 = [
  {
    label: "Project Code",
    key: "name",
    isSortable: true,
  },
  {
    label: "Description",
    key: "name",
    isSortable: true,
  },
  {
    label: "Total Efforts",
    key: "efforts",
    isSortable: true,
    align: "right",
    type: "number",
  },
];

export const PerProjectUserActivityTableHeaderV5 = [
  {
    label: "Department",
    key: "name",
    isSortable: true,
  },
  {
    label: "Description",
    key: "description",
    isSortable: true,
  },
  {
    label: "Total Efforts",
    key: "efforts",
    isSortable: true,
    align: "right",
    type: "number",
  },
];

export const PerProjectUserTimesheetTableHeaderV5 = [
  {
    label: "Activity",
    key: "date",
    isSortable: true,
  },
  {
    label: "Description",
    key: "milestone",
    isSortable: true,
  },
  {
    label: "Total Efforts",
    key: "efforts",
    isSortable: true,
    align: "right",
    type: "number",
  },
];
