import { useState, useCallback, useEffect, useMemo } from "react";

import { useQuery, useQueryClient, useMutation } from "react-query";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  getEmployeeApplications,
  getWorkplaceAttachmentImage,
  submitApplicationAction,
} from "../../../api/userApplications";
import {
  USER_APP_QUERY_KEY,
  WORKPLACE_ATTACHMENT_IMG_KEY,
  HEADER_CELL_ID,
  ORDER,
  APP_TYPE,
  IMAGE_UNAVAILABLE,
} from "../../../lib/UserAppMgmt/UserApplications";
import {
  getComparator,
  getApplicationType,
} from "../../../helper/UserApplicationMgmt/UserApplications";

const generateQueryKeyObj = (
  applicationType = "",
  leaveType = "",
  underOvertimeType = ""
) => {
  const { isLeave, isOvertime } = getApplicationType(applicationType);

  const objType = { applicationType, queryType: "" };

  if (isLeave) objType.queryType = leaveType;

  if (isOvertime) objType.queryType = underOvertimeType;

  return objType;
};

export const useUserApplication = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const application = searchParams.get("application");

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchString, setSearchString] = useState("");
  const [order, setOrder] = useState(ORDER.DESCENDING);
  const [orderBy, setOrderBy] = useState(HEADER_CELL_ID.DATE_FILED);
  const [applicationType, setApplicationType] = useState(
    APP_TYPE?.[application]?.id || APP_TYPE.leave.id
  );
  const [leaveType, setLeaveType] = useState("all");
  const [underOvertimeType, setUnderOverTimeType] = useState("all");
  const [leaveSelectionCache, setLeaveSelectionCache] = useState([]);
  const [underOverTimeSelectionCache, setUnderOvertimeSelectionCache] =
    useState([]);

  useEffect(() => {
    if (!application || !APP_TYPE[application])
      return setSearchParams(
        { application: applicationType },
        { replace: true }
      );
  }, [searchParams, setSearchParams, applicationType, application]);

  const sortFn = useCallback(
    (data) => {
      const sortedData = [...data.data].sort(getComparator(order, orderBy));
      return {
        ...data,
        data: sortedData,
      };
    },

    [order, orderBy]
  );

  const { isLeave, isOvertime } = getApplicationType(applicationType);

  const typeQueryObj = useMemo(
    () => generateQueryKeyObj(applicationType, leaveType, underOvertimeType),
    [applicationType, leaveType, underOvertimeType]
  );

  useEffect(() => {
    setPage(1);
  }, [searchString, applicationType, pageSize]);

  const userApplicationsQuery = useQuery(
    [USER_APP_QUERY_KEY, page, pageSize, searchString, typeQueryObj],
    () => getEmployeeApplications(page, pageSize, searchString, typeQueryObj),
    {
      select: orderBy ? sortFn : undefined,
      onSuccess: (data) => {
        if (isLeave) setLeaveSelectionCache(data.types);
        if (isOvertime) setUnderOvertimeSelectionCache(data.types);
      },
    }
  );

  const changePage = (newPage) => {
    setPage(newPage);
  };

  const changePageSize = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const searchFilter = (filterString) => {
    setSearchString(filterString);
  };

  const changeOrder = (newOrder) => {
    setOrder(newOrder);
  };

  const changeOrderBy = (newOrderBy) => {
    setOrderBy(newOrderBy);
  };

  const changeApplicationType = (newApplicationType) => {
    if (!newApplicationType) return setApplicationType(APP_TYPE.leave.id);
    setSearchParams({ application: newApplicationType }, { replace: true });
    setApplicationType(newApplicationType);
  };

  const changeLeaveType = (newLeaveType) => {
    setLeaveType(newLeaveType);
  };

  const changeUnderOvertimeType = (newUnderOvertimeType) => {
    setUnderOverTimeType(newUnderOvertimeType);
  };
  return {
    ...userApplicationsQuery,
    applicationType,
    order,
    orderBy,
    page,
    pageSize,
    searchString,
    leaveType,
    underOvertimeType,
    typeQueryObj,
    leaveSelection: leaveSelectionCache,
    overtimeSelection: underOverTimeSelectionCache,
    changeApplicationType,
    changePage,
    changePageSize,
    searchFilter,
    changeOrder,
    changeOrderBy,
    changeLeaveType,
    changeUnderOvertimeType,
  };
};

export const usePrefetchUserApplication = (
  hasNextPage,
  page,
  pageSize,
  searchString,
  typeQueryObj
) => {
  const queryClient = useQueryClient();

  if (!hasNextPage) return;

  const nextPage = page + 1;

  queryClient.prefetchQuery(
    [USER_APP_QUERY_KEY, nextPage, pageSize, searchString, typeQueryObj],
    getEmployeeApplications.bind(
      null,
      nextPage,
      pageSize,
      searchString,
      typeQueryObj
    ),
    {
      staleTime: 5000,
    }
  );
};

export const useFetchWorkplaceAttachment = (
  applicationId,
  employeeId,
  type
) => {
  const queryClient = useQueryClient();

  const createImageGalleryObjects = useCallback((data) => {
    if (!data.length) return IMAGE_UNAVAILABLE;
    return data.map((image) => ({ original: image, thumbnail: image }));
  }, []);

  const workplaceAttachmentQuery = useQuery(
    [WORKPLACE_ATTACHMENT_IMG_KEY, applicationId, employeeId, type],
    getWorkplaceAttachmentImage.bind(null, applicationId, employeeId, type),
    {
      enabled: false,
      select: createImageGalleryObjects,
      onError: () => {
        queryClient.setQueryData(
          [WORKPLACE_ATTACHMENT_IMG_KEY, applicationId, employeeId, type],
          IMAGE_UNAVAILABLE
        );
      },
    }
  );

  return workplaceAttachmentQuery;
};

export const useUserApplicationAction = () => {
  const queryClient = useQueryClient();

  const applicationMutation = useMutation(submitApplicationAction, {
    onSuccess: () => {
      toast.success("Action successfully applied.");
      queryClient.resetQueries(USER_APP_QUERY_KEY);
    },
    onError: () => {
      toast.error("Error submitting action.");
    },
  });

  return applicationMutation;
};
