import React, { useState } from "react";
import { Switch, IconButton, Tooltip } from "@mui/material";
import RestoreIcon from "@mui/icons-material/Restore";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import {
  // useActiveInActive,
  useReflect,
  useUndoReflect,
} from "../../../../hooks/react-query/Settings/time";
import ConfirmationModal from "./ConfirmationModal";
import UpdateHoliday from "./UpdateHoliday";
const initStateVal = {
  open: false,
  msg: "",
  submit: () => {},
  isLoading: false,
  params: null,
  submitLabel: "",
};
const Actions = ({ val, ind }) => {
  const [confirmationModal, setConfirmationModal] = useState(initStateVal);
  const [editModal, setEditModal] = useState(false);
  const { mutate: reflect, isLoading: loadingReflect } = useReflect(
    confirmationModal.params,
    val.name
  );
  const { mutate: undo, isLoading: loadingUndo } = useUndoReflect(
    confirmationModal.params,
    val.name
  );

  // const { mutate: activateInactive, isLoading: loadingActiveInactive } =
  //   useActiveInActive(confirmationModal.params);

  const handleReflectModal = (val) => {
    setConfirmationModal({
      ...confirmationModal,
      open: true,
      msg: `Are you sure you want to reflect holiday ${val.name}  to timesheet?`,
      params: val.id,
      submit: () => reflect(),
      submitLabel: "Reflect",
      isLoading: loadingReflect,
    });
  };
  const handleUndoReflectModal = (val) => {
    setConfirmationModal({
      ...confirmationModal,
      open: true,
      msg: `Are you sure you want to remove reflected holiday ${val.name} to timesheet?`,
      params: val.id,
      submit: () => undo(),
      submitLabel: "Yes",
      isLoading: loadingUndo,
    });
  };

  const handleUpdateModal = (val) => {
    setEditModal(true);
  };
  const handleCloseUpdateModal = () => {
    setEditModal(false);
  };

  // const handleActiveInActiveModal = (val,update) => {
  //   setConfirmationModal({
  //     ...confirmationModal,
  //     open: true,
  //     msg:
  //       val.status === 0
  //         ? "Are you sure you want to activate this holiday?"
  //         : "Are you sure you want to deactivate this holiday?",
  //     params: { id: val.id, status: val.status === 1 ? 0 : 1 },
  //     submit: () => update?update(val):requestActiveInactive(val),
  //     submitLabel: val.status === 0 ? "Activate" : "Deactivate",
  //     isLoading: loadingActiveInactive,
  //   });
  // };

  const handleCloseConfirmation = () => {
    setConfirmationModal(initStateVal);
  };

  // const requestActiveInactive = (val) => {
  //   activateInactive({
  //     onSuccess: () => toast.success(`Successfully  holiday: ` + val.name),
  //     onError: () => toast.error("Something went wrong. Please try again"),
  //   });
  // };
  return (
    <>
      <div style={{ margin: "10px" }}>
        {val.status === 1 ? (
          <div>
            {val.timesheet === 0 ? (
              <Tooltip title="Reflect to timesheet">
                <IconButton
                  onClick={() => {
                    handleReflectModal(val);
                  }}
                >
                  <PendingActionsIcon sx={{ color: "green" }} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Undo reflect timesheet">
                <IconButton onClick={() => handleUndoReflectModal(val)}>
                  <RestoreIcon sx={{ color: "red" }} />
                </IconButton>
              </Tooltip>
            )}
          </div>
        ) : (
          <div>
            {val.timesheet === 1 && (
              <Tooltip title="Undo reflect timesheet">
                <IconButton onClick={() => handleUndoReflectModal(val)}>
                  <RestoreIcon sx={{ color: "red" }} />
                </IconButton>
              </Tooltip>
            )}
          </div>
        )}
        <div style={{ display: "flex", alignItems: "center" }}>
          Activate:{" "}
          <Tooltip title={val.status === 1 ? "Deactivate" : "Activate"}>
            <Switch
              onChange={() => handleUpdateModal(val)}
              checked={val.status === 1 ? true : false}
            />
          </Tooltip>
        </div>
      </div>
      <ConfirmationModal
        handleClose={handleCloseConfirmation}
        data={confirmationModal}
        isLoading={
          confirmationModal.submitLabel === "Reflect"
            ? loadingReflect
            : loadingUndo
        }
      />
      <UpdateHoliday
        handleClose={handleCloseUpdateModal}
        open={editModal}
        data={val}
      />
    </>
  );
};
export default Actions;
