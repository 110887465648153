import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function DropDownSelect({
  label,
  DropDownData,
  minWidth,
  setSelected,
  defValue,
}) {
  const [select, setSelect] = React.useState(defValue);

  const handleChange = (event) => {
    setSelect(event.target.value);
    setSelected((status) => ({ ...status, status: event.target.value }));
  };

  return (
    <div>
      <FormControl variant="standard" sx={{ m: 1, minWidth: minWidth }}>
        <Select
          sx={{ textAlign: "center", fontSize: "0.75rem" }}
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={select}
          onChange={handleChange}
        >
          {DropDownData.map((data, key) => (
            <MenuItem
              value={data.value}
              style={{ display: "flex", justifyContent: "center" }}
              key={key}
            >
              {data.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
