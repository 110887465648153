import { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";

import useDebounce from "../../../../hooks/customHooks/debounce";
import useWindowSize from "../../../../hooks/customHooks/useWindowSize";
import ClearFieldButton from "./ClearFieldButton";
import { getApplicationType } from "../../../../helper/UserApplicationMgmt/UserApplications";
import CustomToggleButtonGroup from "./CustomToggleButtonGroup";

const INPUT_FIELD_STYLES = {
  justifySelf: "flex-end",
  height: "44.75px",
  width: "100%",
  maxWidth: "280px",
  ".MuiInputBase-root": {
    height: "100%",
    padding: "0px 2px 0px 7px",
    borderRadius: "8px",
    backgroundColor: "#f1f3f5",
    "&.Mui-disabled": {
      backgroundColor: "#E6E6E6",
    },
    "&.Mui-disabled fieldset": {
      border: "2px solid transparent",
    },
    "&.Mui-disabled:hover fieldset": {
      border: "2px solid transparent",
    },
    "& fieldset": {
      border: "2px solid transparent",
    },
    "&:hover fieldset": {
      borderColor: "#576c9f",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#21293C",
    },
  },
};

const CONTAINER_STYLES = {
  height: "fit-content",
  width: "100%",
  padding: "6px",
  display: "flex",
  gap: "6px",
  backgroundColor: "#fff",
  borderRadius: "14px",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.05)",
};

const DROPDOWN_STYLES = {
  height: "44.75px",
  width: "100%",
  maxWidth: "220px",
  ".MuiOutlinedInput-notchedOutline": {
    border: "1.5px solid #e9ecef",
    borderRadius: "8px",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#21293C",
  },
};

// ! REFACTOR ! /////////////////////////////////////////
const UserApplicationActionBar = ({
  data,
  onSearch,
  onChange,
  type,
  leaveType,
  underOvertimeType,
  changeLeaveType,
  changeUnderOvertimeType,
  selection,
}) => {
  const [searchField, setSearchField] = useState("");

  const searchFilter = useDebounce(searchField);
  const { width } = useWindowSize();

  const { isLeave, isOvertime, isWfh, forgotTimeInOut } =
    getApplicationType(type);

  const dropdownValue = isLeave
    ? leaveType
    : isOvertime
    ? underOvertimeType
    : "all";

  const flexDirection = width <= 1100 ? "column" : "row";
  const showFullWidth = width <= 1100;
  const maxWidth = width <= 768 ? "100%" : "280px";
  const showFull = width <= 500;
  const showFullMaxWidth = showFull ? { flexDirection: "column" } : {};
  const marginLeft = isWfh && showFullWidth ? "0" : "auto";

  useEffect(() => {
    onSearch(searchFilter);
  }, [searchFilter, onSearch]);

  const changeSearchFieldHandler = (event) => {
    setSearchField(event.target.value);
  };

  const clearSearchFieldHandler = () => {
    setSearchField("");
  };

  const selectionOnChangeHandler = (event) => {
    const newValue = event.target.value;
    if (isLeave) changeLeaveType(newValue);
    if (isOvertime) changeUnderOvertimeType(newValue);
  };

  return (
    <Box sx={{ ...CONTAINER_STYLES, flexDirection }}>
      <CustomToggleButtonGroup
        data={data}
        value={type}
        onChange={onChange}
        width={width}
      />

      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: "6px",
          ...showFullMaxWidth,
        }}
      >
        {!isWfh && !forgotTimeInOut && (
          <Select
            size="small"
            labelId={`${type}-select-label`}
            id={`${type}-select`}
            value={dropdownValue}
            onChange={selectionOnChangeHandler}
            sx={{
              ...DROPDOWN_STYLES,
              ...(showFull ? { maxWidth: "100%" } : {}),
            }}
          >
            <MenuItem key="default" value="all">
              All
            </MenuItem>
            {selection.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        )}

        <TextField
          variant="outlined"
          id="search-input"
          placeholder="Search..."
          size="small"
          value={searchField}
          onChange={changeSearchFieldHandler}
          autoComplete="off"
          sx={{ ...INPUT_FIELD_STYLES, maxWidth, marginLeft }}
          InputProps={{
            startAdornment: (
              <SearchIcon sx={{ marginRight: "8px", color: "#adb5bd" }} />
            ),
            endAdornment: (
              <ClearFieldButton
                show={searchField.length > 0}
                onClear={clearSearchFieldHandler}
              />
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default UserApplicationActionBar;
