import apiService from "../axios";

export const getSchedule = async (url) => {
  try {
    let response = await apiService.get(`v1/schedules/get-schedule` + url, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(url),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getShift = async (url) => {
  try {
    let response = await apiService.get(`v1/shifts/get-shift` + url, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(url),
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const addSchedule = async (data) => {
  try {
    await apiService.post(`v1/schedules/add-schedule`, data, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(data),
    });
  } catch (error) {
    throw error;
  }
};

export const deleteSchedule = async (data) => {
  try {
    await apiService.post(`v1/schedules/delete-schedule`, data, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(data),
    });
  } catch (error) {
    throw error;
  }
};

export const editSchedule = async (data) => {
  try {
    await apiService.post(`v1/schedules/edit-schedule`, data, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(data),
    });
  } catch (error) {
    throw error;
  }
};
