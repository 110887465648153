import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Grid,
  Button,
  Grow,
  Box,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  TextField,
  IconButton,
  Slider,
  Input,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CkEditor from "../Dashboard/ck";
import { sliderMarks } from "../Dashboard/timePicker";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import moment from "moment";
//import timesheet from "../../api/timesheet";
import { TimesheetContext } from "../../globalstate/UserContext/timesheetcontext";
// import { getFilteredTimesheet } from "../../helper/timesheet";
import AlertDialog from "./AlertDialog";
import CircularProgress from "@mui/material/CircularProgress";
import {
  useAddTimesheet,
  useEditTimesheet,
} from "../../hooks/react-query/timesheet";
import { v4 as uuidv4 } from "uuid";
import { startOfWeek, isBefore } from "date-fns";

const search = (nameKey, myArray) => {
  for (var i = 0; i < myArray.length; i++) {
    if (myArray[i].value === nameKey) {
      return myArray[i].label;
    }
  }
};

const Entry = () => {
  const {
    // refetchMonthlyTimesheet,
    // refetchWeeklyTimesheet,
    entry,
    selectedActivity,
    selectedCode,
    selectedDate,
    myDuration,
    // setMyDuration,
    timeSheetID,
    myDetails,
    // setMyDetails,
    // weeklyDataTimesheet,
    // setTimeSheetID,
    // setIsEntry,
    isEntry,
    // setAPIResponse,
    mySprint,
    userDepartment,
    handleEntryModal,
  } = useContext(TimesheetContext);
  const [slideValue, setSlideValue] = useState("0 min");
  const [duration, setDuration] = useState(0);
  const [myActivity, setMyActivity] = useState();
  const [myCode, setMyCode] = useState();
  const [details, setDetails] = useState("");
  const [changeDate, setChangeDate] = useState();
  const [sprint, setSprint] = useState();
  const [loading, setLoading] = useState(false);
  const dateFormat = moment(changeDate).format("llll");
  const [openDialog, setOpenDialog] = useState(false);

  // const findTimesheetEntry = useCallback(() => {
  //   const array = findEntryDisable
  //     ? []
  //     : getFilteredTimesheet(weeklyDataTimesheet);
  //   let check = [];
  //   let entries = {};
  //   array.map((data) =>
  //     Object.keys(data).map((mpind) =>
  //       +new Date(data[mpind].created) ===
  //         +new Date(changeDate ? changeDate : selectedDate) &&
  //       data[mpind].time_activity.id === selectedActivity?.id &&
  //       data[mpind].time_code.id === selectedCode?.id
  //         ? (check.push(true),
  //           (entries = {
  //             id: data[mpind].id,
  //             comment: data[mpind].comment,
  //             hours: parseFloat(data[mpind].hours),
  //           }))
  //         : check.push(false)
  //     )
  //   );

  //   if (check.includes(true)) {
  //     setIsEntry(true);
  //     setMyDetails(entries.comment);
  //     setTimeSheetID(entries.id);
  //     setMyDuration(entries.hours);
  //   } else {
  //     setMyDuration(0);
  //     setIsEntry(false);
  //     setMyDetails("");
  //     setTimeSheetID();
  //   }
  // }, [
  //   changeDate,
  //   findEntryDisable,
  //   selectedActivity?.id,
  //   selectedCode?.id,
  //   selectedDate,
  //   setIsEntry,
  //   setMyDetails,
  //   setMyDuration,
  //   setTimeSheetID,
  //   weeklyDataTimesheet,
  // ]);

  useEffect(() => {
    var resultObject = search(parseFloat(myDuration), sliderMarks);
    setSlideValue(resultObject);
    setDuration(myDuration ? parseFloat(myDuration) : 0);
    setMyActivity(selectedActivity);
    setMyCode(selectedCode);
    setDetails(myDetails);
    setSprint(mySprint);
    setChangeDate(selectedDate);
    if (!entry) {
      setChangeDate();
      setDuration(0);
      setDetails("");
      setSprint();
    }
  }, [
    myDuration,
    selectedActivity,
    selectedCode,
    selectedDate,
    timeSheetID,
    entry,
    myDetails,
    mySprint,
  ]);
  // useEffect(() => {
  //   if (changeDate) {
  //     findTimesheetEntry();
  //   }
  // }, [changeDate, findTimesheetEntry]);
  const { mutate: AddTimesheet } = useAddTimesheet();
  const { mutate: EditTimesheet } = useEditTimesheet();
  const HandleRequest = async () => {
    setLoading(true);
    const code = myCode.id;
    const activity = myActivity.id;
    const date = dateFormat;
    const hours = duration;
    const action = "single";
    const id = timeSheetID !== undefined ? timeSheetID : uuidv4();
    const inputs = {
      id,
      code,
      activity,
      date,
      details,
      hours,
      action,
      sprint,
      late: isLate ? 1 : 0,
    };
    if (timeSheetID === undefined) {
      AddTimesheet(inputs);
    } else {
      EditTimesheet(inputs);
    }

    handleEntryModal(false);
    setLoading(false);
    // console.log(data);
    // if (entries?.message) {
    //   setAPIResponse({
    //     msg: entries.response.data.details,
    //     status: "error",
    //   });
    // }
    // if (entries.status === "success") {
    //   setAPIResponse({
    //     msg: entries.data.msg,
    //     status: "success",
    //   });
    //   refetchWeeklyTimesheet();
    //   refetchMonthlyTimesheet();
    //   handleEntryModal(false);
    // }
    // setLoading(false);
  };

  const hideActionIfAnalyticsPage = window.location.pathname;

  const prevWeekEditDisable =
    isBefore(
      new Date(selectedDate),
      startOfWeek(new Date(), { weekStartsOn: 1 })
    ) && isEntry;
  const isLate = isBefore(
    new Date(changeDate),
    startOfWeek(new Date(), { weekStartsOn: 1 })
  );
  return (
    <Modal open={entry} disableEnforceFocus>
      <Grow
        in={entry}
        style={{ transformOrigin: "0 0 0" }}
        {...(entry ? { timeout: 500 } : {})}
        unmountOnExit
      >
        <Card className="login-modal leave-modal">
          <CardHeader
            title={
              <Typography variant="h3" className="h3-bar">
                Timesheet Entry
              </Typography>
            }
            action={
              <IconButton onClick={handleEntryModal}>
                <CloseOutlinedIcon sx={{ width: "15px", height: "15px" }} />
              </IconButton>
            }
            sx={{
              paddingBottom: "10px",
            }}
          />
          <CardContent className="cc-static-dp">
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid item xs={12} lg={6} container rowSpacing={2}>
                <Grid item xs={12}>
                  <Box className="typ-over">
                    <Typography
                      variant="overline"
                      sx={{ paddingLeft: "10px", alignSelf: "center" }}
                    >
                      Selected Date
                    </Typography>
                  </Box>
                  <Box className="picker-container">
                    <Box className="picker-sidebar">
                      <Typography
                        variant="h4"
                        color="text.secondary"
                        sx={{ fontSize: "1.125rem" }}
                      >
                        {dateFormat.substring(0, 3)}
                      </Typography>
                      <Typography variant="h4" className="typ-h4-d">
                        {dateFormat.substr(5, 6).replace(",", "")}
                      </Typography>
                    </Box>
                    <Box className="picker-d">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          value={changeDate}
                          onChange={(newValue) => {
                            setChangeDate(newValue);
                            // findTimesheetEntry();
                          }}
                          disabled={prevWeekEditDisable}
                          // onClose={findTimesheetEntry}
                          inputProps={{ readOnly: true }}
                          renderInput={(params) => (
                            <TextField
                              // onChange={() => findTimesheetEntry()}
                              {...params}
                              size="small"
                              sx={{
                                paddingLeft: "13px",
                                marginBottom: "2px",
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Department</InputLabel>
                    <Select
                      value={10}
                      label="Department"
                      disabled
                      IconComponent={KeyboardArrowDownRoundedIcon}
                    >
                      <MenuItem value={10} sx={{ fontSize: "0.875rem" }}>
                        {userDepartment}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
                container
                rowSpacing={"18px"}
                columnSpacing={1}
              >
                <Grid item xs={4} lg={12}>
                  <TextField
                    size="small"
                    disabled
                    fullWidth
                    label={`Project Code `}
                    value={selectedCode?.name}
                  />
                </Grid>
                <Grid item xs={4} lg={12}>
                  <TextField
                    size="small"
                    disabled
                    fullWidth
                    label={`Activity`}
                    value={selectedActivity?.name}
                  />
                </Grid>
                <Grid item xs={4} lg={12}>
                  <TextField
                    defaultValue={sprint ? sprint : mySprint}
                    onChange={(e) => setSprint(e.target.value)}
                    fullWidth
                    size="small"
                    label={`Milestone: `}
                    error={!sprint}
                    disabled={prevWeekEditDisable}
                  />
                </Grid>
              </Grid>
              <Grid item xs={10} lg={10}>
                <Box sx={{ margin: "8px 7px 0px 5px" }}>
                  <Slider
                    disabled={prevWeekEditDisable}
                    value={duration}
                    valueLabelDisplay="on"
                    onChange={(activeThumb) => {
                      var a = activeThumb.target.value;
                      setDuration(parseFloat(a));
                      var resultObject = search(a, sliderMarks);
                      setSlideValue(resultObject);
                    }}
                    step={0.25}
                    marks
                    min={0}
                    max={8}
                    color="FlignoGreen"
                    className="time-slider"
                  />
                </Box>
              </Grid>
              <Grid item xs={2} lg={2}>
                <Input
                  value={slideValue}
                  sx={{
                    fontSize: "0.775rem",
                    fontWeight: "600",
                    "input.MuiInput-input": {
                      textAlign: "center",
                    },
                    pointerEvent: "none",
                  }}
                />
                <FormHelperText
                  sx={{ fontSize: "0.7rem", textAlign: "center" }}
                >
                  Selected Time
                </FormHelperText>
              </Grid>
              <Grid item xs={12} lg={12}>
                <CkEditor
                  details={setDetails}
                  myDetails={myDetails === undefined ? details : myDetails}
                  disabled={prevWeekEditDisable}
                />
              </Grid>
            </Grid>
          </CardContent>
          {hideActionIfAnalyticsPage !== "/reports/timesheet-analytics" ? (
            <CardActions>
              {isEntry ? (
                <>
                  <Button
                    size="small"
                    className="adjust-w cancel-button"
                    disableElevation
                    variant="contained"
                    sx={{ marginLeft: "auto", minWidth: "150px" }}
                    onClick={() => setOpenDialog(true)}
                  >
                    Delete
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    size="small"
                    className="adjust-w cancel-button"
                    disableElevation
                    variant="contained"
                    sx={{ marginLeft: "auto", minWidth: "150px" }}
                    onClick={() => handleEntryModal(false)}
                  >
                    Cancel
                  </Button>
                </>
              )}
              {duration !== 0 && (
                <Button
                  size="small"
                  className="adjust-w timest-button"
                  disableElevation
                  variant="contained"
                  sx={{ marginLeft: "15px !important", minWidth: "150px" }}
                  onClick={() => HandleRequest()}
                  disabled={
                    prevWeekEditDisable ||
                    loading ||
                    !details ||
                    !sprint ||
                    !changeDate ||
                    (myDetails === details &&
                      parseFloat(myDuration) === duration &&
                      sprint === mySprint &&
                      +new Date(changeDate) === +new Date(selectedDate))
                  }
                  endIcon={
                    loading ? <CircularProgress size={"1.2rem"} /> : <div />
                  }
                >
                  Apply
                </Button>
              )}
            </CardActions>
          ) : null}
          <AlertDialog
            openDialog={openDialog}
            closeDialog={setOpenDialog}
            handleEntryModal={handleEntryModal}
          />
        </Card>
      </Grow>
    </Modal>
  );
};

export default Entry;
