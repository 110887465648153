import React, { useContext, useState } from "react";
import {
  Backdrop,
  Modal,
  Fade,
  Box,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import hooks from "../../hooks/react-query/ManageFeedback";
import { toast } from "react-toastify";
import { GlobalContext } from "../../App";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const AddEditCommentFeedbackModal = ({
  item,
  open,
  close,
  type,
  getComment,
}) => {
  const { mutate } = hooks.useAddEditFeedback();
  const editComment = item?.comment.find((el) => el.id === getComment);
  const [comment, setComment] = useState(editComment?.comment);
  const { user } = useContext(GlobalContext);

  const submitHandler = () => {
    if (type === "add") {
      const data = {
        comment_id: null,
        fb_comment: comment,
        fb_id: item?.id,
      };
      mutate(data);
      toast.success("Feedback successfully added.");
      close(false);
    } else if (type === "edit") {
      const data = {
        comment_id: editComment?.id,
        fb_comment: comment,
        fb_id: item?.id,
        id: user?.employee.id,
      };
      mutate(data);
      toast.success("Feedback successfully updated.");
      close(false);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      // onClose={close(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box className="typ-over" sx={{ marginBottom: "10px" }}>
            <Typography
              variant="overline"
              sx={{ paddingLeft: "10px", alignSelf: "center" }}
            >
              {type === "add" ? "Comment feedback" : "Edit comment feedback"}
            </Typography>
          </Box>
          <TextField
            sx={{ margin: "10px 0px" }}
            id="outlined-basic"
            label="Category"
            variant="outlined"
            fullWidth
            readOnly
            value={item?.category || ""}
          />
          <TextField
            sx={{ margin: "10px 0px" }}
            id="outlined-basic"
            label="Subject"
            variant="outlined"
            readOnly
            fullWidth
            value={item?.subject}
          />
          <TextField
            sx={{ margin: "10px 0px" }}
            id="outlined-basic"
            label="Content"
            variant="outlined"
            fullWidth
            readOnly
            value={item?.content}
          />
          <TextField
            sx={{ margin: "10px 0px" }}
            id="outlined-basic"
            label="Comment"
            variant="outlined"
            fullWidth
            multiline
            defaultValue={type === "add" ? "" : editComment?.comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Button
              fullWidth
              size="large"
              sx={{ marginX: "5px" }}
              variant="contained"
              onClick={() => close(false)}
            >
              <Typography sx={{ fontSize: "0.78rem" }}>CANCEL</Typography>
            </Button>
            <Button
              fullWidth
              sx={{ marginX: "5px", backgroundColor: "#00c292" }}
              size="large"
              variant="contained"
              onClick={submitHandler}
            >
              <Typography sx={{ fontSize: "0.78rem" }}>SUBMIT</Typography>
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AddEditCommentFeedbackModal;
