import React, { useState } from "react";
import {
  Button,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SquareIcon from "@mui/icons-material/Square";
import GroupIcon from "@mui/icons-material/Group";
import SkillPopper from "./SkillPopper";
import DropDownSelect from "../../../component/CustomElement/DropDownSelect";
import ColorPopper from "./ColorPopper";
import { useEditSkills } from "../../../hooks/react-query/SettingsSytem/Skills";
const initVal = {
  PopOver: false,
  editMode: false,
  colorPicker: false,
};
const ActiveInactive = [
  { id: 0, name: "Inactive" },
  { id: 1, name: "Active" },
];
const SkillsCard = ({ skill }) => {
  const [data, setData] = useState(initVal);
  const [edit, setEdit] = useState(skill);
  const { mutate, isLoading } = useEditSkills();

  const handleOpenPopover = (e) => {
    setData({ ...data, PopOver: e.currentTarget });
  };
  const handleClosePopOver = () => {
    setData({ ...data, PopOver: null });
  };
  const handleClickEdit = () => {
    setData({ ...data, editMode: true, PopOver: null });
  };
  const handleCloseEdit = () => {
    setEdit(skill);
    setData(initVal);
  };
  const handleOpenColor = (e) => {
    setData({ ...data, colorPicker: e.currentTarget });
  };
  const handleCloseColor = () => {
    setData({ ...data, colorPicker: null });
  };
  const handleName = (e) => {
    setEdit({ ...edit, name: e.target.value });
  };
  const handleColor = (e) => {
    setEdit({ ...edit, color: e });
  };
  const handleStatus = (e) => {
    setEdit({ ...edit, status: e.target.value });
  };
  const handleSave = () => {
    mutate(edit, { onSuccess: () => handleCloseEdit() });
  };
  return (
    <Paper
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{ display: "flex", justifyContent: "end", alignItems: "center" }}
      >
        {!data.editMode ? (
          <div
            style={{
              display: "flex",
              backgroundColor:
                skill.status === 1
                  ? "rgb(0,128,0,0.1)"
                  : "rgba(255, 0, 0, 0.1)",
              alignItems: "center",
              padding: "0px 2px 0px 2px",
              borderRadius: "3px",
            }}
          >
            <div>
              <SquareIcon
                fontSize="small"
                sx={{
                  fontSize: "0.75rem",
                  color: skill.status === 1 ? "green" : "red",
                  marginRight: "3px",
                }}
              />
            </div>
            <Typography
              variant="caption"
              style={{ color: skill.status === 1 ? "green" : "red" }}
            >
              {skill.status === 1 ? "Active" : "Inactive"}
            </Typography>
          </div>
        ) : (
          <div style={{ marginTop: "5px" }}>
            <DropDownSelect
              objectName={{ val: "id", name: "name" }}
              DropDownData={ActiveInactive}
              label={"Status"}
              minWidth={80}
              size={"small"}
              value={edit.status}
              onChange={handleStatus}
            />
          </div>
        )}
        <IconButton onClick={handleOpenPopover}>
          <MoreHorizIcon fontSize="small" />
        </IconButton>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}
      >
        <div
          style={{
            borderRadius: "100%",
            width: "80px",
            height: "80px",
            backgroundColor: data.editMode ? edit.color : skill.color,
            cursor: data.editMode ? "pointer" : "default",
          }}
          onClick={data.editMode ? handleOpenColor : null}
        />
      </div>
      <div
        style={{ textAlign: "center", marginTop: "10px", fontWeight: "bold" }}
      >
        {!data.editMode ? (
          skill.name
        ) : (
          <TextField value={edit.name} size={"small"} onChange={handleName} />
        )}
      </div>
      <div
        style={{
          margin: "18px 10px 15px 10px",
          padding: "10px",
          backgroundColor: "#F0EEED",
          borderRadius: "7px",
        }}
      >
        {!data.editMode ? (
          <div
            style={{
              display: "flex",
              textAlign: "start",
              fontSize: "0.75rem",
              alignItems: "start",
            }}
          >
            <div style={{ margin: "0px 10px 9px 0px" }}>
              <GroupIcon fontSize="small" />
            </div>
            {skill.emp_skill.length > 1
              ? `${skill.emp_skill.length} Employees`
              : `${skill.emp_skill.length} Employee`}{" "}
            equipped with this skill
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              size="small"
              style={{ color: "red" }}
              onClick={handleCloseEdit}
            >
              Cancel
            </Button>
            <Button
              size="small"
              // style={{color:"green"}}
              onClick={handleSave}
              variant={"contained"}
              disabled={
                (edit.name === skill.name &&
                  edit.color === skill.color &&
                  edit.status === skill.status) ||
                edit.name === "" ||
                isLoading
              }
            >
              {!isLoading ? "Save" : "Saving..."}
            </Button>
          </div>
        )}
      </div>
      <SkillPopper
        handleClose={handleClosePopOver}
        PopOver={data.PopOver}
        handleClickEdit={handleClickEdit}
      />
      <ColorPopper
        handleClose={handleCloseColor}
        PopOver={data.colorPicker}
        handleColor={handleColor}
        color={edit.color}
      />
    </Paper>
  );
};

export default SkillsCard;
