import Typography from "@mui/material/Typography";
import { Tooltip } from "@mui/material";
import { Box } from "@mui/system";

const TITLE_STYLES = {
  fontSize: "clamp(20px, 5vw, 28px)",
  color: "#21293C",
};

const Title = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        cursor: "context-menu",
      }}
    >
      <Tooltip
        title={"View companys timesheet weekly report"}
        placement={"right"}
        arrow
      >
        <Typography sx={TITLE_STYLES}>Timesheet Weekly Report</Typography>
      </Tooltip>
    </Box>
  );
};

export default Title;
