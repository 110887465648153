import React, { useState } from "react";
import {
  Backdrop,
  Modal,
  Fade,
  Box,
  Typography,
  FormControl,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import hooks from "../../../hooks/react-query/ProjectType";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const AddProjectType = ({ open, setOpen }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(true);
  const addMutation = hooks.useAddProjectType();

  const submitHandler = () => {
    const data = {
      description: description,
      name: name,
      status: status ? "1" : "0",
    };
    addMutation.mutate(data);
    setOpen(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      // onClose={setOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box className="typ-over" sx={{ marginBottom: "10px" }}>
            <Typography
              variant="overline"
              sx={{ paddingLeft: "10px", alignSelf: "center" }}
            >
              Add New Project Type
            </Typography>
          </Box>
          <FormControl sx={{ display: "flex", justifyContent: "space-around" }}>
            <TextField
              sx={{ margin: "10px 0px" }}
              id="outlined-basic"
              label="Name"
              variant="outlined"
              fullWidth
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              sx={{ margin: "10px 0px" }}
              id="outlined-basic"
              label="Description"
              variant="outlined"
              fullWidth
              onChange={(e) => setDescription(e.target.value)}
            />
            <FormControl sx={{ margin: "10px 0px" }}>
              <InputLabel id="Status">Status</InputLabel>
              <Select
                labelId="Status"
                label="Status"
                fullWidth
                value={status}
                onChange={() => setStatus(!status)}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </FormControl>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              size="small"
              sx={{ marginX: "5px" }}
              variant="contained"
              onClick={() => setOpen(false)}
            >
              <Typography sx={{ fontSize: "0.78rem" }}>CANCEL</Typography>
            </Button>
            <Button
              sx={{ marginX: "5px", backgroundColor: "#00c292" }}
              size="small"
              onClick={submitHandler}
              variant="contained"
              disabled={name.length === 0 || description.length === 0}
            >
              <Typography sx={{ fontSize: "0.78rem" }}>ADD</Typography>
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AddProjectType;
