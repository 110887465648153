import { useQuery } from "react-query";
import { getManagementSummary } from "../../../api/userApplicationSummary";

export const useUserSummaryHeader = (select) => {
  return useQuery(["get-user-app-summary-headers", select], () => {
    let headers = [];
    if (select === "ForgotTimeInOut") {
      headers = [
        { id: "name", label: "Name", minWidth: 100 },
        { id: "type", label: "Application", minWidth: 100 },
        { id: "reason", label: "Reason", minWidth: 100 },
        { id: "status", label: "Status", minWidth: 100 },
        { id: "action", label: "Action", minWidth: 100 },
      ];
    } else if (select === "Document") {
      headers = [
        { id: "emp", label: "Name", minWidth: 100 },
        { id: "document", label: "Application", minWidth: 100 },
        { id: "status", label: "Status", minWidth: 100 },
        { id: "action", label: "Action", minWidth: 100 },
      ];
    } else {
      headers = [
        { id: "name", label: "Name", minWidth: 100 },
        { id: "type", label: "Application", minWidth: 100 },
        { id: "purpose", label: "Purpose", minWidth: 100 },
        { id: "status", label: "Status", minWidth: 100 },
        { id: "action", label: "Action", minWidth: 100 },
      ];
    }
    return headers;
  });
};

export const useManagementSummary = (
  select,
  startDate,
  endDate,
  status,
  size,
  page,
  search,
  type
) => {
  return useQuery(
    [
      "get-management-summary",
      select,
      startDate,
      endDate,
      status,
      size,
      page,
      search,
      type,
    ],
    () =>
      getManagementSummary(
        select,
        startDate,
        endDate,
        status,
        size,
        page,
        search,
        type
      )
  );
};

const queries = {
  useUserSummaryHeader,
  useManagementSummary,
};

export default queries;
