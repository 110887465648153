import React, { useState } from "react";
import {
  Backdrop,
  Modal,
  Fade,
  Box,
  Typography,
  FormControl,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { format } from "date-fns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { isAfter, isBefore } from "date-fns";
import { toast } from "react-toastify";
import hooks from "../../../hooks/react-query/MOTD";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const AddNewMotd = ({ open, setOpen }) => {
  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));
  // const [range, setRange] = useState("This Week");
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState(true);
  const { mutate } = hooks.useNewMOTD();
  const [errors, setErrors] = useState("");

  const [documentName, setDocumentName] = useState("Upload a document.");
  const [document, setDocument] = useState("");
  const addNewMotdHandler = () => {
    let data = {
      message: description,
      status: status,
      from: startDate,
      to: endDate,
      document: document,
      documentName: documentName,
    };

    const formData = new FormData();
    formData.append("title", title);
    formData.append("message", description);
    formData.append("status", status);
    formData.append("from", startDate);
    formData.append("to", endDate);
    formData.append("document", document);
    formData.append("documentName", documentName);

    mutate(formData);
    setOpen(!open);
  };

  const uploadFile = (e) => {
    setDocumentName(e.target.files[0].name);
    setDocument(e.target.files[0]);
    if (e.target.files[0].size > 3000000) {
      setErrors("Maximum upload file size: 3MB");
      setDocumentName("Upload a document.");
      setDocument("");
    } else {
      setErrors("");
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      // onClose={setOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box className="typ-over" sx={{ marginBottom: "10px" }}>
            <Typography
              variant="overline"
              sx={{ paddingLeft: "10px", alignSelf: "center" }}
            >
              Add new announcement
            </Typography>
          </Box>
          <FormControl sx={{ display: "flex", justifyContent: "space-around" }}>
            <TextField
              sx={{ margin: "10px 0px" }}
              id="outlined-basic"
              label="Title"
              variant="outlined"
              fullWidth
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              sx={{ margin: "10px 0px" }}
              id="outlined-basic"
              label="Message"
              variant="outlined"
              fullWidth
              onChange={(e) => setDescription(e.target.value)}
            />
            <FormControl sx={{ margin: "10px 0px" }}>
              <InputLabel id="Status">Status</InputLabel>
              <Select
                labelId="Status"
                label="Status"
                fullWidth
                value={status}
                onChange={() => setStatus(!status)}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </FormControl>
          <Box
            sx={{
              display: "flex",
              mt: 2,
              justifyContent: "center",
              alignItems: "flex-start",
              marginBottom: 2,
            }}
          >
            <TextField
              error={errors}
              size={"small"}
              disabled
              fullWidth
              name={"document"}
              value={documentName}
              label={"Document"}
              variant="outlined"
              helperText={errors}
            ></TextField>
            <Button
              component="label"
              variant="contained"
              className="timest-button"
              sx={{ ml: 0.5 }}
            >
              Upload&nbsp;
              <input
                hidden
                accept="application/pdf,application/vnd.ms-excel/*"
                multiple
                name="file"
                type="file"
                onChange={(e) => uploadFile(e)}
              />
            </Button>
          </Box>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 5,
              marginBottom: 10,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={startDate}
                label={"Start"}
                onChange={(newValue) => {
                  if (isAfter(newValue, new Date(endDate))) {
                    toast.error("Start date should not be after end date.");
                    return;
                  }
                  setStartDate(format(newValue, "yyyy-MM-dd"));
                  // setRange("Custom");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{
                      width: "100%",
                      marginX: "5px",
                    }}
                  />
                )}
                className="datepicker"
                inputProps={{ readOnly: true }}
              />
              <DatePicker
                value={endDate}
                label={"End"}
                onChange={(newValue) => {
                  if (isBefore(newValue, new Date(startDate))) {
                    toast.error("End date should not be before start date.");
                    return;
                  }
                  setEndDate(format(newValue, "yyyy-MM-dd"));
                  // setRange("Custom");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{
                      width: "100%",
                      margin: "5px",
                    }}
                  />
                )}
                className="datepicker"
                inputProps={{ readOnly: true }}
              />
            </LocalizationProvider>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
            }}
          >
            <Button
              size="small"
              sx={{ marginX: "5px" }}
              variant="contained"
              onClick={() => setOpen(false)}
            >
              <Typography sx={{ fontSize: "0.78rem" }}>CANCEL</Typography>
            </Button>
            <Button
              sx={{ marginX: "5px", backgroundColor: "#00c292" }}
              size="small"
              onClick={addNewMotdHandler}
              variant="contained"
              disabled={title.length ? !description.length : true}
            >
              <Typography sx={{ fontSize: "0.78rem" }}>Save</Typography>
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AddNewMotd;
