import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import "../../../styles/ReportsDashboard/index.scss";
import CountUp from "react-countup";

const TotalStaffEffortV5 = ({ total, expected, range, startDate, endDate }) => {
  return (
    <div>
      <Card
        sx={{
          borderRadius: "10px",
          height: "103px",
        }}
      >
        <CardContent>
          <Grid
            container
            spacing={1}
            sx={{
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <Grid item>
              <div className="tmh-container">
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="overline"
                >
                  TOTAL MAN HOURS
                </Typography>
              </div>
              <div className="hours-container">
                <span className="man-hours">
                  <CountUp end={parseFloat(total)} decimals={2} separator="," />
                </span>
                <span className="total-hours">
                  {" "}
                  / <CountUp end={parseFloat(expected)} separator="," /> HOURS
                </span>
              </div>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  pt: 1,
                }}
              >
                <Avatar
                  className="tmh-icon-bg"
                  sx={{
                    backgroundColor: "#0083c9",
                    height: 60,
                    width: 60,
                  }}
                >
                  <GroupIcon
                    className="tmh-icon"
                    sx={{
                      height: 30,
                      width: 30,
                    }}
                  />
                </Avatar>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default TotalStaffEffortV5;
