import { differenceInDays, format, parseISO } from "date-fns";

function filterDataToTable(value, id, row, select) {
  if (id === "created_at") {
    const dated_format = select === "Overtime" ? "PPp" : "PP";

    value =
      row["type"] === "out" ||
      (row["type"] === "in" && {
        created_at: format(parseISO(row["created_at"]), "LLL dd, yyyy"),
        date: format(parseISO(row["date"]), "LLL dd, yyyy h:mm aa"),
      }) ||
      row["type"] === "Leave Conversion"
        ? {
            created_at: format(parseISO(row["created_at"]), "PPp" || ""),
          }
        : {
            created_at: format(parseISO(row["created_at"]), dated_format),
            from: format(
              parseISO(row["from"] || row["date_from"]),
              dated_format
            ),
            to: format(parseISO(row["to"] || row["date_to"]), dated_format),
            duration:
              row["days"] ||
              row["hours"] ||
              differenceInDays(
                parseISO(row["date_to"]),
                parseISO(row["date_from"])
              ) + 1 ||
              "",
          };
  }

  if (id === "date") {
    value =
      value === null ? "N/A" : format(parseISO(row["updated_at"]), "PPp" || "");
  } else if (id === "reviewed_by") {
    value = row["reviewed_by"] || "N/A";
  } else if (id === "leave_type") {
    value = row["leave_type"]?.name;
  } else if (id === "leave_credits") {
    const json = JSON.parse(row["attr"]);
    value = json?.credits_left;
  } else if (id === "comments") {
    const json = JSON.parse(row["attr"]);
    value = json?.approvers;
  }

  return value;
}

export default filterDataToTable;
