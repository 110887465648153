import format from "date-fns/format";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { startOfWeek, endOfWeek } from "date-fns";

import { ORDER } from "../../../lib/settingsTimesheet/PendingTimesheetEntries";

const descendingComparator = (a, b, orderBy) => {
  const x = a[orderBy],
    y = b[orderBy];

  if (y < x) return -1;
  if (y > x) return 1;
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === ORDER.DESCENDING
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const generateSkeletonLoader = (count, SkeletonComponent) => {
  return Array(count)
    .fill(SkeletonComponent)
    .map((Component, index) => ({ ...Component, key: index }));
};

export const generateRandomNum = (min, max) =>
  Math.floor(Math.random() * (max - min)) + min;

export const downloadFile = ({ data, fileName, fileType }) => {
  const blob = new Blob([data], { type: fileType });

  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);

  const clickEvent = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  a.dispatchEvent(clickEvent);
  a.remove();
};

export const downloadCsv = (pendingEntryData) => {
  const headers = [
    "Name,Date,Type,Code,Activity,Project Code Status,Project Code Monthly Limit,Currently,Details,Hours Rendered",
  ];

  const content = pendingEntryData.map((pendingEntry) =>
    [
      pendingEntry.name ? `"${pendingEntry.name}"` : "-",
      pendingEntry.formatted_date ? `"${pendingEntry.formatted_date}"` : "-",
      pendingEntry.type || "-",
      pendingEntry.code || "-",
      pendingEntry.activity || "-",
      pendingEntry.code_status || "-",
      pendingEntry.code_limit ?? "-",
      pendingEntry.used ?? "-",
      pendingEntry?.details
        ? `"${removeHtmlElement(pendingEntry.details)}"`
        : "-",
      pendingEntry.hours || "-",
    ].join(",")
  );

  const csvData = [...headers, ...content].join("\n");

  downloadFile({
    data: csvData,
    fileName: "pending-entries.csv",
    fileType: "text/csv",
  });
};

export const downloadPdf = (pendingEntryData) => {
  const head = [
    [
      "Name",
      "Date",
      "Type",
      "Code",
      "Activity",
      "Project Code Status",
      "Project Code Monthly Limit",
      "Currently",
      "Details",
      "Hours Rendered",
    ],
  ];

  const body = pendingEntryData.map((pendingEntry) => [
    pendingEntry.name ? pendingEntry.name : "-",
    pendingEntry.formatted_date || "-",
    pendingEntry.type || "-",
    pendingEntry.code || "-",
    pendingEntry.activity || "-",
    pendingEntry.code_status || "-",
    pendingEntry.code_limit ?? "-",
    pendingEntry.used ?? "-",
    pendingEntry?.details ? removeHtmlElement(pendingEntry.details) : "-",
    pendingEntry.hours || "-",
  ]);

  const doc = new jsPDF("landscape");

  autoTable(doc, { head, body });

  doc.save("pending-entries.pdf");
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const removeHtmlElement = (string) => {
  if (!string) return "-";
  return string.replace(/(<([^>]+)>)/gi, "");
};

export const modifyData = (data) => {
  return data.map((el) => {
    const formatted_date = el?.created_at
      ? format(new Date(el.created_at), "LLLL dd, yyyy")
      : "N/A";

    return {
      ...el,
      formatted_date,
      date: el?.created_at || new Date(),
      name: el.time_employee.full_name,
      code: el.time_code.name,
      activity: el.time_activity.name,
      code_status: el.time_code.restricted ? "restricted" : "unrestricted",
      code_limit: el.time_code.limit_per_month,
      details: el.comment,
    };
  });
};

export const getStartEndDateOfWeek = () => {
  const currentDate = new Date();

  const dateOptions = { weekStartsOn: 1 };

  return {
    startOfWeek: startOfWeek(currentDate, dateOptions),
    endOfWeek: endOfWeek(currentDate, dateOptions),
  };
};

export const formatDateForQuery = (dateInput) => {
  const dateToFormat =
    dateInput instanceof Date ? dateInput : new Date(dateInput);

  return format(dateToFormat, "yyyy-MM-dd");
};
