import apiService from "../../axios";
import { toast } from "react-toastify";

export const GetSkillsAPI = async (payload) => {
  try {
    const req = await apiService.get(
      `v5/skills?search=${payload.search}&page_size=100&status=${payload.status}`,
      {
        accept: "application/json",
      }
    );
    return req.data;
  } catch (error) {
    throw error;
  }
};

export const AddSkillsAPI = async (payload) => {
  try {
    const req = await apiService.post("v5/skills/add-skill", payload, {
      accept: "application/json",
      body: JSON.stringify(payload),
    });
    toast.success(`Skills successfully added: ${payload.name}`);
    return req;
  } catch (error) {
    toast.error(`Something went wrong`);
    throw error;
  }
};

export const EditSkillsAPI = async (payload) => {
  try {
    const req = await apiService.post("v5/skills/update-skill", payload, {
      accept: "application/json",
      body: JSON.stringify(payload),
    });
    toast.success(`Skills successfully edited: ${payload.name}`);
    return req;
  } catch (error) {
    toast.error(`Something went wrong`);
    throw error;
  }
};

export const DeleteSkillsAPI = async (payload) => {
  // try {
  //   const req = await apiService.post("v5/skills/update-skill", payload, {
  //     accept: "application/json",
  //     body: JSON.stringify(payload),
  //   });
  //   toast.success(`Skills successfully edited: ${payload.name}`);
  //   return req;
  // } catch (error) {
  //   toast.error(`Something went wrong`);
  //   throw error;
  // }
};

export const GetEmployeeSkillAPI = async (payload) => {
  try {
    const req = await apiService.get(
      `v5/skills/employee-skill?search=${payload.search}&per_page=${payload.per_page}&page=${payload.page}`,
      {
        accept: "application/json",
      }
    );
    return req.data;
  } catch (error) {
    throw error;
  }
};

export const AssignSkillAPI = async (payload) => {
  try {
    const req = await apiService.post("v5/skills/assigned-skill", payload, {
      accept: "application/json",
      body: JSON.stringify(payload),
    });
    toast.success(`Skills successfully updated`);
    return req;
  } catch (error) {
    throw error;
  }
};
