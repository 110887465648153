import { Typography, Box, IconButton, Tooltip } from "@mui/material";
import {
  useState,
  useMemo,
  createContext,
  useCallback,
  useEffect,
} from "react";
import Table from "../../../component/Compliance/ComplianceReport/Table";
import hooks from "../../../hooks/react-query/Compliance/ComplianceReport";
import ComplianceRow from "./ComplianceRow";
import Rows from "./Rows";
import moment from "moment";
import TableOptions from "./TableOptions";
import { getRangeDates } from "../../../helper/Reports";
import { Ranges } from "../../../lib/compliance/complianceReport";
import ExportModal from "./ExportModal";
import {
  downloadCsv,
  downloadPdf,
} from "../../../helper/Compliance/ComplianceReport";
import DownloadIcon from "@mui/icons-material/Download";

export const ComplianceReportContext = createContext({});

const ComplianceReport = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [search, setSearch] = useState("");
  const [keyword, setKeyword] = useState("");
  const [fetch, setFetch] = useState(false);

  const [report, setReport] = useState();
  const [total, setTotal] = useState(0);

  const [startDate, setStartDate] = useState(moment().startOf("month"));
  const [endDate, setEndDate] = useState(moment().endOf("month"));
  const [range, setRange] = useState([]); // eslint-disable-line

  const [columns, setColumn] = useState();
  const [columnsCSV, setColumnCSV] = useState();

  const [isExportModalOpen, openExportModal] = useState(false);

  const [orderDirection, setOrderDirection] = useState("desc");
  const [item, setItem] = useState();
  const [type, setType] = useState("name");
  const value = useMemo(
    () => ({ endDate, startDate, setEndDate, setStartDate }),
    [endDate, startDate, setEndDate, setStartDate]
  );

  const { data, isFetching } = hooks.useComplianceReport(
    getFetchUrl(),
    search,
    pageSize,
    page,
    fetch
  );

  const initComplianceReport = useCallback(() => {
    if (data) {
      setTotal(data?.data?.total || 0);
      setReport(data?.data?.data || []);
      let columns =
        data?.headers?.map(function (column) {
          return {
            label: column.item,
            data: column.item,
            type: "item",
            id: column.id,
          };
        }) || [];
      setColumn([
        { label: "Name", data: "name", type: "name", id: "null" },
        ...columns,
        { label: "overall", data: "overall", type: "overall", id: "null" },
      ]);
      setColumnCSV([
        { label: "Last Name", data: "name" },
        { label: "First Name", data: "name" },
        ...columns,
        { label: "overall", data: "overall" },
      ]);
    }
  }, [data]);

  useEffect(() => {
    initComplianceReport();
  }, [initComplianceReport]);

  function getFetchUrl() {
    return (
      "?search=" +
      search +
      "&page_size=" +
      pageSize +
      "&page=" +
      page +
      "&item=" +
      item +
      "&type=" +
      type +
      "&direction=" +
      orderDirection +
      "&from=" +
      moment(startDate).format("YYYY-MM-DD") +
      "&to=" +
      moment(endDate).format("YYYY-MM-DD")
    );
  }

  const handleRange = (e) => {
    const date = getRangeDates(e);
    setRange(e);
    setStartDate(date.fromDate);
    setEndDate(date.toDate);
  };

  const handleRefresh = () => {
    setSearch("");
    setKeyword("");
    setOrderDirection("asc");
    setType("name");
    setPage(1);
    setFetch(!fetch);
  };

  const handleSortRequest = ({ key, id, typeData }) => {
    let direction = orderDirection === "desc" ? "asc" : "desc";
    setOrderDirection(direction);
    setItem(id);
    setType(typeData);
    setFetch(!fetch);
  };

  const handleExport = (option) => {
    if (option === "pdf") {
      downloadPdf(report, columns);
    } else {
      downloadCsv(report, columns, columnsCSV);
    }
  };

  const Buttons = [
    {
      Icon: (
        <IconButton
          key="add"
          sx={{ mt: 2, mr: 2.5 }}
          aria-label="add"
          onClick={(e) => openExportModal(!isExportModalOpen)}
        >
          <DownloadIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Box sx={{ margin: "0px", pr: "25px", pl: "25px" }}>
      <ComplianceReportContext.Provider value={value}>
        <ExportModal
          isExportModalOpen={isExportModalOpen}
          handleClose={(e) => openExportModal(!isExportModalOpen)}
          handleExport={handleExport}
        />
        <Box
          sx={{
            pt: "5px",
            display: "flex",
            justifyContent: "flex-start",
            cursor: "context-menu",
          }}
        >
          <Tooltip
            title="View employees compliance report"
            placement={"right"}
            arrow
          >
            <Typography variant="h4">Compliance Report </Typography>
          </Tooltip>
        </Box>
        <Box sx={{ pt: "5px" }}>
          <Typography>
            {" "}
            List of employees who are not able to comply.{" "}
          </Typography>
        </Box>
        <Box sx={{ pt: "5px" }}>
          <TableOptions
            Ranges={Ranges}
            handleRange={handleRange}
            handleRefresh={handleRefresh}
          />
        </Box>
        <Box sx={{ pt: "5px" }}>
          <Table
            handleSortRequest={handleSortRequest}
            orderDirection={orderDirection}
            columns={columns}
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
            total={total}
            setSearch={setSearch}
            isFetching={isFetching}
            keyword={keyword}
            setKeyword={setKeyword}
            Buttons={Buttons}
            Rows={
              <Rows
                data={report}
                isFetching={isFetching}
                Row={ComplianceRow}
                columns={columns}
              />
            }
          ></Table>
        </Box>
      </ComplianceReportContext.Provider>
    </Box>
  );
};

export default ComplianceReport;
