import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useQueryClient } from "react-query";
import TableContainerPaginatedAPI from "../../component/CustomTableContainer/TableContainerPaginatedAPI";
import ReusableModal from "../../component/ReusableModal";
import SearchComponent from "../../component/SearchComponent";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { updateArray } from "../../helper/LeaveCredit";
import { toast } from "react-toastify";
import {
  addObject,
  addValue,
  clientCodeHeader,
  removeProperty,
  replaceLabel,
} from "../../helper/ClientCode";
import {
  useAddClientCode,
  useClientCode,
  useDeleteClientCode,
  useUpdateClientCode,
  useValidateClientCode,
} from "../../hooks/react-query/ClientCode";

const ClientCode = () => {
  const queryClient = useQueryClient();
  const [updatedData, setUpdatedData] = React.useState("");
  const [searched, setSearched] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [query, setQuery] = React.useState("");
  const [items, setItems] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [deleteThis, setDeleteThis] = React.useState("");
  const [openModal, setOpenModal] = React.useState({
    zone: "",
    type: "",
    open: false,
    title: "",
    confirmMessage: "",
    message: "",
  });
  const [defaultValues, setDefaultValues] = React.useState("");
  React.useEffect(() => {
    setUpdatedData("");
    setPage(0);
  }, [query]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };

  const { data: clientCode, isFetching: clientDataFetching } = useClientCode(
    query,
    page + 1,
    rowsPerPage
  );

  const clientCodeData =
    updatedData.length === 0 ? clientCode?.data : updatedData;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setUpdatedData("");
  };

  const onClickEditHandler = (data) => {
    setItems(addValue(data));
    setDefaultValues(addValue(data));
    setOpenModal({
      zone: "edit",
      type: "form",
      open: true,
      title: "Edit Client",
      confirmMessage: "Confirm",
      message: "",
    });
  };
  const onClickDeleteHandler = (data) => {
    setDeleteThis(data);
    setItems(addValue(data));
    setDefaultValues(addValue(data));
    setOpenModal({
      zone: "delete",
      type: "confirm",
      open: true,
      title: "Delete Client",
      confirmMessage: "Yes",
      message: "Are you sure to delete Client: " + data.company_name + "?",
    });
  };
  const onClickAddHandler = () => {
    setItems(addValue(""));
    setDefaultValues(addValue(""));
    setOpenModal({
      zone: "add",
      type: "form",
      open: true,
      title: "Add Client",
      confirmMessage: "Confirm",
      message: "",
    });
  };

  const { mutateAsync: updateClientCode } = useUpdateClientCode();

  const { mutateAsync: addClientCode } = useAddClientCode();

  const { mutateAsync: validateClientCode } = useValidateClientCode();

  const { mutateAsync: deleteClientCode } = useDeleteClientCode();

  const validatorFunc = (param, successFunc) =>
    validateClientCode(param, {
      onSuccess: async (data) => {
        if (data.status === "ok") {
          successFunc(param);
        }
      },
      onError: async (data) => {
        toast.error(data.response.data.message);
      },
    });

  const addItem = (data) => {
    addClientCode(data);
    toast.success("Successfully added new client: " + data.company_name);
  };

  const editItem = (data) =>
    updateClientCode(data, {
      onSuccess: async (data) => {
        setUpdatedData(updateArray(clientCodeData, data.data, "id"));
        await queryClient.setQueriesData("update-client-code", (oldData) => {
          oldData.data = updateArray(oldData.data, data.data, "id");
          return oldData;
        });
        toast.success("Successfully edited client: " + data.data.company_name);
      },
    });
  const submitForMutation = async (data) => {
    switch (openModal.zone) {
      case "edit":
        validatorFunc(data, editItem);
        break;
      case "add":
        validatorFunc(data, addItem);
        break;
      case "delete":
        const removeActionPropertyBeforeMutate = removeProperty(
          deleteThis,
          "action"
        );
        deleteClientCode(removeActionPropertyBeforeMutate, {
          onSuccess: async () => {
            await queryClient.refetchQueries(["get-client-code"]);
            setUpdatedData("");
            toast.success(
              "Successfully deleted client: " + deleteThis.company_name
            );
          },
        });
        break;
      default:
      // intentionally empty
    }
  };

  const addAction = (data) =>
    data.map((v) =>
      Object.assign(v, {
        action: (
          <>
            <Stack sx={{ display: "flex", flexDirection: "row" }}>
              <IconButton
                variant="contained"
                onClick={() => onClickEditHandler(v)}
              >
                <EditRoundedIcon />
              </IconButton>
              <IconButton
                variant="contained"
                onClick={() => onClickDeleteHandler(v)}
              >
                <DeleteRoundedIcon />
              </IconButton>
            </Stack>
          </>
        ),
      })
    );

  return (
    <Paper
      sx={{
        padding: 2,
        borderRadius: 5,
        marginLeft: 2,
        marginRight: 2,
        width: "97%",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          cursor: "context-menu",
        }}
      >
        <Tooltip title={"Manage company client code"} placement={"right"} arrow>
          <Typography
            variant="h5"
            component="h4"
            sx={{ padding: 1, marginBottom: 1 }}
          >
            Client Code
          </Typography>
        </Tooltip>
      </Box>{" "}
      <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
        <Stack direction="row" sx={{ flexGrow: 1 }}>
          <Button
            sx={{ width: "200px", backgroundColor: "#008a00" }}
            variant="contained"
            onClick={onClickAddHandler}
          >
            Add Client
          </Button>
        </Stack>
        <Stack direction="row" sx={{ alignItems: "flex-start" }}>
          <SearchComponent
            placeholder="Search client"
            setSearched={setSearched}
            searched={searched}
            setQuery={setQuery}
          />
        </Stack>
      </Stack>
      <TableContainerPaginatedAPI
        columnhead={addObject(clientCodeHeader, replaceLabel)}
        filtereditems={addAction(clientCodeData || Array(10).fill([""]))}
        isFetching={clientDataFetching}
        // setLeaveData={setLeaveData}
        // setOpenModal={setOpenModal}
        type="client-code"
      />
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={clientCode?.total || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showFirstButton
        showLastButton
      />
      <ReusableModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        items={items || []}
        prefillValues={defaultValues}
        submitForMutation={submitForMutation}
      />
    </Paper>
  );
};

export default ClientCode;
