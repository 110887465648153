import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

export default function SwitchComponent({ onAndoff }) {
  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch size="small" />}
        label="Table View"
        onChange={(e) => {
          onAndoff(e.target.checked);
        }}
        sx={{
          "& span.MuiTypography-root": {
            fontSize: "0.78rem",
          },
        }}
      />
    </FormGroup>
  );
}
