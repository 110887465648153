import { Fragment } from "react";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { downloadSampleCsv } from "../../../helper/UserLists";

const AVATAR_STYLES = { bgcolor: "transparent", marginRight: "8px" };

const ImportNotes = () => {
  const downloadSampleCsvHandler = (event) => {
    event.preventDefault();
    downloadSampleCsv();
  };

  return (
    <Box>
      <Typography sx={{ fontWeight: "600", color: "#495057" }}>
        Note: CSV format must be the same as the example below.
      </Typography>

      <List dense sx={{ display: "flex", flexDirection: "column" }}>
        <ListItem disablePadding>
          <Avatar sx={AVATAR_STYLES}>
            <PriorityHighIcon sx={{ color: "#e8590c" }} />
          </Avatar>

          <ListItemText
            primaryTypographyProps={{ fontSize: "16px" }}
            primary="The headers must be:"
            secondary='"First Name", "Last Name", "Middle Name", "Name Extension", "Email", "Gender", "Department", "WFH Required"'
          />
        </ListItem>

        <ListItem disablePadding>
          <Avatar sx={AVATAR_STYLES}>
            <FileDownloadIcon sx={{ color: "#4A90E2" }} />
          </Avatar>

          <ListItemText
            primary={
              <Fragment>
                <Typography sx={{ fontSize: "16px" }}>
                  You can download the sample CSV file{" "}
                  {
                    <a
                      href="/settings/users"
                      onClick={downloadSampleCsvHandler}
                      style={{ textDecoration: "none", color: "#4A90E2" }}
                    >
                      here
                    </a>
                  }
                  {"."}
                </Typography>
              </Fragment>
            }
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default ImportNotes;
