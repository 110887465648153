import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import "./wave.scss";
import { GlobalContext } from "../../App";

const waves = [
  "wave1",
  "wave2",
  "wave3",
  "wave4",
  "wave5",
  "wave6",
  "wave7",
  "wave8",
  "wave9",
  "wave10",
  "wave11",
  "wave12",
  "wave13",
  "wave14",
  "wave15",
  "wave16",
  "wave17",
  "wave18",
  "wave19",
  "wave20",
];
const Wave = () => {
  const { settings } = React.useContext(GlobalContext);

  const [imgUrl, setImgurl] = React.useState("");
  const [name, setName] = React.useState("");
  React.useEffect(() => {
    settings.map((st) => {
      if (st.setting === "company_name") {
        setName(st.value.split(""));
      }
      if (st.setting === "company_logo") {
        setImgurl(st.value);
      }
    });
  }, [settings]);

  const typoMaker = (letter, ind) => {
    if (ind > 19) return;
    return (
      <Grid item key={ind}>
        <Box component="span" className={`span-ani ${waves[ind]}`}>
          <Typography
            variant="overline"
            className="span-item"
            sx={{
              left: "9px",
              mt: "10px",
            }}
          >
            {letter}
          </Typography>
        </Box>
      </Grid>
    );
  };
  const boxWidth = () => {
    if (name?.length > 10 && name?.length <= 14) {
      return "500px";
    } else if (name?.length > 15) {
      return "640px";
    }
    return "300px";
  };
  return (
    <Box className="fligno-waves">
      <Box
        className="box-ani"
        sx={{
          width: boxWidth(),
        }}
      >
        <Grid container sx={{ justifyContent: "space-evenly" }}>
          <Grid item>
            <Box component="span" className="span-ani span-logo">
              <IconButton
                className="span-item"
                sx={{ left: "-7px", width: "40px", height: "40px" }}
              >
                <Avatar src={imgUrl} />
              </IconButton>
            </Box>
          </Grid>
          {name && name.map((cmp, index) => typoMaker(cmp, index))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Wave;
