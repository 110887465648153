import {
  FormControl,
  Paper,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
  Box,
} from "@mui/material";
const TableOptions = ({
  handleClick,
  options,
  handleActive,
  handleDepartment,
}) => {
  return (
    <Paper sx={{ p: 1, display: "flex", flexDirection: "row" }}>
      <Box>
        <Button
          sx={{ color: "white" }}
          size={"small"}
          variant="outlined"
          className="timest-button"
          onClick={handleClick}
        >
          Add Project Activity
        </Button>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row-reverse", flexGrow: 1 }}>
        <FormGroup>
          <FormControlLabel
            onClick={handleActive}
            control={<Switch />}
            label="Show Inactive"
          />
        </FormGroup>
        <FormControl sx={{ width: "175px", mr: 2 }}>
          <InputLabel id="department">Departments</InputLabel>
          <Select
            size="small"
            labelId="department"
            id="department-select"
            defaultValue={" "}
            label="Departments"
            onChange={(e) => {
              handleDepartment(e.target.value);
            }}
          >
            <MenuItem value={" "}>All</MenuItem>
            {options &&
              options.map((option, index) => (
                <MenuItem key={index} value={option.short_name}>
                  {option.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    </Paper>
  );
};

export default TableOptions;
