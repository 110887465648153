import React, { useEffect, useState } from "react";
import {
  Modal,
  Card,
  CardHeader,
  Typography,
  IconButton,
  Grow,
  CardContent,
  Grid,
  Box,
  CardActions,
  Button,
  TextField,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AutoCompleteDescription from "../CustomElement/AutoCompleteDescription";
import DocumentsAlert from "./DocumentsAlert";
import { useAddDocumentRequest } from "../../hooks/react-query/Requests/Document";

const selectDocuments = [
  {
    id: 1,
    name: "COE",
    desc: "Certificate of employment",
  },
  {
    id: 2,
    name: "BIR form 2316",
    desc: "BIR form 2316",
  },
];

const selectCompensationOrNo = [
  {
    id: 1,
    name: "Without Compensation",
  },
  {
    id: 2,
    name: "With Compensation",
  },
];

const initStateValue = {
  document: selectDocuments[0],
  compensation: selectCompensationOrNo[0],
  description: "",
};

const Documents = ({ documentOpen, handleDocument, edit }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { mutate, isLoading } = useAddDocumentRequest();
  const [data, setData] = useState(initStateValue);
  const [validation, setValidation] = useState(false);
  const [openALert, setOpenAlert] = useState(false);
  const handleChangeDocument = (e, value) => {
    setData({
      ...data,
      document: value,
      compensation: value.id === 1 ? selectCompensationOrNo[0] : null,
    });
  };
  const handleDesc = (e) => {
    setData({ ...data, description: e.target.value });
  };
  const handleCompensationOrNo = (e, value) => {
    setData({ ...data, compensation: value });
  };
  const handleOpenNote = () => {
    setOpenAlert(true);
  };
  const handleSubmit = () => {
    const document =
      data.document.id === 1
        ? `${data.document.name} (${data.compensation.name})`
        : data.document.name;

    const payload = {
      id: data?.id,
      document: document,
      description: data.description,
      emp: user.id,
    };
    mutate(payload, {
      onSuccess: (res) => {
        if (res.data.document === "BIR form 2316") {
          handleCLose();
        } else {
          handleOpenNote();
        }
      },
    });
  };
  const handleCLose = () => {
    setData(initStateValue);
    setOpenAlert(false);
    handleDocument();
  };
  useEffect(() => {
    if (edit?.isEdit) {
      const id = edit?.data?.id;
      const document = edit?.data?.document?.includes("COE")
        ? selectDocuments[0]
        : selectDocuments[1];
      const compensation = edit?.data?.document?.includes("Without")
        ? selectCompensationOrNo[0]
        : selectCompensationOrNo[1];
      const description = edit?.data?.description;
      const editState = { id, document, compensation, description };
      setData(editState);
    }
  }, [edit]);
  const disabledSubmit = isLoading || data?.description?.length < 30;
  const descHelperText =
    validation && data?.description?.length < 30
      ? "Description must be at least 30 characters"
      : null;
  return (
    <>
      <Modal open={documentOpen}>
        <Grow
          in={documentOpen}
          style={{ transformOrigin: "0 0 0" }}
          {...(documentOpen ? { timeout: 500 } : {})}
          unmountOnExit
        >
          <Card className="login-modal leave1-modal" sx={{ minWidth: "350px" }}>
            <CardHeader
              title={
                <Typography variant="h3" className="h3-bar">
                  Request Document
                </Typography>
              }
              action={
                <IconButton onClick={handleCLose}>
                  <CloseOutlinedIcon sx={{ width: "15px", height: "15px" }} />
                </IconButton>
              }
            />
            <CardContent sx={{ margin: "10px" }}>
              <Grid container rowSpacing={3} columnSpacing={1}>
                <Grid item xs={12} lg={8}>
                  <Box className="typ-over">
                    <Typography
                      variant="overline"
                      sx={{ paddingLeft: "10px", alignSelf: "center" }}
                    >
                      Select Documents
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={20}>
                  <AutoCompleteDescription
                    data={{ id: "id", name: "name", desc: "desc" }}
                    optionData={selectDocuments}
                    value={data.document}
                    onChange={handleChangeDocument}
                    removeClearBtn={true}
                  />
                </Grid>

                {data.document?.id === 1 ? (
                  <Grid item xs={20}>
                    <AutoCompleteDescription
                      data={{ id: "id", name: "name", desc: "desc" }}
                      optionData={selectCompensationOrNo}
                      value={data.compensation}
                      onChange={handleCompensationOrNo}
                      removeClearBtn={true}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={20}>
                  <TextField
                    variant="outlined"
                    label={"Description"}
                    fullWidth
                    multiline
                    rows={4}
                    onChange={handleDesc}
                    defaultValue={data.description}
                    onFocus={() => setValidation(true)}
                    onBlur={() => setValidation(false)}
                    helperText={descHelperText}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                className="adjust-w cancel-button"
                disableElevation
                variant="contained"
                sx={{ marginLeft: "auto", minWidth: "100px" }}
                onClick={handleCLose}
              >
                Cancel
              </Button>
              <Button
                size="small"
                className="adjust-w timest-button"
                disableElevation
                variant="contained"
                type="submit"
                sx={{ minWidth: "100px", mr: "17px" }}
                onClick={handleSubmit}
                disabled={disabledSubmit}
              >
                Submit
              </Button>
            </CardActions>
          </Card>
        </Grow>
      </Modal>
      <DocumentsAlert openALert={openALert} handleClose={handleCLose} />
    </>
  );
};

export default Documents;
