import React from "react";
import { Button, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const CancelSubmitButton = ({
  handleClose,
  handleSubmit,
  Disabled,
  Loading,
}) => {
  return (
    <Grid container sx={{ justifyContent: "end" }} spacing={3}>
      <Grid item>
        <Button
          className="adjust-w cancel-button"
          variant="contained"
          sx={{ padding: "1px 10px 1px 10px" }}
          onClick={handleClose}
        >
          {" "}
          Cancel
        </Button>
      </Grid>
      <Grid item>
        <Button
          className="adjust-w timest-button"
          variant="contained"
          sx={{ padding: "1px 15px 1px 15px" }}
          onClick={handleSubmit}
          disabled={Loading || Disabled}
          endIcon={Loading ? <CircularProgress size={20} /> : <></>}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default CancelSubmitButton;
