import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import IconButton from "@mui/material/IconButton";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/base/ClickAwayListener";

const { format, getDay } = require("date-fns");

const isWeekend = (date) => {
  const day = getDay(date);

  return day === 0 || day === 6;
};

const DateComp = () => {
  const [value, setValue] = React.useState(new Date());

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const popOpen = Boolean(anchorEl);

  const handleClickAway = () => {
    setAnchorEl(null);
  };
  return (
    <Card className="date-card">
      <CardContent>
        <Box className="date-box">
          <Box>
            <Typography variant="h4" className="date-h4">
              Month
            </Typography>
          </Box>
          <Box className="date-box">
            <IconButton className="date-button" onClick={handleClick}>
              <CalendarMonthOutlinedIcon />
            </IconButton>
            <Popper
              placement="bottom-end"
              open={popOpen}
              anchorEl={anchorEl}
              className="poper-pop"
            >
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box
                  sx={{
                    p: "0px 5px 0px",
                    bgcolor: "background.paper",
                    borderRadius: "10px",
                    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
                    "& div.MuiDialogActions-root": {
                      display: "none",
                    },
                    "& button.MuiPickersToolbar-penIconButton": {
                      display: "none",
                    },
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <StaticDatePicker
                      orientation="landscape"
                      openTo="day"
                      value={value}
                      shouldDisableDate={isWeekend}
                      onChange={(newValue) => {
                        setValue(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Box>
              </ClickAwayListener>
            </Popper>
          </Box>
        </Box>
        <Typography variant="h1" className="date-h1">
          {format(new Date(), "LLL dd, yyyy")}
        </Typography>
        <Typography variant="h6" className="date-h6">
          {format(new Date(), "EEEE")}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default DateComp;
