import React from "react";
import { Paper, Table, TableContainer } from "@mui/material";

const TableContainter = ({ children, style }) => {
  return (
    <TableContainer component={Paper} sx={{ ...style }}>
      <Table size="small">{children}</Table>
    </TableContainer>
  );
};

export default TableContainter;
