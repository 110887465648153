import React from "react";
import { TableRow, TableCell } from "@mui/material";
import moment from "moment";

const UserPerTimesheetLists = ({ item }) => {
  return (
    <>
      <TableRow>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: moment(item.date).format("MM-DD-YYYY"),
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.comment,
            }}
          />
        </TableCell>
        <TableCell align={"right"}>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.hours,
            }}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default UserPerTimesheetLists;
