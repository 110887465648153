import { Fragment, memo, useEffect } from "react";

import Box from "@mui/material/Box";

import {
  useTimesheetWeeklyReport,
  useTimesheetWeeklyReportPagination,
} from "../../../hooks/react-query/Evaluation/TimesheetWeeklyReport";
import TimesheetWeeklyReportContainer from "../../../component/Evaluation/TimesheetWeeklyReport/container/TimesheetWeeklyReportContainer";
import Title from "../../../component/Evaluation/TimesheetWeeklyReport/header/Title";
import ActionBar from "../../../component/Evaluation/TimesheetWeeklyReport/header/ActionBar";
import TimesheetWeeklyReportTableContainer from "../../../component/Evaluation/TimesheetWeeklyReport/container/TimesheetWeeklyReportTableContainer";
import TimesheetWeeklyReportTableRow from "../../../component/Evaluation/TimesheetWeeklyReport/table/TimesheetWeeklyReportTableRow";
import TimesheetWeeklyReportError from "../../../component/Evaluation/TimesheetWeeklyReport/error/TimesheetWeeklyReportError";
import NoEmployeeTableCell from "../../../component/Evaluation/TimesheetWeeklyReport/table/NoEmployeeTableCell";
import LoadingTableCell from "../../../component/Evaluation/TimesheetWeeklyReport/table/LoadingTableCell";

const TABLE_WRAPPER_STYLES = {
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  backgroundColor: "#FFF",
  borderRadius: "12px",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.05)",
};

const TimesheetWeeklyReport = () => {
  const {
    data: timesheetWeeklyReportQuery,
    employeeId,
    handleChangeEmployeeId,
  } = useTimesheetWeeklyReport();

  const {
    skip,
    limit,
    maxLimit,
    remainder,
    isDecrementEnabled,
    isIncrementEnabled,
    handleDecrementSkip,
    handleIncrementSkip,
    handleResetDefaultSkip,
    handleChangeLimit,
  } = useTimesheetWeeklyReportPagination();

  useEffect(() => {
    handleResetDefaultSkip();
  }, [employeeId, handleResetDefaultSkip]);

  const { status, isLoading, isError, data } = timesheetWeeklyReportQuery;

  return (
    <TimesheetWeeklyReportContainer>
      <Title />

      {isError && <TimesheetWeeklyReportError />}

      {!isError && (
        <Fragment>
          <ActionBar
            queryData={data}
            handleChangeEmployeeId={handleChangeEmployeeId}
            skip={skip}
            limit={limit}
            maxLimit={maxLimit}
            remainder={remainder}
            isDecrementEnabled={isDecrementEnabled}
            isIncrementEnabled={isIncrementEnabled}
            handleDecrementSkip={handleDecrementSkip}
            handleIncrementSkip={handleIncrementSkip}
            handleChangeLimit={handleChangeLimit}
          />

          <Box sx={TABLE_WRAPPER_STYLES}>
            <TimesheetWeeklyReportTableContainer
              skip={skip}
              limit={limit}
              maxLimit={maxLimit}
              remainder={remainder}
            >
              {status === "success" &&
                data.years.map((year) => (
                  <TimesheetWeeklyReportTableRow
                    key={year}
                    year={year}
                    data={data}
                    skip={skip}
                    limit={limit}
                  />
                ))}

              {!employeeId && <NoEmployeeTableCell />}

              {isLoading && <LoadingTableCell />}
            </TimesheetWeeklyReportTableContainer>
          </Box>
        </Fragment>
      )}
    </TimesheetWeeklyReportContainer>
  );
};

export default memo(TimesheetWeeklyReport);
