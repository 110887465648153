import React, { useState } from "react";
import { Tooltip, IconButton, CircularProgress } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useRemoveReminder } from "../../../hooks/react-query/SettingsTimesheet/EmployeeToEmailTimesheetReport";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const Actions = ({ val, ind }) => {
  const { mutate: remove, isLoading } = useRemoveReminder();
  const [delInd, setDelInd] = useState(null);

  const handleConfiramtion = () => {
    setDelInd(ind);
  };
  const handleCloseConfirmation = () => {
    setDelInd(null);
  };

  const handleRemove = () => {
    remove(
      { id: val.id },
      {
        onSuccess: () => setDelInd(null),
      }
    );
  };
  return delInd === ind ? (
    isLoading ? (
      <CircularProgress />
    ) : (
      <>
        <Tooltip title="Cancel remove employee">
          <IconButton onClick={handleCloseConfirmation} size="small">
            <CloseIcon fontSize="small" sx={{ color: "red" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Continue remove employee?">
          <IconButton
            onClick={handleRemove}
            size="small"
            sx={{ color: "green" }}
          >
            <CheckIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </>
    )
  ) : (
    <Tooltip title="Remove employee">
      <IconButton onClick={handleConfiramtion} size="small">
        <DeleteIcon
          sx={isLoading ? { color: "grey" } : { color: "red" }}
          fontSize="small"
        />
      </IconButton>
    </Tooltip>
  );
};

export default Actions;
