import { Paper, Button, Box, Typography } from "@mui/material";

const TableOptions = ({ Ranges, handleRange, handleAddForm }) => {
  // const { endDate, startDate, setEndDate, setStartDate } =
  //   useContext(WfhTemplateContext);
  return (
    <Paper
      sx={{
        borderRadius: "7px",
        mt: 1.5,
        px: 1,
        py: 1,
        flexWrap: "wrap",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box>
        <Button
          sx={{ m: 1, backgroundColor: "#00c292" }}
          onClick={(e) => {
            handleAddForm();
          }}
          variant="contained"
        >
          <Typography sx={{ fontSize: "0.78rem" }}>Add Form</Typography>
        </Button>
      </Box>
    </Paper>
  );
};

export default TableOptions;
