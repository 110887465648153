import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import WfhDropzone from "./wfhDropzone";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import FormHelperText from "@mui/material/FormHelperText";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import { Controller } from "react-hook-form";
import { isAfter } from "date-fns";

const WfhForm = ({
  imgUrl,
  open,
  open2,
  open3,
  open4,
  setOpen,
  setOpen2,
  setOpen3,
  setOpen4,
  handleNext,
  handleBack,
  handleSubmit,
  control,
  errors,
  onSubmit,
  setValue,
  getValues,
}) => {
  const onClose = () => {
    setOpen(false);
  };
  const onClose2 = () => {
    setOpen2(false);
  };
  const onClose3 = () => {
    setOpen3(false);
  };
  const onClose4 = () => {
    setOpen4(false);
  };
  const handleDelte = () => {
    imgUrl.current = [];
    setOpen3(true);
  };

  const indiDelete = (indexParam) => {
    imgUrl.current.splice(indexParam, 1);
    setOpen4(true);
  };

  const formHelpers = (name) => (
    <FormHelperText
      error
      sx={{
        fontSize: "0.6rem",
        position: "absolute",
        top: 0,
        right: 0,
      }}
    >
      {name}
    </FormHelperText>
  );
  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item xs={12} lg={4}>
            <Box className="typ-over">
              <Typography
                variant="overline"
                sx={{ paddingLeft: "10px", alignSelf: "center" }}
              >
                Select Date
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} lg={4}>
            <Controller
              control={control}
              name="startDate"
              render={({ field: { onChange, ...field } }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    {...field}
                    label="Start date"
                    onChange={(event) => {
                      onChange(event);
                      setValue("endDate", event);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={6} lg={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Controller
                control={control}
                name="endDate"
                render={({ field: { onChange, ...field } }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      {...field}
                      label="End date"
                      onChange={(event) => {
                        onChange(event);

                        const start = getValues("startDate");
                        const end = getValues("endDate");

                        if (isAfter(start, end)) setValue("startDate", end);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sx={{ position: "relative" }}>
            {errors.purpose?.type === "required" &&
              formHelpers("Please state your purpose")}
            <Controller
              name="purpose"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Purpose"
                  multiline
                  rows={4}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sx={{ position: "relative" }}>
            {errors.workplace?.type === "required" &&
              formHelpers("Please describe your workplace")}
            <Controller
              name="workplace"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Description of workplace"
                  multiline
                  rows={4}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Button
              fullWidth
              variant="contained"
              disableElevation
              disabled={imgUrl.current.length > 0 ? false : true}
              startIcon={<DeleteRoundedIcon />}
              sx={{
                textTransform: "none",
              }}
              onClick={handleDelte}
            >
              Delete all images
            </Button>
            <Box
              sx={{
                "> p": {
                  fontSize: "0.7rem",
                  color: "#C05621",
                  mt: 1,
                },
              }}
            >
              <Typography sx={{ mt: "15px !important" }}>Note:</Typography>
              <Typography>
                <CircleRoundedIcon fontSize="0.5rem" />
                &nbsp;To upload Images/Files, click the "Drag or drop
                images/files or Click to upload" and select the images you wish
                to upload or drag and drop your images.
              </Typography>
              <Typography>
                <CircleRoundedIcon fontSize="0.5rem" />
                &nbsp;Upload atleast one image to show your workplace at home.
              </Typography>
              <Typography>
                <CircleRoundedIcon fontSize="0.5rem" />
                &nbsp;File size up to 5mb
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <WfhDropzone
              setOpen={setOpen}
              setOpen2={setOpen2}
              imgUrl={imgUrl}
            />
          </Grid>
          {imgUrl.current.length > 0 &&
            imgUrl.current.map((imgrl, index) => (
              <Grid
                item
                xs={12}
                lg={6}
                key={index}
                sx={{ position: "relative" }}
              >
                <IconButton
                  sx={{
                    position: "absolute",
                    color: "#fff",
                    bgcolor: "red",
                    mt: "-10px",
                    mr: "-10px",
                    zIndex: 1,
                    right: 0,
                  }}
                  onClick={() => {
                    indiDelete(index);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
                <Avatar
                  src={imgrl}
                  sx={{
                    height: "235px",
                    width: "100%",
                    borderRadius: "5px",
                  }}
                />
              </Grid>
            ))}
        </Grid>

        {open && (
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            open={true}
            autoHideDuration={2500}
            onClose={onClose}
          >
            <Alert
              onClose={onClose}
              elevation={6}
              variant="filled"
              severity="error"
              sx={{ width: "100%" }}
            >
              <AlertTitle>Error</AlertTitle>
              {open}
            </Alert>
          </Snackbar>
        )}
        {open2 && (
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            open={true}
            autoHideDuration={1000}
            onClose={onClose2}
          >
            <Alert
              onClose={onClose2}
              elevation={6}
              variant="filled"
              severity="success"
              sx={{ width: "100%" }}
            >
              <AlertTitle>Success</AlertTitle>
              Successfully uploaded file.
            </Alert>
          </Snackbar>
        )}
        {open3 && (
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            open={true}
            autoHideDuration={2500}
            onClose={onClose3}
          >
            <Alert
              onClose={onClose3}
              elevation={6}
              variant="filled"
              severity="success"
              sx={{ width: "100%" }}
            >
              <AlertTitle>Success</AlertTitle>
              Successfully deleted all file
            </Alert>
          </Snackbar>
        )}
        {open4 && (
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            open={true}
            autoHideDuration={2500}
            onClose={onClose4}
          >
            <Alert
              onClose={onClose4}
              elevation={6}
              variant="filled"
              severity="success"
              sx={{ width: "100%" }}
            >
              <AlertTitle>Success</AlertTitle>
              Successfully deleted file
            </Alert>
          </Snackbar>
        )}
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button color="inherit" onClick={handleBack}>
            Back
          </Button>
          <Button
            variant="contained"
            disableElevation
            disabled={imgUrl.current.length > 0 ? false : true}
            type="submit"
          >
            Proceed
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default WfhForm;
