import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

export const downloadFile = ({ data, fileName, fileType }) => {
  const blob = new Blob([data], { type: fileType });

  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);

  const clickEvent = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  a.dispatchEvent(clickEvent);
  a.remove();
};

export const downloadCsv = (usersData) => {
  const headers = [
    "Last Name, First Name,Expected Hours,Hours Rendered,Times Unable to Comply",
  ];

  const content = usersData.map((data) =>
    [
      data?.name || "-",
      data?.expected || "-",
      data?.hours || "-",
      data?.week || "-",
    ].join(",")
  );

  const csvData = [...headers, ...content].join("\n");

  downloadFile({
    data: csvData,
    fileName: "compliance.csv",
    fileType: "text/csv",
  });
};

export const downloadPdf = (usersData) => {
  const head = [
    ["Name", "Expected Hours", "Hours Rendered", "Times Unable to Comply"],
  ];

  const body = usersData.map((user) => [
    user.name || "-",
    user.expected || "-",
    user.hours || "-",
    user.week || "-",
  ]);

  const doc = new jsPDF("landscape");

  autoTable(doc, { head, body });

  doc.save("compliance.pdf");
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const generateErrorObj = (error) => {
  const status = error.response.status;

  if (status === 422) {
    return {
      title: Object.entries(error.response.data)[0][0],
      message: Object.entries(error.response.data)[0][1],
    };
  }

  return { title: null, message: "An error occured!" };
};
