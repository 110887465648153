import { Fragment } from "react";

import Skeleton from "@mui/material/Skeleton";

import StyledTableCell from "../tableComponents/StyledTableCell";
import {
  generateRandomNum,
  getApplicationType,
  generateSkeletonLoader,
} from "../../../../helper/UserApplicationMgmt/UserApplications";

const PurposeCellSkeleton = ({ type }) => {
  const { isLeave, isOvertime, isWfh } = getApplicationType(type);

  return (
    <StyledTableCell sx={{ verticalAlign: "top" }}>
      <Fragment>
        {generateSkeletonLoader(
          generateRandomNum(1, 4),
          <Skeleton variant="text" width="30ch" sx={{ fontSize: "20px" }} />
        )}

        {(isOvertime || isWfh) && (
          <Skeleton
            variant="text"
            width={generateRandomNum(32, 120)}
            sx={{ fontSize: "20px" }}
          />
        )}

        {isLeave && (
          <Fragment>
            <Skeleton variant="text" width="30ch" sx={{ fontSize: "20px" }} />

            <Skeleton
              variant="text"
              width={generateRandomNum(32, 300)}
              sx={{ fontSize: "20px" }}
            />
          </Fragment>
        )}
      </Fragment>
    </StyledTableCell>
  );
};

export default PurposeCellSkeleton;
