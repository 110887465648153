import apiService from "../axios";

export const visit = async (url) => {
  try {
    let response = await apiService.get(`v5/customer-visit/get-all` + url, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(url),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addVisit = async (data) => {
  try {
    await apiService.post(`v1/customer-visit/add`, data, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(data),
    });
  } catch (error) {
    throw error;
  }
};

export const updateVisit = async (data) => {
  try {
    await apiService.post(`v1/customer-visit/add`, data, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(data),
    });
  } catch (error) {
    throw error;
  }
};

export const deleteVisit = async (data) => {
  try {
    await apiService.post(`v1/customer-visit/delete`, data, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(data),
    });
  } catch (error) {
    throw error;
  }
};

export const getTree = async (data) => {
  try {
    let response = await apiService.post(`v1/customer-visit/get-tree`, data, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(data),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
