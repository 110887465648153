import React, { useEffect } from "react";
import { IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const EditActionIcon = ({
  HandleEditData,
  data,
  editInput,
  CloseEditMode,
  setEdit,
}) => {
  useEffect(() => {
    setEdit({
      ...editInput,
      name: data.name === null ? "" : data.name,
      short_name: data.short_name === null ? "" : data.short_name,
      color: data.color,
      description: data.description === null ? "" : data.description,
      status: data.status,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <IconButton
        size="small"
        onClick={() => HandleEditData(data.id)}
        sx={{ color: "#61a644" }}
        disabled={
          (editInput.name === data.name &&
            editInput.short_name === data.short_name &&
            editInput.color === data.color &&
            editInput.description === data.description &&
            editInput.status === data.status) ||
          editInput.name === "" ||
          editInput.short_name === ""
        }
      >
        <CheckIcon fontSize="small" />
      </IconButton>
      <IconButton
        size="small"
        onClick={CloseEditMode}
        sx={{ color: "rgb(228, 106, 118)" }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );
};

export default EditActionIcon;
