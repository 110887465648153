import React from "react";
import { Typography, Divider, Box } from "@mui/material";
import { TreeView as MuiTreeView } from "@mui/lab";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import TreeItem from "@mui/lab/TreeItem";
import FolderIcon from "@mui/icons-material/Folder";
import { grey } from "@mui/material/colors";

const TreeView = ({ header, tree, selectNode, type }) => {
  return (
    <>
      <Box p={1}>
        <Typography variant="h7">{header}</Typography>
      </Box>
      <Divider />
      <MuiTreeView
        onNodeSelect={(e) => {}}
        aria-label="multi-select"
        defaultCollapseIcon={<FolderIcon />}
        defaultExpandIcon={<FolderOpenIcon />}
        sx={{ pt: 3, pb: 6 }}
        defaultSelected="-1"
      >
        <TreeItem
          nodeId={-1 + ""}
          label="All"
          onClick={(e) => selectNode(e, "", type, "All")}
        ></TreeItem>
        {tree &&
          tree?.map((row, index) => (
            <TreeItem
              key={index}
              nodeId={row.id + ""}
              label={<Typography sx={{ fontSize: 16 }}>{row?.text}</Typography>}
              onClick={(e) => selectNode(e, row.tags, type, row?.text)}
            >
              {row.nodes?.map((node, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    pb: row?.nodes?.length === index + 1 ? "12px" : "0px",
                  }}
                >
                  <Box
                    sx={{
                      width: "20px",
                      borderBottom: 2,
                      borderLeft: 2,
                      borderColor: grey[300],
                    }}
                  ></Box>

                  <TreeItem
                    sx={{
                      ml: "-29px",
                      width: "100%",
                      position: "relative",
                      top: "12px",
                    }}
                    nodeId={node.id + ""}
                    label={
                      <Typography sx={{ fontSize: 16 }}>{node.text}</Typography>
                    }
                    onClick={(e) => selectNode(e, node.tags, type, row?.text)}
                  ></TreeItem>
                </Box>
              ))}
            </TreeItem>
          ))}
      </MuiTreeView>
    </>
  );
};

export default TreeView;
