import { Fragment } from "react";

import StyledTableCell from "../StyledTableCell";
import TitleTypography from "../TitleTypography";
import DetailsTypography from "../DetailsTypography";
import { getApplicationType } from "../../../../../helper/UserApplicationMgmt/UserApplications";

const NameCell = ({ type, name, leaveType, leaveCredits, overTimeType }) => {
  const { isLeave, isOvertime, isWfh } = getApplicationType(type);

  return (
    <StyledTableCell sx={{ verticalAlign: "top" }}>
      <TitleTypography>{name}</TitleTypography>

      {isLeave && (
        <Fragment>
          <DetailsTypography>{leaveType}</DetailsTypography>
          <TitleTypography>(Leave) Credits</TitleTypography>
          <DetailsTypography>{leaveCredits}</DetailsTypography>
        </Fragment>
      )}

      {isOvertime && (
        <DetailsTypography sx={{ textTransform: "capitalize" }}>
          {overTimeType} overtime
        </DetailsTypography>
      )}

      {isWfh && <DetailsTypography>Work From Home</DetailsTypography>}
    </StyledTableCell>
  );
};

export default NameCell;
