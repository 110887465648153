import React from "react";
import TabSelection from "../../../component/CustomTab/TabSelection";
import { Box, LinearProgress, Stack, TablePagination } from "@mui/material";
import CustomSearch from "../../../component/SearchComponent";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import TabPanels from "../../../component/CustomTab/TabPanels";
import TableContainerPaginatedAPI from "../../../component/CustomTableContainer/TableContainerPaginatedAPI";
import permission from "../../../hooks/react-query/Permission";
import { useQueryClient } from "react-query";
import {
  addObject,
  firstObj,
  note,
  tabList,
} from "../../../helper/Permissions";
import { toast } from "react-toastify";

TabPanels.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  selectedTab: PropTypes.number.isRequired,
};

const Permission = () => {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searched, setSearched] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [query, setQuery] = React.useState("");
  const [updatedData, setUpdatedData] = React.useState("");

  // mutation
  const { mutateAsync: updateUserPermission } =
    permission.useUpdateUserPermission();
  const updateUserRole = permission.useUpdateUserRole();
  const updateRolePermission = permission.useUpdateRolePermission();
  //usequery call
  const { data: permissionHead } = permission.usePermissionHead();
  const {
    data: userPermissions,
    isFetching: userPermFetching,
    refetch: userPermissionRefetch,
  } = permission.useUserPermission(page + 1, rowsPerPage, query, selectedTab);

  const { data: roleHead } = permission.useRoleHead();
  const { data: userRole, isFetching: userRoleFetching } =
    permission.useUserRole(page + 1, rowsPerPage, query, selectedTab);
  const { data: rolePermission, isFetching: rolePermFetching } =
    permission.useRolePermission(page + 1, rowsPerPage, query, selectedTab);

  //items passed to table
  const userPermissionList =
    updatedData.length === 0 ? userPermissions?.data : updatedData;
  const userRoleList = updatedData.length === 0 ? userRole?.data : updatedData;
  const rolePermissionList =
    updatedData.length === 0 ? rolePermission?.data : updatedData;

  const permissionHeader = permissionHead?.data;

  // reset page on tab change
  React.useEffect(() => {
    setPage(0);
    setUpdatedData("");
  }, [selectedTab]);

  React.useEffect(() => {
    if (query.length > 2) setPage(0);
    if (query.length === 0) setUpdatedData("");
  }, [query]);

  const updateArray = (data, arrayToUpdate, ref_id) =>
    data.map(
      (obj) => [arrayToUpdate].find((o) => o[ref_id] === obj[ref_id]) || obj
    );

  const onClickIcon = async (data, column, value) => {
    switch (selectedTab) {
      case 0:
        await updateUserPermission(
          {
            id: data.employee_id,
            permission: column,
            value: !value,
          },
          {
            onSuccess: async (data) => {
              setUpdatedData(
                updateArray(userPermissionList, data.data, "employee_id")
              );
              queryClient.setQueriesData("user-permission", (oldData) => {
                oldData.data = updateArray(
                  oldData.data,
                  data.data,
                  "employee_id"
                );
                return oldData;
              });

              let myObject = data.data;

              let isMatchFound = userPermissionList.some(
                (obj) =>
                  obj.employee_id === myObject.employee_id &&
                  obj.permissions.length === myObject.permissions.length
              );

              if (isMatchFound) {
                toast.error("Permission Cannot be Revoked", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              } else {
                toast.success("Permission updated for user", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              }
            },
          }
        );
        break;
      case 1:
        await updateUserRole.mutateAsync(
          {
            id: data.employee_id,
            role: column,
            value: !value,
          },
          {
            onSuccess: async (data) => {
              setUpdatedData(
                updateArray(userRoleList, data.roles, "employee_id")
              );
              queryClient.setQueriesData("user-role", (oldData) => {
                oldData.data = updateArray(
                  oldData.data,
                  data.roles,
                  "employee_id"
                );
                return oldData;
              });
            },
          }
        );
        userPermissionRefetch();
        break;
      case 2:
        await updateRolePermission.mutateAsync(
          {
            id: data.role_id,
            permission: column,
            value: !value,
          },
          {
            onSuccess: async (data) => {
              setUpdatedData(
                updateArray(rolePermissionList, data.role, "role_id")
              );
              queryClient.setQueriesData("role-permission", (oldData) => {
                oldData.data = updateArray(oldData.data, data.role, "role_id");
                return oldData;
              });
            },
          }
        );
        userPermissionRefetch();
        break;

      default:
      // intenationally empty
    }
  };

  const handleChangePage = (event, newPage) => {
    setUpdatedData("");
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    queryClient.invalidateQueries([
      "user-permission",
      "user-role",
      "role-permission",
    ]);
    setPage(0);
    setUpdatedData("");
  };

  return (
    <Box
      style={{
        backgroundColor: "white",
        padding: 10,
        borderRadius: 20,
        marginLeft: "1rem",
        marginRight: 2,
        width: "97%",
        overflow: "hidden",
      }}
    >
      <TabSelection
        tabList={tabList}
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
      />
      {(userPermFetching || userRoleFetching || rolePermFetching) && (
        <LinearProgress sx={{ width: "97%", marginLeft: 2 }} />
      )}
      {/* First  Tab */}
      <TabPanels selectedTab={selectedTab} index={0} dir={theme.direction}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <Stack direction="row" sx={{ flexGrow: 1 }}>
            {note}
          </Stack>
          <Stack direction="row" sx={{ alignItems: "flex-start" }}>
            <CustomSearch
              placeholder="Search user"
              setSearched={setSearched}
              searched={searched}
              setQuery={setQuery}
            />
          </Stack>
        </Stack>
        <TableContainerPaginatedAPI
          columnhead={[firstObj].concat(addObject(permissionHeader))}
          filtereditems={userPermissionList || Array(10).fill([""])}
          onClickIcon={onClickIcon}
          isFetching={userPermFetching}
          type="permissions"
        />
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={userPermissions?.total || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
        />
      </TabPanels>
      {/* Second Tab */}
      <TabPanels selectedTab={selectedTab} index={1} dir={theme.direction}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <Stack direction="row" sx={{ flexGrow: 1 }}></Stack>
          <Stack direction="row" sx={{ alignItems: "flex-start" }}>
            <CustomSearch
              placeholder="Search user"
              setSearched={setSearched}
              searched={searched}
              setQuery={setQuery}
            />
          </Stack>
        </Stack>
        <TableContainerPaginatedAPI
          columnhead={[firstObj].concat(addObject(roleHead?.data))}
          filtereditems={userRoleList || Array(10).fill([""])}
          onClickIcon={onClickIcon}
          isFetching={userRoleFetching}
          type="roles"
        />
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={userRole?.total || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
        />
      </TabPanels>
      {/* Third Tab */}
      <TabPanels selectedTab={selectedTab} index={2} dir={theme.direction}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <Stack direction="row" sx={{ flexGrow: 1 }}></Stack>
          <Stack direction="row" sx={{ alignItems: "flex-start" }}>
            <CustomSearch
              placeholder="Search role from list"
              setSearched={setSearched}
              searched={searched}
              setQuery={setQuery}
            />
          </Stack>
        </Stack>
        <TableContainerPaginatedAPI
          columnhead={[firstObj].concat(addObject(permissionHeader))}
          filtereditems={rolePermissionList || Array(10).fill([""])}
          onClickIcon={onClickIcon}
          isFetching={rolePermFetching}
          type="permissions"
        />
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={rolePermissionList?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
        />
      </TabPanels>
    </Box>
  );
};

export default Permission;
