import { Box, TablePagination, Tooltip, Typography } from "@mui/material";
import React from "react";
import moment from "moment";
import TableContainerPaginatedAPI from "../../component/CustomTableContainer/TableContainerPaginatedAPI";
import InputElements from "./InputElements";
import {
  auditLogHeader,
  addObject,
  replaceLabel,
} from "../../helper/AuditLogs";
import { usePostAuditLogs } from "../../hooks/react-query/AuditLogs";

const WFHSummary = () => {
  const [searched, setSearched] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [range, setRange] = React.useState({
    startDate: moment().startOf("week"),
    endDate: moment().endOf("week"),
  });
  const [query, setQuery] = React.useState("");

  React.useMemo(
    () => setPage(0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query, rowsPerPage]
  );

  let payload = {
    search: query,
    to: moment(range.endDate).format("YYYY-MM-DD"),
    from: moment(range.startDate).format("YYYY-MM-DD"),
    page: page + 1,
    per_page: rowsPerPage,
  };

  const {
    refetch: refreshFunction,
    data: auditLogData,
    isFetching: auditLogFetching,
  } = usePostAuditLogs(payload);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };
  return (
    <Box
      style={{
        backgroundColor: "white",
        padding: 10,
        borderRadius: 20,
        marginLeft: "1rem",
        marginRight: 2,
        width: "97%",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          cursor: "context-menu",
        }}
      >
        <Tooltip title="View company audit log" placement={"right"} arrow>
          <Typography
            variant="h5"
            component="h4"
            sx={{ padding: 1, marginBottom: 1 }}
          >
            Audit Log
          </Typography>
        </Tooltip>
      </Box>
      <InputElements
        refreshFunction={refreshFunction}
        searched={searched}
        setRange={setRange}
        setSearched={setSearched}
        range={range}
        setQuery={setQuery}
      />
      <TableContainerPaginatedAPI
        columnhead={addObject(auditLogHeader, replaceLabel)}
        filtereditems={auditLogData?.data || Array(10).fill([""])}
        isFetching={auditLogFetching}
        type="audit-logs"
      />
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={auditLogData?.total || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showFirstButton
        showLastButton
      />
    </Box>
  );
};

export default WFHSummary;
