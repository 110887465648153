import React from "react";
import { Modal, Card, CardContent, Grow, CardActions } from "@mui/material";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
const Confirm = ({
  childOpen,
  url,
  handleConfirm,
  handleClose,
  message,
  title,
  submitting,
}) => {
  return (
    <Modal open={childOpen} onClose={handleClose}>
      <Grow
        in={childOpen}
        style={{ transformOrigin: "0 0 0" }}
        {...(childOpen ? { timeout: 500 } : {})}
        unmountOnExit
      >
        <Card className="login-modal">
          <CardContent>{message}</CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton
              loading={submitting}
              size="small"
              onClick={handleConfirm}
              sx={{ textTransform: "none", px: "16px", py: "6px" }}
            >
              Confirm
            </LoadingButton>
            <Button
              size="small"
              variant="contained"
              sx={{
                "&:hover": { bgcolor: "#ca5764" },
                bgcolor: "#D06A76",
                textTransform: "none",
                px: "16px",
                py: "6px",
              }}
              onClick={handleClose}
              autoFocus
            >
              Cancel
            </Button>
          </CardActions>
        </Card>
      </Grow>
    </Modal>
  );
};

export default Confirm;
