import { IconButton } from "@mui/material";
import React from "react";
import TableCell from "@mui/material/TableCell";
import { Box } from "@mui/system";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import CircularProgress from "@mui/material/CircularProgress";

const TableBodyDataOption = ({
  status,
  colId,
  rowId,
  rowType,
  deleteFunc,
  notLoadingDelete,
  toDeleteData,
  handleOpenEdit,
  editType,
  editConverted,
  editFrom,
  editTo,
  editWorkplace,
  editPurpose,
  leaveType,
}) => {
  return (
    <TableCell key={colId}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {notLoadingDelete || toDeleteData !== rowId ? (
          <>
            <IconButton
              aria-label="edit"
              color={"success"}
              onClick={() =>
                handleOpenEdit(
                  rowId,
                  editType,
                  editConverted,
                  editFrom,
                  editTo,
                  editWorkplace,
                  editPurpose,
                  leaveType
                )
              }
              disabled={
                status?.toLowerCase() === "approved" ||
                status?.toLowerCase() === "disapproved" ||
                editType === "Under Time" ||
                status?.toLowerCase() === "cancelled"
              }
            >
              <EditRoundedIcon />
            </IconButton>
            <IconButton
              onClick={() => deleteFunc(rowId, rowType)}
              aria-label="delete"
              color={"error"}
              disabled={
                status?.toLowerCase() === "approved" ||
                status?.toLowerCase() === "disapproved" ||
                editType === "Under Time" ||
                status?.toLowerCase() === "cancelled"
              }
            >
              <DeleteForeverRoundedIcon />
            </IconButton>
          </>
        ) : (
          <CircularProgress />
        )}
      </Box>
    </TableCell>
  );
};
export default TableBodyDataOption;
