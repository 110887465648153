export const SortAZ = (arr, name) => {
  return arr?.sort((a, b) => a[name].localeCompare(b[name]));
};

export const SortDescNum = (arr, name) => {
  return arr?.sort((a, b) => b[name] - a[name]);
};

export const SortZA = (arr, name) => {
  return arr?.sort((a, b) => b[name].localeCompare(a[name]));
};
