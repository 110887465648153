import React from "react";

import { Navigate } from "react-router-dom";
import Content from "../component/Content";
//boolean for
export const useAuth = () => {
  const user = localStorage.getItem("token");
  //must send req to api to validate token
  if (user) {
    return true;
  } else {
    return false;
  }
};

const ProtectedRoutes = (props) => {
  const auth = useAuth();

  return auth ? <Content /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
