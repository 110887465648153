import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";

const ActionButtons = ({ onApprove = () => {}, onDecline = () => {} }) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      divider={<Divider orientation="vertical" flexItem />}
      justifyContent="center"
    >
      {/* Approve Button */}
      <Tooltip title="Approve">
        <IconButton onClick={onApprove}>
          <ThumbUpIcon
            sx={{
              color: "#099269ce",
            }}
          />
        </IconButton>
      </Tooltip>

      {/* Decline Button */}
      <Tooltip title="Decline">
        <IconButton onClick={onDecline}>
          <ThumbDownIcon sx={{ color: "#fa5252ce" }} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default ActionButtons;
