import apiService from "../axios";

export const getSettings = async (payload) => {
  try {
    const { data } = await apiService.get(`v1/settings/get-general-settings`);

    return data.data;
  } catch (error) {
    throw error;
  }
};

export const updateGeneralSettings = async (data) => {
  let payload = {
    api: true,
    settings: [
      {
        setting: "company_logo",
        value: data.companyLogo,
      },
      {
        setting: "company_name",
        value: data.companyName,
      },
      {
        setting: "login_image",
        value: data.login_image,
      },
      {
        setting: "login_logo",
        value: data.loginLogo,
      },
      {
        setting: "bgcolor_appbar",
        value: data.bgcolor_appbar,
      },
      {
        setting: "bgcolor_primary",
        value: data.bgcolor_primary,
      },
      {
        setting: "bgcolor_secondary",
        value: data.bgcolor_secondary,
      },
      {
        setting: "wfh_guidelines_url",
        value: data.wfh_guidelines_url,
      },
    ],
  };

  try {
    const request = await apiService.post(
      `/v1/settings/update-general-settings`,
      payload
    );

    return request.data;
  } catch (error) {
    throw error;
  }
};
