export const FixedTree = [
  {
    text: "Announcement",
    id: 1,
    parent_id: 0,
    tags: "/announcement",
  },
  {
    text: "Benefits",
    id: 2,
    parent_id: 0,
    tags: "/benefits",
    nodes: [
      {
        text: "Company Benefits",
        id: 3,
        parent_id: 2,
        tags: "/benefits/company Benefits",
      },
      {
        text: "HMO",
        id: 4,
        parent_id: 2,
        tags: "/benefits/HMO",
      },
    ],
  },
  {
    text: "Forms",
    id: 5,
    parent_id: 0,
    tags: "/forms",
  },
  {
    text: "Government Documents",
    id: 6,
    parent_id: 0,
    tags: "/government Documents",
  },
  {
    text: "Handbook",
    id: 7,
    parent_id: 0,
    tags: "/handbook",
  },
  {
    text: "House Rules",
    id: 8,
    parent_id: 0,
    tags: "/house Rules",
  },
  {
    text: "Legislation",
    id: 9,
    parent_id: 0,
    tags: "/legislation",
    nodes: [
      {
        text: "Australia",
        id: 10,
        parent_id: 9,
        tags: "/legislation/australia",
      },
      {
        text: "Philippines",
        id: 11,
        parent_id: 9,
        tags: "/legislation/philippines",
      },
    ],
  },
  {
    text: "Policies",
    id: 12,
    parent_id: 0,
    tags: "/policies",
    nodes: [
      {
        text: "HR",
        id: 13,
        parent_id: 11,
        tags: "/policies/hR",
      },
      {
        text: "Finance",
        id: 14,
        parent_id: 11,
        tags: "/policies/finance",
      },
    ],
  },
  {
    text: "Procedures",
    id: 15,
    parent_id: 0,
    tags: "/procedures",
  },
];
