import { useQuery } from "react-query";
import { getCreditStatus } from "../../../api/leaveConversion";

export const useCreditStatus = () => {
  return useQuery(["get-employee-credit-status"], () => getCreditStatus());
};

const queries = {
  useCreditStatus,
};

export default queries;
