import React from "react";
import { TableRow, TableCell } from "@mui/material";

const ActivityList = ({ item }) => {
  return (
    <>
      <TableRow>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.name,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.description,
            }}
          />
        </TableCell>
        <TableCell align={"right"}>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.efforts,
            }}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default ActivityList;
