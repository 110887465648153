import React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const DatePickerComponent = ({
  handleDatePicker,
  disableFuture,
  disablePast,
  label,
  dateValue,
  width,
  margin,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={dateValue}
        label={label}
        onChange={(newValue) => {
          handleDatePicker(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            sx={{
              margin: margin ? margin : "5px",
              width: width ? width : "135px",
            }}
          />
        )}
        // onError={() => {
        //   setDateValue(null);
        // }}
        className="datepicker"
        inputProps={{ readOnly: true }}
        disableFuture={disableFuture}
        disablePast={disablePast}
      />
    </LocalizationProvider>
  );
};

export default DatePickerComponent;
