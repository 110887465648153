export const TableSettings = { rows: 25 };

export const TableHeaderActivity = [
  { label: "Name", data: "name", type: "text" },
  { label: "Expected Hours", data: "expected", type: "text" },
  { label: "Hours Rendered", data: "hours", type: "text" },
  { label: "Times Unable To Comply", data: "week", type: "text" },
];

export const Range = [
  { label: "This week", value: "This Week" },
  { label: "Last week", value: "Last Week" },
  { label: "Last 2 weeks", value: "Last 2 Weeks" },
  { label: "This month", value: "This month" },
  { label: "Last month", value: "Last month" },
  { label: "Last 2 month", value: "Last 2 months" },
  { label: "Custom", value: "Custom" },
];
