import apiService from "../axios";
// eslint-disable-next-line import/no-anonymous-default-export
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const projects = {
  getType: async () => {
    try {
      const request = await apiService.post(`v1/employee/get-project-types`);
      return request.data.data;
    } catch (error) {
      throw error;
    }
  },

  //getCode: () => apiService.post(`v1/employee/get-project-codes`),

  //getActivity: () => apiService.post(`v1/employee/get-project-activites`),

  getProjectActivity: async () => {
    try {
      const request = await apiService.post(
        `/v1/employee/get-project-activites`
      );
      return request.data.data;
    } catch (error) {
      throw error;
    }
  },
  getProjectCode: async () => {
    try {
      const request = await apiService.post(`/v1/employee/get-project-codes`);
      return request.data.data;
    } catch (error) {
      throw error;
    }
  },
  getUserdata: async (data) => {
    try {
      const request = await apiService.post(
        `/v1/user/load/user-dashboard `,
        data
      );
      return request.data;
    } catch (error) {
      throw error;
    }
  },

  getTimesheetWeek: async (data) => {
    try {
      const request = await apiService.post(
        `/v5/timesheet/get-timesheet-week`,
        data
      );
      return request.data;
    } catch (error) {
      throw error;
    }
  },

  getAddress: async (data) => {
    try {
      const request = await apiService.post(
        `/v1/time-inout/get-geo-address`,
        data
      );
      return request.data;
    } catch (error) {
      throw error;
    }
  },

  submitEntry: async (dataParam) => {
    const requestOption = {
      api: true,
      action: dataParam.action,
      location: dataParam.location,
      img: dataParam.url,
    };
    try {
      const request = await apiService.post(`/v5/time/time-in`, requestOption);
      return {
        data: request.data,
        // value: dataParam.value,
        error: 0,
      };
    } catch (error) {
      return {
        data: error.response.data,
        // value: dataParam.value,
        error: 1,
      };
    }
  },

  getUpcomingEvent: async () => {
    const requestOption = {
      api: true,
    };

    try {
      const request = await apiService.post(
        `/v1/employee/get-upcoming-events`,
        requestOption
      );
      return request.data;
    } catch (error) {
      throw error;
    }
  },
  getLeaveTypesMng: async ({
    inActives = true,
    page_size = 10,
    search,
    page = 1,
  }) => {
    try {
      const response = await apiService.get(
        `/v5/leave/get-types?page_size=${page_size}&inActives=${inActives}&search=${search}&page=${page}`,
        {
          Accept: `application/json`,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  addLeaveType: async (data) => {
    try {
      const request = await apiService.post(`/v5/leave/add-type`, data);
      return request.data && toast.success("Successfully Added");
    } catch (error) {
      throw toast.error(error?.response.data.message.message);
    }
  },

  disableLeaveType: async (id) => {
    try {
      const request = await apiService.delete(`/v5/leave/delete-type`, {
        data: id,
      });
      return request.data && toast.success("Successfully Deleted");
    } catch (error) {
      throw error;
    }
  },
  restoreLeaveTypes: async ({ id }) => {
    try {
      const request = await apiService.get(`/v5/leave/restore-type?id=${id}`);
      return request.data;
    } catch (error) {
      throw error;
    }
  },

  submitEditType: async (data) => {
    try {
      const request = await apiService.post(`/v5/leave/update-type`, data);
      return request.data && toast.success("Successfully Updated");
    } catch (error) {
      throw toast.error(error.response.data?.message);
    }
  },

  getLeaveTypes: async (data) => {
    const requestOption = {
      employee_id: data,
      api: true,
    };
    try {
      const request = await apiService.get(`/v5/leave/get-types`);
      const request2 = await apiService.post(
        `/v1/employee/get-leave-credits`,
        requestOption
      );
      if (request && request2) {
        const promises = {
          type: request.data.data,
          credits: request2.data.data,
        };
        return promises;
      }
    } catch (error) {
      throw error;
    }
  },

  getHeaderData: async (data) => {
    try {
      const request = await apiService.post(
        `/v5/timesheet/get-timesheet-week`,
        data
      );
      return request.data.employee;
    } catch (error) {
      throw error;
    }
  },

  submitLeave: async (data) => {
    try {
      const request = await apiService.post(
        `/v1/employee/application/submit-leave`,
        data
      );
      return request.data;
    } catch (error) {
      throw error;
    }
  },

  getSkills: async () => {
    const requestOption = {
      api: true,
    };
    try {
      const request = await apiService.post(
        `/v1/settings/get-skills`,
        requestOption
      );
      return request.data.data;
    } catch (error) {
      throw error;
    }
  },

  getColleagues: async (
    page,
    dateSelected,
    filters,
    sorts,
    inOutFilter,
    search
  ) => {
    const data =
      filters === "ALL"
        ? {
            date: dateSelected,
            filter: "",
            dropFilter: sorts,
            aioFilter: inOutFilter,
            search: search,
          }
        : {
            date: dateSelected,
            filter: filters,
            dropFilter: sorts,
            aioFilter: inOutFilter,
            search: search,
          };
    try {
      const request = await apiService.post(
        `v5/time-inout/get-all?page=${page}`,
        data
      );
      return request.data;
    } catch (error) {
      throw error;
    }
  },

  submitReaction: async (dataOpt) => {
    try {
      const request = await apiService.post(
        `v1/time-inout/submit/react-to-img`,
        dataOpt.option
      );
      return { data: request.data, page: dataOpt.page };
    } catch (error) {
      throw error;
    }
  },

  submitCommend: async (dataOpt) => {
    try {
      const request = await apiService.post(
        `v1/time-inout/submit/commend`,
        dataOpt.option
      );
      return { data: request.data, page: dataOpt.page };
    } catch (error) {
      throw error;
    }
  },

  getDailyEntry: async (entrPayload) => {
    try {
      const request = await apiService.post(
        `/v1/timesheet/get-daily-entry`,
        entrPayload
      );
      return request.data.data;
    } catch (error) {
      throw error;
    }
  },

  updateTimesheetEntry: async (updatedEntry) => {
    try {
      const request = await apiService.post(
        `/v1/timesheet/update-entry`,
        updatedEntry
      );
      return request.data;
    } catch (error) {
      throw error;
    }
  },

  deleteTimesheetEntry: async (id) => {
    try {
      const request = await apiService.post(`/v1/timesheet/delete`, id);
      return request.data;
    } catch (error) {
      throw error;
    }
  },

  addTimesheenEntry: async (data) => {
    try {
      const request = await apiService.post(`/v1/timesheet/add-entry`, data);
      return request.data;
    } catch (error) {
      throw error;
    }
  },
  addSubmitLeave: async (data) => {
    try {
      const request = await apiService.post(
        `/v1/employee/application/submit-leave`,
        data
      );
      return request.data;
    } catch (error) {
      throw error;
    }
  },

  addFeedback: async (data) => {
    try {
      const request = await apiService.post(
        `/v5/feedback/submit-feedback`,
        data
      );
      return request.data;
    } catch (error) {
      throw error;
    }
  },

  addSubmitWfh: async (data) => {
    try {
      const request = await apiService.post(
        `/v1/employee/application/submit-wfh`,
        data
      );
      return request.data;
    } catch (error) {
      throw error;
    }
  },
  addSubmitOT: async (data) => {
    try {
      const request = await apiService.post(
        `/v1/employee/application/submit-otut`,
        data
      );
      return request.data;
    } catch (error) {
      throw error;
    }
  },
  addForgotTimeInOutRequest: async (data) => {
    try {
      const request = await apiService.post(
        `/v5/time-in-out/add-time-in-out-application`,
        data
      );
      return request.data;
    } catch (error) {
      throw error;
    }
  },
  getShifts: async (page, keyword, rowsPerPage) => {
    const data = {
      keyword: keyword,
      perpage: rowsPerPage,
    };

    try {
      const request = await apiService.post(
        `/v1/shifts/get-shift-modal?page=${page}`,
        data
      );
      return request.data;
    } catch (error) {
      throw error;
    }
  },
  addSubmitShift: async (data) => {
    try {
      const request = await apiService.post(`/v1/shifts/add-shift`, data);
      return request.data;
    } catch (error) {
      throw error;
    }
  },
  addUpdateShift: async (data) => {
    try {
      const request = await apiService.post(`/v1/shifts/edit-shift`, data);
      return request.data;
    } catch (error) {
      throw error;
    }
  },

  noteReminders: async (data) => {
    try {
      const request = await apiService.post(`v1/motd/user-confirm`, data);
      return request.data;
    } catch (error) {
      throw error;
    }
  },

  addDeleteShift: async (data) => {
    try {
      const request = await apiService.post(`/v1/shifts/delete-shift`, data);
      return request.data;
    } catch (error) {
      throw error;
    }
  },
  getPublicSettings: async () => {
    try {
      const request = await apiService.get(`/public-general-settings`);
      return request.data.data;
    } catch (error) {
      throw error;
    }
  },
  getReactionReport: async (page, from, to, rowsPerPage, filter) => {
    const data = {
      startDate: from,
      endDate: to,
      rowPerPage: rowsPerPage,
      filter: filter,
    };
    try {
      const request = await apiService.post(
        `v1/time-inout/reaction-report?page=${page}`,
        data
      );
      return request.data;
    } catch (error) {
      throw error;
    }
  },

  reminder: async () => {
    try {
      const request = await apiService.get(`/v1/settings/get-motd`);
      return request.data;
    } catch (error) {
      throw error;
    }
  },
  getPulseSurvey: async () => {
    try {
      const request = await apiService.post(`/v1/survey/get-all`);
      return request.data.data;
    } catch (error) {
      throw error;
    }
  },
  submitPulseSurvey: async (dataParam) => {
    try {
      const request = await apiService.post(`/v5/survey/manage`, dataParam);
      return request.data;
    } catch (error) {
      throw error;
    }
  },
  deletePulseSurvey: async (dataID) => {
    try {
      const request = await apiService.delete(`/v5/survey/manage/${dataID}`);
      return request.data;
    } catch (error) {
      throw error;
    }
  },
  updatePulseSurvey: async (dataParam) => {
    const id = dataParam.id;
    const data = dataParam.data;
    console.log(data);
    try {
      const request = await apiService.put(`/v5/survey/manage/${id}`, data);
      return request.data;
    } catch (error) {
      throw error;
    }
  },
  getSpecificPulse: async (dataID) => {
    try {
      const request = await apiService.get(`/v5/survey/manage/${dataID}`);
      return request.data;
    } catch (error) {
      throw error;
    }
  },
  setSurvey: async (dataParam) => {
    try {
      const request = await apiService.post(
        `/v1/survey/psych-survey/set`,
        dataParam
      );
      return request.data;
    } catch (error) {
      throw error;
    }
  },
  toggleSurvey: async (dataParam) => {
    try {
      const request = await apiService.post(
        `/v1/survey/psych-survey/enable`,
        dataParam
      );
      return request.data;
    } catch (error) {
      throw error;
    }
  },
  getSurveySetting: async () => {
    try {
      const request = await apiService.post(`/v1/survey/psych-survey/get`);
      return request.data;
    } catch (error) {
      throw error;
    }
  },
  submitAnswer: async (dataParam) => {
    try {
      const request = await apiService.post(`/v5/survey/submit`, dataParam);
      return request.data;
    } catch (error) {
      throw error;
    }
  },
  showResultAnswer: async (idParam) => {
    const from = "2023-01-01";
    const to = "2023-12-31";
    if (idParam === null) return;
    try {
      const request = await apiService.get(
        `v5/survey/manage/result/${idParam}?from=${from}&to=${to}`
      );
      return request.data.data;
    } catch (error) {
      throw error;
    }
  },
};
export default projects;
