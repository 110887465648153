import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import StyledTableCell from "../../../UserApplicationMgmt/UserApplications/tableComponents/StyledTableCell";

const LoadingTableCell = () => {
  return (
    <TableRow>
      <StyledTableCell colSpan="100%">
        <Stack
          direction="row"
          justifyItems="flex-start"
          alignItems="center"
          spacing={2}
        >
          <CircularProgress size={18} />

          <Typography sx={{ fontSize: "14px" }}>Loading...</Typography>
        </Stack>
      </StyledTableCell>
    </TableRow>
  );
};

export default LoadingTableCell;
