import format from "date-fns/format";

import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subWeeks,
  subMonths,
} from "date-fns";

import {
  RANGE_DATES,
  RANGE_DATES_TYPE,
  ORDER,
} from "../../../lib/reports/perActivity/";

export const downloadFile = (data, fileName) => {
  const blob = new Blob([data]);

  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);

  const clickEvent = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  a.dispatchEvent(clickEvent);
  a.remove();
};

export const getRangeDates = (key) => {
  const currentDate = new Date();

  const { type, offset } = RANGE_DATES[key];

  let fromDate, toDate;

  if (type === RANGE_DATES_TYPE.week) {
    fromDate = subWeeks(startOfWeek(currentDate, { weekStartsOn: 0 }), offset);
    toDate = subWeeks(
      endOfWeek(currentDate, { weekStartsOn: 0 }),
      offset >= 2 ? 1 : offset
    );
  }

  if (type === RANGE_DATES_TYPE.month) {
    fromDate = subMonths(startOfMonth(currentDate), offset);
    toDate = subMonths(endOfMonth(currentDate), offset >= 2 ? 1 : offset);
  }

  return { fromDate, toDate };
};

export const formatDateForQuery = (dateInput) => {
  const dateToFormat =
    dateInput instanceof Date ? dateInput : new Date(dateInput);

  return format(dateToFormat, "yyyy-MM-dd");
};

const descendingComparator = (a, b, orderBy) => {
  const x = a[orderBy],
    y = b[orderBy];

  if (y < x) return -1;
  if (y > x) return 1;
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === ORDER.descending
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const removeHtmlElement = (string) => {
  return string.replace(/(<([^>]+)>)/gi, "");
};
