import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";

import PerActivityStyledTable from "../table/PerActivityStyledTable";
import { ORDER } from "../../../../lib/reports/perActivity/";

const TABLE_CONTAINER_STYLES = {
  margin: "6px 0",
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  backgroundColor: "#FFF",
  borderRadius: "8px",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
};

const NestedTableContainer = ({
  children,
  state,
  handler,
  Pagination,
  TableHeader,
}) => {
  const { order, orderBy } = state;
  const { handleOrderChange, handleOrderChangeBy } = handler;

  const requestSortHandler = (newOrderBy) => {
    const isAsc = orderBy === newOrderBy && order === ORDER.ascending;
    handleOrderChange(isAsc ? ORDER.descending : ORDER.ascending);
    handleOrderChangeBy(newOrderBy);
  };

  return (
    <TableContainer sx={TABLE_CONTAINER_STYLES}>
      <PerActivityStyledTable>
        <TableHeader
          order={order}
          orderBy={orderBy}
          handleOrderChange={requestSortHandler}
        />

        <TableBody>{children}</TableBody>

        {!!Pagination && (
          <TableFooter>
            <TableRow>{Pagination}</TableRow>
          </TableFooter>
        )}
      </PerActivityStyledTable>
    </TableContainer>
  );
};

export default NestedTableContainer;
