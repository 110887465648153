import { useQuery } from "react-query";
import {
  getReportsDashboard,
  getActivityReport,
  getStaffReport,
} from "../../../api/reportsDashboard";

const useReportsDashboard = (params) => {
  return useQuery(["get-dashboard-report", params.criteria], () =>
    getReportsDashboard(params.payload)
  );
};

const useActivityReport = (params) => {
  return useQuery(["get-report-per-user-project", params.criteria], () =>
    getActivityReport(params.payload)
  );
};

const useStaffReport = (params) => {
  return useQuery(["get-per-staff-report", params.criteria], () =>
    getStaffReport(params.payload)
  );
};

const queries = {
  useReportsDashboard,
  useActivityReport,
  useStaffReport,
};
export default queries;
