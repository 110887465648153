import { useQuery } from "react-query";
import { genPostRequest, API_ENDPOINT } from "../../../helper/General";

export const useLeaveAndAbsences = (data) => {
  const range = data.start_date + data.end_date;
  return useQuery(
    ["get-leave-and-absences", range, data.per_page, data.page, data.search],
    (param) => genPostRequest(data, param, API_ENDPOINT.GET_LEAVE_AND_ABSENCES),
    {
      keepPreviousData: true,
      staleTime: 500 * 1000,
    }
  );
};
