import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Wave from "../Loader/wave";
function Timechildloader({ timeinoutloader }) {
  return (
    <Modal open={timeinoutloader}>
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Wave />
      </Box>
    </Modal>
  );
}

export default Timechildloader;
