import apiService from "../axios";

export const login = async (data) => {
  try {
    const response = await apiService.post(`v1/login-api`, data, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(data),
    });
    return response?.data?.errors?.account[0] ===
      "Your account has been disabled!"
      ? response?.data?.errors?.account[0]
      : response;
  } catch (error) {
    return error;
  }
};

export const requestGoogleLogin = async (data) => {
  try {
    const response = await apiService.post(`v1/google-login`, data, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(data),
    });
    if (response && response.data?.token && response.data?.user) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("user", JSON.stringify(response.data.user));
    }
    return response?.data?.errors?.account[0] ===
      "Your account has been disabled!"
      ? response?.data?.errors?.account[0]
      : response;
  } catch (error) {
    console.log(error);
  }
};

export const getUser = () =>
  apiService.get(`user`, {
    Accept: `application/json`,
  });

export const getMe = async () => {
  try {
    const response = await apiService.get(`user`, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = async (data) => {
  try {
    await apiService.post(`v5/forgot-password`, data, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(data),
    });
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (data) => {
  try {
    await apiService.post(`v5/reset-password`, data, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(data),
    });
  } catch (error) {
    throw error;
  }
};
