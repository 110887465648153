import NestedTableContainer from "../../container/NestedTableContainer";
import PerProjectTableHeader from "./PerProjectTableHeader";
import PerProjectTableRow from "./PerProjectTableRow";
import PerActivityTablePagination from "../PerActivityTablePagination";
import GenericTableLoader from "../../loader/GenericTableLoader";
import NestedTableError from "../../error/NestedTableError";
import NoResults from "../NoResults";
import { useNestedActivityReport } from "../../../../../hooks/react-query/PerActivity";
import {
  PER_ACTIVITY_QUERY_KEYS,
  PER_ACTIVITY_HEADER_CELL_ID,
} from "../../../../../lib/reports/perActivity";

const PerProjectTable = ({ activityId, userId, state }) => {
  const { fromDate, toDate, isWeekendShown, isInactiveUsersShown } = state;

  const {
    query: { data, isLoading, isError },
    state: projectActivityTableState,
    handler,
  } = useNestedActivityReport(
    PER_ACTIVITY_QUERY_KEYS.getActivityReportPerProject,
    { activityId, userId },
    fromDate,
    toDate,
    isWeekendShown,
    isInactiveUsersShown,
    PER_ACTIVITY_HEADER_CELL_ID.name
  );

  if (isError) return <NestedTableError />;

  return (
    <NestedTableContainer
      state={projectActivityTableState}
      handler={handler}
      TableHeader={PerProjectTableHeader}
      Pagination={
        <PerActivityTablePagination
          count={data?.total || 0}
          page={data?.current_page ? data.current_page - 1 : 0}
          pageSize={projectActivityTableState.pageSize}
          onPageChange={handler.handleChangePage}
          onRowsPerPageChange={handler.handleChangePageSize}
        />
      }
    >
      {!isLoading ? (
        data.data.map((project) => (
          <PerProjectTableRow
            key={project.row_id}
            activityId={activityId}
            userId={userId}
            project={project}
            state={state}
          />
        ))
      ) : (
        <GenericTableLoader />
      )}

      {!isLoading && !data?.data.length && <NoResults />}
    </NestedTableContainer>
  );
};

export default PerProjectTable;
