import React, { useState } from "react";
import {
  Collapse,
  IconButton,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Box,
  Table,
  TableBody,
  Tooltip,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { mngdFeedbackCommentsHeader } from "../../lib/manageFeedback";
import FeedbackComment from "./FeedbackComment";
import EmptyData from "../../component/Reports/EmptyData";
import AddEditCommentFeedbackModal from "./AddEditCommentFeedbackModal";
import FeedbackStatus from "./FeedbackStatus";

const ManageFeedbackList = ({ item }) => {
  const [open, setOpen] = useState(false);
  const [addEdit, setAddEdit] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [type, setType] = useState("add");
  const [getComment, setGetComment] = useState();

  const addCommentHandler = () => {
    setAddEdit(true);
    setType("add");
  };

  const editCommentHandler = (e) => {
    setAddEdit(true);
    setType("edit");
    setGetComment(e);
  };

  const statusHandler = () => {
    setStatusOpen(true);
  };

  return (
    <>
      {addEdit && (
        <AddEditCommentFeedbackModal
          item={item}
          open={addEdit}
          type={type}
          close={setAddEdit}
          getComment={getComment}
        />
      )}
      {statusOpen && (
        <FeedbackStatus item={item} open={statusOpen} close={setStatusOpen} />
      )}
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item?.id,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item?.category,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item?.subject,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item?.content,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item?.status,
            }}
          />
        </TableCell>
        <TableCell sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton onClick={addCommentHandler}>
            <Tooltip title="Comment">
              <ChatBubbleOutlineIcon />
            </Tooltip>
          </IconButton>
          <IconButton onClick={statusHandler}>
            {item?.status === "Open" ? (
              <Tooltip title="Close">
                <HighlightOffIcon />
              </Tooltip>
            ) : (
              <Tooltip title="Open">
                <LockOpenIcon />
              </Tooltip>
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow style={{ backgroundColor: "#D2E1EB" }}>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: "#ffff" }}
          colSpan={8}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {item?.comment.length === 0 ? (
              <EmptyData />
            ) : (
              <>
                <Box sx={{ margin: 1 }}>
                  <Typography variant="h6" gutterBottom component="div">
                    Comments
                  </Typography>
                </Box>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      {mngdFeedbackCommentsHeader.map((header, i) => {
                        return (
                          <TableCell
                            key={i}
                            align={header.align ? header.align : undefined}
                          >
                            <TableSortLabel
                              sx={{ fontWeight: "700", fontSize: "0.8rem" }}
                            >
                              {header.label}
                            </TableSortLabel>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.comment?.map((cmnt) => {
                      return (
                        <FeedbackComment
                          item={item}
                          cmnt={cmnt}
                          editCommentHandler={editCommentHandler}
                          key={cmnt?.id}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              </>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ManageFeedbackList;
