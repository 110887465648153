import Alert from "@mui/material/Alert";

import StyledTableCell from "../../../UserApplicationMgmt/UserApplications/tableComponents/StyledTableCell";

const NestedTableError = () => {
  return (
    <StyledTableCell colSpan="100%" sx={{ padding: "6px 2px !important" }}>
      <Alert severity="error" variant="filled">
        There is a problem fetching data from the server.
      </Alert>
    </StyledTableCell>
  );
};

export default NestedTableError;
