import {
  getActivityReportPerUser,
  getActivityReportPerProject,
  getActivityReportPerTimesheet,
} from "../../../api/perActivity/";

export const ROWS_PER_PAGE_OPTIONS = [10, 20, 30, 40, 50, 100];
export const ROWS_PER_PAGE_DEFAULT = ROWS_PER_PAGE_OPTIONS[1];

export const RANGE_DATES_TYPE = { week: "week", month: "month" };
export const RANGE_DATES = {
  "this week": { name: "this week", type: RANGE_DATES_TYPE.week, offset: 0 },
  "last week": { name: "last week", type: RANGE_DATES_TYPE.week, offset: 1 },
  "last 2 weeks": {
    name: "last 2 weeks",
    type: RANGE_DATES_TYPE.week,
    offset: 2,
  },
  "this month": {
    name: "this month",
    type: RANGE_DATES_TYPE.month,
    offset: 0,
  },
  "last month": {
    name: "last month",
    type: RANGE_DATES_TYPE.month,
    offset: 1,
  },
  "last 2 months": {
    name: "last 2 months",
    type: RANGE_DATES_TYPE.month,
    offset: 2,
  },
  custom: {
    name: "custom",
    type: RANGE_DATES_TYPE.week,
    offset: 0,
  },
};

export const RANGE_DATES_DEFAULT = RANGE_DATES["this week"];

export const PER_ACTIVITY_QUERY_KEYS = {
  getActivityReport: "get-activity-report",
  getActivityReportPerUser: "get-activity-report-per-user",
  getActivityReportPerProject: "get-activity-report-per-project",
  getActivityReportPerTimesheet: "get-activity-report-per-timesheet",
};

export const NESTED_QUERIES_API_GETTER_FUNCTIONS = {
  [PER_ACTIVITY_QUERY_KEYS.getActivityReportPerUser]: getActivityReportPerUser,
  [PER_ACTIVITY_QUERY_KEYS.getActivityReportPerProject]:
    getActivityReportPerProject,
  [PER_ACTIVITY_QUERY_KEYS.getActivityReportPerTimesheet]:
    getActivityReportPerTimesheet,
};

export const EXPORT_TYPE = {
  excel: {
    id: "excel",
    extension: ".xlsx",
  },
  csv: {
    id: "csv",
    extension: ".csv",
  },
};

export const ORDER = {
  ascending: "asc",
  descending: "desc",
};

export const PER_ACTIVITY_HEADER_CELL_ID = {
  name: "name",
  description: "description",
  efforts: "efforts",
};

export const PER_ACTIVITY_HEADER_CELL = [
  {
    id: PER_ACTIVITY_HEADER_CELL_ID.name,
    label: "Activity Code",
    isSortable: true,
  },
  {
    id: PER_ACTIVITY_HEADER_CELL_ID.description,
    label: "Description",
    isSortable: true,
  },
  {
    id: PER_ACTIVITY_HEADER_CELL_ID.efforts,
    label: "Total Efforts",
    isSortable: true,
  },
];

export const PER_USER_HEADER_CELL_ID = {
  name: "name",
  efforts: "efforts",
};

export const PER_USER_HEADER_CELL = [
  {
    id: PER_USER_HEADER_CELL_ID.name,
    label: "User",
    isSortable: true,
  },
  {
    id: PER_USER_HEADER_CELL_ID.efforts,
    label: "Total Efforts",
    isSortable: true,
  },
];

export const PER_PROJECT_HEADER_CELL_ID = {
  name: "name",
  description: "description",
  efforts: "efforts",
};

export const PER_PROJECT_HEADER_CELL = [
  {
    id: PER_PROJECT_HEADER_CELL_ID.name,
    label: "Project Code",
    isSortable: true,
  },
  {
    id: PER_PROJECT_HEADER_CELL_ID.description,
    label: "Description",
    isSortable: true,
  },
  {
    id: PER_PROJECT_HEADER_CELL_ID.efforts,
    label: "Total Efforts",
    isSortable: true,
  },
];

export const PER_TIMESHEET_HEADER_CELL_ID = {
  date: "date",
  milestone: "milestone",
  details: "details",
  efforts: "efforts",
};

export const PER_TIMESHEET_HEADER_CELL = [
  {
    id: PER_TIMESHEET_HEADER_CELL_ID.date,
    label: "Date",
    isSortable: true,
  },
  {
    id: PER_TIMESHEET_HEADER_CELL_ID.milestone,
    label: "Milestone",
    isSortable: true,
  },
  {
    id: PER_TIMESHEET_HEADER_CELL_ID.details,
    label: "Details",
    isSortable: true,
  },
  {
    id: PER_TIMESHEET_HEADER_CELL_ID.efforts,
    label: "Total Efforts",
    isSortable: true,
  },
];
