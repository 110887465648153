import React, { useState, useContext, useEffect, useCallback } from "react";
import {
  Collapse,
  IconButton,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { PerClientV5Context } from ".";
import Loader from "../../../component/Loader";
import { sortArrayTable } from "../../../helper/Reports";
import ThirdCollapse from "./ThirdCollapse";
import EmptyData from "../../../component/Reports/EmptyData";
import TableHeader from "../../../component/Reports/TableHeader";
import ReportTableContainter from "../../../component/Reports/TableContainter";
import { API_ENDPOINT, genGetRequest } from "../../../helper/General";
import { SecondCollapseHeader } from "../../../helper/ReportPerClient";

const SecondCollapse = ({ item, codeId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [limit, setLimit] = useState(30);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [staffData, setStaffData] = useState([]);
  const { startDate, endDate, isWeekends, isInactiveUsers } =
    useContext(PerClientV5Context);
  const [orderDirection, setOrderDirection] = useState("desc");
  const [tableData, setTableData] = useState([]);

  const handleChangeRowsPerPage = (e) => {
    setPage(1);
    setLimit(e.target.value);
  };

  const handleChangePage = (page) => {
    setPage(page + 1);
  };
  let payload = {
    fromDate: startDate,
    toDate: endDate,
    inActive: isInactiveUsers,
    weekEnd: isWeekends,
    page: page,
    page_size: limit,
  };

  const getStaffData = useCallback(async () => {
    if (isOpen) {
      setIsLoading(true);
      const response = await genGetRequest(
        payload,
        API_ENDPOINT.GET_PER_CLIENTS +
          "/" +
          codeId +
          "/code/" +
          item.row_id +
          "/users"
      );
      setStaffData(response);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page, item, isOpen]);

  const openCollapsable = async () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      await getStaffData();
      setIsOpen(true);
    }
  };

  useEffect(() => {
    getStaffData();
  }, [getStaffData]);

  useEffect(() => {
    if (staffData) {
      setTableData(staffData?.data || []);
    }
  }, [staffData]);

  const handleSortRequest = (key, parentKey) => {
    let direction = "asc";
    direction = orderDirection === "desc" ? "asc" : "desc";
    setOrderDirection(direction);
    setTableData((prev) => sortArrayTable(prev, direction, key, parentKey));
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <TableRow>
        <TableCell>
          <IconButton
            onClick={async () => {
              await openCollapsable();
            }}
          >
            {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.name,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.description,
            }}
          />
        </TableCell>
        <TableCell align={"right"}>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.efforts,
            }}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} sx={{ background: "#dbe9f4" }}>
          <Collapse in={isOpen} unmountOnExit>
            {!staffData?.data?.length ? (
              <EmptyData />
            ) : (
              <ReportTableContainter>
                <TableHeader
                  isCollapsable={true}
                  headerList={SecondCollapseHeader}
                  handleSortRequest={handleSortRequest}
                  orderDirection={orderDirection}
                />
                <TableBody>
                  {tableData?.map((codeItem) => {
                    return (
                      <ThirdCollapse
                        key={codeItem.row_id}
                        item={codeItem}
                        codeId={codeId}
                        activityId={item.row_id}
                      />
                    );
                  })}
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        10, 20, 30, 40, 50, 60, 70, 80, 90, 100,
                      ]}
                      count={staffData?.total || 0}
                      page={
                        staffData?.current_page
                          ? staffData?.current_page - 1
                          : 0
                      }
                      onPageChange={(e, page) => {
                        handleChangePage(page);
                      }}
                      rowsPerPage={limit}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      showFirstButton={true}
                      showLastButton={true}
                    />
                  </TableRow>
                </TableBody>
              </ReportTableContainter>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default SecondCollapse;
