import { useQuery } from "react-query";
import { API_ENDPOINT, genPostRequest } from "../../../helper/General";

export const useGetWFHSummary = (data) => {
  const range = data.start_date + data.end_date;
  return useQuery(
    ["get-wfh-summary", range, data.per_page, data.page, data.search],
    () => genPostRequest(data, null, API_ENDPOINT.GET_WFH_SUMMARY),
    {
      keepPreviousData: true,
      staleTime: 500 * 1000,
    }
  );
};
