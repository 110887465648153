import apiService from "../axios";
import { toast } from "react-toastify";
import { toastAlertOptions } from "../../hooks/customHooks/toastAlertOptions";
export const getManagementSummary = async (
  select,
  startDate,
  endDate,
  status,
  size,
  page,
  search,
  type
) => {
  try {
    const newType = type === "All" ? "*" : type;
    const newStatus = status === "All" ? "*" : status;
    const url =
      select === "Leave"
        ? `/v5/leave/get-leave-applications-summary?start_date=${startDate}&end_date=${endDate}&leave=${newType}&status=${newStatus}&api=${true}&page_size=${size}&page=${page}&search=${search}`
        : select === "Overtime"
        ? `/v5/leave/get-ot-applications-summary?start_date=${startDate}&end_date=${endDate}&type=${newType}&status=${newStatus}&api=${true}&page_size=${size}&page=${page}&search=${search}`
        : select === "ForgotTimeInOut"
        ? `v5/leave/get-forgotTimeInOut-applications-summary?start_date=${startDate}&end_date=${endDate}&type=${newType}&status=${newStatus}&api=${true}&page_size=${size}&page=${page}&search=${search}`
        : select === "Document"
        ? `v5/applications/get-request-document?&page_size=${size}&page=${page}&search=${search}`
        : `/v5/leave/get-wfh-applications-summary?start_date=${startDate}&end_date=${endDate}&type=${newType}&status=${newStatus}&api=${true}&page_size=${size}&page=${page}&search=${search}`;

    const request = await apiService.get(url);
    return request.data;
  } catch (error) {
    throw error;
  }
};

export const cancelRequest = async (id, select) => {
  try {
    const url =
      select === "Leave"
        ? `/v5/applications/close-approved-leave?id=${id}&api=${true}`
        : select === "Overtime"
        ? `/v5/applications/close-approved-ot?id=${id}&api=${true}`
        : select === "ForgotTimeInOut"
        ? `/v5/applications/close-approved-forgotTimeInOut?id=${id}&api=${true}`
        : select === "Document"
        ? `/v5/applications/cancel-request-document/${id}`
        : `/v5/applications/close-approved-wfh?id=${id}&api=${true}`;

    const request = await apiService.post(url);
    toast.success(
      "User application cancelled successfully.",
      toastAlertOptions
    );
    return request.data;
  } catch (error) {
    toast.error("Error was found.", toastAlertOptions);
    throw error;
  }
};
