import { Fragment } from "react";

import ClampedLongText from "../ClampedLongText";
import StyledTableCell from "../StyledTableCell";
import { getApplicationType } from "../../../../../helper/UserApplicationMgmt/UserApplications";
import React from "react";

const PurposeCell = ({ type, purpose }) => {
  const { isLeave, isOvertime } = getApplicationType(type);
  const purposeLineClamp = isLeave ? 6 : isOvertime ? 5 : 7;

  const moddedPurpose = (purpose) => {
    const startIndex = purpose.indexOf("[");
    const endIndex = purpose.indexOf(":");
    const title = purpose.slice(startIndex, endIndex + 1);
    const content = purpose.slice(endIndex + 1);

    if (!title) return purpose;
    return (
      <Fragment>
        <span style={{ color: "#21293C", fontWeight: 600, fontSize: "14px" }}>
          {title}
        </span>
        <br />
        {content}
      </Fragment>
    );
  };

  const editedPurpose = moddedPurpose(purpose);

  return (
    <StyledTableCell
      sx={{
        verticalAlign: "top",
      }}
    >
      <ClampedLongText
        maxWidth={40}
        lineClamp={purposeLineClamp}
        sxTypography={{ fontSize: "14px" }}
        sxButton={{ fontSize: "14px" }}
      >
        {editedPurpose}
      </ClampedLongText>
    </StyledTableCell>
  );
};

export default PurposeCell;
