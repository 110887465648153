export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export const TimeInOutTableHeader = [
  {
    label: "Department",
    key: "department",
    isSortable: true,
  },
  {
    label: "Name",
    key: "full_name",
    isSortable: true,
  },
  {
    label: "Date",
    key: "date",
    isSortable: true,
  },
  {
    label: "Time In",
    key: "time_in",
    isSortable: true,
  },
  {
    label: "Time Out",
    key: "time_out",
    isSortable: true,
  },
  {
    label: "Work Status",
    key: "status",
    isSortable: true,
  },
];
