import { Fragment, memo } from "react";

import Box from "@mui/material/Box";

import PerActivityContainer from "../../../component/Reports/PerActivity/container/PerActivityContainer";
import PerActivityTitle from "../../../component/Reports/PerActivity/title/PerActivityTitle";
import FilterBar from "../../../component/Reports/PerActivity/filterBar";
import PerActivityTableContainer from "../../../component/Reports/PerActivity/container/PerActivityTableContainer";
import PerActivityTableRow from "../../../component/Reports/PerActivity/table/perActivity/PerActivityTableRow";
import PerActivityTablePagination from "../../../component/Reports/PerActivity/table/PerActivityTablePagination";
import PerActivityError from "../../../component/Reports/PerActivity/error/PerActivityError";
import GenericTableLoader from "../../../component/Reports/PerActivity/loader/GenericTableLoader";
import NoResults from "../../../component/Reports/PerActivity/table/NoResults";
import BackdropLoader from "../../../component/Reports/PerActivity/loader/BackdropLoader";
import { useActivityReport } from "../../../hooks/react-query/PerActivity";

const TABLE_WRAPPER_STYLES = {
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  backgroundColor: "#FFF",
  borderRadius: "8px",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.05)",
};

const PerActivity = () => {
  const {
    query: { data, isLoading, isFetching, isError, isSuccess },
    state,
    handler,
  } = useActivityReport();

  return (
    <PerActivityContainer>
      <PerActivityTitle />

      {isError && <PerActivityError />}

      {<BackdropLoader open={isFetching} />}

      {!isError && (
        <Fragment>
          <FilterBar state={state} handler={handler} />

          {(isLoading || isSuccess) && (
            <Box sx={TABLE_WRAPPER_STYLES}>
              <PerActivityTableContainer state={state} handler={handler}>
                {!isLoading ? (
                  data.data.map((activity) => (
                    <PerActivityTableRow
                      key={activity.row_id}
                      activity={activity}
                      state={state}
                    />
                  ))
                ) : (
                  <GenericTableLoader />
                )}

                {!isLoading && !data?.data.length && <NoResults />}
              </PerActivityTableContainer>

              <PerActivityTablePagination
                component={Box}
                count={data?.total || 0}
                page={data?.current_page ? data.current_page - 1 : 0}
                pageSize={state.pageSize}
                onPageChange={handler.handleChangePage}
                onRowsPerPageChange={handler.handleChangePageSize}
              />
            </Box>
          )}
        </Fragment>
      )}
    </PerActivityContainer>
  );
};

export default memo(PerActivity);
