import React, { useEffect, useState } from "react";
import { Grid, Table, TableContainer, IconButton } from "@mui/material";
import HeaderUI from "../../../component/AdminTables/HeaderUI";
import {
  employeeSkillsHeader,
  employeeSkillBody,
} from "../../../helper/SettingsSytem/Skills";
import BodyUI from "../../../component/AdminTables/BodyUI";
import SkeletonLoader from "../../../component/AdminTables/SkeletonLoader";
import CreateIcon from "@mui/icons-material/Create";
import EmployeeSkillModal from "./EmployeeSkillModal";
import ServerPagination from "../../../component/Pagination/ServerPagination";

const EmployeeBody = ({ empSkills }) => {
  const EquippedPending = !empSkills.isFetching
    ? empSkills?.data?.data?.map((skill) => ({
        ...skill,
        employee_skill:
          skill.employee_skill.length === 0 ? "Pending" : "Equipped",
        skill: skill.employee_skill.filter((active) => active.status === 1),
      }))
    : [];

  return (
    <Grid container>
      <TableContainer
        sx={{
          maxHeight: "calc(100vh - 300px)",
          padding: "0px 0px 20px 0px",
          overflowX: "auto",
        }}
      >
        <Table stickyHeader style={{ overflowX: "auto" }}>
          <HeaderUI headers={employeeSkillsHeader} />
          {!empSkills.isFetching ? (
            <BodyUI
              rows={EquippedPending}
              cols={employeeSkillBody}
              Action={EmployeeSkillAction}
            />
          ) : (
            <SkeletonLoader colSpan={employeeSkillsHeader.length} />
          )}
        </Table>
      </TableContainer>
      {!empSkills.isFetching ? (
        <ServerPagination
          field={"per_page"}
          from={empSkills.data.from}
          to={empSkills.data.to}
          total={empSkills.data.total}
          pageData={{
            to: empSkills.data.to,
            from: empSkills.data.from,
            total: empSkills.data.total,
            perPage: empSkills.data.per_page,
          }}
          setPerPage={empSkills.setPerPage}
          startPage={empSkills.startPage}
          lastPage={empSkills.lastPage}
          nextPage={empSkills.nextPage}
          backPage={empSkills.backPage}
        />
      ) : null}
    </Grid>
  );
};

export default EmployeeBody;

const EmployeeSkillAction = ({ val, ind }) => {
  const [modal, setModal] = useState(false);
  const handleOpenEmpSkill = () => {
    setModal(true);
  };
  const handleCloseModal = () => {
    setModal(false);
  };
  useEffect(() => {
    return () => {
      setModal(false);
    };
  }, []);
  return (
    <>
      <IconButton onClick={handleOpenEmpSkill}>
        <CreateIcon fontSize="small" />
      </IconButton>
      <EmployeeSkillModal
        open={modal}
        handleClose={handleCloseModal}
        data={val}
      />
    </>
  );
};
