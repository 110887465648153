import React from "react";
import { Card, Box } from "@mui/material";

const FilterHeader = ({ children }) => {
  return (
    <Card
      sx={{
        borderRadius: "10px",
        padding: "14px",
        marginTop: "15px",
        marginRight: "15px",
        marginLeft: "15px",
        boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
        position: "sticky",
        top: "0",
        zIndex: "20",
        "@media(max-width: 768px)": {
          position: "relative",
        },
      }}
    >
      <Box
        sx={{
          display: { xs: "block", sm: "block", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {children}
      </Box>
    </Card>
  );
};

export default FilterHeader;
