import React, { useContext } from "react";
import {
  Backdrop,
  Modal,
  Fade,
  Box,
  Typography,
  FormControl,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { LeaveTypesFilterContext } from "../LeaveTypes/LeaveTypesFilter";
import { useState } from "react";
import hooks from "../../../hooks/react-query/queryHooks";
import { LeaveTypesContext } from ".";
import AutoCompleteDescription from "../../../component/CustomElement/AutoCompleteDescription";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const AddLeaveTypeModal = () => {
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const [credits, setCredits] = useState("");
  const [dept, setDept] = useState([]);
  const { open, setOpen } = useContext(LeaveTypesFilterContext);
  const { refetch, setLoad } = useContext(LeaveTypesContext);
  const { mutate: addLeaveType, isLoading } = hooks.useAddLeaveType({
    refetch,
    setLoad,
  });
  const { data: department, isFetching: loadingDept } =
    hooks.useGetDepartment();
  const submitHandler = () => {
    const deptID = dept?.map((data) => data.id);
    const data = {
      name: name,
      shortname: code,
      description: description,
      credits: credits,
      department: JSON.stringify(deptID),
    };
    addLeaveType(data);
    setOpen(false);
  };
  const onChangeDepartment = (e, newValue) => {
    setDept(newValue);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      // onClose={setOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Add New Leave Type
          </Typography>
          <FormControl sx={{ display: "flex", justifyContent: "space-around" }}>
            <TextField
              sx={{ margin: "10px 0px" }}
              id="outlined-basic"
              label="Name"
              variant="outlined"
              fullWidth
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              sx={{ margin: "10px 0px" }}
              id="outlined-basic"
              label="Code"
              variant="outlined"
              fullWidth
              onChange={(e) => setCode(e.target.value)}
            />
            <TextField
              sx={{ margin: "10px 0px" }}
              id="outlined-basic"
              label="Description"
              variant="outlined"
              fullWidth
              onChange={(e) => setDescription(e.target.value)}
            />
            <TextField
              type="number"
              sx={{ margin: "10px 0px" }}
              id="outlined-basic"
              label="Credits"
              variant="outlined"
              fullWidth
              onChange={(e) => setCredits(e.target.value)}
            />
            <div style={{ margin: "12px 0px 12px 0px" }}>
              <AutoCompleteDescription
                data={{ id: "id", name: "short_name", desc: "description" }}
                label={"Department"}
                multiple={true}
                optionData={department.data}
                loading={loadingDept}
                value={dept}
                onChange={onChangeDepartment}
              />
            </div>
          </FormControl>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              size="small"
              sx={{ marginX: "5px" }}
              variant="contained"
              onClick={() => setOpen(false)}
            >
              <Typography sx={{ fontSize: "0.78rem" }}>CANCEL</Typography>
            </Button>
            <Button
              sx={{ marginX: "5px", backgroundColor: "#00c292" }}
              size="small"
              onClick={submitHandler}
              disabled={
                name.length === 0 ||
                code.length === 0 ||
                description.length === 0 ||
                parseInt(credits) > 99.99 ||
                credits.length === 0 ||
                isLoading
              }
              endIcon={isLoading ? <CircularProgress size={15} /> : <></>}
              variant="contained"
            >
              <Typography sx={{ fontSize: "0.78rem" }}>ADD</Typography>
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AddLeaveTypeModal;
