import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Skeleton } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import React from "react";

const TableSkeleton = () => {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Skeleton
                variant="rounded"
                width={450}
                height={40}
                sx={{ margin: "0 5px" }}
              />
            </TableCell>
            <TableCell>
              <Skeleton
                variant="rounded"
                width={450}
                height={40}
                sx={{ margin: "0 5px" }}
              />
            </TableCell>
            <TableCell>
              <Skeleton
                variant="rounded"
                width={450}
                height={40}
                sx={{ margin: "0 5px" }}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
              <Skeleton
                variant="rounded"
                width={450}
                height={40}
                sx={{ margin: "0 5px" }}
              />
            </TableCell>
            <TableCell align="right">
              <Skeleton
                variant="rounded"
                width={450}
                height={40}
                sx={{ margin: "0 5px" }}
              />
            </TableCell>
            <TableCell align="right">
              <Skeleton
                variant="rounded"
                width={450}
                height={40}
                sx={{ margin: "0 5px" }}
              />
            </TableCell>
          </TableRow>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
              <Skeleton
                variant="rounded"
                width={450}
                height={40}
                sx={{ margin: "0 5px" }}
              />
            </TableCell>
            <TableCell align="right">
              <Skeleton
                variant="rounded"
                width={450}
                height={40}
                sx={{ margin: "0 5px" }}
              />
            </TableCell>
            <TableCell align="right">
              <Skeleton
                variant="rounded"
                width={450}
                height={40}
                sx={{ margin: "0 5px" }}
              />
            </TableCell>
          </TableRow>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
              <Skeleton
                variant="rounded"
                width={450}
                height={40}
                sx={{ margin: "0 5px" }}
              />
            </TableCell>
            <TableCell align="right">
              <Skeleton
                variant="rounded"
                width={450}
                height={40}
                sx={{ margin: "0 5px" }}
              />
            </TableCell>
            <TableCell align="right">
              <Skeleton
                variant="rounded"
                width={450}
                height={40}
                sx={{ margin: "0 5px" }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default TableSkeleton;
