import React, { useContext } from "react";
import {
  Button,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Box,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import RefreshIcon from "@mui/icons-material/Refresh";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { RangeMenuItem } from "../../../lib/reports/dashboard";
import { ReportsDashboardContext } from ".";
import { isAfter, isBefore } from "date-fns";
import { toast } from "react-toastify";
import DashboardFilterHeader from "../../../component/Reports/Dashboard/DashboardFilterHeader";
import moment from "moment";

const DashboardFilter = ({ handleRangeChange, handleRefresh }) => {
  const { startDate, setStartDate, endDate, setEndDate, range, setRange } =
    useContext(ReportsDashboardContext);

  return (
    <DashboardFilterHeader>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        <InfoIcon sx={{ color: "#00c292", mr: 1 }} />
        <Typography variant="body2" component="span">
          {`Showing statistics from ${moment(startDate).format(
            "MMMM DD, YYYY"
          )} to ${moment(endDate).format("MMMM DD, YYYY")}`}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        <FormControl size={"small"} sx={{ margin: "5px" }}>
          <InputLabel id="range-label">Range</InputLabel>
          <Select
            labelId="range-label"
            id="demo-simple-select"
            size="small"
            value={range}
            onChange={handleRangeChange}
            label="Range"
          >
            {RangeMenuItem.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={startDate}
            label={"Start"}
            onChange={(newValue) => {
              if (isAfter(newValue, new Date(endDate))) {
                toast.error("Start date should not be after end date.");
                return;
              }
              setStartDate(newValue);
              setRange("Custom");
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                sx={{
                  width: "10em",
                  margin: "5px",
                }}
              />
            )}
            className="datepicker"
            inputProps={{ readOnly: true }}
          />
          <DatePicker
            value={endDate}
            label={"End"}
            onChange={(newValue) => {
              if (isBefore(newValue, new Date(startDate))) {
                toast.error("End date should not be before start date.");
                return;
              }
              setEndDate(newValue);
              setRange("Custom");
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                sx={{
                  width: "10em",
                  margin: "5px",
                }}
              />
            )}
            className="datepicker"
            inputProps={{ readOnly: true }}
          />
        </LocalizationProvider>

        <Button
          sx={{ marginX: "5px", backgroundColor: "#00c292" }}
          onClick={() => handleRefresh()}
          startIcon={<RefreshIcon />}
          variant="contained"
        >
          <Typography sx={{ fontSize: "0.78rem" }}>Refresh</Typography>
        </Button>
      </Box>
    </DashboardFilterHeader>
  );
};

export default DashboardFilter;
