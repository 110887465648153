import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import TableRow from "@mui/material/TableRow";

import StyledTableCell from "../tableComponents/StyledTableCell";
import NameCellSkeleton from "./NameCellSkeleton";
import DateFiledCellSkeleton from "./DateFiledCellSkeleton";
import PurposeCellSkeleton from "./PurposeCellSkeleton";
import StatusCellSkeleton from "./StatusCellSkeleton";
import WorkFromHomeCellSkeleton from "./WorkFromHomeCellSkeleton";
import { getApplicationType } from "../../../../helper/UserApplicationMgmt/UserApplications";

const TableRowSkeletonLoader = ({ type }) => {
  const { isWfh } = getApplicationType(type);

  return (
    <TableRow>
      {/* Name */}
      <NameCellSkeleton type={type} />

      {/* Date Filed */}
      <DateFiledCellSkeleton type={type} />

      {/* Purpose */}
      <PurposeCellSkeleton type={type} />

      {isWfh && <WorkFromHomeCellSkeleton />}

      {/* Status */}
      <StatusCellSkeleton />

      {/* Actions */}
      <StyledTableCell align="center" sx={{ verticalAlign: "top" }}>
        <Stack direction="row" spacing={2.5} justifyContent="center">
          <Skeleton variant="circular" width={40} height={40} />

          <Skeleton variant="circular" width={40} height={40} />
        </Stack>
      </StyledTableCell>
    </TableRow>
  );
};

export default TableRowSkeletonLoader;
