import React, { createContext, useMemo, useState, useEffect } from "react";

import UserListFilter from "./UserListFilter";
import AddUser from "./AddUser";
import ImportUserCsv from "./ImportUserCsv";
import HeaderReports from "../../../component/Reports/Header";
import queries from "../../../hooks/react-query/UserList";
import { sortArrayTable } from "../../../helper/Reports";
import Loader from "../../../component/Loader";
import EmptyData from "../../../component/Reports/EmptyData";
import ReportTableContainter from "../../../component/Reports/TableContainter";
import TableHeader from "../../../component/Reports/TableHeader";
import { UserListTableHeader } from "../../../lib/userLists/tableHeaders";
import ExportModal from "./ExportsModal";
import { TableBody, TablePagination, TableRow, Tooltip } from "@mui/material";
import UserLists from "./UserLists";
import TablePaginationActions from "../../../component/TablePaginationAction";
import { useDepartment } from "../../../hooks/react-query/profileQuery";
import { Box } from "@mui/system";

export const UserListContext = createContext({
  isExportModalOpen: false,
  search: "",
  isOpenAddUser: false,
  isInactiveUsers: false,
  departments: [],
  isFetchedDepartments: false,
  userList: [],
  isImportModalOpen: false,
  setSearch() {},
  setIsOpenAddUser() {},
  setIsExportModalOpen() {},
  setIsInactiveUsers() {},
  refetch() {},
  setPage() {},
  setIsImportModalOpen() {},
});

const UserListsPage = () => {
  const [isOpenAddUser, setIsOpenAddUser] = useState(false);
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [orderDirection, setOrderDirection] = useState("desc");
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);
  const [isInactiveUsers, setIsInactiveUsers] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  const handleChangeRowsPerPage = (e) => {
    setPage(1);
    setLimit(e.target.value);
  };

  const handleChangePage = (page) => {
    setPage(page + 1);
  };

  const { data: departments, isFetched: isFetchedDepartments } =
    useDepartment();

  const {
    data: userList,
    refetch,
    isFetching,
  } = queries.useUserList({
    criteria: { page, limit, search, isInactiveUsers },
    payload: {
      inActives: isInactiveUsers,
      search: search,
      page_size: limit,
      page: page,
    },
  });

  const value = useMemo(
    () => ({
      isExportModalOpen,
      setIsExportModalOpen,
      search,
      setSearch,
      isOpenAddUser,
      setIsOpenAddUser,
      isInactiveUsers,
      setIsInactiveUsers,
      refetch,
      setPage,
      departments,
      isFetchedDepartments,
      userList,
      isImportModalOpen,
      setIsImportModalOpen,
    }),
    [
      refetch,
      search,
      setSearch,
      isOpenAddUser,
      setIsOpenAddUser,
      isExportModalOpen,
      setIsExportModalOpen,
      isInactiveUsers,
      setIsInactiveUsers,
      setPage,
      departments,
      isFetchedDepartments,
      userList,
      isImportModalOpen,
      setIsImportModalOpen,
    ]
  );

  useEffect(() => {
    if (userList) {
      const mappedData =
        userList?.data?.data?.map((item) => {
          return {
            ...item,
            emp_company_id: item?.employee?.emp_company_id || "Not Set",
            mappedName: item?.employee?.full_name || "Not Set",
            mappedDepartmentName: item?.employee?.department?.name || "Not Set",
            mappedStatus: !!item.status ? "Active" : "Inactive",
            mappedSchedule: item?.employee?.details?.schedule || "Not Set",
          };
        }) || [];

      setTableData(mappedData);
    }
  }, [userList]);

  const handleSortRequest = (key) => {
    let direction = "asc";
    direction = orderDirection === "desc" ? "asc" : "desc";
    setOrderDirection(direction);
    setTableData((prev) => sortArrayTable(prev, direction, key));
  };

  return (
    <div>
      <Loader isLoading={isFetching} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          cursor: "context-menu",
        }}
      >
        <Tooltip title={"Manage company user list"} placement={"right"} arrow>
          <Box>
            <HeaderReports text={"User List"} />
          </Box>
        </Tooltip>
      </Box>
      <UserListContext.Provider value={value}>
        <UserListFilter />
        {!tableData?.length && !isFetching ? (
          <EmptyData />
        ) : (
          <ReportTableContainter style={{ padding: "1em" }}>
            <TableHeader
              isCollapsable={false}
              headerList={UserListTableHeader}
              handleSortRequest={handleSortRequest}
              orderDirection={orderDirection}
            />
            <TableBody>
              {tableData?.map((item) => {
                return <UserLists key={item.id} item={item} />;
              })}
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  count={userList?.data?.total || 0}
                  page={
                    userList?.data?.current_page
                      ? userList.data?.current_page - 1
                      : 0
                  }
                  onPageChange={(e, page) => {
                    handleChangePage(page);
                  }}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableBody>
          </ReportTableContainter>
        )}
        <AddUser />
        <ExportModal />
        <ImportUserCsv />
      </UserListContext.Provider>
    </div>
  );
};

export default UserListsPage;
