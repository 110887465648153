import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { IsLargescreen } from "../../helper/isLargescreen";
import { IsMediumscreen } from "../../helper/isMediumscreen";
const Tiles = ({ tiles, setTiles, setSkeletontiles }) => {
  const largeScreen = IsLargescreen();
  const mediumScreen = IsMediumscreen();
  const handleChange = (event) => {
    setTiles(event.target.value);
    switch (event.target.value) {
      case 2.4:
        setSkeletontiles(2.4);
        break;
      case 3:
        setSkeletontiles(3);
        break;
      case 4:
        setSkeletontiles(4);
        break;
      case 6:
        setSkeletontiles(6);
        break;
      default:
        setSkeletontiles(12);
        break;
    }
  };
  React.useEffect(() => {
    if (largeScreen && tiles !== 2.4) {
      setTiles(2.4);
      setSkeletontiles(2.4);
    }
    if (mediumScreen && tiles !== 3) {
      setTiles(3);
      setSkeletontiles(3);
    }
    if (!mediumScreen && !largeScreen && tiles !== 4) {
      setTiles(4);
      setSkeletontiles(4);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [largeScreen, mediumScreen]);
  const items = [
    {
      value: 2.4,
      name: 5,
      disabled: IsLargescreen() ? false : true,
    },
    {
      value: 3,
      name: 4,
      disabled: IsMediumscreen() ? false : true,
    },
    {
      value: 4,
      name: 3,
      disabled: false,
    },
    {
      value: 6,
      name: 2,
      disabled: false,
    },
    {
      value: 12,
      name: 1,
      disabled: false,
    },
  ];

  return (
    <FormControl
      sx={{
        minWidth: "50px",
      }}
    >
      <InputLabel>Tiles</InputLabel>
      <Select
        size="small"
        sx={{ margin: "5px" }}
        value={tiles}
        label="Tiles"
        onChange={handleChange}
        renderValue={(value) => {
          const item = items.find(({ value: v }) => v === value);
          return item.name;
        }}
      >
        {items.map((it, i) => (
          <MenuItem value={it.value} key={i} disabled={it.disabled}>
            {it.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Tiles;
