import apiService from "../../axios";

export const getCompliance = async (url) => {
  try {
    let response = await apiService.post(
      `v1/timesheet/get-compliance-report` + url,
      {
        Accept: `application/json`,
        "Content-Type": `multipart/form-data`,
        body: JSON.stringify(url),
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
