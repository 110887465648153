export const CHART_COLOR_CODE = [
  {
    code: "CRYP",
    color: "#430505",
  },
  {
    code: "TABLE AU",
    color: "#7ca4b4",
  },
  {
    code: "RIVAL",
    color: "#f0cc64",
  },
  {
    code: "NMG_MM",
    color: "#d0bf80",
  },
  {
    code: "NMG_FL",
    color: "#f3f0c4",
  },
  {
    code: "NMG_LL",
    color: "#515c62",
  },
  {
    code: "WEB_MC",
    color: "#fcca50",
  },
  {
    code: "NMG_NWF",
    color: "#aa6c23",
  },
  {
    code: "HCA",
    color: "#9d5c3a",
  },
  {
    code: "NMG_TABLE AU",
    color: "#dcc749",
  },
  {
    code: "FMT",
    color: "#502b0f",
  },
  {
    code: "ADMIN",
    color: "#28E2BE",
  },
  {
    code: "HR",
    color: "#FA6A6C",
  },
  {
    code: "HTG",
    color: "#e9ee6c",
  },
  {
    code: "WORK",
    color: "#2b180f",
  },
  {
    code: "ADV",
    color: "#ffde77",
  },
  {
    code: "WEB_AHSC",
    color: "#ffa878",
  },
  {
    code: "INS-FMT",
    color: "#ffdc95",
  },
  {
    code: "FLI_N",
    color: "#d1cc8d",
  },
  {
    code: "NMG_OnOF",
    color: "#809e90",
  },
  {
    code: "FOOT",
    color: "#f95fc7",
  },
  {
    code: "HTG-infra",
    color: "#24110c",
  },
  {
    code: "WHS",
    color: "#ffca0a",
  },
  {
    code: "NMG_CRE",
    color: "#ee840f",
  },
  {
    code: "WEB_STA",
    color: "#ff3c2f",
  },
  {
    code: "CAIR",
    color: "#ff994b",
  },
  {
    code: "BLOCK",
    color: "#494e59",
  },
  {
    code: "WEB_OCD",
    color: "#2e1228",
  },
  {
    code: "WEB_AHB",
    color: "#68293a",
  },
  {
    code: "WEB_DEO",
    color: "#db4749",
  },
  {
    code: "PRO",
    color: "#fc7d5e",
  },
  {
    code: "NMG_BMD",
    color: "#6a406e",
  },
  {
    code: "WEB_OXC",
    color: "#d7220e",
  },
  {
    code: "CIIER",
    color: "#3d0707",
  },
  {
    code: "NMG_OXC",
    color: "#fad866",
  },
  {
    code: "DEVE",
    color: "#ab5c15",
  },
  {
    code: "ADR - AD HOC",
    color: "#ee8a2a",
  },
  {
    code: "NMG_PALM",
    color: "#f7e7cb",
  },
  {
    code: "OUT_TABLE AU",
    color: "#591743",
  },
  {
    code: "QUEM",
    color: "#90143e",
  },
  {
    code: "CARP",
    color: "#c7143b",
  },
  {
    code: "WEB_AUSK",
    color: "#ea5340",
  },
  {
    code: "ECZE",
    color: "#f8bc3f",
  },
  {
    code: "ECOMM",
    color: "#ffbe57",
  },
  {
    code: "DESL",
    color: "#ff7b47",
  },
  {
    code: "MYOS",
    color: "#ff4847",
  },
  {
    code: "WETEXT",
    color: "#782525",
  },
  {
    code: "NCRV",
    color: "#d79356",
  },
  {
    code: "ANG_NMG",
    color: "#ed3939",
  },
  {
    code: "HRIS_PS",
    color: "#ff794a",
  },
  {
    code: "OZG",
    color: "#ffa64a",
  },
  {
    code: "NMG_GCLH",
    color: "#0c140c",
  },
  {
    code: "NMG_AOG",
    color: "#743289",
  },
  {
    code: "NEPT",
    color: "#f67b41",
  },
  {
    code: "FLEARN",
    color: "#59125c",
  },
  {
    code: "PADS",
    color: "#bb1e69",
  },
  {
    code: "ManD",
    color: "#633c69",
  },
  {
    code: "GNNG",
    color: "#2c53b3",
  },
  {
    code: "SASS-MGMT",
    color: "#3c1e4e",
  },
  {
    code: "SASS-NEP",
    color: "#ff5500",
  },
  {
    code: "PKG-LAR",
    color: "#b31c45",
  },
  {
    code: "ECOMM - OUT",
    color: "#471a07",
  },
  {
    code: "HRIS-INT",
    color: "#212A3C",
  },
  {
    code: "PLAYBOOK",
    color: "#762c12",
  },
  {
    code: "NMG_PNOY",
    color: "#a63f1e",
  },
  {
    code: "FORM-EN-PHR",
    color: "#d55129",
  },
  {
    code: "WEB-AAPC",
    color: "#eb632f",
  },
  {
    code: "NMG_SKN",
    color: "#313131",
  },
  {
    code: "NMG_BCC",
    color: "#f3a60c",
  },
  {
    code: "WEB-ES-SUP",
    color: "#227092",
  },
  {
    code: "NMG_MYO",
    color: "#121f40",
  },
  {
    code: "ZIPETT",
    color: "#761655",
  },
  {
    code: "EDUC",
    color: "#6d111a",
  },
  {
    code: "ADON - ENHC",
    color: "#ea021c",
  },
  {
    code: "RTS",
    color: "#ffb42d",
  },
  {
    code: "SQUAD",
    color: "#ff6523",
  },
  {
    code: "TSOCIALA",
    color: "#1c141c",
  },
  {
    code: "WEB_PNOY",
    color: "#e8be39",
  },
  {
    code: "WEB_AUSK-CCS",
    color: "#1f2b39",
  },
  {
    code: "WEB_AUSK-CGS",
    color: "#23241f",
  },
  {
    code: "WEB_AUSK-CDE",
    color: "#1a559f",
  },
  {
    code: "WEB_AUSK-DTO",
    color: "#431016",
  },
  {
    code: "WEB_TABLE AU",
    color: "#841104",
  },
  {
    code: "DIGI",
    color: "#e35729",
  },
  {
    code: "BRANDACTIVE",
    color: "#d84009",
  },
  {
    code: "VA-ADMIN",
    color: "#3c232c",
  },
  {
    code: "WEB_URPLA",
    color: "#eda215",
  },
  {
    code: "ICHEM",
    color: "#ff007f",
  },
  {
    code: "WEB-MMAC",
    color: "#a10024",
  },
  {
    code: "WEBNMG_SMILE",
    color: "#d0002e",
  },
  {
    code: "NEXUS",
    color: "#14212a",
  },
  {
    code: "AO-GARLH",
    color: "#88a704",
  },
  {
    code: "PNOY_RB",
    color: "#275664",
  },
  {
    code: "TYLER",
    color: "#feaa01",
  },
  {
    code: "AO-ABC",
    color: "#365627",
  },
  {
    code: "AOG_INFRA",
    color: "#90282c",
  },
  {
    code: "WEBNMG_SIX STAR DESIGNS",
    color: "#a8391c",
  },
  {
    code: "WEBNMG_SIX STAR CONCEPTS",
    color: "#e5dbcc",
  },
  {
    code: "WEB_DR. DON",
    color: "#5d0b05",
  },
  {
    code: "WEB_DEBULKING",
    color: "#cf5e2e",
  },
  {
    code: "WEB_SKINCARE",
    color: "#f99006",
  },
  {
    code: "WEB_SKINDISEASE",
    color: "#ffb402",
  },
  {
    code: "NMG_LIFE EDUC QLD",
    color: "#fccc04",
  },
  {
    code: "NMG_TABLE PH",
    color: "#ffe102",
  },
  {
    code: "OUT_TABLE PH",
    color: "#fef301",
  },
  {
    code: "WEB_TABLE PH",
    color: "#f5d023",
  },
  {
    code: "TABLE PH",
    color: "#fc8000",
  },
  {
    code: "TABLE PAY",
    color: "#ff4e00",
  },
  {
    code: "TABLE GENERAL",
    color: "#f90093",
  },
  {
    code: "WEB_ADA RENOS",
    color: "#000970",
  },
  {
    code: "WEB_NMG_TECHWELL_MAINT",
    color: "#f4ca54",
  },
  {
    code: "WEB_SKN",
    color: "#ccb44b",
  },
  {
    code: "DENTAL",
    color: "#e9c644",
  },
  {
    code: "WEB_NMG_LIFE_MAINT",
    color: "#cca61b",
  },
  {
    code: "MOBIL",
    color: "#f8df53",
  },
  {
    code: "WEB_FINPAY",
    color: "#527FF4",
  },
  {
    code: "FINPAY_MOBILE",
    color: "#527FF4",
  },
  {
    code: "CAPLAN",
    color: "#",
  },
  {
    code: "WEB_EOLO",
    color: "#",
  },
  {
    code: "NMG_AUSK",
    color: "#",
  },
  {
    code: "NMG_AUSK-CCS",
    color: "#",
  },
  {
    code: "NMG_AUSK-CDE",
    color: "#bacfc2",
  },
  {
    code: "NMG_AUSK-CGS",
    color: "#b5c675",
  },
  {
    code: "NMG_AUSK-DTO",
    color: "#c3d398",
  },
  {
    code: "NMG_SCENTED",
    color: "#cce464",
  },
  {
    code: "ANDREW",
    color: "#f23c63",
  },
  {
    code: "TRACK",
    color: "#405e44",
  },
  {
    code: "EOLO",
    color: "#e8e15b",
  },
  {
    code: "AO-ABAD",
    color: "#87ad67",
  },
  {
    code: "WEB_ADVTECHAUS",
    color: "#3a701f",
  },
  {
    code: "IGM",
    color: "#13221d",
  },
  {
    code: "FOOT2",
    color: "#0450f4",
  },
  {
    code: "CDOEKS",
    color: "#000080",
  },
  {
    code: "ABC",
    color: "#ffd700",
  },
  {
    code: "WEB_NMG_ORIENTF",
    color: "#3979f9",
  },
  {
    code: "HOTEL",
    color: "#fc903d",
  },
  {
    code: "CREVIEW",
    color: "#fdc07e",
  },
  {
    code: "COW_P",
    color: "#ff5a1f",
  },
  {
    code: "Adon - Smarter Financial and I",
    color: "#d5ff1c",
  },
  {
    code: "WEB_VALBIA",
    color: "#ffdf1c",
  },
  {
    code: "WEB_HOPSCOTCH",
    color: "#29ffce",
  },
  {
    code: "WEB_VARSITY",
    color: "#582feb",
  },
  {
    code: "OUT_FOOT",
    color: "#cc6c54",
  },
  {
    code: "NEREUS_MAINT",
    color: "#283135",
  },
  {
    code: "REVS_MAINT",
    color: "#bcbba0",
  },
  {
    code: "POWD_MAINT",
    color: "#744723",
  },
  {
    code: "STAREV",
    color: "#bd9c8e",
  },
  {
    code: "FITAZFK",
    color: "#572918",
  },
  {
    code: "WEB_SWAY",
    color: "#98694e",
  },
  {
    code: "WEB_IDEE",
    color: "#2c1c1a",
  },
  {
    code: "TAGR_MAINT",
    color: "#d3b997",
  },
  {
    code: "NXTF_MAINT",
    color: "#293468",
  },
  {
    code: "CRA_WFW",
    color: "#222b56",
  },
  {
    code: "CRA_OTH",
    color: "#1b2244",
  },
  {
    code: "GIVE",
    color: "#5AFF00",
  },
  {
    code: "HOPS",
    color: "#171d39",
  },
  {
    code: "SPEK",
    color: "#2E6280",
  },
  {
    code: "TION2",
    color: "#141932",
  },
  {
    code: "CRAV_v2",
    color: "#f4c935",
  },
  {
    code: "JAXON",
    color: "#f38040",
  },
  {
    code: "STOOP_CALEXICO",
    color: "#f3216d",
  },
  {
    code: "SIGN_PH",
    color: "#2e31a7",
  },
  {
    code: "RSKYE_GEN",
    color: "#7e33a8",
  },
  {
    code: "RSKYE_T915",
    color: "#233e58",
  },
  {
    code: "RSKYE_T915-OP",
    color: "#3357a7",
  },
  {
    code: "RSKYE_T915-NP",
    color: "#4e76ba",
  },
  {
    code: "RSKYE_TTE",
    color: "#f9553a",
  },
  {
    code: "RSKYE_OKE",
    color: "#a82f34",
  },
  {
    code: "RSKYE_FTLK",
    color: "#8547ff",
  },
  {
    code: "RSKYE_RKDS",
    color: "#691fff",
  },
  {
    code: "RSKYE_OCCI",
    color: "#8249f3",
  },
  {
    code: "RSKYE_EDUPH",
    color: "#521bc2",
  },
  {
    code: "RSKYE_BCC",
    color: "#350c86",
  },
  {
    code: "RSKYE_ENGOO",
    color: "#475869",
  },
  {
    code: "RSKYE_51T",
    color: "#072351",
  },
  {
    code: "CRAIG",
    color: "#f57b6e",
  },
  {
    code: "HRIS_APP",
    color: "#bb7290",
  },
  {
    code: "THEPARK",
    color: "#7870ac",
  },
  {
    code: "VUNIFORM",
    color: "#1a1a32",
  },
  {
    code: "HYPER",
    color: "#0B111D",
  },
  {
    code: "ABC_V2",
    color: "#433558",
  },
  {
    code: "ABC_INFRA",
    color: "#9a597a",
  },
  {
    code: "REFER2",
    color: "#e78169",
  },
  {
    code: "FOOT2.1",
    color: "#fc9f61",
  },
  {
    code: "MADZ",
    color: "#083454",
  },
  {
    code: "CROWDF",
    color: "#04344c",
  },
  {
    code: "LOGB",
    color: "#082738",
  },
  {
    code: "RELEAF",
    color: "#476779",
  },
  {
    code: "WEB_SIGN",
    color: "#4c1c04",
  },
  {
    code: "WEB_SPEK",
    color: "#408BA4",
  },
  {
    code: "CARMILE",
    color: "#fcdc78",
  },
  {
    code: "UREVIEW",
    color: "#846c3c",
  },
  {
    code: "TICKET",
    color: "#c19e62",
  },
  {
    code: "DIRECTP",
    color: "#ffe25d",
  },
  {
    code: "MCAL",
    color: "#f7b904",
  },
  {
    code: "FLI-DEVOPS",
    color: "#140c04",
  },
  {
    code: "FLI-SRE",
    color: "#b00506",
  },
  {
    code: "FLI-SECOPS",
    color: "#830f09",
  },
  {
    code: "WEB_QRT",
    color: "#501c4c",
  },
  {
    code: "URPAY_P2",
    color: "#442454",
  },
  {
    code: "URPAY_THUMB",
    color: "#542c64",
  },
  {
    code: "URPAY_SDK3",
    color: "#897ba9",
  },
  {
    code: "RELEAF_HR",
    color: "#642c5c",
  },
  {
    code: "PROP",
    color: "#1f1815",
  },
  {
    code: "TABLE_MAINT",
    color: "#301c16",
  },
  {
    code: "STAD_MAINT",
    color: "#b32c1a",
  },
  {
    code: "STAD_P2",
    color: "#d69c7a",
  },
  {
    code: "CHANGEGPS",
    color: "#f49790",
  },
  {
    code: "CGPS",
    color: "#e78f8c",
  },
  {
    code: "SQUARE",
    color: "#efa49f",
  },
  {
    code: "LEPRE",
    color: "#f7b4db",
  },
  {
    code: "INFINITY",
    color: "#e086b9",
  },
  {
    code: "PROCP",
    color: "#d81a89",
  },
  {
    code: "ORIENT",
    color: "#ed36a1",
  },
  {
    code: "SHOPIF",
    color: "#cc1c7f",
  },
];
