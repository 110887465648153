import { useEffect } from "react";

import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import { useDeclineLeaveApplicationConversion } from "../../../../../hooks/react-query/LeaveManagement/LeaveConversionApplications";
import BaseDialog from "../base/BaseDialog";
import BaseDialogTitle from "../base/BaseDialogTitle";
import ConfirmationButton from "../../button/ConfirmationButton";

const DeclineActionDialog = ({ data = {}, open = false, onClose }) => {
  const { name, request, id } = data;

  const {
    mutate: declineConversion,
    isLoading,
    isSuccess,
  } = useDeclineLeaveApplicationConversion();

  const handleCancel = () => {
    onClose();
  };

  const handleConfirm = () => {
    const payload = { id, conversion: request };

    declineConversion(payload);
  };

  // ? This will cause the dialog to close automatically when the disapproval is successful.
  useEffect(() => {
    isSuccess && onClose();
  }, [isSuccess, onClose]);

  return (
    <BaseDialog open={open} onClose={onClose}>
      <BaseDialogTitle onClose={handleCancel}>
        Decline Conversion Request
      </BaseDialogTitle>

      <DialogContent>
        <DialogContentText>
          Decline Leave Conversion Request of <strong>{name}</strong> for{" "}
          <strong>{request}</strong>?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>

        <ConfirmationButton isLoading={isLoading} onClick={handleConfirm}>
          Confirm
        </ConfirmationButton>
      </DialogActions>
    </BaseDialog>
  );
};

export default DeclineActionDialog;
