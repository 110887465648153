import React from "react";
import { TableCell, TableHead, TableRow } from "@mui/material";

const HeaderUI = ({ headers }) => {
  return (
    <TableHead>
      <TableRow>
        {headers.map((header, key) => (
          <TableCell
            key={key}
            align={header.align}
            style={{ minWidth: header.minW }}
          >
            {header.name}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default HeaderUI;
