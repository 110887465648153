import React from "react";
import { TableRow, TableCell } from "@mui/material";

const UserLists = ({ item }) => {
  return (
    <>
      <TableRow sx={{ height: "4em" }}>
        <TableCell></TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.department,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.full_name,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.date,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.time_in,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.time_out,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.status,
            }}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default UserLists;
