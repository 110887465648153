import { useCallback, useEffect } from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PunchClockIcon from "@mui/icons-material/PunchClock";

import BaseModal from "../../../UserApplicationMgmt/UserApplications/modalComponents/BaseModal";
import StyledButton from "../Button/StyledButton";
import useWindowSize from "../../../../hooks/customHooks/useWindowSize";
import { usePendingEntryAction } from "../../../../hooks/react-query/SetttingsTimesheet/PendingTimesheetEntries";
import {
  ACTION_TYPE,
  CONFIRMATION_TEXT,
} from "../../../../lib/settingsTimesheet/PendingTimesheetEntries";

const BASE_MODAL_CUSTOM_STYLES = {
  maxWidth: "480px",
  height: "max-content",
  display: "flex",
  flexDirection: "column",
  padding: "24px",
  flexFlow: "column",
  gap: "clamp(34px, 3vw, 42px)",
  overflow: "auto",
};

const HEADER_CONTAINER_STYLES = {
  display: "flex",
  gap: "12px",
  alignItems: "center",
};

const HEADER_ICON_STYLES = {
  display: "flex",
  padding: "4px",
  backgroundColor: "#21293C",
  borderRadius: "3px",
};

const CONFIRMATION_TEXT_STYLES = {
  padding: "0 16px",
  lineHeight: 1.6,
  color: "#868e96",
};

const APPROVE_ACTION_BOX_STYLES = { position: "relative", width: "100%" };

const ACTION_BUTTON_STYLES = {
  minWidth: "128px",
  width: "100%",
};

const CIRCULAR_PROGRESS_STYLES = {
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: "-12px",
  marginLeft: "-12px",
  color: "#21293C",
};

const ActionModal = ({ open, onClose, id, action }) => {
  const { width } = useWindowSize();

  const {
    mutate: updatePendingEntry,
    isLoading,
    isSuccess,
  } = usePendingEntryAction();

  const isSmallScreen = width <= 480;

  const stackDirection = isSmallScreen ? "column" : "row";

  const closeModalHandler = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    isSuccess && closeModalHandler();
  }, [isSuccess, closeModalHandler]);

  const submitActionHandler = () => {
    const payload = { id, action };
    updatePendingEntry(payload);
  };

  const confirmationText =
    action === ACTION_TYPE.APPROVE
      ? CONFIRMATION_TEXT.APPROVE
      : CONFIRMATION_TEXT.DISAPPROVE;

  return (
    <BaseModal
      open={open}
      onClose={closeModalHandler}
      sx={BASE_MODAL_CUSTOM_STYLES}
    >
      {/* HEADER */}
      <Box sx={HEADER_CONTAINER_STYLES}>
        <Box sx={HEADER_ICON_STYLES}>
          <PunchClockIcon sx={{ color: "#fff" }} />
        </Box>

        <Typography
          fontSize={18}
          sx={{ textTransform: "capitalize", color: "#21293C" }}
        >
          {`${action} Timesheet Entry`}
        </Typography>
      </Box>

      {/* CONFIRMATION TEXT */}
      <Typography sx={CONFIRMATION_TEXT_STYLES}>{confirmationText}</Typography>

      {/* ACTION BUTTONS */}
      <Stack direction={stackDirection} justifyContent="stretch" spacing={1}>
        <StyledButton
          variant="outlined"
          color="primary"
          size="large"
          onClick={closeModalHandler}
          sx={ACTION_BUTTON_STYLES}
        >
          Cancel
        </StyledButton>

        <Box sx={APPROVE_ACTION_BOX_STYLES}>
          <StyledButton
            variant="contained"
            color="primary"
            size="large"
            disabled={isLoading}
            sx={ACTION_BUTTON_STYLES}
            onClick={submitActionHandler}
          >
            {action}
          </StyledButton>

          {isLoading && (
            <CircularProgress size={24} sx={CIRCULAR_PROGRESS_STYLES} />
          )}
        </Box>
      </Stack>
    </BaseModal>
  );
};

export default ActionModal;
