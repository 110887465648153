import React from "react";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ViewQuestion from "./viewQuestions";
import { useForm } from "react-hook-form";
import hooks from "../../../../hooks/react-query/queryHooks";
import { useQueryClient } from "react-query";

const ViewSurvey = ({
  open,
  handleViewSurvey2,
  viewData,
  customStyle,
  dashboard,
}) => {
  const queryClient = useQueryClient();
  const [hText, setHtext] = React.useState(false);
  const [sButton, setSbutton] = React.useState(false);
  const {
    handleSubmit,
    setValue,
    getValues,
    register,
    formState: {}, // eslint-disable-line
  } = useForm({
    defaultValues: {
      survey_id: viewData.id,
      questions: "",
    },
  });
  const { mutate: submitAnswer } = hooks.useSubmitAnswer();
  const questionObj = viewData.questions.map((vd, index) => {
    if (vd.type_of_answer === "checkbox") {
      const attributes = JSON.parse(vd.attr);
      register(`checkbox`, { value: attributes });
      return {
        id: vd.id,
        question: vd.question,
        type_of_answer: vd.type_of_answer,
      };
    } else {
      register(`ans${index}`);
      return {
        id: vd.id,
        question: vd.question,
        type_of_answer: vd.type_of_answer,
      };
    }
  });
  setValue("questions", questionObj);

  const onSubmit = (data) => {
    setSbutton(true);
    const filterCheckbox = [];
    data?.checkbox?.map((dc) => {
      if (dc.selected === true) {
        filterCheckbox.push({
          option: dc.option,
          answer: dc.answer,
          selected: dc.selected,
        });
      }
    });
    const newPayloadData = data.questions.map((dq, ind) => {
      if (dq.type_of_answer === "checkbox") {
        return {
          id: dq.id,
          question: dq.question,
          type_of_answer: dq.type_of_answer,
          answer: JSON.stringify(filterCheckbox),
        };
      } else if (dq.type_of_answer === "single") {
        return {
          id: dq.id,
          question: dq.question,
          type_of_answer: dq.type_of_answer,
          answer: getValues(`ans${ind}`),
        };
      } else {
        return {
          id: dq.id,
          question: dq.question,
          type_of_answer: dq.type_of_answer,
          answer: JSON.stringify(getValues(`ans${ind}`)),
        };
      }
    });
    submitAnswer(
      { survey_id: data.survey_id, questions: newPayloadData },
      {
        onSuccess: (request) => {
          queryClient.invalidateQueries(["survey-settings"]);
          handleViewSurvey2();
        },
        onError: (request) => {
          setSbutton(false);
          if (request?.response?.data?.message) {
            setHtext(true);
          }
        },
      }
    );
  };
  return (
    <Modal open={open}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card
          className="login-modal leave1-modal"
          sx={{
            minWidth: "550px",
            maxHeight: "calc(100vh - 75px)",
            overflow: "auto",
          }}
        >
          <CardHeader
            title={
              dashboard ? (
                <Typography variant="h3" className="h3-bar">
                  Psych Survey
                </Typography>
              ) : (
                <Typography variant="h3" className="h3-bar">
                  View Survey
                </Typography>
              )
            }
            action={
              dashboard === false && (
                <IconButton onClick={handleViewSurvey2}>
                  <CloseOutlinedIcon sx={{ width: "15px", height: "15px" }} />
                </IconButton>
              )
            }
          />
          <CardContent>
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid item lg={12} xs={12}>
                <Box
                  sx={{
                    m: "20px",
                    p: "10px",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  {dashboard ? (
                    <Chip label="Psych Survey" sx={customStyle.chip} />
                  ) : (
                    <Chip label={viewData.type?.name} sx={customStyle.chip} />
                  )}
                  <Typography sx={customStyle.title}>
                    {viewData.name}
                  </Typography>
                  <Typography sx={customStyle.desc2}>
                    {viewData.description}
                  </Typography>
                </Box>
              </Grid>
              {viewData.questions.map((vq, i) => (
                <ViewQuestion
                  key={i}
                  customStyle={customStyle}
                  vq={vq}
                  index={i}
                  setValue={setValue}
                  getValues={getValues}
                  hText={hText}
                />
              ))}
              <Grid
                item
                lg={12}
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Stack direction="row">
                  <Button
                    variant="contained"
                    disableElevation
                    type="submit"
                    sx={{
                      m: "10px",
                      bgcolor: "#212A3C",
                    }}
                    disabled={sButton}
                  >
                    Submit Answer
                  </Button>
                  {dashboard && (
                    <Button
                      variant="contained"
                      disableElevation
                      sx={{
                        m: "10px",
                        bgcolor: "#00C292",
                      }}
                      onClick={handleViewSurvey2}
                    >
                      Later
                    </Button>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
    </Modal>
  );
};

export default ViewSurvey;
