import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { RANGE_DATES } from "../../../../lib/reports/perActivity";

const RangeSelection = ({ range, handleRangeChange }) => {
  const handleChange = (event) => {
    handleRangeChange(event.target.value);
  };

  const rangeArr = Object.values(RANGE_DATES);

  return (
    <Box sx={{ width: 140 }}>
      <FormControl fullWidth size="small">
        <InputLabel id="range-select-label">Range</InputLabel>

        <Select
          labelId="range-select-label"
          id="range-select"
          value={range}
          label="range"
          onChange={handleChange}
          sx={{ textTransform: "capitalize" }}
        >
          {rangeArr.map((selection) => (
            <MenuItem
              key={selection.name}
              value={selection.name}
              sx={{ textTransform: "capitalize" }}
            >
              {selection.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default RangeSelection;
