import { format, addDays } from "date-fns";
const LEAVE_COLORS = {
  SL: "#f7a83280",
  VL: "#1ec50a80",
  BDYL: "#49ccb980",
  BL: "#2d2d2d80",
  LWP: "#f44336a8",
  SPL: "#1F698080",
  MTYL: "#f19cd680",
  PTYL: "#6CBBD480",
  Regular: "#7b00ff80",
  Special: "#7b00ff80",
  "Company Event80": "#7b00ff",
  default: "#2CA9D580",
};
function LoadColorByType(type) {
  if (!type) return LEAVE_COLORS.default;
  return LEAVE_COLORS[type];
}
export const leaveObjectData = (leave, holiday, birthdays) => {
  let event_arr = [];
  birthdays?.map((b) => {
    const d = new Date();
    let year = d.getFullYear();
    const dob = b.dob.substring(4, 10);
    event_arr.push({
      obj_type: "birthday",
      title: b.lastname + " " + "birthday",
      date: year + dob,
      backgroundColor: "#212A3C",
      borderColor: "#212A3C",
      description: b.full_name,
      modal_content: {
        calendar_type: "birthday",
        employee: b.full_name,
        date: year + dob,
      },
    });
  });

  leave.forEach((data) =>
    event_arr.push({
      obj_type: "leave",
      title: data.leave_ee.owner.name,
      start: format(new Date(data.from), "yyyy-MM-dd"),
      end: format(addDays(new Date(data.to), 1), "yyyy-MM-dd"),
      backgroundColor: LoadColorByType(data.leave_type.shortname),
      borderColor: "#2CA9D5", //yellow,
      chop: true,
      modal_content: {
        calendar_type: "leave",
        id: data.id,
        employee: data.leave_ee.owner.name,
        from: new Date(data.from),
        to: new Date(data.to),
        purpose: data.purpose,
        leave_type: data.leave_type.name,
        date_filed: new Date(data.created_at),
        date_approved: data.approvalDate,
      },
    })
  );

  holiday.forEach((data) =>
    event_arr.push({
      obj_type: "holiday",
      title: data.name,
      start: format(new Date(data.start), "yyyy-MM-dd"),
      end: format(new Date(data.end), "yyyy-MM-dd"),
      backgroundColor: LoadColorByType(data.type), //yellow
      borderColor: "#e70cf7", //yellow,
      modal_content: {
        calendar_type: "holiday",
        id: data.id,
        holiday: data.name,
        from: new Date(data.start),
        to: new Date(data.end),
        includes: data.includes,
        date_filed: new Date(data.created_at),
        type: data.type,
      },
    })
  );

  return event_arr;
};
