import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Paper,
  Typography,
  Button,
  Skeleton,
  Slider,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import { ModalStyle } from "../../../helper/ModalStyle";
import { useAssignSkill } from "../../../hooks/react-query/SettingsSytem/Skills";
import { SystemSkillContext } from "../../../globalstate/SystemSettings/Skill";

const initSkillState = {
  employee: null,
  status: 1,
  skill: [],
};

const EmployeeSkillModal = ({ open, handleClose, data }) => {
  // const { data: listOfskills, isFetching } = useGetSkills();
  const { listOfskills, fetchingSkills } = useContext(SystemSkillContext);
  const [skills, setSkills] = useState();
  const { mutate, isLoading } = useAssignSkill();
  const handleCloseModal = () => {
    handleClose();
    setSkills(initSkillState);
  };
  const handleSave = () => {
    const assignEquipOnly = skills.skill.filter((data) => data.equipped);

    mutate(
      { ...skills, skill: assignEquipOnly },
      {
        onSuccess: handleCloseModal,
      }
    );
  };
  const handleChangeSkillValue = (e, key) => {
    const arr = [...skills.skill];
    arr[key] = { ...skills.skill[key], value: e.target.value };
    setSkills({ ...skills, skill: arr });
  };
  const handleQuip = (e, key) => {
    const arr = [...skills.skill];
    arr[key] = { ...skills.skill[key], equipped: e.target.checked };
    setSkills({ ...skills, skill: arr });
  };
  useEffect(() => {
    if (!fetchingSkills && open) {
      const initskills = listOfskills.data?.map((skill, ind) => {
        // const findSkill = skill.emp_skill.find(
        //   (emp) =>
        //     emp.id === data?.skill[ind]?.id &&
        //     emp.employee === data?.skill[ind]?.employee
        // );
        const findSkill = data.skill.filter((mySkill) =>
          skill.emp_skill.find(
            (usersInput) => mySkill.skill === usersInput.skill
          )
        );

        const obj = {
          id: skill.id,
          name: skill.name,
          color: skill.color,
          value: findSkill[0] ? findSkill[0].value : 0,
          equipped: findSkill[0] ? true : false,
        };
        return obj;
      });

      setSkills({ employee: data.id, status: 1, skill: initskills });
    }
    return () => {
      setSkills();
    };
  }, [data, fetchingSkills, listOfskills, open]);
  // console.log(skills, "skill->",data?.skill);
  return (
    <Modal
      hideBackdrop
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Paper style={ModalStyle} sx={{ width: "30%" }}>
        <div style={{ margin: "15px" }}>
          <Typography
            style={{ fontSize: ".95rem", fontWeight: "bolder" }}
            marginLeft={3}
            mt={3}
          >
            {data?.owner?.name}
          </Typography>
          <Typography marginLeft={3} mt={1} sx={{ fontSize: ".90rem" }}>
            <span style={{ opacity: 0.6 }}> Department:</span>{" "}
            <span> {data?.department?.name}</span>
          </Typography>
          <div style={{ maxHeight: 360, overflow: "scroll" }}>
            {!fetchingSkills ? (
              listOfskills.data.map((skill, key) => (
                <div
                  style={{ marginTop: "12px", marginLeft: "15px" }}
                  key={key}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      checked={skills?.skill[key]?.equipped}
                      onChange={(e) => handleQuip(e, key)}
                    />
                    <div
                      style={{
                        borderRadius: "100%",
                        width: "50px",
                        height: "50px",
                        backgroundColor: skill.color,
                      }}
                    ></div>
                    <div style={{ marginLeft: "10px", width: "75%" }}>
                      <Typography sx={{ fontSize: ".90rem" }}>
                        {skill.name}
                      </Typography>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Slider
                          value={skills?.skill[key]?.value}
                          onChange={(e) => handleChangeSkillValue(e, key)}
                          disabled={!skills?.skill[key]?.equipped}
                          valueLabelDisplay={"auto"}
                          step={1}
                          min={0}
                          max={10}
                          color="primary"
                          className="time-slider"
                          size="small"
                          sx={{ color: skill.color }}
                        />
                        <div
                          style={{
                            marginLeft: "18px",
                            textAlign: "center",
                            fontWeight: "bold",
                            fontSize: ".75rem",
                            paddingTop: "4px",
                            color: "whitesmoke",
                            width: "25px",
                            height: "25px",
                            borderRadius: "100%",
                            backgroundColor: skill.color,
                          }}
                        >
                          {skills?.skill[key]?.value}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <Skeleton />
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "20px",
              gap: "10px",
            }}
          >
            <Button
              size="small"
              style={{
                color: "red",
                border: "1px solid rgba(0, 0, 0, 0.5)",
                textTransform: "none",
              }}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              style={{ backgroundColor: "#00D100", textTransform: "none" }}
              onClick={handleSave}
              //   disabled={
              //     data.name === "" ||
              //     data.color === "" ||
              //     data.sequence === null ||
              //     data.status === "" ||
              //     isLoading
              //   }
              endIcon={isLoading ? <CircularProgress size={20} /> : <></>}
            >
              Save
            </Button>
          </div>
        </div>
      </Paper>
    </Modal>
  );
};

export default EmployeeSkillModal;
