import * as React from "react";
import Modal from "@mui/material/Modal";
import { Box, Button, FormGroup, TextField } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { useState } from "react";
import { addCompliance } from "../../../api/compliance";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export default function AddComplianceModal({ openAdd, closeAddModal }) {
  const queryClient = useQueryClient();
  const [itemName, setItemName] = useState("");

  const addComplianceMutation = useMutation(() => addCompliance(itemName), {
    onSuccess: async (response) => {
      await queryClient.refetchQueries("get-compliance");
      closeAddModal();
    },
  });

  const cleanInputBeforeClose = () => {
    setItemName("");
    closeAddModal();
  };

  const confirmAddFunc = () => {
    addComplianceMutation.mutate();
  };

  return (
    <div>
      <Modal
        keepMounted
        open={openAdd}
        onClose={cleanInputBeforeClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <FormGroup>
            <Box sx={{ fontWeight: "bold", fontSize: 25, mb: "25px" }}>
              ADD COMPLIANCE ITEM
            </Box>
            <TextField
              value={itemName}
              onChange={(e) => setItemName(e.target.value)}
              sx={{ mb: "10px" }}
              label="Item"
              id="itemCompliance"
            />
            <Box sx={{ mt: "10px", display: "flex", justifyContent: "center" }}>
              <Button
                onClick={() => confirmAddFunc()}
                sx={{ mx: "10px" }}
                variant="contained"
                color={"success"}
              >
                CONFIRM
              </Button>
              <Button
                onClick={cleanInputBeforeClose}
                sx={{ mx: "10px" }}
                variant="outlined"
                color={"error"}
              >
                CANCEL
              </Button>
            </Box>
          </FormGroup>
        </Box>
      </Modal>
    </div>
  );
}
