import apiService from "../axios";
import moment from "moment";

export const getTimekeepingReport = async ({
  page_size = 30,
  fromDate,
  toDate,
  includeGrace = true,
  inActive = true,
  search,
  page = 1,
}) => {
  try {
    let response = await apiService.get(
      `v5/time-inout/time-entry/get-report?page_size=${page_size}&fromDate=${fromDate}&toDate=${toDate}&includeGrace=${includeGrace}&inActive=${inActive}&search=${search}&page=${page}`,
      {
        Accept: `application/json`,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const generateDownloadableTimekeepingReport = async ({
  fromDate,
  toDate,
  includeGrace = true,
  inActive = true,
  type,
}) => {
  try {
    const response = await apiService.get(
      `v5/time-inout/time-entry/export?fromDate=${fromDate}&toDate=${toDate}&includeGrace=${includeGrace}&inActive=${inActive}&ext=${type}`,
      {
        "Content-Type": "application/json",
        body: JSON.stringify(),
        responseType: "blob",
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    const fileName =
      moment(fromDate).format("ll") +
      " - " +
      moment(toDate).format("ll") +
      " Breakdown Per Code";
    const ext = type === "csv" ? ".csv" : ".xlsx";
    link.href = url;
    link.setAttribute("download", fileName + ext);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

    return true;
  } catch (error) {
    throw error;
  }
};
