import React from "react";
import Modal from "@mui/material/Modal";
import Grow from "@mui/material/Grow";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  format,
  getDay,
  differenceInMinutes,
  differenceInHours,
  isAfter,
  isBefore,
} from "date-fns";
import hooks from "../../hooks/react-query/queryHooks";
import { useForm } from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";
import InputController from "../InputController";
import { yupResolver } from "@hookform/resolvers/yup";
import { overTimeSchema } from "../../helper/Yup/overTimeSchema";
import { useQueryClient } from "react-query";

const padTo2Digits = (num) => {
  return num.toString().padStart(2, "0");
};

const computeForHoursMinutes = (start, end) => {
  const msInMinute = 60 * 1000;
  const diffMin = Math.round(Math.abs(end - start) / msInMinute);
  // const diffMin = differenceInMinutes(end, start);
  const minutes = (diffMin % 60) / 60;
  const hours = Math.floor(diffMin / 60);
  const hourMin = hours + "." + minutes.toFixed(2).toString().replace("0.", "");
  // padTo2Digits(hours) + "." + minutes.toFixed(2).toString().replace("0.", "");

  return hourMin;
};

const Overtime = ({
  overTime,
  handleOT,
  user,
  projectType,
  projectCode,
  projectActivity,
}) => {
  const queryClient = useQueryClient();

  const [appType, setApptype] = React.useState(true);
  const [status, setStatus] = React.useState("");
  const [formRhf, setFormrhf] = React.useState(true);
  const [isSending, setIsSending] = React.useState(false);
  const { mutate: addOTmutation } = hooks.useAddSubmitOT();
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(overTimeSchema),
    defaultValues: {
      startTime: new Date(),
      endTime: new Date(),
      purpose: "",
      hours: 0,
    },
  });

  const refetchMyQuery = () => {
    queryClient.refetchQueries(["get-employee-request-data-with-page"]);
  };
  const handleApplyType = () => {
    appType ? setApptype(false) : setApptype(true);
  };

  const hourValueSetter = (date1, date2) => {
    setValue("hours", computeForHoursMinutes(date1, date2));
  };
  const handleDate = (event, dayParam) => {
    const start = getValues("startTime");
    const end = getValues("endTime");

    if (dayParam === 1) {
      setValue("startTime", event);
      hourValueSetter(event, end);
      if (isAfter(event, end)) {
        setValue("endTime", event);
        hourValueSetter(event, event);
      }
    }

    if (dayParam === 2) {
      setValue("endTime", event);
      hourValueSetter(start, event);
      if (isBefore(event, start)) {
        setValue("startTime", event);
        hourValueSetter(event, event);
      }
    }
  };

  const timeRef = React.useRef(null);
  const onMutationTimer = () => {
    setTimeout(() => {
      if (timeRef.current === null) {
        setStatus("success");
        setIsSending(false);
      }
    }, 3000);
  };
  const handleUnderTime = () => {
    setIsSending(true);
    setFormrhf(false);
    onMutationTimer();
    const dataParam = {
      api: true,
      break_hrs: 0,
      id: null,
      hours: getValues("hours"),
      from: format(getValues("startTime"), "LLL dd, yyyy h:mm aa"),
      to: format(getValues("endTime"), "LLL dd, yyyy h:mm aa"),
      ot_activity: "0",
      ot_code: "0",
      ot_work_type: "0",
      ot_dept: user.employee.department.short_name,
      lunch: false,
      purpose: getValues("purpose"),
      time_type: getValues("appType"),
    };
    addOTmutation(dataParam, {
      onSuccess: (request) => {
        setIsSending(false);
        setStatus(request.status);
        timeRef.current = request.status;
      },
      onError: (request) => {
        timeRef.current = request.response.data.status;
        setStatus(request.response.data.status);
      },
    });
  };
  const onSubmit = async (data) => {
    setIsSending(true);
    setFormrhf(false);
    onMutationTimer();
    const dataParam = {
      api: true,
      break_hrs: 0,
      id: null,
      hours: data.hours,
      from: format(data.startTime, "LLL dd, yyyy h:mm aa"),
      to: format(data.endTime, "LLL dd, yyyy h:mm aa"),
      ot_activity: data.activity,
      ot_code: data.code,
      ot_work_type: data.type,
      ot_dept: user.employee.department.short_name,
      lunch: false,
      purpose: data.purpose,
      time_type: data.appType,
    };
    addOTmutation(dataParam, {
      onSuccess: (request) => {
        setIsSending(false);
        setStatus(request.status);
        timeRef.current = request.status;
      },
      onError: (request) => {
        timeRef.current = request.response.data.status;
        setStatus(request.response.data.status);
      },
    });
  };

  const hoursChecker = (event, dayParam) => {
    const start = getValues("startTime");
    const end = getValues("endTime");

    if (dayParam === 1) {
      setValue("startTime", event);
      hourValueSetter(event, end);
    }

    if (dayParam === 2) {
      setValue("endTime", event);
      hourValueSetter(start, event);
    }
  };

  return (
    <Modal open={overTime}>
      <Grow
        in={overTime}
        style={{ transformOrigin: "0 0 0" }}
        {...(overTime ? { timeout: 500 } : {})}
        unmountOnExit
      >
        <Card
          className="login-modal leave1-modal"
          sx={{
            minWidth: "350px",
            maxHeight: "calc(100vh - 75px)",
            overflowY: "scroll",
          }}
        >
          <CardHeader
            title={
              <Typography variant="h3" className="h3-bar">
                Overtime / Undertime Application
              </Typography>
            }
            action={
              <IconButton onClick={handleOT}>
                <CloseOutlinedIcon sx={{ width: "15px", height: "15px" }} />
              </IconButton>
            }
          />
          <CardContent>
            {status && (
              <Box sx={{ textAlign: "center", margin: "0px 20px 10px 20px" }}>
                {status && (
                  <Chip
                    label={<Typography variant="overline">{status}</Typography>}
                    color={status === "duplicate" ? "error" : "success"}
                  />
                )}
                {status === "duplicate" ? (
                  <Typography sx={{ m: 1 }}>Duplicate application</Typography>
                ) : (
                  <Typography sx={{ m: 1 }}>
                    Successfully added application
                  </Typography>
                )}
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  {status && (
                    <Button
                      onClick={() => {
                        handleOT();
                        refetchMyQuery();
                      }}
                    >
                      Close
                    </Button>
                  )}
                </Box>
              </Box>
            )}
            {isSending && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            )}
            {formRhf && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container rowSpacing={3} columnSpacing={1}>
                  <Grid item xs={12} lg={6}>
                    <Box className="typ-over">
                      <Typography
                        variant="overline"
                        sx={{ paddingLeft: "10px", alignSelf: "center" }}
                      >
                        Select Application Type
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputController
                      type="select"
                      options={[
                        { value: "Over Time", label: "Overtime" },
                        { value: "Under Time", label: "Undertime" },
                      ]}
                      control={control}
                      label="Application Type"
                      name="appType"
                      errors={errors}
                      defaultValue={"Over Time"}
                      handleSelectFunc={handleApplyType}
                      size="small"
                    />
                  </Grid>
                  {/* ///// */}
                  {/* <Grid item xs={12} lg={3}>
                    <InputController
                      type="date"
                      control={control}
                      name="statDate"
                      errors={errors}
                      label="Select Date"
                      size="small"
                      handleDateFunc={handleDate}
                    />
                  </Grid> */}
                  <Grid item xs={12} lg={3}>
                    <InputController
                      type="date"
                      control={control}
                      label="Start Date"
                      name="startTime"
                      errors={errors}
                      size="small"
                      handleDateFunc={handleDate}
                      dateNum={1}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <InputController
                      type="timePicker"
                      control={control}
                      label="Start Time"
                      name="startTime"
                      errors={errors}
                      size="small"
                      handleTimePickFunc={hoursChecker}
                      timeNum={1}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <InputController
                      type="date"
                      control={control}
                      label="End Date"
                      name="endTime"
                      errors={errors}
                      size="small"
                      handleDateFunc={handleDate}
                      dateNum={2}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <InputController
                      type="timePicker"
                      control={control}
                      label="End Time"
                      name="endTime"
                      errors={errors}
                      size="small"
                      handleTimePickFunc={hoursChecker}
                      timeNum={2}
                    />
                  </Grid>
                  {/* /////// */}
                  {/* <Grid item xs={12} lg={appType ? 4 : 6}>
                    <InputController
                      type="dateTime"
                      control={control}
                      label="Start Date"
                      name="startTime"
                      errors={errors}
                      size="small"
                      handleTimePickFunc={hoursChecker}
                      timeNum={1}
                    />
                  </Grid>
                  <Grid item xs={12} lg={appType ? 4 : 6}>
                    <InputController
                      type="dateTime"
                      control={control}
                      label="End Date"
                      name="endTime"
                      errors={errors}
                      size="small"
                      handleTimePickFunc={hoursChecker}
                      timeNum={2}
                    />
                  </Grid> */}

                  {appType && (
                    <>
                      <Grid item xs={12} lg={1.5}>
                        <InputController
                          type="text"
                          control={control}
                          label="Hours"
                          name="hours"
                          errors={errors}
                          InputProps={{
                            readOnly: true,
                          }}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} lg={3.5}>
                        <InputController
                          type="autoComplete"
                          control={control}
                          label="Type"
                          name="type"
                          errors={errors}
                          size="small"
                          options={projectType}
                          rules={{
                            required: true,
                          }}
                          isDefaultErrorAlert={true}
                        />
                      </Grid>
                      <Grid item xs={12} lg={3.5}>
                        <InputController
                          type="autoComplete"
                          control={control}
                          label="Code"
                          name="code"
                          errors={errors}
                          size="small"
                          options={projectCode}
                          rules={{
                            required: true,
                          }}
                          isDefaultErrorAlert={true}
                        />
                      </Grid>
                      <Grid item xs={12} lg={3.5}>
                        <InputController
                          type="autoComplete"
                          control={control}
                          label="Activity"
                          name="activity"
                          errors={errors}
                          size="small"
                          options={projectActivity}
                          rules={{
                            required: true,
                          }}
                          isDefaultErrorAlert={true}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    <InputController
                      type="text"
                      control={control}
                      label="Purpose"
                      name="purpose"
                      errors={errors}
                      rows={7}
                      rules={{ required: true }}
                      isDefaultErrorAlert={true}
                    />
                  </Grid>
                </Grid>

                <CardActions sx={{ justifyContent: "right" }}>
                  {appType ? (
                    <Button
                      disableElevation
                      variant="contained"
                      size="small"
                      sx={{ m: "5px" }}
                      type="submit"
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      disableElevation
                      variant="contained"
                      size="small"
                      sx={{ m: "5px" }}
                      onClick={() => {
                        handleUnderTime();
                      }}
                    >
                      Submit
                    </Button>
                  )}
                </CardActions>
              </form>
            )}
          </CardContent>
        </Card>
      </Grow>
    </Modal>
  );
};

export default Overtime;
