import React from "react";
import { Autocomplete, TextField } from "@mui/material";

const CustomAutocomplete = ({
  data,
  minWidth,
  size,
  label,
  content,
  loading,
  open,
  defValue,
  onChange,
}) => {
  return (
    <Autocomplete
      value={defValue}
      onChange={onChange}
      disablePortal
      size={size}
      id="combo-box-demo"
      options={data}
      loading={loading}
      getOptionLabel={(option) => option[content]}
      isOptionEqualToValue={(option, value) =>
        option[content] === value[content]
      }
      sx={{ minWidth: minWidth }}
      renderInput={(params) => <TextField {...params} label={label} />}
      open={open}
    />
  );
};

export default CustomAutocomplete;
