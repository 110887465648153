import * as React from "react";
import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  Paper,
  TablePagination,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  Typography,
  Stack,
} from "@mui/material/";

import { useEffect } from "react";
import LeaveCreditModal from "./LeaveCreditModal";
import leaveCredit from "../../../hooks/react-query/LeaveCredit";
import SearchComponent from "../../../component/SearchComponent";
import TableContainerPaginatedAPI from "../../../component/CustomTableContainer/TableContainerPaginatedAPI";
import {
  yearRange,
  firstObj,
  lastObj,
  addEdittoArray,
  editableFields,
  updateArray,
} from "../../../helper/LeaveCredit";
import { useQueryClient } from "react-query";
import { Tooltip } from "@mui/material";

const Leavecredits = () => {
  const queryClient = useQueryClient();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [batchYear, setBatchYear] = React.useState(new Date().getFullYear());
  const [inactive, setInactive] = React.useState(true);
  const [openModal, setOpenModal] = React.useState(false);
  const [leaveData, setLeaveData] = React.useState([]);
  const [searched, setSearched] = React.useState("");
  const [query, setQuery] = React.useState("");
  const [updatedData, setUpdatedData] = React.useState("");

  React.useEffect(() => {
    setPage(0);
    if (query.length === 0) {
      setUpdatedData("");
    }
  }, [query]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setUpdatedData("");
  };

  const handleChangeYear = (event) => {
    setBatchYear(event.target.value);
  };
  // header
  const { data: leaveCreditHead } = leaveCredit.useLeaveCreditHead();
  const leaveCreditHeader = leaveCreditHead?.data;

  const {
    data: leaveCreditQuery,
    isFetching: postCreditFetching,
    refetch: dataRefetched,
  } = leaveCredit.useLeaveCredit(
    batchYear,
    page + 1,
    rowsPerPage,
    query,
    inactive
  );

  const { data: showAllLeaveCreditQuery, refetch: showAllDataRefetched } =
    leaveCredit.useShowAllLeaveCredit(
      batchYear,
      page + 1,
      rowsPerPage,
      query,
      inactive
    );

  const leaveCreditData = inactive
    ? updatedData.length === 0
      ? leaveCreditQuery?.data
      : updatedData
    : updatedData.length === 0
    ? showAllLeaveCreditQuery?.data
    : updatedData;

  const { mutateAsync: updateMutation } =
    leaveCredit.useUpdateLeaveCredit(inactive);

  const submitForMutation = (data) => {
    updateMutation(data, {
      onSuccess: async (data) => {
        setUpdatedData(updateArray(leaveCreditData, data.data, "id"));
        queryClient.setQueriesData(
          inactive ? "leave-credit" : "show-all-leave-credit",
          (oldData) => {
            oldData.data = updateArray(oldData.data, data.data, "id");
            return oldData;
          }
        );
      },
    });
  };
  useEffect(() => {
    if (batchYear) {
      setUpdatedData("");
      dataRefetched();
      showAllDataRefetched();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchYear]);

  const handleChangeChecked = () => {
    setInactive(!inactive);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setUpdatedData("");
  };

  return (
    <Paper
      sx={{
        padding: 2,
        borderRadius: 5,
        marginLeft: 2,
        marginRight: 2,
        width: "97%",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          cursor: "context-menu",
        }}
      >
        <Tooltip
          title={"Manage company leave credits"}
          placement={"right"}
          arrow
        >
          <Typography
            variant="h4"
            component="h4"
            sx={{ padding: 1, marginBottom: 1 }}
          >
            Leave Credits
          </Typography>
        </Tooltip>
      </Box>{" "}
      <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
        <Stack direction="row" sx={{ flexGrow: 1, marginLeft: "3px" }}>
          <FormControl>
            <InputLabel id="select-small">Year</InputLabel>
            <Select
              sx={{ width: "5rem", height: "37px" }}
              labelId="select-small"
              id="select-small"
              value={batchYear}
              label="Year"
              onChange={handleChangeYear}
            >
              {yearRange.map((range, index) => (
                <MenuItem key={index} value={range}>
                  {range}
                </MenuItem>
              ))}
            </Select>
            <FormControlLabel
              control={
                <Switch checked={!inactive} onChange={handleChangeChecked} />
              }
              sx={{ width: "10rem" }}
              label="Show Inactive"
            />
          </FormControl>
        </Stack>
        <Stack direction="row" sx={{ alignItems: "flex-start" }}>
          <SearchComponent
            placeholder="Search user"
            setSearched={setSearched}
            searched={searched}
            setQuery={setQuery}
          />
        </Stack>
      </Stack>
      <TableContainerPaginatedAPI
        columnhead={[firstObj].concat(leaveCreditHeader).concat([lastObj])}
        filtereditems={addEdittoArray(leaveCreditData || Array(10).fill([""]))}
        isFetching={postCreditFetching}
        setLeaveData={setLeaveData}
        setOpenModal={setOpenModal}
        type="leavecredit"
      />
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={
          (inactive
            ? leaveCreditQuery?.total
            : showAllLeaveCreditQuery?.total) || 0
        }
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showFirstButton
        showLastButton
      />
      <LeaveCreditModal
        items={leaveCreditHeader}
        editableFields={editableFields}
        open={openModal}
        setOpenModal={setOpenModal}
        leaveData={leaveData}
        batchYear={batchYear}
        submitForMutation={submitForMutation}
      />
    </Paper>
  );
};

export default Leavecredits;
