import apiService from "../../api/axios";

export const API_ENDPOINT = {
  GET_CLIENTS: `v5/clients/get-clients`,
  POST_CLIENTS: `v5/clients/edit-client`,
  VALIDATE_CLIENTS: `v5/clients/client-validate`,
  ADD_CLIENTS: `v1/clients/add-client`,
  DELETE_CLIENTS: `v5/clients/delete-client`,
  GET_LEAVE_AND_ABSENCES: `v5/employee/get-leave-absences-summary`,
  GET_PER_CLIENTS: "v5/timesheet/client-report",
  GET_WFH_SUMMARY: `v5/employee/wfh-summary-report`,
  POST_AUDIT_LOG: `v5/logs/audit`,
  POST_EXPORT_REPORT_PER_CLIENT:
    "v1/timesheet/export/generate-excel-client-report",
};

export const genGetRequest = async (param, apiEndpoint) => {
  try {
    let response = await apiService.get(apiEndpoint, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(),
      params: param,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const genPostRequest = async (data, param, apiEndpoint) => {
  try {
    let response = await apiService.post(apiEndpoint, {
      ...data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
