import { useState, useEffect, useContext } from "react";

import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import { APP_TYPE } from "../../../../lib/UserAppMgmt/UserApplications";
import { findSetting } from "../../../../helper/UserApplicationMgmt/UserApplications";
import { GlobalContext } from "../../../../App";

const CONTAINER_STYLES = {
  display: "flex",
  gap: "6px",
};

const BASE_BUTTON_STYLES = {
  width: "100%",
  minHeight: "44.75px",
  backgroundColor: "transparent",
  color: "#222",
  border: "1px solid #dee2e6",
  textTransform: "capitalize",
  borderRadius: "8px",
};

const BASE_BADGE_STYLES = {
  flex: 1,
  "& .MuiBadge-badge": {
    right: 5,
    boxShadow: "0 2px 2px rgba(255, 0, 0, 0.2)",
  },
};

const badgeProps = {
  color: "error",
  max: 99,
  sx: BASE_BADGE_STYLES,
};

const SELECTED_BUTTON_STYLES = {
  color: "#fff",
  boxShadow: "0 2px 3px rgba(0, 0, 0, 0.1)",
  border: "1px solid transparent",
};

const applicationType = Object.values(APP_TYPE);

const CustomToggleButtonGroup = ({
  data,
  value = APP_TYPE.leave.id,
  width,
  onChange,
}) => {
  const [totalCount, setTotalCount] = useState({
    leave: 0,
    ot: 0,
    wfh: 0,
    forgotTimeInOut: 0,
  });
  useEffect(() => {
    if (data?.totalApplicationCounts)
      setTotalCount(data.totalApplicationCounts);
  }, [data]);

  const { settings } = useContext(GlobalContext);

  const buttonBackgroundColor =
    findSetting("bgcolor_appbar", settings) || "#21293C";

  const showFullWidth = width <= 1100;
  const containerMinWidth = showFullWidth ? "100%" : "fit-content";
  const buttonMinWidth = showFullWidth ? "0" : "164px";

  return (
    <Box sx={{ ...CONTAINER_STYLES, minWidth: containerMinWidth }}>
      {applicationType.map((application) => {
        const selectedButtonStyles =
          application.id === value
            ? {
                ...SELECTED_BUTTON_STYLES,
                backgroundColor: buttonBackgroundColor,
              }
            : {};

        const badgeContent = totalCount[application.id];

        return (
          <Badge
            key={application.title}
            {...badgeProps}
            sx={{
              flex: 1,
              "& .MuiBadge-badge": {
                right: badgeContent > 9 ? 8 : 5,
                boxShadow: "0 2px 2px rgba(255, 0, 0, 0.2)",
              },
            }}
            badgeContent={badgeContent}
          >
            <Button
              sx={{
                ...BASE_BUTTON_STYLES,
                ...selectedButtonStyles,
                minWidth: buttonMinWidth,
                "&:hover": {
                  backgroundColor: buttonBackgroundColor,
                  color: "#fff",
                  opacity: "0.65",
                },
              }}
              onClick={onChange.bind(null, application.id)}
            >
              {width < 900 ? application.alternateTitle : application.title}
            </Button>
          </Badge>
        );
      })}
    </Box>
  );
};

export default CustomToggleButtonGroup;
