import { useQuery } from "react-query";
import {
  peruser,
  getReportPerUser,
  getReportPerUserProject,
  getReportPerUserCode,
  getReportPerUserActivity,
  getReportPerUserTimesheet,
} from "../../../api/peruser";

const useTimesheet = (payload) => {
  return useQuery(["get-timesheet-per-user-v2"], () => peruser(payload));
};

const usePerUser = (params) => {
  return useQuery(["get-report-per-user", params.criteria], () =>
    getReportPerUser(params.payload)
  );
};

const usePerUserProject = (payload) => {
  return useQuery(["get-report-per-user-project"], () =>
    getReportPerUserProject(payload)
  );
};

const usePerUserCode = (payload) => {
  return useQuery(["get-report-per-user-code"], () =>
    getReportPerUserCode(payload)
  );
};

const usePerUserActivity = (payload) => {
  return useQuery(["get-report-per-user-activity"], () =>
    getReportPerUserActivity(payload)
  );
};

const usePerUserTimesheet = (payload) => {
  return useQuery(["get-report-per-user-timesheet"], () =>
    getReportPerUserTimesheet(payload)
  );
};

const queries = {
  useTimesheet,
  usePerUser,
  usePerUserProject,
  usePerUserCode,
  usePerUserActivity,
  usePerUserTimesheet,
};

export default queries;
