import { useQuery, useMutation, useQueryClient } from "react-query";
import api from "../../../../api/settings/timesheet/index";
export const useGetProjectCode = (
  per_page,
  search,
  status,
  page,
  department
) => {
  const deptParams = department === "ALL" ? "" : department;
  return useQuery(
    ["get-project-code", per_page, search, status, page, deptParams],
    () => api.GetProjectCode(per_page, search, status, page, deptParams)
  );
};

export const useEditProjectCode = () => {
  const queryClient = useQueryClient();
  return useMutation(api.EditProjectCode, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["get-project-code"]);
      return res;
    },
  });
};

export const useAddProjectCode = () => {
  const queryClient = useQueryClient();
  return useMutation(api.AddProjectCode, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["get-project-code"]);
      return res;
    },
  });
};

export const useDeleteProjectCode = () => {
  const queryClient = useQueryClient();
  return useMutation(api.DeleteProjectCode, {
    onSuccess: () => {
      queryClient.invalidateQueries(["get-project-code"]);
    },
  });
};

export const useImportCode = () => {
  const queryClient = useQueryClient();
  return useMutation(api.ImportProjectCode, {
    onSuccess: () => {
      queryClient.invalidateQueries(["get-project-code"]);
    },
  });
};

export const useGetHistory = (id) => {
  return useQuery(["get-project-code-history"], () => api.getHistory(id));
};

export const useEditRemarks = () => {
  const queryClient = useQueryClient();
  return useMutation(api.editHistoryRemarks, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["get-project-code-history"]);
      return res;
    },
  });
};

export const useGetExportCodes = () => {
  return useQuery(["get-export-codes"], () => api.getExportCodes());
};
