import { TableHead, TableRow, TableSortLabel } from "@mui/material";
import StyledTableCell from "../../../UserApplicationMgmt/UserApplications/tableComponents/StyledTableCell";

import {
  ORDER,
  HEADER_CELL_ID,
  headerCells,
} from "../../../../lib/settingsTimesheet/PendingTimesheetEntries";

const TableHeader = ({ order, orderBy, onChangeOrder }) => {
  const changeOrderHandler = (id, isSortable) => {
    if (!isSortable) return;
    onChangeOrder(id);
  };
  return (
    <TableHead>
      <TableRow>
        {headerCells.map((headerCellEl) => {
          const { id, isSortable, label } = headerCellEl;

          const leftAligned = [HEADER_CELL_ID.NAME, HEADER_CELL_ID.DETAILS];

          const align = leftAligned.includes(id) ? "left" : "center";

          return (
            <StyledTableCell
              key={id}
              sortDirection={orderBy === id ? order : false}
              align={align}
            >
              <TableSortLabel
                sx={{ width: "max-content" }}
                active={orderBy === id}
                direction={orderBy === id ? order : ORDER.ASCENDING}
                hideSortIcon
                onClick={changeOrderHandler.bind(null, id, isSortable)}
              >
                {label}
              </TableSortLabel>
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
