import React, { useContext, useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import TableAutocomplete from "./autocomplete";
import { TimesheetContext } from "../../globalstate/UserContext/timesheetcontext";
import moment from "moment/moment";
import Entry from "../timesheet/entry";
import AutoCompleteMUI from "../DropDown/AutoCompleteMUI";
import Summary from "../timesheet/Summary";
import { GlobalContext } from "../../App";
import Skeleton from "@mui/material/Skeleton";

const Timesheet = ({ timeData, user, isFetching }) => {
  const {
    selectedCode,
    setSelectedCode,
    selectedActivity,
    setSelectedActivity,
    setDate,
    setMyDuration,
    setIsEntry,
    weeklyDataTimesheet,
    totalHours,
    weekDates,
    handleEntryModal,
  } = useContext(TimesheetContext);
  const { projectCode, projectActivity } = useContext(GlobalContext);
  const [addTimesheet, setAddtimesheet] = useState(false);
  const [isDisable, setIsDisable] = useState();
  const [openSummaryModal, setOpenSummaryModal] = useState(false);
  const [timesheetSummary, setTimesheetSummary] = useState([]);

  useEffect(() => {
    selectedCode && selectedActivity ? setIsDisable(false) : setIsDisable(true);
  }, [selectedCode, selectedActivity]);

  const handleInput = (value, index) => {
    const date = moment(weekDates[index]).format("llll");
    const duration = parseFloat(value);
    setDate(date);
    setMyDuration(duration);
    if (selectedCode && selectedActivity) {
      handleEntryModal();
      setAddtimesheet(false);
    } else {
      setIsDisable(true);
    }
  };
  const tableHeader = (day, date) => {
    return (
      <>
        <Typography variant="h6" className="ts-h6">
          {day}
        </Typography>
        <Typography variant="h6" className="ts-h7">
          {date}
        </Typography>
      </>
    );
  };
  const tableCodeActivity = (width, dataName) => {
    return (
      <Card
        className="ts-card waves2"
        sx={{
          minWidth: width,
        }}
      >
        <Typography
          variant="h6"
          className="time-h6
 "
          sx={{
            overflow: "hidden",
            maxWidth: "70px",
          }}
        >
          {dataName}
        </Typography>
      </Card>
    );
  };
  const HandleOpenSummary = (key) => {
    setOpenSummaryModal(true);
    setTimesheetSummary(weeklyDataTimesheet[key].time);
  };
  const HandleCloseSummary = () => setOpenSummaryModal(false);
  return (
    <Card className="tm-card">
      <CardHeader
        title={
          <Typography variant="h3" className="h3-bar">
            This Week's Timesheet Entry
          </Typography>
        }
        action={
          <Button
            size="small"
            className="timest-button"
            startIcon={<AddTaskOutlinedIcon />}
            disableElevation
            variant="contained"
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            onClick={() => {
              if (addTimesheet) {
                setAddtimesheet(false);
                setSelectedActivity();
                setSelectedCode();
              } else {
                setAddtimesheet(true);
              }
            }}
            disabled={isFetching}
          >
            Add Entry
          </Button>
        }
      />
      <CardContent
        sx={{
          paddingTop: "0px",
          paddingBottom: "5px !important",
        }}
      >
        {isFetching ? (
          <Typography variant={"h4"}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Typography>
        ) : (
          <TableContainer sx={{ height: 283, position: "relative" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell className="td-th">
                    <Chip className="ts-chip" size="small" label="Code" />
                  </TableCell>
                  <TableCell className="td-th">
                    <Chip className="ts-chip" size="small" label="Activity" />
                  </TableCell>
                  {weekDates.map((date, key) => (
                    <TableCell className="td-th" key={key}>
                      {tableHeader(
                        moment(date).format("dddd").substring(0, 3),
                        date.substr(0, 6).replace(",", "")
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {addTimesheet && (
                  <TableRow hover sx={{ height: "50px" }}>
                    <TableCell className="td-th">
                      <Box className="ts-chipbox">
                        <AutoCompleteMUI
                          Label={"Code"}
                          data={projectCode}
                          selectedValue={setSelectedCode}
                          required={"true"}
                          errorInput={selectedCode === undefined ? true : false}
                          width={"100px"}
                        />
                      </Box>
                    </TableCell>
                    <TableCell className="td-th">
                      <Box className="ts-chipbox">
                        <AutoCompleteMUI
                          Label={"Activity"}
                          data={projectActivity}
                          selectedValue={setSelectedActivity}
                          required={"true"}
                          errorInput={
                            selectedActivity === undefined ? true : false
                          }
                          width={"120px"}
                        />
                      </Box>
                    </TableCell>
                    {[0, 1, 2, 3, 4, 5, 6].map((week, key) => (
                      <TableCell key={key} className="td-th">
                        <TableAutocomplete
                          index={key}
                          hour={[0.0]}
                          color={isDisable ? "#b0afaf" : ""}
                          bgcolor={""}
                          select={handleInput}
                          disabled={isDisable}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                )}
                {weeklyDataTimesheet?.map((index, key) => (
                  <TableRow hover sx={{ height: "50px" }} key={key}>
                    <TableCell
                      className="td-th"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        HandleOpenSummary(key);
                      }}
                    >
                      {tableCodeActivity(
                        "70px",
                        index.time
                          .map((code) => code.time_code.name)
                          .filter(
                            (value, index, self) =>
                              self.indexOf(value) === index
                          )
                      )}
                    </TableCell>
                    <TableCell className="td-th">
                      {tableCodeActivity(
                        "70px",
                        index.time
                          .map((activity) => activity.time_activity.name)
                          .filter(
                            (value, index, self) =>
                              self.indexOf(value) === index
                          )
                      )}
                    </TableCell>
                    {weekDates.map((weekDay, key) => {
                      const days = new Date(weekDay);
                      return (
                        <TableCell key={key} className="td-th">
                          <TableAutocomplete
                            disabled={addTimesheet}
                            index={key}
                            select={setMyDuration}
                            hour={index.time.map(
                              (timesheet) =>
                                +new Date(timesheet.created) === +days && {
                                  hours: timesheet.hours,
                                  status: timesheet.status,
                                  late: timesheet.late,
                                }
                            )}
                            onClick={() =>
                              handleEntryModal(
                                index.time.map((timesheet) => {
                                  return timesheet.time_code;
                                }),
                                index.time.map((timesheet) => {
                                  return timesheet.time_activity;
                                }),
                                index.time.map(
                                  (timesheet) =>
                                    +new Date(timesheet.created) === +days &&
                                    timesheet.hours
                                ),
                                key,
                                index.time.map(
                                  (timesheet) =>
                                    +new Date(timesheet.created) === +days &&
                                    timesheet.id
                                ),
                                index.time.map(
                                  (timesheet) =>
                                    +new Date(timesheet.created) === +days &&
                                    timesheet.comment
                                ),
                                index.time.map(
                                  (timesheet) =>
                                    +new Date(timesheet.created) === +days &&
                                    timesheet.milestone
                                ),
                                setIsEntry(true)
                              )
                            }
                            // color={"#fff"}
                          />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
              <TableHead
                style={{
                  bottom: 0,
                  zIndex: 2,
                  position: "sticky",
                }}
              >
                <TableRow
                  sx={{
                    borderTop: "1px solid rgb(224, 224, 224)",
                  }}
                >
                  <TableCell className="td-th" colSpan={2}>
                    <Typography variant="h6" sx={{ fontSize: "0.82rem" }}>
                      Total timesheet entry time
                    </Typography>
                  </TableCell>
                  {totalHours?.map((td, index) => (
                    <TableCell key={index} className="td-th">
                      <Button
                        fullWidth
                        className=" footer-button"
                        variant="contained"
                        disableElevation
                        sx={{
                          bgcolor: "#fff",
                          minWidth: "47.86px !important",
                          height: "33px !important",
                          fontSize: "0.7rem",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            position: "absolute",
                            fontSize: "0.7rem",
                            fontWeight: 600,
                          }}
                        >
                          {td.hours ? parseFloat(td.hours).toFixed(2) : "0.00"}
                        </Typography>
                      </Button>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        )}
      </CardContent>
      <Entry handleEntryModal={handleEntryModal} findEntryDisable={true} />
      <Summary
        open={openSummaryModal}
        handleClose={HandleCloseSummary}
        timesheet={timesheetSummary}
      />
    </Card>
  );
};

export default Timesheet;
