import React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useQueryClient } from "react-query";
import { IconButton } from "@mui/material";

const RefreshButton = ({ setNotLoading }) => {
  const queryClient = useQueryClient();
  const page = 1;
  const search = "";
  const size = "";

  const refreshFunction = () => {
    setNotLoading(false);
    setTimeout(async function () {
      await queryClient.refetchQueries(
        "get-management-summary",
        page,
        search,
        size
      );
      setNotLoading(true);
    }, 1000);
  };

  return (
    <IconButton
      aria-label="refresh"
      onClick={() => {
        refreshFunction();
      }}
    >
      <RefreshIcon />
    </IconButton>
  );
};
export default RefreshButton;
