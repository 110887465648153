import Table from "@mui/material/Table";

const TABLE_STYLES = {
  tableLayout: "auto",
  minWidth: "100%",
  width: "100%",
};

const PerActivityStyledTable = ({ stickyHeader = true, children }) => {
  return (
    <Table stickyHeader={stickyHeader} size="small" sx={TABLE_STYLES}>
      {children}
    </Table>
  );
};

export default PerActivityStyledTable;
