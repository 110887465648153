import {
  Button,
  ClickAwayListener,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import MenuList from "@mui/material/MenuList";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const TableRowHeader = ({
  handleChangeType,
  type,
  setType,
  leaveName,
  columns,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    if (event.currentTarget.dataset) {
      const { myValue } = event?.currentTarget?.dataset;
      setType(myValue);
      handleChangeType(myValue);
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }
  return (
    <TableRow>
      {columns?.map((column, key) => (
        <TableCell
          key={column.id}
          align="left"
          style={{ minWidth: column.minWidth, padding: "16px 10px" }}
        >
          {column.id === "leave_type" || column.id === "type" ? (
            <div>
              <Button
                sx={{
                  color: "#21293C",
                  textTransform: "none",
                  fontWeight: "bold",
                }}
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              >
                {type}
              </Button>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        {column.id === "leave_type" ? (
                          <MenuList
                            autoFocusItem={open}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem
                              data-my-value={"All"}
                              onClick={handleClose}
                            >
                              All
                            </MenuItem>
                            {leaveName?.data?.map((value, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  data-my-value={value.name}
                                  onClick={handleClose}
                                >
                                  {value.name}
                                </MenuItem>
                              );
                            })}
                          </MenuList>
                        ) : (
                          <MenuList
                            autoFocusItem={open}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem
                              data-my-value={"All"}
                              onClick={handleClose}
                            >
                              All
                            </MenuItem>
                            <MenuItem
                              data-my-value={"Over time"}
                              onClick={handleClose}
                            >
                              Overtime
                            </MenuItem>
                            <MenuItem
                              data-my-value={"Under time"}
                              onClick={handleClose}
                            >
                              Undertime
                            </MenuItem>
                          </MenuList>
                        )}
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          ) : (
            <Typography variant={"subtitle2"} sx={{ fontWeight: "700" }}>
              {column.label}
            </Typography>
          )}
        </TableCell>
      ))}
    </TableRow>
  );
};
export default TableRowHeader;
