import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";

export const downloadFile = ({ data, fileName, fileType }) => {
  const blob = new Blob([data], { type: fileType });

  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);

  const clickEvent = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  a.dispatchEvent(clickEvent);
  a.remove();
};

export const downloadCsv = (usersData, columns, columnsCSV) => {
  let headers = [];
  columnsCSV?.map((column) => {
    headers.push(column.label);
  });
  headers = headers.join(",");
  headers = [headers];

  const content = [];

  usersData.map((data) => {
    let row = [];
    columns?.map((column) => {
      row.push(data[column?.data] || "-");
    });
    content.push(row.join(","));
  });

  const csvData = [...headers, ...content].join("\n");

  downloadFile({
    data: csvData,
    fileName: "compliance-report-" + moment().format("YYYYMMDD-HHmmss"),
    fileType: "text/csv",
  });
};

export const downloadPdf = (usersData, columns) => {
  let head = [];
  columns?.map((column) => {
    head.push(column.label);
  });
  head = [head];

  const body = [];

  usersData.map((data) => {
    let row = [];
    columns?.map((column) => {
      row.push(data[column.data] || "-");
    });
    body.push(row);
  });

  const doc = new jsPDF("landscape");

  autoTable(doc, { head, body });

  doc.save("compliance-report-" + moment().format("YYYMMDD-HHmmss") + ".pdf");
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const generateErrorObj = (error) => {
  const status = error.response.status;

  if (status === 422) {
    return {
      title: Object.entries(error.response.data)[0][0],
      message: Object.entries(error.response.data)[0][1],
    };
  }

  return { title: null, message: "An error occured!" };
};
