import apiService from "../../axios";

export const getComplianceReport = async (url) => {
  try {
    let response = await apiService.post(`v5/compliance/report` + url, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(url),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
