import moment from "moment";

export const INPUT_TYPE = {
  TEXT: "text",
  SELECT: "select",
  DATE: "date",
};

const UserInformation = class {
  personalInformation = {
    title: "Personal Information",
  };

  constructor() {
    this.personalInformation = {
      title: "Personal Information",
      profile: [],
    };

    this.employeeInformation = {
      title: "Employee Information",
      profile: [],
    };

    this.messagingAppsUserName = {
      title: "Messaging Apps Username",
      profile: [],
    };
  }

  addPersonalInformation(label, value) {
    if (!label) return this;

    this.personalInformation.profile.push({
      label,
      value,
    });

    return this;
  }

  addEmployeeInformation(label, value) {
    if (!label) return this;

    this.employeeInformation.profile.push({
      label,
      value,
    });

    return this;
  }

  addMessagingAppsUserName(label, value) {
    if (!label) return this;

    this.messagingAppsUserName.profile.push({
      label,
      value,
    });

    return this;
  }

  getUserInformation() {
    return [
      this.personalInformation,
      this.employeeInformation,
      this.messagingAppsUserName,
    ];
  }
};

const Fields = class {
  constructor() {
    this.personalInformationField = {
      title: "Personal Information",
      fields: [],
    };

    this.employeeInformationField = {
      title: "Employee Information",
      fields: [],
    };

    this.messagingAppField = {
      title: "Messaging App",
      fields: [],
    };
  }
  addPersonalInformationField(
    label,
    fieldName,
    type,
    required = false,
    selection
  ) {
    this.personalInformationField.fields.push({
      label,
      fieldName,
      type,
      id: fieldName,
      required,
      selection,
    });

    return this;
  }

  addEmployeeInformationField(
    label,
    fieldName,
    type,
    required = false,
    selection
  ) {
    this.employeeInformationField.fields.push({
      label,
      fieldName,
      type,
      id: fieldName,
      required,
      selection,
    });

    return this;
  }

  addMessagingAppField(label, fieldName, type, required = false, selection) {
    this.messagingAppField.fields.push({
      label,
      fieldName,
      type,
      id: fieldName,
      required,
      selection,
    });

    return this;
  }

  getField() {
    return [
      this.personalInformationField,
      this.employeeInformationField,
      this.messagingAppField,
    ];
  }
};

export const formatDate = (date, formatType = "MM/DD/YY") => {
  const formattedDate = moment(date).format(formatType);

  if (formattedDate === "Invalid date") return null;

  return formattedDate;
};

export const getItemFromList = (key, list) => {
  const item = list.find((item) => item.id === +key);

  if (item === undefined) return null;

  return item;
};

export const getDepartmentName = (id, departmentList) => {
  const result = departmentList.find((department) => department.id === +id);

  if (result === undefined) return "";

  return result.name;
};

export const capitalizeString = (string) => {
  if (!string?.length) return "";

  if (string.length === 1) return string.toUpperCase();

  return string[0].toUpperCase() + string.slice(1);
};

export const formatUserInformation = (
  userInformation = {},
  departments = []
) => {
  const {
    email_work: workEmail,
    firstname: firstName,
    middlename: middleName,
    lastname: lastName,
    extension,
    nickname,
    gender,
    dob: birthDate,
    marital: maritalStatus,
    dept: departmentId,
    mobile: mobileNumber,
    address,
    email_personal: personalEmail,
    emp_company_id: employeeId,
  } = userInformation;

  const dateHired = userInformation?.details?.date_hired || new Date();
  const employmentStatus = userInformation?.details?.employment_status;
  const schedule = userInformation?.details?.schedule;
  const wfhMaxDaysPerWeek = userInformation?.details?.wfh_days || 0;
  const skype = userInformation?.details?.skype || "";
  const slack = userInformation?.details?.slack || "";
  const min_hour = userInformation?.details?.min_hour || 0;

  const user = new UserInformation();

  user
    .addPersonalInformation("Work Email", workEmail)
    .addPersonalInformation("First Name", firstName)
    .addPersonalInformation("Middle Name", middleName)
    .addPersonalInformation("Last Name", lastName)
    .addPersonalInformation("Extension", extension)
    .addPersonalInformation("Nickname", nickname)
    .addPersonalInformation("Gender", capitalizeString(gender))
    .addPersonalInformation("Birth Date", formatDate(birthDate))
    .addPersonalInformation("Marital Status", maritalStatus)
    .addPersonalInformation(
      "Department",
      getDepartmentName(departmentId, departments)
    )
    .addPersonalInformation("Mobile Number", mobileNumber)
    .addPersonalInformation("Address", address)
    .addPersonalInformation("Personal Email", personalEmail);

  user
    .addEmployeeInformation("Employee ID", employeeId)
    .addEmployeeInformation("Date hired", formatDate(dateHired))
    .addEmployeeInformation("Employment Status", employmentStatus)
    .addEmployeeInformation("Type of Schedule", schedule)
    .addEmployeeInformation("WFH maximum days per week", wfhMaxDaysPerWeek)
    .addEmployeeInformation("Minimum hours to render", min_hour);

  user
    .addMessagingAppsUserName("Skype", skype)
    .addMessagingAppsUserName("Slack", slack);

  const formattedUserInformation = user.getUserInformation();

  return formattedUserInformation;
};

const getValueAndLabel = (array, keyName, valueName) => {
  return array.map((item) => ({
    value: item[keyName],
    label: item[valueName],
  }));
};

export const generateEditFields = (schedules, departments) => {
  const field = new Fields();

  /* PERSONAL INFORMATION */
  field
    .addPersonalInformationField(
      "First Name",
      "firstname",
      INPUT_TYPE.TEXT,
      true
    )
    .addPersonalInformationField(
      "Middle Name",
      "middlename",
      INPUT_TYPE.TEXT,
      false
    )
    .addPersonalInformationField("Last Name", "lastname", INPUT_TYPE.TEXT, true)
    .addPersonalInformationField("Nickname", "nickname", INPUT_TYPE.TEXT)
    .addPersonalInformationField("Extension", "extension", INPUT_TYPE.TEXT)
    .addPersonalInformationField("Gender", "gender", INPUT_TYPE.SELECT, false, [
      { value: "male", label: "Male" },
      { value: "female", label: "Female" },
      { value: "other", label: "Other" },
    ])
    .addPersonalInformationField("Birth Date", "dob", INPUT_TYPE.DATE)
    .addPersonalInformationField(
      "Marital Status",
      "marital",
      INPUT_TYPE.SELECT,
      false,
      [
        { value: "Unmarried", label: "Unmarried" },
        { value: "Married", label: "Married" },
        { value: "Divorce", label: "Divorced" },
        { value: "Widowed", label: "Widowed" },
      ]
    )
    .addPersonalInformationField(
      "Departments",
      "dept",
      INPUT_TYPE.SELECT,
      true,
      getValueAndLabel(departments, "id", "name").map((item) => ({
        ...item,
        value: `${item.value}`,
      }))
    )
    .addPersonalInformationField("Mobile No", "mobile", INPUT_TYPE.TEXT)
    .addPersonalInformationField("Address", "address", INPUT_TYPE.TEXT)
    .addPersonalInformationField(
      "Personal Email",
      "email_personal",
      INPUT_TYPE.TEXT
    )
    .addPersonalInformationField(
      "Work Email",
      "email_work",
      INPUT_TYPE.TEXT,
      true
    );

  /* EMPLOYEE INFORMATION */
  field
    .addEmployeeInformationField(
      "Employee ID",
      "emp_company_id",
      INPUT_TYPE.TEXT,
      true
    )
    .addEmployeeInformationField("Date Hired", "date_hired", INPUT_TYPE.DATE)
    .addEmployeeInformationField(
      "Employment Status",
      "employment_status",
      INPUT_TYPE.SELECT,
      false,
      [
        { value: "Probationary", label: "Probationary" },
        { value: "Regular", label: "Regular" },
        { value: "Terminated", label: "Terminated" },
      ]
    )
    .addEmployeeInformationField(
      "WFH Number of Days",
      "wfh_days",
      INPUT_TYPE.TEXT
    )
    .addEmployeeInformationField(
      "Type of Schedule",
      "schedule",
      INPUT_TYPE.SELECT,
      false,
      [
        { value: "Flexi", label: "Flexi" },
        { value: "Fixed", label: "Fixed" },
      ]
    )
    .addEmployeeInformationField(
      "Work Schedule",
      "sched_id",
      INPUT_TYPE.SELECT,
      false,
      getValueAndLabel(schedules, "id", "sched_name")
    );

  /* MESSAGING APPS */
  field
    .addMessagingAppField("Skype", "skype", INPUT_TYPE.TEXT)
    .addMessagingAppField("Slack", "slack", INPUT_TYPE.TEXT);

  return field.getField();
};

// This will take all the fields and map them into the default value for the form.
export const generateDefaultFormValues = (field, data) => {
  return field
    .reduce((previous, current) => [...previous, ...current.fields], [])
    .reduce((previous, current) => {
      const currentData =
        data?.[`${current?.id}`] || data?.details?.[`${current?.id}`] || "";
      const dataToReturn =
        current.type === "date" ? new Date(currentData || "") : currentData;

      return {
        ...previous,
        [current.id]: dataToReturn || "",
      };
    }, {});
};
