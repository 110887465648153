import Button from "@mui/material/Button";
import ConfirmButtonCircularProgress from "../loader/ConfirmButtonCircularProgress";

const ConfirmationButton = ({
  children,
  isValid = true,
  isLoading,
  onClick,
}) => {
  return (
    <Button
      position="relative"
      variant="contained"
      disableElevation
      disabled={isLoading || !isValid}
      onClick={onClick}
    >
      {children}

      {isLoading && <ConfirmButtonCircularProgress size={24} />}
    </Button>
  );
};

export default ConfirmationButton;
