import { Grid, Tab, Tabs, Typography } from "@mui/material";
import React, { useContext, useRef } from "react";
import { SystemSkillContext } from "../../../globalstate/SystemSettings/Skill";
import useDebounce from "../../../hooks/customHooks/debounce";
import { useGetEmployeeSkill } from "../../../hooks/react-query/SettingsSytem/Skills";
import AddSkillModal from "./AddSkillModal";
import EmployeeBody from "./EmployeeBody";
import EmployeeHeader from "./EmployeeHeader";
import SkillHeader from "./SkillHeader";
import SkillsBody from "./SkillsBody";

const initDataVal = {
  tab: 1,
  addModal: false,
  search: "",
  page: 1,
  per_page: 25,
};
const AdminSkillsPage = () => {
  const [data, setData] = React.useState(initDataVal);
  const { setSkillsParams, initSkillParam } = useContext(SystemSkillContext);
  const delaySearch = useDebounce(data.search, 500);
  const { data: empSkills, isFetching } = useGetEmployeeSkill({
    ...data,
    search: delaySearch,
  });
  const handleChangeTab = (event, newValue) => {
    setData({ ...data, tab: newValue, search: "" });
    setSkillsParams(initSkillParam);
  };

  const handleOnClickAddSkill = () => {
    setData({ ...data, addModal: true });
  };
  const closeModalSkill = () => {
    setData({ ...data, addModal: false });
  };
  const handleSearch = (e) => {
    setData({ ...data, search: e.target.value, page: 1 });
  };
  const textInput = useRef(null);

  const ClearSearch = () => {
    textInput.current.value = "";
    setData({ ...data, search: "", page: 1 });
  };

  const nextPage = () => {
    setData({ ...data, page: data.page + 1 });
  };

  const backPage = () => {
    setData({ ...data, page: data.page - 1 });
  };
  const startPage = () => {
    setData({ ...data, page: 1 });
  };
  const lastPage = () => {
    !isFetching && setData({ ...data, page: empSkills.last_page });
  };
  return (
    <Grid mt={3} ml={3} mr={3}>
      <Typography variant="h5">Skill Settings</Typography>
      <Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: 1,
            borderColor: "divider",
          }}
          mt={2}
        >
          <Tabs
            value={data.tab}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
          >
            <Tab label="Employees" value={1} />
            <Tab label="Skills" value={2} />
          </Tabs>
          {data.tab === 1 ? (
            <EmployeeHeader
              employees={{
                pending: empSkills?.pending,
                equipped: empSkills?.equipped,
              }}
              search={{
                handleSearch: handleSearch,
                textInput: textInput,
                ClearSearch: ClearSearch,
              }}
            />
          ) : (
            <SkillHeader onClickAddSkill={handleOnClickAddSkill} />
          )}
        </Grid>
      </Grid>
      <Grid>
        {data.tab === 1 ? (
          <EmployeeBody
            empSkills={{
              data: empSkills,
              isFetching: isFetching,
              setPerPage: setData,
              startPage: startPage,
              lastPage: lastPage,
              nextPage: nextPage,
              backPage: backPage,
            }}
          />
        ) : (
          <SkillsBody />
        )}
      </Grid>
      <AddSkillModal open={data.addModal} handleClose={closeModalSkill} />
    </Grid>
  );
};

export default AdminSkillsPage;
