import { useQuery } from "react-query";
import { getTimekeepingReport } from "../../../api/timekeeping";

const useTimekeepingReport = (params) => {
  return useQuery(["get-timekeeping-report", params.criteria], () =>
    getTimekeepingReport(params.payload)
  );
};

const hooks = {
  useTimekeepingReport,
};
export default hooks;
