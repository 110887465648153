import React from "react";
import "../../styles/Error/pagenotfound.css";

const Pagenotfound = () => {
  return (
    <div className="container">
      <div>
        <h1>404</h1>
        <h1>Page not Found</h1>
      </div>
    </div>
  );
};

export default Pagenotfound;
