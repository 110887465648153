import { Fragment } from "react";

import StyledTableCell from "../StyledTableCell";
import TitleTypography from "../TitleTypography";
import DetailsTypography from "../DetailsTypography";
import {
  transformPluralToSingular,
  getOvertimeDuration,
  getWFHDuration,
  getApplicationType,
  getDays,
} from "../../../../../helper/UserApplicationMgmt/UserApplications";
import { Button } from "@mui/material";

const VIEW_IMAGES_BUTTON_STYLES = {
  padding: 0,
  alignSelf: "flex-start",
  textTransform: "unset",
  "&:hover": {
    backgroundColor: "transparent",
    textDecoration: "underline",
  },
};

const TEXT_DETAILS_STYLES = { textTransform: "capitalize" };

const DateFiledCell = ({
  type,
  formattedDateFiled,
  formattedStartDate,
  formattedEndDate,
  duration,
  startDate,
  endDate,
  forgotType,
  location,
  time,
  toggleImageModalHandler,
}) => {
  const { isLeave, isOvertime, isWfh, forgotTimeInOut, isDocument } =
    getApplicationType(type);

  const days = getDays(startDate, endDate, duration);
  return (
    <StyledTableCell sx={{ verticalAlign: "top" }}>
      <TitleTypography>Filed on {formattedDateFiled}</TitleTypography>
      {!forgotTimeInOut && !isDocument && (
        <DetailsTypography>{formattedStartDate} to</DetailsTypography>
      )}
      {!forgotTimeInOut && (
        <DetailsTypography>{formattedEndDate}</DetailsTypography>
      )}
      {isLeave && (
        <Fragment>
          <TitleTypography>Duration</TitleTypography>

          <DetailsTypography sx={TEXT_DETAILS_STYLES}>
            {days} {transformPluralToSingular("day", days > 1)}
          </DetailsTypography>

          <DetailsTypography>{duration}</DetailsTypography>
        </Fragment>
      )}

      {isOvertime && (
        <Fragment>
          <TitleTypography>Duration</TitleTypography>
          <DetailsTypography>
            {getOvertimeDuration(startDate, endDate)}
          </DetailsTypography>
        </Fragment>
      )}
      {forgotTimeInOut && (
        <Fragment>
          <TitleTypography>Type</TitleTypography>
          <DetailsTypography sx={TEXT_DETAILS_STYLES}>
            {forgotType === "in" ? "Time In" : "Time Out"}
          </DetailsTypography>
          <DetailsTypography sx={TEXT_DETAILS_STYLES}>
            on {formattedEndDate} {time}
          </DetailsTypography>
        </Fragment>
      )}
      {forgotTimeInOut && (
        <Fragment>
          <TitleTypography>Location</TitleTypography>
          <DetailsTypography sx={TEXT_DETAILS_STYLES}>
            {location}
          </DetailsTypography>
          <TitleTypography>Image Attachment</TitleTypography>
          <Button
            variant="text"
            sx={VIEW_IMAGES_BUTTON_STYLES}
            onClick={toggleImageModalHandler}
          >
            View Image(s)
          </Button>
        </Fragment>
      )}
      {isWfh && (
        <Fragment>
          <TitleTypography>Duration</TitleTypography>
          <DetailsTypography sx={TEXT_DETAILS_STYLES}>
            {getWFHDuration(startDate, endDate)}
          </DetailsTypography>
        </Fragment>
      )}
    </StyledTableCell>
  );
};

export default DateFiledCell;
