import React from "react";
import { Skeleton } from "@mui/material";
import { Box } from "@mui/system";

const TablePaginationController = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        padding: "10px",
      }}
    >
      <Skeleton
        variant="rounded"
        width={150}
        height={40}
        sx={{ margin: "0 10px" }}
      />
      <Skeleton
        variant="rounded"
        width={150}
        height={40}
        sx={{ margin: "0 10px" }}
      />
      <Skeleton
        variant="rounded"
        width={50}
        height={40}
        sx={{ margin: "0 10px" }}
      />
    </Box>
  );
};
export default TablePaginationController;
