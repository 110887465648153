import React from "react";
import { useState, useRef } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import Dialog from "../../component/TreeView/Dialog";
import ViewLog from "./ViewLog";
import Form from "../../component/TreeView/Form";
import DataTable from "../../component/TreeView";
import Document from "../../component/TreeView/table/rows/Document";
import {
  addDocument,
  deleteDocument,
  updateDocument,
  viewDocument,
} from "../../api/policy";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import hooks from "../../hooks/react-query/PolicyQuery";
import "react-toastify/dist/ReactToastify.css";
import Confirm from "../../component/TreeView/Confirm";
import FormEdit from "../../component/TreeView/EditForm";
import ViewLogTable from "./Tables/ViewLogTable";
import {
  TableSettings,
  TableHeader,
  ViewLogHeader,
} from "../../lib/policydocuments/TableSettings";
import { FixedTree } from "../../lib/policydocuments/FixedTree";

const Policy = () => {
  const formData = useRef(null);
  const [form, setForm] = useState(false);
  const [date, setDate] = useState();
  const [pdfFileName, setpdfFileName] = useState();
  const [confirm, setConfirm] = useState(false);
  const [deleteForm, setDeleteForm] = useState();
  const [edit, setEdit] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [fetch, toggleFetch] = useState(false);
  const [viewLog, setViewLog] = useState(false);
  const [documentID, setDocumentID] = useState();

  const uploadFile = (e) => {
    setpdfFileName(e.target.files[0].name);

    if (e.target.files[0].size > 3000000) {
      setErrors({ file: "Maximum upload file size: 3MB" });
      return;
    } else {
      setErrors("");
    }
  };

  const formField = [
    {
      label: "Name",
      data: "name",
      helperText: errors.name,
      update: (e) => {
        setEditForm({ ...editForm, name: e });
      },
    },
    {
      label: "File",
      data: "pdfFileName",
      type: "document",
      helperText: errors.file,
      value: pdfFileName,
    },
    {
      label: "Type",
      data: "type",
      type: "select",
      menu_item: [
        { value: "announcement", label: "Announcement" },
        { value: "benefits/hMO", label: "Benefits/HMO" },
        {
          value: "benefits/company Benefits",
          label: "Benefits/Company Benefits",
        },
        { value: "forms", label: "Forms" },
        { value: "government Documents", label: "Government Documents" },
        { value: "handbook", label: "Handbook" },
        { value: "house Rules", label: "House Rules" },
        { value: "legislation/philippines", label: "Legislation/Philippines" },
        { value: "legislation/australia", label: "Legislation/Australia" },
        { value: "policies", label: "Policies" },
        { value: "policies/finance", label: "Policies/Finance" },
        { value: "policies/hR", label: "Policies/HR" },
        { value: "procedures", label: "Procedures" },
      ],
      helperText: errors.type,
      update: (e) => {
        setEditForm({ ...editForm, type: e });
      },
    },
    {
      label: "Effective Date",
      data: "date",
      type: "date",
      value: date,
      helperText: errors.date,
      onChange: setDate,
      update: (e) => {
        setEditForm({ ...editForm, date: e });
      },
    },
    {
      label: "Remarks",
      data: "remarks",
      helperText: errors.remarks,
      type: "TextArea",
      update: (e) => {
        setEditForm({ ...editForm, remarks: e });
      },
    },
  ];

  const handleClick = ({ path, name, ext, id }) => {
    let form = new FormData();
    form.append("id", id);
    logViewDocument.mutate(form);
    window.open(path, "_blank");
  };

  const handleRemove = (id, path) => {
    let form = new FormData();
    form.append("id", id);
    form.append("path", path);
    setDeleteForm(form);
    setConfirm(true);
  };

  const removeDocument = useMutation((formData) => deleteDocument(formData), {
    onSuccess: async (response) => {
      toast.success("Document Removed");
      toggleFetch(!fetch);
      setConfirm(false);
    },
    onError: (response) => {
      toast.error(response.message);
    },
  });

  const logViewDocument = useMutation((formData) => viewDocument(formData), {
    onSuccess: async (response) => {},
    onError: (response) => {
      toast.error(response.message);
    },
  });

  const handleConfirm = () => {
    removeDocument.mutate(deleteForm);
  };

  const editDocument = useMutation((formData) => updateDocument(formData), {
    onSuccess: async (response) => {
      toast.success("Record Updated");
      toggleFetch(!fetch);
      setpdfFileName("");
      setEdit(false);
      setErrors({});
    },
    onError: (response) => {
      setErrors(response.response.data.errors || {});
      if (
        !response.response.data.errors.remarks &&
        !response.response.data.errors.name &&
        !response.response.data.errors.file
      ) {
        toast.error("Something Went Wrong");
      }
    },
  });

  const createDocument = useMutation((formData) => addDocument(formData), {
    onSuccess: async (response) => {
      toast.success("Document successfully added.");
      toggleFetch(!fetch);
      setpdfFileName("");
      setForm(false);
      setErrors({});
    },
    onError: (response) => {
      setErrors(response.response.data.errors || {});
      if (
        !response.response.data.errors.name &&
        !response.response.data.errors.remarks &&
        !response.response.data.errors.file
      ) {
        toast.error("Something Went Wrong");
      }
    },
  });

  const handleEdit = (data) => {
    data.date = data.effective_date;
    setEditForm(data);
    var path = data.path;
    var result = path.substring(path.lastIndexOf("/") + 1);
    setpdfFileName(result);
    setEdit(true);
  };

  const handleSubmitEdit = (e) => {
    e.preventDefault();
    let form = new FormData(formData.current);

    if (errors.file) {
      return;
    }
    form.append("doc_nodes", "/admin");
    form.append(
      "fileName",
      pdfFileName && pdfFileName.split(".").slice(0, -1).join(".")
    );
    form.append("id", editForm.id);
    form.append("doc_id", editForm.id);
    form.append("ext", editForm.ext);
    editDocument.mutate(form);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (errors.file) {
      return;
    }
    let form = new FormData(formData.current);
    form.append("doc_nodes", "/admin");
    form.append(
      "fileName",
      pdfFileName && pdfFileName.split(".").slice(0, -1).join(".")
    );
    createDocument.mutate(form);
  };

  const Button = [
    {
      label: "Add Document",
      type: "contained",
      onClick: (e) => setForm(true),
      viewPermission: "manage hrfolder",
    },
  ];

  const handleClose = (e) => {
    setForm(false);
    setEdit(false);
    setpdfFileName("");
    setErrors({});
  };

  const handleReport = (data) => {
    setDocumentID(data.id);
    setViewLog(true);
  };

  return (
    <>
      <Box sx={{ margin: "0px", pr: "25px" }}>
        <Box
          sx={{
            pt: "5px",
            pl: "25px",
            display: "flex",
            justifyContent: "flex-start",
            cursor: "context-menu",
          }}
        >
          <Tooltip
            title="Manage company policies and documents"
            placement={"right"}
            arrow
          >
            <Typography variant="h4">Policies & Documents </Typography>
          </Tooltip>
        </Box>
        <Box sx={{ minWidth: "70vw", pl: "17px", pt: "15px" }}>
          <DataTable
            TableHeader={TableHeader}
            TreeHeader={"Folder List"}
            Tree={FixedTree}
            fetch={hooks.useDocument}
            TableRow={Document}
            handleClick={handleClick}
            handleRemove={handleRemove}
            handleEdit={handleEdit}
            handleReport={handleReport}
            Button={Button}
            toggleFetch={fetch}
            TreeType={"path"}
            TableSettings={TableSettings}
          />
        </Box>
        <Box>
          <Dialog
            size="sm"
            dialog={form}
            handleClose={handleClose}
            title={"Add Document"}
            handleSubmit={handleSubmit}
            submitting={createDocument.isLoading}
            formData={formData}
            Content={
              <Form
                formfield={formField}
                formData={formData}
                handleSubmit={handleSubmit}
                uploadFile={uploadFile}
              />
            }
          />
        </Box>
        <Box>
          <Dialog
            size="md"
            dialog={edit}
            handleClose={handleClose}
            submitting={editDocument.isLoading}
            title={"Edit Document"}
            handleSubmit={handleSubmitEdit}
            formData={formData}
            Content={
              <FormEdit
                data={editForm}
                update={setEditForm}
                formfield={formField}
                formData={formData}
                handleSubmit={handleSubmitEdit}
                uploadFile={uploadFile}
              />
            }
          />
        </Box>
        <Confirm
          childOpen={confirm}
          submitting={removeDocument.isLoading}
          message={"Are you sure you want to delete this document?"}
          handleClose={(e) => setConfirm(false)}
          handleConfirm={handleConfirm}
        />
        <Box>
          <ViewLog
            open={viewLog}
            handleClose={(e) => setViewLog(false)}
            title={"View Log Report"}
            Content={<ViewLogTable id={documentID} header={ViewLogHeader} />}
          />
        </Box>
      </Box>
    </>
  );
};

export default Policy;
