import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import AnswerButton from "./answerButton";
import SingleAnswer from "./singleAnswer";

const PulseAnswer = ({ sq }) => {
  const multiple =
    sq.question.type_of_answer === "multiple"
      ? sq.answers.filter((sa) => {
          if (sa[0] !== "Options") {
            return sa;
          }
        })
      : null;
  const single =
    sq.question.type_of_answer === "single"
      ? sq.answers.map((sa) => {
          return sa;
        })
      : null;
  const checkbox =
    sq.question.type_of_answer === "checkbox"
      ? sq.answers.filter((sa) => {
          if (sa[0] !== "Options") {
            return sa;
          }
        })
      : null;
  const rating =
    sq.question.type_of_answer === "rating"
      ? sq.answers.filter((sa) => {
          if (sa[0] !== "Options") {
            return sa;
          }
        })
      : null;

  return (
    <Grid item xs={12} lg={12}>
      <Card>
        <CardHeader
          title={
            <Typography variant="h3" className="h3-bar">
              {sq.question.question}
            </Typography>
          }
        />
        <CardContent sx={{ maxHeight: "500px", overflowY: "scroll" }}>
          {multiple && <AnswerButton answerData={multiple} />}
          {checkbox && <AnswerButton answerData={checkbox} />}
          {rating && <AnswerButton answerData={rating} />}
          {single && <SingleAnswer answerData={single} />}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default PulseAnswer;
