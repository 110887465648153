import apiService from "../axios";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { toastAlertOptions } from "../../hooks/customHooks/toastAlertOptions";
const user = localStorage.getItem("user");
const getUser = JSON.parse(user);
export const getEmployeeRequest = async (
  id,
  page,
  search,
  size,
  sortBy,
  select,
  type
) => {
  try {
    const newType = type === "All" ? "" : type;
    const url =
      select === "Leave"
        ? `/v5/employee/my-leave-applications/${id}?page=${page}&search=${search}&page_size=${size}&sortBy=${sortBy}&type=${newType}`
        : select === "Overtime"
        ? `/v5/employee/my-ot-applications/${id}?page=${page}&search=${search}&page_size=${size}&sortBy=${sortBy}&type=${newType}`
        : select === "WFH"
        ? `/v5/employee/my-wfh-applications/${id}?page=${page}&search=${search}&page_size=${size}&sortBy=${sortBy}`
        : select === "ForgotTimeInOut"
        ? `/v5/time-in-out/get-employee-time-in-out-applications/${id}?page=${page}&search=${search}&page_size=${size}&type=${newType}`
        : select === "Document"
        ? `/v5/applications/get-emp-request-document/${getUser.id}?page=${page}&search=${search}&page_size=${size}`
        : `/v5/employee/my-conversion-applications/${id}?page=${page}&search=${search}&page_size=${size}&sortBy=${sortBy}`;

    const request = await apiService.get(url);

    return request.data;
  } catch (error) {
    throw error;
  }
};

export const getApprovers = async (data) => {
  try {
    const request = await apiService.post(
      `/v1/employee/get-approvers`,
      {
        api: data,
      },
      {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    );
    return request.data;
  } catch (error) {
    throw error;
  }
};

export const deleteRequest = async (id, type) => {
  try {
    if (type) {
      const path =
        type === "Over Time" || type === "Under Time"
          ? "/v1/employee/application/delete-otut"
          : type.includes("Leave") && type !== "Leave Conversion"
          ? "/v1/employee/application/delete-leave"
          : type === "Leave Conversion"
          ? "/v5/applications/cancel-conversion"
          : type === "in" || type === "out"
          ? "v5/time-in-out/cancel-employee-time-in-out-application"
          : "/v5/employee/application/delete-wfh";

      const request = await apiService.post(
        path,
        type === "Leave Conversion"
          ? {
              conversion_id: id,
            }
          : type === "in" || type === "out"
          ? {
              tioa_id: id,
            }
          : {
              api: true,
              id: id,
              type: type || null,
            }
      );
      toast.success("Application successfully deleted.", toastAlertOptions);
      return request.data;
    } else {
      const req = await apiService.get(
        `/v5/applications/delete-request-document/${id}`
      );
      return req;
    }
  } catch (error) {
    toast.error("Error was found.", toastAlertOptions);
    throw error;
  }
};

export const editRequest = async (
  editId,
  editEmp,
  editCredit,
  type,
  from,
  to,
  workplace,
  purpose
) => {
  let date = dayjs(from).format("MMM D, YYYY");
  let time = dayjs(from).format("h:mm A");
  try {
    if (type) {
      from = dayjs(from).format("YYYY-MM-DDTHH:mm:ssZ[Z]");
      to = dayjs(to).format("YYYY-MM-DDTHH:mm:ssZ[Z]");
      const path =
        type === "Over Time" || type === "Under Time"
          ? "/v5/employee/application/otut-edit"
          : type.includes("Leave") && type !== "Leave Conversion"
          ? "/v5/employee/application/leave-edit"
          : type === "Leave Conversion"
          ? "/v5/applications/edit-conversion"
          : type === "in" || type === "out"
          ? "/v5/time-in-out/edit-employee-time-in-out-application"
          : "/v5/employee/application/wfh-edit";

      const data =
        type === "Over Time" || type === "Under Time"
          ? {
              time_id: editId,
              employee_id: editEmp,
              from: from,
              to: to,
              purpose: purpose,
            }
          : type.includes("Leave") && type !== "Leave Conversion"
          ? {
              leave_id: editId,
              employee_id: editEmp,
              from: from,
              to: to,
              purpose: purpose,
            }
          : type === "Leave Conversion"
          ? {
              conversion_id: editId,
              conversion: editCredit,
            }
          : type === "in" || type === "out"
          ? {
              tioa_id: editId,
              time: date,
              date: time,
              type: type,
              reason: purpose,
              location: workplace,
            }
          : {
              wfh_id: editId,
              employee_id: editEmp,
              from: from,
              to: to,
              workplace: workplace,
              purpose: purpose,
            };
      const request = await apiService.post(path, data);
      toast.success("Application successfully update.", toastAlertOptions);
      return request.data;
    }
  } catch (error) {
    toast.error("Error was found.", toastAlertOptions);
    throw error;
  }
};

export const imageView = async (id, emp, type) => {
  try {
    if (type === "in" || type === "out") {
      const request = await apiService.post(
        "/v5/employee/application/view-image-forgot-timeinout",
        {
          forgotTimeInOut_id: id,
          employee_id: emp,
        }
      );
      toast.success("Image successfully loaded.", toastAlertOptions);
      return request.data.images;
    } else {
      const request = await apiService.post(
        "/v5/employee/application/view-workplace-wfh",
        {
          wfh_id: id,
          employee_id: emp,
        }
      );
      toast.success("Image successfully loaded.", toastAlertOptions);
      return request.data.images;
    }
  } catch (error) {
    toast.error("Error was found.", toastAlertOptions);
    throw error;
  }
};
