import apiService from "../axios";

export const getCreditStatus = async () => {
  try {
    const request = await apiService.get("/v5/leave-conversion/leave-credits");
    return request.data;
  } catch (error) {
    throw error;
  }
};

export const applyConversion = async (data) => {
  try {
    const request = await apiService.post(
      "/v5/leave-conversion/leave-credit",
      {
        conversion: data,
      },
      {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    );
    return request.data;
  } catch (error) {
    throw error;
  }
};
