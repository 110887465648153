import { memo } from "react";

import LeaveConversionApplicationsContextProvider from "../../../globalstate/LeaveManagement/LeaveConversionApplications/LeaveConversionApplicationsContextProvider";
import MainWrapper from "../../../component/LeaveManagement/LeaveConversionApplications/wrapper/MainWrapper";
import Title from "../../../component/LeaveManagement/LeaveConversionApplications/header/Title";
import Table from "../../../component/LeaveManagement/LeaveConversionApplications/table/Table";

const LeaveConversionApplications = () => {
  return (
    <LeaveConversionApplicationsContextProvider>
      <MainWrapper>
        <Title />

        <Table />
      </MainWrapper>
    </LeaveConversionApplicationsContextProvider>
  );
};

export default memo(LeaveConversionApplications);
