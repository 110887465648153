import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { isAfter } from "date-fns";
import TextField from "@mui/material/TextField";

const DateSelection = ({
  fromDate,
  toDate,
  handleFromDateChange,
  handleToDateChange,
}) => {
  const handleStartDateChange = (newValue) => {
    if (isAfter(newValue, toDate)) handleToDateChange(newValue);
    handleFromDateChange(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {/* Start date */}
      <DatePicker
        views={["day"]}
        label="Start"
        value={fromDate}
        onChange={handleStartDateChange}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            helperText={null}
            sx={{ width: "140px" }}
          />
        )}
      />

      {/* End date */}
      <DatePicker
        views={["day"]}
        label="End"
        value={toDate}
        minDate={fromDate}
        onChange={(newValue) => {
          handleToDateChange(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            helperText={null}
            sx={{ width: "140px" }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DateSelection;
