import { useQuery } from "react-query";
import { getUsersLists } from "../../../api/userList";

const useUserList = (params) => {
  return useQuery(["get-user-list", params.criteria], () =>
    getUsersLists(params.payload)
  );
};

const queries = {
  useUserList,
};

export default queries;
