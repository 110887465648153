import React from "react";
import { TextField, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Search = ({ setSearch, ClearSearch, textInput, variant }) => {
  return (
    <TextField
      size="small"
      variant={variant}
      label="Search"
      onChange={setSearch}
      inputRef={textInput}
      InputProps={{
        endAdornment: (
          <IconButton onClick={ClearSearch} size="small">
            <CloseIcon fontSize="small" sx={{ fontSize: "0.85rem" }} />
          </IconButton>
        ),
      }}
    />
  );
};

export default Search;
