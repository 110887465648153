import React from "react";
import { TableRow, TableCell, Typography, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const ScheduleRow = ({
  header,
  row,
  handleEdit,
  handleDelete,
  options,
  edit,
}) => {
  return (
    <TableRow sx={{ backgroundColor: edit && "#FFF" }}>
      {header &&
        header?.map((column, index) => (
          <TableCell key={index} scope="row">
            {column.type === "text" && (
              <Typography
                sx={{
                  color: edit && "#B2BEB5",
                  fontSize: 14,
                  wordWrap: "break-word",
                  whitepace: "normal",
                  textTransform: "none",
                }}
              >
                {row[column.data]}
              </Typography>
            )}

            {column.type === "select" && (
              <Typography
                sx={{
                  fontSize: 14,
                  color: edit && "#B2BEB5",
                  wordWrap: "break-word",
                  whitepace: "normal",
                  textTransform: "none",
                }}
              >
                {
                  options?.find((option) => {
                    return option?.value === row[column?.data];
                  })?.label
                }
              </Typography>
            )}
          </TableCell>
        ))}

      <TableCell key="row-edit">
        <IconButton
          disabled={edit ? true : false}
          aria-label="edit"
          onClick={(e) => handleEdit(row.id)}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          disabled={edit ? true : false}
          aria-label="delete"
          onClick={(e) => handleDelete(row.id)}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default ScheduleRow;
