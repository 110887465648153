import React from "react";

import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const PRESET_MODAL_STYLE = {
  position: "absolute",
  width: "90%",
  maxWidth: "900px",
  height: "65vh",
  maxHeight: "600px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "12px 14px",
  backgroundColor: "#fff",
  borderRadius: "4px",
};

const BaseModal = ({ children, open, onClose, sx = {} }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 200,
      }}
    >
      <Fade in={open}>
        <Box sx={{ ...PRESET_MODAL_STYLE, ...sx }}>
          {children}

          <IconButton
            onClick={onClose}
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Fade>
    </Modal>
  );
};

export default BaseModal;
