import React from "react";
import { TableRow, TableCell } from "@mui/material";
import moment from "moment";

const FourthCollapse = ({ item }) => {
  return (
    <>
      <TableRow>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: moment(item.date).format("MM-DD-YYYY"),
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item?.activity,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item?.milestone || "None",
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item?.details || "None",
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem", textAlign: "right" }}
            dangerouslySetInnerHTML={{
              __html: item?.efforts,
            }}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default FourthCollapse;
