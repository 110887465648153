export const deptColorMaterialUITheme = (dept) => {
  switch (dept) {
    case "SRE":
      return "SRE";
    case "R&D":
      return "RN";
    case "INTERN":
      return "INTERN";
    case "ADMIN":
      return "ADMIN";
    case "HR":
      return "HR";
    case "RSKYE":
      return "RSKYE";
    case "OUTSOURCE":
      return "OUTSOURCE";
    case "WEB":
      return "WEB";
    case "DELIVERY-SRE":
      return "DELIVERYSRE";
    default:
      break;
  }
};

export const deptColor = (dept) => {
  switch (dept) {
    case "SRE":
      return "#A6A6A6";
    case "R&D":
      return "#FFAC30";
    case "INTERN":
      return "#F57CD8";
    case "HR":
      return "#FA6A6C";
    case "ADMIN":
      return "#28E2BE";
    case "RSKYE":
      return "#ECC94B";
    case "ALL":
      return "#22A9D8";
    case "DELIVERY-SRE":
      return "#22ABD8";
    case "WEB":
      return "#BF89FB";
    case "NMG":
      return "#898DFB";
    case "OUTSOURCE":
      return "#79D38B";
    case "in":
      return "#15bc92";
    case "out":
      return "#ec2928";
    default:
      break;
  }
};

export const deptList = [
  "ALL",
  "R&D",
  "ADMIN",
  "HR",
  "SRE",
  "INTERN",
  "RSKYE",
  "DELIVERY-SRE",
  "OUTSOURCE",
  "NMG",
  "WEB",
];

export const aioFilter = [
  { name: "All", value: "all", color: "#15bc92" },
  { name: "Time in", value: "in", color: "#15bc92" },
  { name: "Time out", value: "out", color: "#ec2928" },
];
