import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Grow from "@mui/material/Grow";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { TextField } from "@mui/material";
import { useCreditStatus } from "../../hooks/react-query/LeaveConversion";
import LeaveConversionResponse from "./leaveConversionResponse";
import { useMutation, useQueryClient } from "react-query";
import { applyConversion } from "../../api/leaveConversion";

const LeaveConversion = ({ leaveConversion, handleConversion }) => {
  const { data: creditStatus } = useCreditStatus();
  const [convertStatus, setConvertStatus] = useState(false);
  const [convertConfirm, setConvertConfirm] = useState(false);
  const queryClient = useQueryClient();
  const [convertCredit, setConvertCredit] = useState(0);
  const [error, setError] = useState(false);

  const conversion = useMutation(() => applyConversion(convertCredit), {
    onSuccess: async (response) => {
      setConvertStatus(response?.status);
    },
  });

  const handleConvert = () => {
    setConvertConfirm(true);
    conversion.mutate();
  };

  const refetchMyQuery = () => {
    queryClient.refetchQueries(["get-employee-request-data-with-page"]);
  };

  const handleRequestCredit = (e) => {
    var reg = /^(?:[0-9]\d*|\d)$/;
    if (reg.test(e.target.value) || e.target.value.length === 0) {
      const value = e.target.value;
      setConvertCredit(value);
      if (
        String(Math.abs(Math.trunc(value))) === value &&
        creditStatus?.data?.earned >= e.target.value
      ) {
        setError(false);
      } else {
        setError(true);
      }
    }
  };
  let earnedCredits =
    creditStatus?.data?.earned <= 0 ? 0 : creditStatus?.data?.earned;

  return (
    <Modal open={leaveConversion}>
      <Grow
        in={leaveConversion}
        style={{ transformOrigin: "0 0 0" }}
        {...(leaveConversion ? { timeout: 500 } : {})}
        unmountOnExit
      >
        <Card
          className="login-modal leave1-modal"
          sx={{
            minWidth: "350px",
            overflowY: "scroll",
            maxHeight: "calc(100vh - 40px)",
          }}
        >
          <CardHeader
            title={
              <Typography variant="h3" className="h3-bar">
                Leave Conversion Request
              </Typography>
            }
            action={
              <IconButton onClick={handleConversion}>
                <CloseOutlinedIcon sx={{ width: "15px", height: "15px" }} />
              </IconButton>
            }
          />
          {convertConfirm ? (
            <LeaveConversionResponse
              status={convertStatus}
              handleConversion={handleConversion}
              refetchMyQuery={refetchMyQuery}
            />
          ) : (
            <Box>
              <Box
                sx={{
                  width: "700px",
                  border: "1px solid #DFDFDF",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <Box sx={{ textAlign: "center", py: "30px" }}>
                  <Typography>Earned Credits</Typography>
                  <Typography sx={{ color: "#61646d" }}>
                    {earnedCredits}
                  </Typography>
                </Box>
                <Box sx={{ textAlign: "center", py: "30px" }}>
                  <Typography>Converted Credits</Typography>
                  <Typography sx={{ color: "#61646d" }}>
                    {creditStatus?.data?.converted}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "700px",
                  border: "1px solid #DFDFDF",
                  display: "flex",
                  justifyContent: "space-around",
                  mt: "15px",
                }}
              >
                <Box
                  sx={{
                    textAlign: "center",
                    py: "30px",
                    width: "100%",
                    my: "auto",
                  }}
                >
                  <Typography>Requested Credits :</Typography>
                </Box>
                <Box
                  sx={{
                    textAlign: "center",
                    py: "30px",
                    width: "100%",
                    my: "auto",
                    px: "20px",
                  }}
                >
                  <TextField
                    sx={
                      error
                        ? { width: "100%", border: "1px solid red!important" }
                        : { width: "100%" }
                    }
                    id="outlined-number"
                    value={convertCredit}
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      sx: error && {
                        "&:hover fieldset": {
                          border: "1px solid red!important",
                          borderRadius: 0,
                        },
                        "&:focus-within fieldset, &:focus-visible fieldset": {
                          border: "1px solid red!important",
                        },
                      },
                    }}
                    onChange={(e) => handleRequestCredit(e)}
                  />
                  {error && (
                    <Typography sx={{ color: "#E46A76" }}>
                      Invalid input credits
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  width: "700px",
                  mt: "15px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  disableElevation
                  sx={{
                    mr: "5px",
                    backgroundColor: "#E46A76",
                    "&:hover": {
                      backgroundColor: "#E46A76",
                    },
                  }}
                  onClick={handleConversion}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  sx={{
                    ml: "5px",
                    backgroundColor: "#00C292",
                    "&:hover": {
                      backgroundColor: "#00C292",
                    },
                  }}
                  onClick={() => handleConvert()}
                  disabled={convertCredit <= 0 || error}
                >
                  Apply
                </Button>
              </Box>
            </Box>
          )}
        </Card>
      </Grow>
    </Modal>
  );
};

export default LeaveConversion;
