import { Popover } from "@mui/material";
import React from "react";
import ColorPicker from "../../Settings/CompanySettings/containers/general-identity/component/color-picker";

const ColorPopper = ({ PopOver, handleClose, handleColor, color }) => {
  const id = PopOver ? "simple-popover" : undefined;
  const open = Boolean(PopOver);

  return (
    <Popover
      open={open}
      id={id}
      anchorEl={PopOver}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <ColorPicker color={color} onChange={handleColor} reused={"Skill"} />
    </Popover>
  );
};

export default ColorPopper;
