import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const CheckboxFilter = ({
  isWeekendShown,
  isInactiveUsersShown,
  handleIsWeekendShownChange,
  handleIsInactiveUsersShown,
}) => {
  return (
    <FormGroup sx={{ display: "flex", flexDirection: "row", gap: "14px" }}>
      <FormControlLabel
        checked={isWeekendShown}
        control={<Checkbox onClick={handleIsWeekendShownChange} size="small" />}
        label="Weekends"
        sx={{ margin: "0px" }}
      />

      <FormControlLabel
        checked={isInactiveUsersShown}
        control={<Checkbox onClick={handleIsInactiveUsersShown} size="small" />}
        label="Inactive Users"
        sx={{ marginLeft: "0px" }}
      />
    </FormGroup>
  );
};

export default CheckboxFilter;
