import { TableRow, TableCell } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
const Fetching = () => {
  return (
    <TableRow>
      <TableCell sx={{ height: "90px" }} align="center" colSpan={"100%"}>
        <CircularProgress />
      </TableCell>
    </TableRow>
  );
};

export default Fetching;
