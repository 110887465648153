import React, { useRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { Typography, TextField, Box } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import CircularProgress from "@mui/material/CircularProgress";

const AutoCompleteDescription = ({
  onChange,
  optionData,
  label,
  value,
  loading,
  multiple,
  data,
  removeClearBtn,
}) => {
  const textInput = useRef(null);
  const option =
    optionData?.map((opt) => {
      return {
        id: opt[data.id],
        name: opt[data.name],
        description: opt[data.desc],
      };
    }) || [];
  return (
    <Autocomplete
      disableClearable={removeClearBtn}
      multiple={multiple}
      blurOnSelect
      size="small"
      fullWidth
      options={option}
      defaultValue={value}
      getOptionLabel={(option) => option.name}
      onChange={onChange}
      isOptionEqualToValue={(option, value) =>
        Array.isArray(value)
          ? value.some((v) => v.id === option.id)
          : value?.id === option.id
      }
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={textInput}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <Box
          component="li"
          {...props}
          sx={{
            flexDirection: "column",
            alignItems: "flex-start !important",
          }}
          key={option.id}
        >
          <Typography variant="h6" sx={{ fontSize: "0.75rem" }}>
            {option.name}
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontSize: "0.75rem" }}
            color="text.secondary"
          >
            {option?.description}
          </Typography>
        </Box>
      )}
      popupIcon={<KeyboardArrowDownRoundedIcon />}
    />
  );
};

export default AutoCompleteDescription;
