import React from "react";
import { Divider, Stack, Button } from "@mui/material";
import { BiCrown, BiLike, BiStar, BiHeart } from "react-icons/bi";

const ReactionCount = ({
  expand,
  colleague,
  submitReaction,
  page,
  empId,
  handleOpen,
}) => {
  return (
    <Stack
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
      sx={{
        justifyContent: "space-evenly",
        position: expand ? "" : "absolute",
        bottom: expand ? "" : "20px",
        left: expand ? "" : "27px",
      }}
    >
      {colleague?.count_likes && !expand && (
        <Button
          disabled
          size="small"
          startIcon={<BiLike fontSize="s" />}
          sx={{
            minWidth: "45px",
            padding: "0px",
            color: "#9DB4E9 !important",
          }}
        >
          {colleague?.count_likes}
        </Button>
      )}
      {colleague.count_loves && !expand && (
        <Button
          disabled
          size="small"
          startIcon={<BiHeart />}
          sx={{
            minWidth: "45px",
            padding: "0px",
            color: "#F4A6BC !important",
          }}
        >
          {colleague.count_loves}
        </Button>
      )}

      {colleague.count_commends && !expand && (
        <Button
          size="small"
          startIcon={<BiStar />}
          sx={{
            minWidth: "45px",
            padding: "0px",
            color: "#C4A0EC",
          }}
        >
          {colleague.count_commends}
        </Button>
      )}

      {expand && (
        <Button
          size="small"
          startIcon={<BiLike fontSize="s" />}
          sx={{
            minWidth: "45px",
            padding: "0px",
            color: "#9DB4E9",
          }}
          onClick={() => submitReaction("like", empId, page)}
        >
          {colleague.count_likes}
        </Button>
      )}
      {expand && (
        <Button
          size="small"
          startIcon={<BiHeart />}
          sx={{
            minWidth: "45px",
            padding: "0px",
            color: "#F4A6BC",
          }}
          onClick={() => submitReaction("love", empId, page)}
        >
          {colleague.count_loves}
        </Button>
      )}

      {expand && (
        <Button
          size="small"
          startIcon={<BiStar />}
          sx={{
            minWidth: "45px",
            padding: "0px",
            color: "#C4A0EC",
          }}
          onClick={() => handleOpen("love", empId, page)}
        >
          {colleague.count_commends}
        </Button>
      )}
      {expand && (
        <Button
          size="small"
          startIcon={<BiCrown />}
          sx={{
            minWidth: "45px",
            padding: "0px",
            color: "#F5D67B",
          }}
        >
          0
        </Button>
      )}
    </Stack>
  );
};

export default ReactionCount;
