import { useQuery } from "react-query";
import { getSchedule, getShift } from "../../../api/schedule";

const useSchedule = (
  QueryParam,
  keyword = "",
  limit = 10,
  offset = 1,
  fetch
) => {
  return useQuery(["get-schedules", keyword, limit, offset, fetch], () =>
    getSchedule(QueryParam)
  );
};

const useShift = (
  QueryParam,
  keyword = "",
  limit = 10,
  offset = 1,
  toggleFetch
) => {
  return useQuery(["get-shifts", keyword, limit, offset, toggleFetch], () =>
    getShift(QueryParam)
  );
};

const queries = {
  useSchedule,
  useShift,
};

export default queries;
