export const LeaveConversionLogsTableHeader = [
  {
    label: "Name",
    key: "name",
    isSortable: true,
  },
  {
    label: "Request",
    key: "request",
    isSortable: true,
  },
  {
    label: "Status",
    key: "status",
    isSortable: true,
  },
  {
    label: "Date Requested",
    key: "date_request",
    isSortable: true,
  },
  {
    label: "Date Reviewed",
    key: "date_reviewed",
    isSortable: true,
  },

  {
    label: "Reviewed By",
    key: "reviewed_by",
    isSortable: true,
    // align: "right",
  },
];
