import apiService from "../axios";

export const getPunctualityAndTardiness = async ({
  page_size = 10,
  fromDate,
  toDate,
  search,
  page = 1,
}) => {
  try {
    let response = await apiService.get(
      `v5/time-inout/tardiness-report?page_size=${page_size}&fromDate=${fromDate}&toDate=${toDate}&search=${search}&page=${page}`,
      {
        Accept: `application/json`,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
