import { useState } from "react";

import Box from "@mui/material/Box";
import DownloadIcon from "@mui/icons-material/Download";
import RefreshIcon from "@mui/icons-material/Refresh";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

import { useExportActivityReport } from "../../../../hooks/react-query/PerActivity";
import StyledActionButton from "../button/StyledActionButton";
import { EXPORT_TYPE } from "../../../../lib/reports/perActivity";
import { formatDateForQuery } from "../../../../helper/Reports/PerActivity";

const ActionButtons = ({
  fromDate,
  toDate,
  isWeekendShown,
  isInactiveUsersShown,
  handleRefresh,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { mutate: exportActivityReport } = useExportActivityReport();

  const openMenuHandler = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenuHandler = () => {
    setAnchorEl(null);
  };

  const exportFileHandler = (event) => {
    const payload = {
      type: event.target.id,
      fromDate: formatDateForQuery(fromDate),
      toDate: formatDateForQuery(toDate),
      weekend: isWeekendShown,
      inActives: isInactiveUsersShown,
    };
    exportActivityReport(payload);
  };

  const isMenuOpen = !!anchorEl;

  return (
    <Box sx={{ display: "flex", gap: "14px" }}>
      <StyledActionButton
        color="primary"
        size="small"
        variant="contained"
        startIcon={<DownloadIcon />}
        onClick={openMenuHandler}
      >
        Export
      </StyledActionButton>

      <StyledActionButton
        color="primary"
        size="small"
        variant="contained"
        startIcon={<RefreshIcon />}
        onClick={handleRefresh}
      >
        Refresh
      </StyledActionButton>

      <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={closeMenuHandler}>
        <MenuItem id={EXPORT_TYPE.excel.id} onClick={exportFileHandler}>
          Export as {EXPORT_TYPE.excel.extension}
        </MenuItem>

        <Divider />

        <MenuItem id={EXPORT_TYPE.csv.id} onClick={exportFileHandler}>
          Export as {EXPORT_TYPE.csv.extension}
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ActionButtons;
