import { useQuery } from "react-query";
import { getPerProjectSummary } from "../../../api/perProjectSummary";

const usePerProjectSummary = (params) => {
  return useQuery(
    ["get-project-code-summary", params.criteria],
    () => getPerProjectSummary(params.payload),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const queries = {
  usePerProjectSummary,
};

export default queries;
