import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import { Skeleton, Tooltip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

import Button from "@mui/material/Button";
const CustomBadgeMenu = ({
  anchorEl,
  handleClose,
  open,
  notif,
  handleClick,
  handleReadAll,
  handleRead,
}) => {
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        "& .MuiList-padding": {
          padding: "10px",
        },
      }}
    >
      <Box m={1} width={350}>
        <Typography variant="h4" className="n-h4">
          Notifications
        </Typography>
        {/* <Box sx={{ marginLeft: "16px" }}>
              <Chip label="Coming soon" className="chip-bx" />
            </Box> */}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!notif?.loading ? (
            notif?.data.length === 0 ? (
              <Typography
                sx={{ fontWeight: 500, fontSize: ".80rem", padding: "8px" }}
              >
                No new notification.
              </Typography>
            ) : (
              <Box
                sx={{
                  maxHeight: "420px",

                  overflowY: "scroll",
                }}
                mt={1}
              >
                {notif?.data.map((data, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <MenuItem
                      sx={{ padding: "16px", width: "90%" }}
                      onClick={() => handleClick(data)}
                    >
                      <Typography
                        sx={{
                          whiteSpace: "initial",
                          fontWeight: 500,
                          fontSize: ".80rem",
                        }}
                      >
                        {data.message}
                      </Typography>
                    </MenuItem>
                    <Tooltip title="Mark as read">
                      <IconButton onClick={() => handleRead(data)}>
                        <CancelIcon />
                      </IconButton>
                    </Tooltip>
                    <Divider />
                  </Box>
                ))}
              </Box>
            )
          ) : (
            <Box sx={{ padding: "10px" }}>
              <Skeleton
                variant="rounded"
                width={350}
                height={40}
                sx={{ marginBottom: "10px" }}
              />
              <Skeleton
                variant="rounded"
                width={350}
                height={40}
                sx={{ marginBottom: "10px" }}
              />
              <Skeleton
                variant="rounded"
                width={350}
                height={40}
                sx={{ marginBottom: "10px" }}
              />
            </Box>
          )}
        </Box>
        {!notif?.loading && notif?.data.length > 0 && (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              disableElevation
              variant="contained"
              size="small"
              sx={{
                margin: " 16px 0px 0px",
                fontWeight: 400,
                color: "rgb(255, 255, 255)",
                backgroundColor: "rgb(3, 201, 215)",
                textTransform: "none",
                boxShadow: "none",
                fontSize: "15px",
              }}
              onClick={() => {
                window.location.href = "/notification";
              }}
            >
              See all notifications
            </Button>
            <Button
              disableElevation
              variant="contained"
              size="small"
              sx={{
                margin: " 16px 0px 0px",
                fontWeight: 400,
                color: "rgb(255, 255, 255)",
                backgroundColor: "rgb(3, 201, 215)",
                textTransform: "none",
                boxShadow: "none",
                fontSize: "15px",
              }}
              onClick={handleReadAll}
            >
              Clear all notifications
            </Button>
          </Box>
        )}
      </Box>
    </Menu>
  );
};

export default CustomBadgeMenu;
