import apiService from "../../axios";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  GetProjectCode: async (per_page, search, status, page, department) => {
    try {
      const request =
        await apiService.get(`v5/settings/timesheet/codes/get?page=${page}&per_page=${per_page}&search=${search}&status=${status}&department=${department}
      `);
      return request.data;
    } catch (error) {
      return error;
    }
  },
  GetClients: async () => {
    try {
      const request = await apiService.get(`v1/clients/get-clients`, {
        Accept: "application/json",
      });
      return request.data;
    } catch (error) {
      return error;
    }
  },
  GetProjectType: async () => {
    try {
      const request = await apiService.post(`v1/employee/get-project-types`, {
        Accept: "application/json",
      });
      return request.data;
    } catch (error) {
      return error;
    }
  },
  GetDepartments: async () => {
    try {
      const request = await apiService.get(`v1/department/get-active-dept`, {
        Accept: "application/json",
      });
      return request.data;
    } catch (error) {
      return error;
    }
  },

  EditProjectCode: async (payload) => {
    try {
      const { data } = await apiService.post(
        `v1/settings/timesheet/codes/update`,
        payload,
        {
          Accept: "Application/json",
          body: JSON.stringify(payload),
        }
      );
      return data.data;
    } catch (error) {
      return error;
    }
  },

  AddProjectCode: async (payload) => {
    try {
      const { data } = await apiService.post(
        `v1/settings/timesheet/codes/add`,
        payload,
        {
          Accept: "Application/json",
          body: JSON.stringify(payload),
        }
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  },

  DeleteProjectCode: async (payload) => {
    try {
      const { data } = await apiService.post(
        `v1/settings/timesheet/codes/delete`,
        payload,
        {
          Accept: "Application/json",
          body: JSON.stringify(payload),
        }
      );
      return data.data;
    } catch (error) {
      return error;
    }
  },
  ImportProjectCode: async (payload) => {
    try {
      const data = await apiService.post(`v1/import/import-codes`, payload, {
        Accept: "Application/json",
        body: JSON.stringify(payload),
      });
      return data.data;
    } catch (error) {
      throw error;
    }
  },

  getHistory: async (payload) => {
    try {
      const data = await apiService.post(
        `v1/settings/timesheet/codes/get-history`,
        payload,
        {
          Accept: "Application/json",
          body: JSON.stringify(payload),
        }
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  },

  editHistoryRemarks: async (payload) => {
    try {
      const data = await apiService.post(
        `v1/settings/timesheet/codes/update-history-remarks`,
        payload,
        {
          Accept: "Application/json",
          body: JSON.stringify(payload),
        }
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  },

  getExportCodes: async () => {
    try {
      const data = await apiService.get(`v5/settings/timesheet/export-code`);
      return data.data;
    } catch (error) {
      throw error;
    }
  },
};
