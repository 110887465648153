import jsPDF from "jspdf";
import "jspdf-autotable";
export const filterSummaryExport = (data) => {
  let newArray = data?.map((val) => {
    delete val.id;
    delete val["this->approver_status"];
    delete val["this_approver_status"];
    delete val.paid;
    return val;
  });
  return newArray;
};

const changeColumnHeader = (value, select) => {
  let result = value;

  const toChange = {
    length: "Duration",
    approved: "Date approved",
  };

  for (const key in toChange) {
    if (key === value) {
      result = toChange[key];
      break;
    }
  }

  result = select === "Overtime" && value === "type" ? "Application" : result;

  return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
};

export const getHeaders = (data, type, select) => {
  let list = [Object.keys(data[0])];
  const headers = [];
  list[0].map((val) =>
    type === "csv"
      ? headers.push({
          label: changeColumnHeader(val, select),
          key: val,
        })
      : headers.push(changeColumnHeader(val, select))
  );
  return headers;
};

export const exportPDFSummary = (getData, getTitle, select) => {
  const unit = "pt";
  const size = "A4";
  const orientation = "landscape";

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);

  doc.setFontSize(15);

  const title = getTitle;
  const headers = [getHeaders(getData, "pdf", select)];
  const data = filterSummaryExport(getData);

  let content = {
    startY: 50,
    head: headers,
    body: data?.map((val) => Object.values(val)),
    theme: "grid",
  };

  doc.text(title, marginLeft, 40);
  doc.autoTable(content);
  doc.save(title + ".pdf");
};
