import React, { useContext } from "react";
import Chart from "react-apexcharts";
import { CHART_COLOR_CODE } from "../../../lib/barchart/barchartColorCode";
import { TimesheetContext } from "../../../globalstate/UserContext/timesheetcontext";
import { Stack, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
const { parseISO, getDay } = require("date-fns");
const DashboardApexBar = ({ user }) => {
  const { weekDates, popEntryModal } = useContext(TimesheetContext);
  const getChartColor = (code) => {
    const found = CHART_COLOR_CODE.find((element) => element.code === code);
    return found ? found.color : "rgb(0, 194, 146)";
  };
  const uniqueLegends = () => {
    const data = [
      ...new Set(user?.time_data?.map((item) => item.time_code.name)),
    ];
    return data;
  };

  const MoreThanEight = () => {
    let mon = [];
    let tue = [];
    let wed = [];
    let thur = [];
    let fri = [];
    let sat = [];
    let sun = [];
    let arr = [];

    user.time_data?.map((data) => {
      getDay(new Date(data.created)) === 1 && mon.push(parseFloat(data.hours));
      getDay(new Date(data.created)) === 2 && tue.push(parseFloat(data.hours));
      getDay(new Date(data.created)) === 3 && wed.push(parseFloat(data.hours));
      getDay(new Date(data.created)) === 4 && thur.push(parseFloat(data.hours));
      getDay(new Date(data.created)) === 5 && fri.push(parseFloat(data.hours));
      getDay(new Date(data.created)) === 6 && sat.push(parseFloat(data.hours));
      getDay(new Date(data.created)) === 0 && sun.push(parseFloat(data.hours));
      return 0;
    });
    arr.push(mon.reduce((a, b) => a + b, 0));
    arr.push(tue.reduce((a, b) => a + b, 0));
    arr.push(wed.reduce((a, b) => a + b, 0));
    arr.push(thur.reduce((a, b) => a + b, 0));
    arr.push(fri.reduce((a, b) => a + b, 0));
    arr.push(sat.reduce((a, b) => a + b, 0));
    arr.push(sun.reduce((a, b) => a + b, 0));

    return arr;
  };
  const barchartOptions = {
    options: {
      chart: {
        stacked: true,
        stackType: "100",
        id: "basic-bar",
        toolbar: {
          show: false,
        },
        events: {
          dataPointMouseEnter: (event, chartContext, config) => {
            event.path[0].style.cursor = "pointer";
          },
          dataPointSelection: (event, chartContext, config) => {
            const index = config.seriesIndex;
            popEntryModal(index);
          },
        },
      },
      xaxis: {
        position: "top",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            fontSize: "10px",
          },
        },
        categories: weekDates,
      },
      yaxis: {
        tickAmount: 8,
        min: 0,
        max:
          Math.max(...MoreThanEight()) > 8 ? Math.max(...MoreThanEight()) : 8,
        axisTicks: {
          show: true,
          color: "#00C292",
        },
        axisBorder: {
          show: true,
          color: "#00C292",
        },
        labels: {
          style: {
            colors: ["#00C292"],
          },
        },
      },
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      subtitle: {
        text: `Reminding you to kindly complete the timesheet for last week.
      If you haven't done so.`,
        align: "center",
        offsetX: 2,
        offsetY: 1,
        style: {
          fontSize: "10px",
          color: "rgb(119, 126, 137)",
        },
      },
      legend: {
        show: false,
      },
    },
  };
  return (
    <div>
      <Chart
        options={barchartOptions.options}
        series={
          user?.time_data?.length > 0
            ? user?.time_data.map((dt) => {
                const day = getDay(new Date(dt.created));
                switch (day) {
                  case 1:
                    return {
                      name: dt?.time_code?.name,
                      data: [parseFloat(dt.hours), 0, 0, 0, 0, 0, 0],
                      color: getChartColor(dt.time_code.name),
                    };
                  case 2:
                    return {
                      name: dt?.time_code?.name,
                      data: [0, parseFloat(dt.hours), 0, 0, 0, 0, 0],
                      color: getChartColor(dt.time_code.name),
                    };
                  case 3:
                    return {
                      name: dt?.time_code?.name,
                      data: [0, 0, parseFloat(dt.hours), 0, 0, 0, 0],
                      color: getChartColor(dt.time_code.name),
                    };
                  case 4:
                    return {
                      name: dt?.time_code?.name,
                      data: [0, 0, 0, parseFloat(dt.hours), 0, 0, 0],
                      color: getChartColor(dt.time_code.name),
                    };
                  case 5:
                    return {
                      name: dt?.time_code?.name,
                      data: [0, 0, 0, 0, parseFloat(dt.hours), 0, 0],
                      color: getChartColor(dt.time_code.name),
                    };
                  case 6:
                    return {
                      name: dt?.time_code?.name,
                      data: [0, 0, 0, 0, 0, parseFloat(dt.hours), 0],
                      color: getChartColor(dt.time_code.name),
                    };
                  case 0:
                    return {
                      name: dt?.time_code?.name,
                      data: [0, 0, 0, 0, 0, 0, parseFloat(dt.hours)],
                      color: getChartColor(dt.time_code.name),
                    };
                  default:
                    break;
                }
              })
            : [
                {
                  name: "",
                  data: [0, 0, 0, 0, 0, 0, 0],
                },
              ]
        }
        type="bar"
        height="300px"
      />
      <Stack
        direction="row"
        spacing={2}
        style={{ display: "flex", justifyContent: "center" }}
      >
        {uniqueLegends().map((code, ind) => (
          <div
            key={ind}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircleIcon style={{ width: "15px", color: getChartColor(code) }} />
            <Typography
              variant="caption"
              style={{ padding: "3px", fontSize: "0.6rem" }}
            >
              {code}
            </Typography>
          </div>
        ))}
      </Stack>
    </div>
  );
};

export default DashboardApexBar;
