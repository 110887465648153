import { useQuery } from "react-query";
import { getConversionLogs } from "../../../api/leaveConversionLogs";

const useConversionLogs = (params) => {
  return useQuery(["get-conversion-logs", params.criteria], () =>
    getConversionLogs(params.payload)
  );
};

const queries = {
  useConversionLogs,
};

export default queries;
