import React, { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  InputAdornment,
  Paper,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRowHeader from "../../../component/UserRequests/MyRequestComponent/Table/TableRowHeader";
import { useComplianceRequest } from "../../../hooks/react-query/ComplianceItem";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import Chip from "@mui/material/Chip";
import { useMutation, useQueryClient } from "react-query";
import { delCompliance } from "../../../api/compliance";
import DeleteComplianceModal from "../../../component/ComplianceItem/DeleteComplianceModal";
import AddComplianceModal from "../../../component/ComplianceItem/AddComplianceModal";
import EditComplianceModal from "../../../component/ComplianceItem/EditComplianceModal";

const ComplianceItemV5 = () => {
  const queryClient = useQueryClient();
  const { data: compliance } = useComplianceRequest();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [deleteData, setDeleteData] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [editItem, setEditItem] = useState("");
  const [editStatus, setEditStatus] = useState("");
  const [openAdd, setOpenAdd] = useState("");
  const [counter, setCounter] = useState(0);
  const columns = [
    { id: "item", label: "Item", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteMutation = useMutation((id) => delCompliance(id), {
    onSuccess: async () => {
      await queryClient.refetchQueries("get-compliance");
    },
  });

  const handleDelete = (id) => {
    setOpen(true);
    setDeleteData(id);
  };

  const handleEdit = (id, item, status) => {
    setOpenEdit(true);
    setEditId(id);
    setEditItem(item);
    setEditStatus(status);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmDeleteFunc = () => {
    deleteMutation.mutate(deleteData);
    handleClose();
  };

  const closeAddModal = () => {
    setOpenAdd(false);
  };

  useEffect(() => {
    const temp = compliance?.data?.filter((row) => {
      if (row.item?.toUpperCase().includes(search.toUpperCase())) {
        return row;
      }
    });
    setCounter(temp?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <>
      <Box sx={{ px: "50px", pt: "30px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            cursor: "context-menu",
          }}
        >
          <Tooltip
            title="Manage company compliance item"
            placement={"right"}
            arrow
          >
            <Typography sx={{ mb: "10px" }} variant={"h4"}>
              Compliance Item
            </Typography>
          </Tooltip>
        </Box>
        <Paper elevation={3}>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", p: "20px" }}
          >
            <Button
              onClick={() => {
                setOpenAdd(true);
              }}
              sx={{
                backgroundColor: "#21293B",
                ":hover": {
                  bgcolor: "#2E3852",
                  color: "white",
                },
              }}
              variant="contained"
              startIcon={<AddIcon />}
            >
              ADD ITEM
            </Button>
            <TextField
              type="search"
              id="outlined-search"
              label="Search"
              size={"small"}
              sx={{ width: "200px", minWidth: "200px", mr: "20px" }}
              InputProps={{
                endAdornment: search.length === 0 && (
                  <InputAdornment sx={{ cursor: "pointer" }} position="end">
                    <SearchRoundedIcon
                      color={search.length === 0 ? "" : "info"}
                    />
                  </InputAdornment>
                ),
              }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
        </Paper>
        <Paper elevation={3} sx={{ mt: "20px" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRowHeader columns={columns} />
              </TableHead>
              {counter === 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      NO RECORDS FOUND!
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {compliance?.data
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row, index) => {
                      return (
                        row.item
                          ?.toUpperCase()
                          .includes(search.toUpperCase()) && (
                          <TableRow key={index}>
                            {columns.map((column, index) => {
                              let value = row[column.id];
                              return (
                                <TableCell key={index}>
                                  {column.id === "action" ? (
                                    <Box sx={{ textAlign: "center" }}>
                                      <IconButton
                                        onClick={() =>
                                          handleEdit(
                                            row.id,
                                            row.item,
                                            row.status
                                          )
                                        }
                                        aria-label="edit"
                                        color={"success"}
                                      >
                                        <EditRoundedIcon />
                                      </IconButton>
                                      <IconButton
                                        onClick={() => handleDelete(row.id)}
                                        aria-label="delete"
                                        color={"error"}
                                      >
                                        <DeleteForeverRoundedIcon />
                                      </IconButton>
                                    </Box>
                                  ) : column.id === "status" ? (
                                    <Chip
                                      label={
                                        value === "active"
                                          ? "active"
                                          : "inactive"
                                      }
                                      color={
                                        value === "active"
                                          ? "success"
                                          : "default"
                                      }
                                      size={"small"}
                                    />
                                  ) : (
                                    value
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        )
                      );
                    })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={compliance?.data?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <AddComplianceModal openAdd={openAdd} closeAddModal={closeAddModal} />
        <DeleteComplianceModal
          open={open}
          handleClose={handleClose}
          cancelDeleteFunc={handleClose}
          confirmDeleteFunc={confirmDeleteFunc}
        />
        <EditComplianceModal
          editId={editId}
          editItem={editItem}
          editStatus={editStatus}
          openEdit={openEdit}
          handleCloseEdit={handleCloseEdit}
        />
      </Box>
    </>
  );
};

export default ComplianceItemV5;
