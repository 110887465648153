import React, { useContext, useState } from "react";
import { Grid, Tooltip, Typography } from "@mui/material";
import DashboardBarchart from "../../../component/Dashboard/apexChart/dashboardBarchart";
import Datepicker from "../../../component/Dashboard/datepicker";
import { useGetUserList } from "../../../hooks/react-query/SettingsTimesheet/TimesheetForAll";
import AutoCompleteDescription from "../../../component/CustomElement/AutoCompleteDescription";
import { SortAZ } from "../../../helper/sort";
import { useGetTimesheet } from "../../../hooks/react-query/timesheet";
import { format, startOfWeek, addDays } from "date-fns";
import { getWeeklyTimesheet, getHours } from "../../../helper/timesheet";
import { useEffect } from "react";
import { TimesheetContext } from "../../../globalstate/UserContext/timesheetcontext";
import Entry from "../../../component/timesheet/entry";

const initDataState = {
  employee: null,
  selectDate: new Date(),
  isInMonth: true,
};
const TimesheetAnalytics = () => {
  const { weekDates, setWeekDates, setTimesheet, setOpenBarChartEntryModal } =
    useContext(TimesheetContext);
  const [data, setData] = useState(initDataState);
  const [barChartData, setBarChartData] = useState([]);
  const { data: employee, isLoading: fetchingEmployees } = useGetUserList();
  const payload = {
    employee_id: data.employee,
    year: format(data.selectDate, "y"),
    month: format(data.selectDate, "MM"),
  };
  const {
    data: timesheet,
    isLoading: fetchingTimesheet,
    status,
    refetch,
  } = useGetTimesheet(payload, data.selectDate, data.isInMonth);

  const handleChangeEmployee = (e, val) => {
    console.log(val.id);
    setData({ ...data, employee: val.id });
    setTimeout(refetch, 1000);
  };

  const handleChangeDate = (e) => {
    setData({ ...data, selectDate: e });
  };
  useEffect(() => {
    const getWeekDates = () => {
      let weekDates = [];
      const monday = startOfWeek(data.selectDate, { weekStartsOn: 1 });
      for (let i = 0; i < 7; i++)
        weekDates.push(format(addDays(monday, i), "PP"));
      return weekDates;
    };
    setWeekDates(getWeekDates());
  }, [data.selectDate, setWeekDates]);

  useEffect(() => {
    if (status === "success") {
      setBarChartData({
        time_data: getWeeklyTimesheet(timesheet.time_data, weekDates),
        today_total_hours: getHours(timesheet.time_data, weekDates).today,
        weekly_total_hours: getHours(timesheet.time_data, weekDates)
          .weeklyTotal,
      });
      setTimesheet(timesheet.time_data);
      setOpenBarChartEntryModal(
        getWeeklyTimesheet(timesheet.time_data, weekDates)
      );
    }
  }, [setOpenBarChartEntryModal, setTimesheet, status, timesheet, weekDates]);
  return (
    <Grid container p={2}>
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          cursor: "context-menu",
        }}
      >
        <Tooltip
          title={"View employees timesheet analytics"}
          placement={"right"}
          arrow
        >
          <Typography variant="h5">Timesheet analytics</Typography>
        </Tooltip>
      </Grid>

      <Grid container>
        <Grid item lg={7}>
          <DashboardBarchart
            user={barChartData}
            isFetching={fetchingTimesheet}
            reports={
              <Grid item xs={6} sm={6} md={5.5}>
                <AutoCompleteDescription
                  label={"Employee"}
                  loading={fetchingEmployees}
                  optionData={SortAZ(employee, "full_name")}
                  data={{ id: "id", name: "full_name", desc: "description" }}
                  onChange={handleChangeEmployee}
                  multiple={false}
                />
              </Grid>
            }
          />
        </Grid>
        <Grid item lg={5}>
          <Datepicker
            onChange={handleChangeDate}
            value={data.selectDate}
            isFetching={fetchingTimesheet}
          />
        </Grid>
      </Grid>
      <Entry />
    </Grid>
  );
};

export default TimesheetAnalytics;
