import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import Checkbox from "@mui/material/Checkbox";
const ViewQuestion = ({
  customStyle,
  vq,
  index,
  setValue,
  getValues,
  hText,
}) => {
  const attributes = JSON.parse(vq.attr);
  const lab =
    vq.type_of_answer === "rating"
      ? attributes.map((att) => {
          return att.answer;
        })
      : null;
  const [value, setValues] = React.useState(null);
  const labels = {
    1: lab ? lab[0] : "",
    2: lab ? lab[1] : "",
    3: lab ? lab[2] : "",
    4: lab ? lab[3] : "",
    5: lab ? lab[4] : "",
  };

  const getLabelText = (value) => {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
  };
  return (
    <>
      {vq.type_of_answer === "multiple" && (
        <Grid item lg={12} xs={12}>
          <Card sx={{ position: "relative" }}>
            <Chip
              label={vq.question}
              sx={{
                position: "absolute",
                color: "#fff",
                bgcolor: "#00C292",
                borderRadius: 0,
                height: "50px",
                width: "100%",
                textAlign: "center",
              }}
            />
            <CardContent sx={{ mt: "50px" }}>
              <RadioGroup>
                <Grid container columnSpacing={2} rowSpacing={2}>
                  {attributes.map((att, i) => (
                    <Grid item lg={6} xs={12} key={i}>
                      <Card sx={customStyle.vv}>
                        <FormControlLabel
                          value={att.option}
                          control={
                            <Radio
                              required
                              onClick={() => {
                                setValue(`ans${index}`, [
                                  {
                                    option: att.option,
                                    answer: att.answer,
                                    selected: true,
                                  },
                                ]);
                              }}
                            />
                          }
                          label={att.answer}
                        />
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </RadioGroup>
            </CardContent>
          </Card>
        </Grid>
      )}
      {vq.type_of_answer === "single" && (
        <Grid item lg={12} xs={12}>
          <Card sx={{ position: "relative" }}>
            <Chip
              label={vq.question}
              sx={{
                position: "absolute",
                color: "#fff",
                bgcolor: "#00C292",
                borderRadius: 0,
                width: "100%",
                height: "50px",
                textAlign: "center",
              }}
            />
            <CardContent sx={{ mt: "50px" }}>
              <TextField
                size="small"
                label="Answer written here"
                fullWidth
                required
                onChange={(e) => {
                  setValue(`ans${index}`, e.target.value);
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      )}
      {vq.type_of_answer === "checkbox" && (
        <Grid item lg={12} xs={12}>
          <Card sx={{ position: "relative" }}>
            <Chip
              label={vq.question}
              sx={{
                position: "absolute",
                color: "#fff",
                bgcolor: "#00C292",
                borderRadius: 0,
                width: "100%",
                height: "50px",
                textAlign: "center",
              }}
            />
            <CardContent sx={{ mt: "50px" }}>
              <Grid container columnSpacing={2} rowSpacing={2}>
                {attributes.map((att, i) => (
                  <Grid item lg={6} xs={12} key={i}>
                    <Card sx={customStyle.vv}>
                      <FormControlLabel
                        value={att.option}
                        control={
                          <Checkbox
                            onChange={(e, nv) => {
                              const checkObj = getValues("checkbox");
                              const newObj = checkObj.map((chk, ind) => {
                                if (ind === i) {
                                  return {
                                    option: chk.option,
                                    answer: chk.answer,
                                    selected: nv,
                                  };
                                } else {
                                  return {
                                    option: chk.option,
                                    answer: chk.answer,
                                    selected: chk.selected,
                                  };
                                }
                              });
                              setValue("checkbox", newObj);
                            }}
                          />
                        }
                        label={att.answer}
                      />
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
      {vq.type_of_answer === "rating" && labels !== null && (
        <Grid item lg={12} xs={12}>
          <Card sx={{ position: "relative" }}>
            <Chip
              label={vq.question}
              sx={{
                position: "absolute",
                color: "#fff",
                bgcolor: "#00C292",
                borderRadius: 0,
                width: "100%",
                height: "50px",
                textAlign: "center",
              }}
            />
            <CardContent sx={{ mt: "50px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Rating
                  required
                  size="large"
                  value={value}
                  precision={1}
                  getLabelText={getLabelText}
                  onChange={(event, newValue) => {
                    setValues(newValue);
                    setValue(`ans${index}`, [
                      {
                        option: newValue,
                        answer: labels[newValue],
                        selected: true,
                      },
                    ]);
                  }}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                />
                {value !== null && <Box sx={{ ml: 2 }}>{labels[value]}</Box>}
              </Box>
              {hText && (
                <FormHelperText error sx={{ textAlign: "center", mt: "10px" }}>
                  This field is required
                </FormHelperText>
              )}
            </CardContent>
          </Card>
        </Grid>
      )}
    </>
  );
};

export default ViewQuestion;
