import { useQuery } from "react-query";
import api from "../../api/projects/index";

const RequestAddress = (data, locationObj) => {
  return useQuery(["geolocation-address"], () => api.getAddress(data), {
    enabled: !!locationObj, // Only call the query if locationObj is truthy
  });
};

export default RequestAddress;
