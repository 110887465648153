import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

const ClearFieldButton = ({ show, onClear }) => (
  <Fade in={show} timeout={200}>
    <IconButton
      onClick={onClear}
      size="large"
      sx={{
        margin: "6px",
        padding: "4px",
        "&:hover": {
          backgroundColor: "transparent",
        },
      }}
    >
      <ClearIcon
        fontSize="small"
        sx={{
          color: "#adb5bd",
          "&:hover": {
            color: "#21293C",
          },
        }}
      />
    </IconButton>
  </Fade>
);
export default ClearFieldButton;
