import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

const RowStack = styled(Stack)(() => ({
  justifyContent: "space-between",
  alignItems: "center",
  padding: "1rem",
  border: `1px solid ${grey[400]}`,
  borderRadius: "3px",
}));

export default RowStack;
