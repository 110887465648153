import React from "react";
import { TableCell, IconButton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const DeleteRow = ({ isLoading, DeleteProjectCode, id, setDelInd }) => {
  return (
    <>
      <TableCell colSpan={10} align="center">
        Are you sure you want to delete this project code?
      </TableCell>
      <TableCell align="center">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <IconButton
              size="small"
              onClick={() => {
                DeleteProjectCode({ id: id });
                setDelInd(null);
              }}
              sx={{ color: "#61a644" }}
            >
              <CheckIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => setDelInd(null)}
              sx={{ color: "rgb(228, 106, 118)" }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        )}
      </TableCell>
    </>
  );
};

export default DeleteRow;
