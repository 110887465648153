export const TimekeepingHeader = [
  {
    label: "Name",
    key: "name",
    isSortable: true,
  },
  {
    label: "Average Time In",
    key: "avgTimeIn",
    isSortable: true,
    type: "number",
    align: "center",
  },
  {
    label: "Average Time Out",
    key: "avgTimeOut",
    isSortable: true,
    type: "number",
    align: "center",
  },
  {
    label: "Late (Count)",
    key: "countLates",
    isSortable: true,
    type: "number",
    align: "center",
  },
  {
    label: "Total (Minutes)",
    key: "sumLates",
    isSortable: true,
    type: "number",
    align: "center",
  },
  {
    label: "Average (Per Instance)",
    key: "averageLate",
    isSortable: true,
    type: "number",
    align: "center",
  },
];
