import React from "react";
import { Typography } from "@mui/material";
import Search from "../../../component/AdminTables/Search";
const pointerStyle = {
  cursor: "pointer",
  borderRadius: "10px",
  padding: "5px",
};
const numberSpanStyle = {
  backgroundColor: "red",
  borderRadius: "50%",
  padding: "1px 4px 1px 4px",
  marginLeft: "5px",
  color: "white",
  fontSize: "0.70rem",
};

const EmployeeHeader = ({ search, employees }) => {
  return (
    <div
      style={{
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <div>
        <Typography
          variant="caption"
          style={pointerStyle}
          sx={{ marginRight: "8px", border: "2px solid #0096FF" }}
        >
          Equipped
          <span style={numberSpanStyle}>{employees?.equipped}</span>
        </Typography>

        <Typography
          variant="caption"
          style={pointerStyle}
          sx={{ marginLeft: "8px", border: "2px solid #E3963E" }}
        >
          Pending
          <span style={numberSpanStyle}>{employees?.pending}</span>
        </Typography>
      </div>
      <Search
        textInput={search.textInput}
        setSearch={search.handleSearch}
        ClearSearch={search.ClearSearch}
        variant={"outlined"}
      />
    </div>
  );
};

export default EmployeeHeader;
