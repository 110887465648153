import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: 800,
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const ChangeOpenModal = ({ open, handleClose }) => {
  return (
    <Modal
      hideBackdrop
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h6" mb={3}>
          Project Code Budget
        </Typography>
        <div style={{ fontSize: "0.85rem" }}>
          You can`t change from open to budgeted project. Must create a new
          project with budget specified
        </div>
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}
        >
          <Button onClick={handleClose} variant={"contained"}>
            Ok
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default ChangeOpenModal;
