import React from "react";
import {
  Backdrop,
  Modal,
  Fade,
  Box,
  Typography,
  FormControl,
  Button,
} from "@mui/material";
import "react-toastify/dist/ReactToastify.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const DeleteProjectType = ({ deleteRow, closeHandler, deleteHandler }) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={deleteRow}
      //   onClose={setOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={deleteRow}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Delete this Project Type?
          </Typography>
          <FormControl sx={{ display: "flex", justifyContent: "space-around" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                size="small"
                sx={{ marginX: "5px", backgroundColor: "#00c292" }}
                variant="contained"
                onClick={deleteHandler}
              >
                <Typography sx={{ fontSize: "0.78rem" }}>YES</Typography>
              </Button>
              <Button
                sx={{ marginX: "5px", backgroundColor: "#ff0000" }}
                size="small"
                onClick={closeHandler}
                variant="contained"
              >
                <Typography sx={{ fontSize: "0.78rem" }}>CANCEL</Typography>
              </Button>
            </div>
          </FormControl>
        </Box>
      </Fade>
    </Modal>
  );
};

export default DeleteProjectType;
