import React, { useContext } from "react";
import Calendar from "react-calendar";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import waves from "../../images/greenwaves.png";
import "./datepicker.scss";
import { TimesheetContext } from "../../globalstate/UserContext/timesheetcontext";
import moment from "moment";
import Skeleton from "@mui/material/Skeleton";

const Datepicker = ({ height, isFetching, onChange, value }) => {
  const globalState = useContext(TimesheetContext);
  const [initDate, setInitDate] = React.useState(new Date());

  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      let parseDate = moment(date).format("MMM DD, YYYY");
      let filterData = globalState.timesheet?.filter((el) => {
        if (el.status === 1) {
          return el.created === parseDate;
        }
        return false;
      });

      let hours = 0;

      filterData?.map((el) => {
        return (hours = hours + parseFloat(el.hours));
      });
      if (hours === 8) return "timesheet-calendar-hit";
      else if (hours > 8) return "timesheet-calendar-over";
      else if (hours < 8 && hours > 0) return "timesheet-calendar-under";
      else return "timesheet-calendar-nowork";
    }
  };

  return (
    <Card className="picker-card">
      <CardMedia component="img" height="50" image={waves} alt="Calendar" />
      {isFetching ? (
        <Skeleton variant="rounded" height={300} />
      ) : (
        <CardContent sx={{ height: height }}>
          <Calendar
            tileClassName={tileClassName}
            onChange={(e) => onChange(e)}
            value={value}
          />
        </CardContent>
      )}
    </Card>
  );
};

export default Datepicker;
