import { useEffect } from "react";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";

import RowStack from "./RowStack";

const RequestedCreditsRow = ({
  originalRequested,
  requested,
  earned,
  isValidRequested,
  changeRequestedCredits,
}) => {
  const handleChangeRequestedCredits = (event) => {
    const credits = event.target.valueAsNumber;
    changeRequestedCredits(credits);
  };

  // ? This will automatically set the requested credits to the original request when the dialog is closed.
  useEffect(() => {
    return () => {
      changeRequestedCredits(originalRequested);
    };
  }, [originalRequested, changeRequestedCredits]);

  return (
    <RowStack direction="row" spacing={2}>
      <Typography
        minWidth="max-content"
        height="53.125px"
        display="flex"
        alignItems="center"
        alignSelf="flex-start"
      >
        Requested Credits
      </Typography>

      <FormControl variant="outlined" fullWidth error={!isValidRequested}>
        <OutlinedInput
          type="number"
          value={requested}
          inputProps={{
            min: 0.5,
            max: earned,
            step: 0.5,
          }}
          onChange={handleChangeRequestedCredits}
        />

        {!isValidRequested && (
          <FormHelperText error>Invalid requested credits.</FormHelperText>
        )}
      </FormControl>
    </RowStack>
  );
};

export default RequestedCreditsRow;
