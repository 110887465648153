import * as yup from "yup";

export const pulseSurveySchema = yup
  .object({
    surveyName: yup.string().required("Please select survey name."),
    surveyDescription: yup
      .string()
      .required("Please select survey description."),
    // surveyType: yup.string().required("Please select survey type."),
    // surveyOption: yup.string().required("Please select survey option."),
  })
  .required();
