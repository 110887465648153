import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import React from "react";
import { LoadingButton } from "@mui/lab";
import TextField from "../../component/TimesheetActivity/TextField";
import Select from "../../component/TimesheetActivity/Select";
import ChipSelect from "../../component/TimesheetActivity/ChipSelect";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import BlockIcon from "@mui/icons-material/Block";
const Add = ({
  formFields,
  open,
  handleClose,
  handleConfirm,
  submitting,
  form,
  setForm,
}) => {
  const handleSubmit = () => {
    handleConfirm(form);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add Project Activity</DialogTitle>
      <DialogContent>
        {formFields &&
          formFields.map((field, index) => (
            <React.Fragment key={index}>
              {field.type === "text" && (
                <TextField
                  key={index}
                  field={field}
                  form={form}
                  setForm={setForm}
                />
              )}
              {field.type === "select" && (
                <Select
                  key={index}
                  field={field}
                  form={form}
                  setForm={setForm}
                />
              )}
              {field.type === "chipselect" && (
                <ChipSelect
                  key={index}
                  field={field}
                  form={form}
                  setForm={setForm}
                />
              )}
            </React.Fragment>
          ))}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={submitting}
          size="small"
          onClick={handleSubmit}
          className="timest-button"
          disableElevation
          variant="contained"
          sx={{ marginLeft: "auto" }}
          startIcon={<SaveOutlinedIcon />}
        >
          Submit
        </LoadingButton>
        <LoadingButton
          loading={false}
          size="small"
          onClick={handleClose}
          disableElevation
          variant="contained"
          sx={{
            "&:hover": { bgcolor: "#ca5764" },
            bgcolor: "#D06A76",
            marginLeft: "auto",
            textTransform: "none",
            px: "16px",
            py: "6px",
          }}
          startIcon={<BlockIcon />}
        >
          Cancel
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default Add;
