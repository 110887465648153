import NestedTableContainer from "../../container/NestedTableContainer";
import PerTimesheetTableHeader from "./PerTimesheetTableHeader";
import PerTimesheeeTableRow from "./PerTimesheetTableRow";
import PerActivityTablePagination from "../PerActivityTablePagination";
import GenericTableLoader from "../../loader/GenericTableLoader";
import NestedTableError from "../../error/NestedTableError";
import NoResults from "../NoResults";
import { useNestedActivityReport } from "../../../../../hooks/react-query/PerActivity";
import {
  PER_ACTIVITY_QUERY_KEYS,
  PER_TIMESHEET_HEADER_CELL_ID,
} from "../../../../../lib/reports/perActivity";

const PerTimesheetTable = ({ activityId, userId, timesheetId, state }) => {
  const { fromDate, toDate, isWeekendShown, isInactiveUsersShown } = state;

  const {
    query: { data, isLoading, isError },
    state: timesheetActivityState,
    handler,
  } = useNestedActivityReport(
    PER_ACTIVITY_QUERY_KEYS.getActivityReportPerTimesheet,
    { activityId, userId, timesheetId },
    fromDate,
    toDate,
    isWeekendShown,
    isInactiveUsersShown,
    PER_TIMESHEET_HEADER_CELL_ID.date
  );

  if (isError) return <NestedTableError />;

  return (
    <NestedTableContainer
      state={timesheetActivityState}
      handler={handler}
      TableHeader={PerTimesheetTableHeader}
      Pagination={
        <PerActivityTablePagination
          count={data?.total || 0}
          page={data?.current_page ? data.current_page - 1 : 0}
          pageSize={timesheetActivityState.pageSize}
          onPageChange={handler.handleChangePage}
          onRowsPerPageChange={handler.handleChangePageSize}
        />
      }
    >
      {!isLoading ? (
        data.data.map((timesheet) => (
          <PerTimesheeeTableRow key={timesheet.id} timesheet={timesheet} />
        ))
      ) : (
        <GenericTableLoader />
      )}

      {!isLoading && !data?.data.length && <NoResults />}
    </NestedTableContainer>
  );
};

export default PerTimesheetTable;
