import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Modal,
  Fade,
  Box,
  Typography,
  FormControl,
  Select,
  Button,
  MenuItem,
  InputLabel,
  TextField,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import hooks from "../../../hooks/react-query/UserAppSettings";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const UpdateLeaveSettingsModal = ({ edit, setEdit, item }) => {
  const [selectedDate, setSelectedDate] = useState(
    new Date(item?.leave_setting.date_hired)
  );
  const [tl1, setTl1] = useState({
    id: 0,
    email: "No Email",
    name: "none",
  });
  const [tl2, setTl2] = useState({
    id: 0,
    email: "No Email",
    name: "none",
    option: null,
  });
  const [tl3, setTl3] = useState({
    id: 0,
    email: "No Email",
    name: "none",
    option: null,
  });
  const [option1, setOption1] = useState([]);
  const [option2, setOption2] = useState([]);
  const [attr, setAttr] = useState({
    tl1: { id: 0, email: "No Email", name: "none", option: null },
    tl2: { id: 0, email: "No Email", name: "none", option: null },
    tl3: { id: 0, email: "No Email", name: "none", option: null },
  });
  const [statusUpdate, setStatusUpdate] = useState(item?.leave_setting.status);
  const { data } = hooks.useManagers();
  const updateMutation = hooks.useUpdateSettings();
  useEffect(() => {
    let attr = JSON.parse(item?.leave_setting.attr);
    let d_val = {
      email: "No Email",
      id: "0",
      name: "None",
      option: null,
    };
    let tl1 = "";
    let tl2 = "";
    let tl3 = "";
    if ("tl1" in attr) {
      tl1 = attr["tl1"];
    } else {
      tl1 = d_val;
    }

    if ("tl2" in attr) {
      tl2 = attr["tl2"];
    } else {
      tl2 = d_val;
    }

    if ("tl3" in attr) {
      tl3 = attr["tl3"];
    } else {
      tl3 = d_val;
    }
    setTl1(tl1);
    setTl2(tl2);
    setTl3(tl3);
    setAttr({ tl1: tl1, tl2: tl2, tl3: tl3 });
  }, [item]);

  const status = [
    { id: 1, value: "Probationary", name: "Probationary" },
    { id: 2, value: "Regular", name: "Regular" },
    { id: 3, value: "Regular(1 yr)", name: "Regular(1 yr)" },
    { id: 4, value: "Project-base", name: "Project-base" },
  ];

  const tl1Handler = (e) => {
    let approver = {};

    if (
      e.target.value !== "none" &&
      e.target.value !== 0 &&
      e.target.value !== undefined
    ) {
      approver = data?.data.filter((el) => el.id === e.target.value);
      approver = {
        id: approver[0].id,
        name: approver[0].full_name,
        email: approver[0].owner.email,
        option: null,
      };
    } else {
      approver = {
        id: 0,
        name: "none",
        email: "No Email",
        option: null,
      };
    }

    setTl1(approver);
    setAttr({ ...attr, tl1: approver });
  };

  const tl2Handler = (e) => {
    let approver = {};

    if (
      e.target.value !== "none" &&
      e.target.value !== 0 &&
      e.target.value !== undefined
    ) {
      approver = data?.data.filter((el) => el.id === e.target.value);
      approver = {
        id: approver[0].id,
        name: approver[0].full_name,
        email: approver[0].owner.email,
        option: option1,
      };
    } else {
      approver = {
        id: 0,
        name: "none",
        email: "No Email",
        option: null,
      };
    }

    setTl2(approver);
    setAttr({ ...attr, tl2: approver });
  };

  const tl3Handler = (e) => {
    let approver = {};

    if (
      e.target.value !== "none" &&
      e.target.value !== 0 &&
      e.target.value !== undefined
    ) {
      approver = data?.data.filter((el) => el.id === e.target.value);
      approver = {
        id: approver[0].id,
        name: approver[0].full_name,
        email: approver[0].owner.email,
        option: option2,
      };
    } else {
      approver = {
        id: 0,
        name: "none",
        email: "No Email",
        option: null,
      };
    }

    setTl3(approver);
    setAttr({ ...attr, tl3: approver });
  };

  const option1Handler = (e) => {
    setOption1(e.target.value);
    setTl2({ ...tl2, option: e.target.value });
  };

  const option2Handler = (e) => {
    setOption2(e.target.value);
    setTl3({ ...tl3, option: e.target.value });
  };

  const submitHandler = () => {
    let attr = { tl1: tl1, tl2: tl2, tl3: tl3 };
    const data = {
      id: item.id,
      api: true,
      name: item.full_name,
      status: statusUpdate,
      email: item?.owner.email,
      ee: item?.id,
      date: selectedDate,
      date_hired: selectedDate,
      emp_id: null,
      set: true,
      attr: JSON.stringify(attr),
      approvers: JSON.stringify(attr),
    };
    updateMutation.mutate(data);
    setEdit(false);
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={edit}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={edit}>
        <Box sx={style}>
          <Typography
            sx={{ marginLeft: 1 }}
            id="transition-modal-title"
            variant="h6"
            component="h2"
          >
            Update Leave Settings
          </Typography>
          <div sx={{ display: "flex", justifyContent: "space-around" }}>
            <Typography sx={{ marginLeft: 1 }}>{item.full_name}</Typography>
            <Typography sx={{ marginLeft: 1 }}>Approvers:</Typography>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="Approver" sx={{ marginLeft: 1 }}>
                Approver
              </InputLabel>
              <Select
                autoWidth
                labelId="Approver"
                label="Approver"
                value={tl1.id === 0 ? 0 : tl1.id}
                onChange={(e) => tl1Handler(e)}
              >
                <MenuItem key={0} value={0}>
                  Not Set
                </MenuItem>
                {data?.data.length > 0 &&
                  data?.data.map((item, i) => {
                    return (
                      <MenuItem key={i} value={item.id}>
                        {item.full_name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="Condition">Condition</InputLabel>
              <Select
                autoWidth
                labelId="Condition"
                label="Condition"
                value={tl2?.option || ""}
                onChange={(e) => option1Handler(e)}
              >
                <MenuItem key={1} value="OR">
                  OR
                </MenuItem>
                <MenuItem key={2} value="AND">
                  AND
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="Approver">Approver</InputLabel>
              <Select
                disabled={
                  tl2.option === null && option1.length === 0 ? true : false
                }
                autoWidth
                labelId="Approver"
                label="Approver"
                value={tl2.id === 0 ? 0 : tl2.id}
                onChange={(e) => tl2Handler(e)}
              >
                <MenuItem key={0} value={0}>
                  Not Set
                </MenuItem>
                {data?.data.length > 0 &&
                  data?.data.map((item, i) => {
                    return (
                      <MenuItem key={i} value={item.id}>
                        {item.full_name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="Condition">Condition</InputLabel>
              <Select
                autoWidth
                labelId="Condition"
                label="Condition"
                value={tl3?.option}
                onChange={(e) => option2Handler(e)}
              >
                <MenuItem key={1} value="OR">
                  OR
                </MenuItem>
                <MenuItem key={2} value="AND">
                  AND
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="Approver">Approver</InputLabel>
              <Select
                disabled={
                  tl3.option === null && option2.length === 0 ? true : false
                }
                autoWidth
                labelId="Approver"
                label="Approver"
                value={tl3?.id === 0 ? 0 : tl3?.id}
                onChange={(e) => tl3Handler(e)}
              >
                <MenuItem key={0} value={0}>
                  Not Set
                </MenuItem>
                {data?.data.length > 0 &&
                  data?.data.map((item, i) => {
                    return (
                      <MenuItem key={i} value={item.id}>
                        {item.full_name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="Status">status</InputLabel>
              <Select
                autoWidth
                labelId="Status"
                label="Status"
                defaultValue={statusUpdate || ""}
                onChange={(e) => setStatusUpdate(e.target.value)}
              >
                {status.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.value}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={selectedDate}
                onChange={(newValue) => setSelectedDate(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      marginTop: "10px",
                      marginLeft: 1,
                    }}
                    label="Start"
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              size="small"
              sx={{ marginX: "5px" }}
              variant="contained"
              onClick={() => setEdit(false)}
            >
              <Typography sx={{ fontSize: "0.78rem" }}>Close</Typography>
            </Button>
            <Button
              sx={{ marginX: "5px", backgroundColor: "#00c292" }}
              size="small"
              variant="contained"
              onClick={(e) => submitHandler(e)}
            >
              <Typography sx={{ fontSize: "0.78rem" }}>Submit</Typography>
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default UpdateLeaveSettingsModal;
