import axios from "axios";
import { useQuery } from "react-query";
import { getUser } from "../../../api/auth";
import { useAuth } from "../../../routes/privateroute";

export const useUser = () => {
  return useQuery(["get-user"], () => getUser(), {
    refetchOnWindowFocus: false,
    enabled: useAuth(),
  });
};
