import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";

export const downloadFile = ({ data, fileName, fileType }) => {
  const blob = new Blob([data], { type: fileType });

  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);

  const clickEvent = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  a.dispatchEvent(clickEvent);
  a.remove();
};

export const downloadCsv = (usersData, columns, columnsCSV) => {
  let headers = [];
  columnsCSV?.map((column) => {
    headers.push(column.label);
  });
  headers = headers.join(",");
  headers = [headers];

  const content = usersData.map((data) =>
    [
      data.name || "-",
      data.description || "-",
      data.expire_date ? moment(data.expire_date).format("YYYY-MM-DD") : "-",
      data.created_at ? data.created_at : "-",
      data.status ? (data.isActive === 1 ? "Active" : "Inactive") : "-",
    ].join(",")
  );

  const csvData = [...headers, ...content].join("\n");

  downloadFile({
    data: csvData,
    fileName: "wfh-template-" + moment().format("YYYYMMDD-HHmmss"),
    fileType: "text/csv",
  });
};

export const downloadPdf = (usersData, columns) => {
  let head = [];
  columns?.map((column) => {
    head.push(column.label);
  });
  head = [head];

  const body = usersData.map((user) => [
    user.name || "-",
    user.description || "-",
    user.expire_date ? moment(user.expire_date).format("YYYY-MM-DD") : "-",
    user.created_at ? user.created_at : "-",
    user.status ? (user.isActive === 1 ? "Active" : "Inactive") : "-",
  ]);

  const doc = new jsPDF("landscape");

  autoTable(doc, { head, body });

  doc.save("wfh-template-" + moment().format("YYYMMDD-HHmmss") + ".pdf");
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const generateErrorObj = (error) => {
  const status = error.response.status;

  if (status === 422) {
    return {
      title: Object.entries(error.response.data)[0][0],
      message: Object.entries(error.response.data)[0][1],
    };
  }

  return { title: null, message: "An error occured!" };
};
