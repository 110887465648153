import apiService from "../axios";

export const getAnnouncement = async (url) => {
  try {
    let response = await apiService.get(`v1/settings/get-motdV5`, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(url),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const confirmAnnouncement = async (data) => {
  try {
    let response = await apiService.post(`v1/settings/user-confirmV5`, data, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteDocument = async (data) => {
  try {
    let response = await apiService.post(
      `v1/settings/delete-documentV5`,
      data,
      {
        Accept: `application/json`,
        "Content-Type": `multipart/form-data`,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
