import { useQuery } from "react-query";
import { getPunctualityAndTardiness } from "../../../api/punctualityTradiness";

const usePunctualityTardiness = (params) => {
  return useQuery(
    ["get-report-punctuality-and-tradiness", params.criteria],
    () => getPunctualityAndTardiness(params.payload)
  );
};

const queries = {
  usePunctualityTardiness,
};

export default queries;
