import { useRef, useEffect, useCallback, useState } from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CommentBankIcon from "@mui/icons-material/CommentBank";
import { format } from "date-fns";

import BaseModal from "./BaseModal";
import StyledButton from "../../../SettingsTimesheet/PendingTimesheetEntries/Button/StyledButton";
import { useUserApplicationAction } from "../../../../hooks/react-query/UserApplications";
import useWindowSize from "../../../../hooks/customHooks/useWindowSize";
import {
  isWfh,
  forgotTimeInOut,
} from "../../../../helper/UserApplicationMgmt/UserApplications";
import { APP_TYPE } from "../../../../lib/UserAppMgmt/UserApplications";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import InputController from "../../../InputController";
import { useForm } from "react-hook-form";

const BASE_MODAL_CUSTOM_STYLES = {
  maxWidth: "640px",
  height: "max-content",
  display: "flex",
  flexDirection: "column",
  padding: "24px",
  flexFlow: "column",
  gap: "32px",
  overflow: "auto",
};

const HEADER_CONTAINER_STYLES = {
  display: "flex",
  gap: "12px",
  alignItems: "center",
};

const HEADER_ICON_STYLES = {
  display: "flex",
  padding: "4px",
  backgroundColor: "#21293C",
  borderRadius: "3px",
};

const DETAILS_TITLE_STYLES = {
  fontSize: "14px",
  fontWeight: "600",
  textTransform: "uppercase",
  color: "#868e96",
};

const DETAILS_CONTENT_STYLES = {
  fontSize: "14px",
};

const ACTION_BUTTON_STYLES = {
  width: "128px",
};

const CIRCULAR_PROGRESS_STYLES = {
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: "-12px",
  marginLeft: "-12px",
};

const TEXT_FIELD_STYLES = {
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#21293C",
    },
  },
};

const FORGOT_TEXT_STYLES = {
  color: "#000000",
};

const ApplicationActionModal = ({ open, onClose, details, action }) => {
  const { type, name, from, to, id, date, location, forgotType } = details;

  const textFieldRef = useRef();

  const { width } = useWindowSize();

  const {
    mutate: updateApplication,
    isLoading,
    isSuccess,
  } = useUserApplicationAction();

  const propertyToUse = width > 450 ? "title" : "alternateTitle";
  const title = APP_TYPE[type][propertyToUse];
  const isTypeWfh = isWfh(type);
  const isForgotTimeInOut = forgotTimeInOut(type);
  const startDate = format(new Date(from), "MMM dd, yyyy");
  const endDate =
    type === "document" ? null : format(new Date(to), "MMM dd, yyyy");
  const marginBottom = type !== APP_TYPE[type].id ? "18px" : "0px";
  const [penalty, setPenalty] = useState(null);
  const [timeStatus, setTimeStatus] = useState(null);
  const [requestTime, setRequestTime] = useState(
    !date || format(new Date(date), "hh:mm aa")
  );

  const {
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      time: date,
    },
  });

  const closeModalHandler = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    isSuccess && closeModalHandler();
  }, [isSuccess, closeModalHandler]);

  const timeStatusHandler = (e) => {
    setTimeStatus(e.target.value);
  };

  const submitActionHandler = () => {
    const payload = {
      applicationType: type,
      actionType: action,
      data: { id, remarks: textFieldRef.current.value },
    };
    updateApplication(payload);
  };

  const submitForgotTimeInOut = () => {
    const payload = {
      applicationType: type,
      actionType: action,
      data: {
        id,
        time: `${format(new Date(endDate), "MMM dd, yyyy")} ${requestTime}`,
        remarks: textFieldRef.current.value,
        penalty_mins:
          action === "disapprove"
            ? null
            : timeStatus === "late" || timeStatus === "undertime"
            ? penalty
            : null,
        time_status: action === "disapprove" ? null : timeStatus,
      },
    };
    updateApplication(payload);
  };

  const hoursChecker = (event) => {
    setRequestTime(format(event, "hh:mm aa"));
  };

  const presentLabel = forgotType === "in" ? "Present" : null;
  const lateLabel = forgotType === "in" ? "Late" : "Present";
  const undertimeLabel = forgotType === "in" ? "Grace" : "Undertime";

  return (
    <BaseModal
      open={open}
      onClose={closeModalHandler}
      sx={BASE_MODAL_CUSTOM_STYLES}
    >
      {/* HEADER */}
      <Box sx={{ ...HEADER_CONTAINER_STYLES, marginBottom }}>
        <Box sx={HEADER_ICON_STYLES}>
          <CommentBankIcon sx={{ color: "#fff" }} />
        </Box>
        <Typography fontSize={18}>{`${title} Comment`}</Typography>
      </Box>
      {/* FORGOT TIME IN OUT REQUEST DETAILS */}
      {isForgotTimeInOut && (
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Typography sx={DETAILS_TITLE_STYLES}>
              Name: <span style={FORGOT_TEXT_STYLES}>{name}</span>
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography sx={DETAILS_TITLE_STYLES}>
              Type:{" "}
              <span style={FORGOT_TEXT_STYLES}>
                {forgotType === "in" ? "Time In" : "Time Out"}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography sx={DETAILS_TITLE_STYLES}>
              Location: <span style={FORGOT_TEXT_STYLES}>{location}</span>
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography sx={DETAILS_TITLE_STYLES}>
              Date: <span style={FORGOT_TEXT_STYLES}>{endDate}</span>
            </Typography>
          </Grid>
        </Grid>
      )}
      {action === "approve" && isForgotTimeInOut ? (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <InputController
              type="timePicker"
              value={requestTime}
              control={control}
              label="Time"
              name="time"
              errors={errors}
              size="large"
              handleTimePickFunc={hoursChecker}
              timeNum={1}
            />
          </Grid>{" "}
          <Grid item xs={4}>
            <FormControl fullWidth required>
              <InputLabel shrink>Time Status</InputLabel>
              <Select
                label="Time Status"
                value={timeStatus || ""}
                onChange={(e) => timeStatusHandler(e)}
              >
                <MenuItem value={lateLabel.toLowerCase()}>{lateLabel}</MenuItem>
                <MenuItem value={undertimeLabel.toLowerCase()}>
                  {undertimeLabel}
                </MenuItem>
                {forgotType === "in" && (
                  <MenuItem value={presentLabel.toLowerCase()}>
                    {presentLabel}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            {(timeStatus === "undertime" || timeStatus === "late") && (
              <TextField
                fullWidth
                sx={TEXT_FIELD_STYLES}
                label="Minutes"
                type="number"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={penalty}
                onChange={(e) => setPenalty(e.target.value)}
              />
            )}
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      {/* WFH DETAILS */}
      {isTypeWfh && (
        <Stack spacing={1}>
          <Stack direction="row" spacing={1}>
            <Typography sx={DETAILS_TITLE_STYLES}>Name:</Typography>
            <Typography sx={DETAILS_CONTENT_STYLES}>{name}</Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography sx={DETAILS_TITLE_STYLES}>Start:</Typography>
            <Typography sx={DETAILS_CONTENT_STYLES}>{startDate}</Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography sx={DETAILS_TITLE_STYLES}>End:</Typography>
            <Typography sx={DETAILS_CONTENT_STYLES}>{endDate}</Typography>
          </Stack>
        </Stack>
      )}
      {/* COMMENT BOX */}
      <TextField
        inputRef={textFieldRef}
        placeholder="Comment..."
        variant="outlined"
        multiline
        rows={6}
        sx={TEXT_FIELD_STYLES}
      />

      {/* ACTION BUTTONS */}
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <StyledButton
          variant="outlined"
          color="primary"
          size="large"
          onClick={closeModalHandler}
          sx={ACTION_BUTTON_STYLES}
        >
          Cancel
        </StyledButton>

        <Box sx={{ position: "relative" }}>
          <StyledButton
            variant="contained"
            color="primary"
            size="large"
            sx={ACTION_BUTTON_STYLES}
            disabled={
              isLoading || isForgotTimeInOut
                ? timeStatus === null && action === "approve"
                  ? true
                  : false
                : false
            }
            onClick={
              isForgotTimeInOut ? submitForgotTimeInOut : submitActionHandler
            }
          >
            {action}
          </StyledButton>

          {isLoading && (
            <CircularProgress size={24} sx={CIRCULAR_PROGRESS_STYLES} />
          )}
        </Box>
      </Stack>
    </BaseModal>
  );
};

export default ApplicationActionModal;
