import { useQuery } from "react-query";
import { genPostRequest, API_ENDPOINT } from "../../../helper/General";

export const usePostAuditLogs = (data) => {
  const range = data.to + data.from;
  return useQuery(
    ["post-audit-log", range, data.page, data.per_page, data.search],
    () => genPostRequest(data, null, API_ENDPOINT.POST_AUDIT_LOG),
    {
      keepPreviousData: true,
      staleTime: 500 * 1000,
    }
  );
};
