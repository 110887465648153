import React, { useState, useRef, useEffect } from "react";
import { useGetReportPayroll } from "../../../../hooks/react-query/Reports/Payroll";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableContainer,
  Tooltip,
  Typography,
} from "@mui/material";
import DatePickerComponent from "../../../../component/DatePicker";
import HeaderUI from "../../../../component/AdminTables/HeaderUI";
import {
  Params,
  TableHeaderContents,
  ranges,
  dateFormat,
} from "../../../../helper/Reports/Payroll";
import BodyUI from "../../../../component/AdminTables/BodyUI";
import DropDownSelect from "../../../../component/CustomElement/DropDownSelect";
import RefreshIcon from "@mui/icons-material/Refresh";
import Search from "../../../../component/AdminTables/Search";
import useDebounce from "../../../../hooks/customHooks/debounce";
import ServerPagination from "../../../../component/Pagination/ServerPagination";
import SkeletonLoader from "../../../../component/AdminTables/SkeletonLoader";
import { ExportPayRoll } from "../../../../api/reports/payroll";
import ExportModal from "../../../../component/AdminTables/ExportModal";
import GetAppIcon from "@mui/icons-material/GetApp";

const Payroll = () => {
  const [select, setSelect] = useState({ value: ranges[0].id });
  const [params, setParams] = useState(Params(select.value));
  const textInput = useRef(null);
  const debounceValue = useDebounce(params.search, 800);
  const [openCsvModal, setOpenCsvModal] = useState(false);

  const { data, isFetching, refetch } = useGetReportPayroll(
    dateFormat(params.start_date),
    dateFormat(params.end_date),
    dateFormat(params.ot_start),
    dateFormat(params.ot_end),
    params.page_size,
    params.page,
    debounceValue
  );

  const handleStartDate = (date) => {
    setParams({ ...params, start_date: date });
  };
  const handleEndDate = (date) => {
    setParams({ ...params, end_date: date });
  };
  const handleOTStart = (date) => {
    setParams({ ...params, ot_start: date });
  };
  const handleOTEnd = (date) => {
    setParams({ ...params, ot_end: date });
  };
  const ClearSearch = () => {
    textInput.current.value = "";
    setParams({ ...params, search: "", page: 1 });
  };
  const handleSearch = (e) => {
    setParams({
      ...params,
      search: e.target.value,
      page: 1,
    });
  };
  const nextPage = () => {
    setParams({ ...params, page: params.page + 1 });
  };

  const backPage = () => {
    setParams({ ...params, page: params.page - 1 });
  };
  const startPage = () => {
    setParams({ ...params, page: 1 });
  };
  const lastPage = () => {
    !isFetching && setParams({ ...params, page: data.data.last_page });
  };

  const handleClose = () => {
    setOpenCsvModal(false);
  };

  const handleChange = (e) => {
    setSelect({ ...select, value: e.target.value });
  };

  useEffect(() => {
    setParams(Params(select.value));
  }, [select.value]);
  return (
    <Grid container sx={{ padding: "15px" }}>
      <Grid
        container
        mb={3}
        sx={{ justifyContent: "space-between", cursor: "context-menu" }}
      >
        <Tooltip
          title={"View employee payroll reports"}
          placement={"right"}
          arrow
        >
          <Typography variant="h4">Payroll reports</Typography>
        </Tooltip>
        <Button
          startIcon={<RefreshIcon />}
          variant="text"
          sx={{ padding: "5px", textTransform: "none", color: "#00C292" }}
          onClick={refetch}
        >
          <Typography sx={{ fontSize: "0.78rem" }}>Refresh</Typography>
        </Button>
      </Grid>
      <Paper style={{ width: "100%", padding: "10px" }}>
        <Grid
          container
          sx={{
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <DropDownSelect
            objectName={{ val: "id", name: "name" }}
            DropDownData={ranges}
            minWidth={120}
            onChange={handleChange}
            label={"Range"}
            value={select.value}
            variant={"outlined"}
            size={"small"}
          />
          <Grid item>
            <DatePickerComponent
              handleDatePicker={handleStartDate}
              label={"Start"}
              dateValue={params.start_date}
              readOnly={select.value !== 7}
            />

            <DatePickerComponent
              handleDatePicker={handleEndDate}
              label={"End"}
              dateValue={params.end_date}
              readOnly={select.value !== 7}
            />
            <DatePickerComponent
              handleDatePicker={handleOTStart}
              label={"Overtime Start date"}
              dateValue={params.ot_start}
              readOnly={select.value !== 7}
            />
            <DatePickerComponent
              handleDatePicker={handleOTEnd}
              label={"Overtime End date"}
              dateValue={params.ot_end}
              readOnly={select.value !== 7}
            />
          </Grid>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              onClick={() => {
                setOpenCsvModal(true);
              }}
              startIcon={<GetAppIcon />}
              variant="contained"
              color="success"
              size="small"
            >
              EXPORT
            </Button>
          </div>
        </Grid>
      </Paper>
      <Paper style={{ width: "100%", padding: "20px", marginTop: "20px" }}>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Search
            textInput={textInput}
            ClearSearch={ClearSearch}
            setSearch={handleSearch}
            variant={"standard"}
          />
        </div>
        <TableContainer
          sx={{
            maxHeight: 430,
            padding: "0px 0px 20px 0px",
            overflowX: "auto",
          }}
        >
          <Table stickyHeader style={{ overflowX: "auto" }}>
            <HeaderUI headers={TableHeaderContents()} />
            {!isFetching ? (
              <BodyUI rows={data.data.data} cols={TableHeaderContents()} />
            ) : (
              <SkeletonLoader colSpan={TableHeaderContents().length} />
            )}
          </Table>
        </TableContainer>
        {!isFetching && (
          <ServerPagination
            field={"page_size"}
            from={data.data.from}
            to={data.data.to}
            total={data.data.total}
            pageData={{
              to: data.data.to,
              from: data.data.from,
              total: data.data.total,
              perPage: params.page_size,
            }}
            setPerPage={setParams}
            startPage={startPage}
            lastPage={lastPage}
            nextPage={nextPage}
            backPage={backPage}
          />
        )}
        {!isFetching && (
          <ExportModal
            openModal={openCsvModal}
            handleClose={handleClose}
            data={{
              fromDate: params.start_date,
              toDate: params.end_date,
              payrollFromDate: params.ot_start,
              payrollToDate: params.ot_end,
              total: data.data.total,
              page: data.data.page,
              debounceValue: debounceValue,
            }}
            EndPoint={ExportPayRoll}
          />
        )}
      </Paper>
    </Grid>
  );
};

export default Payroll;
