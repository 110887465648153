import React from "react";
import CustomSearch from "../../component/SearchComponent";
import { rangeSelection } from "../../helper/LeaveAndAbsences";
import DatePickerComponent from "../../component/DatePicker";
import { getRangeDates } from "../../helper/Reports";
import { toast } from "react-toastify";
import { isAfter, isBefore } from "date-fns";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import moment from "moment";

const InputElements = ({
  refreshFunction,
  setRange,
  searched,
  setSearched,
  range,
  setQuery,
}) => {
  const handleChangeRange = (e) => {
    const date = getRangeDates(e.target.value);
    setRange({ startDate: date.fromDate, endDate: date.toDate });
  };

  const handleStartDatePicker = (data) => {
    setRange((prevState) => ({ ...prevState, startDate: data }));
    if (isAfter(data, new Date(range.endDate))) {
      toast.error("Start date should not be after end date.");
      setRange({
        startDate: moment().startOf("week"),
        endDate: moment().endOf("week"),
      });
    }
  };

  const handleEndDatePicker = (data) => {
    setRange((prevState) => ({ ...prevState, endDate: data }));
    if (isBefore(data, new Date(range.startDate))) {
      toast.error("End date should not be after end date.");
      setRange({
        startDate: moment().startOf("week"),
        endDate: moment().endOf("week"),
      });
    }
  };

  return (
    <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
      <Stack direction="row" sx={{ flexGrow: 1 }}>
        <FormControl style={{ paddingTop: "5px", display: "inline-flex" }}>
          <InputLabel sx={{ paddingTop: "8px" }} id="select-small">
            Range
          </InputLabel>
          <Select
            sx={{ height: "37.2px" }}
            labelId="demo-select-small"
            id="select"
            defaultValue="This Week"
            label="Range"
            onChange={handleChangeRange}
          >
            {rangeSelection.map((item, index) => (
              <MenuItem value={item} index={index}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <DatePickerComponent
          handleDatePicker={handleStartDatePicker}
          dateValue={range.startDate}
          label="Start"
        />
        <DatePickerComponent
          handleDatePicker={handleEndDatePicker}
          dateValue={range.endDate}
          label="End"
        />
      </Stack>
      <Stack direction="row" sx={{ alignItems: "flex-start" }}>
        <CustomSearch
          placeholder="Search user"
          setSearched={setSearched}
          searched={searched}
          setQuery={setQuery}
        />
        <IconButton
          aria-label="refresh"
          onClick={() => {
            refreshFunction();
          }}
        >
          <RefreshIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default InputElements;
