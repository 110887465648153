import React from "react";
import Modal from "@mui/material/Modal";
import Grow from "@mui/material/Grow";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import WfhAgreement from "./wfhAgreement";
import WfhForm from "./wfhForm";
import WfhSubmit from "./wfhSubmit";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import hooks from "../../hooks/react-query/queryHooks";
import Chip from "@mui/material/Chip";
import { useQueryClient } from "react-query";
import { GlobalContext } from "../../App";

const steps = ["Telecommute Agreement", "Application Form", "Submitting"];

const WfhModal = ({ wfhOpen, handleWFH }) => {
  const queryClient = useQueryClient();
  const { settings } = React.useContext(GlobalContext);
  const [activeStep, setActiveStep] = React.useState(0);
  const [proceed, setProceed] = React.useState(true);
  const [checkBox, setCheckbox] = React.useState(false);
  const [completed, setCompleted] = React.useState({});
  const imgUrl = React.useRef([]);
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [payload, setPayload] = React.useState({});
  const [status, setStatus] = React.useState(null);
  const [msg, setMsg] = React.useState(null);
  const [wfhLink, setWfhLink] = React.useState("");
  React.useEffect(() => {
    settings.map((st) => {
      if (st.setting === "wfh_guidelines_url") {
        setWfhLink(st.value);
      }
    });
  }, [settings]);

  const handleProceed = () => {
    proceed ? setProceed(false) : setProceed(true);
    checkBox ? setCheckbox(false) : setCheckbox(true);
  };
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = () => {
    setCompleted({ 0: true, 1: true, 2: true });
  };

  React.useEffect(() => {
    imgUrl.current = [];
  }, []);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      startDate: new Date(),
      endDate: new Date(),
    },
  });
  const { mutate: addWfhMutation } = hooks.useAddSubmitWfh();
  const onSubmit = async (data) => {
    handleNext();
    const dataParam = {
      api: true,
      hasUploadedImage: true,
      from: format(data.startDate, "MMM d, yyyy"),
      to: format(data.endDate, "MMM d, yyyy"),
      purpose: data.purpose,
      workplace: data.workplace,
      images: imgUrl.current,
    };
    setPayload(dataParam);
  };

  const submitWfh = () => {
    addWfhMutation(payload, {
      onSuccess: (request) => {
        setStatus(request.status);
        setMsg(request.msg);
        handleComplete();
      },
    });
  };
  return (
    <Modal open={wfhOpen}>
      <Grow
        in={wfhOpen}
        style={{ transformOrigin: "0 0 0" }}
        {...(wfhOpen ? { timeout: 500 } : {})}
        unmountOnExit
      >
        <Card
          className="login-modal leave1-modal"
          sx={{
            minWidth: "350px",
            overflowY: "scroll",
            maxHeight: "calc(100vh - 40px)",
          }}
        >
          <CardHeader
            title={
              <Typography variant="h3" className="h3-bar">
                Work From Home Application
              </Typography>
            }
            action={
              <IconButton onClick={handleWFH}>
                <CloseOutlinedIcon sx={{ width: "15px", height: "15px" }} />
              </IconButton>
            }
          />
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => (
                <Step key={label} completed={completed[index]}>
                  <StepButton color="inherit">{label}</StepButton>
                </Step>
              ))}
            </Stepper>
            <Box>
              {allStepsCompleted() ? (
                <Box sx={{ textAlign: "center", margin: "0px 20px 10px 20px" }}>
                  {status && (
                    <Chip
                      label={
                        <Typography variant="overline">{status}</Typography>
                      }
                      color={status === "duplicate" ? "error" : "success"}
                    />
                  )}
                  {msg && <Typography sx={{ m: 1 }}>{msg}</Typography>}
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    {status && (
                      <Button
                        onClick={() => {
                          handleWFH();
                          queryClient.refetchQueries([
                            "get-employee-request-data-with-page",
                          ]);
                        }}
                      >
                        Close
                      </Button>
                    )}
                  </Box>
                </Box>
              ) : (
                <React.Fragment>
                  <CardContent
                    sx={{
                      m: "15px 35px",
                    }}
                  >
                    {activeStep === 0 && (
                      <>
                        <WfhAgreement
                          handleProceed={handleProceed}
                          checkBox={checkBox}
                          wfhLink={"https://" + wfhLink}
                        />
                        <Button
                          variant="contained"
                          disabled={proceed}
                          onClick={handleNext}
                          sx={{ mr: 1 }}
                        >
                          Proceed
                        </Button>
                      </>
                    )}
                    {activeStep === 1 && (
                      <>
                        <WfhForm
                          imgUrl={imgUrl}
                          open={open}
                          open2={open2}
                          open3={open3}
                          open4={open4}
                          setOpen={setOpen}
                          setOpen2={setOpen2}
                          setOpen3={setOpen3}
                          setOpen4={setOpen4}
                          handleBack={handleBack}
                          handleNext={handleNext}
                          handleSubmit={handleSubmit}
                          control={control}
                          errors={errors}
                          onSubmit={onSubmit}
                          setValue={setValue}
                          getValues={getValues}
                        />
                      </>
                    )}
                    {activeStep === 2 && (
                      <WfhSubmit
                        handleBack={handleBack}
                        submitWfh={submitWfh}
                      />
                    )}
                  </CardContent>
                </React.Fragment>
              )}
            </Box>
          </Box>
        </Card>
      </Grow>
    </Modal>
  );
};

export default WfhModal;
