import React from "react";
import { CSVLink } from "react-csv";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import jsPDF from "jspdf";
import "jspdf-autotable";

const ReactionCsvArray = ({ buttonStyl, tableData }) => {
  const headers = [
    { label: "Employee", key: "employee" },
    { label: "Action", key: "action" },
    { label: "Target Employee", key: "target" },
    { label: "Date", key: "date" },
    { label: "Remarks", key: "remark" },
  ];
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Reaction Reports";
    const headers = [
      ["Employee", "Action", "Target Employee", "Date", "Remarks"],
    ];

    const data = tableData.map((td) => [
      td.employee,
      td.action,
      td.target,
      td.date,
      td.remark,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
      theme: "grid",
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("react-report.pdf");
  };
  return (
    <>
      {tableData && (
        <Box sx={{ display: "flex" }}>
          <Box>
            <CSVLink
              data={tableData}
              headers={headers}
              filename={"reaction-reports.csv"}
              target="_blank"
              style={{
                textDecoration: "none",
              }}
            >
              <Button variant="contained" size="small" sx={buttonStyl}>
                Export CSV
              </Button>
            </CSVLink>
          </Box>
          <Box>
            <Button
              variant="contained"
              size="small"
              sx={buttonStyl}
              onClick={exportPDF}
            >
              Export PDF
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ReactionCsvArray;
