import * as React from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const LinearProgressWithLabel = (props) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", position: "relative" }}>
      <Box sx={{ width: "100%" }}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{
            backgroundColor: props.bgl,
            height: "18px",
            "& .MuiLinearProgress-bar": {
              borderRadius: "0px 10px 10px 0px",
              backgroundColor: props.bg,
            },
          }}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{
            lineHeight: 2,
            fontSize: "0.65rem",
            ml: "10px",
          }}
        >
          {props.text}
        </Typography>
      </Box>
    </Box>
  );
};

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default LinearProgressWithLabel;
