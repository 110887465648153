import { useQuery, useMutation, useQueryClient } from "react-query";
import apiService from "../../../api/axios";
import { toast } from "react-toastify";
import {
  getManagers,
  getUserAppSettingsData,
} from "../../../api/userAppSettings";

const useUserAppSettingsData = (params) => {
  return useQuery(["get-user-application-settings", params.criteria], () =>
    getUserAppSettingsData(params.payload)
  );
};

const useManagers = () => {
  return useQuery(["get-managers"], () => getManagers());
};

const useUpdateSettings = () => {
  const queryClient = new useQueryClient();
  return useMutation(
    "get-user-application-settings",
    async (data) => {
      try {
        let response = await apiService.post(`v5/leave/update-setting`, {
          Accept: `application/json`,
          "Content-Type": `multipart/form-data`,
          api: true,
          ...data,
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(["get-user-application-settings"]);
        toast.success("User application setting successfully updated.", {});
      },
    },
    {
      onError: () => {
        queryClient.refetchQueries(["get-user-application-settings"]);
      },
    }
  );
};

const hooks = {
  useUserAppSettingsData,
  useManagers,
  useUpdateSettings,
};

export default hooks;
