import { useCallback, useEffect, useMemo, useState } from "react";

import LeaveConversionApplicationsContext from "./LeaveConversionApplicationsContext";
import {
  ORDER,
  ROWS_PER_PAGE_DEFAULT,
  HEADER_CELL_ID,
} from "../../../lib/leaveManagement/leaveConversionApplications";
import {
  useLeaveConversionApplications,
  usePrefetchLeaveConversionApplications,
} from "../../../hooks/react-query/LeaveManagement/LeaveConversionApplications";

const LeaveConversionApplicationsContextProvider = ({ children }) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(ROWS_PER_PAGE_DEFAULT);
  const [searchString, setSearchString] = useState("");
  const [order, setOrder] = useState(ORDER.descending);
  const [orderBy, setOrderBy] = useState(HEADER_CELL_ID.date_request);

  const query = useLeaveConversionApplications(
    page,
    pageSize,
    searchString,
    order,
    orderBy
  );

  const hasNextPage = query.data?.next_page_url;
  usePrefetchLeaveConversionApplications(
    hasNextPage,
    page,
    pageSize,
    searchString
  );

  useEffect(() => {
    setPage(1);
  }, [searchString, pageSize]);

  const changePage = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  const changePageSize = useCallback((newPageSize) => {
    setPageSize(newPageSize);
  }, []);

  const changeSearchFilter = useCallback((filterString) => {
    setSearchString(filterString);
  }, []);

  const changeOrder = useCallback((newOrder) => {
    setOrder(newOrder);
  }, []);

  const changeOrderBy = useCallback((newOrderBy) => {
    setOrderBy(newOrderBy);
  }, []);

  const state = { page, pageSize, searchString, order, orderBy };
  const handler = useMemo(
    () => ({
      changePage,
      changePageSize,
      changeSearchFilter,
      changeOrder,
      changeOrderBy,
    }),
    [changePage, changePageSize, changeSearchFilter, changeOrder, changeOrderBy]
  );

  return (
    <LeaveConversionApplicationsContext.Provider
      value={{ query, state, handler }}
    >
      {children}
    </LeaveConversionApplicationsContext.Provider>
  );
};

export default LeaveConversionApplicationsContextProvider;
