import React, { createContext } from "react";
import { useGetNotification } from "../../hooks/react-query/Notification";

export const NotificationContext = createContext({});

export const NotificationProvider = (props) => {
  const { data, isLoading } = useGetNotification();
  return (
    <NotificationContext.Provider
      value={{
        data,
        isLoading,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};
