import apiService from "../axios";

import { modifyData } from "../../helper/SettingsTimesheet/PendingTimesheetEntries";

export const getPendingTimesheetEntries = async (
  page,
  pageSize,
  search = ""
) => {
  const endpoint =
    `v5/timesheet/pending-entry?page=${page}&per_page=${pageSize}` +
    (search ? `&search=${search}` : "");

  try {
    const { data: responseData } = await apiService.get(endpoint);

    responseData.data = modifyData(responseData.data);

    return responseData;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const submitPendingTimesheetEntryAction = async ({ id, action }) => {
  const endpoint = `v5/timesheet/pending-entry/${action}`;
  const payload = { id };

  try {
    await apiService.post(endpoint, payload);
  } catch (error) {
    console.log(error);
    throw error;
  }
};
