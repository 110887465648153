import { useQuery } from "react-query";

import { getEmployeeSchedule } from "../../../api/employeeSchedule";
import { getStartAndEndDate } from "../../../helper/EmployeeSchedule";

export const useEmployeeSchedule = (payload) => {
  const { id: emp_id, currentDate } = payload;
  const payloadData = {
    emp_id,
    currentDate,
    ...getStartAndEndDate(currentDate),
  };

  return useQuery(
    ["get-employee-schedule"],
    getEmployeeSchedule.bind(null, payloadData)
  );
};
