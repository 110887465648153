import React, { useRef, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Box,
  Typography,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  FormGroup,
  Button,
} from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { IsMobile } from "../../helper/isMobile";
import "../../styles/Auth/login.css";
import { resetPassword } from "../../api/auth";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import LockIcon from "@mui/icons-material/Lock";
import { useResetPassToken } from "../../hooks/react-query/ResetPassToken";
import Fetching from "../../component/SvgImage/Fetching";
import Pagenotfound from "../Error/pagenotfound";
import PageNotFound from "../../component/SvgImage/PageNotFound";

const ResetPassword = () => {
  const [show_pass, setShowPass] = React.useState(false);
  const [confirmPassword, setConfirmPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const navigate = useNavigate();
  const form = useRef(null);
  const [errors, setErrors] = useState({});
  const windowUrl = window.location.href;
  const token = windowUrl.substring(windowUrl.indexOf("reset/") + 6);
  const { data: validToken, isError, isFetching } = useResetPassToken(token);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newPassword === confirmPassword) {
      let data = {
        password: newPassword,
        password_confirmation: confirmPassword,
        token: token,
      };
      password.mutate(data);
    } else {
      setErrors({ password_confirmation: "Password Do Not Match." });
    }
  };

  const password = useMutation((formData) => resetPassword(formData), {
    onSuccess: async (response) => {
      toast.success("Password changed successfully");
      navigate("/login");
    },
    onError: (response) => {
      setErrors(response.response.data.errors || {});
      if (
        !response.response.data.errors.password &&
        !response.response.data.errors.password_confirmation
      ) {
        toast.error("Something went wrong");
      }
    },
  });

  const handleShowPassword = () => {
    setShowPass((prevState) => !prevState);
  };

  return (
    <Card
      className="Login-card"
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CardContent
        className="cc-cont"
        sx={{
          height: "470px",
          width: isFetching || isError ? "700px" : "auto",
          marginTop: "100px",
          marginBottom: "100px",
          padding: "20px",
          backgroundColor: "#fff",
          borderRadius: "20px",
        }}
      >
        <Grid
          container
          sx={{
            overflow: "hidden",
            justifyContent: "center",
            alignItems: "center",
            padding: `${
              IsMobile() ? "0px 3px 0px 3px" : "20px 45px 10px 45px"
            }`,
          }}
        >
          <Grid item xs={12} sm={12} lg={12}>
            <Grid container sx={{ display: "flex", justifyContent: "center" }}>
              {isFetching ? (
                <Fetching />
              ) : isError ? (
                <PageNotFound />
              ) : (
                <Grid item xs={12}>
                  <form ref={form} onSubmit={handleSubmit}>
                    <Box
                      sx={{
                        padding: "25px",
                        border: "1.5px solid",
                        borderColor: "rgb(119, 126, 137)",
                        borderRadius: "10px",
                        boxShadow:
                          "rgb(90 114 123 / 11%) 0px 7px 30px 0px !important",
                      }}
                    >
                      <Typography variant="h3" className="log-h2">
                        Reset Password
                      </Typography>
                      <Box
                        sx={{
                          marginTop: "0px",
                        }}
                      >
                        <FormControl
                          fullWidth
                          sx={{
                            margin: "15px 0px 5px",
                          }}
                        >
                          <TextField
                            variant="outlined"
                            name="New password"
                            id="new_password"
                            fullWidth
                            error={!!errors.password}
                            helperText={errors.password}
                            type={show_pass ? "text" : "password"}
                            placeholder="New Password"
                            value={newPassword ? newPassword : ""}
                            onChange={(e) => {
                              setNewPassword(e.target.value);
                            }}
                            InputProps={{
                              startAdornment: <LockIcon sx={{ mr: 1 }} />,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                      handleShowPassword();
                                    }}
                                    onMouseDown={() => {
                                      handleShowPassword();
                                    }}
                                    edge="end"
                                  >
                                    {show_pass ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                        <FormControl
                          fullWidth
                          sx={{
                            margin: "15px 0px 5px",
                          }}
                        >
                          <TextField
                            variant="outlined"
                            name="Confirm password"
                            id="confirm_password"
                            fullWidth
                            type={"password"}
                            placeholder="Confirm Password"
                            value={confirmPassword ? confirmPassword : ""}
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                            }}
                            error={!!errors.password_confirmation}
                            helperText={errors.password_confirmation}
                          />
                        </FormControl>

                        <Box className="log-box">
                          <FormGroup></FormGroup>
                        </Box>

                        <Button
                          variant="contained"
                          sx={{
                            mt: 2,
                            textTransform: "none",
                            backgroundColor: "#22A9D8",
                            color: "#fff",
                            height: "56px",
                          }}
                          fullWidth
                          disableElevation
                          size="large"
                          className="log-sign"
                          type="submit"
                        >
                          Reset Password
                        </Button>

                        <Button
                          variant="contained"
                          onClick={(e) => navigate("/login")}
                          sx={{
                            textTransform: "none",
                            backgroundColor: "white",
                            color: "#22A9D8",
                            height: "56px",
                            mt: 1,
                            border: 1,
                            borderColor: "#22A9D8",
                          }}
                          fullWidth
                          disableElevation
                          size="large"
                          className="log-sign"
                          type="submit"
                        >
                          Cancel
                        </Button>
                      </Box>
                    </Box>
                  </form>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ResetPassword;
