export const Ranges = [
  {
    value: "This Week",
    name: "This Week",
  },
  {
    value: "Last Week",
    name: "Last Week",
  },
  {
    value: "Last 2 Weeks",
    name: "Last 2 Weeks",
  },
  {
    value: "This month",
    name: "This month",
  },
  {
    value: "Last month",
    name: "Last month",
  },
  {
    value: "Last 2 months",
    name: "Last 2 months",
  },
  {
    value: "Custom",
    name: "Custom",
  },
];

export const TableHeader = [
  { label: "Name", data: "name", type: "text" },
  { label: "Description", data: "description", type: "text" },
  { label: "Expire Date", data: "expire_date", type: "date" },
  { label: "Created", data: "created_at", type: "date" },
  { label: "Status", data: "isActive", type: "select" },
];
