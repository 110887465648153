import apiService from "../axios";
import { toast } from "react-toastify";

export const getComplianceRequest = async (search) => {
  try {
    const request = await apiService.get("/v5/settings/query-compliance", {
      search: search,
    });
    return request.data;
  } catch (error) {
    throw error;
  }
};

export const delCompliance = async (id) => {
  try {
    const request = await apiService.delete("/v5/settings/delete-compliance", {
      data: { id },
    });
    toast.error("Compliance deleted!");
    return request?.data;
  } catch (error) {
    toast.error("Something wrong!");
    throw error;
  }
};

export const addCompliance = async (item) => {
  try {
    const request = await apiService.post("/v5/settings/new-compliance", {
      item: item,
    });
    if (request?.data?.status === "success") {
      toast.success("Compliance added successfully!");
    }
    return request.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    }
    if (error?.response?.data?.item[0]) {
      toast.error(error?.response?.data?.item[0]);
    }
    throw error;
  }
};

export const editCompliance = async (id, item, status) => {
  try {
    const request = await apiService.put("/v5/settings/update-compliance", {
      id: id,
      item: item,
      status: status,
    });
    if (request?.data?.status === "success") {
      toast.success("Compliance update successfully!");
    }
    return request.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    }
    if (error?.response?.data?.item[0]) {
      toast.error(error?.response?.data?.item[0]);
    }
    throw error;
  }
};
