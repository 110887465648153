import React, { useEffect, useState, useContext, useCallback } from "react";
import {
  Collapse,
  IconButton,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { PerUserV5Context } from ".";
import { getReportPerUserTimesheet } from "../../../api/peruser";
import { PerUserTimesheetTableHeaderV5 } from "../../../lib/reports/perUserV5";
import Loader from "../../../component/Loader";
import moment from "moment";
import UserPerTimesheetLists from "./UserPerTimesheetLists";
import { sortArrayTable } from "../../../helper/Reports";
import EmptyData from "../../../component/Reports/EmptyData";
import TableHeader from "../../../component/Reports/TableHeader";
import ReportTableContainter from "../../../component/Reports/TableContainter";

const UserPerActivityList = ({ item, userId, projectId, codeId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [limit, setLimit] = useState(30);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [userPerTimesheetListData, setUserPerTimesheetListData] = useState([]);
  const { startDate, endDate, isInactiveUsers, isWeekends } =
    useContext(PerUserV5Context);
  const [orderDirection, setOrderDirection] = useState("desc");
  const [tableData, setTableData] = useState([]);

  const handleChangeRowsPerPage = (e) => {
    setPage(1);
    setLimit(e.target.value);
  };

  const handleChangePage = (page) => {
    setPage(page + 1);
  };

  const getUserPerTimesheet = useCallback(async () => {
    if (isOpen) {
      setIsLoading(true);
      const response = await getReportPerUserTimesheet({
        fromDate: moment(startDate).format("YYYY-MM-DD"),
        toDate: moment(endDate).format("YYYY-MM-DD"),
        page_size: limit,
        id: userId,
        id2: projectId,
        id3: codeId,
        id4: item.row_id,
        page: page,
        inActives: isInactiveUsers,
        weekends: isWeekends,
      });

      setUserPerTimesheetListData(response);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page, item, isOpen, userId, projectId, codeId]);

  useEffect(() => {
    getUserPerTimesheet();
  }, [getUserPerTimesheet]);

  useEffect(() => {
    if (userPerTimesheetListData) {
      setTableData(userPerTimesheetListData?.data || []);
    }
  }, [userPerTimesheetListData]);

  const openCollapsable = async () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      await getUserPerTimesheet();
      setIsOpen(true);
    }
  };

  const handleSortRequest = (key) => {
    let direction = "asc";
    direction = orderDirection === "desc" ? "asc" : "desc";
    setOrderDirection(direction);
    setTableData((prev) => sortArrayTable(prev, direction, key));
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <TableRow>
        <TableCell>
          <IconButton
            onClick={async () => {
              await openCollapsable();
            }}
          >
            {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.name,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.description,
            }}
          />
        </TableCell>
        <TableCell align={"right"}>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.total_hours,
            }}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} sx={{ background: "#dbe9f4" }}>
          <Collapse in={isOpen} unmountOnExit>
            {!userPerTimesheetListData?.data?.length ? (
              <EmptyData />
            ) : (
              <ReportTableContainter>
                <TableHeader
                  isCollapsable={false}
                  headerList={PerUserTimesheetTableHeaderV5}
                  handleSortRequest={handleSortRequest}
                  orderDirection={orderDirection}
                />
                <TableBody>
                  {tableData?.map((codeItem) => {
                    return (
                      <UserPerTimesheetLists
                        key={codeItem.row_id}
                        item={codeItem}
                      />
                    );
                  })}
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[1, 5, 10, 30]}
                      count={userPerTimesheetListData?.total || 0}
                      page={
                        userPerTimesheetListData?.current_page
                          ? userPerTimesheetListData?.current_page - 1
                          : 0
                      }
                      onPageChange={(e, page) => {
                        handleChangePage(page);
                      }}
                      showFirstButton={true}
                      showLastButton={true}
                      rowsPerPage={limit}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableBody>
              </ReportTableContainter>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default UserPerActivityList;
