import * as React from "react";
import Alert from "@mui/material/Alert";

export const DashboardAlert = ({ severity, message, reset }) => {
  // SEVERITY TYPES
  // *error
  // *success
  // *info
  // *warning
  return (
    <Alert
      variant="filled"
      severity={severity}
      onClose={() => {
        reset();
      }}
      sx={{ ml: "14px", mt: "5px" }}
    >
      {message}
    </Alert>
  );
};
