import { Box, Tooltip, Typography } from "@mui/material";
import { useState, useCallback, useEffect, useRef } from "react";
import hooks from "../../../hooks/react-query/SettingsTimesheet/Compliance";
import Table from "../../../component/SettingsTimesheet/Compliance/Table";
import Row from "./Row";
import {
  TableHeaderActivity,
  Range,
} from "../../../lib/settingsTimesheet/compliance/TableSettings";
import ComplianceRow from "./ComplianceRow";
import { getRangeDates } from "../../../helper/Reports";
import TableOptions from "./TableOptions";
import moment from "moment";
import { IconButton } from "@mui/material";
import ExportModal from "./ExportModal";
import {
  downloadCsv,
  downloadPdf,
} from "../../../helper/TimesheetSettings/Compliance";
import { createContext } from "react";
import { useMemo } from "react";
import DownloadIcon from "@mui/icons-material/Download";

export const ComplianceContext = createContext({});

const Compliance = () => {
  const [search, setSearch] = useState("");
  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState(); // eslint-disable-line
  const [filter, setFilter] = useState(); // eslint-disable-line
  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(1);
  const [fetch, setFetch] = useState();
  const [total, setTotal] = useState();
  const [isExportModalOpen, openExportModal] = useState(false);
  const ref = useRef(null);

  const [compliance, setCompliance] = useState();
  const [range, setRange] = useState();
  const [startDate, setStartDate] = useState(moment().startOf("month"));
  const [endDate, setEndDate] = useState(moment().endOf("month"));

  const { data, isFetching } = hooks.useCompliance(
    getFetchUrl(),
    keyword,
    status,
    filter,
    limit,
    offset,
    fetch
  );

  const initCompliance = useCallback(() => {
    if (data) {
      setCompliance(data.data);
      setTotal(data.total);
    }
  }, [data]);

  useEffect(() => {
    initCompliance();
  }, [initCompliance]);

  function getFetchUrl() {
    return (
      "?search=" +
      keyword +
      "&status=" +
      status +
      "&filter=" +
      filter +
      "&fromDate=" +
      moment(startDate).format("YYYY-MM-DD") +
      "&toDate=" +
      moment(endDate).format("YYYY-MM-DD") +
      "&page_size=" +
      limit +
      "&page=" +
      offset
    );
  }

  const handleRange = (e) => {
    const date = getRangeDates(e);
    setRange(e);
    setStartDate(date.fromDate);
    setEndDate(date.toDate);
  };

  const handleRefresh = () => {
    setSearch("");
    setKeyword("");
    setOffset(1);
    setFetch(!fetch);
  };

  const Buttons = [
    {
      Icon: (
        <IconButton
          key="add"
          sx={{ mt: 2 }}
          aria-label="add"
          onClick={(e) => openExportModal(!isExportModalOpen)}
        >
          <DownloadIcon />
        </IconButton>
      ),
    },
  ];

  const handleExport = (option) => {
    if (option === "pdf") {
      downloadPdf(compliance);
    } else {
      downloadCsv(compliance);
    }
  };

  const value = useMemo(
    () => ({
      search,
      setSearch,
      endDate,
      setEndDate,
      startDate,
      setStartDate,
    }),
    [search, setSearch, endDate, setEndDate, startDate, setStartDate]
  );

  return (
    <Box sx={{ margin: "0px", pr: "25px", pl: "25px" }}>
      <ComplianceContext.Provider value={value}>
        <ExportModal
          isExportModalOpen={isExportModalOpen}
          handleClose={(e) => openExportModal(!isExportModalOpen)}
          handleExport={handleExport}
        />
        <Box
          sx={{
            pt: "5px",
            display: "flex",
            justifyContent: "flex-start",
            cursor: "context-menu",
          }}
        >
          <Tooltip
            title={"View employees timesheet compliance report"}
            placement={"right"}
            arrow
          >
            <Typography variant="h4">Timesheet Compliance Report</Typography>
          </Tooltip>
        </Box>
        <Box>
          <Typography sx={{ mt: 2 }}>
            List of employees unable to complete 40 hours a week
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ mt: 2, fontStyle: "italic" }}>
            {" "}
            Note: Results are based on generated compliance report from last
            week, Saturday 8pm.
          </Typography>
        </Box>

        <Box sx={{ pt: "5px" }}>
          <Table
            reference={ref}
            total={total}
            header={TableHeaderActivity}
            Buttons={Buttons}
            Row={
              <Row
                data={compliance}
                header={TableHeaderActivity}
                isFetching={isFetching}
                Row={ComplianceRow}
              />
            }
            limit={limit}
            setLimit={setLimit}
            offset={offset}
            setOffset={setOffset}
            setKeyword={setKeyword}
            tableOptions={
              <TableOptions
                Range={Range}
                startDate={startDate}
                endDate={endDate}
                range={range}
                handleRange={handleRange}
                handleRefresh={handleRefresh}
              />
            }
          />
        </Box>
      </ComplianceContext.Provider>
    </Box>
  );
};

export default Compliance;
