import { useQuery } from "react-query";
import {
  getDepartment,
  getEmployee,
  getSchedule,
  getUserInformation,
} from "../../api/user";
import { formatUserInformation } from "../../helper/UserInformation";

export const useEmployee = () => {
  return useQuery(["get-employee"], () => getEmployee());
};

export const useDepartment = () => {
  return useQuery(["get-department"], () => getDepartment());
};

export const useDepartmentDropdowns = () => {
  return useQuery(["get-department"], async () => {
    const data = await getDepartment();
    if (!data) return [];

    const departments = data.map(({ id: value, name: label }) => ({
      value,
      label,
    }));

    return departments;
  });
};

export const useSchedule = () => {
  return useQuery(["get-schedule"], () => getSchedule());
};

export const useUserInformation = (payload) => {
  return useQuery(["get-user-information"], async () => {
    try {
      const departments = await getDepartment();
      const fetchedUserInformation = await getUserInformation(payload);

      const userInformation = formatUserInformation(
        fetchedUserInformation,
        departments
      );

      return {
        userInfo: userInformation,
        departments,
        userData: fetchedUserInformation,
      };
    } catch (error) {
      console.log(error);
    }
  });
};
