import { Grid, Skeleton } from "@mui/material";
import React, { useContext } from "react";
import { SystemSkillContext } from "../../../globalstate/SystemSettings/Skill";
import SkillsCard from "./SkillsCard";

const SkillsBody = () => {
  const { listOfskills, fetchingSkills } = useContext(SystemSkillContext);
  return (
    <Grid
      container
      columnGap={5}
      rowGap={3}
      mt={2}
      style={{ justifyContent: "center" }}
    >
      {!fetchingSkills
        ? listOfskills.data.map((skill, key) => (
            <Grid item xs={10} md={2.3} key={key}>
              <SkillsCard skill={skill} />
            </Grid>
          ))
        : [0, 1, 2, 3, 4, 5].map((key) => (
            <Grid item xs={10} md={2.3} key={key}>
              <Skeleton variant="rounded" height={250} />
            </Grid>
          ))}
      {!fetchingSkills && listOfskills.data.length === 0
        ? "No records found"
        : null}
    </Grid>
  );
};

export default SkillsBody;
