import moment from "moment";

export const getRangeDates = (filter) => {
  switch (filter) {
    case "This Week":
      return {
        fromDate: moment().startOf("week"),
        toDate: moment().endOf("week"),
      };
    case "Last Week":
      return {
        fromDate: moment().subtract(1, "weeks").startOf("week"),
        toDate: moment().subtract(1, "weeks").endOf("week"),
      };
    case "Last 2 Weeks":
      return {
        fromDate: moment().subtract(2, "weeks").startOf("week"),
        toDate: moment().subtract(1, "weeks").endOf("week"),
      };
    case "This month":
      return {
        fromDate: moment().startOf("month"),
        toDate: moment().endOf("month"),
      };
    case "Last month":
      return {
        fromDate: moment().subtract(1, "months").startOf("month"),
        toDate: moment().subtract(1, "months").endOf("month"),
      };
    case "Last 2 months":
      return {
        fromDate: moment().subtract(2, "months").startOf("month"),
        toDate: moment().subtract(1, "months").endOf("month"),
      };
    case "Fiscal Year":
      return {
        fromDate: moment().subtract("year", 1).month("July").startOf("month"),
        toDate: moment().month("August").endOf("month"),
      };
    default:
      return {
        fromDate: moment().startOf("week"),
        toDate: moment().endOf("week"),
      };
  }
};

export const sortArrayTable = (arr, orderBy, key, parentKey) => {
  if (!parentKey) {
    switch (orderBy) {
      case "asc":
      default:
        return arr.sort((a, b) =>
          a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0
        );
      case "desc":
        return arr.sort((a, b) =>
          a[key] < b[key] ? 1 : b[key] < a[key] ? -1 : 0
        );
    }
  } else {
    switch (orderBy) {
      case "asc":
      default:
        return arr.sort((a, b) =>
          a[parentKey][key] > b[parentKey][key]
            ? 1
            : b[parentKey][key] > a[parentKey][key]
            ? -1
            : 0
        );
      case "desc":
        return arr.sort((a, b) =>
          a[parentKey][key] < b[parentKey][key]
            ? 1
            : b[parentKey][key] < a[parentKey][key]
            ? -1
            : 0
        );
    }
  }
};

export const searchReports = (data, cells, searchKey) => {
  if (!searchKey) return data;
  if (data.length < 1) return data;
  let newData = data.filter((item) => {
    let matched = false;
    for (let i in cells) {
      if (cells[i].hasOwnProperty("type") && cells[i].type === "number") {
        matched = parseFloat(item[cells[i].id]) === parseFloat(searchKey);
        if (matched) break;
      } else {
        if (
          item[cells[i].key]?.toLowerCase().indexOf(searchKey?.toLowerCase()) >
          -1
        ) {
          matched = true;
          break;
        }
      }
    }
    return matched;
  });
  return newData;
};
