import Skeleton from "@mui/material/Skeleton";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import TableRow from "@mui/material/TableRow";

import StyledTableCell from "../table/StyledTableCell";
import TextSkeleton from "./TextSkeleton";

const TableRowSkeletonLoader = () => {
  return (
    <TableRow>
      {/* Name */}
      <TextSkeleton minWidth={128} maxWidth={164} justifyContent="left" />

      {/* Request */}
      <TextSkeleton minWidth={32} maxWidth={36} />

      {/* Earned */}
      <TextSkeleton minWidth={32} maxWidth={36} />

      {/* Status */}
      <TextSkeleton minWidth={64} maxWidth={72} />

      {/* Date Requested */}
      <TextSkeleton minWidth={128} maxWidth={128} />

      {/* Actions */}
      <StyledTableCell>
        <Stack
          direction="row"
          spacing={1}
          divider={<Divider orientation="vertical" flexItem />}
          justifyContent="center"
        >
          <Skeleton variant="circular" width={40} height={40} />

          <Skeleton variant="circular" width={40} height={40} />
        </Stack>
      </StyledTableCell>
    </TableRow>
  );
};

export default TableRowSkeletonLoader;
