import TablePagination from "@mui/material/TablePagination";

import { ROWS_PER_PAGE_OPTIONS } from "../../../../lib/reports/perActivity";

const TABLE_PAGINATION_STYLES = {
  minHeight: "53px",
  borderBottom: "none",
};

const PerActivityTablePagination = ({
  component,
  count,
  page,
  pageSize,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const handlePageRowChange = (event) => {
    onRowsPerPageChange(+event.target.value);
  };

  const handlerPageChange = (_, page) => {
    onPageChange(page + 1);
  };

  return (
    <TablePagination
      sx={TABLE_PAGINATION_STYLES}
      component={component}
      colSpan={4}
      showFirstButton={true}
      showLastButton={true}
      count={count}
      page={page}
      rowsPerPage={pageSize}
      rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
      onPageChange={handlerPageChange}
      onRowsPerPageChange={handlePageRowChange}
    />
  );
};

export default PerActivityTablePagination;
