import apiService from "../axios";
import { toast } from "react-toastify";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  AddDepartment: async (data) => {
    try {
      const request = await apiService.post("v1/department/add", data, {
        Accept: "application/json",
        body: JSON.stringify(data),
      });
      return request.data;
    } catch (error) {
      toast.error("Something went wrong please try again");
      return error;
    }
  },
  GetDepartments: async () => {
    try {
      const request = await apiService.post(`v1/department/get-all`, {
        Accept: "application/json",
      });
      return request.data;
    } catch (error) {
      return error;
    }
  },

  DeleteDepartment: async (data) => {
    try {
      const request = await apiService.post("v1/department/delete", data, {
        Accept: "application/json",
        body: JSON.stringify(data),
      });
      return request.data;
    } catch (error) {
      return error;
    }
  },

  EditDepartment: async (data) => {
    try {
      const request = await apiService.post("v1/department/edit", data, {
        Accept: "application/json",
        body: JSON.stringify(data),
      });
      return request.data;
    } catch (error) {
      return error;
    }
  },
};
