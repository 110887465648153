import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

const TITLE_STYLE = {
  width: "100%",
  maxWidth: "256px",
  fontSize: "2rem",
  marginBottom: ".5rem",
};

const UserInformationSkeletonLoader = ({ number }) => {
  return Array(number)
    .fill()
    .map((_, index) => (
      <Box
        sx={{
          padding: "28px 34px",
          backgroundColor: "#FFF",
          borderRadius: "4px",
          boxShadow: "0 0 20px rgba(0,0,0,0.09)",
        }}
        key={index}
      >
        <Skeleton variant="text" sx={TITLE_STYLE} />

        {Array(5)
          .fill()
          .map((_, index) => (
            <Grid
              container
              key={index}
              columnSpacing="28px"
              sx={{
                marginBottom: "12px",
              }}
            >
              <Grid item xs={6} md={3}>
                <Skeleton sx={{ maxWidth: "192px", fontSize: "1.2rem" }} />
              </Grid>

              <Grid item xs={6} md={9}>
                <Skeleton sx={{ maxWidth: "512px", fontSize: "1.2rem" }} />
              </Grid>
            </Grid>
          ))}
      </Box>
    ));
};

export default UserInformationSkeletonLoader;
