export const getAuth = () => {
  const user = localStorage.getItem("user");

  if (!user) {
    return null;
  }

  return JSON.parse(user);
};

export const getRoles = () => {
  const user = localStorage.getItem("user");

  if (!user) {
    return [];
  }

  /* the default values are temporary until resolve in the backend */
  const roles = JSON.parse(user).permissions || [
    "view colleagues",
    "time entry",
    "file requests",
  ];

  return roles;
};
