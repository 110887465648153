import React, { useEffect, useState, useContext, useCallback } from "react";
import {
  Collapse,
  IconButton,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { PerTypeV5Context } from ".";
import { getPerTypeReportActivity } from "../../../api/perType";
import { PerProjectUserTimesheetTableHeaderV5 } from "../../../lib/reports/perTypeV5";
import Loader from "../../../component/Loader";
import moment from "moment";
import ActivityList from "./ActivityList";
import { sortArrayTable } from "../../../helper/Reports";
import EmptyData from "../../../component/Reports/EmptyData";
import TableHeader from "../../../component/Reports/TableHeader";
import ReportTableContainter from "../../../component/Reports/TableContainter";

const TypeActivityList = ({ item, userId, typeId, codeId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [limit, setLimit] = useState(30);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [typeActivityListData, setTypeActivityListData] = useState([]);
  const { startDate, endDate, isWeekends, isInactiveUsers } =
    useContext(PerTypeV5Context);
  const [orderDirection, setOrderDirection] = useState("desc");
  const [tableData, setTableData] = useState([]);

  const handleChangeRowsPerPage = (e) => {
    setPage(1);
    setLimit(e.target.value);
  };

  const handleChangePage = (page) => {
    setPage(page + 1);
  };

  const getUserPerActivity = useCallback(async () => {
    if (isOpen) {
      setIsLoading(true);
      const response = await getPerTypeReportActivity({
        fromDate: moment(startDate).format("YYYY-MM-DD"),
        toDate: moment(endDate).format("YYYY-MM-DD"),
        page_size: limit,
        id: codeId,
        id2: typeId,
        deptId: item.row_id,
        page: page,
        inActives: isInactiveUsers,
        weekends: isWeekends,
      });

      setTypeActivityListData(response);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page, item, isOpen, typeId, codeId]);

  const openCollapsable = async () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      await getUserPerActivity();
      setIsOpen(true);
    }
  };

  useEffect(() => {
    getUserPerActivity();
  }, [getUserPerActivity]);

  useEffect(() => {
    if (typeActivityListData) {
      setTableData(typeActivityListData?.data || []);
    }
  }, [typeActivityListData]);

  const handleSortRequest = (key) => {
    let direction = "asc";
    direction = orderDirection === "desc" ? "asc" : "desc";
    setOrderDirection(direction);
    setTableData((prev) => sortArrayTable(prev, direction, key));
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <TableRow>
        <TableCell>
          <IconButton
            onClick={async () => {
              await openCollapsable();
            }}
          >
            {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.name,
            }}
          />
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.description,
            }}
          />
        </TableCell>
        <TableCell align={"right"}>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.efforts,
            }}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} sx={{ background: "#dbe9f4" }}>
          <Collapse in={isOpen} unmountOnExit>
            {!typeActivityListData?.data?.length ? (
              <EmptyData />
            ) : (
              <ReportTableContainter>
                <TableHeader
                  isCollapsable={false}
                  headerList={PerProjectUserTimesheetTableHeaderV5}
                  handleSortRequest={handleSortRequest}
                  orderDirection={orderDirection}
                />
                <TableBody>
                  {tableData?.map((codeItem) => {
                    return (
                      <ActivityList
                        key={codeItem.row_id}
                        item={codeItem}
                        userId={userId}
                        codeId={item.row_id}
                      />
                    );
                  })}
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                      count={typeActivityListData?.total || 0}
                      page={
                        typeActivityListData?.current_page
                          ? typeActivityListData?.current_page - 1
                          : 0
                      }
                      onPageChange={(e, page) => {
                        handleChangePage(page);
                      }}
                      rowsPerPage={limit}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      showFirstButton={true}
                      showLastButton={true}
                    />
                  </TableRow>
                </TableBody>
              </ReportTableContainter>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TypeActivityList;
