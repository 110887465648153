export const manageFeedbackHeader = [
  {
    label: "",
    key: "",
  },
  {
    label: "No",
    key: "id",
    isSortable: true,
  },
  {
    label: "Category",
    key: "category",
    isSortable: true,
  },
  {
    label: "Subject",
    key: "subject",
    isSortable: true,
  },
  {
    label: "Content",
    key: "content",
    isSortable: true,
    align: "center",
  },
  {
    label: "Status",
    key: "status",
    isSortable: true,
  },
  {
    label: "Actions",
    key: "actions",
    align: "center",
  },
];

export const mngdFeedbackCommentsHeader = [
  {
    label: "Name",
    key: "comment.empolyee.name",
    isSortable: true,
  },
  {
    label: "Comment",
    key: "comment.comment",
    isSortable: true,
    align: "center",
  },
  {
    label: "Actions",
    key: "action",
    align: "center",
  },
];
