import React from "react";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { IconButton, Typography, Box } from "@mui/material";
const likable = ({ anchorEl, handleClick, handleClose, open, reactions }) => {
  const { format, parseISO } = require("date-fns");
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Card sx={{ minWidth: "250px", maxHeight: "250px", overflowY: "scroll" }}>
        <CardHeader
          sx={{ pb: 1 }}
          title={
            <Typography sx={{ fontSize: "0.88rem" }}>
              {reactions?.label}
            </Typography>
          }
        />
        <CardContent sx={{ pt: 0 }}>
          <MenuList>
            {reactions?.value?.map((item, index) => (
              <Box key={index}>
                <MenuItem>
                  <ListItemIcon>
                    <IconButton
                      disabled
                      sx={{ color: `${reactions?.color} !important` }}
                    >
                      {reactions?.icon}
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText
                    sx={{ ml: 1, display: "flex", flexDirection: "column" }}
                  >
                    <Typography sx={{ fontSize: "0.78rem" }}>
                      {item.employee.lastname +
                        ", " +
                        item.employee.firstname +
                        " "}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ fontSize: "0.7rem" }}
                    >
                      {format(new Date(item.created_at), "PPPP, hh:mm aaa")}
                    </Typography>
                  </ListItemText>
                </MenuItem>
                <Divider />
              </Box>
            ))}
          </MenuList>
        </CardContent>
      </Card>
    </Popover>
  );
};

export default likable;
