import React from "react";
import { TableRow, TableCell, Typography } from "@mui/material";

const ComplianceRow = ({ columns, row }) => {
  return (
    <TableRow sx={{ backgroundColor: "#FFF" }}>
      {columns &&
        columns.map((column, index) => (
          <TableCell key={index} scope="row">
            <Typography
              sx={{
                py: 2,
                fontSize: 14,
                wordWrap: "break-word",
                whitepace: "normal",
                textTransform: "none",
              }}
            >
              {row[column.data]}
            </Typography>
          </TableCell>
        ))}
    </TableRow>
  );
};

export default ComplianceRow;
