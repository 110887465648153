import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { makeStyles } from "@material-ui/core/styles";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../App";
const useStyles = makeStyles(() => ({
  padding: {
    margin: "30px !important",
  },
}));

const ProfileButton = () => {
  const { userDashboard, setReminder, reminder } = useContext(GlobalContext);
  const navigate = useNavigate();
  const classes = useStyles();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  React.useEffect(() => {
    if (reminder.logOut) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      navigate(`/`);
      handleCloseUserMenu();
      setReminder({ logout: false });
    }
  }, [reminder]);

  const confirmLogout = () =>
    setReminder(
      userDashboard?.time_inout?.timeIn_status &&
        userDashboard?.time_inout?.timeOut_status
        ? { logOut: true }
        : { timeOutTrigger: true }
    );

  return (
    <Box>
      <Tooltip title="Open settings" TransitionComponent={Fade}>
        <IconButton
          onClick={handleOpenUserMenu}
          color="primary"
          sx={{
            display: "flex",
            alignItems: "center",
            borderRadius: 1,
            py: 2,
          }}
        >
          <Avatar
            alt="avatar / image"
            src={userDashboard?.time_inout.timein_image}
            className="avatar-Korra"
          />

          <Typography className="T_1" variant="h5">
            Hi,
          </Typography>
          <Typography
            variant="h5"
            className="T_2"
            sx={{
              color: "rgba(0, 0, 0, 0.87)",
            }}
          >
            {userDashboard?.time_inout === "N/A"
              ? "Admin"
              : userDashboard?.time_inout.details.firstname}
          </Typography>
          <KeyboardArrowDownRoundedIcon
            sx={{
              color: "#fff",
            }}
          />
        </IconButton>
      </Tooltip>
      <Menu
        variant="menu"
        sx={{
          mt: "45px",
        }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        MenuListProps={{
          classes: { padding: classes.padding },
        }}
      >
        <Box className="UserProfile">
          <Box className="UserProfile-inner">
            <Typography variant="h4" className="UserProfile-typo">
              User Profile
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box className="B_1">
            <Box className="B_1-inner">
              {/* <Avatar
                alt="image file"
                src={"../img.png"}
                className="B_1-avatar"
              /> */}
              <Box className="B_1-typo">
                <Typography
                  alt="admin/company name"
                  variant="h4"
                  className="B_1-typo-1"
                >
                  {userDashboard?.time_inout === "N/A"
                    ? "Admin"
                    : userDashboard?.time_inout.details.full_name}
                </Typography>
                <Typography
                  alt="admin / title"
                  variant="h6"
                  className="B_1-typo-2"
                >
                  {userDashboard?.time_inout.details?.department?.name}
                </Typography>
                <Box className="B_2">
                  <Typography className="B_2-typo">
                    <MailOutlineIcon
                      sx={{
                        width: "21px",
                        height: "28px",
                      }}
                    />
                  </Typography>
                  <Typography
                    alt="abc@company.com"
                    variant="h6"
                    className="B_2-typo-2"
                  >
                    {userDashboard?.time_inout === "N/A"
                      ? ""
                      : userDashboard?.time_inout.details.email_work}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box>
            <NavLink
              to="/user"
              style={{
                textDecoration: "none",
              }}
            >
              <Divider component="hr" />
              <MenuItem onClick={handleCloseUserMenu} className="upl-menuitem">
                <Box className="Box_loop">
                  <Button
                    className="Box_loop_button"
                    sx={{
                      backgroundColor: "#22A9D8",
                      color: "#fff",
                    }}
                  >
                    <AdminPanelSettingsOutlinedIcon />
                  </Button>
                  <Box className="Box_loop_box">
                    <Typography
                      variant="h5"
                      className="Box_loop_t1"
                      sx={{
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    >
                      My Profile
                    </Typography>
                    <Typography variant="h6" className="h6-b2">
                      Account Settings
                    </Typography>
                  </Box>
                </Box>
              </MenuItem>
            </NavLink>
          </Box>
        </Box>
        <Button
          variant="contained"
          disableElevation
          className="Logout_button"
          onClick={() => {
            confirmLogout();
            // timeoutCheck();
          }}
          sx={{
            color: "#fff",
          }}
        >
          Logout
        </Button>
      </Menu>
    </Box>
  );
};

export default ProfileButton;
