import { useQuery } from "react-query";
import { visit, getTree } from "../../../api/visit";

const useVisit = (
  QueryParam,
  path,
  date,
  keyword,
  limit,
  offset,
  toggleFetch
) => {
  return useQuery(
    ["get-visits", path, date, keyword, limit, offset, toggleFetch],
    () => visit(QueryParam)
  );
};

const useTree = (toggleFetch) => {
  return useQuery(["get-tree", toggleFetch], () => getTree());
};

const queries = {
  useVisit,
  useTree,
};

export default queries;
