import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import FaceRetouchingNaturalOutlinedIcon from "@mui/icons-material/FaceRetouchingNaturalOutlined";
import IconButton from "@mui/material/IconButton";
import Greetings from "./greetings";
import Skeleton from "@mui/material/Skeleton";
const { format } = require("date-fns");

const greet = () => {
  const time = format(new Date(), "k");
  var greet = "";
  if (time < 12) {
    greet = "Good Morning";
  } else if (time >= 12 && time <= 17) {
    greet = "Good Afternoon";
  } else if (time >= 17 && time <= 24) {
    greet = "Good Evening";
  }
  return greet;
};

// {loading ? <Skeleton /> : 'h1'
const GreetingCard = ({ employee, isLoading, companyName }) => {
  return (
    <Card sx={{ height: "260.75px" }} elevation={0} className="greeting-card">
      <CardContent>
        <Box className="greeting-box">
          <Box sx={{ zIndex: 1 }}>
            {isLoading ? (
              <Skeleton height={70} />
            ) : (
              <Typography variant="h4" className="greeting-h4">
                {greet()}, {employee.employee.firstname}!
              </Typography>
            )}
            <Typography variant="h3" className="greeting-h3">
              Here is what’s happening in
              <br />
              <Button className="greet-fligno" disabled>
                {companyName}
              </Button>{" "}
              today
            </Typography>
          </Box>
          <Box className="greeting-box1">
            <IconButton className="greeting-button">
              <FaceRetouchingNaturalOutlinedIcon />
            </IconButton>
          </Box>
        </Box>
        {isLoading ? (
          <Skeleton height={75} width={187.32}>
            <Button />
          </Skeleton>
        ) : (
          <Button className="greeting-b1" disabled>
            <Greetings employee={employee} companyName={companyName} />
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default GreetingCard;
