import * as React from "react";
import Modal from "@mui/material/Modal";
import {
  Box,
  IconButton,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import Chip from "@mui/material/Chip";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export default function CommentComponentModal({
  comment,
  commentOpen,
  handleCloseComment,
}) {
  return (
    <div>
      <Modal
        keepMounted
        open={commentOpen}
        onClose={handleCloseComment}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant={"h5"} sx={{ m: "10px" }}>
              Approver's comments
            </Typography>
            <IconButton onClick={handleCloseComment}>
              <CloseRoundedIcon />
            </IconButton>
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Name</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Status</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Comment</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {comment?.map(
                  (row) =>
                    row.name === "None" || (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell>
                          {row.status === "approved" ? (
                            <Chip label={row.status} color="success" />
                          ) : row.status === "disapproved" ? (
                            <Chip label={row.status} color="error" />
                          ) : (
                            <Chip label={"pending"} color="warning" />
                          )}
                        </TableCell>
                        <TableCell>{row.remarks}</TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
}
