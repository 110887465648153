import React, { createContext, useEffect, useMemo, useState } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Paper,
  TableBody,
  TablePagination,
  Tooltip,
} from "@mui/material";
import UserAppSettingsFilter from "./UserAppSettingsFilter";
import { userAppSettingsHeader } from "../../../lib/UserAppMgmt/UserAppSettings";
import { sortArrayTable } from "../../../helper/UserAppSettings";
import hooks from "../../../hooks/react-query/UserAppSettings";
import UserAppSettingsDataList from "./UserAppSettingsDataList";
import Loader from "../../../component/Loader";
import EmptyData from "../../../component/Reports/EmptyData";

export const UserAppSettingsContext = createContext({});
const UserApplicationSettings = () => {
  const [tableData, setTableData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [orderDirection, setOrderDirection] = useState("desc");
  const [search, setSearch] = useState("");
  const [load, setLoad] = useState("");

  const { status, data, isFetching, refetch } = hooks.useUserAppSettingsData({
    criteria: { page, rowsPerPage, search },
    payload: {
      page_size: rowsPerPage,
      page: page,
      search: search,
    },
  });
  useEffect(() => {
    if (status === "success" && data) setTableData(data.data || []);
  }, [data, status]);

  const value = useMemo(
    () => ({
      search,
      load,
      setLoad,
      setSearch,
      isFetching,
      refetch,
      open,
      setOpen,
    }),
    [search, load, setLoad, setSearch, isFetching, refetch, open, setOpen]
  );
  const handleSortRequest = (key) => {
    let direction = "asc";
    direction = orderDirection === "desc" ? "asc" : "desc";
    setOrderDirection(direction);
    setTableData((prev) => sortArrayTable(prev, direction, key));
  };
  const handleChangePage = (page) => {
    setPage(page + 1);
  };
  const handleChangeRowsPerPage = (e) => {
    setPage(1);
    setRowsPerPage(e.target.value);
  };
  const filteredData = tableData.filter((el) => {
    if (search === "") {
      return el;
    } else if (el.full_name.toLowerCase().includes(search.toLowerCase())) {
      return el.full_name;
    }
    return null;
  });
  return (
    <div className="box">
      <Loader isLoading={isFetching} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "0px 15px",
          cursor: "context-menu",
        }}
      >
        <Tooltip
          title={"Manage employees application approver settings"}
          placement={"right"}
          arrow
        >
          <Typography variant="h4" component="div" sx={{ padding: "1rem" }}>
            User Application Settings
          </Typography>
        </Tooltip>
      </Box>
      <UserAppSettingsContext.Provider value={value}>
        <UserAppSettingsFilter />
        {!filteredData?.length && !isFetching ? (
          <EmptyData />
        ) : (
          <TableContainer component={Paper} sx={{ padding: "1em" }}>
            <Table>
              <TableHead>
                <TableRow>
                  {userAppSettingsHeader.map((header, index) => {
                    return (
                      <TableCell
                        onClick={() =>
                          handleSortRequest(
                            header.key,
                            header?.parentKey ?? null
                          )
                        }
                        key={index}
                        align={header.align ? header.align : undefined}
                      >
                        <TableSortLabel
                          direction={orderDirection}
                          sx={{ fontWeight: "700", fontSize: "0.8rem" }}
                        >
                          {header.label}
                        </TableSortLabel>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((item, i) => {
                  return <UserAppSettingsDataList item={item} key={i} id={i} />;
                })}
              </TableBody>
            </Table>
            <TablePagination
              sx={{ display: "flex", justifyContent: "end" }}
              rowsPerPageOptions={[10, 25, 50, 100]}
              count={data?.total || 0}
              page={data?.current_page ? data?.current_page - 1 : 0}
              onPageChange={(_, page) => {
                handleChangePage(page);
              }}
              showFirstButton={true}
              showLastButton={true}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
      </UserAppSettingsContext.Provider>
    </div>
  );
};

export default UserApplicationSettings;
