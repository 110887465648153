import {
  FormControl,
  MenuItem,
  Select as MuiSelect,
  InputLabel,
} from "@mui/material";
const Select = ({ field, form, setForm, options }) => {
  return (
    <FormControl fullWidth sx={{ mt: 1.5 }}>
      <InputLabel id="department">{field.label}</InputLabel>
      <MuiSelect
        labelId="department"
        id="department-select"
        defaultValue="1"
        label="Departments"
        onChange={(e) => {
          setForm({
            ...form,
            [field.payload]: e.target.value,
          });
        }}
        error={field.helperText && true}
      >
        {field.options ? (
          field.options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem value={0}> No Value </MenuItem>
        )}
      </MuiSelect>
    </FormControl>
  );
};

export default Select;
