import { useCallback } from "react";

import { useQuery, useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";

import {
  getLeaveConversionApplications,
  approveLeaveConversionApplication,
  declineLeaveConversionApplication,
} from "../../../../api/LeaveManagement/LeaveConversionApplications";
import { LEAVE_CONVERSION_APPLICATIONS_QUERY_KEY as QUERY_KEY } from "../../../../lib/leaveManagement/leaveConversionApplications";
import { getComparator } from "../../../../helper/LeaveManagement/LeaveConversionApplications";

export const useLeaveConversionApplications = (
  page,
  pageSize,
  searchString,
  order,
  orderBy
) => {
  const sortFunction = useCallback(
    (data) => {
      const sortedData = [...data.data].sort(getComparator(order, orderBy));

      return { ...data, data: sortedData };
    },
    [order, orderBy]
  );

  const query = useQuery(
    [QUERY_KEY, page, pageSize, searchString],
    getLeaveConversionApplications.bind(null, page, pageSize, searchString),
    {
      select: sortFunction,
    }
  );

  return query;
};

export const usePrefetchLeaveConversionApplications = (
  hasNextPage,
  page,
  pageSize,
  searchString
) => {
  const queryClient = useQueryClient();

  if (!hasNextPage) return;

  const nextPage = page + 1;

  queryClient.prefetchQuery(
    [QUERY_KEY, nextPage, pageSize, searchString],
    getLeaveConversionApplications.bind(null, nextPage, pageSize, searchString),
    {
      staleTime: 5_000,
    }
  );
};

export const useApproveLeaveApplicationConversion = () => {
  const queryClient = useQueryClient();

  const applicationMutation = useMutation(approveLeaveConversionApplication, {
    onSuccess: () => {
      toast.success("Application successfully approved.");
      queryClient.resetQueries(QUERY_KEY);
    },
    onError: () => {
      toast.error("Error submitting action.");
    },
  });

  return applicationMutation;
};

export const useDeclineLeaveApplicationConversion = () => {
  const queryClient = useQueryClient();

  const applicationMutation = useMutation(declineLeaveConversionApplication, {
    onSuccess: () => {
      toast.success("Application successfully declined.");
      queryClient.resetQueries(QUERY_KEY);
    },
    onError: () => {
      toast.error("Error submitting action.");
    },
  });

  return applicationMutation;
};
