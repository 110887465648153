import React, { createContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import Loader from "../../../component/Loader";
import { getRangeDates, sortArrayTable } from "../../../helper/Reports";
import { TableBody, TableRow, TablePagination, Tooltip } from "@mui/material";
import TimeInOutFilter from "./TimeInOutFilter";
import ExportModal from "./ExportModal";
import UserLists from "./UserLists";
import HeaderReports from "../../../component/Reports/Header";
import EmptyData from "../../../component/Reports/EmptyData";
import TableHeader from "../../../component/Reports/TableHeader.jsx";
import ReportTableContainter from "../../../component/Reports/TableContainter";
import { TimeInOutTableHeader } from "../../../lib/reports/ReportTimeInOut";
import reactQuery from "../../../hooks/react-query/TimeInOut";
import { Box } from "@mui/system";

export const TimeInOutV5Context = createContext({});

const convert = (date) => moment(date).format("YYYY-MM-DD");

const PerClientActivity = () => {
  const [range, setRange] = useState("This Week");
  const [startDate, setStartDate] = useState(
    convert(new Date(moment().startOf("week")))
  );
  const [endDate, setEndDate] = useState(
    convert(new Date(moment().endOf("week")))
  );
  const [search, setSearch] = useState("");
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);
  const [orderDirection, setOrderDirection] = useState("desc");
  const [tableData, setTableData] = useState([]);

  const handleRangeChange = (e) => {
    const date = getRangeDates(e.target.value);
    setRange(e.target.value);
    setStartDate(convert(new Date(date.fromDate)));
    setEndDate(convert(new Date(date.toDate)));
  };
  const handleChangeRowsPerPage = (e) => {
    setPage(1);
    setLimit(e.target.value);
  };

  const handleRefresh = () => {
    refetch();
    setPage(1);
    setLimit(25);
  };

  const {
    data: timeInOut,
    refetch,
    isFetching,
  } = reactQuery.useTimeInOut({
    criteria: { startDate, endDate, search, page, limit },
    payload: {
      fromDate: moment(startDate).format("YYYY-MM-DD"),
      toDate: moment(endDate).format("YYYY-MM-DD"),
      search: search,
      page: page,
      page_size: limit,
    },
  });

  useEffect(() => {
    if (timeInOut) {
      setTableData(timeInOut?.data || []);
    }
  }, [timeInOut]);

  const handleChangePage = (page) => {
    setPage(page + 1);
  };

  const handleSortRequest = (key, parentKey) => {
    let direction = "asc";
    direction = orderDirection === "desc" ? "asc" : "desc";
    setOrderDirection(direction);
    setTableData((prev) => sortArrayTable(prev, direction, key, parentKey));
  };

  const value = useMemo(
    () => ({
      isExportModalOpen,
      setIsExportModalOpen,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      search,
      setSearch,
      refetch,
      range,
      setRange,
      isFetching,
      setPage,
    }),
    [
      isExportModalOpen,
      setIsExportModalOpen,
      range,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      search,
      setSearch,
      refetch,
      setRange,
      isFetching,
      setPage,
    ]
  );
  return (
    <div>
      <Loader isLoading={isFetching} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          cursor: "context-menu",
        }}
      >
        <Tooltip
          title={"View company time in and out report"}
          placement={"right"}
          arrow
        >
          <Box>
            <HeaderReports text={"Time In / Out Report"} />
          </Box>
        </Tooltip>
      </Box>
      <TimeInOutV5Context.Provider value={value}>
        {/* Filter */}
        <TimeInOutFilter
          handleRangeChange={handleRangeChange}
          handleRefresh={handleRefresh}
        />
        <ExportModal />
        {/* End of  Filter */}

        {!timeInOut?.data?.length && !isFetching ? (
          <EmptyData />
        ) : (
          <ReportTableContainter>
            <TableHeader
              isCollapsable={true}
              headerList={TimeInOutTableHeader}
              handleSortRequest={handleSortRequest}
              orderDirection={orderDirection}
            />
            <TableBody>
              {tableData?.map((item, index) => {
                return (
                  <UserLists key={`${index}_${item.full_name}`} item={item} />
                );
              })}
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  count={timeInOut?.total || 0}
                  page={
                    timeInOut?.current_page ? timeInOut.current_page - 1 : 0
                  }
                  onPageChange={(e, page) => {
                    handleChangePage(page);
                  }}
                  showFirstButton={true}
                  showLastButton={true}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableBody>
          </ReportTableContainter>
        )}
      </TimeInOutV5Context.Provider>
    </div>
  );
};

export default PerClientActivity;
