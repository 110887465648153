import React, {
  useState,
  useMemo,
  createContext,
  useEffect,
  useCallback,
} from "react";
import { format } from "date-fns";
import {
  Box,
  Typography,
  TableContainer,
  Paper,
  TableBody,
  TablePagination,
  TableCell,
  TableSortLabel,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import ComplianceCheckFilter from "./ComplianceCheckFilter";
import hooks from "../../../hooks/react-query/ComplianceCheck";
import ComplianceCheckList from "./ComplianceCheckList";
import { sortArrayTable } from "../../../helper/LeaveManagement";
import Loader from "../../../component/Loader";
import EmptyData from "../../../component/Reports/EmptyData";

export const ComplianceCheckCntxt = createContext({});

const ComplianceCheck = () => {
  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [range, setRange] = useState("This Week");
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderDirection, setOrderDirection] = useState("desc");
  const [headers, setHeaders] = useState();
  const { data, isFetching, refetch } = hooks.useComplianceCheckerList({
    criteria: { page, rowsPerPage, search, startDate },
    payload: {
      page_size: rowsPerPage,
      page: page,
      search: search,
      date: startDate,
    },
  });

  const handleDatePicker = (newValue) => {
    setStartDate(newValue);
  };

  const headerChecker = useCallback(() => {
    if (data) {
      let columns =
        data?.headers?.map(function (headerColumn) {
          return {
            label: headerColumn.item,
            key: headerColumn.item,
          };
        }) || [];
      setHeaders([{ label: "Name", key: "name" }, ...columns]);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      setTableData(data?.data?.data);
    }
    headerChecker();
  }, [data, headerChecker]);

  const value = useMemo(
    () => ({
      startDate,
      setStartDate,
      search,
      setSearch,
      range,
      setRange,
      isFetching,
      refetch,
    }),
    [
      search,
      setSearch,
      startDate,
      setStartDate,
      range,
      setRange,
      isFetching,
      refetch,
    ]
  );

  const handleSortRequest = (key, parentKey) => {
    let direction = "asc";
    direction = orderDirection === "desc" ? "asc" : "desc";
    setOrderDirection(direction);
    setTableData((prev) => sortArrayTable(prev, direction, key, parentKey));
  };
  const handleChangePage = (page) => {
    setPage(page + 1);
  };

  const handleChangeRowsPerPage = (e) => {
    setPage(1);
    setRowsPerPage(e.target.value);
  };

  const filteredList = tableData?.filter((el) => {
    if (search === "") {
      return el;
    } else if (el.name.toLowerCase().includes(search.toLowerCase())) {
      return el.name;
    }
    return null;
  });
  return (
    <div className="box">
      <Loader isLoading={isFetching} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "0px 15px",
          cursor: "context-menu",
        }}
      >
        <Tooltip title="Manage employees compliance" placement={"right"} arrow>
          <Typography variant="h4" component="div" sx={{ padding: "1rem" }}>
            Compliance Check
          </Typography>
        </Tooltip>
      </Box>
      <ComplianceCheckCntxt.Provider value={value}>
        <ComplianceCheckFilter handleDatePicker={handleDatePicker} />
        {!filteredList?.length && !isFetching ? (
          <EmptyData />
        ) : (
          <TableContainer component={Paper} sx={{ padding: "1em" }}>
            <TableHead>
              <TableRow>
                {headers?.map((header, index) => {
                  const smallWidthHeader = ["shortname", "credits", "actions"];
                  const width = smallWidthHeader.includes(header.key)
                    ? "15%"
                    : "auto";

                  return (
                    <TableCell
                      onClick={() =>
                        handleSortRequest(header.key, header?.parentKey ?? null)
                      }
                      key={index}
                      align={header.align ? header.align : undefined}
                      sx={{ width }}
                    >
                      <TableSortLabel
                        sx={{
                          fontWeight: "700",
                          fontSize: "0.8rem",
                          "& .MuiTableSortLabel-icon": {
                            transform: `rotate(${
                              orderDirection === "desc" ? "180deg" : "0deg"
                            })`,
                          },
                        }}
                      >
                        {header.label}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredList.map((item) => {
                return (
                  <ComplianceCheckList
                    startDate={startDate}
                    item={item}
                    key={item?.employee_id}
                    columns={data?.headers}
                  />
                );
              })}
            </TableBody>
            <TablePagination
              sx={{ display: "flex", justifyContent: "end" }}
              rowsPerPageOptions={[10, 25, 50, 100]}
              count={data?.data?.total || 0}
              page={data?.data.current_page ? data?.data.current_page - 1 : 0}
              onPageChange={(e, page) => {
                handleChangePage(page);
              }}
              showFirstButton={true}
              showLastButton={true}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
      </ComplianceCheckCntxt.Provider>
    </div>
  );
};

export default ComplianceCheck;
