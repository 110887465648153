import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { Typography, TextField, Box } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

const entryAutocomplete = React.forwardRef(
  ({ onChange, field, optionData, label, defaultData }, ref) => {
    const option =
      optionData?.map((opt) => {
        return {
          id: opt.id,
          name: opt.name,
          description: opt.description,
        };
      }) || [];
    return (
      <Autocomplete
        blurOnSelect
        size="small"
        fullWidth
        options={option}
        defaultValue={defaultData}
        getOptionLabel={(option) => option.name}
        onChange={(_, data) => onChange(data?.id)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField {...params} {...field} inputRef={ref} label={label} />
        )}
        renderOption={(props, option) => (
          <Box
            component="li"
            {...props}
            sx={{
              flexDirection: "column",
              alignItems: "flex-start !important",
            }}
          >
            <Typography variant="h6" sx={{ fontSize: "0.75rem" }}>
              {option.name}
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: "0.75rem" }}
              color="text.secondary"
            >
              {option.description}
            </Typography>
          </Box>
        )}
        popupIcon={<KeyboardArrowDownRoundedIcon />}
      />
    );
  }
);

export default entryAutocomplete;
