import reportsQuery from "../../../hooks/react-query/PerUser/index";

export const PerUserTableHeaderV5 = [
  {
    label: "User",
    key: "full_name",
    isSortable: true,
  },
  {
    label: "Department",
    key: "name",
    isSortable: true,
  },
  {
    label: "Total Effort",
    key: "efforts",
    isSortable: true,
    align: "right",
    type: "number",
  },
];
export const PerUserProjectTableHeaderV5 = [
  {
    label: "Project Type",
    key: "name",
    isSortable: true,
  },
  {
    label: "Description",
    key: "description",
    isSortable: true,
  },
  {
    label: "Total Effort",
    key: "total_hours",
    isSortable: true,
    align: "right",
    type: "number",
  },
];

export const PerUserCodeTableHeaderV5 = [
  {
    label: "Project Code",
    key: "name",
    isSortable: true,
  },
  {
    label: "Description",
    key: "description",
    isSortable: true,
  },
  {
    label: "Total Effort",
    key: "total_hours",
    isSortable: true,
    align: "right",
    type: "number",
  },
];

export const PerUserActivityTableHeaderV5 = [
  {
    label: "Project Activity",
    key: "name",
    isSortable: true,
  },
  {
    label: "Description",
    key: "description",
    isSortable: true,
  },
  {
    label: "Total Effort",
    key: "total_hours",
    isSortable: true,
    align: "right",
    type: "number",
  },
];

export const PerUserTimesheetTableHeaderV5 = [
  {
    label: "Date",
    key: "date",
    isSortable: true,
  },
  {
    label: "Details",
    key: "comment",
    isSortable: true,
  },
  {
    label: "Total Effort",
    key: "hours",
    isSortable: true,
    align: "right",
    type: "number",
  },
];

export const PerUserProjectTableHeader = [
  {
    label: "Project Type",
    key: "name",
    isSortable: true,
  },
  {
    label: "Description",
    key: "description",
    isSortable: true,
  },
  {
    label: "Total Effort",
    key: "efforts",
    isSortable: true,
    align: "right",
  },
];

export const PerUserCodeTableHeader = [
  {
    label: "Project Code",
    key: "name",
    isSortable: true,
  },
  {
    label: "Description",
    key: "description",
    isSortable: true,
  },
  {
    label: "Total Effort",
    key: "efforts",
    isSortable: true,
    align: "right",
  },
];

export const PerUserActivityTableHeader = [
  {
    label: "Activity",
    key: "name",
    isSortable: true,
  },
  {
    label: "Description",
    key: "description",
    isSortable: true,
  },
  {
    label: "Total Effort",
    isSortable: true,
    align: "right",
  },
];

export const PerUserTimesheetTableHeader = [
  {
    label: "Date",
    key: "created_at",
    isSortable: true,
  },
  {
    label: "Details",
    key: "details",
    isSortable: true,
  },
  {
    label: "Total Effort",
    isSortable: true,
    align: "right",
  },
];

export const PerUserKeysV5 = [
  {
    value: "full_name",
  },
  {
    value: "name",
  },
  {
    value: "efforts",
    align: "right",
  },
];

export const PerUserCollapsableRows = [
  {
    value: "types",
    page_size: 30,
    apiCall: reportsQuery.usePerUserProject,
    tableHeader: [
      {
        label: "Project Type",
        key: "name",
        isSortable: true,
      },
      {
        label: "Description",
        key: "description",
        isSortable: true,
      },
      {
        label: "Total Effort",
        key: "efforts",
        isSortable: true,
        align: "right",
      },
    ],
    keys: [
      {
        value: "name",
      },
      {
        value: "description",
      },
      {
        value: "efforts",
        align: "right",
      },
    ],
    subLevel: [
      {
        value: "codes",
        page_size: 30,
        tableHeader: [
          {
            label: "Project Code",
            key: "name",
            isSortable: true,
          },
          {
            label: "Description",
            key: "description",
            isSortable: true,
          },
          {
            label: "Total Effort",
            key: "efforts",
            isSortable: true,
            align: "right",
          },
        ],
        keys: [
          {
            value: "name",
          },
          {
            value: "description",
          },
          {
            value: "efforts",
            align: "right",
          },
        ],
        subLevel: [
          {
            value: "activity",
            page_size: 30,
            tableHeader: [
              {
                label: "Activity",
                key: "name",
                isSortable: true,
              },
              {
                label: "Description",
                key: "description",
                isSortable: true,
              },
              {
                label: "Total Effort",
                isSortable: true,
                align: "right",
              },
            ],
            keys: [
              {
                value: "name",
              },
              {
                value: "description",
              },
              {
                value: "efforts",
                align: "right",
              },
            ],
            subLevel: [
              {
                value: "timesheets",
                page_size: 30,
                tableHeader: [
                  {
                    label: "Date",
                    key: "created_at",
                    isSortable: true,
                  },
                  {
                    label: "Details",
                    key: "details",
                    isSortable: true,
                  },
                  {
                    label: "Total Effort",
                    isSortable: true,
                    align: "right",
                  },
                ],
                keys: [
                  {
                    value: "created_at",
                  },
                  {
                    value: "details",
                  },
                  {
                    value: "efforts",
                    align: "right",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export const RangeMenuItem = [
  {
    value: "This Week",
    name: "This Week",
  },
  {
    value: "Last Week",
    name: "Last Week",
  },
  {
    value: "Last 2 Weeks",
    name: "Last 2 Weeks",
  },
  {
    value: "This month",
    name: "This month",
  },
  {
    value: "Last month",
    name: "Last month",
  },
  {
    value: "Last 2 months",
    name: "Last 2 months",
  },
  {
    value: "Custom",
    name: "Custom",
  },
];
