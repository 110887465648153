import { Fragment } from "react";

import Skeleton from "@mui/material/Skeleton";

import StyledTableCell from "../tableComponents/StyledTableCell";
import {
  generateRandomNum,
  getApplicationType,
} from "../../../../helper/UserApplicationMgmt/UserApplications";

const DateFiledCellSkeleton = ({ type }) => {
  const { isLeave, isOvertime, isWfh, forgotTimeInOut } =
    getApplicationType(type);

  return (
    <StyledTableCell sx={{ verticalAlign: "top" }}>
      <Skeleton
        variant="text"
        width={generateRandomNum(220, 230)}
        sx={{ fontSize: "20px" }}
      />

      <Skeleton
        variant="text"
        width={generateRandomNum(165, 170)}
        sx={{ fontSize: "20px" }}
      />

      <Skeleton
        variant="text"
        width={generateRandomNum(155, 165)}
        sx={{ fontSize: "20px" }}
      />

      <Skeleton variant="text" width={90} sx={{ fontSize: "20px" }} />

      {isLeave && (
        <Fragment>
          <Skeleton
            variant="text"
            width={generateRandomNum(56, 64)}
            sx={{ fontSize: "20px" }}
          />

          <Skeleton
            variant="text"
            width={generateRandomNum(64, 80)}
            sx={{ fontSize: "20px" }}
          />
        </Fragment>
      )}

      {isOvertime && (
        <Skeleton
          variant="text"
          width={generateRandomNum(90, 120)}
          sx={{ fontSize: "20px" }}
        />
      )}

      {isWfh && forgotTimeInOut && (
        <Skeleton
          variant="text"
          width={generateRandomNum(64, 80)}
          sx={{ fontSize: "20px" }}
        />
      )}
    </StyledTableCell>
  );
};

export default DateFiledCellSkeleton;
