import React from "react";
import TableCell from "@mui/material/TableCell";
import "react-image-gallery/styles/css/image-gallery.css";
import Chip from "@mui/material/Chip";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { format, parseISO } from "date-fns";

const SummaryTableData = ({ select, row, column, value }) => {
  let filed = row.filed;
  let from = row.from;
  let to = row.to;
  let length = row.length;

  if (select === "Leave") {
    const [date] = row.filed.split(" ");
    filed = format(parseISO(date), "PP");
    from = format(parseISO(row.from), "PP");
    to = format(parseISO(row.to), "PP");
    length = row.length + " day(s)";
  } else if (select === "Overtime") {
    const date_from = row.from.split(" ");
    const format_from = date_from[0].split("/");
    const date_to = row.to.split(" ");
    const format_to = date_to[0].split("/");
    filed = format(parseISO(row.filed), "PP");
    from =
      format(
        new Date(format_from[2] + "-" + format_from[0] + "-" + format_from[1]),
        "PP"
      ) +
      " " +
      date_from[2] +
      " " +
      date_from[3];
    to =
      format(
        new Date(format_to[2] + "-" + format_to[0] + "-" + format_to[1]),
        "PP"
      ) +
      " " +
      date_to[2] +
      " " +
      date_to[3];
    length = row.length + " hour(s)";
  } else if (select === "WFH") {
    filed = format(parseISO(row.filed), "PP");
    from = format(parseISO(row.from), "PP");
    to = format(parseISO(row.to), "PP");
    length = row.length;
  }

  return (
    <TableCell key={column.id} align={column.align}>
      {column.id === "status" ? (
        <>
          {select !== "ForgotTimeInOut" &&
            select !== "WFH" &&
            select !== "Document" && (
              <Box sx={{ mb: "10px" }}>
                <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                  Credits
                </Typography>
                <Typography sx={{ fontSize: "12px" }}>{row.credits}</Typography>
              </Box>
            )}
          <Chip
            label={value}
            size={"small"}
            color={
              value.toLowerCase().includes("approved") &&
              value.toLowerCase() !== "disapproved"
                ? "success"
                : value.toLowerCase() === "disapproved"
                ? "error"
                : value.toLowerCase() === "pending" ||
                  value.toLowerCase().includes("approval")
                ? "warning"
                : "default"
            }
          />
        </>
      ) : column.id === "type" ? (
        <>
          <Box sx={{ mb: "10px" }}>
            <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
              {row.type}
            </Typography>
          </Box>
          <Box sx={{ mb: "10px" }}>
            <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
              Filed on {filed}
            </Typography>
            {select !== "ForgotTimeInOut" && (
              <Typography sx={{ fontSize: "13px" }}>{from} to</Typography>
            )}
            <Typography sx={{ fontSize: "13px" }}>{to}</Typography>
          </Box>
          <Box sx={{ mb: "10px" }}>
            {select === "ForgotTimeInOut" ? (
              <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                Type
              </Typography>
            ) : (
              <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                Duration
              </Typography>
            )}
            {select === "ForgotTimeInOut" ? (
              <Typography sx={{ fontSize: "13px" }}>
                {row.action === "in" ? "Time In" : "Time Out"} on
              </Typography>
            ) : (
              <Typography sx={{ fontSize: "13px" }}>{length}</Typography>
            )}
            {select !== "Overtime" && (
                <Typography sx={{ fontSize: "13px" }}>Whole day</Typography>
              ) &&
              select === "ForgotTimeInOut" && (
                <Typography sx={{ fontSize: "13px" }}>
                  {row.date_time}
                </Typography>
              )}
          </Box>
        </>
      ) : column.format && typeof value === "number" ? (
        column.format(value)
      ) : (
        value
      )}
    </TableCell>
  );
};
export default SummaryTableData;
