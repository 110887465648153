import * as React from "react";
import Modal from "@mui/material/Modal";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { editRequest } from "../../../../api/userrequest";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useCreditStatus } from "../../../../hooks/react-query/LeaveConversion";

export default function EditRequestModal({
  openEdit,
  handleCloseEdit,
  setOpenEdit,
  editId,
  editEmp,
  editType,
  editConverted,
  editFrom,
  editTo,
  editWorkplace,
  editPurpose,
  search,
  setNotLoadingDelete,
  setToDeleteData,
  select,
  leaveTypeEdit,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [type, setType] = useState();
  const [workplace, setWorkplace] = useState();
  const [purpose, setPurpose] = useState();
  const [leaveTypeEditName, setLeaveTypeEditName] = useState();
  const queryClient = useQueryClient();

  const { data: creditStatus } = useCreditStatus();
  const [editCredit, setEditCredit] = useState(0);
  const [error, setError] = useState(false);

  const handleRequestCredit = (e) => {
    var reg = /^(?:[0-9]\d*|\d)$/;
    if (reg.test(e.target.value) || e.target.value.length === 0) {
      const value = e.target.value;
      setEditCredit(value);
      if (
        Math.abs(Math.trunc(value)) === value &&
        creditStatus?.data?.earned >= e.target.value
      ) {
        setError(false);
      } else {
        setError(true);
      }
    }
  };
  useEffect(() => {
    setFrom(editFrom);
    setTo(editTo);
    setType(editType);
    setEditCredit(editConverted);
    setWorkplace(editWorkplace);
    setPurpose(editPurpose);
    setLeaveTypeEditName(leaveTypeEdit);
  }, [
    editFrom,
    editTo,
    editType,
    editConverted,
    editWorkplace,
    editPurpose,
    leaveTypeEdit,
  ]);

  const editData = useMutation(
    () =>
      editRequest(
        editId,
        editEmp,
        editCredit,
        type,
        from,
        to,
        workplace,
        purpose
      ),
    {
      onSuccess: async () => {
        await setNotLoadingDelete(() => true);
        await queryClient.refetchQueries(
          "get-employee-request-data-with-page",
          search
        );
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const confirmEdit = () => {
    setToDeleteData(editId);
    setNotLoadingDelete(() => false);
    setOpenEdit(false);
    editData.mutate();
  };

  const closeModal = () => {
    setFrom(editFrom);
    setTo(editTo);
    setType(editType);
    setWorkplace(editWorkplace);
    setPurpose(editPurpose);
    handleCloseEdit();
  };

  const handleChangeFrom = (newValue) => {
    setFrom(newValue);
  };

  const handleChangeTo = (newValue) => {
    setTo(newValue);
  };

  const handleLocation = (event) => {
    setWorkplace(event.target.value);
  };
  const handleType = (event) => {
    setType(event.target.value);
  };
  return (
    <div>
      <Modal
        keepMounted
        open={openEdit}
        onClose={closeModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography variant={"h6"}>Edit Request Application</Typography>
          <TextField
            value={
              editType === "in"
                ? "Forgot time in"
                : editType === "out"
                ? "Forgot time out"
                : editType === "Leave Transaction"
                ? leaveTypeEdit
                : editType === "Over Time"
                ? "Overtime"
                : editType
            }
            sx={{ mt: "25px", borderColor: "#fff" }}
            fullWidth
            id="outlined-basic2"
            variant="outlined"
            disabled
          />
          {editType === "Leave Conversion" ? (
            <Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <Box sx={{ textAlign: "center", py: "30px" }}>
                  <Typography>Earned Credits</Typography>
                  <Typography sx={{ color: "#61646d" }}>
                    {creditStatus?.data?.earned}
                  </Typography>
                </Box>
                <Box sx={{ textAlign: "center", py: "30px" }}>
                  <Typography>Converted Credits</Typography>
                  <Typography sx={{ color: "#61646d" }}>
                    {creditStatus?.data?.converted}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  mt: "15px",
                }}
              >
                <Box
                  sx={{
                    textAlign: "center",
                    py: "30px",
                    width: "100%",
                    my: "auto",
                  }}
                >
                  <Typography>Requested Credits :</Typography>
                </Box>
                <Box
                  sx={{
                    textAlign: "center",
                    py: "30px",
                    width: "100%",
                    my: "auto",
                    px: "20px",
                  }}
                >
                  <TextField
                    sx={
                      error
                        ? { width: "100%", border: "1px solid red!important" }
                        : { width: "100%" }
                    }
                    id="outlined-number"
                    value={editCredit}
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      sx: error && {
                        "&:hover fieldset": {
                          border: "1px solid red!important",
                          borderRadius: 0,
                        },
                        "&:focus-within fieldset, &:focus-visible fieldset": {
                          border: "1px solid red!important",
                        },
                      },
                    }}
                    onChange={(e) => handleRequestCredit(e)}
                  />
                  {error && (
                    <Typography sx={{ color: "#E46A76" }}>
                      Invalid input credits
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          ) : (
            <>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {select === "ForgotTimeInOut" ? (
                  <Grid container spacing={2} sx={{ mt: "10px", mb: "10px" }}>
                    <Grid item sm>
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="type">Type</InputLabel>
                        <Select
                          autoWidth
                          labelId="Type"
                          label="Type"
                          value={type}
                          onChange={(e) => handleType(e)}
                        >
                          <MenuItem key={0} value={"in"}>
                            Time In
                          </MenuItem>
                          <MenuItem key={1} value={"out"}>
                            Time out
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item sm>
                      <DateTimePicker
                        label="Date & Time"
                        value={from}
                        onChange={handleChangeFrom}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </Grid>
                    <Grid item sm>
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="location">Location</InputLabel>
                        <Select
                          autoWidth
                          labelId="location"
                          label="Location"
                          value={workplace}
                          onChange={(event) => handleLocation(event)}
                        >
                          <MenuItem key={0} value={"Office"}>
                            Office
                          </MenuItem>
                          <MenuItem key={1} value={"Home/Remote"}>
                            Home/Remote
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                ) : editType === "Over Time" || editType === "Under Time" ? (
                  <Grid container spacing={2} sx={{ mt: "0px" }}>
                    <Grid item sm>
                      <DateTimePicker
                        label="Start"
                        value={from}
                        onChange={handleChangeFrom}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </Grid>
                    <Grid item sm>
                      <DateTimePicker
                        label="End"
                        value={to}
                        onChange={handleChangeTo}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={2} sx={{ mt: "0px" }}>
                    <Grid item sm={6}>
                      <MobileDatePicker
                        label="From"
                        inputFormat="MM/DD/YYYY"
                        value={from}
                        onChange={handleChangeFrom}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <MobileDatePicker
                        label="to"
                        inputFormat="MM/DD/YYYY"
                        value={to}
                        onChange={handleChangeTo}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </Grid>
                  </Grid>
                )}
              </LocalizationProvider>
              {editType === "Work from home" && (
                <TextField
                  value={workplace || ""}
                  onChange={(e) => setWorkplace(e.target.value)}
                  sx={{ mt: "20px" }}
                  multiline
                  fullWidth
                  id="outlined-basic"
                  label="Workplace"
                  variant="outlined"
                />
              )}
              <TextField
                value={purpose}
                onChange={(e) => setPurpose(e.target.value)}
                sx={{ mt: "20px" }}
                multiline
                fullWidth
                id="outlined-basic"
                label={select === "ForgotTimeInOut" ? "Reason" : "Purpose"}
                variant="outlined"
              />
            </>
          )}
          <Box
            sx={{ display: "flex", flexDirection: "row-reverse", mt: "20px" }}
          >
            <Button
              onClick={closeModal}
              sx={{ mx: "5px" }}
              variant="contained"
              color="error"
            >
              Cancel
            </Button>
            <Button
              onClick={() => confirmEdit()}
              sx={{ mx: "5px" }}
              variant="contained"
              color="success"
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
