import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Paper,
  IconButton,
  Skeleton,
  Switch,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { AlertComponent } from "../../../../component/Alerts";
import api from "../../../../api/settings/timesheet/index";
import CloseIcon from "@mui/icons-material/Close";
import { useQuery } from "react-query";
import { TableHeader } from "../../../../helper/ProjectCodeSettings";
import EditRow from "../../../../component/SettingsTimesheet/ProjectCode/EditRow";
import {
  useDeleteProjectCode,
  useGetProjectCode,
  useGetExportCodes,
} from "../../../../hooks/react-query/Settings/timsheet";
import AddProjectCode from "../../../../component/SettingsTimesheet/ProjectCode/AddProjectCode";
import ImportCsv from "../../../../component/SettingsTimesheet/ProjectCode/ImportCsv";
import DeleteRow from "../../../../component/SettingsTimesheet/ProjectCode/DeleteRow";
import ShowRows from "../../../../component/SettingsTimesheet/ProjectCode/ShowRows";
import CustomAutocomplete from "../../../../component/CustomElement/Autocomplete";
import ServerPagination from "../../../../component/Pagination/ServerPagination";
import useDebounce from "../../../../hooks/customHooks/debounce";
import History from "../../../../component/SettingsTimesheet/ProjectCode/History";
import ProjectCodeExport from "./ProjectCodeExports";
const initParams = {
  per_page: 25,
  search: "",
  status: "active",
};

const ProjectCode = () => {
  const [open, setOpen] = useState(false);
  const [openImportCsv, setOpenImportCsv] = useState(false);
  const [apiResponseMsg, setApiResponseMsg] = useState("");
  const [editInd, setEditInd] = useState(null);
  const [delInd, setDelInd] = useState(null);
  const [page, setPage] = useState(1);
  const textInput = useRef(null);
  const [params, setParams] = useState(initParams);
  const debounceValue = useDebounce(params.search, 800);
  const [openHistory, setOpenHistory] = useState({ open: false, id: null });
  const handleClose = () => {
    setOpenHistory({ ...openHistory, open: false, id: null });
  };

  const openAddProjectCode = () => {
    setOpen(true);
  };

  const closeAddProjectCode = () => {
    setOpen(false);
  };

  const closeImportCsv = () => {
    setOpenImportCsv(false);
  };

  const { data: DataGetClient, isFetching: loadingGetClient } = useQuery(
    ["get-client"],
    () => api.GetClients()
  );

  const { data: DataGetTypes, isFetching: loadingGetTypes } = useQuery(
    ["get-types"],
    () => api.GetProjectType()
  );
  const { data: DataGetDept, isFetching: loadingGetDept } = useQuery(
    ["get-dept"],
    () => api.GetDepartments()
  );
  const [filterDepartment, setFilterDepartment] = useState({
    list: [],
    select: { short_name: "ALL" },
  });

  const { data: DataGetProjectCode, isFetching: loadingGetProjectCode } =
    useGetProjectCode(
      params.per_page,
      debounceValue,
      params.status,
      page,
      filterDepartment.select.short_name
    );

  const { data: ExportDatas } = useGetExportCodes();
  const CloseAlert = () => {
    setApiResponseMsg("");
  };
  const { mutate: DeleteProjectCode, isLoading } = useDeleteProjectCode();

  useEffect(() => {
    // if (!loadingGetProjectCode) {
    //   setRows(
    //     Departments(DataGetProjectCode.data, filterDepartment.select.short_name)
    //   );
    // }

    if (!loadingGetDept) {
      setFilterDepartment((oldState) => ({
        ...oldState,
        list: [...DataGetDept.data, { short_name: "ALL" }],
      }));
    }
    return () => {};
  }, [
    DataGetDept,
    DataGetProjectCode,
    filterDepartment.select.short_name,
    loadingGetDept,
    loadingGetProjectCode,
  ]);
  const showInactive = (e, c) => {
    if (c) {
      setParams({ ...params, status: "" });
    } else {
      setParams({ ...params, status: "active" });
    }
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const backPage = () => {
    setPage(page - 1);
  };
  const startPage = () => {
    setPage(1);
  };
  const lastPage = () => {
    !loadingGetProjectCode && setPage(DataGetProjectCode.last_page);
  };

  const handleAutoComplete = (event, value, reason) => {
    if (reason === "clear") {
      setPage(1);
      setFilterDepartment((oldState) => ({
        ...oldState,
        select: { short_name: "ALL" },
      }));
    } else {
      setPage(1);
      setFilterDepartment((oldState) => ({
        ...oldState,
        select: { short_name: value.short_name },
      }));
    }
  };

  return (
    <Grid container sx={{ padding: "0px 15px 0px 15px" }}>
      <Paper
        sx={{
          width: "100%",
          margin: "0px 0px 15px 0px",
          padding: "15px",
          borderRadius: "5px",
        }}
      >
        <Grid container mb={2} sx={{ cursor: "context-menu" }}>
          <Tooltip
            title={"Manage company project code"}
            placement={"right"}
            arrow
          >
            <Typography variant="h4">Project Code</Typography>
          </Tooltip>
        </Grid>
        <Grid
          container
          style={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid item style={{ marginRight: "10px" }}>
            <CustomAutocomplete
              minWidth={200}
              size="small"
              label={"Departments"}
              loading={loadingGetDept}
              data={filterDepartment.list}
              defValue={filterDepartment.select}
              content={"short_name"}
              onChange={handleAutoComplete}
            />
          </Grid>
          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <div style={{ fontSize: "0.85rem" }}>Show Inactive:</div>
            <Switch onChange={showInactive} />
          </Grid>
        </Grid>
      </Paper>

      <Paper
        sx={{
          width: "100%",
          padding: "15px 15px 0px 15px",
          borderRadius: "5px",
        }}
      >
        <Grid
          container
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
          mb={4}
          spacing={5}
        >
          <Grid item>
            <Button
              className="adjust-w timest-button"
              variant="contained"
              sx={{ padding: "1px 5px 1px 5px", m: "5px" }}
              onClick={openAddProjectCode}
            >
              Add Project code
            </Button>
            {ExportDatas && <ProjectCodeExport tableData={ExportDatas} />}
            <Button onClick={() => setOpenImportCsv(true)}>
              Import CSV-PROJECT CODE
            </Button>
          </Grid>
          <Grid item>
            <TextField
              variant="standard"
              label="Search"
              onChange={(e) => {
                setPage(1);
                setParams({ ...params, search: e.target.value });
              }}
              inputRef={textInput}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      textInput.current.value = "";
                      setParams({ ...params, search: "" });
                    }}
                    size="small"
                  >
                    <CloseIcon fontSize="small" sx={{ fontSize: "0.85rem" }} />
                  </IconButton>
                ),
              }}
            />
          </Grid>
        </Grid>
        <TableContainer
          sx={{ maxHeight: "calc(100vh - 410px)", padding: "0px 0px 20px 0px" }}
        >
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                {TableHeader().map((header, key) => (
                  <TableCell
                    key={key}
                    sx={{ fontWeight: "bold" }}
                    align={header === "Action" ? "center" : "inherit"}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loadingGetProjectCode &&
                DataGetProjectCode?.data?.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={10} align="center">
                      No records found.
                    </TableCell>
                  </TableRow>
                )}
              {!loadingGetProjectCode &&
              !loadingGetClient &&
              !loadingGetTypes &&
              !loadingGetDept ? (
                DataGetProjectCode.data.map((data, key) => (
                  <TableRow key={key}>
                    {editInd === key ? (
                      <EditRow
                        setEditInd={setEditInd}
                        data={data}
                        clients={DataGetClient.data}
                        types={DataGetTypes.data}
                        departments={DataGetDept.data}
                      />
                    ) : delInd === key ? (
                      <DeleteRow
                        DeleteProjectCode={DeleteProjectCode}
                        id={data.id}
                        isLoading={isLoading}
                        setDelInd={setDelInd}
                      />
                    ) : (
                      <ShowRows
                        data={data}
                        DataGetClient={DataGetClient}
                        DataGetTypes={DataGetTypes}
                        DataGetDept={DataGetDept}
                        setEditInd={setEditInd}
                        editInd={editInd}
                        delInd={delInd}
                        setDelInd={setDelInd}
                        index={key}
                        setOpenHistory={setOpenHistory}
                      />
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={11}>
                    <Typography variant="h4">
                      {[0, 1, 2, 3, 4, 5, 6].map((key) => (
                        <Skeleton key={key} />
                      ))}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!loadingGetProjectCode && (
          <ServerPagination
            setPerPage={setParams}
            startPage={startPage}
            lastPage={lastPage}
            nextPage={nextPage}
            field={"per_page"}
            backPage={backPage}
            pageData={{
              page: page,
              perPage: params.per_page,
              from: DataGetProjectCode.from,
              to: DataGetProjectCode.to,
              total: DataGetProjectCode.total,
            }}
          />
        )}
      </Paper>

      {apiResponseMsg && (
        <AlertComponent
          severity={"success"}
          message={apiResponseMsg}
          handleOpenReaction={CloseAlert}
        />
      )}
      {!loadingGetClient &&
        !loadingGetTypes &&
        !loadingGetDept &&
        !loadingGetProjectCode && (
          <AddProjectCode
            open={open}
            handleClose={closeAddProjectCode}
            clients={DataGetClient.data}
            types={DataGetTypes.data}
            departments={DataGetDept.data}
            projectCodes={DataGetProjectCode.data}
          />
        )}
      <ImportCsv
        open={openImportCsv}
        handleClose={closeImportCsv}
        successMsg={setApiResponseMsg}
      />
      {openHistory.open && (
        <History
          open={openHistory.open}
          handleClose={handleClose}
          id={openHistory.id}
        />
      )}
    </Grid>
  );
};
export default ProjectCode;
