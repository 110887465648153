import format from "date-fns/format";
import intervalToDuration from "date-fns/intervalToDuration";
import moment from "moment";

const {
  ORDER,
  HEADER_CELL_ID,
  headerCells,
  APP_TYPE,
  ACTION_TYPE,
} = require("../../../lib/UserAppMgmt/UserApplications");

export const getDays = (startDate, endDate, duration) => {
  if (/half/gim.test(duration)) return 0.5;

  const start = moment([
    startDate.getYear(),
    startDate.getMonth(),
    startDate.getDate(),
  ]);
  const end = moment([
    endDate.getYear(),
    endDate.getMonth(),
    endDate.getDate(),
  ]);
  const result = end.diff(start, "days");
  return result === 0 ? 1 : result + 1;
};

export const getApplicationTypeProperty = (obj, value, property) => {
  const key = Object.keys(obj).find((key) => obj[key].API === value);
  return obj[key][property];
};

export const formatDate = (date, withHour = true) => {
  return format(new Date(date), `LLLL dd, yyyy${withHour ? " hh:mm aa" : ""}`);
};

const descendingComparator = (a, b, orderBy) => {
  const x = a[orderBy],
    y = b[orderBy];

  if (y < x) return -1;
  if (y > x) return 1;
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === ORDER.DESCENDING
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const generateSkeletonLoader = (count, SkeletonComponent) => {
  return Array(count)
    .fill(SkeletonComponent)
    .map((Component, index) => ({ ...Component, key: index }));
};

export const generateRandomNum = (min, max) =>
  Math.floor(Math.random() * (max - min)) + min;

export const isWfh = (input) => {
  const regex = /work from home|wfh/gim;
  return regex.test(input);
};

export const forgotTimeInOut = (input) => {
  const regex = /forgotTimeInOut/;
  return regex.test(input);
};

export const filterHeaderByType = (type) => {
  const removeForLeave = [
    HEADER_CELL_ID.WFH_WORKPLACE,
    HEADER_CELL_ID.WFH_ATTACHMENT,
    HEADER_CELL_ID.WFH_TELECOMMUTE,
    HEADER_CELL_ID.REASON,
    HEADER_CELL_ID.FORGOT_LOCATION,
  ];

  const removeForWfh = [
    HEADER_CELL_ID.LEAVE_CREDITS,
    HEADER_CELL_ID.APPLICATION,
    HEADER_CELL_ID.DURATION,
    HEADER_CELL_ID.NO_OF_DAYS,
    HEADER_CELL_ID.REASON,
    HEADER_CELL_ID.FORGOT_LOCATION,
  ];

  const removeForForgot = [
    HEADER_CELL_ID.LEAVE_CREDITS,
    HEADER_CELL_ID.APPLICATION,
    HEADER_CELL_ID.DURATION,
    HEADER_CELL_ID.PURPOSE,
    HEADER_CELL_ID.WFH_WORKPLACE,
    HEADER_CELL_ID.NO_OF_DAYS,
  ];

  const removeForOtUt = [
    ...removeForLeave,
    ...removeForWfh,
    ...removeForForgot,
  ];

  const removeForDocument = [
    HEADER_CELL_ID.LEAVE_CREDITS,
    HEADER_CELL_ID.APPLICATION,
    HEADER_CELL_ID.DURATION,
    HEADER_CELL_ID.WFH_WORKPLACE,
    HEADER_CELL_ID.NO_OF_DAYS,
  ];

  if (type === APP_TYPE.leave.id)
    return headerCells.filter((el) => !removeForLeave.includes(el.id));

  if (type === APP_TYPE.ot.id)
    return headerCells.filter((el) => !removeForOtUt.includes(el.id));

  if (type === APP_TYPE.wfh.id)
    return headerCells.filter((el) => !removeForWfh.includes(el.id));

  if (type === APP_TYPE.forgotTimeInOut.id)
    return headerCells.filter((el) => !removeForForgot.includes(el.id));
  if (type === APP_TYPE.document.id)
    return headerCells.filter((el) => !removeForDocument.includes(el.id));
};

export const pickupAndPopulateApplicationsData = (data) => {
  return data?.map((el) => {
    const startDate = new Date(
      el?.from || el?.date_from || el?.created_at || ""
    );
    const endDate = new Date(el?.to || el?.date_to || el?.date || "");
    const dateFiled = new Date(el?.created_at || "");
    const forgotDate = el?.date ? format(new Date(el?.date), "hh:mm aa") : null;
    const duration = el?.attr ? JSON.parse(el.attr)?.duration : null;

    return {
      ...el,
      name:
        el.leave_ee?.full_name || el.ee?.full_name || el.employee?.full_name,
      leaveType: el?.leave_type?.name || "Leave",
      dateFiled,
      startDate,
      endDate,
      length: el?.days,
      duration,
      credits: el?.leave_type?.credits,
      wfh_template: el?.template_id || "NA",
      employeeId:
        el?.employee?.id || el?.employee_id || el?.employee || el?.employeeId,
      time: forgotDate,
      location: el?.location,
      reason: el?.reason,
      forgotType: el?.type,
      hr_approver: el?.hr_approver,
    };
  });
};

export const getApplicationActionEndpoint = (actionType, applicationType) => {
  let baseUrl =
    applicationType === "document" ? "v5/applications/" : "v5/leave/";
  if (actionType === ACTION_TYPE.APPROVE)
    baseUrl += `${ACTION_TYPE.APPROVE}-${
      applicationType === "document" ? "request-" : ""
    }`;
  if (actionType === ACTION_TYPE.DENY)
    baseUrl += `${ACTION_TYPE.DENY}-${
      applicationType === "document" ? "request-" : ""
    }`;

  return baseUrl + applicationType;
};

export const transformPluralToSingular = (
  stringToTransform,
  booleanCondition
) => {
  if (booleanCondition === false) return stringToTransform;

  return `${stringToTransform}s`;
};

export const getOvertimeDuration = (startDate, endDate) => {
  if (endDate.getTime() < startDate.getTime()) return "invalid date range";

  const { hours, minutes } = intervalToDuration({
    start: startDate,
    end: endDate,
  });

  if (hours === 0 && minutes === 0) return "no duration";

  let returnStr = "";

  if (hours > 0)
    returnStr = `${hours} ${transformPluralToSingular("hour", hours > 1)}`;

  if (minutes > 0)
    returnStr += ` ${minutes} ${transformPluralToSingular(
      "minute",
      minutes > 1
    )}`;

  return returnStr;
};

export const getWFHDuration = (startDate, endDate) => {
  const { days } = intervalToDuration({
    start: endDate.getTime() < startDate.getTime() ? endDate : startDate,
    end: endDate.getTime() < startDate.getTime() ? startDate : endDate,
  });

  const daysAbsolute = Math.abs(days);

  if (daysAbsolute === 0) return "1 day";

  return `${daysAbsolute + 1} days`;
};

export const getApplicationType = (type) => {
  const isLeave = type === APP_TYPE.leave.id;
  const isOvertime = type === APP_TYPE.ot.id;
  const isWfh = type === APP_TYPE.wfh.id;
  const forgotTimeInOut = type === APP_TYPE.forgotTimeInOut.id;
  const isDocument = type === APP_TYPE.document.id;
  return {
    isLeave,
    isOvertime,
    isWfh,
    forgotTimeInOut,
    isDocument,
  };
};

export const findSetting = (settParam, settings = []) => {
  if (!settings?.length) return null;

  return settings.map((st) => {
    if (st.setting === settParam) {
      return st.value;
    }
    return null;
  });
};
