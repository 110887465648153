import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import Chip from "@mui/material/Chip";
import ApproverSkeleton from "../ApproverSkeleton";
import Paper from "@mui/material/Paper";

const ApproverContainer = ({ approvers }) => {
  return (
    <Paper elevation={3} sx={{ margin: "15px 20px", padding: "12px" }}>
      <Typography
        variant={"subtitle2"}
        sx={{ padding: "0 10px", fontWeight: "bold" }}
      >
        Your Approvers(s) :
      </Typography>
      <Container maxWidth={"m"}>
        {approvers?.data ? (
          <Chip
            label={approvers?.data}
            variant="outlined"
            sx={{ margin: "10px" }}
          />
        ) : (
          <ApproverSkeleton />
        )}
      </Container>
    </Paper>
  );
};
export default ApproverContainer;
