import * as yup from "yup";

export const compSettingSchema = yup
  .object({
    companyName: yup.string().required("Please provide company name."),
    bgcolor_appbar: yup.string().required("Please provide header color."),
    bgcolor_primary: yup.string().required("Please provide primary color."),
    bgcolor_secondary: yup.string().required("Please provide secondary color."),
  })
  .required();
