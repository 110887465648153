import Colleagues from "../pages/Colleagues";
import Dashboard from "../pages/Dashboard";
import Profile from "../pages/UserProfile";
import Visit from "../pages/Visit";
import TimesheetPage from "../pages/Timesheet";
import { TimesheetProvider } from "../globalstate/UserContext/timesheetcontext";
import PerUserV5 from "../pages/Reports/PerUserV5";
import PerProjectUserV5 from "../pages/Reports/PerProjectUserV5";
import PerProjectActivityV5 from "../pages/Reports/PerProjectActivityV5";
import ProjectCodeSummary from "../pages/Reports/ProjectCodeSummary";
import LeaveTypes from "../pages/LeaveManagement/LeaveTypes";
import PunctualityTardiness from "../pages/Reports/PunctualityTardiness";
import CompanySettings from "../pages/Settings/CompanySettings";
import ResetPassword from "../pages/Auth/ResetPassword";
import UserLists from "../pages/Settings/UserLists";
import Leavecredit from "../pages/LeaveManagement/LeaveCredit";
import DepartmentSettings from "../pages/Admin/Settings/Department";
import Permission from "../pages/SettingsSystem/Permission";
import UserSchedule from "../pages/UserSchedule";
import UserInformation from "../pages/UserInformation";
import UserApplicationSettings from "../pages/UserApplicationMgmt/UserApplicationSettings";
import Policy from "../pages/Policy";
import Myrequest from "../pages/UserRequests/MyRequests";
import Schedule from "../pages/Schedule";
import ShiftModal from "../component/Dashboard/shiftModal";
import ProjectType from "../pages/SettingsTimesheet/ProjectType";
import ProjectCode from "../pages/Admin/Settings/Timesheet/ProjectCode";
import TimesheetActivity from "../pages/TimesheetActivity";
import ComplianceItemV5 from "../pages/Compliance/ComplianceItemV5";
import UserApplications from "../pages/UserApplicationMgmt/UserApplications";
import ReportsDashboard from "../pages/Reports/ReportsDashboard";
import TimeInOutReport from "../pages/Reports/TimeInOutReport";
import LeaveConversionLogs from "../pages/LeaveManagement/LeaveConversionLogs";

import ClientCode from "../pages/ClientCode";
import ReactionReport from "../lib/reports/reactions/reactionReport";
import LeaveAndAbsencesSummary from "../pages/LeaveAndAbsencesSummary/index.js";

import Timekeeping from "../pages/Reports/Timekeeping";
import Payroll from "../pages/Admin/Settings/Report/Payroll";
import Compliance from "../pages/SettingsTimesheet/Compliance";
import PendingTimesheetEntries from "../pages/SettingsTimesheet/PendingTimesheetEntries";
import ComplianceReport from "../pages/Compliance/ComplianceReport";
import UserApplicationSummary from "../pages/UserApplicationMgmt/UserApplicationSummary";
import Holiday from "../pages/Admin/Settings/Time/Holiday";
import PerActivity from "../pages/Reports/PerActivity";
import WfhTemplate from "../pages/SettingsSystem/WfhTemplate";
import WFHSummary from "../pages/WFHSummary";
import PerTypeV5 from "../pages/Reports/PerTypeV5";
import MessageOfTheDay from "../pages/SettingsSystem/MessageOfTheDay";
import AuditLogs from "../pages/AuditLogs";
import ManageFeedback from "../pages/ManageFeedback";
import TimesheetWeeklyReport from "../pages/Evaluation/TimesheetWeeklyReport";
import ComplianceCheck from "../pages/Compliance/ComplianceCheck";
import PerClientActivity from "../pages/Reports/PerClientActivity";
import LeaveCalendar from "../pages/LeaveManagement/LeaveCalendar";
import AllPendingTimesheetEntries from "../pages/SettingsTimesheet/AllPendingTimesheetEntries";
import NotificationPage from "../pages/Notification/index";
import { NotificationProvider } from "../globalstate/UserContext/Notification";
import TimesheetForAll from "../pages/Admin/Settings/Timesheet/TimesheetForAll";
import LeaveConversionApplications from "../pages/LeaveManagement/LeaveConversionApplications";

import PulseSurvey from "../component/PulseSurvey/PulseSurvey";
import EmployeeToEmailTimesheetReport from "../pages/SettingsTimesheet/EmployeeToEmailTimesheetReport";
import LeaveConversionHelper from "../lib/leaveConversion/leaveConversionHelper";
import TimesheetAnalytics from "../pages/Evaluation/TimesheetWeeklyReport/TimesheetAnalytics";
import AdminSkillsPage from "../pages/SettingsSystem/Skills";
import { SystemSkillProvider } from "../globalstate/SystemSettings/Skill";
const routes = [
  {
    path: "/colleagues",
    exact: true,
    roles: ["view colleagues"],
    component: () => <Colleagues />,
  },
  {
    path: "/dashboard",
    exact: true,
    roles: ["all"],
    component: () => (
      <TimesheetProvider>
        <Dashboard />
      </TimesheetProvider>
    ),
  },
  {
    path: "/user",
    exact: true,
    roles: ["all"],
    component: () => <Profile />,
  },
  {
    path: "/hr-folder",
    exact: true,
    roles: ["view hrfolder", "manage hrfolder"],
    component: () => <Policy />,
  },
  {
    path: "/customer-visit-log",
    exact: true,
    roles: ["view customer visits", "manage customer visits"],
    component: () => <Visit />,
  },
  {
    path: "/my-requests",
    exact: true,
    roles: ["file requests"],
    component: () => <Myrequest />,
  },
  {
    path: "/timesheet",
    exact: true,
    roles: ["time entry"],
    component: () => (
      <TimesheetProvider>
        <TimesheetPage />
      </TimesheetProvider>
    ),
  },
  {
    path: "/compliance/compliance-check",
    exact: true,
    roles: ["access reports"],
    component: () => <ComplianceCheck />,
  },

  {
    path: "/compliance/compliance-item",
    exact: true,
    roles: ["access reports"],
    component: () => <ComplianceItemV5 />,
  },
  {
    path: "/reports/dashboard",
    exact: true,
    roles: ["access reports"],
    component: () => <ReportsDashboard />,
  },
  {
    path: "/reports/per-user",
    exact: true,
    roles: ["access reports"],
    component: () => <PerUserV5 />,
  },
  {
    path: "/reports/per-project-user",
    exact: true,
    roles: ["access reports"],
    component: () => <PerProjectUserV5 />,
  },
  {
    path: "/reports/per-type",
    exact: true,
    roles: ["access reports"],
    component: () => <PerTypeV5 />,
  },
  {
    path: "/reports/per-project-activity",
    exact: true,
    roles: ["access reports"],
    component: () => <PerProjectActivityV5 />,
  },
  {
    path: "/reports/project-code-summary",
    exact: true,
    roles: ["access reports"],
    component: () => <ProjectCodeSummary />,
  },
  {
    path: "/reports/timekeeping",
    exact: true,
    roles: ["access reports"],
    component: () => <Timekeeping />,
  },
  {
    path: "/leave-management/leave-types",
    exact: true,
    roles: ["manage leave types"],
    component: () => <LeaveTypes />,
  },
  {
    path: "/reports/tardiness",
    exact: true,
    roles: ["access reports"],
    component: () => <PunctualityTardiness />,
  },
  {
    path: "/reports/reaction-reports",
    exact: true,
    roles: ["access reports"],
    component: () => <ReactionReport />,
  },
  {
    path: "/reports/time-in-out-report",
    exact: true,
    roles: ["access reports"],
    component: () => <TimeInOutReport />,
  },
  {
    path: "/settings/general",
    exact: true,
    roles: ["manage general settings"],
    component: () => <CompanySettings />,
  },
  {
    path: "/settings/shift",
    exact: true,
    roles: ["manage schedules"],
    component: () => <ShiftModal />,
  },
  {
    path: "/settings/schedule",
    exact: true,
    roles: ["manage schedules"],
    component: () => <Schedule />,
  },
  {
    path: "/settings/users",
    exact: true,
    roles: ["manage accounts"],
    component: () => <UserLists />,
  },
  {
    path: "/password/reset",
    exact: true,
    roles: ["all"],
    isAdmin: false,
    component: () => <ResetPassword />,
  },
  {
    path: "/leave-management/leave-credits",
    exact: true,
    roles: ["manage employee leave credits"],
    isAdmin: false,
    component: () => <Leavecredit />,
  },
  {
    path: "/leave-management/leave-conversion-logs",
    exact: true,
    roles: ["manage leave types"],
    isHR: true,
    isAdmin: true,
    isManager: false,
    component: () => <LeaveConversionLogs />,
  },
  {
    path: "/settings/department",
    exact: true,
    roles: ["manage departments"],
    component: () => <DepartmentSettings />,
  },
  {
    path: "/employee-schedule/:id",
    exact: true,
    roles: ["manage schedules"],
    component: () => <UserSchedule />,
  },
  {
    path: "settings/users/:id",
    exact: true,
    roles: ["manage profiles"],
    component: () => <UserInformation />,
  },
  {
    path: "/settings/permission",
    exact: true,
    roles: ["manage permissions"],
    component: () => <Permission />,
  },
  {
    path: "/settings/announcement",
    exact: true,
    roles: ["manage permissions"],
    component: () => <MessageOfTheDay />,
  },
  {
    path: "/user-application-mgmt/user-application-settings",
    exact: true,
    roles: ["manage employee leave settings"],
    isAdmin: true,
    component: () => <UserApplicationSettings />,
  },
  {
    path: "/settings/project-type",
    exact: true,
    roles: ["manage timesheet codes"],
    isAdmin: true,
    component: () => <ProjectType />,
  },
  {
    path: "/settings/timesheet-code",
    exact: true,
    roles: ["manage timesheet codes"],
    isAdmin: true,
    component: () => <ProjectCode />,
  },
  {
    path: "/settings/timesheet-activity",
    exact: true,
    roles: ["manage general settings"],
    component: () => <TimesheetActivity />,
  },
  {
    path: "/settings-timesheet/client-code",
    exact: true,
    roles: ["manage employee leave settings"],
    isAdmin: true,
    component: () => <ClientCode />,
  },
  {
    path: "/user-application-mgmt/user-applications",
    exact: true,
    roles: ["manage employee leaves"],
    isAdmin: true,
    component: () => <UserApplications />,
  },
  {
    path: "/user-application-mgmt/user-application-summary",
    exact: true,
    roles: ["manage employee leaves"],
    isAdmin: true,
    component: () => <UserApplicationSummary />,
  },
  {
    path: "/evaluation/leave-and-absences",
    exact: true,
    roles: ["manage employee leaves"],
    isAdmin: true,
    component: () => <LeaveAndAbsencesSummary />,
  },
  {
    path: "payroll/report",
    exact: true,
    roles: ["access reports"],
    isAdmin: true,
    component: () => <Payroll />,
  },
  {
    path: "/settings/compliance",
    exact: true,
    roles: ["manage timesheet codes"],
    isAdmin: true,
    component: () => <Compliance />,
  },
  {
    path: "/settings-timesheet/pending-timesheet-entries",
    exact: true,
    roles: ["access pending timesheet entries"],
    isAdmin: true,
    component: () => <PendingTimesheetEntries />,
  },
  {
    path: "/compliance/compliance-report",
    exact: true,
    roles: ["access reports"],
    component: () => <ComplianceReport />,
  },
  {
    path: "/settings/holiday",
    exact: true,
    roles: ["manage holiday"],

    component: () => <Holiday />,
  },
  {
    path: "/settings/wfh-template",
    exact: true,
    roles: ["manage general settings"],
    component: () => <WfhTemplate />,
  },
  {
    path: "/reports/per-activity",
    exact: true,
    roles: ["access reports"],
    component: () => <PerActivity />,
  },
  {
    path: "/reports/wfh-summary",
    exact: true,
    roles: ["access reports"],
    component: () => <WFHSummary />,
  },
  {
    path: "/audit-logs",
    exact: true,
    roles: ["access audit logs"],
    component: () => <AuditLogs />,
  },
  {
    path: "/manage-feedback",
    exact: true,
    roles: ["manage feedback"],
    isAdmin: true,
    component: () => <ManageFeedback />,
  },
  {
    path: "/reports/per-client-activity",
    exact: true,
    roles: ["access reports"],
    isAdmin: true,
    component: () => <PerClientActivity />,
  },
  {
    path: "/leave-management/calendar",
    exact: true,
    roles: ["manage leave types"],
    component: () => <LeaveCalendar />,
  },
  {
    path: "/evaluation/timesheet-weekly-report",
    exact: true,
    roles: ["access reports"],
    component: () => <TimesheetWeeklyReport />,
  },
  {
    path: "/settings-timesheet/all-pending-timesheet-entries",
    exact: true,
    roles: ["access pending timesheet entries"],
    isAdmin: true,
    component: () => <AllPendingTimesheetEntries />,
  },
  {
    path: "/notification",
    exact: true,
    roles: ["all"],
    component: () => (
      <NotificationProvider>
        <NotificationPage />
      </NotificationProvider>
    ),
  },
  {
    path: "/settings-timesheet/timesheet-entry-all",
    exact: true,
    roles: ["manage timesheet codes"],
    isAdmin: true,
    component: () => <TimesheetForAll />,
  },
  {
    path: "/leave-management/leave-conversion-applications",
    exact: true,
    roles: ["manage leave types"],
    isAdmin: true,
    component: () => <LeaveConversionApplications />,
  },
  {
    path: "/pulse-survey",
    exact: true,
    roles: ["manage surveys"],
    isAdmin: true,
    component: () => <PulseSurvey />,
  },
  {
    path: "/settings-timesheet/employee-to-email-timesheet-report",
    exact: true,
    roles: ["access reports"],
    component: () => <EmployeeToEmailTimesheetReport />,
  },
  {
    path: "/manage-leave-conversion-application/:id/:conversion/:status",
    exact: true,
    roles: ["manage leave types"],
    isAdmin: true,
    component: () => <LeaveConversionHelper />,
  },
  {
    path: "/reports/timesheet-analytics",
    exact: true,
    roles: ["access reports"],
    isAdmin: true,
    component: () => (
      <TimesheetProvider>
        <TimesheetAnalytics />
      </TimesheetProvider>
    ),
  },
  {
    path: "/settings/skills",
    exact: true,
    roles: ["manage accounts"],
    isAdmin: true,
    component: () => (
      <SystemSkillProvider>
        {" "}
        <AdminSkillsPage />
      </SystemSkillProvider>
    ),
  },
];
export default routes;
