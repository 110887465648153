export const ProjectCodeSummaryTableHeaderV5 = [
  {
    label: "Code",
    key: "name",
    isSortable: true,
  },
  {
    label: "Description",
    key: "name",
    isSortable: true,
  },
  {
    label: "Client",
    key: "company_code",
    parentKey: "client",
    isSortable: true,
  },
  {
    label: "Type",
    key: "name",
    parentKey: "codetype",
    isSortable: true,
  },
  {
    label: "Departments",
    key: "key",
    isSortable: true,
  },
  {
    label: "Actions",
    key: "efforts",
    align: "right",
  },
];
