import { useMutation, useQuery } from "react-query";
import {
  API_ENDPOINT,
  genGetRequest,
  genPostRequest,
} from "../../../helper/General";

export const useClientCode = (search, page, per_page) => {
  const param = { search, page, per_page };
  return useQuery(["get-client-code", search, page], () =>
    genGetRequest(param, API_ENDPOINT.GET_CLIENTS)
  );
};

export const useUpdateClientCode = (param) => {
  return useMutation("update-client-code", (data) =>
    genPostRequest(data, param, API_ENDPOINT.POST_CLIENTS)
  );
};

export const useValidateClientCode = (param) => {
  return useMutation("validate-client-code", (data) =>
    genPostRequest(data, param, API_ENDPOINT.VALIDATE_CLIENTS)
  );
};

export const useDeleteClientCode = (param) => {
  return useMutation("delete-client-code", (data) =>
    genPostRequest(data, param, API_ENDPOINT.DELETE_CLIENTS)
  );
};

export const useAddClientCode = (param) => {
  return useMutation("add-client-code", (data) =>
    genPostRequest(data, param, API_ENDPOINT.ADD_CLIENTS)
  );
};
