import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  addDocumentRequestAPI,
  myDocumentRequestAPI,
} from "../../../../api/Requests/Documents";

export const useGetMyDocumentRequest = () => {
  return useQuery(["my-document-request"], () => myDocumentRequestAPI);
};

export const useAddDocumentRequest = () => {
  const queryClient = useQueryClient();
  return useMutation(addDocumentRequestAPI, {
    onSuccess: (res) => {
      queryClient.invalidateQueries("my-document-request");
      queryClient.invalidateQueries("get-employee-request-data-with-page");
      toast.success(res.response);
    },
    onError: (res) => {
      const duplicate = res?.response?.data;
      if (duplicate === "You have already requested this document") {
        toast.warn(duplicate);
      } else {
        toast.error("Something went wrong");
      }
    },
  });
};
