import React from "react";
import {
  TextField,
  MenuItem,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Select,
  Alert,
  Box,
  Typography,
  Autocomplete,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  LocalizationProvider,
  DatePicker,
  DateTimePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Controller } from "react-hook-form";
import { HexColorPicker, HexColorInput } from "react-colorful";

const InputController = ({
  label,
  name,
  control,
  rules,
  errors,
  type,
  options,
  rows,
  InputProps,
  size,
  inputType = "",
  hoursChecker = null,
  dateTimeNum,
  handleAppType = null,
  defaultValue,
  isDefaultErrorAlert = false,
  disableDate,
  timeNum,
  dateNum,
  handleSelectFunc,
  handleTimePickFunc,
  handleDateTimeFunc,
  handleDateFunc,
  handleAutoFunc,
  getValues,
  hexValue,
  handleSurveySelect,
  onChange,
}) => {
  function handleColorChange(name, color) {
    onChange(name, color);
  }
  return (
    <>
      <Controller
        rules={rules}
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field, fieldState: { error } }) => {
          if (type === "t-number") {
            return (
              <TextField
                {...field}
                fullWidth
                size={size}
                label={label}
                type={inputType}
                inputProps={InputProps}
              />
            );
          }
          if (type === "text") {
            return (
              <TextField
                {...field}
                variant="outlined"
                label={label}
                fullWidth
                multiline
                rows={rows}
                size={size}
                inputProps={InputProps}
              />
            );
          }

          if (type === "select") {
            return (
              <FormControl variant="outlined" fullWidth size={size}>
                <InputLabel id="gender-select">{label}</InputLabel>
                <Select
                  variant="outlined"
                  fullWidth
                  label={label}
                  labelId="gender-select"
                  id="gender-select"
                  size={size}
                  {...field}
                  onChange={(event) => {
                    field.onChange(event);
                    if (handleAppType) handleAppType();
                    if (handleSelectFunc) handleSelectFunc();
                    if (handleSurveySelect) handleSurveySelect(event);
                  }}
                >
                  {options.map((option, index) => {
                    return (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText error={!!error}>
                  {error?.message}
                </FormHelperText>
              </FormControl>
            );
          }

          if (type === "selectNoErrorMessage") {
            return (
              <FormControl variant="outlined" fullWidth size={size}>
                <InputLabel id="gender-select">{label}</InputLabel>
                <Select
                  variant="outlined"
                  fullWidth
                  label={label}
                  labelId="gender-select"
                  id="gender-select"
                  size={size}
                  {...field}
                  onChange={(event) => {
                    field.onChange(event);
                    if (handleAppType) handleAppType();
                    if (handleSelectFunc) handleSelectFunc();
                  }}
                >
                  {options.map((option, index) => {
                    return (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            );
          }

          if (type === "date") {
            return (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  {...field}
                  label={label}
                  onChange={(event) => {
                    field.onChange(event);
                    if (handleDateFunc) handleDateFunc(event, dateNum);
                  }}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} size={size} />
                  )}
                  className="datepicker"
                  inputProps={{ readOnly: true }}
                  shouldDisableDate={disableDate}
                />
              </LocalizationProvider>
            );
          }

          if (type === "check") {
            return (
              <FormControlLabel
                label="Requires WFH Application Approval"
                control={<Checkbox checked={field.value} {...field} />}
              />
            );
          }

          if (type === "dateTime") {
            return (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  {...field}
                  inputProps={{ readOnly: true }}
                  hideTabs={false}
                  onChange={(event) => {
                    field.onChange(event);
                    if (hoursChecker) hoursChecker(event, dateTimeNum);
                    if (handleDateTimeFunc)
                      handleDateTimeFunc(event, dateTimeNum);
                    if (handleTimePickFunc) handleTimePickFunc(event, timeNum);
                  }}
                  renderInput={(props) => (
                    <TextField {...props} size={size} fullWidth />
                  )}
                  label={label}
                />
              </LocalizationProvider>
            );
          }
          if (type === "autoComplete") {
            return (
              <Autocomplete
                blurOnSelect
                size={size}
                fullWidth
                options={options}
                defaultValue={defaultValue}
                getOptionLabel={(option) => option.name}
                onChange={(_, data) => {
                  field.onChange(data?.id);
                  if (handleAutoFunc) handleAutoFunc(data);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...field}
                    inputRef={field.ref}
                    label={label}
                  />
                )}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    {...props}
                    sx={{
                      flexDirection: "column",
                      alignItems: "flex-start !important",
                    }}
                  >
                    <Typography variant="h6" sx={{ fontSize: "0.75rem" }}>
                      {option.name}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: "0.75rem" }}
                      color="text.secondary"
                    >
                      {option.description}
                    </Typography>
                  </Box>
                )}
                popupIcon={<KeyboardArrowDownRoundedIcon />}
              />
            );
          }
          if (type === "timePicker") {
            return (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  {...field}
                  label={label}
                  inputProps={{ readOnly: true }}
                  onChange={(event) => {
                    field.onChange(event);
                    if (handleTimePickFunc) handleTimePickFunc(event, timeNum);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size={size} fullWidth />
                  )}
                />
              </LocalizationProvider>
            );
          }
          if (type === "hexInput") {
            return (
              <HexColorInput
                className="hexColorInputComponent"
                color={getValues(hexValue)}
                onChange={field.onChange}
                prefixed
                alpha
              />
            );
          }
          if (type === "hexPicker") {
            return (
              <HexColorPicker
                color={getValues(hexValue)}
                onChange={(color) => {
                  field.onChange(color);
                  handleColorChange(field.name, color);
                }}
              />
            );
          }
        }}
      />
      {errors[name] && isDefaultErrorAlert && (
        <Alert severity="error">{errors[name]?.message}</Alert>
      )}
    </>
  );
};

export default InputController;
