import React from "react";
import { BiCrown, BiLike, BiStar, BiHeart } from "react-icons/bi";
import { Divider, Stack, Button, Menu } from "@mui/material";
const ReactionMenu = ({
  anchorEl,
  handleOnChange,
  submitReaction,
  empId,
  page,
  handleOpen,
}) => {
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleOnChange}
      sx={{
        "& .MuiPaper-root": {
          ml: "-25px",
          mt: "-10px",
          overflow: "unset",
        },
        "& .MuiList-root-MuiMenu-list": {
          position: "relative",
        },
      }}
    >
      <Stack
        spacing="5px"
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        sx={{
          justifyContent: "space-evenly",
        }}
      >
        <Button
          size="small"
          sx={{
            minWidth: "45px",
            padding: " 5px 5px",
            color: "#9DB4E9",
            ml: "7px",
          }}
          onClick={() => {
            submitReaction("like", empId, page);
            handleOnChange();
          }}
        >
          <BiLike />
        </Button>
        <Button
          size="small"
          sx={{
            minWidth: "45px",
            padding: " 5px 5px",
            color: "#F4A6BC",
          }}
          onClick={() => {
            submitReaction("love", empId, page);
            handleOnChange();
          }}
        >
          <BiHeart />
        </Button>
        <Button
          size="small"
          sx={{
            minWidth: "45px",
            padding: " 5px 5px",
            color: "#C4A0EC",
          }}
          // onClick={() => {
          //   handleOnChange();
          // }}
          onClick={() => handleOpen("love", empId, page)}
        >
          <BiStar />
        </Button>
        <Button
          size="small"
          sx={{
            minWidth: "45px",
            padding: " 5px 5px",
            color: "#F5D67B",
            mr: "7px !important",
          }}
          onClick={() => {
            handleOnChange();
          }}
        >
          <BiCrown />
        </Button>
      </Stack>
    </Menu>
  );
};

export default ReactionMenu;
