import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
const UpRating = ({ register, errors, ind, ttRating }) => {
  register(`answerType${ind}`, { value: "rating" });
  const opt1 = `rateOptA${ind}`;
  const opt2 = `rateOptB${ind}`;
  const opt3 = `rateOptC${ind}`;
  const opt4 = `rateOptD${ind}`;
  const opt5 = `rateOptE${ind}`;

  const ans1 = `ansRateA${ind}`;
  const ans2 = `ansRateB${ind}`;
  const ans3 = `ansRateC${ind}`;
  const ans4 = `ansRateD${ind}`;
  const ans5 = `ansRateE${ind}`;
  const distributeOptions = (index) => {
    if (index === 0) {
      return opt1;
    }
    if (index === 1) {
      return opt2;
    }
    if (index === 2) {
      return opt3;
    }
    if (index === 3) {
      return opt4;
    }
    if (index === 4) {
      return opt5;
    }
  };
  const distributeAnswer = (index) => {
    if (index === 0) {
      return ans1;
    }
    if (index === 1) {
      return ans2;
    }
    if (index === 2) {
      return ans3;
    }
    if (index === 3) {
      return ans4;
    }
    if (index === 4) {
      return ans5;
    }
  };
  return (
    <Grid container rowSpacing={2} columnSpacing={2}>
      {ttRating.length > 0 &&
        ttRating.map((tt, i) => (
          <Grid item xs={12} key={i} container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={3}>
              <TextField
                variant="outlined"
                label="Option"
                defaultValue={tt.option}
                fullWidth
                size="small"
                name={distributeOptions(i)}
                required
                {...register(distributeOptions(i))}
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                variant="outlined"
                label="Enter answer here"
                defaultValue={tt.answer}
                fullWidth
                size="small"
                name={distributeAnswer(i)}
                required
                {...register(distributeAnswer(i))}
              />
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
};

export default UpRating;
