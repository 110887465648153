import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { isAfter, addDays } from "date-fns";

const DateSelect = ({
  label,
  date,
  setDate1,
  setDate2,
  startDate,
  endDate,
  dateSetter,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        value={date}
        onChange={(newValue) => {
          if (label === "From") {
            if (isAfter(newValue, endDate)) {
              dateSetter(newValue, addDays(newValue, 1));
            }
            setDate1(newValue);
          }
          if (label === "To") {
            if (isAfter(startDate, newValue)) {
              dateSetter(newValue);
            }
            setDate2(newValue);
          }
        }}
        renderInput={(params) => (
          <TextField
            fullWidth
            {...params}
            size="small"
            sx={{ margin: "5px" }}
          />
        )}
        className="datepicker"
        inputProps={{ readOnly: true }}
      />
    </LocalizationProvider>
  );
};

export default DateSelect;
