import {
  FormControl,
  Box,
  Chip,
  Tooltip,
  Checkbox,
  MenuItem,
  Select as MuiSelect,
} from "@mui/material";
import { useState } from "react";
const ChipSelectEdit = ({ field, form, setForm, options, value = [] }) => {
  const [selected, setSelect] = useState(
    Array.isArray(value) ? [value] : [value]
  );

  const handleSelect = (e) => {
    setSelect(e.target.value);

    setForm({
      ...form,
      [field.payload]: e.target.value,
    });
  };

  return (
    <FormControl fullWidth>
      <Tooltip
        open={field.helperText ? true : false}
        title={field.helperText ? field.helperText : ""}
        arrow
      >
        <MuiSelect
          size="small"
          multiple
          value={selected}
          onChange={(e) => {
            handleSelect(e);
          }}
          error={field.helperText && true}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected instanceof Array ? (
                selected.map((value) => <Chip key={value} label={value} />)
              ) : (
                <Chip label={selected} />
              )}
            </Box>
          )}
        >
          {options ? (
            options.map((option, index) => (
              <MenuItem key={index} value={option.short_name}>
                {" "}
                <Checkbox checked={selected.includes(option.short_name)} />
                {option.label}
              </MenuItem>
            ))
          ) : (
            <MenuItem value={0}> No Value </MenuItem>
          )}
        </MuiSelect>
      </Tooltip>
    </FormControl>
  );
};

export default ChipSelectEdit;
