import TableRow from "@mui/material/TableRow";

import StyledTableCell from "../../../UserApplicationMgmt/UserApplications/tableComponents/StyledTableCell";

const NoResults = () => {
  return (
    <TableRow>
      <StyledTableCell colSpan="100%" height="53px">
        No results found.
      </StyledTableCell>
    </TableRow>
  );
};

export default NoResults;
