export const motdHead = [
  {
    label: "Start",
    key: "from",
    isSortable: true,
  },
  {
    label: "To",
    key: "to",
    isSortable: true,
  },
  {
    label: "Title",
    key: "title",
    isSortable: true,
    align: "left",
  },
  {
    label: "Message",
    key: "message",
    isSortable: true,
    align: "right",
  },
  {
    label: "Users Who Confirmed",
    key: "confirmation",
    isSortable: true,
    align: "center",
  },
  {
    label: "Status",
    key: "status",
    align: "center",
    isSortable: true,
  },
  {
    label: "Document",
    key: "document",
    align: "center",
    isSortable: false,
  },
  {
    label: "Actions",
    key: "actions",
    align: "center",
  },
];
