import { useQuery, useMutation, useQueryClient } from "react-query";
import timesheeetAPI from "../../../api/timesheet/index";
import { format } from "date-fns";
import { GlobalContext } from "../../../App";
import { useContext } from "react";
import { toast } from "react-toastify";
import { toastAlertOptions } from "../../customHooks/toastAlertOptions";

export const useGetTimesheet = (payload, selectedDate, isInMonth) => {
  return useQuery(
    ["monthly-timesheet"],
    () => timesheeetAPI.GetTimesheetMonth(payload),
    { enabled: isInMonth && selectedDate instanceof Date }
  );
};

export const useAddTimesheet = () => {
  const { projectCode, projectActivity } = useContext(GlobalContext);
  const queryClient = useQueryClient();

  return useMutation(timesheeetAPI.AddTimesheet, {
    onSuccess: () => {
      toast.success("Timesheet added successfully!", toastAlertOptions);
    },
    onMutate: async (newtimesheet) => {
      await queryClient.cancelQueries("monthly-timesheet");
      const code = projectCode.find((code) => code.id === newtimesheet.code);
      const activity = projectActivity.find(
        (project) => project.id === newtimesheet.activity
      );
      const mutation = {
        id: newtimesheet.id,
        created: format(new Date(newtimesheet.date), "MMM dd, yyyy"),
        hours: newtimesheet.hours,
        status: code.restricted === 1 ? 2 : newtimesheet.late === 1 ? 2 : 1,
        late: newtimesheet.late,
        milestone: newtimesheet.sprint,
        comment: newtimesheet.details,
        time_activity: activity,
        time_code: code,
      };
      const oldtimesheet = queryClient.getQueryData("monthly-timesheet");
      queryClient.setQueryData("monthly-timesheet", (oldQueryTimesheet) => {
        let findind = oldQueryTimesheet?.time_data_conso.map((val, ind) =>
          val.time.map(
            (subVal) =>
              +new Date(subVal.created) !== +new Date(newtimesheet.date) &&
              val.code === newtimesheet.code &&
              val.activity === newtimesheet.activity
          )
        );

        let index = findind?.findIndex((ind) => !ind.includes(false));

        const conso_mutation = () => {
          if (index === -1) {
            return [...oldQueryTimesheet.time_data_conso, { time: [mutation] }];
          } else {
            return oldQueryTimesheet.time_data_conso.map((val, ind) =>
              //SHOULD FIND INDEX TO INSERT MUTATION
              ind === index
                ? {
                    ...val,
                    time: [...val.time, mutation],
                  }
                : { ...val }
            );
          }
        };

        const time_data = [...oldQueryTimesheet?.time_data, mutation];
        return {
          employee: oldQueryTimesheet.employee,
          time_data_conso: conso_mutation(),
          time_data: time_data,
        };
      });
      // if mutation is error
      return oldtimesheet;
    },
    onError: (_error, _timesheet, context) => {
      toast.error("Something went wrong, please try again", toastAlertOptions);
      queryClient.setQueryData("monthly-timesheet", context);
    },
    onSettled: () => {
      queryClient.invalidateQueries("monthly-timesheet");
    },
  });
};

export const useDeleteTimesheet = () => {
  const queryClient = useQueryClient();

  return useMutation(timesheeetAPI.deleteTimesheet, {
    onSuccess: () => {
      toast.success("Timesheet deleted successfully!", toastAlertOptions);
    },
    onMutate: async (newtimesheet) => {
      await queryClient.cancelQueries("monthly-timesheet");
      const oldtimesheet = queryClient.getQueryData("monthly-timesheet");
      queryClient.setQueryData("monthly-timesheet", (oldQueryTimesheet) => {
        oldQueryTimesheet.time_data_conso.forEach((item) =>
          item.time.forEach((subItem, index) => {
            if (subItem.id === newtimesheet.id) {
              return item.time.splice(index, 1);
            }
          })
        );

        const time_data = oldQueryTimesheet.time_data.map((item, ind) =>
          item.id === newtimesheet.id
            ? oldQueryTimesheet.time_data.splice(ind, 0)
            : { ...item }
        );
        return {
          employee: oldQueryTimesheet.employee,
          time_data_conso: oldQueryTimesheet.time_data_conso,
          time_data: time_data,
        };
      });
      // if mutation is error
      return oldtimesheet;
    },
    onError: (_error, _timesheet, context) => {
      toast.error("Something went wrong, please try again", toastAlertOptions);
      queryClient.setQueryData("monthly-timesheet", context);
    },
    onSettled: () => {
      queryClient.invalidateQueries("monthly-timesheet");
    },
  });
};

export const useEditTimesheet = () => {
  const { projectCode, projectActivity } = useContext(GlobalContext);
  const queryClient = useQueryClient();

  return useMutation(timesheeetAPI.updateTimesheetEntry, {
    onSuccess: (res) => {
      toast.success("Timesheet updated successfully!", toastAlertOptions);
    },
    onMutate: async (newtimesheet) => {
      await queryClient.cancelQueries("monthly-timesheet");
      const code = projectCode.find((code) => code.id === newtimesheet.code);
      const activity = projectActivity.find(
        (project) => project.id === newtimesheet.activity
      );
      const mutation = {
        id: newtimesheet.id,
        created: format(new Date(newtimesheet.date), "MMM dd, yyyy"),
        hours: newtimesheet.hours,
        status: code.restricted === 1 ? 2 : 1,
        milestone: newtimesheet.sprint,
        comment: newtimesheet.details,
        time_activity: activity,
        time_code: code,
      };

      const oldtimesheet = queryClient.getQueryData("monthly-timesheet");
      queryClient.setQueryData("monthly-timesheet", (oldQueryTimesheet) => {
        let findID = oldQueryTimesheet.time_data_conso.map((val, ind) => {
          return {
            ...val,
            time: val.time.map((subVal) =>
              subVal.id === newtimesheet.id ? mutation : { ...subVal }
            ),
          };
        });
        const time_data = oldQueryTimesheet.time_data.map((item, ind) =>
          item.id === newtimesheet.id ? mutation : { ...item }
        );
        return {
          employee: oldQueryTimesheet.employee,
          time_data_conso: findID,
          time_data: time_data,
        };
      });
      // if mutation is error
      return oldtimesheet;
    },
    onError: (_error, _timesheet, context) => {
      toast.error("Something went wrong, please try again", toastAlertOptions);
      queryClient.setQueryData("monthly-timesheet", context);
    },
    onSettled: () => {
      queryClient.invalidateQueries("monthly-timesheet");
    },
  });
};
