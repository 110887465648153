import React, { useState } from "react";
import { Modal, Box, TextField, Button, Grid, Typography } from "@mui/material";
import api from "../../../../api/Department";
import ColorPicker from "../../../Settings/CompanySettings/containers/general-identity/component/color-picker";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 450,
  maxHeight: 600,
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const AddDeptModal = ({
  open,
  handleClose,
  setApiRequest,
  refetch,
  departments,
}) => {
  const [name, setName] = useState("");
  const [short_name, setShortName] = useState("");
  const [description, setDescription] = useState("");
  const [color, setColor] = useState("");
  const [loading, setLoading] = useState(false);
  const HandleRequestAddDepartment = async () => {
    setLoading(true);
    const inputs = { name, short_name, description, color };
    const request = await api.AddDepartment(inputs);
    if (request.status === "success") {
      setApiRequest("Department Added Successfully");
      refetch();
      handleCancel();
    }
    setLoading(false);
  };

  const CheckDeptIfExist = (input, field) => {
    return departments.find((dept) => dept[field] === input);
  };

  const handleCancel = () => {
    setName("");
    setShortName("");
    setDescription("");
    setColor("");
    handleClose();
  };
  return (
    <Modal
      hideBackdrop
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h4" mb={5}>
          Add Department
        </Typography>
        <TextField
          value={name}
          variant="standard"
          label="Name"
          fullWidth
          sx={{ marginBottom: "15px" }}
          onChange={(e) => setName(e.target.value)}
          error={CheckDeptIfExist(name, "name") && true}
          helperText={
            (CheckDeptIfExist(name, "name") &&
              "The name has already been taken.") ||
            (name.length >= 30 && "the max length of 30 characters is reached")
          }
          inputProps={{ maxLength: 30 }}
        />
        <TextField
          value={short_name}
          variant="standard"
          label="Short name"
          fullWidth
          sx={{ marginBottom: "15px" }}
          onChange={(e) => setShortName(e.target.value)}
          error={CheckDeptIfExist(short_name, "short_name") && true}
          helperText={
            (CheckDeptIfExist(short_name, "short_name") &&
              "The short name has already been taken.") ||
            (short_name.length >= 15 &&
              "the max length of 15 characters is reached")
          }
          inputProps={{ maxLength: 15 }}
        />
        <ColorPicker
          color={color}
          onChange={(e) => setColor(e)}
          onChangeText={(e) => setColor(e.input.target)}
          reused={"Department"}
        />
        {CheckDeptIfExist(color, "color") && (
          <Typography sx={{ fontSize: "0.75rem", color: "red" }} mb={2}>
            {" "}
            This color is already taken
          </Typography>
        )}
        <TextField
          value={description}
          variant="standard"
          label="Description"
          fullWidth
          sx={{ marginBottom: "50px" }}
          onChange={(e) => setDescription(e.target.value)}
          helperText={
            description.length >= 80 &&
            "the max length of 80 characters is reached"
          }
          inputProps={{ maxLength: 80 }}
        />
        <Grid container sx={{ justifyContent: "end" }} spacing={3}>
          <Grid item>
            <Button
              className="adjust-w cancel-button"
              variant="contained"
              sx={{ padding: "1px 10px 1px 10px" }}
              onClick={handleCancel}
            >
              {" "}
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={
                loading ||
                name === "" ||
                short_name === "" ||
                color === "" ||
                CheckDeptIfExist(short_name, "short_name") ||
                CheckDeptIfExist(name, "name") ||
                CheckDeptIfExist(color, "color")
              }
              className="adjust-w timest-button"
              variant="contained"
              sx={{ padding: "1px 15px 1px 15px" }}
              onClick={() => HandleRequestAddDepartment()}
            >
              {" "}
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddDeptModal;
