import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

const TimesheetWeeklyReportTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    paddingLeft: "24px",
    paddingRight: "24px",
  },
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    backgroundColor: "#FFF",
    color: "#21293C",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#343a40",
    fontSize: 14,
  },
}));

export default TimesheetWeeklyReportTableCell;
