export const addObject = (data) =>
  data.map((item) =>
    Object.fromEntries([
      ["name", item],
      ["shortname", item || ""],
    ])
  );

export const rangeSelection = [
  "This Week",
  "Last Week",
  "Last 2 Weeks",
  "This month",
  "Last month",
  "Last 2 months",
  "Custom",
];
