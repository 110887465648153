import * as React from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

// Tab selection -  Reusable tabs pass array of
// selections to tablist
// value is for index

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "white",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "rgba(255, 255, 255, 0.7)",
    "&.Mui-selected": {
      color: "#fff",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

const TabSelection = ({ tabList, selectedTab, setSelectedTab }) => {
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ backgroundColor: "green", margin: "1rem" }}>
      <StyledTabs
        value={selectedTab}
        onChange={handleChange}
        aria-label="styled tabs example"
        sx={{ width: "100%" }}
      >
        {tabList.map((elem, index) => (
          <StyledTab
            key={elem.label}
            label={elem.label}
            sx={{ width: elem.width, maxWidth: elem.maxWidth }}
          />
        ))}
      </StyledTabs>
      <Box sx={{ p: 1 }} />
    </Box>
  );
};

export default TabSelection;
