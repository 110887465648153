export const clientCodeHeader = [
  "company_name",
  "company_code",
  "primary_email",
  "primary_firstname",
  "primary_lastname",
  "action",
];

export const replaceLabel = (item) => {
  switch (item) {
    case "primary_firstname":
      return "First name";
    case "primary_lastname":
      return "Last name";
    default:
      return item.replace(/_/g, " ");
  }
};

export const addObject = (data, replace) =>
  data.map((item) =>
    Object.fromEntries([
      ["name", replace(item)],
      ["shortname", item || ""],
    ])
  );
export const removeProperty = (obj, property) => {
  const { [property]: unused, ...rest } = obj;

  return rest;
};

export const addValue = (data) =>
  ["id"]
    .concat(clientCodeHeader)
    .slice(0, -1)
    .map((item) =>
      Object.fromEntries([
        ["name", item || ""],
        ["value", data[item] || ""],
      ])
    );
