import {
  Button,
  Grid,
  Typography,
  Card,
  Box,
  Skeleton,
  Tooltip,
} from "@mui/material";
import React from "react";
import SelectDropDownUI from "../../component/DropDown";
import Accordy from "../../component/Colleagues/Accordy";
import DatePickerComponent from "../../component/DatePicker";
import SwitchComponent from "../../component/Switch";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeptFilterButtons from "../../component/Colleagues/DeptFilterButtons";
import TimeInOut from "../../component/Colleagues/timeInOut";
import TableView from "../../component/TableView";
import { AlertComponent } from "../../component/Alerts";
import hooks from "../../hooks/react-query/queryHooks";
import ColleagueCard from "../../component/Colleagues/ColleagueCard";
import Loader from "../../component/Loader";
import Tiles from "../../component/Colleagues/tiles";
import Dialog from "../../component/Colleagues/Dialog";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchComponent from "../../component/Colleagues/SearchComponent";

const Colleagues = () => {
  const { format } = require("date-fns");
  const [filters, setFilters] = React.useState("");
  const [sorts, setSorts] = React.useState("earliest");
  const [inOutFilter, setInOutFilter] = React.useState("all");
  const [dateSelected, setDateSelected] = React.useState(
    format(new Date(), "yyyy/MM/dd")
  );
  const [tableview, setTableView] = React.useState(false);
  const [expand, setExpand] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [expandGrid, setExpandgrid] = React.useState(6);
  const [expandItem1, setExpanditem1] = React.useState(6);
  const [expandItem2, setExpanditem2] = React.useState(3);
  const [tabValue, setTabvalue] = React.useState("ALL");
  const [commentModal, openComment] = React.useState(false);
  const [comment, setComment] = React.useState();
  const [param, setParam] = React.useState();
  const [errors, setErrors] = React.useState();
  const [tiles, setTiles] = React.useState(4);
  const [skeletonTiles, setSkeletontiles] = React.useState(4);
  const [search, setSearch] = React.useState("");
  const [keyword, setKeyword] = React.useState("");

  const handleExpand = () => {
    if (expand) {
      setExpandgrid(6);
      setExpand(false);
      setExpanditem1(6);
      setExpanditem2(3);
    } else {
      setExpandgrid(12);
      setExpand(true);
      setExpanditem1(4);
      setExpanditem2(4);
    }
  };

  const handleRefresh = () => {
    setRefresh(true);
    refetch();
  };
  const handleDatePicker = (value) => {
    setDateSelected(format(value, "yyyy/MM/dd"));
  };

  const [skillSet, setSkillset] = React.useState(null);

  const { status: skillStats, data: skills } = hooks.useSkills();

  const {
    fetchNextPage, //function
    hasNextPage, // bolean
    isFetchingNextPage, //bolean
    data,
    isLoading,
    isFetching,
    refetch,
  } = hooks.useColleagues(dateSelected, filters, sorts, inOutFilter, search);

  if (skillStats === "success") {
    if (skillSet === null) {
      setSkillset([
        { value: 90, text: skills[0]?.name, bg: "#79cac2", bgl: "#9dd9d4" },
        { value: 75, text: skills[1]?.name, bg: "#4DB775", bgl: "#79c695" },
        { value: 80, text: skills[2]?.name, bg: "#5A73D9", bgl: "#7c8ed3" },
        { value: 70, text: skills[3]?.name, bg: "#FCAD5D", bgl: "#f9c188" },
        { value: 80, text: skills[4]?.name, bg: "#CC91CD", bgl: "#d6b5d7" },
        { value: 87, text: skills[5]?.name, bg: "#D4597F", bgl: "#e092ab" },
      ]);
    }
  }

  const intObserver = React.useRef();
  const lastCardRef = React.useCallback(
    (card) => {
      if (isFetchingNextPage) return;

      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver((cards) => {
        if (cards[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });

      if (card) intObserver.current.observe(card);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  const skeletonLoader = (data) =>
    Array.from(new Array(data)).map((item, key) => (
      <Grid item id={item} key={key} xs={12} sm={6} md={skeletonTiles}>
        <Skeleton
          sx={{
            borderRadius: "10px",
          }}
          variant="rectangular"
          height={220}
        />
      </Grid>
    ));

  const { data: reactionData, mutate, reset } = hooks.useUpdateReaction();

  const handleReaction = () => {
    reset();
  };

  const submitReaction = (action, id, page) => {
    const dataOpt = {
      action: action,
      api: true,
      date: dateSelected,
      emp: id,
    };
    mutate({ page: page, option: dataOpt });
  };

  const { mutate: commendMutate } = hooks.useUpdateCommend(
    openComment,
    setComment,
    setErrors
  );
  const formfield = { value: comment, setFormfield: (e) => setComment(e) };

  const handleTabChange = (event, newValue) => {
    setTabvalue(newValue);
  };

  const submitCommend = (action, id, page) => {
    if (!comment) {
      var error = { comment: "comment must not be empty" };
      toast.error(error.comment);
      setErrors(error.comment);
      return;
    }
    const dataOpt = {
      action: action,
      api: true,
      date: dateSelected,
      id: id,
      remarks: comment,
    };
    commendMutate({ page: page, option: dataOpt });
  };

  const handleOpen = (action, id, page) => {
    var user = JSON.parse(localStorage.getItem("user"));
    if (user.isAdmin) {
      setParam({ action: action, id: id, page: page });
      openComment(true);
    } else {
      var error = { commend: "Only Admins can commend" };
      toast.error(error.commend);
    }
  };

  const LoadingIndicator = () => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        mt: "30px",
      }}
    >
      <Typography>Loading</Typography>
      <Box className="dot1"></Box>
      <Box className="dot2"></Box>
      <Box className="dot3"></Box>
    </Box>
  );
  return (
    <>
      <Dialog
        open={commentModal}
        handleSubmit={submitCommend}
        handleClose={() => {
          setComment("");
          openComment(false);
          setErrors("");
        }}
        param={param}
        formfield={formfield}
        errors={errors}
      />
      <div
        className="myDiv"
        style={{ padding: "5px 10px", width: "100%", height: "fit-content" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "0px 15px",
          }}
        >
          <Tooltip
            sx={{ cursor: "context-menu" }}
            title="Show all employee time in and time out"
            placement={"right"}
            arrow
          >
            <Typography variant="h4" component="div">
              Colleagues
            </Typography>
          </Tooltip>
          <Box
            sx={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
            }}
          >
            <SwitchComponent onAndoff={setTableView} />
            <Button
              startIcon={<RefreshIcon />}
              variant="text"
              sx={{ padding: "5px", textTransform: "none", color: "#00C292" }}
              onClick={handleRefresh}
            >
              <Typography sx={{ fontSize: "0.78rem" }}>Refresh</Typography>
            </Button>
          </Box>
        </Box>
        <Card
          sx={{
            borderRadius: "10px",
            padding: "14px",
            margin: "15px",
            boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
            position: "sticky",
            top: "0",
            zIndex: "1",
            "@media(max-width: 768px)": {
              position: "relative",
            },
          }}
        >
          <Grid container rowSpacing={2}>
            <Grid
              item
              xs={12}
              lg={12}
              container
              sx={{ justifyContent: "space-between" }}
            >
              <DeptFilterButtons
                setFilters={setFilters}
                setInOutFilter={setInOutFilter}
                tabValue={tabValue}
                handleTabChange={handleTabChange}
              />
            </Grid>
            <Grid
              item
              lg={12}
              container
              sx={{
                justifyContent: "right",
              }}
            >
              <SearchComponent
                setSearch={setSearch}
                setPage={(e) => {}}
                isFetching={false}
                keyword={keyword}
                setKeyword={setKeyword}
              />
              <Tiles
                tiles={tiles}
                setTiles={setTiles}
                setSkeletontiles={setSkeletontiles}
              />
              <TimeInOut
                inOutFilter={inOutFilter}
                setInOutFilter={setInOutFilter}
              />
              <SelectDropDownUI sorts={sorts} setSorts={setSorts} />
              <DatePickerComponent
                handleDatePicker={handleDatePicker}
                dateValue={dateSelected}
                disableFuture={true}
              />
              <Accordy handleExpand={handleExpand} />
            </Grid>
          </Grid>
        </Card>
        <Box
          sx={{
            margin: "25px 15px",
          }}
        >
          {isLoading && LoadingIndicator()}
          {isFetchingNextPage && LoadingIndicator()}
          {refresh && <Loader isLoading={isFetching} />}
          {tableview ? (
            data && (
              <TableView
                tableData={data.pages}
                dateSelected={dateSelected}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage}
                inOutFilter={inOutFilter}
              />
            )
          ) : (
            <>
              <Grid
                container
                rowSpacing={4}
                columnSpacing={5}
                sx={{ mt: "-25px" }}
              >
                {isLoading && skeletonLoader(15)}
                {data &&
                  skillSet &&
                  data?.pages.map((page, i) => {
                    return page.data.map((colleague, index) => {
                      if (page.data.length === index + 1) {
                        return (
                          <ColleagueCard
                            ref={lastCardRef}
                            key={index}
                            colleague={colleague}
                            expand={expand}
                            expandGrid={expandGrid}
                            expandItem1={expandItem1}
                            expandItem2={expandItem2}
                            skillSet={skillSet}
                            submitReaction={submitReaction}
                            page={i}
                            inOutFilter={inOutFilter}
                            handleOpen={handleOpen}
                            tiles={tiles}
                          />
                        );
                      }
                      return (
                        <ColleagueCard
                          key={index}
                          colleague={colleague}
                          expand={expand}
                          expandGrid={expandGrid}
                          expandItem1={expandItem1}
                          expandItem2={expandItem2}
                          skillSet={skillSet}
                          submitReaction={submitReaction}
                          page={i}
                          inOutFilter={inOutFilter}
                          handleOpen={handleOpen}
                          tiles={tiles}
                        />
                      );
                    });
                  })}
                {isFetchingNextPage && skeletonLoader(6)}
              </Grid>
            </>
          )}
          {isLoading && LoadingIndicator()}
          {isFetchingNextPage && LoadingIndicator()}
        </Box>
        {reactionData && (
          <AlertComponent
            severity={reactionData.data.status}
            message={reactionData.data.msg}
            handleOpenReaction={handleReaction}
          />
        )}
      </div>
    </>
  );
};

export default Colleagues;
