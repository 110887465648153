export const getValidTimesheetDropdown = () => {
  let values = [];
  let value = 0;
  let name = 0;
  for (var i = 1; i <= 32; i++) {
    if (i === 1) {
      values.push({
        value: parseFloat((value += 0)).toFixed(2),
        name: parseFloat((name += 0)).toFixed(2),
      });
    }
    values.push({
      value: parseFloat((value += 0.25)).toFixed(2),
      name: parseFloat((name += 0.25)).toFixed(2),
    });
  }

  return values;
};
export const getValidTimesheetAutoComplete = () => {
  let values = [];
  let label = 0;

  for (var i = 1; i <= 32; i++) {
    if (i === 1) {
      values.push({
        label: parseFloat((label += 0)).toFixed(2),
      });
    }
    values.push({
      label: parseFloat((label += 0.25)).toFixed(2),
    });
  }

  return values;
};

export const getTotalTimesheet = (array) => {
  const excludeRestrictedCode =
    array?.filter(function (item) {
      return item.status !== 2;
    }) || [];
  const sumPerDay = excludeRestrictedCode.reduce((acc, item) => {
    acc[item.created] = acc[item.created]
      ? { ...item, hours: +item.hours + +acc[item.created].hours }
      : item;
    return acc;
  }, {});
  return sumPerDay;
};

export const getWeeklyConsoUI = (array, weekDates) => {
  let formatDate = [];
  weekDates.map((date) => formatDate.push(+new Date(date)));
  const data = array?.map((element) => {
    return {
      ...element,
      time: element.time?.filter((timesheet) =>
        formatDate.includes(+new Date(timesheet.created))
      ),
    };
  });
  const removeEmpty = data.filter((fd) => fd.time.length >= 1);
  return removeEmpty;
};

export const getWeeklyTimesheet = (array, weekDates) => {
  let formatDate = [];
  weekDates.map((date) => formatDate.push(+new Date(date)));
  return array.filter((timesheet) =>
    formatDate.includes(+new Date(timesheet.created))
  );
};

export const getHours = (array, weekDates) => {
  const timesheet = getWeeklyTimesheet(array, weekDates);

  const excludeRestrictedCode = timesheet?.filter((item) => {
    return item.status !== 2;
  });

  let sumPerDay = excludeRestrictedCode.reduce((acc, item) => {
    acc[item.created] = acc[item.created]
      ? { ...item, hours: +item.hours + +acc[item.created].hours }
      : item;
    return acc;
  }, {});

  let sortedDate = {};

  Object.keys(sumPerDay)
    .sort((a, b) => {
      return new Date(a) - new Date(b);
    })
    .forEach((key) => {
      sortedDate[key] = sumPerDay[key];
    });

  const todaytimesheet = Object.values(sumPerDay)?.filter(
    (timesheet) =>
      +new Date(timesheet.created) === +new Date(new Date().toDateString()) &&
      timesheet.hours
  );

  let arrayOfTotalHours = [
    { hours: null, date: weekDates[0] },
    { hours: null, date: weekDates[1] },
    { hours: null, date: weekDates[2] },
    { hours: null, date: weekDates[3] },
    { hours: null, date: weekDates[4] },
    { hours: null, date: weekDates[5] },
    { hours: null, date: weekDates[6] },
  ];

  const weeklyHours = Object.values(sortedDate).map(
    (timesheet) => timesheet.hours
  );

  const weeklyHoursArray = arrayOfTotalHours.map((obj) => {
    return {
      ...obj,
      hours: Object.values(sortedDate).find(
        (timesheet) => +new Date(obj.date) === +new Date(timesheet.created)
      )?.hours,
    };

    //  +new Date(obj.date) === +new Date(timesheet.created)&&{...obj, hours: timesheet.hours}
  });

  const TotalHoursWeekly =
    weeklyHours.length !== 0
      ? weeklyHours.reduce(
          (partialSum, a) => parseFloat(partialSum) + parseFloat(a)
        )
      : "0";

  return {
    weekly: weeklyHoursArray,
    today: todaytimesheet.length !== 0 ? todaytimesheet[0].hours : "0",
    weeklyTotal: TotalHoursWeekly,
  };
};
