import React, { useContext } from "react";
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Grid,
  Grow,
  FormHelperText,
  TextField,
  IconButton,
  Box,
  Slider,
  Input,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CkEditor from "./dashboardCkEditor";
import { sliderMarks } from "./timePicker";
import { format, parseISO } from "date-fns";
import EntryAutocomplete from "./entryAutocomplete";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { useForm, Controller } from "react-hook-form";
import { TimesheetContext } from "../../globalstate/UserContext/timesheetcontext";
import { useAddTimesheet } from "../../hooks/react-query/timesheet";
import { v4 as uuidv4 } from "uuid";
import { startOfWeek, isBefore } from "date-fns";

const EntryModal = ({
  entry,
  handleEntryModal,
  employee,
  projectCode,
  projectActivity,
  timesheetIndex,
  submitUpdatedEntry,
  deleteEntry,
  addEntry,
  open,
}) => {
  const { refetchWeeklyTimesheet, refetchMonthlyTimesheet } =
    useContext(TimesheetContext);
  const user = employee;
  const timesheetEntryID = user?.time_data[timesheetIndex]?.id;
  const { mutate } = useAddTimesheet();

  const submitDeletedentry = () => {
    const deleteEntries = {
      id: timesheetEntryID,
    };
    deleteEntry(deleteEntries);
  };

  const createObject = (CodeActivity) => {
    return {
      id: CodeActivity?.id,
      name: CodeActivity?.name,
      description: CodeActivity?.description,
    };
  };
  const comment =
    timesheetIndex !== null ? user?.time_data[timesheetIndex]?.comment : "";
  const getCodeObject = createObject(
    user?.time_data[timesheetIndex]?.time_code
  );
  const getActivityObject = createObject(
    user?.time_data[timesheetIndex]?.time_activity
  );
  const codeObj = timesheetIndex === null ? null : getCodeObject;
  const activityObj = timesheetIndex === null ? null : getActivityObject;

  const handleActiveThumbtext = (activeThumb) => {
    const findLabel = sliderMarks.find(
      (element) => element.value === activeThumb
    );
    return findLabel.label;
  };

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      selectDate:
        timesheetIndex !== null
          ? new Date(user?.time_data[timesheetIndex]?.created_at)
          : new Date(),
      hours:
        timesheetIndex !== null
          ? parseFloat(user?.time_data[timesheetIndex]?.hours)
          : 0.25,
      sprint:
        timesheetIndex !== null
          ? user?.time_data[timesheetIndex]?.milestone
          : "",
      details: comment,
      selectedTime:
        timesheetIndex !== null
          ? handleActiveThumbtext(
              parseFloat(user?.time_data[timesheetIndex]?.hours)
            )
          : handleActiveThumbtext(0.25),
      formatMonth:
        timesheetIndex !== null
          ? format(parseISO(user?.time_data[timesheetIndex]?.created_at), "LLL")
          : format(new Date(), "LLL"),
      formatDay:
        timesheetIndex !== null
          ? format(parseISO(user?.time_data[timesheetIndex]?.created_at), "dd")
          : format(new Date(), "dd"),
      code: codeObj ? codeObj?.id : null,
      activity: activityObj ? activityObj.id : null,
    },
  });

  const onSubmit = async (data) => {
    const isLate = isBefore(
      new Date(data.selectDate),
      startOfWeek(new Date(), { weekStartsOn: 1 })
    );
    if (timesheetIndex !== null) {
      const updatedEntries = {
        id: timesheetEntryID,
        code: data.code,
        activity: data.activity,
        date: format(data.selectDate, "yyyy-MM-dd H:mm:ss"),
        hours: data.hours,
        comment: data.details,
        sprint: data.sprint,
      };
      submitUpdatedEntry(updatedEntries);
    } else {
      const id = uuidv4();

      const addEntries = {
        action: "single",
        code: data.code,
        activity: data.activity,
        date: format(data.selectDate, "EEE, LLL d, yyyy"),
        hours: data.hours,
        details: data.details,
        sprint: data.sprint,
        late: isLate ? 1 : 0,
        id,
      };
      mutate(addEntries);
      refetchWeeklyTimesheet();
      refetchMonthlyTimesheet();
      handleEntryModal(null);
    }
  };
  const loadingButtons = (open, margin, minW, icon, name) => (
    <LoadingButton
      loading={open}
      size="small"
      className="adjust-w timest-button"
      disableElevation
      variant="contained"
      sx={{ marginLeft: margin, minWidth: minW }}
      type="submit"
      startIcon={icon}
    >
      {name}
    </LoadingButton>
  );
  const formHelpers = (name) => (
    <FormHelperText
      error
      sx={{
        fontSize: "0.6rem",
        position: "absolute",
        top: 0,
        right: 0,
      }}
    >
      {name}
    </FormHelperText>
  );
  return (
    <Modal
      open={entry}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      disableEnforceFocus
    >
      <Grow
        in={entry}
        style={{ transformOrigin: "0 0 0" }}
        {...(entry ? { timeout: 500 } : {})}
        unmountOnExit
      >
        <Card
          sx={{
            width: "80%",
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardHeader
              title={
                <Typography variant="h3" className="h3-bar">
                  Timesheet Entry
                </Typography>
              }
              action={
                <IconButton
                  onClick={() => {
                    handleEntryModal(null);
                  }}
                  sx={{ mr: "5px" }}
                >
                  <CloseOutlinedIcon sx={{ width: "17px", height: "17px" }} />
                </IconButton>
              }
              sx={{
                paddingBottom: "10px",
              }}
            />
            <CardContent className="cc-static-dp">
              <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid item xs={12} lg={4} container rowSpacing={2}>
                  <Grid item xs={12}>
                    <Box className="typ-over">
                      <Typography
                        variant="overline"
                        sx={{ paddingLeft: "10px", alignSelf: "center" }}
                      >
                        Selected Date
                      </Typography>
                    </Box>
                    <Box className="picker-container">
                      <Box className="picker-sidebar">
                        <Controller
                          name="formatMonth"
                          control={control}
                          render={({ field: { value } }) => (
                            <Typography
                              variant="string"
                              color="text.secondary"
                              sx={{ fontSize: "1.125rem" }}
                            >
                              {value}
                            </Typography>
                          )}
                        />
                        <Controller
                          name="formatDay"
                          control={control}
                          render={({ field: { value } }) => (
                            <Typography
                              variant="h4"
                              className="typ-h4-d"
                              sx={{ pl: "inherit" }}
                            >
                              {value}
                            </Typography>
                          )}
                        />
                      </Box>
                      <Box className="picker-d">
                        <Controller
                          control={control}
                          name="selectDate"
                          render={({ field: { onChange, ...field } }) => (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                {...field}
                                onChange={(event) => {
                                  onChange(event);
                                  setValue("formatMonth", format(event, "LLL"));
                                  setValue("formatDay", format(event, "dd"));
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    sx={{
                                      paddingLeft: "13px",
                                      marginBottom: "2px",
                                    }}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={4}
                  container
                  rowSpacing={"18px"}
                  columnSpacing={1}
                >
                  <Grid item xs={6} lg={12}>
                    <TextField
                      size="small"
                      fullWidth
                      label="Department"
                      variant="outlined"
                      disabled
                      value={user?.employee?.department?.name}
                      sx={{ fontSize: "0.75" }}
                    />
                  </Grid>
                  <Grid item xs={6} lg={12} sx={{ position: "relative" }}>
                    {errors.sprint?.type === "required" &&
                      formHelpers("Please input Milestone/Sprint")}
                    <Controller
                      name="sprint"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          fullWidth
                          label="Milestone"
                          variant="outlined"
                          sx={{ fontSize: "0.75" }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={4}
                  container
                  rowSpacing={"18px"}
                  columnSpacing={1}
                >
                  <Grid item xs={6} lg={12} sx={{ position: "relative" }}>
                    {errors.code?.type === "required" &&
                      formHelpers("Please select project code")}
                    <Controller
                      control={control}
                      name="code"
                      rules={{
                        required: true,
                      }}
                      render={({ field: { ref, onChange, ...field } }) => (
                        <EntryAutocomplete
                          ref={ref}
                          onChange={onChange}
                          field={field}
                          optionData={projectCode}
                          label="Project code"
                          defaultData={codeObj}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} lg={12} sx={{ position: "relative" }}>
                    {errors.activity?.type === "required" &&
                      formHelpers("Please select project activity")}
                    <Controller
                      control={control}
                      name="activity"
                      rules={{
                        required: true,
                      }}
                      render={({ field: { ref, onChange, ...field } }) => (
                        <EntryAutocomplete
                          ref={ref}
                          onChange={onChange}
                          field={field}
                          optionData={projectActivity}
                          label="Project activity"
                          defaultData={activityObj}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={10} lg={10}>
                  <Box sx={{ margin: "8px 7px 0px 5px" }}>
                    <Controller
                      control={control}
                      name="hours"
                      render={({ field: { onChange, ...field } }) => (
                        <Slider
                          {...field}
                          onChange={(event) => {
                            const sliderMarkValue = event.target.value;
                            onChange(sliderMarkValue);
                            setValue(
                              "selectedTime",
                              handleActiveThumbtext(sliderMarkValue)
                            );
                          }}
                          valueLabelDisplay="on"
                          step={0.25}
                          marks
                          min={0.25}
                          max={8}
                          color="FlignoGreen"
                          className="time-slider"
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} lg={2}>
                  <Controller
                    name="selectedTime"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        sx={{
                          fontSize: "0.775rem",
                          fontWeight: "600",
                          "input.MuiInput-input": {
                            textAlign: "center",
                          },
                          pointerEvent: "none",
                        }}
                      />
                    )}
                  />
                  <FormHelperText
                    sx={{ fontSize: "0.7rem", textAlign: "center" }}
                  >
                    Selected Time
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} lg={12}>
                  {errors.details?.type === "required" && (
                    <FormHelperText
                      error
                      sx={{
                        fontSize: "0.7rem",
                        textAlign: "center",
                      }}
                    >
                      Please input your daily comments
                    </FormHelperText>
                  )}
                  <CkEditor
                    comment={comment}
                    register={register}
                    setValue={setValue}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions sx={{ margin: "0px 10px 10px" }}>
              {timesheetIndex !== null ? (
                <>
                  <LoadingButton
                    loading={open}
                    size="small"
                    className="adjust-w cancel-button"
                    disableElevation
                    variant="contained"
                    sx={{ marginLeft: "auto", minWidth: "150px" }}
                    onClick={() => {
                      submitDeletedentry();
                    }}
                    startIcon={<DeleteForeverOutlinedIcon />}
                  >
                    Delete
                  </LoadingButton>
                  {loadingButtons(
                    open,
                    "15px !important",
                    "150px",
                    <LibraryAddOutlinedIcon />,
                    "Update"
                  )}
                </>
              ) : (
                loadingButtons(
                  open,
                  "auto",
                  "150px",
                  <SaveOutlinedIcon />,
                  "Submit"
                )
              )}
            </CardActions>
          </form>
        </Card>
      </Grow>
    </Modal>
  );
};

export default EntryModal;
