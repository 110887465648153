import React from "react";
import {
  Modal,
  Card,
  CardContent,
  Grow,
  CardActions,
  CardHeader,
} from "@mui/material";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import { grey } from "@material-ui/core/colors";
const Confirm = ({
  open,
  handleConfirm,
  handleClose,
  message,
  title,
  submitting,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Grow
        in={open}
        style={{ transformOrigin: "0 0 0" }}
        {...(open ? { timeout: 500 } : {})}
        unmountOnExit
      >
        <Card className="login-modal">
          <CardHeader sx={{ pb: 1 }} title={title} />
          <CardContent sx={{ color: grey[500], pt: 0 }}>{message}</CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton
              loading={submitting}
              size="small"
              onClick={handleConfirm}
              sx={{ textTransform: "none", px: "16px", py: "6px" }}
            >
              Confirm
            </LoadingButton>
            <Button
              size="small"
              variant="contained"
              sx={{
                "&:hover": { bgcolor: "#ca5764" },
                bgcolor: "#D06A76",
                textTransform: "none",
                px: "16px",
                py: "6px",
              }}
              onClick={handleClose}
              autoFocus
            >
              Cancel
            </Button>
          </CardActions>
        </Card>
      </Grow>
    </Modal>
  );
};

export default Confirm;
