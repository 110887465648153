import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TablePagination, Tooltip, Typography } from "@mui/material";
import {
  useEmployeeApprovers,
  useEmployeeRequest,
  useEmployeeTableHeader,
} from "../../hooks/react-query/EmployeeRequest";
import { getAuth } from "../../hooks/customHooks/auth";
import { deleteRequest, getEmployeeRequest } from "../../api/userrequest";
import TablePaginationController from "../../component/UserRequests/MyRequestComponent/Table/TablePaginationControllerSkeleton";
import TableSkeleton from "../../component/UserRequests/MyRequestComponent/Table/TableSkeleton";
import { useMutation, useQueryClient } from "react-query";
import DeleteRequestModal from "../../component/UserRequests/MyRequestComponent/DeleteRequestModal";
import ApproverContainer from "../../component/UserRequests/MyRequestComponent/ApproverContainer";
import SearchComponent from "../../component/UserRequests/MyRequestComponent/SearchComponent";
import TableRowHeader from "../../component/UserRequests/MyRequestComponent/Table/TableRowHeader";
import TableBodyData from "../../component/UserRequests/MyRequestComponent/Table/TableBodyData";
import TableBodyDataOption from "../../component/UserRequests/MyRequestComponent/Table/TableBodyDataOption";
import { Box } from "@mui/system";
import TablePaginationActions from "../../component/UserRequests/MyRequestComponent/Table/TablePaginationActions.js";
import EditRequestModal from "../../component/UserRequests/MyRequestComponent/EditRequestModal";
import Stack from "@mui/material/Stack";
import RefreshButton from "../../component/UserRequests/MyRequestComponent/RefreshButton";
import LinearProgress from "@mui/material/LinearProgress";
import RequestTabination from "../../component/UserRequests/MyRequestComponent/RequestTabination";
import hooks from "../../hooks/react-query/queryHooks";
import EmptyTableComponent from "../../component/EmptyTableComponent";
// import { toast } from "react-toastify";
// import { toastAlertOptions } from "../../hooks/customHooks/toastAlertOptions";
import filterDataToTable from "../../helper/MyRequest/filterDataToTable";
import { SortDescNum } from "../../helper/sort";
import Documents from "../../component/Requests/Documents";

const Myrequests = () => {
  const queryClient = useQueryClient();
  const [select, setSelect] = useState("Leave");
  const user = getAuth();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [sortBy] = useState("");
  const [search, setSearch] = useState("");
  const [type, setType] = useState("All");
  const { data: approvers } = useEmployeeApprovers(true);
  const {
    data: employeeRequest,
    isRefetching,
    refetch,
  } = useEmployeeRequest(
    user.employee.id,
    page,
    search,
    size,
    sortBy,
    select,
    type
  );
  const { data: columns } = useEmployeeTableHeader(select);
  const { data: leaveTypeName } = hooks.useLeaveType(user?.employee?.id);
  const [notLoading, setNotLoading] = useState(true);
  const [notLoadingDelete, setNotLoadingDelete] = useState(true);
  const [toDeleteData, setToDeleteData] = useState("");
  const [toDeleteType, setToDeleteType] = useState("");
  const [open, setOpen] = useState(false);

  const [editId, setEditId] = useState("");
  const [editType, setEditType] = useState("");
  const [editConverted, setEditConverted] = useState(0);
  const [editFrom, setEditFrom] = useState("");
  const [editTo, setEditTo] = useState("");
  const [editWorkplace, setEditWorkplace] = useState("");
  const [editPurpose, setEditPurpose] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const [leaveTypeEdit, setLeaveTypeEdit] = useState("");
  const [editDocument, setEditDocument] = useState();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setNotLoadingDelete(() => true);
  };
  const handleOpenEdit = (
    id,
    type,
    converted,
    from,
    to,
    workplace,
    purpose,
    leaveType
  ) => {
    setEditId(id);
    setEditType(type);
    setEditConverted(converted);
    setEditFrom(from);
    setEditTo(to);
    setLeaveTypeEdit(leaveType);
    setEditWorkplace(workplace);
    setEditPurpose(purpose);
    setOpenEdit(() => true);
    if (select === "Document") {
      const edit = employeeRequest?.data.find((dc) => dc.id === id);
      setEditDocument(edit);
    }
  };

  const handleCloseEdit = () => {
    setOpenEdit(() => false);
  };

  const deleteData = useMutation(
    //After the user confirm the deletion this will execute delete api
    () => deleteRequest(toDeleteData, toDeleteType),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(
          "get-employee-request-data-with-page",
          page,
          search,
          size
        );
        await setNotLoadingDelete(() => true);
        // reset the employee request id and type
        setToDeleteData("");
        setToDeleteType("");
      },
    }
  );

  const changePage = useMutation(
    ({ id, page }) =>
      getEmployeeRequest(id, page, search, size, sortBy, select, type),
    {
      onSuccess: async (newPageData) => {
        setNotLoading(() => true); // This will stop the loading effect
        setPage(newPageData?.current_page || 0);
      },
    }
  );

  const changeType = useMutation(
    ({ id, type }) =>
      getEmployeeRequest(id, page, search, size, sortBy, select, type),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(
          "get-employee-request-data-with-page",
          type
        );
        setNotLoading(() => true); // This will stop the loading effect
        setPage(1);
      },
    }
  );

  const handleChangeType = (type) => {
    if (notLoading) {
      setNotLoading(() => false); //If search is trigger this will disable or loading effect will cover the buttons
      const id = user.employee.id;
      changeType.mutate({ id, type });
    }
  };

  const HandleChangePage = async (event, newPage) => {
    if (notLoading) {
      setNotLoading(() => false); //If search is trigger this will disable or loading effect will cover the buttons
      const id = user.employee.id;
      const page = newPage + 1;
      changePage.mutate({ id, page });
    }
  };

  const handleChangeRowsPerPage = async (event) => {
    const size = event.target.value;
    setSize(size);
  };

  const deleteFunc = (id, type) => {
    // This will open modal confirmation if the user confirm or cancel the action
    setToDeleteData(id);
    setToDeleteType(type);
    handleOpen(); // Open modal
  };

  const confirmDeleteFunc = () => {
    // This will close the modal and execute the delete api
    deleteData.mutate();
    setOpen(false);
    setNotLoadingDelete(() => false); // This will trigger the loading in deletion
  };

  const cancelDeleteFunc = () => handleClose();

  // const myDepartment = JSON.parse(localStorage.getItem("user"));

  // const dept = myDepartment?.employee?.department?.name;
  //RSKYE
  // const handleApproved = () => {
  //   if (dept === "RSKYE") {
  //     return "pending";
  //   }
  //   return "approved";
  // };

  const handleCloseEditDocument = () => {
    setOpenEdit(false);
  };
  return (
    <>
      <Box
        sx={{
          margin: "15px 20px",
          display: "flex",
          justifyContent: "flex-start",
          cursor: "context-menu",
        }}
      >
        <Tooltip title="Manage employees requests" placement={"right"} arrow>
          <Typography variant={"h4"}>My Requests</Typography>
        </Tooltip>
      </Box>
      <RequestTabination
        length={employeeRequest?.data?.length}
        type={type}
        setType={setType}
        setPage={setPage}
        select={select}
        setSelect={setSelect}
      />
      <ApproverContainer approvers={approvers} />
      <Paper sx={{ margin: "15px 20px" }}>
        {isRefetching && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
          sx={{ padding: "20px" }}
        >
          <SearchComponent
            search={search}
            setSearch={setSearch}
            setPage={setPage}
          />
          <RefreshButton setNotLoading={setNotLoading} refetch={refetch} />
        </Stack>
        {employeeRequest?.data && leaveTypeName && notLoading ? (
          <TableContainer>
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{
                p: "0px 40px",
              }}
            >
              <TableHead>
                <TableRowHeader
                  handleChangeType={handleChangeType}
                  type={type}
                  setType={setType}
                  leaveName={leaveTypeName.type}
                  columns={columns}
                />
              </TableHead>

              {employeeRequest?.data?.length > 0 ? (
                <>
                  <TableBody>
                    {SortDescNum(employeeRequest?.data, "id")?.map(
                      (item, index) => {
                        const row = {
                          ...item,
                          type:
                            item.type === "Over Time" ? "Overtime" : item.type,
                        };
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            {columns?.map((column, index) => {
                              let value = row[column.id];
                              value = filterDataToTable(
                                value,
                                column.id,
                                row,
                                select
                              );

                              return column.id !== "action" ? (
                                <TableBodyData
                                  approvers={approvers}
                                  emp={row.employee_id}
                                  row={row}
                                  colId={column.id}
                                  key={index}
                                  id={index}
                                  align={column.align}
                                  formatt={column.format}
                                  value={value}
                                />
                              ) : (
                                // This column is for the edit and delete options
                                <TableBodyDataOption
                                  status={row["status"]}
                                  key={index}
                                  colId={column.id}
                                  rowId={row.id}
                                  rowType={row.type}
                                  deleteFunc={deleteFunc}
                                  notLoadingDelete={notLoadingDelete}
                                  toDeleteData={toDeleteData}
                                  handleOpenEdit={handleOpenEdit}
                                  editType={row.type}
                                  editConverted={row.converted}
                                  editWorkplace={row.workplace || row.location}
                                  editPurpose={row.purpose || row.reason}
                                  editFrom={
                                    row.from || row.date_from || row.date
                                  }
                                  editTo={row.to || row.date_to}
                                  leaveType={row?.leave_type?.name}
                                />
                              );
                            })}
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </>
              ) : (
                <EmptyTableComponent columns={columns} />
              )}
            </Table>
          </TableContainer>
        ) : (
          <TableSkeleton />
        )}
        {employeeRequest && leaveTypeName && notLoading ? (
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={employeeRequest?.total || 0}
            rowsPerPage={employeeRequest?.per_page}
            page={page - 1}
            onPageChange={HandleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            sx={{
              p: "15px !important",
            }}
          />
        ) : (
          <TablePaginationController />
        )}
      </Paper>
      <DeleteRequestModal
        select={select}
        open={open}
        handleClose={handleClose}
        cancelDeleteFunc={cancelDeleteFunc}
        confirmDeleteFunc={confirmDeleteFunc}
      />
      <EditRequestModal
        leaveType={employeeRequest?.data?.leave_type}
        select={select}
        openEdit={openEdit && select !== "Document"}
        handleCloseEdit={handleCloseEdit}
        setOpenEdit={setOpenEdit}
        editId={editId}
        editEmp={user.employee.id}
        editType={editType}
        editConverted={editConverted}
        editFrom={editFrom}
        editTo={editTo}
        editWorkplace={editWorkplace}
        editPurpose={editPurpose}
        search={search}
        leaveTypeEdit={leaveTypeEdit}
        setNotLoadingDelete={setNotLoadingDelete}
        setToDeleteData={setToDeleteData}
      />
      <Documents
        edit={{ isEdit: true, data: editDocument }}
        documentOpen={openEdit && select === "Document"}
        handleDocument={handleCloseEditDocument}
      />
    </>
  );
};

export default Myrequests;
