import Typography from "@mui/material/Typography";
import { Tooltip } from "@mui/material";
import { Box } from "@mui/system";

const PendingTimesheetTitle = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        cursor: "context-menu",
      }}
    >
      <Tooltip
        title={"View employee overtime/undertime pending application"}
        placement={"right"}
        arrow
      >
        <Typography
          variant="h4"
          sx={{ fontSize: "clamp(20px, 5vw, 28px)", color: "#21293C" }}
        >
          Pending Entries
        </Typography>
      </Tooltip>
    </Box>
  );
};

export default PendingTimesheetTitle;
