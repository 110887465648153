import TableRow from "@mui/material/TableRow";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

import StyledTableCell from "../../../UserApplicationMgmt/UserApplications/tableComponents/StyledTableCell";
import TextSkeleton from "../../../UserApplicationMgmt/UserApplications/skeletonComponents/TextSkeleton";

const PendingEntryTableSkeletonLoader = () => {
  return (
    <TableRow>
      {/* Name */}
      <TextSkeleton minWidth={128} maxWidth={164} justifyContent="start" />

      {/* Date */}
      <TextSkeleton minWidth={128} maxWidth={136} />

      {/* Type */}
      <TextSkeleton minWidth={28} maxWidth={36} />

      {/* Code */}
      <TextSkeleton minWidth={48} maxWidth={64} />

      {/* Activity */}
      <TextSkeleton minWidth={28} maxWidth={36} />

      {/* Project Code Status */}
      <TextSkeleton minWidth={32} maxWidth={36} />

      {/* Project Code Monthly Limit */}
      <TextSkeleton minWidth={24} maxWidth={32} />

      {/* Details */}
      <TextSkeleton minWidth={128} maxWidth={156} justifyContent="start" />

      {/* Hours Rendered */}
      <TextSkeleton minWidth={24} maxWidth={32} />

      {/* Actions */}
      <StyledTableCell align="center">
        <Stack
          direction="row"
          spacing={1}
          divider={<Divider orientation="vertical" flexItem />}
          justifyContent="center"
        >
          <Skeleton variant="circular" width={40} height={40} />

          <Skeleton variant="circular" width={40} height={40} />
        </Stack>
      </StyledTableCell>
    </TableRow>
  );
};

export default PendingEntryTableSkeletonLoader;
