import React from "react";
import TextField from "@mui/material/TextField";
const ad = "djadkaw";
const SingleAnswer = ({ answerData }) => {
  return (
    <>
      {answerData[0].map((ad, i) => (
        <TextField
          key={i}
          value={ad}
          fullWidth
          size="small"
          multiline
          inputProps={{
            readOnly: true,
          }}
          sx={{ m: "5px" }}
        />
      ))}
    </>
  );
};

export default SingleAnswer;
