import React, { useState } from "react";
import { TableCell, TextField, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from "@mui/material/CircularProgress";
import DropDownSelect from "../../CustomElement/DropDownSelect";
import DropDownMultiple from "../../CustomElement/DropDownSelectMulti";
import { useEditProjectCode } from "../../../hooks/react-query/Settings/timsheet";
import {
  isJsonString,
  ActiveOrNot,
  RestrictedOrNot,
} from "../../../helper/ProjectCodeSettings";
import RemarksModal from "./RemarksModal";
import ChangeOpenModal from "./ChangeOpenModal";

const EditRow = ({ data, clients, types, departments, setEditInd }) => {
  const { mutate: EditProjectCode, isLoading } = useEditProjectCode();
  const initEditData = {
    name: data.name,
    description: data.description,
    client_id: data.client_id,
    type: data.type,
    key: data.key,
    status: data.status,
    budget: data.budget,
    oldBudget: data.budget,
    balance: data.balance,
    restricted: data.restricted,
    limit_per_month: data.limit_per_month,
  };
  const initRemarks = {
    open: false,
    remarks: "",
  };

  const [edit, setEdit] = useState(initEditData);
  const [remarks, setRemarks] = useState(initRemarks);
  const [openChangeModal, setOpenChangeModal] = useState(false);

  const CloseEditMode = () => {
    setEditInd(null);
    setEdit(initEditData);
  };

  const EditWithNoRemarks = (id) => {
    if (edit.budget === data.budget) {
      EditProjectCode({
        ...edit,
        id: id,
        key: isJsonString(edit.key) ? isJsonString(edit.key) : edit.key,
      });
      CloseEditMode();
    }
    if (data.budget === "0.00" && edit.budget !== "0.00") {
      setOpenChangeModal(true);
    } else {
      setRemarks({ ...remarks, open: true });
      setEdit({
        ...edit,
        id: id,
        key: isJsonString(edit.key) ? isJsonString(edit.key) : edit.key,
      });
    }
  };
  const closeRemarks = () => {
    setRemarks(initRemarks);
    CloseEditMode();
  };

  const EditWithRemarks = () => {
    EditProjectCode({ ...edit, remarks: remarks.remarks });
    CloseEditMode();
  };

  const InValidInput = () => {
    if (
      (edit.name === data.name &&
        edit.description === data.description &&
        edit.client_id === data.client_id &&
        edit.type === data.type &&
        edit.key === data.key &&
        edit.status === data.status &&
        edit.budget === data.budget &&
        edit.balance === data.balance &&
        edit.restricted === data.restricted &&
        edit.limit_per_month === data.limit_per_month) ||
      edit.name === "" ||
      edit.description === ""
    ) {
      return true;
    }
  };

  const closeChangeOpen = () => {
    setOpenChangeModal(false);
    CloseEditMode();
  };

  const DepartmenShortName = departments.map((dept) => dept.short_name);

  const arr = isJsonString(edit.key)
    ? isJsonString(data.key).map((dept) => dept)
    : edit.key;
  const InitArrayIFUndefined = (data) => {
    return data === undefined ? [] : typeof data === "string" ? [data] : data;
  };

  const handleDeptSelect = (event) => {
    const {
      target: { value },
    } = event;
    setEdit({
      ...edit,
      key: typeof value === "string" ? value.split(",") : value,
    });
  };
  const handleClientSelect = (e) => {
    setEdit({ ...edit, client_id: e.target.value });
  };

  const handleTypeSelect = (e) => {
    setEdit({ ...edit, type: e.target.value });
  };

  const handleStatusSelect = (e) => {
    setEdit({ ...edit, status: e.target.value });
  };
  const handleRestrictSelect = (e) => {
    setEdit({ ...edit, restricted: e.target.value });
  };

  return (
    <>
      <TableCell>
        <TextField
          defaultValue={data.name}
          variant="standard"
          size="small"
          InputProps={{ style: { fontSize: "0.75rem" } }}
          fullWidth
          onChange={(e) => setEdit({ ...edit, name: e.target.value })}
          inputProps={{ maxLength: 100 }}
          helperText={
            edit.name.length >= 100 &&
            "the max length of 100 characters is reached"
          }
        />
      </TableCell>
      <TableCell>
        <TextField
          defaultValue={data.description}
          variant="standard"
          size="small"
          InputProps={{ style: { fontSize: "0.75rem" } }}
          fullWidth
          onChange={(e) => setEdit({ ...edit, description: e.target.value })}
          inputProps={{ maxLength: 250 }}
          helperText={
            edit.description.length >= 250 &&
            "the max length of 250 characters is reached"
          }
        />
      </TableCell>
      <TableCell sx={{ paddingBottom: 3 }}>
        <DropDownSelect
          objectName={{ val: "id", name: "company_name" }}
          DropDownData={clients}
          minWidth={80}
          onChange={handleClientSelect}
          value={edit.client_id}
          variant={"standard"}
        />
      </TableCell>
      <TableCell sx={{ paddingBottom: 3 }}>
        <DropDownSelect
          objectName={{ val: "id", name: "name" }}
          DropDownData={types}
          minWidth={80}
          onChange={handleTypeSelect}
          value={edit.type}
          variant={"standard"}
        />
      </TableCell>
      <TableCell align="right" sx={{ paddingBottom: 3 }}>
        <DropDownMultiple
          DropDownData={DepartmenShortName}
          minWidth={80}
          onChange={handleDeptSelect}
          variant={"standard"}
          value={InitArrayIFUndefined(arr)}
        />
      </TableCell>
      <TableCell sx={{ paddingBottom: 3 }}>
        <DropDownSelect
          objectName={{ val: "id", name: "name" }}
          DropDownData={ActiveOrNot}
          minWidth={80}
          onChange={handleStatusSelect}
          value={edit.status}
          variant={"standard"}
        />
      </TableCell>
      <TableCell>
        <TextField
          defaultValue={data.budget}
          type="number"
          variant="standard"
          InputProps={{ style: { fontSize: "0.75rem" } }}
          inputProps={{ step: 0.5, style: { textAlign: "center" } }}
          style={{ textAlign: "center" }}
          onChange={(e) => setEdit({ ...edit, budget: e.target.value })}
        />
      </TableCell>
      <TableCell align="center">
        {data.balance === "0.00" ? "open" : data.balance}
      </TableCell>
      <TableCell sx={{ paddingBottom: 3 }}>
        <DropDownSelect
          objectName={{ val: "id", name: "name" }}
          DropDownData={RestrictedOrNot}
          minWidth={80}
          onChange={handleRestrictSelect}
          value={edit.restricted}
          variant={"standard"}
        />
      </TableCell>
      <TableCell>
        <TextField
          defaultValue={data.limit_per_month}
          type="number"
          variant="standard"
          InputProps={{ style: { fontSize: "0.75rem" } }}
          inputProps={{ step: 0.5, style: { textAlign: "center" } }}
          style={{ textAlign: "center" }}
          onChange={(e) =>
            setEdit({ ...edit, limit_per_month: e.target.value })
          }
        />
      </TableCell>
      <TableCell align="center">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <IconButton
              size="small"
              onClick={() => EditWithNoRemarks(data.id)}
              sx={{ color: "#61a644" }}
              disabled={InValidInput()}
            >
              <CheckIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              onClick={CloseEditMode}
              sx={{ color: "rgb(228, 106, 118)" }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        )}
      </TableCell>
      <ChangeOpenModal open={openChangeModal} handleClose={closeChangeOpen} />
      <RemarksModal
        open={remarks.open}
        setRemarks={setRemarks}
        handleEdit={EditWithRemarks}
        isLoading={isLoading}
        handleClose={closeRemarks}
        remarks={remarks.remarks}
      />
    </>
  );
};

export default EditRow;
