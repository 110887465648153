import React from "react";
import "../../styles/Auth/login.css";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import GoogleLogin from "react-google-login";
import FormHelperText from "@mui/material/FormHelperText";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import { IsMobile } from "../../helper/isMobile";
import { login, requestGoogleLogin } from "../../api/auth/index";
import { useFormik } from "formik";
import * as yup from "yup";
import { Alert, TextField } from "@mui/material";
import { useNavigate } from "react-router";
import Dialog from "./Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import { useMutation, useQueryClient } from "react-query";
import hooks from "../../hooks/react-query/queryHooks";
const Login = () => {
  const queryClient = useQueryClient();
  const [show_pass, setShowPass] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [modal, showModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [dynamicLogo, setDynamiclogo] = React.useState("");
  const [dynamicName, setDynamicName] = React.useState("");
  const { data } = hooks.usePublicSettings(setDynamiclogo, setDynamicName); // eslint-disable-line
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Input a valid email.")
        .required("Email is required"),
      password: yup.string().required("Password is required"),
    }),
    onSubmit(values, actions) {
      onLogin(values);
    },
  });

  const loginData = useMutation((newData) => login(newData), {
    onSuccess: async (updateData) => {
      if (updateData === "Your account has been disabled!") {
        setError(updateData);
      } else if (updateData?.response?.data?.errors?.password[0]) {
        setError(updateData?.response?.data?.errors?.password[0]);
      }
      if (updateData && updateData.data.token && updateData.data.user) {
        localStorage.setItem("token", updateData.data.token);
        localStorage.setItem("user", JSON.stringify(updateData.data.user));
      }
      queryClient.refetchQueries(["get-user"]);
      queryClient.refetchQueries(["user-dashboard"]);
      queryClient.refetchQueries(["get-settings"]);
      setShowAlert(false);
      setLoading(false);
      navigate("/dashboard");
    },
    onError: () => {
      setShowAlert(true);
      setLoading(false);
    },
  });

  const googleLoginData = useMutation(
    (newData) => requestGoogleLogin(newData),
    {
      onSuccess: async (updateData) => {
        if (updateData === "Your account has been disabled!") {
          setError(updateData);
        } else if (updateData?.response?.data?.errors?.password[0]) {
          setError(updateData?.response?.data?.errors?.password[0]);
        }
        if (updateData && updateData.data.token && updateData.data.user) {
          localStorage.setItem("token", updateData.data.token);
          localStorage.setItem("user", JSON.stringify(updateData.data.user));
        }
        queryClient.refetchQueries(["get-user"]);
        queryClient.refetchQueries(["user-dashboard"]);
        queryClient.refetchQueries(["get-settings"]);

        setShowAlert(false);
        setLoading(false);
        navigate("/dashboard");
      },
      onError: () => {
        setShowAlert(true);
        setLoading(false);
      },
    }
  );

  const onLogin = async (values) => {
    setLoading(true);
    const { email, password } = values;
    const creds = { email, password };
    loginData.mutate(creds);
  };

  const handleShowPassword = () => {
    setShowPass((prevState) => !prevState);
  };

  const handleGoogleLogin = async (googleResponse) => {
    const id = googleResponse.profileObj.googleId;
    const email = googleResponse.profileObj.email;
    const name =
      googleResponse.profileObj.givenName +
      " " +
      googleResponse.profileObj.familyName;
    const token = googleResponse.tokenId;
    const google = { id, email, name, token };
    googleLoginData.mutate(google);
  };

  return (
    <>
      <Dialog open={modal} handleClose={showModal} show={showModal}></Dialog>
      <Card
        className="Login-card"
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CardContent
          className="cc-cont"
          sx={{
            marginTop: "100px",
            marginBottom: "100px",
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "20px",
          }}
        >
          <Grid
            container
            sx={{
              justifyContent: "center",
              alignItems: "center",
              padding: `${
                IsMobile() ? "0px 3px 0px 3px" : "20px 45px 10px 45px"
              }`,
            }}
          >
            <Grid item xs={12} sm={12} lg={6}>
              <Box
                className="box-img"
                sx={{
                  position: "relative",
                }}
              >
                <Box className="box-img-parent">
                  {dynamicLogo && (
                    <img
                      className="fligno-logo"
                      alt="fligno"
                      src={dynamicLogo}
                      loading="lazy"
                      style={{
                        width: "100%",
                        maxWidth: "812px",
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <Grid
                container
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item xs={12}>
                  <Box
                    sx={{
                      padding: "25px",
                      border: "1.5px solid",
                      borderColor: "rgb(119, 126, 137)",
                      borderRadius: "10px",
                      boxShadow:
                        "rgb(90 114 123 / 11%) 0px 7px 30px 0px !important",
                    }}
                  >
                    {dynamicName && (
                      <Typography variant="h3" className="log-h2">
                        Welcome to {dynamicName} HRIS
                      </Typography>
                    )}
                    <Box
                      sx={{
                        marginTop: "32px",
                      }}
                    >
                      <form onSubmit={formik.handleSubmit}>
                        <FormControl
                          fullWidth
                          sx={{
                            margin: "15px 0px 5px",
                          }}
                        >
                          <TextField
                            variant="outlined"
                            name="email"
                            id="email"
                            type="text"
                            fullWidth
                            placeholder="Email"
                            value={formik.values["email"]}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "email",
                                e.target.value,
                                true
                              )
                            }
                            error={
                              formik.touched["email"] &&
                              !!formik.errors["email"]
                            }
                            helperText={
                              formik.touched["email"] && formik.errors["email"]
                            }
                          />
                        </FormControl>
                        <FormControl
                          fullWidth
                          sx={{
                            margin: "15px 0px 29px",
                          }}
                        >
                          <TextField
                            variant="outlined"
                            name="password"
                            id="password"
                            fullWidth
                            type={show_pass ? "text" : "password"}
                            placeholder="Password"
                            value={formik.values["password"]}
                            error={
                              formik.touched["password"] &&
                              !!formik.errors["password"]
                            }
                            helperText={
                              formik.touched["password"] &&
                              formik.errors["password"]
                            }
                            onChange={(e) =>
                              formik.setFieldValue(
                                "password",
                                e.target.value,
                                true
                              )
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                      handleShowPassword();
                                    }}
                                    onMouseDown={() => {
                                      handleShowPassword();
                                    }}
                                    edge="end"
                                  >
                                    {show_pass ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                        {showAlert && <Alert severity="error">{error}</Alert>}
                        <Box className="log-box">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  sx={{ color: "rgb(119, 126, 137)" }}
                                  size="medium"
                                />
                              }
                              label="Remember me"
                              sx={{ marginBottom: "16px" }}
                            />
                          </FormGroup>
                          <Box
                            sx={{
                              marginLeft: "auto",
                              marginBottom: "15px",
                            }}
                          >
                            <Button
                              onClick={(e) => showModal(true)}
                              className="log-a1"
                              sx={{ textTransform: "none" }}
                            >
                              Forgot Password?
                            </Button>
                          </Box>
                        </Box>
                        <Button
                          variant="contained"
                          sx={{
                            textTransform: "none",
                            backgroundColor: "#22A9D8",
                            color: "#fff",
                            height: "56px",
                          }}
                          disabled={loading}
                          fullWidth
                          disableElevation
                          size="large"
                          className="log-sign"
                          type="submit"
                          endIcon={
                            loading ? (
                              <CircularProgress size={"1.2rem"} />
                            ) : (
                              <div />
                            )
                          }
                        >
                          Sign In
                        </Button>
                        <Box className="log-box1">
                          <Typography
                            variant="h6"
                            component="span"
                            className="log-span"
                          >
                            or
                          </Typography>
                        </Box>
                        <GoogleLogin
                          prompt="select_account"
                          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                          buttonText="Sign in with Google"
                          onSuccess={handleGoogleLogin}
                          onFailure={() => {}}
                          cookiePolicy={"single_host_origin"}
                          className="google-button"
                        />
                        <Box sx={{ marginTop: "35px" }}>
                          <FormControl variant="standard">
                            <FormHelperText
                              id="component-helper-text"
                              sx={{ textAlign: "center", letterSpacing: "0.6" }}
                            >
                              We have updated our{" "}
                              <Link
                                href="/privacy-policy"
                                variant="body2"
                                target="_blank"
                                sx={{
                                  letterSpacing: "0.06em",
                                  fontStyle: "italic",
                                  fontSize: "0.75rem",
                                  color: "#22A9D8",
                                }}
                              >
                                Privacy Policy
                              </Link>
                              . By continuing to use our service, you agree to
                              our policies.
                            </FormHelperText>
                          </FormControl>
                        </Box>
                      </form>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default Login;
