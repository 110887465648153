import { useState, useCallback } from "react";

import { useQuery } from "react-query";
import cloneDeep from "lodash/cloneDeep";

import {
  getEmployeeList,
  getTimesheetWeeklyReport,
} from "../../../../api/evaluation/timesheetWeeklyReport";
import { TIMESHEET_WEEKLY_REPORT_QUERY_KEYS } from "../../../../lib/evaluation/timesheetWeeklyReport/";
import {
  generateYearsArray,
  generateFullReport,
} from "../../../../helper/Evaluation/TimesheetWeeklyReport/";

export const useEmployeeList = () =>
  useQuery(TIMESHEET_WEEKLY_REPORT_QUERY_KEYS.employeeList, getEmployeeList, {
    select: (data) => {
      const targetData = data.map((employee) => ({
        id: employee.id,
        label: employee.full_name,
      }));

      const key = "label";
      const filteredData = [
        ...new Map(targetData.map((item) => [item[key], item])).values(),
      ];

      // return targetData;
      return filteredData;
    },
  });

export const useTimesheetWeeklyReport = () => {
  const [employeeId, setEmployeeId] = useState(null);
  const [maxHoursPerWeek, setMaxHoursPerWeek] = useState(40); // Max hours to render per week. PH: 40hrs, AUS: 38hrs

  const handleChangeEmployeeId = (newEmployeeId) => {
    setEmployeeId(newEmployeeId);
  };

  const handleChangeMaxHoursPerWeek = (newMaxHoursPerWeek) => {
    setMaxHoursPerWeek(newMaxHoursPerWeek);
  };

  const addDataFunction = useCallback(
    (data) => {
      const modifiedData = cloneDeep(data);
      modifiedData.years = generateYearsArray(modifiedData.dateHired);

      modifiedData.fullReport = generateFullReport(
        modifiedData,
        maxHoursPerWeek
      );
      return modifiedData;
    },
    [maxHoursPerWeek]
  );

  const timesheetWeeklyReportQuery = useQuery(
    [TIMESHEET_WEEKLY_REPORT_QUERY_KEYS, employeeId],
    getTimesheetWeeklyReport.bind(null, employeeId),
    {
      enabled: !!employeeId,
      select: addDataFunction,
    }
  );

  return {
    employeeId,
    maxHoursPerWeek,
    handleChangeEmployeeId,
    handleChangeMaxHoursPerWeek,
    data: timesheetWeeklyReportQuery,
  };
};

export const useTimesheetWeeklyReportPagination = () => {
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);

  const maxLimit = Math.ceil(52 / limit); // 52 is the number of weeks per year
  const remainder = 52 % limit;
  const isIncrementEnabled = skip + 1 < maxLimit;
  const isDecrementEnabled = skip - 1 >= 0;

  const handleIncrementSkip = () => {
    if (!isIncrementEnabled) return;
    setSkip((prev) => prev + 1);
  };

  const handleDecrementSkip = () => {
    if (!isDecrementEnabled) return;
    setSkip((prev) => prev - 1);
  };

  const handleResetDefaultSkip = useCallback(() => {
    setSkip(0);
  }, []);

  const handleChangeLimit = (newLimit) => {
    handleResetDefaultSkip();
    setLimit(newLimit);
  };

  return {
    maxLimit,
    remainder,
    skip,
    limit,
    isIncrementEnabled,
    isDecrementEnabled,
    handleIncrementSkip,
    handleDecrementSkip,
    handleResetDefaultSkip,
    handleChangeLimit,
  };
};
