import React from "react";
// Material
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

export const Invalid = function ({ open, onClose = () => {} }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <MuiAlert
        onClose={onClose}
        elevation={6}
        variant="filled"
        severity="error"
        sx={{ width: "100%" }}
      >
        Invalid form, please check fields.
      </MuiAlert>
    </Snackbar>
  );
};

export const Error = function ({ open, onClose = () => {} }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <MuiAlert
        onClose={onClose}
        elevation={6}
        variant="filled"
        severity="error"
        sx={{ width: "100%" }}
      >
        Oops! Something went wrong.
      </MuiAlert>
    </Snackbar>
  );
};
export const Success = function ({ open, message, onClose = () => {} }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <MuiAlert
        onClose={onClose}
        elevation={6}
        variant="filled"
        severity="success"
        sx={{ width: "100%" }}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export const Retrieve = function ({
  open,
  message = "A newly QR was created!",
  onClose = () => {},
}) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <MuiAlert
        onClose={onClose}
        elevation={6}
        variant="filled"
        severity="info"
        sx={{ width: "100%" }}
      >
        {"Retrieving Data..."}
      </MuiAlert>
    </Snackbar>
  );
};
