import React, { useContext } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import TimeToLeaveOutlinedIcon from "@mui/icons-material/TimeToLeaveOutlined";
import DoorFrontOutlinedIcon from "@mui/icons-material/DoorFrontOutlined";
import companyLogo from "../../images/company_logo.jpeg";
import { BiCrown, BiLike, BiStar, BiHeart } from "react-icons/bi";
import Likable from "./likable";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import { GlobalContext } from "../../App";
import EmployeeHandbook from "./employeeHandbook";

const Timeout = ({ handleTimeInOut, handleEntryModal, imgUrl }) => {
  const { userDashboard, reminder } = useContext(GlobalContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchor, setAnchor] = React.useState();

  React.useEffect(() => {
    if (reminder.timein) handleTimeInOut("in");
    if (reminder.timeout) handleTimeInOut("out");
  }, [reminder]);

  const open = Boolean(anchorEl);

  const timeInBgcolor =
    userDashboard?.time_inout?.timeIn_status === "late"
      ? "#FDF3F5 !important"
      : userDashboard?.time_inout?.timeIn_status === "grace"
      ? "#FCF4E5 !important"
      : "";
  // data.time_inout.timeIn_status #FFF4E5 !important "#FEC90E !important"
  const timeInColor =
    userDashboard?.time_inout?.timeIn_status === "late"
      ? "#EE6A6C !important"
      : userDashboard?.time_inout?.timeIn_status === "grace"
      ? "#F2C910 !important"
      : "";

  const timeOutBgcolor =
    userDashboard?.time_inout?.timeOut_status === "undertime"
      ? "#FFF4E5 !important"
      : "";
  const timeOutColor =
    userDashboard?.time_inout?.timeOut_status === "undertime"
      ? "#FEC90E !important"
      : "";

  return (
    <>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <Card className="card-inout" sx={{ height: "294.65px" }}>
            <Grid container>
              <Grid item xs={4} sm={12} md={12} lg={12}>
                <CardHeader
                  title={
                    <>
                      <Button
                        disableElevation
                        size="small"
                        variant="contained"
                        className="timest-button"
                        fullWidth
                        onClick={() => {
                          handleTimeInOut("in");
                        }}
                      >
                        Time in
                      </Button>
                      <Divider sx={{ margin: "5px 0px 5px 0px" }} />
                    </>
                  }
                  subheader={
                    <IconButton
                      className="in"
                      sx={{
                        width: "100%",
                        justifyContent: "space-around",
                        color: timeInColor,
                        bgcolor: timeInBgcolor,
                      }}
                      size="small"
                    >
                      <DoorFrontOutlinedIcon />
                      <Typography
                        variant="h3"
                        className="card-typ0"
                        sx={{
                          color: timeInColor,
                        }}
                      >
                        {userDashboard?.time_inout?.timeIn}
                      </Typography>
                    </IconButton>
                  }
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={12} sx={{ margin: "auto" }}>
                <Box>
                  <CardMedia
                    className="card-media"
                    component="img"
                    height="80"
                    alt="Time in image"
                    image={
                      userDashboard?.time_inout === "N/A"
                        ? imgUrl
                        : userDashboard?.time_inout?.timein_image ===
                          "/img/default_profile.jpg"
                        ? imgUrl
                        : userDashboard?.time_inout?.timein_image
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={4} sm={8} md={8} lg={12} sx={{ margin: "auto" }}>
                <CardContent>
                  <Box>
                    <Box>
                      <Typography variant="h6" className="card-typ">
                        {userDashboard?.time_inout.in_location}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card className="card-inout" sx={{ height: "294.65px" }}>
            <Grid container>
              <Grid item xs={4} sm={12} md={12} lg={12}>
                <CardHeader
                  title={
                    <>
                      <Button
                        disableElevation
                        size="small"
                        variant="contained"
                        className="timest-button"
                        fullWidth
                        onClick={() => {
                          handleTimeInOut("out");
                        }}
                      >
                        Time out
                      </Button>
                      <Divider sx={{ margin: "5px 0px 5px 0px" }} />
                    </>
                  }
                  subheader={
                    <IconButton
                      className="in"
                      sx={{
                        width: "100%",
                        justifyContent: "space-around",
                        color: timeOutColor,
                        bgcolor: timeOutBgcolor,
                      }}
                      size="small"
                    >
                      <TimeToLeaveOutlinedIcon />
                      <Typography
                        variant="h3"
                        className="card-typ0"
                        sx={{ color: timeOutColor }}
                      >
                        {userDashboard?.time_inout.timeOut}
                      </Typography>
                    </IconButton>
                  }
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={12} sx={{ margin: "auto" }}>
                <Box>
                  <CardMedia
                    className="card-media"
                    component="img"
                    height="80"
                    alt="Time out image"
                    image={
                      userDashboard?.time_inout === "N/A"
                        ? imgUrl
                        : userDashboard?.time_inout?.timeout_image ===
                          "/img/default_profile.jpg"
                        ? imgUrl
                        : userDashboard?.time_inout?.timeout_image
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={4} sm={8} md={8} lg={12} sx={{ margin: "auto" }}>
                <CardContent>
                  <Box>
                    <Box>
                      <Typography variant="h6" className="card-typ">
                        {userDashboard?.time_inout?.out_location}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} lg={12} sx={{ margin: "auto" }}>
          <EmployeeHandbook imgUrl={imgUrl} />
        </Grid>
      </Grid>
    </>
  );
};

export default Timeout;
