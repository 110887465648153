import * as React from "react";
import Modal from "@mui/material/Modal";
import { Box, IconButton, Typography } from "@mui/material";
import { ModalStyleGlobal } from "../../../../helper/ModalStyle";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { ButtonCustomColor } from "../../../../helper/ButtonCustomColor";
import { CSVLink } from "react-csv";
import {
  exportPDFSummary,
  filterSummaryExport,
  getHeaders,
} from "../../../../helper/UserApplicationMgmt/Summary/SummaryHelper";

export default function ExportModal({
  select,
  exportModal,
  setExportModal,
  data,
}) {
  const summaryData = filterSummaryExport(data);
  const addStyle = {
    width: "auto",
    px: "50px",
  };
  const newStyle = { ...ModalStyleGlobal, ...addStyle };

  const handleClose = () => {
    setExportModal(false);
  };

  return (
    <div>
      <Modal
        keepMounted
        open={exportModal}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={newStyle}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant={"h6"}>EXPORT FILE</Typography>
            <IconButton onClick={handleClose} aria-label="close">
              <CloseRoundedIcon />
            </IconButton>
          </Box>
          <Box sx={{ m: "20px" }}>
            <Box sx={{ m: "5px 5px" }}>
              {summaryData?.length > 0 && (
                <CSVLink
                  data={summaryData}
                  headers={getHeaders(summaryData, "csv", select)}
                  filename={"User-application-summary.csv"}
                >
                  <ButtonCustomColor
                    sx={{ width: "400px", m: "auto" }}
                    variant="contained"
                  >
                    CSV
                  </ButtonCustomColor>
                </CSVLink>
              )}
            </Box>
            <Box sx={{ m: "5px 5px" }}>
              <ButtonCustomColor
                onClick={() =>
                  exportPDFSummary(data, "User application summary", select)
                }
                sx={{ width: "400px", m: "auto" }}
                variant="contained"
              >
                PDF
              </ButtonCustomColor>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
