import { useContext, useState, useEffect } from "react";
import {
  Card,
  TextField,
  InputAdornment,
  Button,
  Typography,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import useDebounce from "../../../hooks/customHooks/debounce";
import { MotdCntxt } from ".";

const MotdFilter = () => {
  const { search, setSearch, setOpen } = useContext(MotdCntxt);
  const [searchString, setSearchString] = useState(search);
  const debounceValue = useDebounce(searchString, 700);

  useEffect(() => {
    setSearch(debounceValue);
  }, [debounceValue, setSearch]);

  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "10px",
        padding: "14px",
        alignItems: "center",
        margin: "15px",
        boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
        position: "sticky",
        top: "0",
        zIndex: "1",
        "@media(max-width: 768px)": {
          position: "relative",
        },
      }}
    >
      <Button
        sx={{
          backgroundColor: "#00c292",
          height: 35,
        }}
        variant="contained"
        startIcon={<AddCircleOutlineIcon />}
        onClick={() => setOpen(true)}
      >
        <Typography sx={{ fontSize: "0.78rem" }}>Add Announcement</Typography>
      </Button>
      <TextField
        value={searchString}
        onChange={(e) => {
          setSearchString(e.target.value);
        }}
        sx={{ margin: "5px" }}
        size="small"
        align={"right"}
        placeholder="Search"
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CloseIcon
                style={{ color: "#000000", cursor: "pointer" }}
                onClick={() => setSearchString("")}
              />
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Card>
  );
};

export default MotdFilter;
