import React from "react";
import {
  Box,
  FormGroup,
  InputLabel,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Stack,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import CkEditor from "./CkEditor";

const Form = ({ formfield, uploadFile }) => {
  return (
    <>
      {formfield?.map((column, index) => (
        <FormGroup key={index}>
          {column.type === "document" && (
            <>
              <Box
                sx={{
                  display: "flex",
                  mt: 2,
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <TextField
                  error={column.helperText && true}
                  size={"small"}
                  disabled
                  fullWidth
                  name={column.data}
                  value={column.value ? column.value : "Upload a document."}
                  label={column.label}
                  variant="outlined"
                  helperText={column.helperText}
                ></TextField>
                <Button
                  component="label"
                  variant="contained"
                  className="timest-button"
                  sx={{ ml: 0.5 }}
                >
                  Upload&nbsp;
                  <input
                    hidden
                    accept="application/pdf,application/vnd.ms-excel/*"
                    multiple
                    name="file"
                    type="file"
                    onChange={(e) => uploadFile(e)}
                  />
                </Button>
              </Box>
            </>
          )}

          {column.type === "date" && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={3}>
                <DesktopDatePicker
                  disableToolbar
                  label={column.label}
                  inputFormat="MM/dd/yyyy"
                  value={column.value}
                  onChange={(e) => {
                    column.onChange(e);
                  }}
                  renderInput={(params) => (
                    <TextField
                      size={"small"}
                      sx={{ mt: 2 }}
                      name={column.data}
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </Stack>
            </LocalizationProvider>
          )}
          {column.type === "select" && (
            <>
              <FormControl sx={{ mt: 2 }} variant="outlined">
                <InputLabel id={column.label}>{column.label}</InputLabel>

                <Select
                  size={"small"}
                  labelId={column.label}
                  label={column.label}
                  name={column.data}
                  onChange={(e) => {
                    return;
                  }}
                  defaultValue={column.menu_item[0].value}
                  fullWidth
                >
                  {column.menu_item.map((row, index) => (
                    <MenuItem key={index} value={row.value}>
                      {row.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}

          {column.type === "CkEditor" && (
            <>
              {column.helperText && true ? (
                <>
                  <Typography
                    sx={{ mt: 1, ml: 2, color: "red" }}
                    variant="caption"
                  >
                    {column.helperText}
                  </Typography>
                  <Box
                    sx={{
                      "&:click": {
                        border: "0.1em solid",
                        borderColor: "red",
                        borderRadius: 1,
                      },
                      border: "0.01em solid",
                      borderColor: "red",
                      borderRadius: 1,
                    }}
                  >
                    <CkEditor data={column.form} setData={column.setData} />
                  </Box>
                </>
              ) : (
                <Box sx={{ mt: 2 }}>
                  <CkEditor data={column.form} setData={column.setData} />
                </Box>
              )}
            </>
          )}

          {column.type === "TextArea" && (
            <>
              <TextField
                size={"small"}
                error={column.helperText && true}
                sx={{ mt: 2 }}
                multiline
                minRows={3}
                name={column.data}
                label={column.label}
                variant="outlined"
                helperText={column.helperText}
              ></TextField>
            </>
          )}

          {!column.type && (
            <>
              <TextField
                size={"small"}
                error={column.helperText && true}
                sx={{ mt: 2 }}
                name={column.data}
                label={column.label}
                variant="outlined"
                helperText={column.helperText}
              ></TextField>
            </>
          )}
        </FormGroup>
      ))}

      <Box
        sx={{
          py: 1.5,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      ></Box>
    </>
  );
};

export default Form;
