import apiService from "../axios";
import moment from "moment";

export const getPerProjectSummary = async ({
  page_size = 10,
  fromDate,
  toDate,
  inactive = false,
  search,
  page = 1,
}) => {
  try {
    let response = await apiService.get(
      `v5/timesheet/report-summary?page_size=${page_size}&fromDate=${fromDate}&toDate=${toDate}&inactive=${inactive}&search=${search}&page=${page}`,
      {
        Accept: `application/json`,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const exportSummaryApi = async (payload) => {
  try {
    const response = await apiService.post(
      `v5/timesheet/export/generate-project-code-summary`,
      payload,
      {
        "Content-Type": "application/json",
        body: JSON.stringify(payload),
        responseType: "blob",
      }
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    const fileName =
      payload.export_type === "date"
        ? moment(payload.start).format("ll") +
          " - " +
          moment(payload.end).format("ll") +
          ` ${payload.project_code}` +
          " Summary"
        : payload.project_code + " Lifetime Summary";
    const ext = ".xlsx";
    link.href = url;
    link.setAttribute("download", fileName + ext);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

    return true;
  } catch (error) {
    throw error;
  }
};
