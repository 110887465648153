import { ORDER } from "../../../lib/leaveManagement/leaveConversionApplications";

const descendingComparator = (a, b, orderBy) => {
  const x = a[orderBy],
    y = b[orderBy];

  if (y < x) return -1;
  if (y > x) return 1;
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === ORDER.descending
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const generateSkeletonLoader = (count, SkeletonComponent) => {
  return Array(count)
    .fill(SkeletonComponent)
    .map((Component, index) => ({ ...Component, key: index }));
};

export const generateRandomNum = (min, max) =>
  Math.floor(Math.random() * (max - min)) + min;
