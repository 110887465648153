import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "react-query";
import api from "../../api/projects/index";
import { useAuth } from "../../routes/privateroute";
import { getSettings } from "../../api/settings";
import { updateGeneralSettings } from "../../api/settings";
import { toast } from "react-toastify";
import {
  declineLeaveConversionApplication,
  approveLeaveConversionApplication,
} from "../../api/LeaveManagement/LeaveConversionApplications";
import API from "../../api/settings/timesheet/index";
const { format } = require("date-fns");

const dataForm = {
  month: format(new Date(), "M"),
  year: format(new Date(), "yyyy"),
};

const data = format(new Date(), "yyyy-MM-dd");

const useTimesheetWeek = () => {
  return useQuery(["timesheet-week"], () => api.getTimesheetWeek(dataForm));
};

const useHeaderData = () => {
  return useQuery(["header-data"], () => api.getHeaderData(dataForm));
};

export const useUserDashboard = () => {
  return useQuery(["user-dashboard"], () => api.getUserdata(data), {
    enabled: useAuth(),
  });
};

const useUpcomingEvents = () => {
  return useQuery(["upcoming-event"], () => api.getUpcomingEvent());
};

const useLeaveType = (data) => {
  return useQuery(["leave-types"], () => api.getLeaveTypes(data));
};
const useLeaveTypeMng = (params) => {
  return useQuery(["leave-types-management", params.criteria], () =>
    api.getLeaveTypesMng(params.payload)
  );
};

const useGetDepartment = () => {
  return useQuery(["get-deparments"], API.GetDepartments);
};

const useAddLeaveType = () => {
  const queryClient = useQueryClient();
  return useMutation(api.addLeaveType, {
    onSuccess: () => {
      queryClient.refetchQueries(["leave-types-management"]);
      queryClient.refetchQueries(["leave-types"]);
    },
  });
};

const useDisabledLeaveType = () => {
  const queryClient = useQueryClient();
  return useMutation(api.disableLeaveType, {
    onSuccess: () => {
      queryClient.refetchQueries(["leave-types-management"]);
    },
  });
};
const useRestoreLeaveType = () => {
  const queryClient = useQueryClient();
  return useMutation(api.restoreLeaveTypes, {
    onSuccess: (request) => {
      if (request.data === "success update") {
        queryClient.refetchQueries(["leave-types-management"]);
      }
    },
  });
};

const useEditTypes = ({ edit, setEdit }) => {
  const queryClient = useQueryClient();
  return useMutation(api.submitEditType, {
    onSuccess: () => {
      queryClient.refetchQueries(["leave-types-management"]);
      queryClient.refetchQueries(["leave-types"]);
      setEdit(!edit);
    },
    onError: () => {
      setEdit(false);
    },
  });
};

const useSkills = () => {
  return useQuery(["user-skills"], () => api.getSkills());
};

const useColleagues = (dateSelected, filters, sorts, inOutFilter, search) => {
  return useInfiniteQuery(
    ["colleagues", dateSelected, filters, sorts, inOutFilter, search],
    ({ pageParam = 1 }) =>
      api.getColleagues(
        pageParam,
        dateSelected,
        filters,
        sorts,
        inOutFilter,
        search
      ),
    {
      getNextPageParam: (page) => {
        return page.current_page === page.last_page
          ? undefined
          : page.current_page + 1;
      },
    }
  );
};

const useUpdateReaction = () => {
  const queryClient = useQueryClient();
  return useMutation(api.submitReaction, {
    onSuccess: (request) => {
      if (request.data.status === "success") {
        queryClient.refetchQueries(["colleagues"], {
          type: "active",
          refetchPage: (_, index) => index === request.page,
        });
      }
    },
    onError: () => {},
  });
};

const useGetDailyEntry = (entrPayload) => {
  return useQuery(["daily-entries"], () => api.getDailyEntry(entrPayload));
};

const useUpdateTimeInOut = (
  setChildOpen,
  handleTimeInOut,
  handleChildLoader,
  setUrl,
  setAlertOpen,
  handleViewSurvey2,
  getPsychSurvey
) => {
  return useMutation(api.submitEntry, {
    onSuccess: (request) => {
      if (request.error === 1) {
        setChildOpen(false);
        handleChildLoader(false);
        setUrl(null);
        switch (request.data?.status) {
          case "no time in":
            toast.warning("You don't have a time in yet");
            break;
          case "incomplete timesheet":
            setAlertOpen(true);
            break;
          default:
            toast.error("Something went wrong");
            break;
        }
      }
      if (request.error === 0) {
        if (
          request.data.action === "time-in" &&
          getPsychSurvey?.data?.done === false &&
          getPsychSurvey?.data?.enabled
        ) {
          handleViewSurvey2();
        }
        handleChildLoader(false);
      }
    },
  });
};

const useReminder = () => {
  return useQuery(["settings-reminder"], () => api.reminder());
};

const useProjectCode = () => {
  return useQuery(["dashboard-codes"], () => api.getProjectCode());
};
const useProjectActivity = () => {
  return useQuery(["dashboard-activity"], () => api.getProjectActivity());
};

const useUpdateTimesheetEntry = () => {
  const queryClient = useQueryClient();
  return useMutation(api.updateTimesheetEntry, {
    onSuccess: () => {
      queryClient.invalidateQueries(["timesheet-week"]);
    },
  });
};

const useDeleteTimesheetEntry = () => {
  const queryClient = useQueryClient();
  return useMutation(api.deleteTimesheetEntry, {
    onSuccess: () => {
      queryClient.invalidateQueries(["timesheet-week"]);
    },
  });
};

const useUpdateCommend = (modal, setComment, setErrors) => {
  const queryClient = useQueryClient();
  return useMutation(api.submitCommend, {
    onSuccess: (request) => {
      modal(false);
      setComment("");
      setErrors("");
      toast.success("Comments successfully added");
      if (request.data.status === "success") {
        queryClient.refetchQueries(["colleagues"], {
          type: "active",
          refetchPage: (_, index) => index === request.page,
        });
      }
    },
  });
};

const useAddTimesheetEntry = () => {
  const queryClient = useQueryClient();
  return useMutation(api.addTimesheenEntry, {
    onSuccess: () => {
      queryClient.invalidateQueries(["timesheet-week"]);
    },
  });
};

const useAddSubmitLeave = () => {
  return useMutation(api.addSubmitLeave);
};

const useAddSubmitFeedback = () => {
  return useMutation(api.addFeedback);
};

const useAddSubmitWfh = () => {
  return useMutation(api.addSubmitWfh);
};

const useGetProjectTypes = () => {
  return useQuery(["project-leave-types"], () => api.getType());
};
const useAddSubmitOT = () => {
  return useMutation(api.addSubmitOT);
};

const useForgotTimeInOut = () => {
  return useMutation(api.addForgotTimeInOutRequest);
};

const useShift = (keyword, rowsPerPage) => {
  return useInfiniteQuery(
    ["shifts", keyword, rowsPerPage],
    ({ pageParam = 1 }) => api.getShifts(pageParam, keyword, rowsPerPage),
    {
      getNextPageParam: (page) => {
        return page.current_page === page.last_page
          ? undefined
          : page.current_page + 1;
      },
    }
  );
};
const useAddSubmitShift = () => {
  return useMutation(api.addSubmitShift);
};

const useUpdateShift = () => {
  return useMutation(api.addUpdateShift);
};
const useDeleteShift = () => {
  return useMutation(api.addDeleteShift);
};

const useNotedReminder = () => {
  return useMutation(api.noteReminders);
};

const useGeneralSettings = () => {
  return useQuery(["general-settings"], () => getSettings());
};

const useUpdateSettings = () => {
  return useMutation(updateGeneralSettings);
};
const usePublicSettings = (setDynamiclogo, setDynamicName) => {
  return useQuery(["public-settings"], () => api.getPublicSettings(), {
    onSuccess: (settingsData) => {
      //eslint-disable-next-line
      settingsData.map((st) => {
        if (st.setting === "company_name") {
          setDynamicName(st.value);
        }
        if (st.setting === "login_logo") {
          setDynamiclogo(st.value);
        }
      });
    },
  });
};

const useReactionReports = (from, to, rowsPerPage, filter, current) => {
  return useInfiniteQuery(
    ["reaction-report", from, to, rowsPerPage, filter, current],
    ({ pageParam = current + 1 }) =>
      api.getReactionReport(pageParam, from, to, rowsPerPage, filter),
    {
      getNextPageParam: (page) => {
        return page.current_page > page.last_page
          ? undefined
          : page.current_page;
      },
    }
  );
};

const usePulseSurvey = () => {
  return useMutation(api.submitPulseSurvey);
};

const useGetPulseSurvey = () => {
  return useQuery(["pulse-survey"], () => api.getPulseSurvey());
};

const useDeletePulse = () => {
  return useMutation(api.deletePulseSurvey);
};

const useSpecificPulseSurvey = (dataIdParam) => {
  return useQuery(["specific-survey", dataIdParam], () =>
    api.getSpecificPulse(dataIdParam)
  );
};
const useUpdatePulseSurvey = () => {
  return useMutation(api.updatePulseSurvey);
};
const useEnableSurveySetting = () => {
  return useMutation(api.toggleSurvey);
};
const useSetSurveySetting = () => {
  return useMutation(api.setSurvey);
};

const useGetSettings = () => {
  return useQuery(["survey-settings"], () => api.getSurveySetting());
};
const useSubmitAnswer = () => {
  return useMutation(api.submitAnswer);
};

const useGetPulseAnswer = (idParam) => {
  return useQuery(["show-pulse-result", idParam], () =>
    api.showResultAnswer(idParam)
  );
};

const useApproveConversion = () => {
  return useMutation(approveLeaveConversionApplication);
};
const useDisapproveConversion = () => {
  return useMutation(declineLeaveConversionApplication);
};

const queries = {
  useTimesheetWeek,
  useHeaderData,
  useUserDashboard,
  useUpcomingEvents,
  useLeaveType,
  useLeaveTypeMng,
  useAddLeaveType,
  useDisabledLeaveType,
  useRestoreLeaveType,
  useEditTypes,
  useSkills,
  useColleagues,
  useUpdateReaction,
  useGetDailyEntry,
  useUpdateTimeInOut,
  useProjectCode,
  useProjectActivity,
  useUpdateTimesheetEntry,
  useDeleteTimesheetEntry,
  useUpdateCommend,
  useAddTimesheetEntry,
  useAddSubmitLeave,
  useAddSubmitWfh,
  useGetProjectTypes,
  useAddSubmitOT,
  useShift,
  useAddSubmitShift,
  useUpdateShift,
  useDeleteShift,
  useGeneralSettings,
  useUpdateSettings,
  usePublicSettings,
  useReactionReports,
  useReminder,
  useNotedReminder,
  useAddSubmitFeedback,
  usePulseSurvey,
  useGetPulseSurvey,
  useDeletePulse,
  useSpecificPulseSurvey,
  useUpdatePulseSurvey,
  useEnableSurveySetting,
  useSetSurveySetting,
  useGetSettings,
  useSubmitAnswer,
  useGetPulseAnswer,
  useApproveConversion,
  useDisapproveConversion,
  useForgotTimeInOut,
  useGetDepartment,
};

export default queries;
