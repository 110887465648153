import {
  Box,
  Paper,
  TableContainer,
  Table as MuiTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import Pagination from "./Pagination";
import SearchComponent from "./SearchComponent";

const Table = ({
  columns,
  Rows,
  page,
  pageSize,
  setPage,
  setPageSize,
  total,
  setSearch,
  isFetching,
  keyword,
  setKeyword,
  Buttons,
}) => {
  return (
    <>
      <TableContainer sx={{ mt: 1.5 }} component={Paper}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          {Buttons &&
            Buttons.map((Button, index) => {
              return Button.Icon;
            })}
          <SearchComponent
            keyword={keyword}
            setKeyword={setKeyword}
            setSearch={setSearch}
            setPage={setPage}
            isFetching={isFetching}
          />
        </Box>
        <MuiTable size="small">
          <TableHead>
            <TableRow>
              {columns &&
                columns.map((column, index) => (
                  <TableCell component="th" key={index}>
                    <Typography sx={{ fontSize: "15px" }}>
                      <b>{column.label}</b>
                    </Typography>
                  </TableCell>
                ))}
              <TableCell component="th">
                <Typography sx={{ fontSize: "15px" }}>
                  <b>Action</b>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{Rows}</TableBody>
        </MuiTable>
      </TableContainer>
      <TableContainer component={Paper}>
        <MuiTable>
          <TableBody>
            <TableRow>
              <Pagination
                page={page}
                pageSize={pageSize}
                total={total}
                onPageChange={setPage}
                onPageSizeChange={setPageSize}
              />
            </TableRow>
          </TableBody>
        </MuiTable>
      </TableContainer>
    </>
  );
};

export default Table;
