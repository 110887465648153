import { useQuery, useMutation, useQueryClient } from "react-query";
import { getComplianceCheck } from "../../../api/compliance/complianceCheck";
import { toast } from "react-toastify";
import apiService from "../../../api/axios";

const useComplianceCheckerList = (params) => {
  return useQuery(["get-compliance-check", params.criteria], () =>
    getComplianceCheck(params.payload)
  );
};

const useAddDidNotComply = () => {
  const queryClient = new useQueryClient();
  return useMutation(
    "get-compliance-check",
    async (data) => {
      try {
        let response = await apiService.post(
          `/v1/compliance/add-did-not-comply`,
          {
            Accept: `application/json`,
            "Content-Type": `multipart/form-data`,
            api: true,
            ...data,
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(["get-compliance-check"]);
        toast.success("Compliance successfully updated.", {});
      },
    },
    {
      onError: () => {
        queryClient.refetchQueries(["get-compliance-check"]);
      },
    }
  );
};

const hooks = {
  useComplianceCheckerList,
  useAddDidNotComply,
};
export default hooks;
