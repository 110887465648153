import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import StyledTableCell from "../StyledTableCell";
import TitleTypography from "../TitleTypography";
import DetailsTypography from "../DetailsTypography";
import ClampedLongText from "../ClampedLongText";

const VIEW_IMAGES_BUTTON_STYLES = {
  padding: 0,
  alignSelf: "flex-start",
  textTransform: "unset",
  "&:hover": {
    backgroundColor: "transparent",
    textDecoration: "underline",
  },
};

const WorkFromHomeCell = ({
  workplace,
  wfh_template,
  toggleImageModalHandler,
}) => {
  return (
    <StyledTableCell
      sx={{
        verticalAlign: "top",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <Stack>
        <TitleTypography>(WFH) Workplace</TitleTypography>
        <ClampedLongText
          maxWidth={40}
          lineClamp={1}
          sxTypography={{ fontSize: "14px" }}
        >
          {workplace}
        </ClampedLongText>
      </Stack>

      <Stack>
        <TitleTypography>(WFH) Attachment</TitleTypography>
        <Button
          variant="text"
          sx={VIEW_IMAGES_BUTTON_STYLES}
          onClick={toggleImageModalHandler}
        >
          View Image(s)
        </Button>
      </Stack>

      <Stack>
        <TitleTypography>(WFH) Telecommute Template</TitleTypography>
        <DetailsTypography>{wfh_template}</DetailsTypography>
      </Stack>
    </StyledTableCell>
  );
};

export default WorkFromHomeCell;
