import apiService from "../axios";

export const getConversionLogs = async ({
  page_size = 10,
  page,
  search = "",
}) => {
  try {
    let response = await apiService.get(
      `v5/leave-conversion/get-conversion-logs?page${page}&page_size=${page_size}&search=${search}`,
      {
        Accept: `application/json`,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
