import NoData from "./NoData";
import React from "react";

const Rows = ({
  data = [],
  columns,
  Row,
  handleViewTemplate,
  handleEditTemplate,
  handleActivate,
  handleDelete,
}) => {
  return (
    <>
      {data && data.length === 0 ? (
        <NoData />
      ) : (
        data &&
        data.map((row, index) => (
          <React.Fragment key={index}>
            <Row
              columns={columns}
              row={row}
              handleViewTemplate={handleViewTemplate}
              handleEditTemplate={handleEditTemplate}
              handleActivate={handleActivate}
              handleDelete={handleDelete}
            />
          </React.Fragment>
        ))
      )}
    </>
  );
};

export default Rows;
