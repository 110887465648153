import React, { useContext } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { compSettingSchema } from "../../../../../helper/Yup/compSettingSchema";
import InputController from "../../../../../component/InputController";
import SettingDropzone from "./component/settingDropzone";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import PopperComponent from "./component/popperComponent";
import hooks from "../../../../../hooks/react-query/queryHooks";
import LinearProgress from "@mui/material/LinearProgress";
import { GlobalContext } from "../../../../../App";
const SettingCard = ({ settings }) => {
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(compSettingSchema),
    defaultValues: {
      companyName: "",
      companyLogo: "",
      bgcolor_appbar: "",
      bgcolor_primary: "",
      bgcolor_secondary: "",
      loginLogo: "",
      wfh_guidelines_url: "",
    },
  });
  const { setSetting, setting } = useContext(GlobalContext);
  const [imgUrl, setImgurl] = React.useState("");
  const [imgUrl2, setImgurl2] = React.useState("");
  const [updating, setUpdating] = React.useState(false);
  const { mutate: addSettingMutation } = hooks.useUpdateSettings();
  const settChecker = React.useRef(true);
  React.useEffect(() => {
    if (settChecker) {
      //eslint-disable-next-line
      settings.map((st) => {
        switch (st.setting) {
          case "company_name":
            setValue("companyName", st.value);
            break;
          case "company_logo":
            setImgurl(st.value);
            break;
          case "login_logo":
            setImgurl2(st.value);
            break;
          case "bgcolor_appbar":
            setValue("bgcolor_appbar", st.value);
            break;
          case "bgcolor_primary":
            setValue("bgcolor_primary", st.value);
            break;
          case "bgcolor_secondary":
            setValue("bgcolor_secondary", st.value);
            break;
          case "wfh_guidelines_url":
            setValue(
              "wfh_guidelines_url",
              st.value === "false" ? "" : st.value
            );
            break;
          default:
          //intentionally empty
        }
      });
      settChecker.current = false;
    }
    return () => {
      // Code to run when the component is unmounted
      setSetting("");
    };
    // eslint-disable-next-line
  }, []);

  const onSubmit = (data) => {
    setUpdating(true);
    const settingEntries = {
      companyName: data.companyName,
      companyLogo: data.companyLogo,
      login_image: data.loginLogo,
      loginLogo: data.loginLogo,
      bgcolor_appbar: data.bgcolor_appbar,
      bgcolor_primary: data.bgcolor_primary,
      bgcolor_secondary: data.bgcolor_secondary,
      wfh_guidelines_url: data.wfh_guidelines_url,
    };
    addSettingMutation(settingEntries, {
      onSuccess: (request) => {
        window.location.reload();
      },
      onError: (request) => {
        window.location.reload();
      },
    });
  };

  const palletePreviewMaker = (palleteParam) => {
    return (
      <Controller
        control={control}
        name={palleteParam}
        render={({ field }) => (
          <TextField
            fullWidth
            size="small"
            sx={{
              bgcolor: getValues(palleteParam),
              borderRadius: "5px",
              width: "40px",
            }}
            inputProps={{
              readOnly: true,
            }}
          />
        )}
      />
    );
  };

  const customCss = {
    box: {
      display: "flex",
      justifyContent: "center",
    },
    typo: {
      mb: "15px",
    },
    card: {
      p: "35px",
      height: "100%",
    },
    submit: {
      display: "flex",
      alignItems: "center",
      p: "18px",
    },
  };

  const handleInputValueChange = (name, value) => {
    const newSetting = { ...setting };
    newSetting[name] = value;
    setSetting(newSetting);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        sx={{ m: "20px", width: "-webkit-fill-available" }}
        columnSpacing={2}
        rowSpacing={2}
      >
        <Grid item xs={12} lg={5}>
          <Card sx={customCss.card}>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <Typography sx={customCss.typo}>Company Name</Typography>
                <InputController
                  type="text"
                  control={control}
                  name="companyName"
                  errors={errors}
                  size="small"
                  rules={{ required: true }}
                  isDefaultErrorAlert={true}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={customCss.typo}>Header Color</Typography>
                <Box sx={{ display: "flex" }}>
                  <Box width="100%">
                    <InputController
                      type="hexInput"
                      control={control}
                      name="bgcolor_appbar"
                      errors={errors}
                      rules={{ required: true }}
                      isDefaultErrorAlert={true}
                      getValues={getValues}
                      hexValue="bgcolor_appbar"
                      onChange={handleInputValueChange}
                    />
                  </Box>
                  <Box sx={{ m: "0px 10px", height: "40px" }}>
                    {palletePreviewMaker("bgcolor_appbar")}
                  </Box>
                  <Box>
                    <PopperComponent>
                      <InputController
                        type="hexPicker"
                        control={control}
                        name="bgcolor_appbar"
                        errors={errors}
                        rules={{ required: true }}
                        isDefaultErrorAlert={true}
                        getValues={getValues}
                        hexValue="bgcolor_appbar"
                        onChange={handleInputValueChange}
                      />
                    </PopperComponent>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={customCss.typo}>Primary Color</Typography>
                <Box sx={{ display: "flex" }}>
                  <Box width="100%">
                    <InputController
                      type="hexInput"
                      control={control}
                      name="bgcolor_primary"
                      errors={errors}
                      rules={{ required: true }}
                      isDefaultErrorAlert={true}
                      getValues={getValues}
                      hexValue="bgcolor_primary"
                      onChange={handleInputValueChange}
                    />
                  </Box>
                  <Box sx={{ m: "0px 10px", height: "40px" }}>
                    {palletePreviewMaker("bgcolor_primary")}
                  </Box>
                  <Box>
                    <PopperComponent>
                      <InputController
                        type="hexPicker"
                        control={control}
                        name="bgcolor_primary"
                        errors={errors}
                        rules={{ required: true }}
                        isDefaultErrorAlert={true}
                        getValues={getValues}
                        hexValue="bgcolor_primary"
                        onChange={handleInputValueChange}
                      />
                    </PopperComponent>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={customCss.typo}>Secondary Color</Typography>
                <Box sx={{ display: "flex" }}>
                  <Box width="100%">
                    <InputController
                      type="hexInput"
                      control={control}
                      name="bgcolor_secondary"
                      errors={errors}
                      rules={{ required: true }}
                      isDefaultErrorAlert={true}
                      getValues={getValues}
                      hexValue="bgcolor_secondary"
                      onChange={handleInputValueChange}
                    />
                  </Box>
                  <Box sx={{ m: "0px 10px", height: "40px" }}>
                    {palletePreviewMaker("bgcolor_secondary")}
                  </Box>
                  <Box>
                    <PopperComponent>
                      <InputController
                        type="hexPicker"
                        control={control}
                        name="bgcolor_secondary"
                        errors={errors}
                        rules={{ required: true }}
                        isDefaultErrorAlert={true}
                        getValues={getValues}
                        hexValue="bgcolor_secondary"
                        onChange={handleInputValueChange}
                      />
                    </PopperComponent>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} lg={7} container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} lg={6}>
            <Card sx={customCss.card}>
              <Typography sx={customCss.typo}>Company Logo</Typography>
              <Box sx={customCss.box}>
                <SettingDropzone
                  setImgurl={setImgurl}
                  imgUrl={imgUrl}
                  setValue={setValue}
                  type="companyLogo"
                />
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card sx={customCss.card}>
              <Typography sx={customCss.typo}>Login Logo</Typography>
              <Box sx={customCss.box}>
                <SettingDropzone
                  setImgurl={setImgurl2}
                  imgUrl={imgUrl2}
                  setValue={setValue}
                  type="loginLogo"
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={5}>
          <Card sx={customCss.card}>
            <Grid container rowSpacing={2}>
              <Typography sx={customCss.typo}>WFH Guidelines</Typography>
              <Box width="100%">
                <InputController
                  type="text"
                  control={control}
                  name="wfh_guidelines_url"
                  errors={errors}
                  isDefaultErrorAlert={true}
                  getValues={getValues}
                  hexValue="wfh_guidelines_url"
                />
              </Box>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          position: "sticky",
          top: 0,
          borderRadius: 5,
          paddingRight: 2,
          paddingLeft: 3,
        }}
      >
        <Grid item xs={12} md={12}>
          <Box display="flex" p={1} justifyContent="flex-end">
            <Grid item xs={12}>
              <Card sx={customCss.submit}>
                <Box width="100%" m="0px 17px">
                  {updating && <LinearProgress />}
                </Box>
                <Button
                  disabled={updating}
                  size="small"
                  disableElevation
                  variant="contained"
                  type="submit"
                  sx={{ minWidth: "100px", mr: "17px" }}
                >
                  Update
                </Button>
              </Card>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default SettingCard;
