import apiService from "../axios";

export const updateEmployee = async (payload) => {
  try {
    const { data } = await apiService.post(
      `v1/employee/update-employee-details`,
      payload,
      {
        Accept: `application/json`,
        "Content-Type": `multipart/form-data`,
        body: JSON.stringify(payload),
      }
    );

    return data.data;
  } catch (error) {
    throw error;
  }
};

export const updatePassword = async (payload) => {
  try {
    const { data } = await apiService.post(
      `v1/employee/update-password`,
      payload,
      {
        Accept: `application/json`,
        "Content-Type": `multipart/form-data`,
        body: JSON.stringify(payload),
      }
    );
    return data.data;
  } catch (error) {
    throw error;
  }
};

export const getEmployee = async (payload) => {
  try {
    const { data } = await apiService.get(
      `v5/employee/get-employee-details`,
      payload,
      {
        Accept: `application/json`,
        "Content-Type": `multipart/form-data`,
        body: JSON.stringify(payload),
      }
    );
    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const getDepartment = async (payload) => {
  try {
    const { data } = await apiService.post(`v1/department/get-all`, payload, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(payload),
    });

    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const getSchedule = async (payload) => {
  try {
    const { data } = await apiService.get(
      `v1/schedules/get-schedule`,
      payload,
      {
        Accept: `application/json`,
        "Content-Type": `multipart/form-data`,
        body: JSON.stringify(payload),
      }
    );
    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const getUserInformation = async (payload) => {
  try {
    const { data } = await apiService.post(
      `v1/employee/get-employee-details`,
      { ...payload, api: true },
      {
        Accept: `application/json`,
        "Content-Type": `multipart/form-data`,
        body: JSON.stringify(payload),
      }
    );

    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateUserInformation = async (payload) => {
  const { add, update } = payload;
  await apiService.post(
    `v1/employee/update-employee-details`,
    { ...update, api: true },
    {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(update),
    }
  );

  await apiService.post(
    `v1/employee/add`,
    { ...add, api: true },
    {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(add),
    }
  );
};
