import React, { useState, useRef, useEffect, useContext } from "react";
import "../../styles/Dashboard/index.scss";
import Grid from "@mui/material/Grid";
import GreetingCard from "../../component/Dashboard/greetingCard";
import Timeinout from "../../component/Dashboard/timeinout";
import DateComp from "../../component/Dashboard/datecomp";
import Leave from "../../component/Dashboard/leave";
import DashboardBarchart from "../../component/Dashboard/apexChart/dashboardBarchart";
import Activity from "../../component/Dashboard/activity";
import Announcement from "../../component/Dashboard/announcement";
import Quote from "../../component/Dashboard/quote";
import Timesheet from "../../component/Dashboard/timesheet";
import Datepicker from "../../component/Dashboard/datepicker";
import TimeinoutModal from "../../component/Dashboard/timeinoutModal";
import EntryModal from "../../component/Dashboard/entryModal";
import hooks from "../../hooks/react-query/queryHooks";
import { useQuery } from "react-query";
import api from "../../api/timesheet/index";
import moment from "moment";
import { AlertComponent } from "../../component/Alerts";
import { DashboardAlert } from "../../component/Alerts/Dashboard";
import Stack from "@mui/material/Stack";
import { TimesheetContext } from "../../globalstate/UserContext/timesheetcontext";
import { GlobalContext } from "../../App";
import {
  getWeeklyConsoUI,
  getWeeklyTimesheet,
  getHours,
} from "../../helper/timesheet";
import { RequestContext } from "../../globalstate/UserContext/RequestContext";
import Reactions from "../../component/Dashboard/reactions";

const checkGeolocationPermission = async () => {
  const permissionStatus = await navigator.permissions.query({
    name: "geolocation",
  });
  return permissionStatus.state === "granted";
};

const checkCameraPermission = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    stream.getTracks().forEach((track) => track.stop());
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const checkGeoCameraEnabled = async () => {
  const geolocationEnabled = await checkGeolocationPermission();
  const cameraEnabled = await checkCameraPermission();
  return { geolocationEnabled, cameraEnabled };
};

const Dashboard = () => {
  const [isCameraEnabled, setIsCameraEnabled] = useState(false);
  const [isGeolocationEnabled, setIsGeolocationEnabled] = useState(false);

  const { handleViewSurvey2 } = React.useContext(RequestContext);
  const { settings, projectActivity, projectCode } = useContext(GlobalContext);
  const [timeinout, setTimeinout] = useState(false);
  const [entry, setEntry] = useState(false);
  const [leave, setLeave] = useState(false);
  const [company_name, setCompanyName] = useState("");
  const tab = useRef(null);
  const {
    setTimesheet,
    setRefetchMonthlyTimesheet,
    setRefetchWeeklyTimesheet,
    setWeeklyDataTimesheet,
    setWeeks,
    apiResponse,
    setAPIResponse,
    setWeekDates,
    weekDates,
    setTotalHours,
    setUserDepartment,
    setOpenBarChartEntryModal,
  } = useContext(TimesheetContext);
  const [selectedDate, setDate] = useState(new Date());
  const [monthDate, setMonthDate] = useState(new Date());
  const [isInMonth, setIsInMonth] = useState(true);
  const [barChartData, setBarChartData] = useState([]);
  const [reminder, setReminder] = useState([]);
  const [modal, setModal] = useState(false);

  const getUser = JSON.parse(localStorage.getItem("user"));
  const id = getUser?.id;
  const year = moment(selectedDate, "DD/MM/YYYY").format("YYYY");
  const month = moment(selectedDate, "DD/MM/YYYY").format("MM");
  const timesheetMonthPayload = { id, year, month };

  const {
    status: statusMonthlyTimesheet,
    data: dataMonthlyTimesheet,
    isLoading,
    refetch: refetchMonthlyTimesheet,
    isFetching,
  } = useQuery(
    ["monthly-timesheet"],
    () => api.GetTimesheetMonth(timesheetMonthPayload),
    { enabled: isInMonth && selectedDate instanceof Date }
  );

  useEffect(() => {
    const getWeekDates = () => {
      let weekDates = [];

      for (let i = 0; i < 7; i++)
        weekDates.push(
          moment(selectedDate)
            .startOf("isoWeek")
            .day(1)
            .add(i, "d")
            .format("ll")
        );
      return weekDates;
    };
    setWeekDates(getWeekDates());
    setWeeks(getWeekDates());
  }, [isInMonth, selectedDate, setWeekDates, setWeeks]);

  useEffect(() => {
    //check if selectedDate is in Month
    if (
      moment(selectedDate).isSame(monthDate, "month") &&
      statusMonthlyTimesheet === "success"
    ) {
      setIsInMonth(false);
    } else if (!statusMonthlyTimesheet === "success") {
      setIsInMonth(true);
    } else if (!moment(selectedDate).isSame(monthDate, "month")) {
      setMonthDate(selectedDate);
      setIsInMonth(true);
    }
  }, [monthDate, selectedDate, statusMonthlyTimesheet, weekDates]);

  useEffect(() => {
    if (statusMonthlyTimesheet === "success") {
      setWeeklyDataTimesheet(
        getWeeklyConsoUI(dataMonthlyTimesheet?.time_data_conso, weekDates)
      );
      setBarChartData({
        time_data: getWeeklyTimesheet(
          dataMonthlyTimesheet.time_data,
          weekDates
        ),
        today_total_hours: getHours(dataMonthlyTimesheet.time_data, weekDates)
          .today,
        weekly_total_hours: getHours(dataMonthlyTimesheet.time_data, weekDates)
          .weeklyTotal,
      });
      setTimesheet(dataMonthlyTimesheet.time_data);

      setUserDepartment(dataMonthlyTimesheet?.employee?.department?.name);

      setTotalHours(getHours(dataMonthlyTimesheet.time_data, weekDates).weekly);

      setOpenBarChartEntryModal(
        getWeeklyTimesheet(dataMonthlyTimesheet.time_data, weekDates)
      );
    }

    setRefetchMonthlyTimesheet(() => () => refetchMonthlyTimesheet());
  }, [
    statusMonthlyTimesheet,
    setTimesheet,
    dataMonthlyTimesheet,
    setRefetchMonthlyTimesheet,
    refetchMonthlyTimesheet,
    setRefetchWeeklyTimesheet,
    setWeeklyDataTimesheet,
    setUserDepartment,
    setOpenBarChartEntryModal,
    weekDates,
    setTotalHours,
  ]);

  const CloseAlert = () => {
    setAPIResponse();
  };
  const [timesheetIndex, setTimesheetIndex] = useState(null);

  const handleTimeInOut = async (value) => {
    const { geolocationEnabled, cameraEnabled } = await checkGeoCameraEnabled();
    setIsGeolocationEnabled(geolocationEnabled);
    setIsCameraEnabled(cameraEnabled);
    tab.current = value;
    timeinout ? setTimeinout(false) : setTimeinout(true);
  };

  const handleEntryModal = (timeDataIndex) => {
    setTimesheetIndex(timeDataIndex);
    entry ? setEntry(false) : setEntry(true);
  };
  const handleLeaveModal = () => {
    leave ? setLeave(false) : setLeave(true);
  };

  const [open, setOpen] = useState(false);

  const {
    data: timesheetUpdateData,
    mutate: addEntryMutate,
    reset,
  } = hooks.useUpdateTimesheetEntry();

  const {
    data: timesheetNewData,
    mutate: entryDeleteMutate,
    reset: resetEntry,
  } = hooks.useDeleteTimesheetEntry();
  const {
    data: timesheetAddEntryData,
    mutate: AddTimesheetMutate,
    reset: resetAddTimesheet,
  } = hooks.useAddTimesheetEntry();

  const { data: getReminder } = hooks.useReminder();

  useEffect(() => {
    if (getReminder) {
      setReminder(getReminder.data);
    }
    if (getReminder && getReminder.data) {
      const noConfirmation = getReminder.data.some(
        (el) => el.confirmation.length === 0
      );
      setModal(noConfirmation);
    }
  }, [getReminder]);

  const submitUpdatedEntry = (updatedEntry) => {
    setOpen(true);
    addEntryMutate(updatedEntry, {
      onSuccess: () => {
        setOpen(false);
        setOpen(false);
        handleEntryModal(null);
      },
    });
  };

  const deleteEntry = (id) => {
    setOpen(true);
    entryDeleteMutate(id, {
      onSuccess: () => {
        setOpen(false);
        handleEntryModal(null);
      },
    });
  };

  const addEntry = (addEntries) => {
    setOpen(true);
    AddTimesheetMutate(addEntries, {
      onSuccess: () => {
        setOpen(false);
        handleEntryModal(null);
      },
    });
  };
  const [imgUrl, setImgurl] = React.useState("");
  useEffect(() => {
    //eslint-disable-next-line
    settings.map((row) => {
      if (row.setting === "company_name") {
        setCompanyName(row.value);
      }
      if (row.setting === "company_logo") {
        setImgurl(row.value);
      }
    });
  }, [settings]);

  const handleChangeDate = (e) => {
    setDate(e);
  };

  return (
    <div style={{ padding: "5px 10px" }}>
      <Grid container>
        <Grid item xs={12} lg={7}>
          <GreetingCard
            employee={dataMonthlyTimesheet}
            isLoading={isLoading}
            companyName={company_name ?? "FLIGNO"}
          />
        </Grid>
        <Grid item xs={12} lg={5}>
          <Announcement />
        </Grid>

        <Grid item xs={12} lg={6}>
          <DashboardBarchart
            user={barChartData}
            handleEntryModal={handleEntryModal}
            isFetching={isFetching}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Timeinout
            handleTimeInOut={handleTimeInOut}
            handleEntryModal={handleEntryModal}
            imgUrl={imgUrl}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Quote companyName={company_name ?? "Fligno"} />
        </Grid>

        <Grid item xs={12} lg={4}>
          <Activity />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Leave
            handleLeaveModal={handleLeaveModal}
            user={dataMonthlyTimesheet}
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          {statusMonthlyTimesheet === "success" && (
            <Timesheet isFetching={isFetching} />
          )}
          {apiResponse !== undefined && (
            <Stack
              sx={{
                width: "fit-content",
                position: "sticky",
                bottom: 0,
                zIndex: 1000,
              }}
            >
              <AlertComponent
                severity={apiResponse.status}
                message={apiResponse.msg}
                handleOpenReaction={CloseAlert}
              />
            </Stack>
          )}
        </Grid>
        <Grid item xs={12} lg={4}>
          <Datepicker
            onChange={handleChangeDate}
            value={selectedDate}
            height={"325px"}
            isFetching={isFetching}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <Reactions
            handleTimeInOut={handleTimeInOut}
            handleEntryModal={handleEntryModal}
            imgUrl={imgUrl}
          />
        </Grid>
      </Grid>
      {statusMonthlyTimesheet === "success" && tab.current && (
        <TimeinoutModal
          isGeolocationEnabled={isGeolocationEnabled}
          isCameraEnabled={isCameraEnabled}
          timeinout={timeinout}
          handleTimeInOut={handleTimeInOut}
          employee={dataMonthlyTimesheet}
          tab={tab.current}
          handleViewSurvey2={handleViewSurvey2}
        />
      )}
      {entry && (
        <EntryModal
          entry={entry}
          handleEntryModal={handleEntryModal}
          employee={dataMonthlyTimesheet}
          projectCode={projectCode}
          projectActivity={projectActivity}
          timesheetIndex={timesheetIndex}
          submitUpdatedEntry={submitUpdatedEntry}
          deleteEntry={deleteEntry}
          addEntry={addEntry}
          open={open}
        />
      )}

      <Stack
        sx={{
          width: "fit-content",
          position: "sticky",
          bottom: 0,
          zIndex: 1000,
        }}
      >
        {timesheetUpdateData && (
          <DashboardAlert
            severity={"success"}
            message={timesheetUpdateData.message}
            reset={reset}
          />
        )}
        {timesheetNewData && (
          <DashboardAlert
            severity={"error"}
            message={timesheetNewData.message}
            reset={resetEntry}
          />
        )}
        {timesheetAddEntryData && (
          <DashboardAlert
            severity={"info"}
            message={timesheetAddEntryData.data.msg}
            reset={resetAddTimesheet}
          />
        )}
      </Stack>
    </div>
  );
};

export default Dashboard;
