import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import NotificationButton from "./notification";
import ProfileButton from "./profile";
import "../../styles/Header/index.scss";
import { NotificationProvider } from "../../globalstate/UserContext/Notification";

const Header = ({ handleToggleSidebar, settings, setting }) => {
  const [company_logo, setCompanyLogo] = React.useState(null);

  React.useEffect(() => {
    //setting base64 formatted(img)  to a state so mui5 can process image
    //directly setting base64 to avatar component wont work
    settings.map(
      (row) => row.setting === "company_logo" && setCompanyLogo(row.value)
    );
  }, [settings]);

  const findSetting = (settParam) =>
    //this function will be used when theme manager is up
    //status currently under development
    settings.map((st) => {
      if (st.setting === settParam) {
        return st.value;
      }
      return false;
    });
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: setting?.bgcolor_appbar
            ? setting.bgcolor_appbar
            : findSetting("bgcolor_appbar"),
          boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
        }}
      >
        <Toolbar>
          <Box
            className="header-box"
            sx={{
              display: "flex",
              alignItems: "center",
              transition: "width 0.3s",
            }}
          >
            <Box width="50px">
              <NavLink to="/dashboard">
                {company_logo && (
                  <Avatar alt="Logo" src={company_logo} className="Logos" />
                )}
              </NavLink>
            </Box>
            <Box>
              {settings && (
                <Typography
                  variant="h1"
                  className="header-h1"
                  color="inherit"
                  sx={{
                    paddingLeft: "10px",
                    fontWeight: 600,
                    fontSize: "1.6rem",
                    lineHeight: 1.8,
                    transition: "width 2s linear 3s;",
                  }}
                >
                  {findSetting("company_name")}
                </Typography>
              )}
            </Box>
          </Box>

          <Box className="ham-menu">
            <IconButton
              className="header-ham"
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2, display: "none" }}
              onClick={() => {
                handleToggleSidebar(true);
              }}
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Box className="header-right">
            <NotificationProvider>
              <NotificationButton />
            </NotificationProvider>

            <Box className="box-divider"></Box>
            <ProfileButton />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
