import { useState, memo } from "react";

import { useQueryClient } from "react-query";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import HomeWorkRoundedIcon from "@mui/icons-material/HomeWorkRounded";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { WORKPLACE_ATTACHMENT_IMG_KEY } from "../../../../lib/UserAppMgmt/UserApplications/";

import BaseModal from "./BaseModal";
import { AttachFileOutlined } from "@mui/icons-material";

const fallbackImageUrl =
  "https://via.placeholder.com/854x492.png?text=Image+Not+Available";

const BASE_MODAL_STYLES = {
  display: "flex",
  flexDirection: "column",
  padding: "24px",
  flexFlow: "column",
  gap: "28px",
};

const HEADER_ICON_STYLES = {
  display: "flex",
  padding: "4px",
  backgroundColor: "#21293C",
  borderRadius: "3px",
};

const IMAGE_GALLERY_CONTAINER_STYLES = {
  width: "100%",
  flexGrow: "1",
  borderRadius: "3px",
  display: "grid",
  placeItems: "center",
  overflow: "auto",
};

const WorkplaceImageModal = ({
  open = false,
  onClose,
  id,
  attachment,
  isLoading,
  isWfh,
}) => {
  const [showNav, setShowNav] = useState(false);
  const [showIndex, setShowIndex] = useState(false);

  const queryClient = useQueryClient();

  const showNavHandler = () => {
    setShowIndex(true);
    setShowNav(true);
  };

  const closeModalHandler = () => {
    queryClient.resetQueries([WORKPLACE_ATTACHMENT_IMG_KEY, id]);
    onClose();
  };

  const backgroundColor = isLoading ? "#f1f3f5" : "transparent";

  return (
    <BaseModal open={open} onClose={closeModalHandler} sx={BASE_MODAL_STYLES}>
      <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
        <Box sx={HEADER_ICON_STYLES}>
          {isWfh ? (
            <HomeWorkRoundedIcon sx={{ color: "#fff" }} />
          ) : (
            <AttachFileOutlined sx={{ color: "#fff" }} />
          )}
        </Box>
        <Typography fontSize={18}>
          {isWfh ? "Workplace" : "Attachment"}
        </Typography>
      </Box>

      <Box sx={{ ...IMAGE_GALLERY_CONTAINER_STYLES, backgroundColor }}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <ImageGallery
            showIndex={showIndex}
            showPlayButton={false}
            showThumbnails={false}
            showNav={showNav}
            items={attachment}
            showFullscreenButton={showNav}
            onImageLoad={showNavHandler}
            onErrorImageURL={fallbackImageUrl}
          />
        )}
      </Box>
    </BaseModal>
  );
};

export default memo(WorkplaceImageModal);
