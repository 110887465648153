import React from "react";

import { Navigate, Outlet } from "react-router-dom";
import { getRoles } from "../hooks/customHooks/auth";

const RoleBasedRoutes = ({ route }) => {
  const roles = getRoles();

  return roles.some((role) => {
    if (route.roles.includes("all")) {
      return true;
    } else {
      return route.roles.includes(role);
    }
  }) ? (
    <Outlet />
  ) : (
    <Navigate to="/unauthorized" replace />
  );
};

export default RoleBasedRoutes;
