import React from "react";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import PulseAnswer from "./pulseAnswer";
const PulseResult = ({ open, handlePulseResult, resultID, surveyAnswer }) => {
  return (
    <Modal open={open}>
      <Card
        className="login-modal leave1-modal"
        sx={{
          minWidth: "550px",
          maxHeight: "calc(100vh - 75px)",
          overflow: "auto",
        }}
      >
        <CardHeader
          title={
            <Typography variant="h3" className="h3-bar">
              Survey Results
            </Typography>
          }
          action={
            <IconButton
              onClick={() => {
                handlePulseResult(null);
              }}
            >
              <CloseOutlinedIcon sx={{ width: "15px", height: "15px" }} />
            </IconButton>
          }
        />
        <CardContent>
          <Grid container columnSpacing={2} rowSpacing={2}>
            {surveyAnswer &&
              surveyAnswer.map((sq, i) => <PulseAnswer key={i} sq={sq} />)}
          </Grid>
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "right",
            mt: "5px",
            mb: "10px",
          }}
        >
          <Button
            variant="contained"
            disableElevation
            type="submit"
            onClick={() => {
              handlePulseResult(null);
            }}
          >
            Exit
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default PulseResult;
