import React from "react";
import Chart from "react-apexcharts";

const AnswerButton = ({ answerData }) => {
  const categories = answerData.filter((af) => {
    return af[0];
  });

  const options = {
    chart: {
      type: "bar",
      stacked: true,
      width: "100%",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: "rounded",
        columnWidth: "50%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: categories,
    },

    fill: {
      opacity: 1,
    },
  };
  return (
    <>
      <Chart
        options={options}
        series={
          answerData.length > 0
            ? answerData.map((dt, index) => {
                switch (index) {
                  case 0:
                    return {
                      name: dt[0],
                      data: [dt[1], 0, 0, 0],
                      color: "#F44335",
                    };
                  case 1:
                    return {
                      name: dt[0],
                      data: [0, dt[1], 0, 0],
                      color: "#00C292",
                    };
                  case 2:
                    return {
                      name: dt[0],
                      data: [0, 0, dt[1], 0],
                      color: "#898DFB",
                    };
                  case 3:
                    return {
                      name: dt[0],
                      data: [0, 0, 0, dt[1]],
                      color: "#FFAC30",
                    };
                  case 4:
                    return {
                      name: dt[0],
                      data: [0, 0, 0, 0, dt[1]],
                      color: "#212A3C",
                    };
                  default:
                    break;
                }
              })
            : [
                {
                  name: "",
                  data: [0, 0, 0, 0],
                },
              ]
        }
        type="bar"
        height="300px"
      />
    </>
  );
};

export default AnswerButton;
