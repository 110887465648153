import React from "react";
import {
  TableRow,
  TableCell,
  Tooltip,
  TextField,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";

const AddRow = ({
  header,
  hidden = true,
  handleCancel,
  handleConfirm,
  formFields = [],
}) => {
  const [form, setForm] = useState();

  const handleSubmit = () => {
    handleConfirm(form);
  };

  return (
    <>
      {hidden && (
        <TableRow>
          {formFields &&
            formFields.map((formField, index) => (
              <TableCell key={index} scope="row">
                {!formField.type && (
                  <Tooltip
                    open={formField.helperText ? true : false}
                    title={formField.helperText ? formField.helperText : ""}
                    arrow
                  >
                    <TextField
                      placeholder={formField.label}
                      sx={{ minWidth: "300px" }}
                      defaultValue=""
                      onChange={(e) => {
                        setForm({
                          ...form,
                          [formField.payload]: e.target.value,
                        });
                      }}
                      error={formField.helperText && true}
                    ></TextField>
                  </Tooltip>
                )}

                {formField.type === "text" && (
                  <Tooltip
                    open={formField.helperText ? true : false}
                    title={formField.helperText ? formField.helperText : ""}
                    arrow
                  >
                    <TextField
                      placeholder={formField.label}
                      defaultValue=""
                      size="small"
                      sx={{ width: "175px" }}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          [formField.payload]: e.target.value,
                        });
                      }}
                      error={formField.helperText && true}
                    ></TextField>
                  </Tooltip>
                )}

                {formField.type === "select" && (
                  <>
                    <Select
                      defaultValue="0"
                      size="small"
                      onChange={(e) => {
                        setForm({
                          ...form,
                          [formField.payload]: e.target.value,
                        });
                      }}
                      error={formField.helperText && true}
                    >
                      {formField.options &&
                        formField.options.map((option, index) => {
                          return (
                            <MenuItem key={index} value={option.value}>
                              {option.label}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </>
                )}
              </TableCell>
            ))}
          <TableCell key="add-row-confirm">
            <IconButton aria-label="confirm" onClick={(e) => handleSubmit()}>
              <CheckIcon />
            </IconButton>
            <IconButton aria-label="cancel" onClick={handleCancel}>
              <CancelIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default AddRow;
