import React, { createContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import Loader from "../../../component/Loader";
import { getRangeDates, sortArrayTable } from "../../../helper/Reports";
import { TableBody, TableRow, TablePagination, Tooltip } from "@mui/material";
import PerClientFilter from "./PerClientFilter";
import ExportModal from "./ExportModal";
import FirstCollapse from "./FirstCollapse";
import HeaderReports from "../../../component/Reports/Header";
import EmptyData from "../../../component/Reports/EmptyData";
import TableHeader from "../../../component/Reports/TableHeader.jsx";
import ReportTableContainter from "../../../component/Reports/TableContainter";
import { PerClientTableHeader } from "../../../helper/ReportPerClient";
import { useGetPerClient } from "../../../api/reportPerClient";
import { Box } from "@mui/system";

export const PerClientV5Context = createContext({});

const convert = (date) => moment(date).format("YYYY-MM-DD");

const PerClientActivity = () => {
  const [range, setRange] = useState("This Week");
  const [isWeekends, setIsWeekends] = useState(true);
  const [isInactiveUsers, setIsInactiveUsers] = useState(true);
  const [startDate, setStartDate] = useState(
    convert(new Date(moment().startOf("week")))
  );
  const [endDate, setEndDate] = useState(
    convert(new Date(moment().endOf("week")))
  );
  const [search, setSearch] = useState("");
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(1);
  const [orderDirection, setOrderDirection] = useState("desc");
  const [tableData, setTableData] = useState([]);

  const handleRangeChange = (e) => {
    const date = getRangeDates(e.target.value);
    setRange(e.target.value);
    setStartDate(convert(new Date(date.fromDate)));
    setEndDate(convert(new Date(date.toDate)));
  };

  const handleChangeRowsPerPage = (e) => {
    setPage(1);
    setLimit(e.target.value);
  };

  const handleRefresh = () => {
    refetch();
    setPage(1);
    setLimit(30);
  };

  let payload = {
    page: page,
    page_size: limit,
    fromDate: startDate,
    toDate: endDate,
    inActive: isInactiveUsers,
    weekEnd: isWeekends,
    search: search,
  };

  const { data: perClientData, refetch, isFetching } = useGetPerClient(payload);

  useEffect(() => {
    if (perClientData) {
      setTableData(perClientData?.data || []);
    }
  }, [perClientData]);

  const handleChangePage = (page) => {
    setPage(page + 1);
  };

  const handleSortRequest = (key, parentKey) => {
    let direction = "asc";
    direction = orderDirection === "desc" ? "asc" : "desc";
    setOrderDirection(direction);
    setTableData((prev) => sortArrayTable(prev, direction, key, parentKey));
  };

  const value = useMemo(
    () => ({
      isExportModalOpen,
      setIsExportModalOpen,
      isWeekends,
      setIsWeekends,
      isInactiveUsers,
      setIsInactiveUsers,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      search,
      setSearch,
      refetch,
      range,
      setRange,
      isFetching,
      setPage,
    }),
    [
      isExportModalOpen,
      setIsExportModalOpen,
      range,
      isWeekends,
      setIsWeekends,
      isInactiveUsers,
      setIsInactiveUsers,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      search,
      setSearch,
      refetch,
      setRange,
      isFetching,
      setPage,
    ]
  );
  return (
    <div>
      <Loader isLoading={isFetching} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          cursor: "context-menu",
        }}
      >
        <Tooltip
          title={"View company timesheet per client"}
          placement={"right"}
          arrow
        >
          <Box>
            <HeaderReports text={"Timesheet Per Client"} />
          </Box>
        </Tooltip>
      </Box>
      <PerClientV5Context.Provider value={value}>
        {/* Filter */}
        <PerClientFilter
          handleRangeChange={handleRangeChange}
          handleRefresh={handleRefresh}
        />
        <ExportModal />
        {/* End of  Filter */}

        {!tableData?.length && !isFetching ? (
          <EmptyData />
        ) : (
          <ReportTableContainter>
            <TableHeader
              isCollapsable={true}
              headerList={PerClientTableHeader}
              handleSortRequest={handleSortRequest}
              orderDirection={orderDirection}
            />
            <TableBody>
              {tableData?.map((item) => {
                return <FirstCollapse key={item.code_id} item={item} />;
              })}
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                  count={perClientData?.total || 0}
                  page={
                    perClientData?.current_page
                      ? perClientData.current_page - 1
                      : 0
                  }
                  onPageChange={(e, page) => {
                    handleChangePage(page);
                  }}
                  showFirstButton={true}
                  showLastButton={true}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableBody>
          </ReportTableContainter>
        )}
      </PerClientV5Context.Provider>
    </div>
  );
};

export default PerClientActivity;
