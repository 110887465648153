import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

export default function DropDownSelect({
  DropDownData,
  minWidth,
  value,
  label,
  variant,
  size,
  onChange,
  objectName,
}) {
  return (
    <FormControl variant={variant} sx={{ minWidth: minWidth }} size={size}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        sx={{ textAlign: "center", fontSize: "0.75rem" }}
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={value}
        onChange={onChange}
        size={size}
        label={label}
      >
        {DropDownData.map((item, key) => (
          <MenuItem
            value={item[objectName?.val]}
            style={{ display: "flex", justifyContent: "center" }}
            key={key}
          >
            {item[objectName?.name]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
