import TextField from "@mui/material/TextField";
import React from "react";

const Single = ({ register, ind }) => {
  register(`answerType${ind}`, { value: "single" });
  const single = `single${ind}`;
  return (
    <TextField
      variant="outlined"
      label="Enter answer here"
      fullWidth
      size="small"
      {...register(single)}
      required
    />
  );
};

export default Single;
