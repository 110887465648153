import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { WEEKS_PER_PAGE_OPTIONS } from "../../../../lib/evaluation/timesheetWeeklyReport";

const DROPDOWN_STYLES = {
  height: "45px",
  ".MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
};

const WeekPerPageSelect = ({ limit, handleChangeLimit }) => {
  const handleWeeksPerPageChange = (event) => {
    handleChangeLimit(event.target.value);
  };
  return (
    <Stack direction="row" alignItems="center" paddingLeft="8px">
      <Typography sx={{ fontSize: "12px" }}>Weeks per page:</Typography>

      <FormControl>
        <Select
          value={limit}
          onChange={handleWeeksPerPageChange}
          input={<OutlinedInput />}
          sx={DROPDOWN_STYLES}
        >
          {WEEKS_PER_PAGE_OPTIONS.map((option) => (
            <MenuItem key={option} value={option}>
              {option === 52 ? "ALL" : option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default WeekPerPageSelect;
