import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import project_codes_sample_csv from "../../../images/project_codes_sample_csv.png";
import { downloadSampleCSV } from "../../../helper/ProjectCodeSettings";
import CancelSubmitButton from "../../CancelSubmitButton";
import { useImportCode } from "../../../hooks/react-query/Settings/timsheet";
const ImportCsv = ({ open, handleClose, successMsg }) => {
  const initState = { csv: null, csv_name: null, error: null };
  const [file, setFile] = useState(initState);
  const { mutate, isLoading } = useImportCode();

  const selectedCsv = (event) => {
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setFile({ csv: reader.result, csv_name: file.name, error: null });
    };
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    mutate([file.csv], {
      onSuccess: (data) => {
        successMsg(data.msg);
        setFile(initState);
        handleClose();
      },
      onError: (data) => {
        setFile({ ...initState, error: data.response.data.error });
      },
    });
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle id="max-width-dialog-title">
        Import CSV File of Project Codes
      </DialogTitle>
      <DialogContent style={{ marginTop: "25px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ paddingRight: "10px" }}>
            <Button
              variant="contained"
              component="label"
              style={{ fontSize: "10px" }}
              startIcon={<FileUploadIcon />}
            >
              Select File
              <input
                hidden
                accept=".csv"
                multiple
                type="file"
                onChange={selectedCsv}
              />
            </Button>
          </div>
          {file.csv_name && (
            <div style={{ paddingLeft: "10px" }}>
              <Typography>File: {file.csv_name}</Typography>
            </div>
          )}
          {file.error && (
            <div style={{ color: "red", fontSize: "0.85rem" }}>
              {file.error}
            </div>
          )}
        </div>
        <div style={{ marginTop: "10px" }}>
          <div style={{ fontWeight: "bold" }}>Note:</div>
          <div style={{ display: "flex", fontSize: "0.85rem" }}>
            CSV format must be the same below. The headers must be "Code Name",
            and "Description". You can download the sample CSV file&nbsp;
            <div
              style={{
                textDecoration: "underline",
                color: "blue",
                cursor: "pointer",
              }}
              onClick={downloadSampleCSV}
            >
              {" "}
              here
            </div>
            .
          </div>
        </div>
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={project_codes_sample_csv}
            alt="Sample csv file screenshot"
          />
        </div>
        <div
          style={{ marginTop: "15px", display: "flex", justifyContent: "end" }}
        >
          <CancelSubmitButton
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            Disabled={file.csv === null}
            Loading={isLoading}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ImportCsv;
