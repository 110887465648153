export const isJsonString = (str) => {
  try {
    const arr = JSON.parse(str);
    if (arr instanceof Array) {
      return arr;
    }
    if (typeof arr === "string") {
      return [arr];
    }
  } catch (e) {
    return false;
  }
};

export const FindDataByID = (arr, id) => {
  return arr.data?.find((data) => data.id === id);
};

export const findDataByArrID = (arr, idArr) => {
  return arr.data?.filter((data) => idArr.includes(data.id));
};

export const FindDataByName = (arr, name) => {
  return arr.find((val) => val.name.toLowerCase() === name.toLowerCase());
};

export const FindDepartment = (arr, name) => {
  return arr.data?.find((val) => val.short_name === name);
};
export const TableHeader = () => {
  return [
    "Code",
    "Description",
    "Client",
    "Type",
    "Department",
    "Status",
    "Budget(hours)",
    "Balance",
    "Restricted",
    "Monthly Budget(hours)",
    "Action",
  ];
};

export const ActiveOrNot = [
  { name: "Active", id: 1 },
  { name: "Inactive", id: 0 },
];

export const RestrictedOrNot = [
  { name: "Restricted", id: 1 },
  { name: "Open", id: 0 },
];

export const downloadSampleCSV = () => {
  const row =
    `"PROJ1","First company project"\r\n` +
    `"CHAT","Chat system for internal use"\r\n`;

  let csvContent =
    "data:text/csv;charset=utf-8," +
    ["Code Name", "Description"].join(",") +
    "\n" +
    row;

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `project_codes_sample_csv_file.csv`);
  document.body.appendChild(link);
  link.click();
};

export const SearchRow = (keywords, DataGetProjectCode) => {
  const filteredRows = DataGetProjectCode?.codes.filter((fd) => {
    return (
      fd.name.toLowerCase().includes(keywords.toLowerCase()) ||
      (fd.description !== null &&
        fd.description.toLowerCase().includes(keywords.toLowerCase()))
    );
  });
  return filteredRows;
};

export const FilterStatus = (arr, showInactive) => {
  const filter = arr?.filter((fd) => {
    return fd.status === 1;
  });
  if (showInactive) {
    return arr;
  } else {
    return filter;
  }
};

export const Departments = (arr, dept) => {
  return arr.filter((data) =>
    dept === "ALL"
      ? arr
      : isJsonString(data.key)
      ? isJsonString(data.key).includes(dept)
      : data.key === dept
  );
};
