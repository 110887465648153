import { TextField as MuiTextField } from "@mui/material";
const TextField = ({ field, form, setForm }) => {
  return (
    <MuiTextField
      autoFocus
      margin="dense"
      label={field.label}
      fullWidth
      defaultValue=""
      variant="outlined"
      onChange={(e) => {
        setForm({
          ...form,
          [field.payload]: e.target.value,
        });
      }}
      helperText={field.helperText}
      error={field.helperText && true}
    />
  );
};

export default TextField;
