import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  TextField,
  IconButton,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CheckIcon from "@mui/icons-material/Check";
import hooks from "../../../hooks/react-query/MOTD";
import { format } from "date-fns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { isAfter, isBefore } from "date-fns";
import { toast } from "react-toastify";
import { Box, Button } from "@mui/material";
import DeleteDocument from "./DeleteDocument";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Tooltip } from "@mui/material";

const MotdList = ({ item, key }) => {
  const [edit, setEdit] = useState(true);
  const [startDate, setStartDate] = useState(
    format(new Date(item?.from), "yyyy-MM-dd")
  );
  const [endDate, setEndDate] = useState(
    format(new Date(item?.to), "yyyy-MM-dd")
  );
  const [message, setMessage] = useState(item?.message);
  const [title, setTitle] = useState(item?.title);
  const [status, setStatus] = useState(item?.status);
  const [selected, setSelected] = useState(null);
  const [documentName, setDocumentName] = useState("Upload a document.");
  const [document, setDocument] = useState("");
  const [path, setPath] = useState(true);
  const [errors, setErrors] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const { mutate: deleteMOTD } = hooks.useDeleteMOTD();
  const { mutate: updateMOTD } = hooks.useUpdateMotd();
  const deleteHandler = () => {
    const data = {
      id: item?.id,
    };
    deleteMOTD(data);
  };

  const deleteDocument = (id) => {
    setDeleteId(id);
    setDeleteModal(true);
  };

  const updateHandler = () => {
    const data = {
      id: item?.id,
      message: message,
      from: startDate,
      to: endDate,
      status: status,
      document: document,
      documentName: documentName,
    };

    const formData = new FormData();
    formData.append("id", item?.id);
    formData.append("title", title);
    formData.append("message", message);
    formData.append("status", status);
    formData.append("from", startDate);
    formData.append("to", endDate);
    formData.append("document", document);
    formData.append("documentName", documentName);
    formData.append("path", path);
    updateMOTD(formData);
    setEdit(true);
  };

  const uploadFile = (e) => {
    setDocumentName(e.target.files[0].name);
    setDocument(e.target.files[0]);
    if (e.target.files[0].size > 3000000) {
      setErrors("Maximum upload file size: 3MB");
      setDocumentName("Upload a document.");
      setDocument("");
    } else {
      setErrors("");
    }
  };

  const setUpdate = (data) => {
    setPath(false);
    setDocument("");
    setDocumentName("Upload a document.");
  };

  return (
    <>
      <DeleteDocument
        id={deleteId}
        open={deleteModal}
        handleClose={setDeleteModal}
        setUpdate={setUpdate}
      ></DeleteDocument>
      <TableRow key={item?.id}>
        {selected === item?.id ? (
          <>
            <TableCell
              colSpan={6}
              style={{
                displat: "flex",
                justifyContent: "center",
              }}
            >
              Are you sure want to delete this Announcement?
            </TableCell>
            <TableCell>
              <IconButton sx={{ marginRight: "5px" }} onClick={deleteHandler}>
                <CheckIcon style={{ color: "#00C292" }} />
              </IconButton>
              <IconButton
                sx={{ marginRight: "5px" }}
                onClick={() => setSelected(null)}
              >
                <CloseIcon style={{ color: "#ff0000" }} />
              </IconButton>
            </TableCell>
          </>
        ) : (
          <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TableCell>
                <DatePicker
                  className="datepicker"
                  value={startDate}
                  readOnly={edit === true ? true : false}
                  disableOpenPicker={edit}
                  onChange={(newValue) => {
                    if (isAfter(newValue, new Date(endDate))) {
                      toast.error("Start date should not be after end date.");
                      return;
                    }
                    setStartDate(format(newValue, "yyyy-MM-dd"));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      size="small"
                      sx={{
                        width: "120px",
                        fontSize: 10,
                      }}
                    />
                  )}
                  InputProps={{
                    readOnly: edit,
                    disableUnderline: edit,
                  }}
                />
              </TableCell>
              <TableCell>
                <DatePicker
                  className="datepicker"
                  value={endDate}
                  readOnly={edit === true ? true : false}
                  disableOpenPicker={edit}
                  onChange={(newValue) => {
                    if (isBefore(newValue, new Date(startDate))) {
                      toast.error("End date should not be before start date.");
                      return;
                    }
                    setEndDate(format(newValue, "yyyy-MM-dd"));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      size="small"
                      sx={{
                        width: "120px",
                      }}
                    />
                  )}
                  InputProps={{
                    readOnly: edit,
                    disableUnderline: edit,
                  }}
                />
              </TableCell>
            </LocalizationProvider>
            <TableCell>
              <TextField
                required
                defaultValue={item.title}
                InputProps={{
                  readOnly: edit,
                  disableUnderline: edit,
                }}
                variant="standard"
                fullWidth
                multiline
                onChange={(e) => setTitle(e.target.value)}
                inputProps={{
                  min: 0,
                  style: { textAlign: "left", width: 150 },
                }}
              />
            </TableCell>
            <TableCell>
              <TextField
                required
                defaultValue={item.message}
                InputProps={{
                  readOnly: edit,
                  disableUnderline: edit,
                }}
                variant="standard"
                fullWidth
                multiline
                onChange={(e) => setMessage(e.target.value)}
                inputProps={{
                  min: 0,
                  style: { textAlign: "right", width: 500 },
                }}
              />
            </TableCell>
            <TableCell>
              <TextField
                multiline
                sx={{ justifyContent: "center" }}
                required
                defaultValue={item?.confirmation.length}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                variant="standard"
                inputProps={{ min: 0, style: { textAlign: "center" } }}
              />
            </TableCell>
            <TableCell>
              <TextField
                multiline
                required
                select={edit === true ? false : true}
                InputProps={{
                  readOnly: edit,
                  disableUnderline: edit,
                }}
                value={
                  edit === false ? status : status === 1 ? "Active" : "Inactive"
                }
                variant="standard"
                onChange={(e) => setStatus(e.target.value)}
                inputProps={{ min: 0, style: { textAlign: "center" } }}
              >
                <MenuItem value={1}>Active</MenuItem>
                <MenuItem value={0}>Inactive</MenuItem>
              </TextField>
            </TableCell>
            <TableCell align="center">
              {edit === false ? (
                <Box
                  sx={{
                    display: "flex",
                    mt: 2,
                    justifyContent: "center",
                    alignItems: "flex-start",
                    marginBottom: 2,
                  }}
                >
                  <TextField
                    sx={{ minWidth: "100px" }}
                    error={errors}
                    size={"small"}
                    disabled
                    fullWidth
                    name={"document"}
                    value={documentName}
                    label={"Document"}
                    variant="outlined"
                    helperText={errors}
                  ></TextField>
                  <Button
                    component="label"
                    variant="contained"
                    className="timest-button"
                    sx={{ ml: 0.5 }}
                  >
                    Upload&nbsp;
                    <input
                      hidden
                      accept="application/pdf,application/vnd.ms-excel/*"
                      multiple
                      name="file"
                      type="file"
                      onChange={(e) => uploadFile(e)}
                    />
                  </Button>
                </Box>
              ) : (
                item?.path && (
                  <>
                    <Tooltip title="Open Document">
                      <OpenInNewIcon
                        sx={{
                          mr: 1,
                          "&:hover": { cursor: "pointer" },
                          color: "#00C292",
                        }}
                        onClick={(e) => window.open(item.path, "_blank")}
                      />
                    </Tooltip>
                    <Tooltip title="Delete Document">
                      <RemoveCircleIcon
                        sx={{
                          "&:hover": { cursor: "pointer" },
                          color: "#bb2124",
                        }}
                        onClick={(e) => deleteDocument(item.id)}
                      />
                    </Tooltip>
                  </>
                )
              )}
            </TableCell>
            <TableCell align="center">
              <div style={{ display: "flex" }}>
                {edit === true ? (
                  <IconButton
                    sx={{ marginRight: "5px" }}
                    onClick={() => setEdit(false)}
                    disabled={selected !== null}
                  >
                    <EditIcon style={{ color: "#00C292" }} />
                  </IconButton>
                ) : (
                  <IconButton
                    sx={{ marginRight: "5px" }}
                    onClick={updateHandler}
                  >
                    <CheckIcon style={{ color: "#00C292" }} />
                  </IconButton>
                )}
                {edit === true ? (
                  <IconButton
                    sx={{ marginRight: "5px" }}
                    onClick={() => setSelected(item?.id)}
                    disabled={selected !== null}
                  >
                    <DeleteOutlineIcon style={{ color: "#000000" }} />
                  </IconButton>
                ) : (
                  <IconButton
                    sx={{ marginRight: "5px" }}
                    onClick={() => setEdit(true)}
                  >
                    <CloseIcon style={{ color: "#ff0000" }} />
                  </IconButton>
                )}
              </div>
            </TableCell>
          </>
        )}
      </TableRow>
    </>
  );
};

export default MotdList;
