import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Popper from "@mui/material/Popper";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import Skills from "./skills";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const SkillPopper = ({ skillSet }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const popOpen = Boolean(anchorEl);

  const handleClickAway = () => {
    setAnchorEl(null);
  };
  return (
    <Box className="date-box">
      <IconButton aria-label="settings" onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Popper
        placement="bottom-end"
        open={popOpen}
        anchorEl={anchorEl}
        className="poper-pop"
        sx={{ inset: "-10px -30px auto auto !important" }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box
            sx={{
              p: "15px 0px",
              bgcolor: "background.paper",
              borderRadius: "10px",
              boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
              "& div.MuiDialogActions-root": {
                display: "none",
              },
              "& button.MuiPickersToolbar-penIconButton": {
                display: "none",
              },
            }}
          >
            <Box>
              <Grid container>
                <Grid item xs={5}>
                  <Divider sx={{ paddingTop: "5px" }} />
                </Grid>
                <Grid item xs={2} className="modal-detail">
                  <Typography variant="h6" className="card-typ">
                    Skills
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Divider sx={{ paddingTop: "5px" }} />
                </Grid>
              </Grid>
            </Box>
            <Skills skillSet={skillSet} />
          </Box>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};

export default SkillPopper;
