import React from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import VerifiedIcon from "@mui/icons-material/Verified";
import BlockIcon from "@mui/icons-material/Block";
import Skeleton from "@mui/material/Skeleton";
import ModeEditRoundedIcon from "@mui/icons-material/ModeEdit";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
} from "@mui/material/";

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   // "&:nth-of-type(odd)": {
//   //   backgroundColor: theme.palette.action.hover,
//   // },
//   // hide last border
//   // "&:last-child td, &:last-child th": {
//   //   border: 0,
//   // },
// // }
// ));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const TableContainerPaginatedAPI = ({
  columnhead,
  filtereditems,
  onClickIcon,
  isFetching,
  setLeaveData,
  setOpenModal,
  type,
}) => {
  const formatTwodec = (value) => value.toFixed(2);

  return (
    <div>
      <TableContainer
        sx={{ marginTop: "1rem", maxHeight: 600, borderRadius: 2 }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead className="custom-table-container">
            <TableRow>
              {columnhead.map((column, index) => (
                <Tooltip
                  title={column?.name?.replace(/\b\w/g, (c) => c.toUpperCase())}
                  placement="top-start"
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: "black",
                        "& .MuiTooltip-arrow": {
                          color: "black",
                        },
                      },
                    },
                  }}
                >
                  <StyledTableCell
                    key={index}
                    style={{
                      textTransform: "capitalize",
                      fontSize: ".9rem",
                      width: "70px",
                      lineHeight: 1,
                      fontWeight: 500,
                      color: "black",
                      borderBottomWidth: "medium",
                    }}
                  >
                    {type === "leavecredit" ? column?.shortname : column?.name}
                  </StyledTableCell>
                </Tooltip>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            className={type === "permissions" ? "custom-body-container" : ""}
          >
            {filtereditems?.length === 0 && (
              <StyledTableCell colSpan={6} align="center">
                No records found
              </StyledTableCell>
            )}
            {filtereditems?.map((row) => {
              const leaveInfo = row;
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columnhead.map((column, index) => {
                    const value = row[column?.shortname];
                    if (isFetching)
                      return (
                        <StyledTableCell>
                          <Skeleton variant="rounded" animation="wave" />
                        </StyledTableCell>
                      );
                    if (type === "audit-logs") {
                      let data = "";
                      let type = Object.prototype.toString.call(value);
                      switch (type) {
                        case "[object Array]":
                          data = value.map((data) => <li>{data}</li>);
                          break;
                        case "[object Object]":
                          data = Object.entries(value).map(([k, v]) => (
                            <li>
                              {k}:{v}
                            </li>
                          ));
                          break;
                        default: {
                          data = value;
                        }
                      }
                      return (
                        <StyledTableCell key={index} align={column?.align}>
                          {data}
                        </StyledTableCell>
                      );
                    }
                    return (
                      <StyledTableCell key={index} align={column?.align}>
                        {(type === "permissions" || type === "roles") &&
                        index !== 0 ? (
                          row[type]?.some(
                            (perm) => perm.name === column?.shortname
                          ) ? (
                            <IconButton
                              onClick={() =>
                                onClickIcon(row, column.shortname, value)
                              }
                            >
                              <VerifiedIcon sx={{ fill: "rgb(0, 194, 146)" }} />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() =>
                                onClickIcon(row, column.shortname, value)
                              }
                            >
                              <BlockIcon sx={{ fill: "red" }} />
                            </IconButton>
                          )
                        ) : typeof value === "number" ? (
                          formatTwodec(value)
                        ) : value === "Edit" ? (
                          <IconButton
                            onClick={() => {
                              setLeaveData(leaveInfo);
                              setOpenModal(true);
                            }}
                          >
                            <ModeEditRoundedIcon
                              sx={{ fill: "rgb(0, 194, 146)" }}
                            />
                          </IconButton>
                        ) : (
                          value
                        )}
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TableContainerPaginatedAPI;
