import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  Stack,
  Typography,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  TextField,
} from "@mui/material";
import Modal from "@mui/material/Modal";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function LeaveCreditModal({
  items,
  open,
  setOpenModal,
  leaveData,
  batchYear,
  submitForMutation,
  editableFields,
}) {
  const [leaveName, setLeaveName] = React.useState([]);
  const [valueIndex, setValueIndex] = React.useState("");
  const [empId, setEmpId] = React.useState("");
  const [newValue, setNewValue] = React.useState("");

  const handleClose = () => {
    resetValues();
    setOpenModal(false);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setLeaveName(
      // On autofill we get a stringified value.
      typeof value === "string" && value
    );
  };

  const resetValues = () => {
    setLeaveName([]);
    setNewValue("");
  };

  const submitData = async () => {
    submitForMutation([
      {
        cred_credits: newValue,
        cred_leave: valueIndex,
        ee_id: empId,
        cred_batch: batchYear,
        cred_year: batchYear,
      },
    ]);
    setValueIndex("");
    setNewValue("");
    setEmpId("");
    setLeaveName("");
    setOpenModal(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h4">
            Update Leave Credits
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-name-label">Leave Type</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={leaveName}
                onChange={handleChange}
                input={<OutlinedInput label="Leave Type" />}
                MenuProps={MenuProps}
              >
                {items?.map(
                  (item, index) =>
                    !editableFields.indexOf(item.name) > -1 &&
                    item.id && (
                      <MenuItem
                        key={item.name}
                        value={item.name}
                        onClick={() => {
                          setValueIndex(item.id);
                          setNewValue(leaveData[item.shortname]);
                          setEmpId(leaveData.id);
                        }}
                      >
                        {item.name}
                      </MenuItem>
                    )
                )}
              </Select>
              <TextField
                sx={{ marginTop: 2 }}
                id="value-id"
                label="Value"
                variant="outlined"
                autoFocus
                onChange={(e) => {
                  setNewValue(e.target.value);
                }}
                value={newValue}
              ></TextField>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  paddingTop: "1.5rem",
                  alignSelf: "center",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                <Button variant="outlined" color="error" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={submitData}
                  disabled={newValue?.length === 0 || leaveName?.length === 0}
                >
                  Submit
                </Button>
              </Stack>
            </FormControl>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
