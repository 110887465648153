import React from "react";
import Modal from "@mui/material/Modal";
import Grow from "@mui/material/Grow";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import hooks from "../../hooks/react-query/queryHooks";
import { useForm } from "react-hook-form";
import InputController from "../InputController";
import { yupResolver } from "@hookform/resolvers/yup";
import { shiftModalSchema } from "../../helper/Yup/shiftModalSchema";
import { format } from "date-fns";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { useQueryClient } from "react-query";
const customStyle = {
  borderRadius: "5px",
  color: "rgba(0, 0, 0, 0.53)",
  borderColor: "rgba(0, 0, 0, 0.23)",
  m: "10px 20px 20px 20px",
};
const DeleteShift = ({ delRow, setDelrow }) => {
  const queryClient = useQueryClient();

  const [deleting, setDeleting] = React.useState(false);
  const { mutate: deleteShiftMutation } = hooks.useDeleteShift();
  const handleDelete = () => {
    setDeleting(true);
    const dataParam = {
      id: delRow.id,
    };
    deleteShiftMutation(dataParam, {
      onSuccess: (request) => {
        queryClient.refetchQueries(["shifts"]);
        setDelrow(null);
      },
    });
  };
  return (
    <Modal open={delRow ? true : false}>
      <Grow
        in={delRow ? true : false}
        style={{ transformOrigin: "0 0 0" }}
        {...(delRow ? true : false ? { timeout: 500 } : {})}
        unmountOnExit
      >
        <Card className="login-modal leave1-modal">
          <CardHeader
            title={
              <Typography variant="h3" className="h3-bar">
                Delete
              </Typography>
            }
          />
          <CardContent>
            <Box sx={{ textAlign: "center" }}>
              <Typography sx={{ fontWeight: 600 }}>
                Confirm to delete shift
              </Typography>
              <Chip
                variant="outlined"
                label={
                  <Typography variant="overline">
                    {delRow.shift_name}
                  </Typography>
                }
                sx={customStyle}
              />
              <Box>
                <Button
                  variant="contained"
                  disableElevation
                  color="success"
                  sx={{ m: "5px" }}
                  onClick={() => {
                    setDelrow(null);
                  }}
                  disabled={deleting}
                >
                  cancel
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  color="error"
                  sx={{ m: "5px" }}
                  onClick={handleDelete}
                  disabled={deleting}
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grow>
    </Modal>
  );
};

export default DeleteShift;
