import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import { deptColor } from "../Colleagues/DeptColor";
import { format, differenceInMinutes, parseISO } from "date-fns";

const tableHeader = [
  "User",
  "Time In",
  "In location",
  "Time out",
  "Out Location",
  "Hours/mins",
];
const skeletonLoader = Array.from(new Array(15)).map((_, index) => (
  <TableRow key={index}>
    {tableHeader.map((_, index) => (
      <TableCell key={index}>
        <Skeleton variant="rectangular" />
      </TableCell>
    ))}
  </TableRow>
));
const BasicTable = ({
  tableData,
  dateSelected,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  inOutFilter,
}) => {
  const [current, setCurrent] = React.useState(0);

  const padTo2Digits = (num) => {
    return num.toString().padStart(2, "0");
  };

  const hoursChecker = (end, start) => {
    const diffMin = differenceInMinutes(parseISO(end), parseISO(start));
    const minutes = diffMin % 60;
    const hours = Math.floor(diffMin / 60);
    const hourMin = hours + ":" + padTo2Digits(minutes);
    return hourMin;
  };

  const typoComponent = (color, data) => (
    <Typography
      variant="h6"
      color={color}
      sx={{
        fontWeight: 400,
        fontSize: "0.75rem",
        lineHeight: 1.5,
      }}
    >
      {data}
    </Typography>
  );

  return (
    <Paper
      sx={{
        width: "100%",
        borderRadius: "10px",
      }}
    >
      <TableContainer
        sx={{
          overflow: "hidden",
          padding: "14px",
          boxShadow: "none !important",
        }}
        component={Paper}
      >
        <Table>
          <TableHead>
            <TableRow>
              {tableHeader.map((header, index) => (
                <TableCell align="left" key={index}>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 500,
                      fontSize: "0.85rem",
                      lineHeight: 1.5,
                    }}
                  >
                    {header}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {isFetchingNextPage && skeletonLoader}
            {tableData[current]?.data.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      src={
                        inOutFilter === "out"
                          ? row.time_out?.custom_photo_path
                          : row.time_in?.custom_photo_path
                      }
                    />
                    <Box sx={{ ml: "16px", position: "relative" }}>
                      <Chip
                        size="small"
                        label={
                          <Typography
                            variant="overline"
                            sx={{
                              fontSize: "0.5rem",
                              color: "#fff",
                            }}
                          >
                            {row.department?.name}
                          </Typography>
                        }
                        sx={{
                          marginTop: "-13px",
                          width: "fit-content",
                          position: "absolute",
                          left: "0px",
                          height: "15px",
                          backgroundColor: deptColor(row.department?.name),
                        }}
                      />
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "0.85rem",
                        }}
                      >
                        {row.full_name}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>

                <TableCell align="left">
                  {row.time_in
                    ? typoComponent(
                        row.time_in.time_status === "late"
                          ? "red"
                          : row.time_in.time_status === "grace"
                          ? "#F2C910"
                          : "#4ACFAB",

                        format(parseISO(row.time_in.time), "hh : mm a")
                      )
                    : "N/A"}
                </TableCell>
                {/*
 : colleague?.time_in?.time_status === "grace"
        ? "#F2C910 !important"
*/}
                <TableCell align="left" sx={{ maxWidth: "160px" }}>
                  {row.time_in
                    ? typoComponent("text.secondary", row.time_in.gps_address)
                    : "N/A"}
                </TableCell>

                <TableCell align="left">
                  {row.time_out
                    ? typoComponent(
                        row.time_out.time_status === "undertime"
                          ? "#FEC90E"
                          : "#4ACFAB",
                        format(parseISO(row.time_out.time), "hh : mm a")
                      )
                    : "N/A"}
                </TableCell>

                <TableCell align="left">
                  {row.time_out
                    ? typoComponent("text.secondary", row.time_out.gps_address)
                    : "N/A"}
                </TableCell>

                <TableCell align="left" sx={{ textAlign: "center" }}>
                  {row.time_out ? (
                    <Typography sx={{ fontSize: "0.8rem" }}>
                      {hoursChecker(
                        row.time_out?.created_at,
                        row.time_in?.created_at
                      )}
                    </Typography>
                  ) : (
                    0
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        flexDirection="row"
        sx={{
          padding: "0px 14px 25px",
          justifyContent: "flex-end",
        }}
      >
        <Pagination
          count={hasNextPage ? tableData.length + 1 : tableData.length}
          variant="outlined"
          shape="rounded"
          onChange={(_, page) => {
            fetchNextPage();
            setCurrent(page - 1);
          }}
        />
      </Stack>
    </Paper>
  );
};

export default BasicTable;
