import { useState } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import format from "date-fns/format";

import useWindowSize from "../../../../hooks/customHooks/useWindowSize";
import { useEmployeeList } from "../../../../hooks/react-query/Evaluation/TimesheetWeeklyReport";
import WeekPerPageSelect from "./WeekPerPageSelect";
import WeeklyPagination from "./WeeklyPagination";

const DROPDOWN_STYLES = {
  ".MuiOutlinedInput-notchedOutline": {
    border: "1.5px solid #e9ecef",
    borderRadius: "6px",
  },
};

const ActionBar = ({
  queryData,
  handleChangeEmployeeId,
  skip,
  limit,
  maxLimit,
  remainder,
  isDecrementEnabled,
  isIncrementEnabled,
  handleDecrementSkip,
  handleIncrementSkip,
  handleChangeLimit,
}) => {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const { width } = useWindowSize();

  const { data, isLoading } = useEmployeeList();

  const handleValueChange = (_, newValue) => {
    setValue(newValue);
    handleChangeEmployeeId(newValue?.id);
  };

  const handleInputChange = (_, newValue) => {
    setInputValue(newValue);
  };

  const dateHiredFormatted = queryData?.dateHired
    ? format(new Date(queryData.dateHired), "LLLL dd, yyyy")
    : "";

  const mainStackDirection = width >= 1281 ? "row" : "column";
  const mainStackJustification = width >= 1281 ? "flex-start" : "space-between";
  const secondaryStackJustification =
    width >= 1281 ? "flex-end" : "space-between";
  const employeeDetailsStackDirection = width >= 533 ? "row" : "column";
  const paginationStackDirection = width >= 377 ? "row" : "column";

  return (
    <Stack
      direction={mainStackDirection}
      alignItems="space-between"
      justifyContent={mainStackJustification}
      sx={{
        backgroundColor: "#FFF",
        borderRadius: "12px",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.05)",
        padding: "8px",
      }}
    >
      <Stack
        direction={employeeDetailsStackDirection}
        alignItems="center"
        spacing={2}
        sx={{ width: "100%" }}
      >
        <Autocomplete
          value={value}
          onChange={handleValueChange}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          id="controllable-states-demo"
          options={!isLoading ? data : []}
          sx={{ ...DROPDOWN_STYLES, width: width >= 533 ? "300px" : "100%" }}
          renderInput={(params) => (
            <TextField {...params} placeholder="Employee name" />
          )}
        />

        {queryData && (
          <Typography sx={{ fontSize: "14px" }}>
            Date hired: <b>{dateHiredFormatted}</b>
          </Typography>
        )}
      </Stack>

      <Stack
        direction={paginationStackDirection}
        alignItems="center"
        justifyContent={secondaryStackJustification}
        sx={{ width: "100%" }}
      >
        <WeekPerPageSelect
          limit={limit}
          handleChangeLimit={handleChangeLimit}
        />

        <WeeklyPagination
          skip={skip}
          limit={limit}
          maxLimit={maxLimit}
          remainder={remainder}
          isDecrementEnabled={isDecrementEnabled}
          isIncrementEnabled={isIncrementEnabled}
          handleDecrementSkip={handleDecrementSkip}
          handleIncrementSkip={handleIncrementSkip}
        />
      </Stack>
    </Stack>
  );
};

export default ActionBar;
