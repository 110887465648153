import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import TableCell from "@mui/material/TableCell";
import { Box } from "@mui/system";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CircularProgress from "@mui/material/CircularProgress";

const SummaryTableOption = ({
  setToCancelData,
  toCancelData,
  loadingCancel,
  row,
  colId,
  status,
  setOpenModal,
}) => {
  const cancelFunc = () => {
    setToCancelData(row.id);
    setOpenModal(true);
  };

  return (
    <TableCell key={colId}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {loadingCancel && row.id === toCancelData ? (
          <CircularProgress />
        ) : (
          <Tooltip title={"Cancel"}>
            <span>
              <IconButton
                onClick={cancelFunc}
                aria-label="delete"
                color={"error"}
                disabled={
                  status?.toLowerCase().includes("pending") ||
                  status?.toLowerCase().includes("closed") ||
                  status?.toLowerCase().includes("disapproved") ||
                  status?.toLowerCase().includes("cancelled")
                }
              >
                <CancelRoundedIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Box>
    </TableCell>
  );
};
export default SummaryTableOption;
