import React from "react";
import { Typography, Accordion, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Accordy({ handleExpand }) {
  return (
    <Accordion
      sx={{
        boxShadow: "none",
        height: "37.13px",
        margin: "5px",
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "4px",
        "& .MuiAccordionSummary-root.Mui-expanded": {
          minHeight: 0,
        },
      }}
      onClick={handleExpand}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          minHeight: "0px",
          "& .MuiAccordionSummary-content": {
            margin: "9px 0",
          },
          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "9px 0",
          },
        }}
      >
        <Typography sx={{ fontSize: "0.78rem" }}>Expand cards</Typography>
      </AccordionSummary>
    </Accordion>
  );
}

export default Accordy;
