import { getMOTD } from "../../../api/motd";
import { useQuery, useMutation, useQueryClient } from "react-query";
import apiService from "../../../api/axios";
import { toast } from "react-toastify";

const useMOTD = (params) => {
  return useQuery(["get-message-of-the-day", params.criteria], () =>
    getMOTD(params.payload)
  );
};

const useNewMOTD = () => {
  const queryClient = new useQueryClient();
  return useMutation(
    "get-message-of-the-day",
    async (data) => {
      try {
        let response = await apiService.post(
          `/v5/settings/create-motdV5`,
          data,
          {
            Accept: `application/json`,
            "Content-Type": `multipart/form-data`,
            api: true,
          }
        );
        return response.data;
      } catch (error) {
        queryClient.refetchQueries(["get-message-of-the-day"]);
        throw error;
      }
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(["get-message-of-the-day"]);
        toast.success("New Announcement added.");
      },
    }
  );
};

const useDeleteMOTD = () => {
  const queryClient = new useQueryClient();
  return useMutation(
    "get-message-of-the-day",
    async (data) => {
      try {
        let response = await apiService.post(`/v5/settings/delete-motd`, {
          Accept: `application/json`,
          "Content-Type": `multipart/form-data`,
          api: true,
          ...data,
        });
        return response.data;
      } catch (error) {
        queryClient.refetchQueries(["get-message-of-the-day"]);
        throw error;
      }
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(["get-message-of-the-day"]);
        toast.success("Announcement successfully deleted.");
      },
    }
  );
};

const useUpdateMotd = () => {
  const queryClient = new useQueryClient();
  return useMutation(
    "get-message-of-the-day",
    async (data) => {
      try {
        let response = await apiService.post(
          `/v5/settings/update-motdV5`,
          data,
          {
            Accept: `application/json`,
            "Content-Type": `multipart/form-data`,
            api: true,
          }
        );
        return response.data;
      } catch (error) {
        queryClient.refetchQueries(["get-message-of-the-day"]);
        throw error;
      }
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(["get-message-of-the-day"]);
        toast.success("Announcement successfully updated");
      },
    }
  );
};

const hooks = {
  useMOTD,
  useNewMOTD,
  useDeleteMOTD,
  useUpdateMotd,
};

export default hooks;
