import {
  FormControl,
  Paper,
  Button,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ComplianceReportContext } from ".";
import { useContext } from "react";
import { useState } from "react";

const TableOptions = ({ Ranges, handleRange, handleRefresh }) => {
  const { endDate, startDate, setEndDate, setStartDate } = useContext(
    ComplianceReportContext
  );
  const [value, setValue] = useState("This Week");

  return (
    <Paper
      sx={{
        mt: 1.5,
        px: 1,
        py: 1,
        flexWrap: "wrap",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box>
        <FormControl sx={{ width: "175px", m: 1 }}>
          <InputLabel id="department">Range</InputLabel>
          <Select
            size="small"
            labelId="department"
            id="department-select"
            defaultValue={"This month"}
            value={value}
            label="Range"
            onChange={(e) => {
              handleRange(e.target.value);
              setValue(e.target.value);
            }}
          >
            {Ranges &&
              Ranges.map((Range, index) => (
                <MenuItem key={index} value={Range.value}>
                  {Range.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <FormControl sx={{ width: "175px", m: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={startDate}
              onChange={(e) => {
                setStartDate(e);
                setValue("Custom");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Start Date"
                  sx={{
                    marginBottom: "2px",
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </FormControl>
      </Box>
      <Box>
        <FormControl sx={{ width: "175px", m: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={endDate}
              onChange={(e) => {
                setEndDate(e);
                setValue("Custom");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="End Date"
                  sx={{
                    marginBottom: "2px",
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </FormControl>
      </Box>
      <Box>
        <Button
          sx={{ m: 1, backgroundColor: "#00c292" }}
          onClick={(e) => {
            handleRefresh();
          }}
          startIcon={<RefreshIcon />}
          variant="contained"
        >
          <Typography sx={{ fontSize: "0.78rem" }}>Refresh</Typography>
        </Button>
      </Box>
    </Paper>
  );
};

export default TableOptions;
