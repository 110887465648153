import React, { useContext } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { BiCrown, BiLike, BiStar, BiHeart } from "react-icons/bi";
import Likable from "./likable";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import { GlobalContext } from "../../App";

const Timeout = ({ handleTimeInOut, handleEntryModal, imgUrl }) => {
  const { userDashboard, reminder } = useContext(GlobalContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchor, setAnchor] = React.useState();

  React.useEffect(() => {
    if (reminder.timein) handleTimeInOut("in");
    if (reminder.timeout) handleTimeInOut("out");
  }, [reminder]);
  const reactions = {
    likes: {
      label: "Likes",
      value: userDashboard?.likes,
      icon: <BiLike />,
      color: "#9DB4E9",
    },
    loves: {
      label: "Loves",
      value: userDashboard?.loves,
      icon: <BiHeart />,
      color: "#F4A6BC",
    },
    commends: {
      label: "Commends",
      value: userDashboard?.commends,
      icon: <BiStar />,
      color: "#C4A0EC",
    },
    rewards: {
      label: "Rewards",
      value: userDashboard?.rewards,
      icon: <BiCrown />,
      color: "#F5D67B",
    },
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setAnchor(reactions[event.currentTarget.id]);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Grid container>
        <Grid item xs={12} lg={12} sx={{ margin: "auto" }}>
          <Card className="card-inout">
            <CardHeader
              title={
                <Typography variant="h3" className="h3-bar">
                  Reactions
                </Typography>
              }
              className="reaction-header"
            />
            <CardContent sx={{ padding: "10px 16px 14px 16px" }}>
              <Box className="timest">
                <Grid container sx={{ alignItems: "center" }}>
                  <Grid
                    item
                    xs={6}
                    sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.1)" }}
                  >
                    <Stack direction="row">
                      <Button
                        id="likes"
                        size="medium"
                        startIcon={<BiLike />}
                        className="reaction-buttons"
                        sx={{
                          color: "#9DB4E9",
                        }}
                        onClick={handleClick}
                      >
                        {userDashboard ? userDashboard.likes.length : 0}
                      </Button>
                      <Button
                        id="loves"
                        size="medium"
                        startIcon={<BiHeart />}
                        className="reaction-buttons"
                        sx={{
                          color: "#F4A6BC",
                        }}
                        onClick={handleClick}
                      >
                        {userDashboard ? userDashboard.loves.length : 0}
                      </Button>

                      <Button
                        id="commends"
                        size="medium"
                        startIcon={<BiStar />}
                        className="reaction-buttons"
                        sx={{
                          color: "#C4A0EC",
                        }}
                        onClick={handleClick}
                      >
                        {userDashboard ? userDashboard.commends.length : 0}
                      </Button>
                      <Button
                        id="rewards"
                        size="medium"
                        startIcon={<BiCrown />}
                        className="reaction-buttons"
                        sx={{
                          color: "#F5D67B",
                        }}
                        onClick={handleClick}
                      >
                        0
                      </Button>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ display: "flex", justifyContent: "right" }}>
                      <Button
                        size="small"
                        className="timest-button"
                        startIcon={<AddTaskOutlinedIcon />}
                        disableElevation
                        variant="contained"
                        onClick={() => handleEntryModal(null)}
                      >
                        Add Timesheet Entry
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
            <Likable
              anchorEl={anchorEl}
              handleClick={handleClick}
              handleClose={handleClose}
              open={open}
              reactions={anchor}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Timeout;
