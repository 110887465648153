import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import UpMultiple from "./upMultiple";
import UpSingle from "./upSingle";
import UpCheckbox from "./upCheckbox";
import UpRating from "./upRating";
import pulseHelper from "../pulseHelper/pulseHelper";
const choicesArr = [
  {
    value: "Multiple Choice",
    name: "Multiple Choice",
  },
  {
    value: "Single Text Box",
    name: "Single Text Box",
  },
  {
    value: "Checkboxes",
    name: "Checkboxes",
  },
  {
    value: "Rating",
    name: "Rating",
  },
];
const UpQuestion = ({
  ind,
  register,
  errors,
  handleQuestionArr,
  unregister,
  qt,
  isGreater,
  getValues,
}) => {
  if (qt?.id) {
    register(`id${ind}`, { value: qt.id });
  }
  register(`status${ind}`, { value: 1 });
  const toValue = (qq) => {
    if (qq === "multiple") {
      return "Multiple Choice";
    }
    if (qq === "single") {
      return "Single Text Box";
    }
    if (qq === "checkbox") {
      return "Checkboxes";
    }
    if (qq === "rating") {
      return "Rating";
    }
  };
  const valueReturned = toValue(qt ? qt.type_of_answer : "multiple");
  const [value, setValue] = React.useState(valueReturned);
  const name = `selectTypeAnswer${ind}`;
  const name2 = `selectTypeQuestion${ind}`;

  const newCreatedQuestion = [
    { answer: "option a", option: "a" },
    { answer: "option b", option: "b" },
    { answer: "option c", option: "c" },
    { answer: "option d", option: "d" },
  ];
  const newCreatedRating = [
    { answer: "Bad", option: 1 },
    { answer: "Not Bad", option: 2 },
    { answer: "Good", option: 3 },
    { answer: "Very Good", option: 4 },
    { answer: "Extremely Good", option: 5 },
  ];
  const ttField = isGreater ? newCreatedQuestion : JSON.parse(qt.attr);
  const ttRating = isGreater ? newCreatedRating : JSON.parse(qt.attr);
  const ttSingle = isGreater ? "" : JSON.parse(qt.attr);
  return (
    <Card sx={{ minWidth: "550px", m: "5px 0px 5px 0px", p: "10px" }}>
      <CardHeader
        title={
          <Typography variant="h3" className="h3-bar">
            Question Number {ind + 1}
          </Typography>
        }
        action={
          <IconButton
            onClick={() => {
              handleQuestionArr(
                ind,
                value,
                getValues(`id${ind}`) ? getValues(`id${ind}`) : null
              );
            }}
          >
            <DeleteIcon sx={{ width: "20px", height: "20px", color: "red" }} />
          </IconButton>
        }
      />
      <CardContent>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item lg={6} xs={6}>
            <FormControl fullWidth>
              <InputLabel>Survey Type Answer</InputLabel>
              <Select
                size="small"
                value={value}
                label="Survey Type Answer"
                name={name}
                {...register(name)}
                onChange={(e) => {
                  pulseHelper.handleUnregisterCreate(
                    ind,
                    e.target.value,
                    unregister,
                    setValue
                  );
                }}
                renderValue={(value) => {
                  const item = choicesArr.find(({ value: v }) => v === value);
                  return item.name;
                }}
              >
                {choicesArr.map((it, i) => (
                  <MenuItem value={it.value} key={i}>
                    {it.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={6} xs={6}>
            <TextField
              variant="outlined"
              label="Enter Question Here"
              fullWidth
              multiline
              defaultValue={qt ? qt.question : ""}
              size="small"
              required
              name={name2}
              {...register(name2)}
            />
          </Grid>
          <Grid item lg={12} xs={12} sx={{ mt: "5px", mb: "5px" }}>
            {value === "Multiple Choice" && (
              <UpMultiple
                register={register}
                errors={errors}
                ind={ind}
                ttField={ttField}
              />
            )}
            {value === "Single Text Box" && (
              <UpSingle
                register={register}
                errors={errors}
                ind={ind}
                ttSingle={ttSingle}
              />
            )}
            {value === "Checkboxes" && (
              <UpCheckbox
                register={register}
                errors={errors}
                ind={ind}
                ttField={ttField}
              />
            )}
            {value === "Rating" && (
              <UpRating
                register={register}
                errors={errors}
                ind={ind}
                ttRating={ttRating}
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UpQuestion;
