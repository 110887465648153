import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Stack, Typography, TextField, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import { useForm, Controller } from "react-hook-form";
import { GlobalContext } from "../../App";

const style = {
  borderStyle: "none",
  borderRadius: "1rem",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  p: 4,
  "&:focus-visible": {
    outline: "none",
  },
};

const ReusableModal = ({
  items,
  openModal,
  setOpenModal,
  prefillValues,
  submitForMutation,
}) => {
  const { setReminder, reminder } = React.useContext(GlobalContext);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: prefillValues,
  });
  const onSubmit = async (data, e) => {
    submitForMutation(data);
    handleClose();
  };

  React.useEffect(() => {
    reset(prefillValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleClose = () => {
    if (reminder.timeOutTrigger) {
      setReminder({ timeOutTrigger: false });
    }
    setOpenModal({ open: false });
  };

  const handleLogout = () => {
    setReminder({ logOut: true });
    setOpenModal({ open: false });
  };
  return (
    <div>
      <Modal
        open={openModal.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack sx={{ display: "flex", flexDirection: "row" }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h4"
              sx={{ width: "90%" }}
            >
              {openModal.title}
            </Typography>
            <IconButton onClick={handleClose} sx={{ marginTop: "-5%" }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          {(openModal.type === "confirm" ||
            openModal.type === "confirmLogout") && (
            <Typography
              id="modal-confirmation"
              component="h6"
              sx={{ marginTop: "1rem" }}
            >
              {openModal.message}
            </Typography>
          )}
          <form
            style={{ m: 1, width: "100%" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            {openModal.type === "form" &&
              items.map((value, index) => (
                <React.Fragment>
                  {((index !== 0 && openModal.zone === "add") ||
                    openModal.zone === "edit") && (
                    <Controller
                      key={index + value}
                      name={value.name}
                      control={control}
                      rules={{ required: true }}
                      defaultValue={value.value}
                      shouldUnregister
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={value.name.replace(/_/g, " ")}
                          sx={{
                            marginTop: 2,
                            textTransform: "capitalize",
                            width: "100%",
                            display: index === 0 ? "none" : "",
                          }}
                          error={errors[value.name]}
                          helperText={errors[value.name]?.type}
                        />
                      )}
                    />
                  )}
                </React.Fragment>
              ))}
            <Stack
              direction="row"
              spacing={2}
              sx={{
                paddingTop: "1.5rem",
                alignSelf: "center",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            >
              {(openModal.type === "form" || openModal.type === "confirm") && (
                <Button variant="outlined" color="error" onClick={handleClose}>
                  Cancel
                </Button>
              )}

              {openModal.type === "confirmLogout" && (
                <>
                  <Button variant="contained" color="success" type="submit">
                    {openModal.proceedToTimeout}
                  </Button>
                  <Button onClick={handleLogout}>{openModal.logout}</Button>
                </>
              )}
              {openModal.type === "form" && (
                <Button
                  variant="contained"
                  color="success"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {" "}
                  {openModal.confirmMessage}
                </Button>
              )}
              {openModal.type === "confirm" && (
                <Button variant="contained" color="success" type="submit">
                  {openModal.confirmMessage}
                </Button>
              )}
            </Stack>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default ReusableModal;
