import { useQuery } from "react-query";
import { getTimeInOutReport } from "../../../api/reportTimeInOut";

const useTimeInOut = (params) => {
  return useQuery(["timeinout/report", params.criteria], () =>
    getTimeInOutReport(params.payload)
  );
};

const queries = {
  useTimeInOut,
};

export default queries;
