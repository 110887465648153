import React from "react";
import { Tooltip, TableRow, TableCell, Typography } from "@mui/material";
import moment from "moment";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const Visit = ({
  data,
  header,
  handleClick,
  handleRemove,
  handleEdit,
  permission,
}) => {
  return (
    <>
      {data?.map((row, index) => (
        <TableRow key={index}>
          {header?.map((column, index) => (
            <TableCell key={index}>
              {column?.type === "date" ? (
                <Typography sx={{ fontSize: 14 }} nowrap="true">
                  {moment(row[column?.data]).format("ll")}
                </Typography>
              ) : column?.data === "remarks" ? (
                <Typography
                  sx={{
                    fontSize: 14,
                    maxWidth: "200px",
                    wordWrap: "break-word",
                    whitepace: "normal",
                  }}
                >
                  {row[column?.data]}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontSize: 14,
                    wordWrap: "break-word",
                    whitepace: "normal",
                  }}
                >
                  {row[column?.data]}
                </Typography>
              )}
            </TableCell>
          ))}
          <TableCell scope="row" align="right" nowrap="true">
            <Tooltip title="View Content">
              <OpenInNewIcon
                name={row["name"]}
                sx={{
                  mr: 0.5,
                  "&:hover": { cursor: "pointer" },
                  color: "#00C292",
                }}
                onClick={(e) =>
                  handleClick(row["name"], row["remarks"], row["content"])
                }
              />
            </Tooltip>
            {permission &&
              permission["permissions"].find(
                (column) => column === "manage customer visits"
              ) && (
                <>
                  <Tooltip title="Edit Content">
                    <EditOutlinedIcon
                      sx={{
                        mr: 0.5,
                        "&:hover": { cursor: "pointer" },
                        color: "#0083c9",
                      }}
                      name={row["name"]}
                      remarks={row["remarks"]}
                      content={row["content"]}
                      onClick={(e) => handleEdit(row["id"], row["path"], row)}
                    />
                  </Tooltip>
                  <Tooltip title="Delete Content">
                    <DeleteOutlineIcon
                      name={row["name"]}
                      sx={{
                        "&:hover": { cursor: "pointer" },
                        color: "#D06A76",
                      }}
                      onClick={(e) => handleRemove(row["id"], row["path"])}
                    />
                  </Tooltip>
                </>
              )}
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default Visit;
