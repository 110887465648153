import { useQuery } from "react-query";
import { getComplianceRequest } from "../../../api/compliance";

export const useComplianceRequest = (search) => {
  return useQuery(["get-compliance", search], () =>
    getComplianceRequest(search)
  );
};
const queries = {
  useComplianceRequest,
};

export default queries;
