import { useContext, useState, useEffect, memo } from "react";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import SearchIcon from "@mui/icons-material/Search";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import useDebounce from "../../../../hooks/customHooks/debounce";
import useWindowSize from "../../../../hooks/customHooks/useWindowSize";
import ClearFieldButton from "./ClearFieldButton";
import { AllPendingTimesheetEntriesContext } from "../../../../pages/SettingsTimesheet/AllPendingTimesheetEntries";

const HEADER_STYLES = {
  width: "100%",
  height: "min-content",
  padding: "8px",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: "16px",
  backgroundColor: "#FFF",
  borderRadius: "12px",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.05)",
};

const INPUT_FIELD_STYLES = {
  width: "100%",
  ".MuiInputBase-root": {
    padding: " 3px 2px 3px 7px",
    borderRadius: "6px",
    backgroundColor: "#f1f3f5",
    "&.Mui-disabled": {
      backgroundColor: "#E6E6E6",
    },
    "&.Mui-disabled fieldset": {
      border: "2px solid transparent",
    },
    "&.Mui-disabled:hover fieldset": {
      border: "2px solid transparent",
    },
    "& fieldset": {
      border: "2px solid transparent",
    },
    "&:hover fieldset": {
      borderColor: "#576c9f",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#21293C",
    },
  },
};

const AllPendingTimesheetHeader = () => {
  const {
    state: { status, fromDate, toDate },
    handler: {
      searchFilter: onSearch,
      changeStatus,
      changeFromDate,
      changeToDate,
    },
  } = useContext(AllPendingTimesheetEntriesContext);

  const [searchField, setSearchField] = useState("");

  const searchFilter = useDebounce(searchField);

  const { width } = useWindowSize();

  useEffect(() => {
    onSearch(searchFilter);
  }, [searchFilter, onSearch]);

  const changeSearchFieldHandler = (event) => {
    setSearchField(event.target.value);
  };

  const clearSearchFieldHandler = () => {
    setSearchField("");
  };

  const showExportIcon = width < 487;
  const maxWidth = showExportIcon ? "100%" : "420px";

  return (
    <Box sx={{ ...HEADER_STYLES }}>
      {/* SEARCH FILTER */}
      <TextField
        variant="outlined"
        id="search"
        placeholder="Search"
        size="small"
        value={searchField}
        onChange={changeSearchFieldHandler}
        autoComplete="off"
        sx={{ ...INPUT_FIELD_STYLES, maxWidth }}
        inputProps={{
          style: {
            fontSize: "14px",
          },
        }}
        InputProps={{
          startAdornment: (
            <SearchIcon
              sx={{
                marginRight: "8px",
                color: "#495057",
              }}
            />
          ),
          endAdornment: (
            <ClearFieldButton
              show={searchField.length > 0}
              onClear={clearSearchFieldHandler}
            />
          ),
        }}
      />

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {/* Start date */}
        <DatePicker
          views={["day"]}
          label="Start"
          value={fromDate}
          onChange={changeFromDate}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              helperText={null}
              sx={{
                width: "fit-content",
                flex: "0 1 fit-content",
              }}
            />
          )}
        />

        {/* End date */}
        <DatePicker
          views={["day"]}
          label="End"
          value={toDate}
          minDate={fromDate}
          onChange={changeToDate}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              helperText={null}
              sx={{ minWidth: "160px", flex: "0 1 max-content" }}
            />
          )}
        />
      </LocalizationProvider>

      {/* SHOW ALL */}
      <FormGroup>
        <FormControlLabel
          checked={status}
          control={<Checkbox onClick={changeStatus} />}
          label="Show all pending entries"
          size="small"
          sx={{ flex: "0 1 max-content" }}
        />
      </FormGroup>
    </Box>
  );
};

export default memo(AllPendingTimesheetHeader);
