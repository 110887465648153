import StyledTableCell from "../StyledTableCell";
import TitleTypography from "../TitleTypography";
import DetailsTypography from "../DetailsTypography";

import React from "react";

const APPROVAL_STYLES = {
  backgroundColor: "#e5dbff",
  color: "#7048e8",
};

const PENDING_STYLES = {
  backgroundColor: "#fff3bf",
  color: "#f59f00",
};

const StatusCell = ({ status, type }) => {
  const statusColor = /pending/gim.test(status)
    ? PENDING_STYLES
    : APPROVAL_STYLES;

  return (
    <StyledTableCell
      align="center"
      sx={{ verticalAlign: "top", textAlign: "left" }}
    >
      {type === "document" ? null : <TitleTypography>Status</TitleTypography>}
      <DetailsTypography
        sx={{
          width: "fit-content",
          padding: "0 8px",
          textTransform: "capitalize",
          borderRadius: "4px",
          ...statusColor,
        }}
      >
        {status}
      </DetailsTypography>
    </StyledTableCell>
  );
};

export default StatusCell;
