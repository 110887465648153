import React, { createContext, useState, useMemo, useEffect } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Paper,
  TableBody,
  TablePagination,
  Tooltip,
} from "@mui/material";
import { leaveTypesHeader } from "../../../lib/leaveManagement/leaveTypes";
import hooks from "../../../hooks/react-query/queryHooks";
import LeaveTypesFilter from "./LeaveTypesFilter";
import { sortArrayTable } from "../../../helper/LeaveManagement";
import Loader from "../../../component/Loader";
import LeaveTypesList from "./LeaveTypesList";
import EmptyData from "../../../component/Reports/EmptyData";
export const LeaveTypesContext = createContext({});

const LeaveTypes = () => {
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState("");
  const [load, setLoad] = useState(false);
  const [toggleActive, setToggleActive] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderDirection, setOrderDirection] = useState("desc");
  const { status, data, isFetching, refetch, isLoading } =
    hooks.useLeaveTypeMng({
      criteria: { page, rowsPerPage, search, toggleActive },
      payload: {
        page_size: rowsPerPage,
        page: page,
        search: search,
        inActives: toggleActive,
      },
    });

  const { data: departments, isFetching: loadingDepartments } =
    hooks.useGetDepartment();
  useEffect(() => {
    setLoad(true);
    if (status === "success" && data) {
      const leaveData = data.data.data;
      setTableData(leaveData || []);
    }
  }, [data, status, setTableData]);
  const value = useMemo(
    () => ({
      search,
      setSearch,
      isFetching,
      refetch,
      isLoading,
      load,
      setLoad,
      toggleActive,
      setToggleActive,
    }),
    [
      search,
      setSearch,
      isFetching,
      refetch,
      isLoading,
      load,
      setLoad,
      toggleActive,
      setToggleActive,
    ]
  );

  const handleSortRequest = (key, parentKey) => {
    let direction = "asc";
    direction = orderDirection === "desc" ? "asc" : "desc";
    setOrderDirection(direction);
    setTableData((prev) => sortArrayTable(prev, direction, key, parentKey));
  };
  const newData = tableData.filter((el) => {
    if (search === "") {
      return el;
    } else if (el.name.toLowerCase().includes(search.toLowerCase())) {
      return el.name;
    }
    return null;
  });
  const handleChangePage = (page) => {
    setPage(page + 1);
  };
  const handleChangeRowsPerPage = (e) => {
    setPage(1);
    setRowsPerPage(e.target.value);
  };
  return (
    <div className="box">
      {load && <Loader isLoading={isFetching} />}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "0px 15px",
          cursor: "context-menu",
        }}
      >
        <Tooltip title={"Manage company leave types"} placement={"right"} arrow>
          <Typography variant="h4" component="div" sx={{ padding: "1rem" }}>
            Leave Types
          </Typography>
        </Tooltip>
      </Box>
      <LeaveTypesContext.Provider value={value}>
        <LeaveTypesFilter />
        {!newData?.length && !isFetching ? (
          <EmptyData />
        ) : (
          <div style={{ margin: "15px" }}>
            <TableContainer
              component={Paper}
              sx={{ maxHeight: "calc(100vh - 320px)" }}
            >
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    {leaveTypesHeader.map((header, index) => {
                      const smallWidthHeader = [
                        "shortname",
                        "credits",
                        "actions",
                      ];
                      const width = smallWidthHeader.includes(header.key)
                        ? "15%"
                        : "auto";

                      return (
                        <TableCell
                          onClick={() =>
                            handleSortRequest(
                              header.key,
                              header?.parentKey ?? null
                            )
                          }
                          key={index}
                          align={header.align ? header.align : undefined}
                          sx={{ width }}
                        >
                          <TableSortLabel
                            sx={{ fontWeight: "700", fontSize: "0.8rem" }}
                          >
                            {header.label}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {newData
                    .filter((item) => item?.status === 1)
                    .map((item) => {
                      return (
                        <LeaveTypesList
                          item={item}
                          key={item?.id}
                          departments={departments?.data}
                        />
                      );
                    })}
                </TableBody>
              </Table>
              <TablePagination
                sx={{ display: "flex", justifyContent: "end" }}
                rowsPerPageOptions={[1, 5, 10, 30]}
                count={data?.data?.total || 0}
                page={
                  data?.data?.current_page ? data?.data?.current_page - 1 : 0
                }
                onPageChange={(e, page) => {
                  handleChangePage(page);
                }}
                showFirstButton={true}
                showLastButton={true}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </div>
        )}
      </LeaveTypesContext.Provider>
    </div>
  );
};

export default LeaveTypes;
