import { Typography } from "@mui/material";
import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
const TableRowHeader = ({ columns }) => {
  return (
    <TableRow>
      {columns?.map((column) => (
        <TableCell
          key={column.id}
          align={column.label === "Action" ? "center" : column.align}
          style={{ minWidth: column.minWidth }}
        >
          <Typography variant={"subtitle2"} sx={{ fontWeight: "700" }}>
            {column.label}
          </Typography>
        </TableCell>
      ))}
    </TableRow>
  );
};
export default TableRowHeader;
