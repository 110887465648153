import { TableRow, TableCell, Typography } from "@mui/material";
const NoData = () => {
  return (
    <TableRow>
      <TableCell sx={{ height: "90px" }} align="center" colSpan={"100%"}>
        <Typography>No Records Found.</Typography>
      </TableCell>
    </TableRow>
  );
};

export default NoData;
