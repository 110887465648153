import React from "react";
import { TableRow, TableCell, Typography } from "@mui/material";
import moment from "moment";

const ViewLogRows = ({ data, header }) => {
  return (
    <>
      {data &&
        data.map((row, index) => (
          <TableRow key={index}>
            {header &&
              header.map((column, index) => (
                <TableCell sx={{ py: 0.5 }} key={index} scope="row">
                  {column?.type === "date" ? (
                    <Typography sx={{ fontSize: 14 }}>
                      {moment(row[column?.data]).format("ll")}
                    </Typography>
                  ) : column?.data === "remarks" ? (
                    <Typography
                      sx={{
                        py: 0.5,
                        fontSize: 14,
                        maxWidth: "150px",
                        wordWrap: "break-word",
                        whitepace: "normal",
                      }}
                    >
                      {row[column?.data]}
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        py: 0.5,
                        fontSize: 14,
                        wordWrap: "break-word",
                        whitepace: "normal",
                        textTransform: "none",
                      }}
                    >
                      {row[column?.data]}
                    </Typography>
                  )}
                </TableCell>
              ))}
          </TableRow>
        ))}
    </>
  );
};

export default ViewLogRows;
