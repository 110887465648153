import React from "react";
import { Paper, Table, TableContainer } from "@mui/material";

const DashboardTableContainer = ({ children, style }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        ...style,
        minHeight: "500px",
      }}
    >
      <Table size="small">{children}</Table>
    </TableContainer>
  );
};

export default DashboardTableContainer;
