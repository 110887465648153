import React from "react";
import {
  Modal,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Typography,
  Paper,
  IconButton,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const Summary = ({ open, handleClose, timesheet }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal open={open} onClose={handleClose} disableEnforceFocus>
      <Box sx={style}>
        <Box
          style={{ display: "flex", justifyContent: "space-between" }}
          mb={7}
        >
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            Timesheet Summary
          </Typography>
          <IconButton aria-label="delete" onClick={handleClose}>
            <CloseOutlinedIcon sx={{ width: "15px", height: "15px" }} />
          </IconButton>
        </Box>
        <Paper>
          <TableContainer sx={{ maxHeight: 400 }}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Date
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Code
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Activity
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Sprint
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Details
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Hours
                  </TableCell>
                </TableRow>
              </TableHead>
              {timesheet.map((data) => (
                <TableBody>
                  <TableRow>
                    <TableCell align="left">{data.created}</TableCell>
                    <TableCell align="center">{data.time_code.name}</TableCell>
                    <TableCell align="center">
                      {data.time_activity.name}
                    </TableCell>
                    <TableCell align="left">{data.milestone}</TableCell>
                    <TableCell align="left">
                      {
                        <div
                          dangerouslySetInnerHTML={{ __html: data.comment }}
                        />
                      }
                    </TableCell>
                    <TableCell align="center">{data.hours}</TableCell>
                  </TableRow>
                </TableBody>
              ))}
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Modal>
  );
};

export default Summary;
