import { Typography, Box, IconButton, Tooltip } from "@mui/material";
import {
  useState,
  useMemo,
  createContext,
  useCallback,
  useEffect,
} from "react";
import Table from "../../../component/SettingsSystem/WfhTemplate/Table";
import hooks from "../../../hooks/react-query/SettingsSytem/WfhTemplate";
import WfhTemplateRow from "./WfhTemplateRow";
import Rows from "./Rows";
import moment from "moment";
import TableOptions from "./TableOptions";
import { getRangeDates } from "../../../helper/Reports";
import { Ranges, TableHeader } from "../../../lib/settingsSystem/wfhTemplate";
import ExportModal from "./ExportModal";
import Confirm from "../../../component/SettingsSystem/WfhTemplate/Confirm";
import {
  downloadCsv,
  downloadPdf,
} from "../../../helper/SettingsSytem/WfhTemplate";
import DownloadIcon from "@mui/icons-material/Download";
import {
  deleteWfhTemplate,
  activateWfhTemplate,
  addWfhTemplate,
} from "../../../api/settingsSystem/wfhTemplate";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import Dialog from "../../../component/SettingsSystem/WfhTemplate/Dialog";
import Content from "./Content";
import Add from "./Add";
import Edit from "./Edit";
import Loader from "../../../component/Loader";

export const WfhTemplateContext = createContext({});

const WfhTemplate = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [search, setSearch] = useState("");
  const [keyword, setKeyword] = useState("");
  const [fetch, setFetch] = useState(false);

  const [wfhTemplates, setWfhTemplates] = useState();
  const [total, setTotal] = useState(0);

  const [startDate, setStartDate] = useState(moment().startOf("month"));
  const [endDate, setEndDate] = useState(moment().endOf("month"));
  const [range, setRange] = useState([]); // eslint-disable-line

  const [columns, setColumn] = useState();
  const [columnsCSV, setColumnCSV] = useState();

  const [isExportModalOpen, openExportModal] = useState(false);

  const [view, setView] = useState(false);
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [content, setContent] = useState("");

  const [errors, setErrors] = useState([]);
  const [form, setForm] = useState();

  const [id, setId] = useState("");
  const [confirm, setConfirm] = useState(false);

  const value = useMemo(
    () => ({ endDate, startDate, setEndDate, setStartDate }),
    [endDate, startDate, setEndDate, setStartDate]
  );

  const { data, isFetching } = hooks.useWfhTemplates(
    getFetchUrl(),
    search,
    pageSize,
    page,
    fetch
  );

  const initComplianceReport = useCallback(() => {
    if (data) {
      setTotal(data.total || 0);
      setWfhTemplates(data.data || []);
      setColumn(TableHeader);
      setColumnCSV(TableHeader);
    }
  }, [data]);

  useEffect(() => {
    initComplianceReport();
  }, [initComplianceReport]);

  function getFetchUrl() {
    return (
      "?search=" +
      search +
      "&page_size=" +
      pageSize +
      "&page=" +
      page +
      "&from=" +
      moment(startDate).format("YYYY-MM-DD") +
      "&to=" +
      moment(endDate).format("YYYY-MM-DD")
    );
  }

  const handleRange = (e) => {
    const date = getRangeDates(e);
    setRange(e);
    setStartDate(date.fromDate);
    setEndDate(date.toDate);
  };

  const handleExport = (option) => {
    const wfhTemplateToDateCreatedAt =
      wfhTemplates &&
      wfhTemplates.map((temp) => ({
        ...temp,
        created_at: new Date(temp.created_at),
      }));

    if (option === "pdf") {
      downloadPdf(wfhTemplateToDateCreatedAt, columns);
    } else {
      downloadCsv(wfhTemplateToDateCreatedAt, columns, columnsCSV);
    }
    openExportModal(false);
  };
  const Buttons = [
    {
      Icon: (
        <IconButton
          key="add"
          sx={{ mt: 2, mr: 2.5 }}
          aria-label="add"
          onClick={(e) => openExportModal(!isExportModalOpen)}
        >
          <DownloadIcon />
        </IconButton>
      ),
    },
  ];

  const handleAddForm = () => {
    setForm(defaultValues);
    setAdd(true);
  };

  const handleViewTemplate = (content) => {
    setView(true);
    setContent(content);
  };

  const handleEditTemplate = (row) => {
    setForm(row);
    setEdit(true);
  };

  const handleActivate = (id, isActive) => {
    let formData = { id: id };
    toast.success(
      isActive === 1 ? "Template is deactivated" : "Template is now activated"
    );
    switchWfhTemplate.mutate(formData);
  };

  const handleDelete = (id) => {
    let formData = { id: id };
    setId(formData);
    setConfirm(true);
  };

  const handleConfirm = () => {
    removeWfhTemplate.mutate(id);
  };

  const removeWfhTemplate = useMutation(
    (formData) => deleteWfhTemplate(formData),
    {
      onSuccess: async (response) => {
        toast.success("Template removed sucessfully");
        setConfirm(false);
        setFetch(!fetch);
      },
      onError: (response) => {
        toast.error(response.message);
      },
    }
  );

  const switchWfhTemplate = useMutation(
    (formData) => activateWfhTemplate(formData),
    {
      onSuccess: async (response) => {
        setFetch(!fetch);
      },
      onError: (response) => {
        toast.error(response.message);
      },
    }
  );

  const createWfhTemplate = useMutation(
    (formData) => addWfhTemplate(formData),
    {
      onSuccess: async (response) => {
        toast.success("Template successfully added");
        setAdd(false);
        setEdit(false);
        setFetch(!fetch);
        setErrors({});
      },
      onError: (response) => {
        setErrors(response.response.data || {});
      },
    }
  );

  const updateWfhTemplate = useMutation(
    (formData) => addWfhTemplate(formData),
    {
      onSuccess: async (response) => {
        toast.success("Template successfully updated");
        setAdd(false);
        setEdit(false);
        setFetch(!fetch);
        setErrors({});
      },
      onError: (response) => {
        setErrors(response.response.data || {});
      },
    }
  );

  const defaultValues = {
    name: "",
    description: "",
    expire_date: moment().format("YYYY-MM-DD"),
    content: "",
  };

  const formFields = [
    {
      label: "Name",
      payload: "name",
      type: "text",
      value: "",
      helperText: "name",
    },
    {
      label: "Description",
      payload: "description",
      type: "text",
      value: "",
      helperText: "description",
    },
    {
      label: "Expire Date",
      payload: "expire_date",
      type: "date",
      value: "",
      helperText: "expire_date",
    },
    {
      label: "Content",
      payload: "content",
      type: "CkEditor",
      value: "",
      helperText: "content",
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    createWfhTemplate.mutate(form);
  };

  const handleSubmitEdit = (e) => {
    e.preventDefault();
    updateWfhTemplate.mutate(form);
  };

  const handleClose = (e) => {
    setAdd(false);
    setEdit(false);
    setErrors({});
  };

  return (
    <Box sx={{ margin: "0px", pr: "25px", pl: "25px" }}>
      <Loader isLoading={isFetching} />
      <Confirm
        open={confirm}
        handleConfirm={handleConfirm}
        handleClose={(e) => setConfirm(false)}
        message={"Are you sure you want to delete this record?"}
        title={""}
        submitting={isFetching}
      />
      <Dialog
        dialog={view}
        handleClose={(e) => setView(!view)}
        title={"WFH Template"}
        subject={""}
        Content={<Content content={content} />}
        type="content"
        size="md"
      />

      <Dialog
        dialog={add}
        handleClose={handleClose}
        title={"WFH Template"}
        subject={""}
        Content={
          <Add
            formFields={formFields}
            form={form}
            setForm={setForm}
            errors={errors}
          />
        }
        handleSubmit={handleSubmit}
        size="md"
        type="submit"
        submitting={isFetching}
      />

      <Dialog
        dialog={edit}
        handleClose={handleClose}
        title={"WFH Template"}
        subject={""}
        Content={
          <Edit
            formFields={formFields}
            form={form}
            setForm={setForm}
            errors={errors}
          />
        }
        handleSubmit={handleSubmitEdit}
        size="md"
        type="submit"
        submitting={isFetching}
      />
      <WfhTemplateContext.Provider value={value}>
        <ExportModal
          isExportModalOpen={isExportModalOpen}
          handleClose={(e) => openExportModal(!isExportModalOpen)}
          handleExport={handleExport}
        />
        <Box
          sx={{
            pt: "5px",
            cursor: "context-menu",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Tooltip
            title={"Manage companys telecommute/remote agreement"}
            placement={"right"}
            arrow
          >
            <Typography variant="h4">Telecommute/Remote Agreement </Typography>
          </Tooltip>
        </Box>
        <Box sx={{ pt: "5px" }}>
          <TableOptions
            Ranges={Ranges}
            handleRange={handleRange}
            handleAddForm={handleAddForm}
          />
        </Box>
        <Box sx={{ pt: "5px" }}>
          <Table
            columns={columns}
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
            total={total}
            setSearch={setSearch}
            isFetching={isFetching}
            keyword={keyword}
            setKeyword={setKeyword}
            Buttons={Buttons}
            Rows={
              <Rows
                handleViewTemplate={handleViewTemplate}
                handleEditTemplate={handleEditTemplate}
                handleActivate={handleActivate}
                handleDelete={handleDelete}
                data={wfhTemplates}
                isFetching={isFetching}
                Row={WfhTemplateRow}
                columns={columns}
              />
            }
          ></Table>
        </Box>
      </WfhTemplateContext.Provider>
    </Box>
  );
};

export default WfhTemplate;
