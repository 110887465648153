import {
  Dialog as MuiDialog,
  DialogContent,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  IconButton,
} from "@mui/material";
import React from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import BlockIcon from "@mui/icons-material/Block";

const Dialog = ({
  handleClose,
  dialog,
  title,
  subject,
  Content,
  handleSubmit,
  size = "sm",
  formData,
  type = "submit",
  submitting = false,
}) => {
  return (
    <>
      <MuiDialog
        disableEnforceFocus
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        maxWidth={size}
        fullWidth
        open={dialog}
      >
        <DialogContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 0,
          }}
        >
          <Card sx={{ width: "100%", m: 0 }}>
            <form
              ref={formData}
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <CardHeader
                title={<Typography variant="h5">{title}</Typography>}
                action={
                  <IconButton onClick={handleClose} sx={{ mr: "5px" }}>
                    <CloseOutlinedIcon
                      sx={{ color: "#00C292", width: "22px", height: "22px" }}
                    />
                  </IconButton>
                }
                sx={{
                  pb: "0!important",
                  ml: "5px",
                }}
              />
              <CardContent
                sx={{ ml: "5px", pt: "0!important", pb: "15px!important" }}
              >
                <Typography color="text.secondary">{subject}</Typography>
              </CardContent>

              <CardContent
                sx={{ pt: 0, pr: 2, pl: 2 }}
                className="cc-static-dp"
              >
                {Content}
              </CardContent>

              <CardActions sx={{ margin: "0px 10px 10px" }}>
                {type === "submit" && (
                  <>
                    <LoadingButton
                      loading={submitting}
                      size="small"
                      type="submit"
                      className="timest-button"
                      disableElevation
                      variant="contained"
                      sx={{ marginLeft: "auto" }}
                      startIcon={<SaveOutlinedIcon />}
                    >
                      Submit
                    </LoadingButton>
                    <LoadingButton
                      loading={false}
                      size="small"
                      onClick={handleClose}
                      disableElevation
                      variant="contained"
                      sx={{
                        "&:hover": { bgcolor: "#ca5764" },
                        bgcolor: "#D06A76",
                        marginLeft: "auto",
                        textTransform: "none",
                        px: "16px",
                        py: "6px",
                      }}
                      startIcon={<BlockIcon />}
                    >
                      Cancel
                    </LoadingButton>
                  </>
                )}
              </CardActions>
            </form>
          </Card>
        </DialogContent>
      </MuiDialog>
    </>
  );
};

export default Dialog;
