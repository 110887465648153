import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Typography } from "@mui/material";
import { TimesheetContext } from "../../globalstate/UserContext/timesheetcontext";
// import timesheet from "../../api/timesheet";
import CircularProgress from "@mui/material/CircularProgress";
import { useDeleteTimesheet } from "../../hooks/react-query/timesheet";

export default function AlertDialog({
  openDialog,
  closeDialog,
  handleEntryModal,
}) {
  const {
    // refetchMonthlyTimesheet,
    // refetchWeeklyTimesheet,
    timeSheetID,
    // setAPIResponse,
  } = useContext(TimesheetContext);
  const [loading, setLoading] = useState(false);

  const { mutate } = useDeleteTimesheet();
  const HandleDelete = async () => {
    setLoading(true);
    const id = timeSheetID;
    const myID = { id };
    //const reqdelete = await timesheet.deleteTimesheet(myID);
    mutate(myID);
    // if (reqdelete) {
    //   setAPIResponse({
    //     msg: reqdelete.message,
    //     status: "success",
    //   });
    //   refetchWeeklyTimesheet();
    //   refetchMonthlyTimesheet();
    //   handleEntryModal(false);
    //   closeDialog(false);
    // }
    handleEntryModal(false);
    closeDialog(false);
    setLoading(false);
  };

  return (
    <Dialog
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          fontWeight: "bold",
        }}
      >
        <DeleteOutlineIcon
          style={{ color: "rgb(228, 106, 118)" }}
          fontSize="large"
        />{" "}
        {"Delete"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="caption">
            Are you sure you want to remove this activity from timesheet?
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="small"
          style={{ textTransform: "none" }}
          onClick={() => closeDialog(false)}
        >
          Cancel
        </Button>
        <Button
          size="small"
          style={{ textTransform: "none", color: "rgb(228, 106, 118)" }}
          onClick={() => HandleDelete()}
          autoFocus
          disabled={loading}
          endIcon={loading ? <CircularProgress size={"1.2rem"} /> : <div />}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
