import React, { useState, useEffect, useCallback } from "react";
import Chart from "react-apexcharts";
import { Box, Paper, Typography } from "@mui/material";

const DepartmentChart = ({ effortPerTypeData }) => {
  const [chartData, setChartData] = useState([0]);
  const [newChartData, setNewChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([""]);

  useEffect(() => {
    if (effortPerTypeData?.length > 0) {
      let temp = {};
      let chart_data = [];
      let labels = [];
      let max_cnt = 0;

      for (let i in effortPerTypeData) {
        let name = effortPerTypeData[i].time_type.name;
        let effort = effortPerTypeData[i].hours;

        labels.push(name);
        temp[name] = parseFloat(effort);
        max_cnt = max_cnt > effort ? max_cnt : effort;
      }
      labels.sort();

      for (let i in labels) {
        chart_data.push(temp[labels[i]]);
      }

      const charDataMinimumCount = 5;
      if (chart_data?.length < charDataMinimumCount) {
        const emptyArray = new Array(charDataMinimumCount - chart_data.length);
        const emptyLabel = new Array(charDataMinimumCount - labels.length);
        emptyArray.fill(0, 0, emptyArray.length);
        emptyLabel.fill("", 0, emptyLabel.length);
        labels = labels.concat(emptyLabel);
        chart_data = chart_data.concat(emptyArray);
      }

      setChartLabels(labels);
      setChartData(chart_data);
    }
  }, [effortPerTypeData]);

  const removeZero = useCallback(async () => {
    if (chartData.length > 0) {
      const result = chartData.map((data) => (data !== 0 ? data : null));
      setNewChartData(result);
    }
  }, [chartData]);

  useEffect(() => {
    removeZero();
  }, [removeZero]);

  const chartInfo = {
    options: {
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: 0,
        offsetY: -16,
        style: {
          colors: ["#333"],
        },
      },

      chart: {
        id: "effort-per-type",
      },
      xaxis: {
        categories: chartLabels,
      },
      yaxis: {
        title: {
          text: "H O U R S",
        },
        tickAmount: 8,
      },
    },
    series: [
      {
        name: "Hours",
        data: newChartData,
      },
    ],
  };

  return (
    <>
      <Paper
        sx={{
          borderRadius: "10px",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Effort per Type
          </Typography>
        </Box>
        <Box sx={{ pl: 2 }}>
          <Chart
            options={chartInfo.options}
            series={chartInfo.series}
            type="bar"
            width="100%"
            height={381}
          />
        </Box>
      </Paper>
    </>
  );
};

export default DepartmentChart;
