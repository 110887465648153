import React from "react";
import NoData from "./NoData";
import Fetching from "./Fetching";

const Row = ({
  data = [],
  header,
  handleDelete,
  handleEdit,
  isFetching = true,
  Row,
  options,
  edit,
  formFields = [],
}) => {
  return (
    <>
      {isFetching ? (
        <Fetching />
      ) : data && data.length === 0 ? (
        <NoData />
      ) : (
        data &&
        data.map((row, index) => (
          <Row
            key={index}
            header={header}
            edit={edit}
            row={row}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            options={options}
            formFields={formFields}
          />
        ))
      )}
    </>
  );
};

export default Row;
