import { createContext, memo } from "react";

import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import {
  useAllPendingTimesheetEntries,
  usePrefetchAllPendingTimesheetEntries,
} from "../../../hooks/react-query/SetttingsTimesheet/AllPendingTimesheetEntries";
import MainContainer from "../../../component/SettingsTimesheet/AllPendingTimesheetEntries/container/MainContainer";
import TableWrapper from "../../../component/SettingsTimesheet/AllPendingTimesheetEntries/container/TableWrapper";
import StyledTable from "../../../component/SettingsTimesheet/AllPendingTimesheetEntries/Table/StyledTable";
import AllPendingTimesheetTitle from "../../../component/SettingsTimesheet/AllPendingTimesheetEntries/Title";
import AllPendingTimesheetHeader from "../../../component/SettingsTimesheet/AllPendingTimesheetEntries/Header";
import TableHeader from "../../../component/SettingsTimesheet/AllPendingTimesheetEntries/Table/TableHeader";
import AllPendingEntryTableRow from "../../../component/SettingsTimesheet/AllPendingTimesheetEntries/Table/AllPendingEntryTableRow";
import PendingEntryTableSkeletonLoader from "../../../component/SettingsTimesheet/AllPendingTimesheetEntries/Table/PendingEntryTableSkeletonLoader";
import StyledTableCell from "../../../component/UserApplicationMgmt/UserApplications/tableComponents/StyledTableCell";
import PendingTimesheetEntriesError from "../../../component/SettingsTimesheet/PendingTimesheetEntries/Error/";
import { ROWS_PER_PAGE_OPTIONS } from "../../../lib/settingsTimesheet/PendingTimesheetEntries";
import { generateSkeletonLoader } from "../../../helper/UserApplicationMgmt/UserApplications";

const TABLE_PAGINATION_STYLES = {
  display: "flex",
  justifyContent: "end",
  minHeight: "53px",
  borderTop: "1px solid #dee2e6",
  borderBottom: "none",
};

export const AllPendingTimesheetEntriesContext = createContext({
  query: {},
  state: {},
  handler: {},
});

const AllPendingTimesheetEntries = () => {
  const {
    data,
    isLoading,
    isError,
    isSuccess,
    searchString,
    page,
    pageSize,
    status,
    fromDate,
    toDate,
    changePage,
    changePageSize,
    query,
    state,
    handler,
  } = useAllPendingTimesheetEntries();

  usePrefetchAllPendingTimesheetEntries(
    data?.next_page_url,
    page,
    pageSize,
    searchString,
    status,
    fromDate,
    toDate
  );

  const changeRowsPageHandler = (event) => {
    changePageSize(+event.target.value);
  };

  const changePageHandler = (_, page) => {
    changePage(page + 1);
  };

  return (
    <AllPendingTimesheetEntriesContext.Provider
      value={{ query, state, handler }}
    >
      <MainContainer>
        {/* TITLE */}
        <AllPendingTimesheetTitle />

        {/* SEARCH FILTER AND EXPORT COMPONENT */}
        {!isError && <AllPendingTimesheetHeader />}

        {/* ERROR COMPONENT */}
        {isError && <PendingTimesheetEntriesError />}

        {/* TABLE */}
        {(isLoading || isSuccess) && !isError && (
          <TableWrapper>
            <TableContainer>
              <StyledTable stickyHeader>
                {/* TABLE HEAD */}
                <TableHeader />

                {/* TABLE BODY */}
                <TableBody>
                  {!isLoading
                    ? data.data.map((pendingEntry) => (
                        <AllPendingEntryTableRow
                          key={pendingEntry.id}
                          data={pendingEntry}
                        />
                      ))
                    : generateSkeletonLoader(
                        pageSize,
                        <PendingEntryTableSkeletonLoader />
                      )}

                  {!isLoading && !data?.data.length ? (
                    <TableRow>
                      <StyledTableCell colSpan="100%">
                        No results found.
                      </StyledTableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </StyledTable>
            </TableContainer>

            {/* PAGINATION */}
            <TablePagination
              component={Box}
              sx={TABLE_PAGINATION_STYLES}
              showFirstButton={true}
              showLastButton={true}
              count={data?.total || 0}
              page={data?.current_page ? data?.current_page - 1 : 0}
              rowsPerPage={pageSize}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              onPageChange={changePageHandler}
              onRowsPerPageChange={changeRowsPageHandler}
            />
          </TableWrapper>
        )}
      </MainContainer>
    </AllPendingTimesheetEntriesContext.Provider>
  );
};

export default memo(AllPendingTimesheetEntries);
