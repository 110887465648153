import React, { createContext, useState, useMemo } from "react";
import moment from "moment";
import DashboardFilter from "./DashboardFilter";
import { getRangeDates } from "../../../helper/Reports";
import HeaderReports from "../../../component/Reports/Header";
import Loader from "../../../component/Loader";
import reactQuery from "../../../hooks/react-query/reportsDashboard";
import { Box, Grid, Tooltip } from "@mui/material";
import DepartmentChart from "./DepartmentChart";
import ProjectChart from "./ProjectChart";
import TotalStaffEffortV5 from "./TotalStaffEffortV5";
import UserTable from "./UserTable";
import ActivityTable from "./ActivityTable";
import DashboardEmptyData from "../../../component/Reports/Dashboard/DashboardEmptyData";

export const ReportsDashboardContext = createContext({});

const ReportsDashboard = () => {
  const [range, setRange] = useState("This Week");
  const [startDate, setStartDate] = useState(moment().startOf("week"));
  const [endDate, setEndDate] = useState(moment().endOf("week"));
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const {
    data: reportsDashboardData,
    refetch,
    isFetching,
  } = reactQuery.useReportsDashboard({
    criteria: { startDate, endDate },
    payload: {
      fromDate: moment(startDate).format("YYYY-MM-DD"),
      toDate: moment(endDate).format("YYYY-MM-DD"),
    },
  });

  const value = useMemo(
    () => ({
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      refetch,
      range,
      setRange,
      setPage,
    }),
    [
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      refetch,
      range,
      setRange,
      setPage,
    ]
  );

  const handleRangeChange = (e) => {
    const date = getRangeDates(e.target.value);
    setRange(e.target.value);
    setStartDate(date.fromDate);
    setEndDate(date.toDate);
  };

  const handleRefresh = () => {
    if (page === 1 && limit === 30) {
      refetch();
      return;
    }
    setPage(1);
    setLimit(10);
  };

  return (
    <div>
      <Loader isLoading={isFetching} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          cursor: "context-menu",
        }}
      >
        <Tooltip
          title="View company statistics report"
          placement={"right"}
          arrow
        >
          <Box>
            <HeaderReports text={"Dashboard"} />
          </Box>
        </Tooltip>
      </Box>
      <ReportsDashboardContext.Provider value={value}>
        <DashboardFilter
          handleRangeChange={handleRangeChange}
          handleRefresh={handleRefresh}
        />
        <Box sx={{ p: 2 }}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={5}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    {!reportsDashboardData && !isFetching ? (
                      <DashboardEmptyData />
                    ) : (
                      <TotalStaffEffortV5
                        total={reportsDashboardData?.data?.user_efforts}
                        expected={reportsDashboardData?.data?.expected_efforts}
                        range={range}
                        startDate={startDate}
                        endDate={endDate}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <DepartmentChart
                      effortPerTypeData={reportsDashboardData?.data?.per_type}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={7}>
                <ProjectChart
                  projectData={reportsDashboardData?.data?.per_code}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ pt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={7}>
                <UserTable startDate={startDate} endDate={endDate} />
              </Grid>
              <Grid item xs={12} md={5}>
                <ActivityTable startDate={startDate} endDate={endDate} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </ReportsDashboardContext.Provider>
    </div>
  );
};

export default ReportsDashboard;
