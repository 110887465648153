export const TableSettings = { limit: 25 };

export const TableHeader = [
  { label: "Name", data: "name" },
  { label: "Type", data: "type" },
  { label: "Date Uploaded", data: "created_at", type: "date" },
  { label: "Effective Date", data: "effective_date", type: "date" },
  { label: "Remarks", data: "remarks" },
];

export const ViewLogHeader = [
  { label: "Employee Name", data: "employee_name" },
  { label: "Date Viewed", data: "date_viewed", type: "date" },
];
