import { Box } from "@mui/material";

// Reusable Tab Panel
// enclose component into this as a children

const TabPanels = (props) => {
  const { children, selectedTab, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={{ marginRight: "1rem", marginLeft: "1rem" }}
      hidden={selectedTab !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {selectedTab === index && <Box>{children}</Box>}
    </div>
  );
};

export default TabPanels;
