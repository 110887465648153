import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { findSetting } from "./UserApplicationMgmt/UserApplications";
import { useContext } from "react";
import { GlobalContext } from "../App";

export const ButtonCustomColor = styled(Button)(({ theme }) => {
  const { settings } = useContext(GlobalContext);
  const buttonBackgroundColor =
    findSetting("bgcolor_appbar", settings) || "#21293C";

  return {
    color: "#f1f1f1",
    backgroundColor: buttonBackgroundColor,
    "&:hover": {
      backgroundColor: buttonBackgroundColor,
    },
  };
});
