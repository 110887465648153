import AddIcon from "@mui/icons-material/Add";
import { IconButton } from "@mui/material";
const Add = ({ handleClick }) => {
  return (
    <IconButton aria-label="add" onClick={handleClick}>
      <AddIcon />
    </IconButton>
  );
};

export default Add;
