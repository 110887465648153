import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import apiService from "../../../api/axios";

const useUpdateUserPermission = () => {
  return useMutation("update-user-permission", async (data) => {
    let param = data;
    try {
      let response = await apiService.post(`v5/permission/toggle`, {
        Accept: `application/json`,
        "Content-Type": `multipart/form-data`,
        api: true,
        ...param,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  });
};

const useUpdateUserRole = () => {
  return useMutation(
    "update-user-role",
    async (data) => {
      let param = data;
      try {
        let response = await apiService.post(`v5/permission/toggle-role`, {
          Accept: `application/json`,
          "Content-Type": `multipart/form-data`,
          api: true,
          ...param,
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: async () => {
        toast.success("Permission updated for user", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      },
      onError: async () => {
        toast.error("Oops, something went wrong.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      },
    }
  );
};

const useUpdateRolePermission = () => {
  return useMutation(
    "update-role-permission",
    async (data) => {
      let param = data;
      try {
        let response = await apiService.post(
          `v5/permission/toggle-role-permission`,
          {
            Accept: `application/json`,
            "Content-Type": `multipart/form-data`,
            api: true,
            ...param,
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: async () => {
        toast.success("Successfully updated permission", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      },
      onError: async () => {
        toast.error("Oops, something went wrong.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      },
    }
  );
};

const useUserPermission = (page, rowsPerPage, query, selectedTab) => {
  return useQuery(
    ["user-permission", page, rowsPerPage, query],
    async () => {
      try {
        let response = await apiService.get(
          `v5/permission/user-permission/get?&page=${page}&page_size=${rowsPerPage}&search=${query}&api=true`,
          {
            Accept: `application/json`,
            "Content-Type": `multipart/form-data`,
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    {
      enabled: selectedTab === 0,
      keepPreviousData: true,
      staleTime: 500 * 1000,
    },
    {
      onSuccess: async () => {
        toast.success("Updated list for User Permissions", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      },
      onError: async () => {
        toast.error("Oops, something went wrong.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      },
    }
  );
};

const useUserRole = (page, rowsPerPage, query, selectedTab) => {
  return useQuery(
    ["user-role", page, rowsPerPage, query],
    async () => {
      try {
        let response = await apiService.get(
          `v5/permission/user-roles/get?&page=${page}&page_size=${rowsPerPage}`,
          {
            Accept: `application/json`,
            "Content-Type": `multipart/form-data`,
            params: {
              search: query,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    {
      enabled: selectedTab === 1,
      keepPreviousData: true,
      staleTime: 500 * 1000,
    },
    {
      onSuccess: async () => {
        toast.success("Updated list for User Roles!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      },
      onError: async () => {
        toast.error("Oops, something went wrong.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      },
    }
  );
};

const useRolePermission = (page, rowsPerPage, query, selectedTab) => {
  return useQuery(
    ["role-permission", page, rowsPerPage, query],
    async () => {
      try {
        let response = await apiService.get(
          `v5/permission/role-permissions/get?&page=${page}&page_size=${rowsPerPage}`,
          {
            Accept: `application/json`,
            "Content-Type": `multipart/form-data`,
            params: {
              search: query,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    {
      enabled: selectedTab === 2,
      keepPreviousData: true,
      staleTime: 500 * 1000,
    },

    {
      onSuccess: async () => {
        toast.success("Updated list for Role Permission", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      },
      onError: async () => {
        toast.error("Oops, something went wrong.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      },
    }
  );
};

const useRoleHead = () => {
  return useQuery(
    "role-head",
    async () => {
      try {
        let response = await apiService.get(
          `v5/permission/get-user-role-header`,
          {
            Accept: `application/json`,
            "Content-Type": `multipart/form-data`,
            api: true,
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    {
      keepPreviousData: true,
      staleTime: 500 * 1000,
    }
  );
};

const usePermissionHead = () => {
  return useQuery("permission-head", async () => {
    try {
      let response = await apiService.get(`v5/permission/get`, {
        Accept: `application/json`,
        "Content-Type": `multipart/form-data`,
        api: true,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  });
};
const permission = {
  useRolePermission,
  useUserRole,
  useUserPermission,
  usePermissionHead,
  useRoleHead,
  useUpdateUserRole,
  useUpdateUserPermission,
  useUpdateRolePermission,
};

export default permission;
