import React, { useState } from "react";
import { Modal, Box, TextField, Button, Grid, Typography } from "@mui/material";
import DropDownSelect from "../../CustomElement/DropDownSelect";
import {
  ActiveOrNot,
  RestrictedOrNot,
} from "../../../helper/ProjectCodeSettings";
import DropDownMultiple from "../../CustomElement/DropDownSelectMulti";
import { useAddProjectCode } from "../../../hooks/react-query/Settings/timsheet";
import CircularProgress from "@mui/material/CircularProgress";
import { FindDataByName } from "../../../helper/ProjectCodeSettings";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: 800,
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const AddProjectCode = ({
  open,
  handleClose,
  clients,
  types,
  departments,
  projectCodes,
}) => {
  const initData = {
    name: "",
    description: "",
    client_id: "",
    type: "",
    key: [],
    status: "",
    budget: 0,
    balance: 0,
    restricted: "",
    limit_per_month: 0,
  };
  const [data, setData] = useState(initData);
  const DepartmenShortName = departments.map((dept) => dept.short_name);

  const { mutate, isLoading } = useAddProjectCode();
  const HandleAddProjectCode = () => {
    mutate(data, {
      onSuccess: () =>
        toast.success("Successfully added project code: " + data.name),
      onError: (error) => {
        console.log(error);
        if (error.response.status === 422) {
          toast.warning(error.response.data.name[0]);
        } else {
          toast.error("Something went wrong. Please try again");
        }
      },
    });
    setData(initData);
    handleClose();
  };

  const handleClientSelect = (e) => {
    setData({ ...data, client_id: e.target.value });
  };

  const handleTypeSelect = (e) => {
    setData({ ...data, type: e.target.value });
  };

  const handleDeptSelect = (e) => {
    setData({ ...data, key: e.target.value });
  };
  const handleStatusSelect = (e) => {
    setData({ ...data, status: e.target.value });
  };
  const handleRestrictSelect = (e) => {
    setData({ ...data, restricted: e.target.value });
  };

  const closeModal = () => {
    setData(initData);
    handleClose();
  };
  return (
    <Modal
      hideBackdrop
      open={open}
      onClose={closeModal}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h4" mb={5}>
          Add Project Code
        </Typography>
        <div style={{ marginBottom: "30px" }}>
          <div style={{ paddingLeft: "5px" }}>
            <TextField
              variant="outlined"
              label="Code"
              size="small"
              sx={{ marginBottom: "10px", minWidth: 260 }}
              inputProps={{ maxLength: 100 }}
              onChange={(e) => setData({ ...data, name: e.target.value })}
              error={FindDataByName(projectCodes, data.name) !== undefined}
              helperText={
                FindDataByName(projectCodes, data.name) !== undefined &&
                "The code has already been taken."
              }
            />
            <TextField
              variant="outlined"
              label="Description"
              multiline
              rows={3}
              fullWidth
              sx={{ marginBottom: 4 }}
              inputProps={{ maxLength: 250 }}
              InputProps={{ style: { fontSize: "0.75rem" } }}
              onChange={(e) =>
                setData({ ...data, description: e.target.value })
              }
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ margin: 8 }}>
              <DropDownSelect
                DropDownData={clients}
                objectName={{ val: "id", name: "company_name" }}
                minWidth={260}
                onChange={handleClientSelect}
                label={"Client"}
                value={data.client_id}
                variant={"outlined"}
                size={"small"}
              />
            </div>
            <DropDownSelect
              objectName={{ val: "id", name: "name" }}
              DropDownData={types}
              minWidth={180}
              onChange={handleTypeSelect}
              label={"Project Type"}
              value={data.type}
              variant={"outlined"}
              size={"small"}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <DropDownMultiple
              DropDownData={DepartmenShortName}
              minWidth={260}
              onChange={handleDeptSelect}
              value={data.key}
              label={"Department"}
              variant={"outlined"}
              size={"small"}
            />
            <DropDownSelect
              objectName={{ val: "id", name: "name" }}
              DropDownData={ActiveOrNot}
              minWidth={180}
              onChange={handleStatusSelect}
              label={"Status"}
              value={data.status}
              variant={"outlined"}
              size={"small"}
            />
          </div>
          <div style={{ display: "flex", alignContent: "center" }}>
            <div style={{ padding: "7px 8px 10px 7px" }}>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                label="Budget(hours)"
                value={data.budget}
                InputProps={{ style: { fontSize: "0.75rem" } }}
                inputProps={{ step: 0.5, style: { textAlign: "center" } }}
                style={{ textAlign: "center", minWidth: "260px" }}
                onChange={(e) => setData({ ...data, budget: e.target.value })}
              />
            </div>
            <div style={{ marginTop: 7 }}>
              <DropDownSelect
                objectName={{ val: "id", name: "name" }}
                DropDownData={RestrictedOrNot}
                minWidth={180}
                onChange={handleRestrictSelect}
                label={"Restricted"}
                value={data.restricted}
                variant={"outlined"}
                size={"small"}
              />
            </div>
          </div>
          <div style={{ padding: "7px 8px 10px 7px" }}>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              label="Monthly Budget(hours)"
              InputProps={{ style: { fontSize: "0.75rem" } }}
              inputProps={{ step: 0.5, style: { textAlign: "center" } }}
              style={{ textAlign: "center", minWidth: "260px" }}
              onChange={(e) =>
                setData({ ...data, limit_per_month: e.target.value })
              }
            />
          </div>
        </div>
        <Grid container sx={{ justifyContent: "end" }} spacing={3}>
          <Grid item>
            <Button
              className="adjust-w cancel-button"
              variant="contained"
              sx={{ padding: "1px 10px 1px 10px" }}
              onClick={closeModal}
            >
              {" "}
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              className="adjust-w timest-button"
              variant="contained"
              sx={{ padding: "1px 15px 1px 15px" }}
              onClick={HandleAddProjectCode}
              disabled={
                isLoading ||
                data.name === "" ||
                data.status === null ||
                data.restricted === null ||
                data.status === "" ||
                data.restricted === "" ||
                FindDataByName(projectCodes, data.name) !== undefined
              }
              endIcon={isLoading ? <CircularProgress size={20} /> : <></>}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddProjectCode;
