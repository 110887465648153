import React from "react";
import "./greetings.scss";
import Typography from "@mui/material/Typography";
const Greetings = ({ employee, companyName }) => {
  return (
    <div className="content">
      <div className="content__container">
        <ul className="content__container__list">
          <li className="content__container__list__item">
            <Typography variant="h4" className="greeting-h4-g">
              Hello
            </Typography>
          </li>
          <li className="content__container__list__item">
            <Typography variant="h4" className="greeting-h4-g">
              {`${employee.employee.firstname} ${employee.employee.lastname}`}
            </Typography>
          </li>
          <li className="content__container__list__item">
            <Typography variant="h4" className="greeting-h4-g">
              Welcome to {companyName}
            </Typography>
          </li>
          <li className="content__container__list__item">
            <Typography variant="h4" className="greeting-h4-g">
              {`${employee.employee.firstname} ${employee.employee.lastname}`}
            </Typography>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Greetings;
