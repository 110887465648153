export const userAppSettingsHeader = [
  {
    label: "Name",
    key: "full_name",
    isSortable: true,
  },
  {
    label: "Approvers",
    key: "approvers",
    isSortable: true,
  },
  {
    label: "Status",
    key: "status",
    isSortable: true,
  },
  {
    label: "Date Hired",
    key: "date_hired",
    isSortable: true,
  },
  {
    label: "Actions",
    key: "actions",
    align: "center",
  },
];
