import apiService from "../axios";

export const getActivity = async (url) => {
  try {
    let response = await apiService.get(
      `v5/employee/get-project-activites` + url,
      {
        Accept: `application/json`,
        "Content-Type": `multipart/form-data`,
        body: JSON.stringify(url),
      }
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getDepartment = async (url) => {
  try {
    let response = await apiService.post(`v1/department/get-all` + url, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(url),
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const addActivity = async (data) => {
  try {
    await apiService.post(`v1/settings/timesheet/activity/add`, data, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(data),
    });
  } catch (error) {
    throw error;
  }
};

export const deleteActivity = async (data) => {
  try {
    await apiService.post(`v1/settings/timesheet/activity/delete`, data, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(data),
    });
  } catch (error) {
    throw error;
  }
};

export const editActivity = async (data) => {
  try {
    await apiService.post(`v1/settings/timesheet/activity/update`, data, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(data),
    });
  } catch (error) {
    throw error;
  }
};
