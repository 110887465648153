import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import AddReactionOutlinedIcon from "@mui/icons-material/AddReactionOutlined";
import LeaveCredits from "./leaveCredits";
import { RequestContext } from "../../globalstate/UserContext/RequestContext";
import LeaveImg from "./LeaveImg";
import { generateHolidayNameArray } from "../../helper/Dashboard";
import { useHolidayData } from "../../hooks/customHooks/useHoliday";

const Leave = () => {
  const { handleLeave } = React.useContext(RequestContext);
  const date = new Date();
  const countryCode = "PH";

  const holidayData = useHolidayData(countryCode, date);

  const holidayNameArray = React.useMemo(() => {
    return generateHolidayNameArray(countryCode, date, holidayData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holidayData]);

  return (
    <Card className="card-leave">
      <LeaveImg holidayNameArray={holidayNameArray} />
      <CardContent>
        <Box>
          <Typography variant="h6" className="date-h4">
            Request
          </Typography>
        </Box>
        <Box>
          <Grid container columnSpacing={1} sx={{ minHeight: "142px" }}>
            <LeaveCredits />
          </Grid>
          <Divider />
          <Box className="leave-box">
            <Button
              size="small"
              className="timest-button"
              startIcon={<AddReactionOutlinedIcon />}
              disableElevation
              variant="contained"
              onClick={handleLeave}
            >
              Request Leave
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Leave;
