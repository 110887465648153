import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
const ForgotTimeInOutResponse = ({
  status,
  handleConversion,
  refetchMyQuery,
}) => {
  return (
    <Box sx={{ textAlign: "center", margin: "0px 20px 10px 20px" }}>
      {status ? (
        <>
          <Chip
            label={
              <Typography variant="overline">
                {status === "success" ? "Success" : "Duplicate"}
              </Typography>
            }
            color={status === "success" ? "success" : "error"}
          />
          <Typography sx={{ m: 1 }}>
            {status === "success"
              ? "Successfully submitted application"
              : "Already have an application on the said date"}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            {status && (
              <Button
                onClick={() => {
                  handleConversion();
                  refetchMyQuery();
                }}
              >
                Close
              </Button>
            )}
          </Box>
        </>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};

export default ForgotTimeInOutResponse;
