import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  deleteDocument,
  getApprovers,
  getEmployeeRequest,
} from "../../../api/userrequest";

export const useEmployeeRequest = (
  id,
  page,
  search,
  size,
  sortBy,
  select,
  type
) => {
  return useQuery(
    [
      "get-employee-request-data-with-page",
      page,
      search,
      size,
      sortBy,
      select,
      type,
    ],
    () => getEmployeeRequest(id, page, search, size, sortBy, select, type)
  );
};

// export const useDeleteDocument = (id) => {
//   const queryClient = useQueryClient();
//   return useMutation(() => deleteDocument(id), {
//     onSuccess: () => {
//       queryClient.invalidateQueries("get-employee-request-data-with-page");
//     },
//     onError: () => {
//       toast.error("Something went wrong");
//     },
//   });
// };

export const useEmployeeApprovers = (data) => {
  return useQuery(["get-approvers"], () => getApprovers(data));
};

export const useEmployeeTableHeader = (select) => {
  return useQuery(["get-employee-request-headers", select], () => {
    let headers = [];
    if (select === "Leave") {
      headers = [
        { id: "leave_type", label: "Leave type", minWidth: 100 },
        { id: "created_at", label: "Date filed", minWidth: 100 },
        { id: "purpose", label: "Purpose", minWidth: 100 },
        { id: "leave_credits", label: "(Leave)\nCredits", minWidth: 100 },
        { id: "comments", label: "Comments", minWidth: 100 },
        { id: "status", label: "Status", minWidth: 100 },
        { id: "action", label: "Action", minWidth: 100 },
      ];
    } else if (select === "Overtime") {
      headers = [
        { id: "type", label: "Undertime/Overtime", minWidth: 100 },
        { id: "created_at", label: "Date filed", minWidth: 100 },
        { id: "purpose", label: "Purpose", minWidth: 100 },
        { id: "comments", label: "Comments", minWidth: 100 },
        { id: "status", label: "Status", minWidth: 100 },
        { id: "action", label: "Action", minWidth: 100 },
      ];
    } else if (select === "WFH") {
      headers = [
        { id: "created_at", label: "Date filed", minWidth: 100 },
        { id: "purpose", label: "Purpose", minWidth: 100 },
        { id: "workplace", label: "(WFH) Workplace", minWidth: 100 },
        { id: "comments", label: "Comments", minWidth: 100 },
        { id: "status", label: "Status", minWidth: 100 },
        { id: "action", label: "Action", minWidth: 100 },
      ];
    } else if (select === "LeaveConversion") {
      headers = [
        { id: "created_at", label: "Date Requested", minWidth: 100 },
        { id: "converted", label: "Credit Requested", minWidth: 100 },
        { id: "date", label: "Date Reviewed", minWidth: 100 },
        { id: "reviewed_by", label: "Reviewed By", minWidth: 100 },
        { id: "status", label: "Status", minWidth: 100 },
        { id: "action", label: "Action", minWidth: 100 },
      ];
    } else if (select === "ForgotTimeInOut") {
      headers = [
        { id: "created_at", label: "Date filed", minWidth: 100 },
        { id: "reason", label: "Reason", minWidth: 100 },
        { id: "location", label: "Location", minWidth: 100 },
        { id: "status", label: "Status", minWidth: 100 },
        { id: "action", label: "Action", minWidth: 100 },
      ];
    } else if (select === "Document") {
      headers = [
        { id: "Document", label: "Document", minWidth: 100 },
        { id: "docuDescription", label: "Description", minWidth: 100 },
        { id: "status", label: "Status", minWidth: 100 },
        { id: "action", label: "Action", minWidth: 100 },
      ];
    }
    return headers;
  });
};

const queries = {
  useEmployeeRequest,
  useEmployeeApprovers,
};

export default queries;
