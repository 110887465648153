import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  lastDayOfMonth,
  startOfYear,
  lastDayOfYear,
} from "date-fns";
const startWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
const endWeek = endOfWeek(new Date());
const startMonth = startOfMonth(new Date());
const endMonth = lastDayOfMonth(new Date());
const startYear = startOfYear(new Date());
const endYear = lastDayOfYear(new Date());

const d = new Date();
d.setFullYear(d.getFullYear() - 1);

const lastStartYear = startOfYear(d);
const lastEndYear = lastDayOfYear(d);

const ReactionSelect = ({
  itemSelect,
  title,
  setFilter,
  setFrom,
  setTo,
  setDate1,
  setDate2,
}) => {
  const [value, setValue] = React.useState(
    title === "Type" ? "all" : "this week"
  );

  const setDates = (date1, date2) => {
    setFrom(date1);
    setTo(date2);
    setDate1(date1);
    setDate2(date2);
  };
  return (
    <FormControl
      sx={{
        minWidth: "50px",
      }}
    >
      <InputLabel>{title}</InputLabel>
      <Select
        size="small"
        sx={{ margin: "5px" }}
        value={value}
        label={title}
        onChange={(e) => {
          if (title === "Range") {
            setValue(e.target.value);
            switch (e.target.value) {
              case "this week":
                setDates(startWeek, endWeek);
                return;
              case "this month":
                setDates(startMonth, endMonth);
                return;
              case "this year":
                setDates(startYear, endYear);
                return;
              case "last year":
                setDates(lastStartYear, lastEndYear);
                return;
            }
          }
          setFilter(e.target.value);
          setValue(e.target.value);
        }}
        renderValue={(value) => {
          const item = itemSelect.find(({ value: v }) => v === value);
          return item.name;
        }}
      >
        {itemSelect.map((it, i) => (
          <MenuItem value={it.value} key={i}>
            {it.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ReactionSelect;
