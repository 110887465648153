import React, { useState, useContext, useEffect, useCallback } from "react";
import {
  Collapse,
  IconButton,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { PerProjectUserV5Context } from ".";
import { PerProjectUserActivityTableHeaderV5 } from "../../../lib/reports/perProjectUserV5";
import moment from "moment";
import Loader from "../../../component/Loader";
import UserActivityList from "./UserActivityList";
import { sortArrayTable } from "../../../helper/Reports";
import { getReportPerCodeReportActivity } from "../../../api/perProjectUser";
import EmptyData from "../../../component/Reports/EmptyData";
import TableHeader from "../../../component/Reports/TableHeader";
import ReportTableContainter from "../../../component/Reports/TableContainter";

const UserProjectLists = ({ item, codeId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [limit, setLimit] = useState(30);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [activityListData, setActivityListData] = useState(null);
  const { startDate, endDate, isWeekends, isInactiveUsers } = useContext(
    PerProjectUserV5Context
  );
  const [orderDirection, setOrderDirection] = useState("desc");
  const [tableData, setTableData] = useState([]);

  const handleChangeRowsPerPage = (e) => {
    setPage(1);
    setLimit(e.target.value);
  };

  const handleChangePage = (page) => {
    setPage(page + 1);
  };

  const getReportPerCodeReportActivityList = useCallback(async () => {
    if (isOpen) {
      setIsLoading(true);
      const response = await getReportPerCodeReportActivity({
        fromDate: moment(startDate).format("YYYY-MM-DD"),
        toDate: moment(endDate).format("YYYY-MM-DD"),
        page_size: limit,
        id: item.row_id, //emp_id
        id2: codeId, //code_id
        page: page,
        inActives: isInactiveUsers,
        weekends: isWeekends,
      });

      setActivityListData(response);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page, item, isOpen]);

  const openCollapsable = async () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      await getReportPerCodeReportActivityList();
      setIsOpen(true);
    }
  };

  useEffect(() => {
    getReportPerCodeReportActivityList();
  }, [getReportPerCodeReportActivityList]);

  useEffect(() => {
    if (activityListData) {
      setTableData(activityListData?.data || []);
    }
  }, [activityListData]);

  const handleSortRequest = (key) => {
    let direction = "asc";
    direction = orderDirection === "desc" ? "asc" : "desc";
    setOrderDirection(direction);
    setTableData((prev) => sortArrayTable(prev, direction, key));
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <TableRow>
        <TableCell>
          <IconButton
            onClick={async () => {
              await openCollapsable();
            }}
          >
            {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.name,
            }}
          />
        </TableCell>
        <TableCell align={"right"}>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.efforts,
            }}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} sx={{ background: "#dbe9f4" }}>
          <Collapse in={isOpen} unmountOnExit>
            {!activityListData?.data?.length ? (
              <EmptyData />
            ) : (
              <ReportTableContainter>
                <TableHeader
                  isCollapsable={true}
                  headerList={PerProjectUserActivityTableHeaderV5}
                  handleSortRequest={handleSortRequest}
                  orderDirection={orderDirection}
                />
                <TableBody>
                  {tableData?.map((codeItem) => {
                    return (
                      <UserActivityList
                        key={codeItem.row_id}
                        item={codeItem}
                        userId={item.row_id}
                        codeId={codeId}
                      />
                    );
                  })}
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[1, 5, 10, 30]}
                      count={activityListData?.total || 0}
                      page={
                        activityListData?.current_page
                          ? activityListData?.current_page - 1
                          : 0
                      }
                      onPageChange={(e, page) => {
                        handleChangePage(page);
                      }}
                      rowsPerPage={limit}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      showFirstButton={true}
                      showLastButton={true}
                    />
                  </TableRow>
                </TableBody>
              </ReportTableContainter>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default UserProjectLists;
