import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  TextField,
  InputAdornment,
  Button,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useDebounce from "../../../hooks/customHooks/debounce";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import AddProjectType from "./AddProjectType";
import { ProjectTypeCntxt } from ".";

const ProjectTypeFilter = () => {
  const { search, setSearch, inActive, setInActive } =
    useContext(ProjectTypeCntxt);
  const [searchString, setSearchString] = useState(search);
  const [open, setOpen] = useState(false);
  const debounceValue = useDebounce(searchString, 700);

  useEffect(() => {
    setSearch(debounceValue);
  }, [debounceValue, setSearch]);

  const openModalHandler = () => {
    setOpen(true);
  };

  const toggleHandler = () => {
    setInActive(!inActive);
  };

  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "10px",
        padding: "14px",
        alignItems: "center",
        margin: "15px",
        boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
        position: "sticky",
        top: "0",
        zIndex: "1",
        "@media(max-width: 768px)": {
          position: "relative",
        },
      }}
    >
      <div className="flex">
        {open && <AddProjectType open={open} setOpen={setOpen} />}
        <Button
          sx={{ backgroundColor: "#00c292", height: 35 }}
          onClick={openModalHandler}
          variant="contained"
          startIcon={<AddCircleOutlineIcon />}
        >
          <Typography sx={{ fontSize: "0.78rem" }}>Add New</Typography>
        </Button>
        <FormControlLabel
          control={<Switch checked={inActive} />}
          sx={{ width: "10rem", marginLeft: "10px" }}
          label="Show Inactive"
          onClick={toggleHandler}
        />
      </div>
      <div>
        <TextField
          value={searchString}
          onChange={(e) => {
            setSearchString(e.target.value);
          }}
          sx={{ margin: "5px" }}
          size="small"
          align={"right"}
          placeholder="Search"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CloseIcon
                  style={{ color: "#000000", cursor: "pointer" }}
                  onClick={() => setSearchString("")}
                />
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
    </Card>
  );
};

export default ProjectTypeFilter;
