export const sortArrayTable = (arr, orderBy, key, parentKey) => {
  if (!parentKey) {
    switch (orderBy) {
      case "asc":
      default:
        return arr.sort((a, b) =>
          a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0
        );
      case "desc":
        return arr.sort((a, b) =>
          a[key] < b[key] ? 1 : b[key] < a[key] ? -1 : 0
        );
    }
  } else {
    switch (orderBy) {
      case "asc":
      default:
        return arr.sort((a, b) =>
          a[parentKey][key] > b[parentKey][key]
            ? 1
            : b[parentKey][key] > a[parentKey][key]
            ? -1
            : 0
        );
      case "desc":
        return arr.sort((a, b) =>
          a[parentKey][key] < b[parentKey][key]
            ? 1
            : b[parentKey][key] < a[parentKey][key]
            ? -1
            : 0
        );
    }
  }
};
