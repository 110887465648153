const createMulti = (data, i, getValues) => {
  return {
    question: getValues(`selectTypeQuestion${i}`),
    type_of_answer: "multiple",
    answer: "",
    id: getValues(`id${i}`) ? getValues(`id${i}`) : "",
    status: getValues(`status${i}`),
    attr: [
      {
        option: getValues(`opta${i}`),
        answer: getValues(`ansa${i}`),
        selected: false,
      },
      {
        option: getValues(`optb${i}`),
        answer: getValues(`ansb${i}`),
        selected: false,
      },
      {
        option: getValues(`optc${i}`),
        answer: getValues(`ansc${i}`),
        selected: false,
      },
      {
        option: getValues(`optd${i}`),
        answer: getValues(`ansd${i}`),
        selected: false,
      },
    ],
  };
};
const createSingle = (data, i, getValues) => {
  return {
    question: getValues(`selectTypeQuestion${i}`),
    type_of_answer: "single",
    answer: "",
    answerType: "single",
    id: getValues(`id${i}`) ? getValues(`id${i}`) : "",
    status: getValues(`status${i}`),
    attr: [
      {
        answer: getValues(`single${i}`),
      },
    ],
  };
};
const createCheckbox = (data, i, getValues) => {
  return {
    question: getValues(`selectTypeQuestion${i}`),
    type_of_answer: "checkbox",
    answer: "",
    answerType: "checkbox",
    id: getValues(`id${i}`) ? getValues(`id${i}`) : "",
    status: getValues(`status${i}`),
    attr: [
      {
        option: getValues(`optChkA${i}`),
        answer: getValues(`ansChkA${i}`),
        selected: false,
      },
      {
        option: getValues(`optChkB${i}`),
        answer: getValues(`ansChkB${i}`),
        selected: false,
      },
      {
        option: getValues(`optChkC${i}`),
        answer: getValues(`ansChkC${i}`),
        selected: false,
      },
      {
        option: getValues(`optChkD${i}`),
        answer: getValues(`ansChkD${i}`),
        selected: false,
      },
    ],
  };
};
const createRating = (data, i, getValues) => {
  return {
    question: getValues(`selectTypeQuestion${i}`),
    type_of_answer: "rating",
    answer: "",
    answerType: "rating",
    id: getValues(`id${i}`) ? getValues(`id${i}`) : "",
    status: getValues(`status${i}`),
    attr: [
      {
        option: getValues(`rateOptA${i}`),
        answer: getValues(`ansRateA${i}`),
        selected: false,
      },
      {
        option: getValues(`rateOptB${i}`),
        answer: getValues(`ansRateB${i}`),
        selected: false,
      },
      {
        option: getValues(`rateOptC${i}`),
        answer: getValues(`ansRateC${i}`),
        selected: false,
      },
      {
        option: getValues(`rateOptD${i}`),
        answer: getValues(`ansRateD${i}`),
        selected: false,
      },
      {
        option: getValues(`rateOptE${i}`),
        answer: getValues(`ansRateE${i}`),
        selected: false,
      },
    ],
  };
};
const handleUnregister = (ind, val, qtID, setValue, unregister) => {
  if (qtID) {
    setValue(`status${ind}`, 0);
  } else {
    if (val === "Multiple Choice") {
      unregister(`opta${ind}`);
      unregister(`optb${ind}`);
      unregister(`optc${ind}`);
      unregister(`optd${ind}`);
      //
      unregister(`ansa${ind}`);
      unregister(`ansb${ind}`);
      unregister(`ansc${ind}`);
      unregister(`ansd${ind}`);
      //
    }
    if (val === "Single Text Box") {
      //
      unregister(`single${ind}`);
      //
    }
    if (val === "Checkboxes") {
      // CHECKBOX
      unregister(`optChkA${ind}`);
      unregister(`optChkB${ind}`);
      unregister(`optChkC${ind}`);
      unregister(`optChkD${ind}`);
      // CHECKBOX
      unregister(`ansChkA${ind}`);
      unregister(`ansChkB${ind}`);
      unregister(`ansChkC${ind}`);
      unregister(`ansChkD${ind}`);
      //
    }
    if (val === "Rating") {
      // RATING
      unregister(`rateOptA${ind}`);
      unregister(`rateOptB${ind}`);
      unregister(`rateOptC${ind}`);
      unregister(`rateOptD${ind}`);
      unregister(`rateOptE${ind}`);
      // RATING
      unregister(`ansRateA${ind}`);
      unregister(`ansRateB${ind}`);
      unregister(`ansRateC${ind}`);
      unregister(`ansRateD${ind}`);
      unregister(`ansRateE${ind}`);
    }
    //
    unregister(`answerType${ind}`);
    unregister(`selectTypeAnswer${ind}`);
    unregister(`selectTypeQuestion${ind}`);
  }
};
const handleUnregisterCreate = (ind, val, unregister, setValue) => {
  // function for creation of data
  // TYPE SELECT & QUESTION TEXT
  unregister(`selectTypeAnswer${ind}`);
  unregister(`selectTypeQuestion${ind}`);
  // MULTIPLE
  unregister(`opta${ind}`);
  unregister(`optb${ind}`);
  unregister(`optc${ind}`);
  unregister(`optd${ind}`);
  // MULTIPLE
  unregister(`ansa${ind}`);
  unregister(`ansb${ind}`);
  unregister(`ansc${ind}`);
  unregister(`ansd${ind}`);
  // SINGLLE
  unregister(`single${ind}`);
  // ANSWER TYPE
  unregister(`answerType${ind}`);
  // CHECKBOX
  unregister(`optChkA${ind}`);
  unregister(`optChkB${ind}`);
  unregister(`optChkC${ind}`);
  unregister(`optChkD${ind}`);
  // CHECKBOX
  unregister(`ansChkA${ind}`);
  unregister(`ansChkB${ind}`);
  unregister(`ansChkC${ind}`);
  unregister(`ansChkD${ind}`);
  //
  // RATING
  unregister(`rateOptA${ind}`);
  unregister(`rateOptB${ind}`);
  unregister(`rateOptC${ind}`);
  unregister(`rateOptD${ind}`);
  unregister(`rateOptE${ind}`);
  // RATING
  unregister(`ansRateA${ind}`);
  unregister(`ansRateB${ind}`);
  unregister(`ansRateC${ind}`);
  unregister(`ansRateD${ind}`);
  unregister(`ansRateE${ind}`);
  //
  setValue(val);
};
const Multi = (data, i, getValues) => {
  return {
    question: getValues(`selectTypeQuestion${i}`),
    type_of_answer: "multiple",
    answer: "",
    attr: [
      {
        option: getValues(`opta${i}`),
        answer: getValues(`ansa${i}`),
        selected: false,
      },
      {
        option: getValues(`optb${i}`),
        answer: getValues(`ansb${i}`),
        selected: false,
      },
      {
        option: getValues(`optc${i}`),
        answer: getValues(`ansc${i}`),
        selected: false,
      },
      {
        option: getValues(`optd${i}`),
        answer: getValues(`ansd${i}`),
        selected: false,
      },
    ],
  };
};
const Single = (data, i, getValues) => {
  return {
    question: getValues(`selectTypeQuestion${i}`),
    type_of_answer: "single",
    answer: "",
    answerType: "single",
    attr: [
      {
        answer: getValues(`single${i}`),
      },
    ],
  };
};
const Checkbox = (data, i, getValues) => {
  return {
    question: getValues(`selectTypeQuestion${i}`),
    type_of_answer: "checkbox",
    answer: "",
    answerType: "checkbox",
    attr: [
      {
        option: getValues(`optChkA${i}`),
        answer: getValues(`ansChkA${i}`),
        selected: false,
      },
      {
        option: getValues(`optChkB${i}`),
        answer: getValues(`ansChkB${i}`),
        selected: false,
      },
      {
        option: getValues(`optChkC${i}`),
        answer: getValues(`ansChkC${i}`),
        selected: false,
      },
      {
        option: getValues(`optChkD${i}`),
        answer: getValues(`ansChkD${i}`),
        selected: false,
      },
    ],
  };
};
const Rating = (data, i, getValues) => {
  return {
    question: getValues(`selectTypeQuestion${i}`),
    type_of_answer: "rating",
    answer: "",
    answerType: "rating",
    attr: [
      {
        option: getValues(`rateOptA${i}`),
        answer: getValues(`ansRateA${i}`),
        selected: false,
      },
      {
        option: getValues(`rateOptB${i}`),
        answer: getValues(`ansRateB${i}`),
        selected: false,
      },
      {
        option: getValues(`rateOptC${i}`),
        answer: getValues(`ansRateC${i}`),
        selected: false,
      },
      {
        option: getValues(`rateOptD${i}`),
        answer: getValues(`ansRateD${i}`),
        selected: false,
      },
      {
        option: getValues(`rateOptE${i}`),
        answer: getValues(`ansRateE${i}`),
        selected: false,
      },
    ],
  };
};
const disOption = (index) => {
  if (index === 0) {
    return "option a";
  }
  if (index === 1) {
    return "option b";
  }
  if (index === 2) {
    return "option c";
  }
  if (index === 3) {
    return "option d";
  }
};
const disAnswer = (index) => {
  if (index === 0) {
    return "answer a";
  }
  if (index === 1) {
    return "answer b";
  }
  if (index === 2) {
    return "answer c";
  }
  if (index === 3) {
    return "answer d";
  }
};
const ratingOpt = (index) => {
  if (index === 0) {
    return "option a";
  }
  if (index === 1) {
    return "option b";
  }
  if (index === 2) {
    return "option c";
  }
  if (index === 3) {
    return "option d";
  }
  if (index === 4) {
    return "option e";
  }
};
const ratingAns = (index) => {
  if (index === 0) {
    return "answer a";
  }
  if (index === 1) {
    return "answer b";
  }
  if (index === 2) {
    return "answer c";
  }
  if (index === 3) {
    return "answer d";
  }
  if (index === 4) {
    return "answer e";
  }
};
const queries = {
  createMulti,
  createSingle,
  createCheckbox,
  createRating,
  handleUnregister,
  handleUnregisterCreate,
  disOption,
  disAnswer,
  Multi,
  Single,
  Checkbox,
  Rating,
  ratingOpt,
  ratingAns,
};

export default queries;
