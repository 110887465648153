import { useQuery } from "react-query";
import { getReportPerCodeReport } from "../../../api/perProjectUser";

const usePerProjectUser = (params) => {
  return useQuery(["get-report-per-project-user", params.criteria], () =>
    getReportPerCodeReport(params.payload)
  );
};

const queries = {
  usePerProjectUser,
};

export default queries;
