import React from "react";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import hooks from "../../hooks/react-query/queryHooks";
import TableBody from "@mui/material/TableBody";
import ShiftRow from "./shiftRow";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import AddShiftModal from "./addShiftModal";
import DeleteShift from "./deleteShift";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { Tooltip } from "@mui/material";
const tableHeader = [
  { name: "Shift Name", align: "left" },
  { name: "Time In", align: "center" },
  { name: "Time Out", align: "center" },
  { name: "Grace Period", align: "center" },
  { name: "Action", align: "center" },
];

const tableStyle = {
  box: {
    display: "flex",
    justifyContent: "space-between",
  },
  minW: {
    minWidth: "170px",
  },
  iconS: {
    width: "15px",
    height: "15px",
  },
  iconB: {
    borderRadius: "5px",
  },
  bttn: {
    textTransform: "none",
    fontSize: "1rem",
    height: "37px",
  },
  cAction: {
    display: "flex",
    justifyContent: "flex-end",
    mb: "15px",
  },
  tTypo: {
    fontWeight: 500,
    fontSize: "0.85rem",
    lineHeight: 1.5,
  },
  tStack: {
    padding: "0px 14px 25px",
    justifyContent: "flex-end",
  },
  tableBox: {
    m: "15px 30px",
    p: "15px 30px",
  },
};
const ShiftModal = ({ shiftOpen, handleShift }) => {
  const keyRef = React.useRef("");
  const [current, setCurrent] = React.useState(0);
  const [delRow, setDelrow] = React.useState(null);
  const [keyword, setKeyword] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowDetails, setRowdetails] = React.useState(null);
  const [addShift, setAddshift] = React.useState(false);
  const {
    data: tableData,
    hasNextPage,
    fetchNextPage,
  } = hooks.useShift(keyword, rowsPerPage);
  const handleAddModal = (data) => {
    setRowdetails(data);
    addShift ? setAddshift(false) : setAddshift(true);
  };
  const handleDeleteModal = (dataParam) => {
    setDelrow(dataParam);
  };
  return (
    <Paper sx={tableStyle.tableBox}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          cursor: "context-menu",
        }}
      >
        <Tooltip title="Manage company shift" placement={"right"} arrow>
          <CardHeader title={<Typography variant="h4">Shift</Typography>} />
        </Tooltip>
      </Box>
      <CardContent>
        <Box sx={tableStyle.box}>
          <Button
            disableElevation
            variant="contained"
            size="small"
            sx={tableStyle.bttn}
            onClick={() => {
              handleAddModal(null);
            }}
          >
            Add shift
          </Button>
          <Box sx={{ m: "15px" }}>
            <TextField
              size="small"
              label="Search"
              variant="outlined"
              onChange={(e) => {
                if (e.target.value === "") {
                  setKeyword("");
                }
                keyRef.current = e.target.value;
              }}
            />
            <IconButton
              sx={tableStyle.iconB}
              color="primary"
              onClick={() => {
                setKeyword(keyRef.current);
                setCurrent(0);
              }}
            >
              <SearchRoundedIcon />
            </IconButton>
          </Box>
        </Box>
        <Box>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {tableHeader.map((header, index) => (
                  <TableCell align={header.align} key={index}>
                    <Typography variant="h5" sx={tableStyle.tTypo}>
                      {header.name}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData?.pages[current]?.data.map((row, index) => (
                <ShiftRow
                  key={index}
                  row={row}
                  handleAddModal={handleAddModal}
                  handleDeleteModal={handleDeleteModal}
                />
              ))}
            </TableBody>
          </Table>
        </Box>
      </CardContent>
      <Stack flexDirection="row" sx={tableStyle.tStack}>
        <TablePagination
          component="div"
          showFirstButton
          showLastButton
          count={tableData?.pages[0]?.total ? tableData?.pages[0]?.total : 10}
          page={current}
          rowsPerPage={rowsPerPage}
          onPageChange={(_, page) => {
            fetchNextPage();
            setCurrent(page);
          }}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setCurrent(0);
          }}
        />
      </Stack>
      {addShift && (
        <AddShiftModal
          tableStyle={tableStyle}
          addShift={addShift}
          handleAddModal={handleAddModal}
          rowDetails={rowDetails}
        />
      )}
      {delRow && <DeleteShift delRow={delRow} setDelrow={setDelrow} />}
    </Paper>
  );
};

export default ShiftModal;
