import Button, { buttonClasses } from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const StyledActionButton = styled(Button)(() => ({
  [`&.${buttonClasses.root}`]: {
    minWidth: "110px",
  },
}));

export default StyledActionButton;
