import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import "../../styles/Sidebar/sidebar.scss";
import Tooltip from "@mui/material/Tooltip";
import CloseFullscreenOutlinedIcon from "@mui/icons-material/CloseFullscreenOutlined";
import "react-pro-sidebar/dist/css/styles.css";
import SidebarMenuItem from "./SidebarMenuItem";
import { SidebarItems } from "./SidebarItem";

const Sidebar = ({
  collapsed,
  sidebarCollapsed,
  handleToggleSidebar,
  toggled,
  secondaryColor,
  handleLeave,
  handleWFH,
  handleMyRequests,
  handleOT,
  handleConversion,
  handleShift,
  handleTabby,
  handleFeedback,
  handleTimeInOut,
  handleDocument,
}) => {
  const toolTipprop = {
    arrow: true,
    leaveDelay: 200,
    placement: "right",
  };

  const sidebaritems = SidebarItems({
    handleLeave,
    handleWFH,
    handleMyRequests,
    handleOT,
    handleConversion,
    handleShift,
    handleTabby,
    handleFeedback,
    handleTimeInOut,
    handleDocument,
  });

  return (
    <>
      <ProSidebar
        breakPoint="md"
        collapsed={collapsed}
        toggled={toggled}
        onToggle={handleToggleSidebar}
      >
        <SidebarContent style={{ backgroundColor: secondaryColor }}>
          <Menu iconShape="circle">
            {sidebaritems.map((elem, index) => (
              <SidebarMenuItem
                sidebarItem={elem}
                index={index}
                collapsed={collapsed}
                key={index}
              />
            ))}
          </Menu>
        </SidebarContent>

        <SidebarFooter style={{ backgroundColor: secondaryColor }}>
          <Menu iconShape="circle">
            <Tooltip title={collapsed ? "Collapse" : ""} {...toolTipprop}>
              <MenuItem
                icon={<CloseFullscreenOutlinedIcon />}
                onClick={() => {
                  sidebarCollapsed();
                }}
              >
                Collapse
              </MenuItem>
            </Tooltip>
          </Menu>
          <style>
            {`
              .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
                background-color: ${secondaryColor};
              }
            `}
          </style>
        </SidebarFooter>
      </ProSidebar>
    </>
  );
};

export default Sidebar;
