import Skeleton from "@mui/material/Skeleton";

import StyledTableCell from "../tableComponents/StyledTableCell";
import { generateRandomNum } from "../../../../helper/UserApplicationMgmt/UserApplications";

const StatusCellSkeleton = () => {
  return (
    <StyledTableCell sx={{ verticalAlign: "top" }}>
      <Skeleton variant="text" width={90} sx={{ fontSize: "20px" }} />

      <Skeleton
        variant="text"
        width={generateRandomNum(100, 120)}
        sx={{ fontSize: "20px" }}
      />
    </StyledTableCell>
  );
};

export default StatusCellSkeleton;
