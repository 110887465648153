import React, { useState, useRef } from "react";
import {
  Grid,
  Typography,
  Button,
  Paper,
  TableContainer,
  Table,
  Switch,
  Tooltip,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import Search from "../../../../component/AdminTables/Search";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Addholiday from "./Addholiday";
import HeaderUI from "../../../../component/AdminTables/HeaderUI";
import {
  headerContent,
  tableCol,
  years,
} from "../../../../helper/Settings/Time";
import {
  useGetHoliday,
  useUpdateHoliday,
} from "../../../../hooks/react-query/Settings/time";
import BodyUI from "../../../../component/AdminTables/BodyUI";
import SkeletonLoader from "../../../../component/AdminTables/SkeletonLoader";
import ServerPagination from "../../../../component/Pagination/ServerPagination";
import useDebounce from "../../../../hooks/customHooks/debounce";
import DropDownSelect from "../../../../component/CustomElement/DropDownSelect";
import Actions from "./Actions";
import ConfirmationModal from "./ConfirmationModal";
import { toast } from "react-toastify";

const Holiday = () => {
  const [openModal, setOpenModal] = useState({ add: false, update: false });
  const [params, setParams] = useState({
    per_page: 25,
    year: new Date().getFullYear(),
    search: "",
    page: 1,
    status: 1,
  });
  const debounceValue = useDebounce(params.search, 800);
  const textInput = useRef(null);

  const { data, isFetching, refetch } = useGetHoliday(
    params.per_page,
    params.year,
    debounceValue,
    params.page,
    params.status
  );

  const { mutate, isLoading } = useUpdateHoliday();

  const handleCloseModal = () => {
    setOpenModal({ ...openModal, add: false });
  };

  const nextPage = () => {
    setParams({ ...params, page: params.page + 1 });
  };

  const backPage = () => {
    setParams({ ...params, page: params.page - 1 });
  };
  const startPage = () => {
    setParams({ ...params, page: 1 });
  };
  const lastPage = () => {
    !isFetching && setParams({ ...params, page: data?.data.data.last_page });
  };
  const ClearSearch = () => {
    textInput.current.value = "";
    setParams({ ...params, search: "", page: 1 });
  };

  const handleSearch = (e) => {
    setParams({
      ...params,
      search: e.target.value,
      page: 1,
    });
  };

  const handleYearChange = (e) => {
    setParams({ ...params, year: e.target.value });
  };

  const showInactive = (e, c) => {
    if (c) {
      setParams({ ...params, status: 0 });
    } else {
      setParams({ ...params, status: 1 });
    }
  };

  const handleCloseUpdate = () => {
    setOpenModal({ ...openModal, update: false });
  };

  const updateHoliday = () => {
    mutate("", {
      onSuccess: (e) => {
        toast.success(e.data);
        handleCloseUpdate();
      },
    });
  };

  return (
    <Grid container sx={{ padding: "15px" }}>
      <Grid container mb={3} sx={{ justifyContent: "space-between" }}>
        <Tooltip
          sx={{ cursor: "context-menu" }}
          title="Manage company holiday"
          placement={"right"}
          arrow
        >
          <Typography variant="h4">Holiday</Typography>
        </Tooltip>

        <Button
          startIcon={<RefreshIcon />}
          variant="text"
          sx={{ padding: "5px", textTransform: "none", color: "#00C292" }}
          onClick={refetch}
        >
          <Typography sx={{ fontSize: "0.78rem" }}>Refresh</Typography>
        </Button>
      </Grid>
      <Paper style={{ width: "100%", padding: "20px", marginTop: "20px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Grid container>
            <Grid item>
              <DropDownSelect
                DropDownData={years()}
                label={"Year"}
                objectName={{ val: "id", name: "id" }}
                size={"small"}
                minWidth={100}
                value={params.year}
                onChange={handleYearChange}
              />
            </Grid>

            <Grid item ml={2}>
              <Button
                startIcon={<AddCircleIcon />}
                size={"small"}
                onClick={() => setOpenModal({ ...openModal, add: true })}
              >
                Add Event/Holiday
              </Button>
            </Grid>
            <Grid item ml={2}>
              <Button
                startIcon={<AddCircleIcon />}
                size={"small"}
                onClick={() => setOpenModal({ ...openModal, update: true })}
              >
                Update Event/Holiday
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <div style={{ fontSize: "0.85rem" }}>Show Inactive:</div>
            <Switch onChange={showInactive} />
          </Grid>
        </div>
      </Paper>
      <Paper style={{ width: "100%", padding: "20px", marginTop: "20px" }}>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Search
            textInput={textInput}
            ClearSearch={ClearSearch}
            setSearch={handleSearch}
            variant={"standard"}
          />
        </div>
        <TableContainer
          sx={{
            maxHeight: 430,
            padding: "0px 0px 20px 0px",
            overflowX: "auto",
          }}
        >
          <Table stickyHeader style={{ overflowX: "auto" }}>
            <HeaderUI headers={headerContent} />
            {!isFetching && data ? (
              <BodyUI
                rows={data?.data.data.data}
                cols={tableCol}
                Action={Actions}
              />
            ) : (
              <SkeletonLoader colSpan={headerContent.length} />
            )}
          </Table>
        </TableContainer>
        {!isFetching && (
          <ServerPagination
            field={"per_page"}
            from={data?.data.data.from}
            to={data?.data.data.to}
            total={data?.data.data.total}
            pageData={{
              to: data?.data.data.to,
              from: data?.data.data.from,
              total: data?.data.data.total,
              perPage: params.per_page,
            }}
            setPerPage={setParams}
            startPage={startPage}
            lastPage={lastPage}
            nextPage={nextPage}
            backPage={backPage}
          />
        )}
      </Paper>
      <Addholiday open={openModal.add} handleClose={handleCloseModal} />
      <ConfirmationModal
        data={{
          open: openModal.update,
          msg: "Are you sure you want to update holiday?",
          submitLabel: "Update",
          submit: updateHoliday,
          isLoading: isLoading,
        }}
        handleClose={handleCloseUpdate}
      />
    </Grid>
  );
};

export default Holiday;
