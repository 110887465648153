import apiService from "../axios";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  AddTimesheet: async (data) => {
    try {
      const request = await apiService.post(`v1/timesheet/add-entry`, data, {
        Accept: `application/json`,
        "Content-Type": `multipart/form-data`,
        body: JSON.stringify(data),
      });
      return request.data;
    } catch (error) {
      throw error;
    }
  },

  GetTimesheetWeek: async (data) => {
    try {
      const request = await apiService.post(
        `v5/timesheet/get-timesheet-week`,
        data,
        {
          Accept: `application/json`,
          "Content-Type": `multipart/form-data`,
          body: JSON.stringify(data),
        }
      );
      return request.data;
    } catch (error) {
      throw error;
    }
  },
  GetTimesheetMonth: async (data) => {
    try {
      const request = await apiService.post(
        `v1/timesheet/get-timesheet-week`,
        data,
        {
          Accept: `application/json`,
          "Content-Type": `multipart/form-data`,
          body: JSON.stringify(data),
        }
      );
      return request.data;
    } catch (error) {
      throw error;
    }
  },
  deleteTimesheet: async (data) => {
    try {
      const request = await apiService.post(`v1/timesheet/delete-entry`, data, {
        Accept: `application/json`,
        "Content-Type": `multipart/form-data`,
        body: JSON.stringify(data),
      });

      return request.data;
    } catch (error) {
      throw error;
    }
  },
  updateTimesheetEntry: async (updatedEntry) => {
    try {
      const request = await apiService.post(
        `/v1/timesheet/update-entry`,
        updatedEntry
      );

      return request.data;
    } catch (error) {
      throw error;
    }
  },
};
