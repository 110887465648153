import React from "react";
import Paper from "@mui/material/Paper";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import SearchComponent from "../../../UserApplicationSummary/SearchComponent";
import RefreshButton from "../../../UserApplicationSummary/RefreshButton";
import hooks from "../../../../hooks/react-query/queryHooks";
import { getAuth } from "../../../../hooks/customHooks/auth";
import {
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { toast, ToastContainer } from "react-toastify";
import ExportComponent from "../../../UserApplicationSummary/ExportComponent";
import { Box } from "@mui/system";

const OptionContainer = ({
  setRange,
  range,
  setStartDate,
  startDate,
  setEndDate,
  endDate,
  setStatus,
  status,
  setType,
  type,
  search,
  setSearch,
  setPage,
  select,
  setNotLoading,
  summaryTotal,
}) => {
  const user = getAuth();
  const { data: leave } = hooks.useLeaveType(user?.employee?.id);
  const currentDate = new Date();
  const getLastWeeksDate = (numWeeks) => {
    const numOfDayInAWeek = 7;
    return new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - numOfDayInAWeek * numWeeks
    );
  };
  const getLastMonthsDate = (numOfMonths) => {
    return new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - numOfMonths,
      currentDate.getDate()
    );
  };
  const handleRangeChange = (event) => {
    setRange(event.target.value);
    switch (event.target.value) {
      case "This week":
        setStartDate(format(startOfWeek(currentDate), "yyyy-MM-dd"));
        setEndDate(format(endOfWeek(currentDate), "yyyy-MM-dd"));
        break;
      case "Last week":
        setStartDate(format(startOfWeek(getLastWeeksDate(1)), "yyyy-MM-dd"));
        setEndDate(format(endOfWeek(getLastWeeksDate(1)), "yyyy-MM-dd"));
        break;
      case "Last 2 weeks":
        setStartDate(format(startOfWeek(getLastWeeksDate(2)), "yyyy-MM-dd"));
        setEndDate(format(endOfWeek(getLastWeeksDate(1)), "yyyy-MM-dd"));
        break;
      case "This month":
        setStartDate(format(startOfMonth(currentDate), "yyyy-MM-dd"));
        setEndDate(format(endOfMonth(currentDate), "yyyy-MM-dd"));
        break;
      case "Last month":
        setStartDate(format(startOfMonth(getLastMonthsDate(1)), "yyyy-MM-dd"));
        setEndDate(format(endOfMonth(getLastMonthsDate(1)), "yyyy-MM-dd"));
        break;
      case "Last 2 months":
        setStartDate(format(startOfMonth(getLastMonthsDate(2)), "yyyy-MM-dd"));
        setEndDate(format(endOfMonth(getLastMonthsDate(1)), "yyyy-MM-dd"));
        break;
      case "Custom":
        setStartDate(format(startOfMonth(currentDate), "yyyy-MM-dd"));
        setEndDate(format(endOfMonth(currentDate), "yyyy-MM-dd"));
        break;
      default:
      //intentionally empty
    }
    setPage(1);
  };

  const handleCalendarStartChange = (newValue) => {
    try {
      if (format(newValue.$d, "yyyy-MM-dd") > endDate) {
        toast.error("Start date should not be after end date.");
      } else {
        setRange("Custom");
        setStartDate(format(newValue.$d, "yyyy-MM-dd"));
        setPage(1);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleCalendarEndChange = (newValue) => {
    try {
      if (startDate > format(newValue.$d, "yyyy-MM-dd")) {
        toast.error("End date should not be before start date.");
      } else {
        setRange("Custom");
        setEndDate(format(newValue.$d, "yyyy-MM-dd"));
        setPage(1);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
    setPage(1);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setPage(1);
  };

  return (
    <Paper elevation={3} sx={{ margin: "15px 20px" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box>
          <FormControl sx={{ m: "10px" }} size={"small"}>
            <InputLabel id="demo-simple-select-helper-label">Range</InputLabel>
            <Select
              value={range}
              label={"Range"}
              onChange={handleRangeChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value={"This week"}>This week</MenuItem>
              <MenuItem value={"Last week"}>Last week</MenuItem>
              <MenuItem value={"Last 2 weeks"}>Last 2 weeks</MenuItem>
              <MenuItem value={"This month"}>This month</MenuItem>
              <MenuItem value={"Last month"}>Last month</MenuItem>
              <MenuItem value={"Last 2 months"}>Last 2 months</MenuItem>
              <MenuItem value={"Custom"}>Custom</MenuItem>
            </Select>
          </FormControl>
          <DesktopDatePicker
            label="Start"
            inputFormat="MM/DD/YYYY"
            value={startDate}
            onChange={handleCalendarStartChange}
            renderInput={(params) => (
              <TextField
                size={"small"}
                sx={{ m: "10px", maxWidth: "150px" }}
                {...params}
              />
            )}
          />
          <DesktopDatePicker
            label="End"
            inputFormat="MM/DD/YYYY"
            value={endDate}
            onChange={handleCalendarEndChange}
            renderInput={(params) => (
              <TextField
                size={"small"}
                sx={{ m: "10px", maxWidth: "150px" }}
                {...params}
              />
            )}
          />
          {select !== "WFH" && (
            <FormControl sx={{ m: "10px" }} size={"small"}>
              <InputLabel id="demo-simple-select-helper-label">Type</InputLabel>
              {select === "Leave" ? (
                <Select
                  value={type}
                  label={"Type"}
                  onChange={handleTypeChange}
                  displayEmpty
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  {leave?.type?.data?.map((name) => {
                    return (
                      <MenuItem key={name.id} value={name.name}>
                        {name.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              ) : select === "ForgotTimeInOut" ? (
                <Select
                  value={type}
                  label={"Type"}
                  onChange={handleTypeChange}
                  displayEmpty
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value={"in"}>Time In</MenuItem>
                  <MenuItem value={"out"}>Time Out</MenuItem>
                </Select>
              ) : (
                <Select
                  value={type}
                  label={"Type"}
                  onChange={handleTypeChange}
                  displayEmpty
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value={"Over Time"}>OverTime</MenuItem>
                  <MenuItem value={"Under Time"}>UnderTime</MenuItem>
                </Select>
              )}
            </FormControl>
          )}
          <FormControl sx={{ m: "10px" }} size={"small"}>
            <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
            <Select
              value={status}
              label={"Status"}
              onChange={handleStatusChange}
              displayEmpty
            >
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={"Approved"}>Approved</MenuItem>
              <MenuItem value={"Cancelled"}>Cancelled</MenuItem>
              <MenuItem value={"Disapproved"}>Disapproved</MenuItem>
              <MenuItem value={"Pending"}>Pending</MenuItem>
              <MenuItem value={"Approval"}>Approval</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ m: "10px" }}>
                <SearchComponent
                  search={search}
                  setSearch={setSearch}
                  setPage={setPage}
                />
              </Box>
              {summaryTotal > 0 && (
                <Box sx={{ m: "10px", display: "flex" }}>
                  <ExportComponent
                    data={{
                      select,
                      startDate,
                      endDate,
                      type,
                      status,
                      search,
                      summaryTotal,
                    }}
                  />
                </Box>
              )}
              <Box sx={{ display: "flex" }}>
                <RefreshButton setNotLoading={setNotLoading} />
              </Box>
            </Box>
          </FormControl>
        </Box>
      </LocalizationProvider>
      <ToastContainer position="bottom-right" theme="colored" />
    </Paper>
  );
};
export default OptionContainer;
