import { FormControl, Typography, Box } from "@mui/material";
import TextField from "../../../component/SettingsSystem/WfhTemplate/TextField";
import DatePicker from "../../../component/SettingsSystem/WfhTemplate/DatePicker";
import CkEditor from "../../../component/SettingsSystem/WfhTemplate/CkEditor";
const Edit = ({ formFields, form, setForm, errors }) => {
  return (
    <>
      {formFields &&
        formFields.map((field, index) => (
          <FormControl sx={{ mt: 1 }} fullWidth key={index}>
            {field.type === "text" && (
              <TextField
                key={index}
                field={field}
                form={form}
                setForm={setForm}
                errors={errors}
              />
            )}

            {field.type === "date" && (
              <DatePicker
                key={index}
                field={field}
                form={form}
                setForm={setForm}
                errors={errors}
              />
            )}

            {field.type === "CkEditor" && (
              <>
                {errors[field.helperText] && true ? (
                  <>
                    <Typography
                      sx={{ mt: 1, ml: 2, color: "red" }}
                      variant="caption"
                    >
                      Content Field is Required
                    </Typography>
                    <Box
                      sx={{
                        "&:click": {
                          border: "0.1em solid",
                          borderColor: "red",
                        },
                        border: "0.01em solid",
                        borderColor: "red",
                      }}
                    >
                      <CkEditor
                        data={form[field.payload]}
                        setData={(e) =>
                          setForm({
                            ...form,
                            [field.payload]: e,
                          })
                        }
                      />
                    </Box>
                  </>
                ) : (
                  <CkEditor
                    data={form[field.payload]}
                    setData={(e) =>
                      setForm({
                        ...form,
                        [field.payload]: e,
                      })
                    }
                  />
                )}
              </>
            )}
          </FormControl>
        ))}
    </>
  );
};

export default Edit;
