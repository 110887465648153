import { Fragment, useState } from "react";

import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import StyledTableCell from "../../../../UserApplicationMgmt/UserApplications/tableComponents/StyledTableCell";
import CollapsedTableCell from "../CollapsedTableCell";
import PerProjectTable from "../PerProjectTable/";

const PerUserTableRow = ({ activityId, user, state }) => {
  const { name, efforts, row_id: userId } = user;

  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandCollapse = () => {
    setIsExpanded((previousState) => !previousState);
  };

  return (
    <Fragment>
      <TableRow hover>
        {/* Expand/Collapse button */}
        <StyledTableCell>
          <IconButton onClick={handleExpandCollapse}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>

        {/* Name */}
        <StyledTableCell>{name}</StyledTableCell>

        {/* Total efforts */}
        <StyledTableCell>{efforts}</StyledTableCell>
      </TableRow>

      <TableRow>
        <CollapsedTableCell colSpan={3}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <PerProjectTable
              activityId={activityId}
              userId={userId}
              state={state}
            />
          </Collapse>
        </CollapsedTableCell>
      </TableRow>
    </Fragment>
  );
};

export default PerUserTableRow;
