import React, { useContext } from "react";

import {
  Backdrop,
  Modal,
  Fade,
  Stack,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
} from "@mui/material";

import { UserListContext } from ".";
import { downloadCsv, downloadPdf } from "../../../helper/UserLists";

const ExportModal = () => {
  const { isExportModalOpen, setIsExportModalOpen, userList } =
    useContext(UserListContext);

  const [optionSelected, setOptionSelected] = React.useState("csv");

  const handleClose = () => {
    setIsExportModalOpen(!isExportModalOpen);
  };

  const handleExports = () => {
    if (optionSelected === "csv") downloadCsv(userList.data.data);

    if (optionSelected === "pdf") downloadPdf(userList.data.data);

    handleClose();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "320px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: "28px 30px",
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isExportModalOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isExportModalOpen} unmountOnExit>
        <Stack gap={2} sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Export As
          </Typography>

          <FormControl sx={{ marginLeft: 1 }}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="csv"
              name="controlled-radio-buttons-group"
              value={optionSelected}
              onChange={(e) => setOptionSelected(e.target.value)}
            >
              <FormControlLabel value="csv" control={<Radio />} label="CSV" />

              <FormControlLabel value="pdf" control={<Radio />} label="PDF" />
            </RadioGroup>
          </FormControl>

          <Stack
            gap={1}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              size="medium"
              variant="outlined"
              onClick={() => setIsExportModalOpen(false)}
            >
              <Typography sx={{ fontSize: "0.78rem" }}>CANCEL</Typography>
            </Button>

            <Button size="medium" onClick={handleExports} variant="contained">
              <Typography sx={{ fontSize: "0.78rem" }}>EXPORT</Typography>
            </Button>
          </Stack>
        </Stack>
      </Fade>
    </Modal>
  );
};

export default ExportModal;
