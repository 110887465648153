import React from "react";
import { TableCell, IconButton, Chip } from "@mui/material";

import {
  isJsonString,
  FindDepartment,
} from "../../../helper/ProjectCodeSettings";
import EditIcon from "@mui/icons-material/Edit";
import CircleIcon from "@mui/icons-material/Circle";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";

const ShowRows = ({
  data,
  DataGetDept,
  setEditInd,
  editInd,
  delInd,
  setDelInd,
  index,
  setOpenHistory,
}) => {
  return (
    <>
      <TableCell sx={{ fontSize: "0.75rem" }}>{data.name}</TableCell>
      <TableCell sx={{ fontSize: "0.75rem" }}>{data.description}</TableCell>
      <TableCell sx={{ fontSize: "0.75rem" }}>
        {data.client ? data.client.company_code : "NA"}
      </TableCell>
      <TableCell align="center" sx={{ fontSize: "0.75rem" }}>
        {data.codetype?.name}
      </TableCell>
      <TableCell align="center" sx={{ fontSize: "0.75rem" }}>
        {isJsonString(data.key) ? (
          isJsonString(data.key).map((data, key) => (
            <Chip
              key={key}
              label={data}
              size="small"
              style={{
                fontSize: "0.65rem",
                color: FindDepartment(DataGetDept, data)?.color
                  ? "white"
                  : "black",
                backgroundColor: FindDepartment(DataGetDept, data)?.color,
              }}
            />
          ))
        ) : (
          <Chip
            key={index}
            label={data.key}
            size="small"
            style={{
              fontSize: "0.65rem",
              color: FindDepartment(DataGetDept, data.key)?.color
                ? "white"
                : "black",
              backgroundColor: FindDepartment(DataGetDept, data.key)?.color,
            }}
          />
        )}
      </TableCell>
      <TableCell sx={{ fontSize: "0.75rem" }}>
        {data.status === 1 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            Active{" "}
            <CircleIcon
              fontSize="small"
              style={{
                fontSize: "0.75rem",
                color: "#32CD32",
                paddingLeft: "2px",
              }}
            />
          </div>
        ) : (
          "Inactive"
        )}
      </TableCell>
      <TableCell align="center">
        {data.budget === "0.00" ? "open" : data.budget}
      </TableCell>
      <TableCell align="center">
        {data.balance === "0.00" ? "open" : data.balance}
      </TableCell>
      <TableCell align="center">
        {data.restricted === 1 ? <div style={{ color: "red" }}>Yes</div> : "No"}
      </TableCell>
      <TableCell align="center" sx={{ fontSize: "0.75rem" }}>
        {data.limit_per_month === 0 ? "No limit" : data.limit_per_month}
      </TableCell>
      <TableCell>
        <IconButton
          size="small"
          onClick={() =>
            setOpenHistory((openHistory) => ({
              ...openHistory,
              open: true,
              id: data.id,
            }))
          }
        >
          <HistoryToggleOffIcon fontSize="small" />
        </IconButton>
        <IconButton
          size="small"
          onClick={() => setEditInd(index)}
          disabled={delInd !== null || editInd !== null}
          sx={{ color: "#008a00" }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
        <IconButton
          size="small"
          onClick={() => setDelInd(index)}
          disabled={delInd !== null || editInd !== null}
          sx={{ color: "#db3700" }}
        >
          <DeleteOutlineIcon fontSize="small" />
        </IconButton>
      </TableCell>
    </>
  );
};

export default ShowRows;
