import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import {
  ViewState,
  EditingState,
  IntegratedEditing,
} from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  WeekView,
  Appointments,
  Toolbar,
  DateNavigator,
  AppointmentForm,
  TodayButton,
  AppointmentTooltip,
  ConfirmationDialog,
  DragDropProvider,
} from "@devexpress/dx-react-scheduler-material-ui";
import { toast } from "react-toastify";

import {
  addEmployeeSchedule,
  deleteEmployeeSchedule,
  updateEmployeeSchedule,
} from "../../api/employeeSchedule";
import { useEmployeeSchedule } from "../../hooks/react-query/EmployeeSchedule";
import {
  getStartAndEndDate,
  formatDate,
  isFutureDate,
  isSameDate,
} from "../../helper/EmployeeSchedule";

const CONTAINER_STYLE = {
  height: "750px",
  padding: "28px",
  margin: "18px",
  position: "relative",
  background: "#FFF",
  boxShadow: "0 0 5px  rgba(0,0,0,0.1)",
};

const CENTER_STYLE = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const appointmentComponent = (props) => {
  return <Appointments.Appointment {...props} onDoubleClick={() => false} />;
};

const commandLayoutComponent = (props) => {
  return <AppointmentForm.CommandLayout {...props} hideDeleteButton={true} />;
};

const UserScheduleNew = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const [currentDate, setCurrentDate] = useState(new Date());
  const [addedSchedule, setAddedSchedule] = useState({});
  const [scheduleChanges, setScheduleChanges] = useState({});
  const [editingSchedule, setEditingSchedule] = useState(undefined);

  const { data, isLoading, refetch, isError } = useEmployeeSchedule({
    id,
    currentDate,
  });

  const addScheduleMutation = useMutation(addEmployeeSchedule, {
    onSuccess: () => {
      queryClient.invalidateQueries("get-employee-schedule");
      toast.success("Schedule successfully added!");
    },
    onError: () => {
      toast.error("Error saving new schedule.");
    },
  });

  const deleteScheduleMutation = useMutation(deleteEmployeeSchedule, {
    onSuccess: () => {
      queryClient.invalidateQueries("get-employee-schedule");
      toast.success("Schedule deleted successfully!");
    },
    onError: () => {
      toast.error("Unable to delete schedule.");
    },
  });

  const updateScheduleMutation = useMutation(updateEmployeeSchedule, {
    onSuccess: () => {
      queryClient.invalidateQueries("get-employee-schedule");
      toast.success("Schedule updated successfully!");
    },
    onError: () => {
      toast.error("Error updating schedule.");
    },
  });

  useEffect(() => {
    refetch();
  }, [currentDate, refetch]);

  const changeDateHandler = (date) => {
    setCurrentDate(date);
  };

  const actionsHandler = ({ added, changed, deleted }) => {
    if (added) {
      if (!isFutureDate(added.startDate, added.endDate)) {
        toast.error("Invalid date!");
        return;
      }

      const time_start = formatDate(addedSchedule.startDate);
      const time_end = formatDate(addedSchedule.endDate);
      const { start_date, end_date } = getStartAndEndDate(currentDate);

      const newSchedulePayload = {
        emp_id: id,
        time_start,
        time_end,
        start_date,
        end_date,
      };

      addScheduleMutation.mutate(newSchedulePayload);
    }

    if (changed) {
      const changedSchedId = Object.keys(changed);

      if (
        !isFutureDate(
          changed[`${changedSchedId}`].startDate || editingSchedule.startDate,
          changed[`${changedSchedId}`].endDate || editingSchedule.endDate
        )
      ) {
        toast.error("Invalid date!");
        return;
      }

      if (
        editingSchedule.type === "resize-start" &&
        isSameDate(
          editingSchedule.startDate,
          changed[`${changedSchedId}`].startDate
        )
      )
        return;

      if (
        (editingSchedule.type === "vertical" ||
          editingSchedule.type === "resize-end") &&
        isSameDate(
          editingSchedule.endDate,
          changed[`${changedSchedId}`].endDate
        )
      )
        return;

      const schedToBeChanged = data.sched[changedSchedId];
      const { start_date, end_date } = getStartAndEndDate(currentDate);
      const { startDate: time_start, endDate: time_end } =
        changed[changedSchedId];

      const changedSchedulePayload = {
        emp_id: id,
        date: schedToBeChanged.date,
        time_start: formatDate(time_start),
        time_end: formatDate(time_end),
        start_date,
        end_date,
      };

      updateScheduleMutation.mutate(changedSchedulePayload);
    }

    if (deleted !== undefined) {
      const { start_date, end_date } = getStartAndEndDate(currentDate);

      const deleteSchedulePayload = {
        emp_id: id,
        date: data.sched[`${deleted}`].date,
        start_date,
        end_date,
      };

      deleteScheduleMutation.mutate(deleteSchedulePayload);
    }
  };

  const onAddedAppointmentChangeHandler = (addedSchedule) => {
    setAddedSchedule(addedSchedule);
  };

  const onScheduleChangesChangeHandler = (scheduleChanges) => {
    setScheduleChanges(scheduleChanges);
  };

  const onEditingScheduleChangeHandler = (editingSchedule) => {
    setEditingSchedule(editingSchedule);
  };

  return (
    <Box sx={CONTAINER_STYLE}>
      {isLoading && <CircularProgress sx={CENTER_STYLE} />}

      {!isLoading && !isError && (
        <Scheduler
          height={650}
          data={data.sched.map((el) => ({
            ...el,
            startDate: new Date(el.startDate),
            endDate: new Date(el.endDate),
          }))}
        >
          <Typography
            sx={{
              alignSelf: "flex-start",
              fontSize: "20px",
              mb: "20px",
            }}
          >
            {`${data.name}'s Schedule`}
          </Typography>
          <ViewState
            currentDate={currentDate}
            onCurrentDateChange={changeDateHandler}
          />
          <EditingState
            onCommitChanges={actionsHandler}
            onAddedAppointmentChange={onAddedAppointmentChangeHandler}
            appointmentChanges={scheduleChanges}
            onAppointmentChangesChange={onScheduleChangesChangeHandler}
            editingAppointment={editingSchedule}
            onEditingAppointmentChange={onEditingScheduleChangeHandler}
          />
          <IntegratedEditing />
          <WeekView startDayHour={0} endDayHour={24} cellDuration={60} />
          <Toolbar />
          <DateNavigator />
          <TodayButton />
          <ConfirmationDialog
            messages={{
              confirmDeleteMessage:
                "Are you sure you want to delete this schedule?",
            }}
          />
          <Appointments appointmentComponent={appointmentComponent} />
          <AppointmentTooltip showCloseButton showDeleteButton={true} />
          <AppointmentForm
            booleanEditorComponent={() => false}
            textEditorComponent={() => false}
            messages={{ detailsLabel: "Schedule", moreInformationLabel: "" }}
            commandLayoutComponent={commandLayoutComponent}
          />
          <DragDropProvider />
        </Scheduler>
      )}

      {isError && (
        <Typography variant="h6" sx={CENTER_STYLE}>
          Employee has no schedule
        </Typography>
      )}
    </Box>
  );
};

export default UserScheduleNew;
