import TableRow from "@mui/material/TableRow";

import TimesheetWeeklyReportTableCell from "./TimesheetWeeklyReportTableCell";
import { getSkipStartEnd } from "../../../../helper/Evaluation/TimesheetWeeklyReport";

const TimesheetWeeklyReportTableRow = ({ year, data, skip, limit }) => {
  const { fullReport } = data;
  const { averageWorkedHoursPerYear, weeklyReport } = fullReport[year];

  const average = averageWorkedHoursPerYear.toFixed(1);
  const { start, end } = getSkipStartEnd(skip, limit);

  const tableCellArr = weeklyReport.slice(start, end);

  return (
    <TableRow>
      {/* Year */}
      <TimesheetWeeklyReportTableCell>{year}</TimesheetWeeklyReportTableCell>

      {/* Average */}
      <TimesheetWeeklyReportTableCell>
        {average}%
      </TimesheetWeeklyReportTableCell>

      {tableCellArr.map((weekReport) => (
        <TimesheetWeeklyReportTableCell key={weekReport.week}>
          {weekReport.average === null
            ? "N/A"
            : `${weekReport.average.toFixed(1)}%`}
        </TimesheetWeeklyReportTableCell>
      ))}
    </TableRow>
  );
};

export default TimesheetWeeklyReportTableRow;
