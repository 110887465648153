import { TableCell, IconButton, TableRow, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const FeedbackComment = ({ cmnt, editCommentHandler }) => {
  return (
    <TableRow id={cmnt?.id}>
      <TableCell>
        <div
          style={{ fontSize: "0.7rem" }}
          dangerouslySetInnerHTML={{
            __html: cmnt?.employee?.name,
          }}
        />
      </TableCell>
      <TableCell>
        <div
          style={{ fontSize: "0.7rem" }}
          dangerouslySetInnerHTML={{
            __html: cmnt?.comment,
          }}
        />
      </TableCell>
      <TableCell align="center">
        <IconButton onClick={() => editCommentHandler(cmnt?.id)}>
          <Tooltip title="Edit comment">
            <EditIcon />
          </Tooltip>
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default FeedbackComment;
