import React, { useState } from "react";
import { TablePagination, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import RequestTabination from "../../../component/UserRequests/MyRequestComponent/RequestTabination";
import OptionContainer from "../../../component/UserApplicationMgmt/UserApplicationSummary/OptionContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import {
  useManagementSummary,
  useUserSummaryHeader,
} from "../../../hooks/react-query/UserAppSummary";
import TablePaginationActions from "../../../component/UserRequests/MyRequestComponent/Table/TablePaginationActions";
import TableRowHeader from "../../../component/UserApplicationSummary/Table";
import TableSkeleton from "../../../component/UserRequests/MyRequestComponent/Table/TableSkeleton";
import { useMutation, useQueryClient } from "react-query";
import {
  cancelRequest,
  getManagementSummary,
} from "../../../api/userApplicationSummary";
import TablePaginationController from "../../../component/UserRequests/MyRequestComponent/Table/TablePaginationControllerSkeleton";
import { endOfWeek, format, startOfWeek } from "date-fns";
import SummaryTableOption from "../../../component/UserApplicationSummary/SummaryTableOption";
import CancelModal from "../../../component/UserApplicationSummary/CancelModal";
import SummaryTableData from "../../../component/UserApplicationSummary/SummaryTableData";
import EmptyTableComponent from "../../../component/EmptyTableComponent";
import { toast } from "react-toastify";
import { toastAlertOptions } from "../../../hooks/customHooks/toastAlertOptions";

const UserApplicationSummary = () => {
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [select, setSelect] = useState("Leave");
  const { data: columns } = useUserSummaryHeader(select);
  const [range, setRange] = useState("This week");
  const [search, setSearch] = useState("");
  const [size, setSize] = useState("");
  const [startDate, setStartDate] = useState(
    format(startOfWeek(new Date()), "yyyy-MM-dd")
  );
  const [endDate, setEndDate] = useState(
    format(endOfWeek(new Date()), "yyyy-MM-dd")
  );
  const [status, setStatus] = useState("All");
  const [type, setType] = useState("All");
  const [notLoading, setNotLoading] = useState(true);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [toCancelData, setToCancelData] = useState("");
  const { data: summary } = useManagementSummary(
    select,
    startDate,
    endDate,
    status,
    size,
    page,
    search,
    type
  );

  const changePage = useMutation(
    ({ page }) =>
      getManagementSummary(
        select,
        startDate,
        endDate,
        status,
        size,
        page,
        search,
        type
      ),
    {
      onSuccess: async (newPageData) => {
        setNotLoading(() => true); // This will stop the loading effect
        setPage(newPageData?.current_page || 0);
      },
    }
  );
  const HandleChangePage = async (event, newPage) => {
    if (notLoading) {
      setNotLoading(() => false);
      const page = newPage + 1;
      changePage.mutate({ page });
    }
  };

  const handleChangeRowsPerPage = async (event) => {
    const size = event.target.value;
    setSize(size);
  };

  const handleClose = () => {
    setToCancelData("");
    setLoadingCancel(false);
    setOpenModal(false);
    toast.error("Cancel user application failed.", toastAlertOptions);
  };

  const cancelData = useMutation(() => cancelRequest(toCancelData, select), {
    onSuccess: async () => {
      await queryClient.refetchQueries(
        "get-management-summary",
        select,
        startDate,
        endDate,
        status,
        size,
        page,
        search,
        type
      );
      await setLoadingCancel(false);
    },
  });

  const confirmFunc = () => {
    cancelData.mutate();
    setLoadingCancel(true);
    setOpenModal(false);
  };

  return (
    <Box>
      <Box
        sx={{
          margin: "15px 20px",
          display: "flex",
          justifyContent: "flex-start",
          cursor: "context-menu",
        }}
      >
        <Tooltip
          title={"Manage employees application summary"}
          placement={"right"}
          arrow
        >
          <Typography variant={"h4"}>User Application Summary</Typography>
        </Tooltip>
      </Box>
      <RequestTabination
        length={summary?.data?.length}
        type={type}
        setType={setType}
        setPage={setPage}
        select={select}
        setSelect={setSelect}
        notInclude={"LeaveConversion"}
        startDate={startDate}
        endDate={endDate}
        status={status}
      />
      <OptionContainer
        setRange={setRange}
        range={range}
        setStartDate={setStartDate}
        startDate={startDate}
        setEndDate={setEndDate}
        endDate={endDate}
        setStatus={setStatus}
        status={status}
        setType={setType}
        type={type}
        search={search}
        setSearch={setSearch}
        setPage={setPage}
        select={select}
        setNotLoading={setNotLoading}
        summaryTotal={summary?.total}
      />
      <TableContainer sx={{ maxHeight: 550, px: "20px" }}>
        {summary?.data && notLoading ? (
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRowHeader columns={columns} />
            </TableHead>
            {summary?.data?.length > 0 ? (
              <TableBody>
                {summary?.data.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column, index) => {
                        let value = row[column.id];
                        return column.id === "action" ? (
                          <SummaryTableOption
                            key={index}
                            setToCancelData={setToCancelData}
                            toCancelData={toCancelData}
                            loadingCancel={loadingCancel}
                            row={row}
                            colId={column.id}
                            status={row.status}
                            setOpenModal={setOpenModal}
                          />
                        ) : (
                          <SummaryTableData
                            key={index}
                            select={select}
                            row={row}
                            column={column}
                            value={value}
                          />
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <EmptyTableComponent columns={columns} />
            )}
          </Table>
        ) : (
          <TableSkeleton />
        )}
      </TableContainer>
      {summary?.data && notLoading ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={summary?.total || 0}
          rowsPerPage={summary?.per_page || 10}
          page={page - 1}
          onPageChange={HandleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      ) : (
        <TablePaginationController />
      )}
      <CancelModal
        confirmFunc={confirmFunc}
        openModal={openModal}
        handleClose={handleClose}
      />
    </Box>
  );
};

export default UserApplicationSummary;
