import React, { useState } from "react";
import { TableRow, TableCell, TextField, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { format } from "date-fns";
import UpdateLeaveSettingsModal from "./UpdateLeaveSettingsModal";

const UserAppSettingsDataList = ({ item, id }) => {
  const [edit, setEdit] = useState(false);
  const date = new Date(item?.leave_setting.date_hired);
  const dateFormatted = format(date, "PP");
  const editHandler = () => {
    setEdit(true);
  };
  return (
    <>
      {edit && (
        <UpdateLeaveSettingsModal edit={edit} setEdit={setEdit} item={item} />
      )}
      <TableRow key={item?.id}>
        <TableCell>
          <TextField
            required
            multiline
            value={item?.full_name}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />
        </TableCell>
        <TableCell>
          <TextField
            required
            value={item?.leave_setting.approvers}
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />
        </TableCell>
        <TableCell>
          <TextField
            required
            value={item?.leave_setting.status}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />
        </TableCell>
        <TableCell>
          <TextField
            required
            value={dateFormatted}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />
        </TableCell>
        <TableCell align="center">
          <IconButton sx={{ marginRight: "20px" }} onClick={editHandler}>
            <EditIcon style={{ color: "#00C292" }} />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default UserAppSettingsDataList;
