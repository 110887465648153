import { useQuery } from "react-query";
import {
  getWfhTemplates,
  getWfhTemplate,
} from "../../../../api/settingsSystem/wfhTemplate";

const useWfhTemplates = (
  QueryParam,
  search = "",
  pageSize = 10,
  page = 1,
  fetch
) => {
  return useQuery(["get-wfh-templates", search, pageSize, page, fetch], () =>
    getWfhTemplates(QueryParam)
  );
};

const useWfhTemplate = () => {
  return useQuery(["get-wfh-templates"], () => getWfhTemplate());
};

const queries = {
  useWfhTemplate,
  useWfhTemplates,
};

export default queries;
