import { TextField as MuiTextField } from "@mui/material";
const TextField = ({ field, form, setForm, errors }) => {
  return (
    <MuiTextField
      autoFocus
      margin="dense"
      label={field.label}
      fullWidth
      defaultValue={form[field.payload]}
      variant="outlined"
      onChange={(e) => {
        setForm({
          ...form,
          [field.payload]: e.target.value,
        });
      }}
      helperText={errors[field.helperText]}
      error={errors[field.helperText] && true}
    />
  );
};

export default TextField;
