import Skeleton from "@mui/material/Skeleton";

import StyledTableCell from "../tableComponents/StyledTableCell";
import { generateRandomNum } from "../../../../helper/UserApplicationMgmt/UserApplications";

const WorkFromHomeCellSkeleton = () => {
  return (
    <StyledTableCell sx={{ verticalAlign: "top" }}>
      <Skeleton variant="text" width={120} sx={{ fontSize: "20px" }} />

      <Skeleton
        variant="text"
        width={generateRandomNum(210, 420)}
        sx={{ fontSize: "20px" }}
      />

      <Skeleton variant="text" width={130} sx={{ fontSize: "20px" }} />
      <Skeleton variant="text" width={100} sx={{ fontSize: "20px" }} />

      <Skeleton variant="text" width={155} sx={{ fontSize: "20px" }} />
      <Skeleton variant="text" width={32} sx={{ fontSize: "20px" }} />
    </StyledTableCell>
  );
};

export default WorkFromHomeCellSkeleton;
