import React, { useState, useContext } from "react";
import {
  TableRow,
  TableCell,
  TextField,
  IconButton,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CheckIcon from "@mui/icons-material/Check";
import hooks from "../../../hooks/react-query/ProjectType";
import DeleteProjectType from "./DeleteProjectType";
import { ProjectTypeCntxt } from ".";

const ProjectTypeList = ({ item, index }) => {
  const { refetch } = useContext(ProjectTypeCntxt);
  const [deleteRow, setDeleteRow] = useState(false);
  const [edit, setEdit] = useState(true);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(item?.status);
  const deleteProjectType = hooks.useDeleteProjectType();
  const editProjectType = hooks.useEditTypes();

  const deleteHandler = () => {
    const data = {
      created_at: item?.created_at,
      deleted: item?.deleted,
      id: item?.id,
      key: null,
      name: item?.name,
      status: item?.status,
    };
    deleteProjectType.mutate(data);
  };

  const handleEdit = () => {
    setEdit(!edit);
    if (!edit) {
      const editData = {
        created_at: item?.created_at,
        deleted: item?.deleted,
        id: item?.id,
        key: null,
        name: name !== "" ? name : item?.name,
        status: status,
        description: description !== "" ? description : item.description,
      };
      editProjectType.mutate(editData);
      refetch();
    }
  };

  const showDeleteModal = () => {
    setDeleteRow(true);
  };

  const closeHandler = () => {
    setDeleteRow(false);
  };

  const cancelHandler = () => {
    setEdit(true);
  };
  return (
    <>
      {deleteRow && (
        <DeleteProjectType
          deleteRow={deleteRow}
          closeHandler={closeHandler}
          deleteHandler={deleteHandler}
        />
      )}
      <TableRow key={index}>
        <TableCell>
          <TextField
            required
            defaultValue={item.name}
            InputProps={{
              readOnly: edit,
              disableUnderline: edit,
            }}
            variant="standard"
            onChange={(e) => setName(e.target.value)}
            multiline
          />
        </TableCell>
        <TableCell>
          <TextField
            required
            multiline
            onChange={(e) => setDescription(e.target.value)}
            defaultValue={item.description}
            InputProps={{
              readOnly: edit,
              disableUnderline: edit,
            }}
            variant="standard"
          />
        </TableCell>
        <TableCell>
          {/* {edit ? ( */}
          <TextField
            fullWidth
            required
            select={edit === true ? false : true}
            InputProps={{
              readOnly: edit,
              disableUnderline: edit,
            }}
            value={
              edit === false ? status : status === 1 ? "Active" : "Inactive"
            }
            variant="standard"
            onChange={(e) => setStatus(e.target.value)}
          >
            <MenuItem value={1}>Active</MenuItem>
            <MenuItem value={0}>Inactive</MenuItem>
          </TextField>
          {/* ) : (
            <FormControl>
              <NativeSelect
                defaultValue={item?.status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value={1}>Active</option>
                <option value={0}>Inactive</option>
              </NativeSelect>
            </FormControl>
          )} */}
        </TableCell>
        <TableCell align="center">
          <IconButton onClick={handleEdit}>
            {edit === true ? (
              <EditIcon style={{ color: "#00C292" }} />
            ) : (
              <CheckIcon style={{ color: "#00C292" }} />
            )}
          </IconButton>
          {edit === true ? (
            <IconButton onClick={showDeleteModal} sx={{ marginRight: "5px" }}>
              <DeleteOutlineIcon style={{ color: "#000000" }} />
            </IconButton>
          ) : (
            <IconButton sx={{ marginRight: "5px" }} onClick={cancelHandler}>
              <CloseIcon style={{ color: "#ff0000" }} />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default ProjectTypeList;
