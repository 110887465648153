import apiService from "../../axios";

export const getComplianceCheck = async ({
  date,
  page_size = 10,
  search,
  page = 1,
}) => {
  try {
    let response = await apiService.get(
      `v5/compliance/get-checker?per_page=${page_size}&page=${page}&search=${search}&date=${date}`,
      {
        Accept: `application/json`,
        "Content-Type": "application/json",
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
