export const PerProjectActivityTableHeader = [
  {
    label: "Project",
    key: "name",
    isSortable: true,
  },
  {
    label: "Description",
    key: "description",
    isSortable: true,
  },
  {
    label: "Total Efforts",
    key: "efforts",
    isSortable: true,
    align: "right",
    type: "number",
  },
];

export const ProjectListTableHeader = [
  {
    label: "Activity",
    key: "name",
    parentKey: "time_activity",
    isSortable: true,
  },
  {
    label: "Description",
    parentKey: "time_activity",
    key: "description",
    isSortable: true,
  },
  {
    label: "Total Efforts",
    key: "hours",
    isSortable: true,
    align: "right",
    type: "number",
  },
];

export const StaffListTableHeader = [
  {
    label: "User",
    key: "full_name",
    parentKey: "time_employee",
    isSortable: true,
  },
  {
    label: "Total Efforts",
    key: "hrs",
    isSortable: true,
    align: "right",
    type: "number",
  },
];

export const TimesheetListTableHeader = [
  {
    label: "Date",
    key: "created_at",
    isSortable: true,
  },
  {
    label: "Milestone",
    key: "milestone",
    isSortable: true,
  },
  {
    label: "Details",
    key: "comment",
    isSortable: true,
  },
  {
    label: "Total Efforts",
    key: "hours",
    isSortable: true,
    align: "right",
    type: "number",
  },
];
