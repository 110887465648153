import {
  FormControl,
  Box,
  Chip,
  Checkbox,
  MenuItem,
  Select as MuiSelect,
  InputLabel,
} from "@mui/material";
import { useState } from "react";
const ChipSelect = ({ field, form, setForm, options, value = [] }) => {
  const [selected, setSelect] = useState(value);

  const handleSelect = (e) => {
    setSelect(e.target.value);

    setForm({
      ...form,
      [field.payload]: e.target.value,
    });
  };

  return (
    <FormControl fullWidth sx={{ mt: 1.5 }}>
      <InputLabel id="department">{field.label}</InputLabel>
      <MuiSelect
        labelId="department"
        multiple
        id="department-select"
        value={selected}
        label="Departments"
        onChange={(e) => {
          handleSelect(e);
        }}
        error={field.helperText && true}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip
                key={value}
                label={
                  field.options.find((option) => {
                    return option.short_name === value;
                  })?.short_name
                }
              />
            ))}
          </Box>
        )}
      >
        {field.options ? (
          field.options.map((option, index) => (
            <MenuItem key={index} value={option.short_name}>
              {" "}
              <Checkbox checked={selected.includes(option.short_name)} />
              {option.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem value={0}> No Value </MenuItem>
        )}
      </MuiSelect>
      {/* {
                field.helperText && (
                    <FormHelperText sx={{color: red[500]}}>{field.helperText}</FormHelperText>
                )
            } */}
    </FormControl>
  );
};

export default ChipSelect;
