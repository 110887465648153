import apiService from "../../axios";
import { format } from "date-fns";

export const getPayrollReportAPI = async (
  start_date,
  end_date,
  ot_start,
  ot_end,
  page_size,
  page,
  search
) => {
  try {
    const data = await apiService.get(
      `v5/payroll/get-payroll-report?start_date=${start_date}&end_date=${end_date}&ot_start=${ot_start}&ot_end=${ot_end}&page_size=${page_size}&page=${page}&search=${search}`,
      {
        Accept: `application/json`,
        "Content-Type": `multipart/form-data`,
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const ExportPayRoll = async (payload) => {
  try {
    const req = await apiService.post(
      `v1/payroll/export/generate-payroll-report`,
      payload,
      {
        "Content-Type": "application/json",
        body: JSON.stringify(payload),
        responseType: "blob",
      }
    );
    const url = window.URL.createObjectURL(new Blob([req.data]));
    const link = document.createElement("a");
    const fileName =
      format(payload.fromDate, "PPP") +
      " - " +
      format(payload.toDate, "PPP") +
      " Payroll report";
    const ext = payload.type === "excel" ? ".xlsx" : ".csv";
    link.href = url;
    link.setAttribute("download", fileName + ext);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

    return true;
  } catch (error) {
    return error;
  }
};
