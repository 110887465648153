import { Fragment, useCallback, useState } from "react";

import TableRow from "@mui/material/TableRow";

import StyledTableCell from "./StyledTableCell";
import ActionButtons from "../button/ActionButtons";
import DeclineActionDialog from "../dialog/decline/DeclineActionDialog";
import ApproveActionDialog from "../dialog/approve/ApproveActionDialog";

const ApplicationTableRow = ({ data }) => {
  const { date_request, earned, name, request, status } = data;

  const [isDeclineDialogOpen, setIsDeclineDialogOpen] = useState(false);
  const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);

  const handleCloseDialogs = useCallback(() => {
    setIsApproveDialogOpen(false);
    setIsDeclineDialogOpen(false);
  }, []);

  const handleApproveAction = () => {
    setIsApproveDialogOpen(true);
  };

  const handleDeclineAction = () => {
    setIsDeclineDialogOpen(true);
  };

  return (
    <Fragment>
      <TableRow>
        {/* Name */}
        <StyledTableCell>{name}</StyledTableCell>

        {/* Request */}
        <StyledTableCell align="center">{request}</StyledTableCell>

        {/* Earned */}
        <StyledTableCell align="center">{earned}</StyledTableCell>

        {/* Status */}
        <StyledTableCell align="center">{status}</StyledTableCell>

        {/* Date Requested */}
        <StyledTableCell align="center">{date_request}</StyledTableCell>

        {/* Actions */}
        <StyledTableCell>
          <ActionButtons
            onApprove={handleApproveAction}
            onDecline={handleDeclineAction}
          />
        </StyledTableCell>
      </TableRow>

      {/* Approve Action Dialog */}
      <ApproveActionDialog
        data={data}
        open={isApproveDialogOpen}
        onClose={handleCloseDialogs}
      />

      {/* Decline Action Dialog */}
      <DeclineActionDialog
        data={data}
        open={isDeclineDialogOpen}
        onClose={handleCloseDialogs}
      />
    </Fragment>
  );
};

export default ApplicationTableRow;
