import { useQuery } from "react-query";
import { API_ENDPOINT, genGetRequest } from "../../helper/General";
import apiService from "../axios";
import moment from "moment";

export const generateDownloadableContent = async (payload, apiEndpoint) => {
  try {
    const response = await apiService.post(apiEndpoint, payload, {
      "Content-Type": "application/json",
      body: JSON.stringify(payload),
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    const fileName =
      moment(payload.fromDate).format("ll") +
      " - " +
      moment(payload.toDate).format("ll") +
      " Breakdown Per Code";
    const ext = payload.type === "excel" ? ".xlsx" : ".csv";
    link.href = url;
    link.setAttribute("download", fileName + ext);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

    return true;
  } catch (error) {
    throw error;
  }
};

export const useGetPerClient = (data) => {
  const range = data.fromDate + data.toDate;
  return useQuery(
    [
      "get-per-client",
      range,
      data.page_size,
      data.page,
      data.search,
      data.inActive,
      data.weekEnd,
    ],
    () => genGetRequest(data, API_ENDPOINT.GET_PER_CLIENTS),
    {
      keepPreviousData: true,
      staleTime: 500 * 1000,
    }
  );
};
