import { useState, useContext } from "react";

import {
  Box,
  Grid,
  Chip,
  Button,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Typography,
  CardHeader,
  CardContent,
  Card,
  Skeleton,
} from "@mui/material";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import AutoCompleteMUI from "../DropDown/AutoCompleteMUI";
import moment from "moment";
import { TimesheetContext } from "../../globalstate/UserContext/timesheetcontext";
import SelectDropDownUI from "./SelectDropdown";
import { getValidTimesheetDropdown } from "../../helper/timesheet";
import Summary from "./Summary";
import { GlobalContext } from "../../App";

const Timesheet = ({ weekDates, totalHours, isFetching }) => {
  const {
    selectedCode,
    setSelectedCode,
    selectedActivity,
    setSelectedActivity,
    setDate,
    setMyDuration,
    setIsEntry,
    weeklyDataTimesheet,
    handleEntryModal,
  } = useContext(TimesheetContext);
  const { projectActivity, projectCode } = useContext(GlobalContext);

  const [addTimesheet, setAddtimesheet] = useState(false);
  const [openSummaryModal, setOpenSummaryModal] = useState(false);
  const [timesheetSummary, setTimesheetSummary] = useState([]);

  const handleInput = (value, index) => {
    const date = moment(weekDates[index]).format("llll");
    const duration = parseFloat(value);
    setDate(date);
    setMyDuration(duration);
    if (selectedCode && selectedActivity) {
      handleEntryModal();
      setAddtimesheet(false);
    }
  };

  const HandleOpenSummary = (key) => {
    setOpenSummaryModal(true);
    setTimesheetSummary(weeklyDataTimesheet[key].time);
  };
  const HandleCloseSummary = () => setOpenSummaryModal(false);

  return (
    <Card className="tm-card">
      <CardHeader
        title={
          <Grid container style={{ justifyContent: "space-between" }}>
            <Typography variant="h3" className="h3-bar">
              Timesheet
            </Typography>
            <Button
              size="small"
              className="timest-button"
              startIcon={<AddTaskOutlinedIcon />}
              disableElevation
              variant="contained"
              disabled={isFetching}
              onClick={() => {
                if (addTimesheet) {
                  setAddtimesheet(false);
                  setSelectedActivity();
                  setSelectedCode();
                } else {
                  setAddtimesheet(true);
                }
              }}
            >
              Add Timesheet Entry
            </Button>
          </Grid>
        }
      />
      <CardContent
        sx={{
          paddingTop: "0px",
          paddingBottom: "5px !important",
        }}
      >
        {isFetching ? (
          <Typography variant={"h3"}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Typography>
        ) : (
          <TableContainer
            sx={{ maxHeight: 356, minHeight: 150, width: "100%" }}
          >
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell className="td-th">
                    <Box className="ts-chipbox">
                      <Chip className="ts-chip" size="small" label="Code" />
                    </Box>
                  </TableCell>
                  <TableCell className="td-th">
                    <Box className="ts-chipbox">
                      <Chip className="ts-chip" size="small" label="Activity" />
                    </Box>
                  </TableCell>
                  {weekDates.map((week, key) => {
                    return (
                      <TableCell className="td-th" key={key}>
                        <Typography variant="h6" className="ts-h6">
                          {moment(week).format("dddd").substring(0, 3)}
                        </Typography>
                        <Typography variant="h6" className="ts-h7">
                          {week.substr(0, 6).replace(",", "")}
                        </Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {addTimesheet && (
                  <TableRow hover sx={{ height: "50px" }}>
                    <TableCell className="td-th">
                      <Box className="ts-chipbox">
                        <AutoCompleteMUI
                          Label={"Code"}
                          data={projectCode}
                          selectedValue={setSelectedCode}
                          required={"true"}
                          errorInput={selectedCode === undefined ? true : false}
                          width={"140px"}
                        />
                      </Box>
                    </TableCell>
                    <TableCell className="td-th">
                      <Box className="ts-chipbox">
                        <AutoCompleteMUI
                          Label={"Activity"}
                          data={projectActivity}
                          selectedValue={setSelectedActivity}
                          required={"true"}
                          errorInput={
                            selectedActivity === undefined ? true : false
                          }
                          width={"140px"}
                        />
                      </Box>
                    </TableCell>
                    {[0, 1, 2, 3, 4, 5, 6].map((week, key) => (
                      <TableCell key={key} className="td-th">
                        <SelectDropDownUI
                          date={key}
                          menuItem={getValidTimesheetDropdown()}
                          select={handleInput}
                          onClick={handleInput}
                          disabled={!selectedActivity || !selectedCode}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                )}
                {weeklyDataTimesheet?.map((index, key) => (
                  <TableRow hover sx={{ height: "50px" }} key={key}>
                    <TableCell className="td-th">
                      <Box className="ts-chipbox">
                        <Card
                          className="ts-card waves2"
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            HandleOpenSummary(key);
                          }}
                        >
                          <Typography
                            variant="h6"
                            className="ts-h61 h6-min"
                            sx={{ width: "80px", overflow: "hidden" }}
                          >
                            {index.time
                              .map((code) => code.time_code.name)
                              .filter(
                                (value, index, self) =>
                                  self.indexOf(value) === index
                              )}
                          </Typography>
                        </Card>
                      </Box>
                    </TableCell>
                    <TableCell className="td-th">
                      <Box className="ts-chipbox">
                        <Card className="ts-card waves2">
                          <Typography
                            variant="h6"
                            className="ts-h61 h6-min"
                            sx={{ width: "80px", overflow: "hidden" }}
                          >
                            {index.time
                              .map((activity) => activity.time_activity.name)
                              .filter(
                                (value, index, self) =>
                                  self.indexOf(value) === index
                              )}
                          </Typography>
                        </Card>
                      </Box>
                    </TableCell>
                    {weekDates.map((day, key) => {
                      const days = new Date(day);
                      return (
                        <TableCell key={key} className="td-th">
                          <SelectDropDownUI
                            disabled={addTimesheet}
                            menuItem={getValidTimesheetDropdown()}
                            select={setMyDuration}
                            value={index.time.map(
                              (timesheet) =>
                                +new Date(timesheet.created) === +days && {
                                  hours: timesheet.hours,
                                  status: timesheet.status,
                                  late: timesheet.late,
                                }
                            )}
                            onClick={() =>
                              handleEntryModal(
                                index.time.map((timesheet) => {
                                  return timesheet.time_code;
                                }),
                                index.time.map((timesheet) => {
                                  return timesheet.time_activity;
                                }),
                                index.time.map(
                                  (timesheet) =>
                                    +new Date(timesheet.created) === +days &&
                                    timesheet.hours
                                ),
                                key,
                                index.time.map(
                                  (timesheet) =>
                                    +new Date(timesheet.created) === +days &&
                                    timesheet.id
                                ),
                                index.time.map(
                                  (timesheet) =>
                                    +new Date(timesheet.created) === +days &&
                                    timesheet.comment
                                ),
                                index.time.map(
                                  (timesheet) =>
                                    +new Date(timesheet.created) === +days &&
                                    timesheet.milestone
                                ),
                                setIsEntry(true)
                              )
                            }
                          />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>

              <TableHead
                style={{
                  left: 0,
                  bottom: 0,
                  zIndex: 2,
                  position: "sticky",
                }}
              >
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography variant="h6" className="ts-h6 h6-adjust">
                      Total timesheet entry time
                    </Typography>
                  </TableCell>
                  {weekDates.map((days, key) => {
                    return (
                      <TableCell key={key} className="td-th">
                        <Button
                          className="bottom-button footer-button"
                          variant="contained"
                          disableElevation
                        >
                          {totalHours[key]?.hours === undefined
                            ? "0.00"
                            : parseFloat(totalHours[key]?.hours).toFixed(2)}
                        </Button>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        )}
      </CardContent>
      <Summary
        open={openSummaryModal}
        handleClose={HandleCloseSummary}
        timesheet={timesheetSummary}
      />
    </Card>
  );
};

export default Timesheet;
