import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

const CollapsedTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    padding: "0 6px",
    backgroundColor: grey[200],
    borderBottom: "0",
  },
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FFF",
    color: "#21293C",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#343a40",
    fontSize: 14,
  },
}));

export default CollapsedTableCell;
