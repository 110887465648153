import apiService from "../axios";

export const getProjectType = async ({
  page_size = 25,
  search,
  page = 1,
  status,
}) => {
  try {
    let response = await apiService.get(
      `v5/settings/timesheet/get-types?page_size=${page_size}&search=${search}&page=${page}&status=${status}`,
      {
        Accept: `application/json`,
        api: true,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
