import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import FileUploadIcon from "@mui/icons-material/FileUpload";

const ActionButtons = ({ isLoading, onClose, csvFile, onFormSubmit }) => {
  return (
    <Stack direction="row" justifyContent="flex-end" spacing={2}>
      <Button type="button" variant="outlined" onClick={onClose}>
        CANCEL
      </Button>

      <LoadingButton
        loading={isLoading}
        loadingPosition="start"
        type="submit"
        variant="contained"
        startIcon={<FileUploadIcon />}
        onClick={onFormSubmit}
        disabled={!csvFile}
      >
        UPLOAD
      </LoadingButton>
    </Stack>
  );
};

export default ActionButtons;
