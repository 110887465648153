export const HeaderContent = [
  {
    name: "Email",
    align: "left",
    field: "owner.email",
  },
  {
    name: "Name",
    align: "left",
    field: "owner.name",
  },
  {
    name: "Action",
    align: "center",
  },
];

export const BodyContent = [
  {
    field: "owner.email_work",
    minW: "",
    align: "left",
  },
  {
    field: "owner.full_name",
    minW: "",
    align: "left",
  },
];

export const ExcludeEmployeeIfInTable = (employees, excludeEmp) => {
  return employees?.filter(
    (employeeList) => !excludeEmp?.includes(employeeList?.id)
  );
};
