import React from "react";
import logo from "../../images/banner.png";
import { Link } from "react-router-dom";
import { IsMobile } from "../../helper/isMobile";
const Privacypolicy = () => {
  return (
    <div>
      <div
        className="header"
        style={{
          display: "flex",
          padding: "20px 20px 20px 50px",
          margin: "5px",
        }}
      >
        <div>
          <Link to="/">
            <img src={logo} alt="logo" style={{ width: "20%" }} />
          </Link>
        </div>
      </div>
      <div
        className="body1"
        style={{
          padding: `${IsMobile() ? "0px 0px 0px 0px" : "0px 150px 0 150px"}`,
          backgroundColor: "#f3f3f3",
        }}
      >
        <div>
          <div>
            <div>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "5em",
                  marginBottom: "50px",
                }}
              >
                <span>
                  <b>Privacy Policy</b>
                </span>
              </div>

              <div>
                <span style={{ fontSize: "1.2em" }}>
                  <b>Privacy Policy</b>
                </span>
                <br />
                <br />
                <span>
                  Fligno (<b>"we","our", "us”,”Fligno”</b>) and are collectively
                  committed to respecting the privacy of your personal
                  information.
                </span>
                <br />
                <br />
                <span>
                  This Privacy Policy explains our policy for dealing with
                  personal information that we collect through the following
                  channels (here and after, the Collection Channels):
                </span>
                <br />
                <br />
                <ul>
                  <li>
                    our online properties (here and after, each a "Website"),
                    including:
                    <ul>
                      <li>any Fligno website;</li>
                      <li>
                        any of our related websites, social media pages,
                        internal websites; and
                      </li>
                      <li>any Fligno mobile or tablet applications;</li>
                    </ul>
                  </li>
                  <li>
                    any other means through which an individual provides
                    personal information to Fligno, including either physically
                    or electronically; and
                  </li>
                </ul>
                <span>
                  any other means through which we lawfully collect personal
                  information about you. You may contact us with any queries you
                  may have in respect of this Privacy Policy or your personal
                  information by contacting Flignos’ privacy officer via email:{" "}
                  <a href="mailto:info@fligno.com">info@fligno.com</a>
                </span>
              </div>

              <div style={{ marginTop: "25px" }}>
                <span style={{ fontSize: "1.2em" }}>
                  <b>Personal information is important to us</b>
                </span>
                <br />
                <br />
                <ul>
                  <li>
                    We are committed to safeguarding your personal privacy. We
                    recognise that you have a right to control how your personal
                    information is collected and used. Providing personal
                    information is an act of trust and it is taken seriously.
                    Unless given consent to do otherwise, we will only collect
                    and use personal information as set out below.
                  </li>
                  <li>
                    Throughout this Privacy Policy, we refer to your 'personal
                    information', which means information or an opinion about an
                    identified individual, or an individual who is reasonably
                    identifiable whether the information or opinion is true or
                    not and whether the information or opinion is recorded in a
                    material form or not. Your full name, gender, date of birth,
                    home address, home telephone number, mobile telephone
                    number, email address and workplace contact details are
                    examples of information which may constitute personal
                    information. Personal information may also include
                    information we may collect about your individual
                    preferences. Fligno also business information from sole
                    proprietorships that apply for membership and for program
                    participation, some of which could constitute personal
                    information, as well as personal information from
                    individuals acting solely in their business capacity.
                  </li>
                  <li>
                    Fligno has developed and implemented its Privacy Policy with
                    respect to personal information to reflect world best
                    practice on privacy policies. These principles meet and
                    exceed the requirements of the Privacy Act 1988 (Cth) (as
                    amended) and the 13 Australian Privacy Principles (“APP”).
                  </li>
                  <li>
                    In order to keep up with changing legislation and best
                    practice, we may revise this Privacy Policy at any time
                    without notice. We will post any changes to this Privacy
                    Policy on our Websites, so we encourage you to check this
                    Privacy Policy from time to time.
                  </li>
                  <li>
                    In addition to the provisions of this Privacy Policy, there
                    may also be specific and additional privacy and consent
                    provisions that apply to certain Collection Channels.
                    Because those specific and additional provisions also relate
                    to your privacy protection, we recommend that you review
                    them wherever they appear. In the event of any inconsistency
                    between the provisions of this Privacy Policy and those
                    other specific and additional provisions, the specific and
                    additional provisions will prevail.
                  </li>
                </ul>
              </div>

              <div style={{ marginTop: "25px" }}>
                <span style={{ fontSize: "1.2em" }}>
                  <b>Anonymity and pseudonymity</b>
                </span>
                <br />
                <br />
                <ul>
                  <li>
                    Where practicable, we will allow you to deal with us on an
                    anonymous or pseudonymous basis. If this is practicable, our
                    Collection Channels will only seek information in this way.
                    However, where it is not practicable for the purposes for
                    which information is collected, we will seek the information
                    identified below. It will not be practicable to deal with
                    you on an anonymous or pseudonymous basis when we wish to
                    send you direct marketing materials or need to provide you
                    with goods or services requested by you.
                  </li>
                </ul>
              </div>

              <div style={{ marginTop: "25px" }}>
                <span style={{ fontSize: "1.2em" }}>
                  <b>Kinds of Personal Information Fligno Collects and Holds</b>
                </span>
                <br />
                <br />
                <ul>
                  <li>
                    Personal information that may be requested includes, but is
                    not limited to:
                    <ul>
                      <li>
                        contact information such as your full name, telephone
                        number, mobile telephone number, current and valid email
                        address, business address, drop off addresses, billing
                        address and postcode;
                      </li>
                      <li>more detailed contact preferences;</li>
                      <li>your gender;</li>
                      <li>
                        your Apple AppStore and/or Google Play Store ID and your
                        unique device identifier (UDID);
                      </li>
                      <li>location information;</li>
                      <li>
                        financial details including your bank account, PayPal or
                        other electronic payment details;
                      </li>
                      <li>
                        transaction data including the details of any products
                        or services you have purchased from our partners through
                        the Website;
                      </li>
                      <li>
                        your online behaviour and interests based on your online
                        browsing activity;
                      </li>
                      <li>
                        demographic information such as age group, preferences
                        and interests;
                      </li>
                      <li>
                        information about your preferences, interests, and
                        experiences with our products or services. This
                        information is collected in order to tailor our
                        communications to you and continuously improve our
                        products and services;
                      </li>
                      <li>
                        information about your experience with our products or
                        services or third party products or services listed on a
                        Website;
                      </li>
                      <li>
                        the contact details of third parties. If you are asked
                        to provide details about other people, please ensure
                        that these individuals are happy for their details to be
                        given to Fligno and used for the purposes set out in
                        this policy (which may include using their details for
                        marketing purposes); and
                      </li>
                      <li>
                        any other personal information which you provide
                        directly to us. Where you provide us with unsolicited
                        personal information, we will retain this information
                        where it falls within our primary purposes for
                        collection of personal information (as stated in this
                        privacy policy).
                      </li>
                    </ul>
                  </li>
                  <li>
                    When you use a Website, we may also collect personal
                    information about you in the following general categories:
                    <ul>
                      <li>
                        usage and preference information: we collect information
                        about how you interact with a Website, including the
                        pages you visit, your preferences and settings that you
                        choose. We may do this through the use of cookies and
                        other similar technologies that uniquely identify you;
                      </li>
                      <li>
                        device information: we may collect information about
                        your mobile device such as the hardware model, operating
                        system, preferred language, unique device identifier and
                        mobile network; and
                      </li>
                      <li>
                        other information: we may also collect and log
                        information such as your IP address, access dates and
                        times, browser type and pages visited when you interact
                        with a Website.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

              <div style={{ marginTop: "25px" }}>
                <span style={{ fontSize: "1.2em" }}>
                  <b>Sensitive Information</b>
                </span>
                <br />
                <br />
                <ul>
                  <li>
                    We will only collect sensitive information with your consent
                    and where you provide it to us directly. Where you provide
                    us with any sensitive information (including, but not
                    limited to, your signature, information about your sexual
                    orientation, medical and/or criminal history), we will only
                    use this information for the purposes stated at the time of
                    collection will share this information with our trusted
                    third parties in the manner stated on the Website or in this
                    Privacy Policy.
                  </li>
                </ul>
              </div>

              <div style={{ marginTop: "25px" }}>
                <span style={{ fontSize: "1.2em" }}>
                  <b>How Fligno Collects and Holds Personal Information</b>
                </span>
                <br />
                <br />
                <ul>
                  <li>
                    We will only collect or monitor any personal information
                    about you with your consent including as provided in this
                    Privacy Policy or if it is otherwise lawful to do so. The
                    only personal information collected by us is what has been
                    provided to or collected by us in accordance with this
                    Privacy Policy or has been provided to us lawfully by third
                    parties.
                  </li>
                </ul>
              </div>

              <div style={{ marginTop: "25px" }}>
                <span style={{ fontSize: "1.2em" }}>
                  <b>Active Information Collection</b>
                </span>
                <br />
                <br />
                <ul>
                  <li>
                    Personal information may be collected (and combined) via our
                    Collection Channels if you:
                    <ul>
                      <li>
                        have previously provided us with personal information
                        prior to this Privacy Policy coming into effect, either
                        directly from you personally or via a third party;
                      </li>
                      <li>
                        sign-up and/or register to become a member of any
                        Collection Channel;
                      </li>
                      <li>
                        download and use a mobile and/or tablet application
                        including Fligno App;
                      </li>
                      <li>
                        submit any content to or participate in any way via a
                        Collection Channel, including engaging with other users
                        or taking part in campaigns or offers;
                      </li>
                      <li>
                        subscribe to any newsletters, updates, alerts or news
                        and media releases, or request launch or event
                        information or information about our products or
                        services or other information services as well as third
                        party products or services;
                      </li>
                      <li>
                        complete and submit any forms or applications to us;
                      </li>
                      <li>
                        contact us directly in person or via any medium
                        including mail, telephone, social media and commercial
                        electronic messages (SMS (Short Message Service), MMS
                        (Multimedia Message Service), IM (Instant Messaging) and
                        email) including via the contact details listed on a
                        Website;
                      </li>

                      <li>
                        participate in any offers, promotions, competitions,
                        games, rewards or marketing activities or other
                        activities offered by Fligno from time to time;
                      </li>
                      <li>interact with a Website for a specific purpose;</li>
                      <li>interact with or browse a Website generally; or</li>
                    </ul>
                  </li>
                  <li>
                    Personal information is requested in order to allow you to
                    participate in a Collection Channel, to facilitate the
                    services undertaken by Fligno in connection with but not
                    limited to the carriage, transport and/or the storage of
                    goods, marketing materials (including direct marketing
                    materials), newsletters, electronic newsletters
                    (e-newsletters), news and media releases / launch
                    information, and also to advise you of other Fligno or third
                    party opportunities and products, services, offers,
                    competitions or promotions which may be of interest. No one
                    is obligated to provide personal information. However,
                    failure to do so may result in you being unable to
                    participate in a Collection Channel and Fligno being unable
                    facilitate or provide you with certain content, products or
                    services, products or services information, upcoming
                    opportunities and promotion, competition, game, offer or
                    event information or accept competition entries or other
                    content from you (where applicable).
                  </li>
                  <li>
                    We may also obtain your personal information from legitimate
                    third party sources and platforms including social media
                    channels, marketing companies, targeting companies, list
                    brokers and other data providers or organisations that share
                    data in circumstances where it is lawful and/or you have
                    given permission for them to do so. For example:
                    <ul>
                      <li>
                        we may use services from other companies that enable us
                        to derive a general geographic area based on your IP
                        address in order to customise certain services to your
                        geographic area.
                      </li>
                      <li>
                        if you connect your third-party social networking or
                        other platform service profile (such as but not limited
                        to Facebook, LinkedIn, Twitter, Google, Apple or Windows
                        Live) to your Fligno registration, we may access and use
                        your username, profile image, location information,
                        interests, activities, ‘likes’, connection list and age
                        for those services; and
                      </li>
                      <li>
                        we may obtain third party information relating to your
                        advertisement interaction and viewing data, such as ad
                        click-through rates and information about how many times
                        you viewed a particular advertisement.
                      </li>
                    </ul>
                  </li>
                  <li>
                    To customise your user experience on our Websites we provide
                    you with the opportunity to connect, access and/or interact
                    with third-party social networking services, such as but not
                    limited to Facebook and Twitter. When you connect through
                    these third-party social networking services, you are
                    authorising Fligno to access and use certain information
                    from your social network profile. Of course, you can cancel
                    this service at any time by deleting the application from
                    your social network account.
                  </li>
                </ul>
              </div>

              <div style={{ marginTop: "25px" }}>
                <span style={{ fontSize: "1.2em" }}>
                  <b>Children</b>
                </span>
                <br />
                <br />
                <ul>
                  <li>
                    The collection of personal information is neither intended
                    for, nor directed to, persons who are under the age of
                    sixteen (16) years old. Personal information will not be
                    collected by any person who is known by Fligno to be under
                    the age of sixteen (16) without the consent of a parent or
                    legal guardian. Persons under age sixteen (16) may only use
                    our Websites with the involvement and consent of a parent or
                    legal guardian.
                  </li>
                </ul>
              </div>

              <div style={{ marginTop: "25px" }}>
                <span style={{ fontSize: "1.2em" }}>
                  <b>Passive Information Collection</b>
                </span>
                <br />
                <br />
                <ul>
                  <li>
                    As with many commercial websites and mobile and tablet
                    applications, we may also collect information which tells us
                    about visitors to our Websites. For example, we may collect
                    information about the date, time and duration of visits and
                    which pages of a Website are most commonly accessed. This
                    information is generally not linked to the identity of
                    visitors, except where a Website is accessed via links in an
                    email we have sent or where we are able to uniquely identify
                    the device or user accessing a Website. By accessing a
                    Website via links in an email we have sent and/or by
                    accessing a Website generally including when you are logged
                    into an account, you consent to the collection of such
                    information where it is personal information. When you
                    receive newsletters or promotional emails from Fligno, we
                    may use web beacons (described below), customised links or
                    similar technologies to determine whether the email has been
                    opened and which links you click in order to provide you
                    with more focused email communications or other information.
                  </li>
                  <li>
                    As you navigate through our Websites, certain information
                    can be passively collected (that is, gathered without you
                    actively providing the information) using various
                    technologies, such as Unique Device Identifiers (UDI),
                    cookies, Internet tags or web beacons, and navigational data
                    collection (log files, server logs, clickstream). In certain
                    circumstances, this information may be considered anonymous
                    information or personal information under the Privacy Act
                    1988 (Cth). This is dependent on the device used and the
                    method by which you connect to the Internet and the Website.
                    Your Internet browser automatically transmits some of this
                    anonymous information or personal information to the
                    Website, such as the URL of the website you just came from,
                    the Internet Protocol (IP) address, the UDI (if applicable)
                    and the browser version your device is currently using. Our
                    Websites may also collect anonymous information or personal
                    information from your device through cookies and Internet
                    tags or web beacons. You may set your browser to notify you
                    when a cookie is sent or to refuse cookies altogether, but
                    certain features of a Website might not work without cookies
                    and this may limit the services provided by a Website.
                    Cookies and other technical methods may involve the
                    transmission of information either directly to us or to
                    another party authorised by us to collect information on our
                    behalf.
                  </li>
                  <li>
                    Our Websites may use and combine such passively collected
                    anonymous information or personal information and/or
                    information from various third party sources, including as
                    described above, and may combine this anonymous information
                    or personal information with other personal information
                    collected from you to provide better service to Website
                    visitors and users, customise a Website based on your
                    preferences, compile and analyse statistics and trends,
                    provide you with relevant advertising when you visit a
                    Website or a third party website, and otherwise administer
                    and improve a Website for your use. We may combine your
                    visitor session information or other information collected
                    through tracking technologies with personally identifiable
                    information from time to time in order to understand and
                    measure your online experiences and to determine what
                    products, promotions and services are likely to be of
                    interest to you. By accessing a Website, you consent to
                    information about you being collected, compiled and used in
                    this way.
                  </li>
                  <li>
                    For more information about cookies and how you can opt out,
                    you can visit{" "}
                    <a href="http://www.youronlinechoices.com.au/">
                      http://www.youronlinechoices.com.au/
                    </a>
                    .
                  </li>
                </ul>
              </div>

              <div style={{ marginTop: "25px" }}>
                <span style={{ fontSize: "1.2em" }}>
                  <b>Platform permissions</b>
                </span>
                <br />
                <br />
                <ul>
                  <li>
                    Mobile platforms such as iOS and Android generally define
                    certain types of information or data that applications
                    cannot access without your consent. Each platform has its
                    own permissions system for obtaining your consent. For
                    example, the iOS platform generally alerts you the first
                    time a Fligno app wants permission to access certain types
                    of data, such as location services, and will let you choose
                    whether or not to consent to that request. Android devices
                    will generally notify you of the permissions that an app
                    seeks before you first use the app, and your use of the app
                    constitutes your consent. You can generally manage your
                    platform level permissions via the Settings section on your
                    device. For more information, please contact our Privacy
                    Officer at the details set out in this policy.
                  </li>
                </ul>
              </div>

              <div style={{ marginTop: "25px" }}>
                <span style={{ fontSize: "1.2em" }}>
                  <b>
                    Purposes for which Fligno collects, holds, uses and
                    discloses personal information
                  </b>
                </span>
                <br />
                <br />
                <ul>
                  <li>
                    Personal information collected will be used for the
                    following primary purposes:
                    <ul>
                      <li>
                        To facilitate the services undertaken by Fligno in
                        connection with but not limited to the carriage,
                        transport and/or the storage of goods.
                      </li>
                      <li>
                        For the purposes stated in a privacy collection
                        statement on a particular Collection Channel.
                      </li>
                      <li>
                        To maintain the functionality of a Website, including
                        the provision of information to you relating to the
                        content available on the Website and e-commerce
                        transactions conducted via the Website.
                      </li>
                      <li>
                        To send you any technical, administrative or legal
                        notices important to our Websites.
                      </li>
                      <li>
                        To allow you to create an account, sign in and use the
                        functionality of a Fligno Website.
                      </li>
                      <li>
                        To allow you to respond to and participate in offers and
                        competitions.
                      </li>
                      <li>
                        To conduct competitions or promotions and communicate
                        offers either for us or third parties.
                      </li>
                      <li>
                        For internal record keeping including site usage and
                        administration of our products and services.
                      </li>
                      <li>
                        To provide you with information about your transactions,
                        content, services and products.
                      </li>
                      <li>
                        To provide direct marketing materials, events, offers,
                        competitions, games and promotions in person and via any
                        medium including mail, telephone and commercial
                        electronic messages (SMS (Short Message Service), MMS
                        (Multimedia Messaging Service), IM (Instant Messaging),
                        email, push notifications) or any other form of
                        electronic, emerging, digital or conventional
                        communications channel.
                      </li>
                      <li>
                        To render services related to our company (such as after
                        sales services and enquiries).
                      </li>
                      <li>
                        To provide you with newsletters via mail and electronic
                        newsletters (e-newsletters) via commercial electronic
                        messages.
                      </li>
                      <li>
                        To respond to customer enquiries, complaints and
                        complaints handling.
                      </li>
                      <li>To improve Website and system administration.</li>
                      <li>
                        To obtain opinions or comments about products and/or
                        services and to conduct other research and development.
                      </li>
                      <li>To verify your identity.</li>
                      <li>
                        To investigate complaints made by you or if we have
                        reason to believe that you are in breach of our Terms or
                        otherwise engaged in unlawful activity.
                      </li>
                      <li>
                        To record statistical data for marketing analysis and to
                        conduct market research.
                      </li>
                      <li>
                        To share personal information with any sub-Contractor or
                        Agent of Fligno, or any agent or sub-Contractor of a
                        sub-Contractor of Fligno for the legitimate purposes of
                        fulfilling the undertaking by Fligno in connection with
                        but not limited to the carriage, transport and/or the
                        storage of goods.
                      </li>
                      <li>
                        Any other purpose as may be deemed reasonably necessary
                        by Fligno in the circumstances.
                      </li>
                    </ul>
                  </li>
                  <li>
                    For the purposes described above, personal information may
                    be shared within Fligno, any servant or agent or
                    sub-contractor of Fligno or any servant or agent of any
                    sub-contractor or by any other person and any such
                    arrangement required to fulfill the undertaking by Fligno in
                    connection with but not limited to the carriage, transport
                    and/or the storage.
                  </li>
                  <li>
                    Failure to provide personal information may result in Fligno
                    being unable to fulfil any undertaking requested of them.
                  </li>
                  <li>
                    In order to operate a Website or deliver a service
                    (including for example, processing of credit card payments),
                    personal information may also be shared with selected
                    service providers and their related bodies corporate, and/or
                    other trusted third parties either in Australia or overseas
                    countries. Fligno selected service providers and/or other
                    trusted third parties may be engaged by Fligno to perform a
                    variety of functions, such as email systems providers, legal
                    and accounting services, data storage, fulfilling orders,
                    conducting market research, processing credit card payments,
                    assisting with promotions or offers and providing technical
                    services for our Websites. These companies may have access
                    to personal information if needed to perform such functions.
                  </li>
                  <li>
                    We recognise the trust with which you provide personal
                    information, and except as stated in this Privacy Policy or
                    as is otherwise lawful, such information will not be used or
                    disclosed for any other purposes without consent. However,
                    Fligno reserves the right to use or disclose any
                    information, including personal information, as needed to
                    satisfy any law, regulation or legal request, to protect the
                    rights or property of Fligno, any member of Fligno, or any
                    member of the public, to protect the integrity of a Website,
                    to fulfil your requests, or to cooperate in any law
                    enforcement investigation or an investigation on a matter of
                    public safety.
                  </li>
                </ul>
              </div>

              <div style={{ marginTop: "25px" }}>
                <span style={{ fontSize: "1.2em" }}>
                  <b>Email Offers from Fligno</b>
                </span>
                <br />
                <br />
                <ul>
                  <li>
                    Fligno provides a service that delivers great offers direct
                    to your inbox. Any promotional offers and direct marketing
                    messages from us will be sent via the email address you have
                    provided.
                  </li>
                  <li>
                    <b>Opt-In</b> - At the point you register with Fligno you
                    subscribe to our mailing list. By subscribing to our mailing
                    list you are opting-in to us using your personal information
                    as set out in this Privacy Policy. You may also be asked to
                    opt-in to receive further information or communications from
                    our advertisers and partners.
                  </li>
                  <li>
                    <b>Opt-Out</b> - When you opt-out of our email services,
                    your mailing subscription with the specific Fligno company
                    will be cancelled and you will no longer receive marketing
                    communications from that company. The above steps will
                    ensure your personal information is de-identified or
                    permanently deleted from our records, where we no longer
                    need it for the purposes of adhering to the APP.
                  </li>
                  <li>
                    If you receive communications purporting to be connected
                    with us or our services that you believe have been sent to
                    you other than in accordance with this Privacy Policy, or in
                    breach of any law, please contact our Privacy Officer
                    (contact details set out below).
                  </li>
                </ul>
              </div>

              <div style={{ marginTop: "25px" }}>
                <span style={{ fontSize: "1.2em" }}>
                  <b>Contact by Fligno</b>
                </span>
                <br />
                <br />
                <ul>
                  <li>
                    Fligno of companies, any servant or agent or sub-contractor
                    of Fligno or any servant or agent of any sub-contractor or
                    by any other person and other selected service providers
                    either in Australia or overseas, may contact you via
                    telephone, SMS (Short Message Service), MMS (Multimedia
                    Messaging Service), IM (Instant Messaging), email, post,
                    push notification or any other form of electronic, emerging,
                    digital or conventional communications channel using the
                    information provided in order to contact you in respect of
                    the primary purposes for collection of personal information
                    as stated above.
                  </li>
                  <li>
                    Fligno does not send advertising or marketing information
                    without obtaining prior consent, for example the consent
                    contained within this Privacy Policy, or as is otherwise
                    lawful. If you receive communications from Fligno which you
                    do not wish to receive, you may unsubscribe, opt out, or
                    request to remove your name from the database in the manner
                    indicated in the communication, including by utilising the
                    functional unsubscribe/opt-out facility (if the
                    communication is via commercial electronic message) or by
                    contacting Fligno at the contact details in this Privacy
                    Policy. Please allow 5 working days for this request to be
                    processed.
                  </li>
                  <li>
                    Despite removing your name from the database from receiving
                    future advertising and marketing information, Fligno may
                    send you non-commercial “Administrative Emails”.
                    Administrative Emails relate to a Fligno user account and
                    may include administrative and transaction confirmations,
                    requests and inquiries or information about a particular
                    Fligno user account. If you do not wish to receive such
                    communications from Fligno, you may remove your name from
                    the database by utilising the functional unsubscribe
                    facility.
                  </li>
                </ul>
              </div>

              <div style={{ marginTop: "25px" }}>
                <span style={{ fontSize: "1.2em" }}>
                  <b>Ability of others to view information</b>
                </span>
                <br />
                <br />
                <ul>
                  <li>
                    Fligno may provide areas on a Website where you can upload
                    user-generated content, post or provide information about
                    yourself, communicate with other users, utilise
                    functionalities that allow you to post content to your own
                    social media channels, or interact with particular content.
                    This information may be shared with others and may be
                    publicly posted on our Websites, including without
                    limitation, other social media platforms and other public
                    forums in which you choose to participate. This information
                    may become publicly available and may be read, collected and
                    used by others either on our Websites or outside of our
                    Websites. Fligno is not responsible for the conduct of
                    others who may read, collect and use this information.
                  </li>
                </ul>
              </div>

              <div style={{ marginTop: "25px" }}>
                <span style={{ fontSize: "1.2em" }}>
                  <b>Changing and deleting the information we have about you</b>
                </span>
                <br />
                <br />
                <ul>
                  <li>
                    Information contained on the database may be amended, or
                    your preferences for contact from us changed, by contacting
                    Fligno’s privacy officer at the contact details contained in
                    this Privacy Policy. Please allow 30 days for this request
                    to be processed.
                  </li>
                  <li>
                    We are not responsible for removing your personal
                    information from the lists of any third party who has
                    previously been provided your information in accordance with
                    this policy.
                  </li>
                </ul>
              </div>

              <div style={{ marginTop: "25px" }}>
                <span style={{ fontSize: "1.2em" }}>
                  <b>Storage and security of personal information</b>
                </span>
                <br />
                <br />
                <ul>
                  <li>
                    Fligno will endeavour to take all reasonable steps to keep
                    secure any personal information recorded and to keep this
                    information accurate, up to date, complete and relevant. We
                    ensure only those necessary have access to your personal
                    information. Personal information is stored on secure
                    servers that are protected in controlled facilities. This
                    service may be performed on our behalf and data may be
                    hosted by our selected data storage providers. In some cases
                    these facilities may be overseas.
                  </li>
                </ul>
              </div>

              <div style={{ marginTop: "25px" }}>
                <span style={{ fontSize: "1.2em" }}>
                  <b>Related Fligno Websites</b>
                </span>
                <br />
                <br />
                <ul>
                  <li>
                    All Websites operated by Fligno will adhere to this Privacy
                    Policy. The policies on the Websites of some members of
                    Fligno may vary, however, because of local customs,
                    practices or laws.
                  </li>
                </ul>
              </div>

              <div style={{ marginTop: "25px" }}>
                <span style={{ fontSize: "1.2em" }}>
                  <b>Sale of the Company</b>
                </span>
                <br />
                <br />
                <ul>
                  <li>
                    If Fligno merges with, or is acquired by, another company,
                    or sells all or a portion of its assets, your personal
                    information may be disclosed to our advisers and any
                    prospective purchaser’s adviser, and may be among the assets
                    transferred. However, personal information will always
                    remain subject to this Privacy Policy.
                  </li>
                </ul>
              </div>

              <div style={{ marginTop: "25px" }}>
                <span style={{ fontSize: "1.2em" }}>
                  <b>Problems or queries</b>
                </span>
                <br />
                <br />
                <ul>
                  <li>
                    If you have any questions about our Privacy Policy, or any
                    problems or complaints about how we have collected, used,
                    stored, handled and/or disclosed your personal information,
                    please contact our privacy officer via one of the following
                    channels:
                    <br />
                    <br />
                    Email: <a href="mailto:info@fligno.com">info@fligno.com</a>
                  </li>
                </ul>
                <span>
                  Please allow 30 days for this request to be processed. If you
                  do not receive a satisfactory response from Fligno to your
                  query, problem or complaint within 30 days, you may refer your
                  query, problem or complaint to the Office of the Australian
                  Information Commissioner via the contact details listed at{" "}
                  <a href="http://www.oaic.gov.au/about-us/contact-us">
                    http://www.oaic.gov.au/about-us/contact-us
                  </a>
                  .
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacypolicy;
