import React from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import { IconButton } from "@mui/material";
import DropDownSelect from "../CustomElement/DropDownSelect";
const perPageOptions = [{ val: 25 }, { val: 50 }, { val: 100 }];
const ServerPagination = ({
  setPerPage,
  nextPage,
  backPage,
  lastPage,
  startPage,
  field,
  pageData,
}) => {
  const handleChange = (event) => {
    setPerPage((oldState) => ({ ...oldState, [field]: event.target.value }));
  };
  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "0.88rem",
              margin: "0px 25px 0px 0px",
            }}
          >
            Rows per page:
            <DropDownSelect
              DropDownData={perPageOptions}
              onChange={handleChange}
              value={pageData.perPage}
              objectName={{ val: "val", name: "val" }}
              size={"small"}
            />
          </div>
          {pageData.total !== 0 && (
            <div style={{ fontSize: "0.88rem" }}>
              {pageData.from} - {pageData.to} of {pageData.total}
            </div>
          )}
          <IconButton
            onClick={startPage}
            disabled={pageData.from === 1 || pageData.total === 0}
          >
            <SkipPreviousIcon />
          </IconButton>
          <IconButton
            onClick={backPage}
            disabled={pageData.from === 1 || pageData.total === 0}
          >
            <ArrowBackIosNewIcon style={{ fontSize: "0.85rem" }} />
          </IconButton>
          <IconButton
            onClick={nextPage}
            disabled={pageData.to === pageData.total || pageData.total === 0}
          >
            <ArrowForwardIosIcon style={{ fontSize: "0.85rem" }} />
          </IconButton>
          <IconButton
            onClick={lastPage}
            disabled={pageData.to === pageData.total || pageData.total === 0}
          >
            <SkipNextIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default ServerPagination;
