import React from "react";
import "./ck.scss";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CkEditor = ({ details, myDetails, disabled }) => {
  // useEffect(() => {
  //   myDetails && details(myDetails);
  // }, [details, myDetails]);
  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        data={myDetails ? myDetails : ""}
        disabled={disabled}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          console.log("Editor is ready to use!", editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          details(data);
        }}
        // onBlur={(event, editor) => {
        //   console.log("Blur.", editor);
        // }}
        // onFocus={(event, editor) => {
        //   console.log("Focus.", editor);
        // }}
        config={{
          link: {
            decorators: {
              addTargetToExternalLinks: {
                mode: "automatic",
                callback: (url) => /^(https?:)?\/\//.test(url),
                attributes: {
                  target: "_blank",
                  rel: "noopener noreferrer",
                },
              },
            },
          },
          toolbar: [
            "undo",
            "redo",
            "bold",
            "italic",
            "heading",
            "link",
            "numberedList",
            "bulletedList",
            "insertTable",
            "tableColumn",
            "tableRow",
            "mergeTableCells",
          ],
        }}
      />
    </div>
  );
};
export default CkEditor;
