export const auditLogHeader = [
  "user",
  "model",
  "event",
  "affected_data",
  "date",
  "old_values",
  "new_values",
];

export const replaceLabel = (item) => {
  switch (item) {
    default:
      return item.replace(/_/g, " ");
  }
};

export const addObject = (data, replace) =>
  data.map((item) =>
    Object.fromEntries([
      ["name", replace(item)],
      ["shortname", item || ""],
    ])
  );
