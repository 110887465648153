import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";

const IMPORT_BOX_STYLE = {
  position: "relative",
  backgroundColor: "#edf2f7",
  border: "3px dashed #cbd5e0",
  padding: "28px 32px 18px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "18px",
};

const IMPORT_TEXT_STYLE = {
  color: "#495057",
  fontStyle: "italic",
};

const ImportBox = ({ csvFile, onChange }) => {
  return (
    <Box component="form" sx={IMPORT_BOX_STYLE}>
      <Button
        variant="contained"
        component="label"
        startIcon={<AddIcon />}
        sx={{ maxWidth: "128px" }}
      >
        Add File
        <input
          hidden
          type="file"
          name="file"
          accept=".csv"
          onChange={onChange}
        />
      </Button>

      {csvFile ? (
        <Typography sx={IMPORT_TEXT_STYLE}>
          File selected: {csvFile.name}
        </Typography>
      ) : (
        <Typography sx={IMPORT_TEXT_STYLE}>No file selected.</Typography>
      )}
    </Box>
  );
};

export default ImportBox;
