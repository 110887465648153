import React, { useState, useEffect, useRef, useCallback } from "react";
import { Grid, Box, Paper, Button } from "@mui/material";
import * as yup from "yup";
import GridView from "../../component/UserProfile/GridView";
import Password from "../../component/UserProfile/form/Password";
import Profile from "../../component/UserProfile/form/Profile";
import * as Alerts from "../../component/Alerts/UserProfile";
import { useFormik } from "formik";
import moment from "moment";
import { updateEmployee, updatePassword } from "../../api/user";
import {
  useDepartment,
  useEmployee,
  useSchedule,
} from "../../hooks/react-query/profileQuery";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useGetSkills } from "../../hooks/react-query/SettingsSytem/Skills";

const UserProfilePage = () => {
  /* local state */
  const [changePassword, openChangePassword] = useState(false);
  const [profile, openProfile] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [formDepartment, setFormDepartment] = useState([]);
  const [formSchedule, setFormSchedule] = useState([]);

  /* react queries */
  const queryClient = useQueryClient();
  const {
    data: employee,
    isFetching: employeeFetching,
    isFetched: employeeFetched,
  } = useEmployee();
  const { data: EmpSkill, isFetching: EmpSkillFetching } = useGetSkills({
    status: 1,
    search: "",
  });
  const { data: department } = useDepartment();
  const { data: schedules } = useSchedule();

  const form = useRef(null);

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      nickname: "",
      extension: "",
      gender: "",
      marital: "",
      dob: "",
      dept: "",
      mobile: "",
      address: "",
      email_work: "",
      email_personal: "",
      employment_status: "",
      wfh_days: "",
      schedule: "Fixed",
      date_hired: "Fixed",
      sched_id: "",
      skype: "",
      slack: "",
      password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: yup.object({
      firstname: yup
        .string("Symbols and Numbers is not allowed")
        .required("First Name is required"),
      lastname: yup
        .string("Symbols and Numbers is not allowed")
        .required("Last Name is required"),
      mobile: yup
        .string()
        .matches(
          new RegExp("^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$"),
          "Invalid phone number"
        ),
      email_work: yup
        .string()
        .email("Invalid email format")
        .required("Work email is required"),
      email_personal: yup.string().email("Invalid email format"),
    }),
    onSubmit(values, actions) {
      onUpdateEmployee(values);
    },
  });

  const updateData = useMutation((newData) => updateEmployee(newData), {
    onSuccess: async (updatedData) => {
      queryClient.setQueryData(["get-employee"], () => updatedData);
      toast.success("User Information Changed");
      openProfile((prev) => !prev);
    },
    onError: (error) => {
      /* temporary */
      toast.error("ERROR");
    },
  });

  const updateDataPassword = useMutation((newData) => updatePassword(newData), {
    onSuccess: async (updatedData) => {
      toast.success("User Information Changed");
      openChangePassword((prev) => !prev);
    },
    onError: (error) => {
      toast.error(error.response.data.message);
    },
  });

  const onUpdatePassword = () => {
    let formData = new FormData(form.current);

    formData.append("id", JSON.parse(localStorage.getItem("user")).id);
    updateDataPassword.mutate(formData);
  };

  const onUpdateEmployee = () => {
    const formData = new FormData();

    for (let key in formik.values) {
      if (key === "wfh_days") {
        formData.append(key, formik.values[key] ? formik.values[key] : 0);
      } else if (key === "dob" || key === "date_hired") {
        if (formik.values[key] && formik.values[key] !== "Invalid Date") {
          formData.append(key, moment(formik.values[key]).format("YYYY/MM/DD"));
        }
      } else {
        formData.append(key, formik.values[key] ? formik.values[key] : " ");
      }
    }
    formData.append("id", JSON.parse(localStorage.getItem("user")).employee.id);

    updateData.mutate(formData);
  };

  /* useCallbacks */
  const initEmployee = useCallback(() => {
    if (employee) {
      setGridData(employee);
      formik.setValues({
        firstname: employee["firstname"] || "",
        lastname: employee["lastname"] || "",
        nickname: employee["nickname"] || "",
        extension: employee["extension"] || "",
        gender: employee?.["gender"]?.toLowerCase() || "",
        marital: employee?.["marital"]?.toLowerCase() || "",
        dob: employee["dob"],
        dept: employee["dept"],
        mobile: employee["mobile"] || "",
        address: employee["address"] || "",
        email_work: employee["email_work"] || "",
        email_personal: employee["email_personal"] || "",
        emp_company_id: employee["emp_company_id"],
        date_hired: employee.details["date_hired"],
        employment_status:
          employee?.details?.["employment_status"]?.toLowerCase() || "",
        wfh_days: employee.details["wfh_days"] || 0,
        schedule: employee.details["schedule"],
        sched_id: employee.details["sched_id"],
        skype: employee.details["skype"] || "",
        slack: employee.details["slack"] || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee]);

  const initDepartment = useCallback(() => {
    if (department) {
      const resultLabel = department.map(({ id: value, name: label }) => ({
        value,
        label,
      }));

      setFormDepartment(resultLabel);
    }
  }, [department]);

  const initSchedule = useCallback(() => {
    if (schedules) {
      const resultLabel = schedules.map(({ id: value, sched_name: label }) => ({
        value,
        label,
      }));
      setFormSchedule(resultLabel);
    }
  }, [schedules]);

  useEffect(() => {
    initEmployee();
  }, [initEmployee]);

  useEffect(() => {
    initDepartment();
  }, [initDepartment]);

  useEffect(() => {
    initSchedule();
  }, [initSchedule]);

  const passwordFormik = useFormik({
    initialValues: {
      current_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: yup.object({
      current_password: yup.string().required("Current Password Required"),
      new_password: yup
        .string()
        .required("Password Required")
        .min(8, "Password is too short - should be 8 chars minimum."),
      confirm_password: yup
        .string()
        .required("Password Required")
        .oneOf([yup.ref("new_password"), null], "Password Do not match"),
    }),
    onSubmit(values, actions) {
      onUpdatePassword(values);
    },
  });

  return (
    <>
      <Box>
        <Alerts.Retrieve open={employeeFetching} />
        <Grid
          container
          sx={{
            position: "sticky",
            top: 0,
            borderRadius: 5,
            paddingRight: 2,
          }}
        >
          <Grid item xs={12} md={12}>
            <Paper>
              <Box display="flex" p={1} justifyContent="flex-end">
                <Box mr={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      openChangePassword(true);
                    }}
                    disabled={!employeeFetched}
                    sx={{
                      fontSize: ".8rem",
                    }}
                  >
                    Change Password
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => openProfile((prev) => !prev)}
                    disabled={!employeeFetched}
                    sx={{
                      fontSize: ".8rem",
                    }}
                  >
                    Edit
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        {formDepartment.length > 0 &&
          formSchedule.length > 0 &&
          !EmpSkillFetching && (
            <GridView
              gridData={{ ...gridData, Skills: EmpSkill.data }}
              department={formDepartment}
              schedule={formSchedule}
            />
          )}

        {formDepartment.length > 0 && formSchedule.length > 0 && (
          <Profile
            formDepartment={formDepartment}
            formSchedule={formSchedule}
            validation={formik}
            open={profile}
            close={() => {
              openProfile((prev) => !prev);
            }}
          />
        )}
        <Password
          form={form}
          open={changePassword}
          close={() => {
            openChangePassword((prev) => !prev);
          }}
          validation={passwordFormik}
        />
      </Box>
    </>
  );
};

export default UserProfilePage;
