import React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import hooks from "../../../hooks/react-query/Announcement";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import dayjs from "dayjs";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EmailIcon from "@mui/icons-material/Email";
import { confirmAnnouncement } from "../../../api/announcement";
import { useMutation } from "react-query";
import {
  Button,
  Dialog,
  DialogContent,
  CardActions,
  Chip,
  Link,
} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Carousel from "react-material-ui-carousel";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AnnouncementImg from "../../../images/announcement-freepik.png";
import AnnouncementViewImg from "../../../images/announcement-view.png";
import LoadingButton from "@mui/lab/LoadingButton";
import BlockIcon from "@mui/icons-material/Block";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";

import { useState, useCallback, useEffect } from "react";

const { format, parseISO } = require("date-fns");
const color_pallete = [
  "#9FEA93",
  "#98ECA6",
  "#7CD5B3",
  "#66C3BD",
  "#47A5C0",
  "rgb(0, 194, 146)",
  "rgb(251, 150, 120)",
  "rgb(3, 201, 215)",
  "rgb(254, 201, 15)",
  "rgb(228, 106, 118)",
  "#212A3C",
  "#22A9D8",
  "red",
];

const Announcement = () => {
  //const { status, data } = hooks.useUpcomingEvents();

  const [announcements, setAnnouncements] = useState();
  const [viewAnnouncement, setViewAnnouncement] = useState();
  const [open, setOpen] = useState(false);
  const { data, isFetching } = hooks.useAnnouncement();
  const [markAsRead, disableMarkAsRead] = useState(false);

  const useAnnouncement = useMutation(
    (formData) => confirmAnnouncement(formData),
    {
      onSuccess: async (response) => {
        toast.success("Announcement updated successfully!");
        setAnnouncements(response.data);
      },
      onError: (response) => {
        toast.error("Something went wrong!");
      },
    }
  );

  const notedHandler = (id) => {
    const payload = {
      id: id,
    };

    useAnnouncement.mutate(payload);
    disableMarkAsRead(true);
  };

  const initAnnouncement = useCallback(() => {
    if (data) {
      setAnnouncements(data.data);
    }
  }, [data]);

  const vewAnnouncement = (announcement) => {
    setViewAnnouncement(announcement);
    disableMarkAsRead(announcement?.confirmation.length > 0 ? true : false);
    setOpen(true);
  };

  useEffect(() => {
    initAnnouncement();
  }, [initAnnouncement]);

  return (
    <>
      <Dialog
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="simple-dialog-title"
        maxWidth={"md"}
        fullWidth
        disableEnforceFocus
        PaperProps={{
          style: {
            borderRadius: "10px", // Change this to adjust the border radius
          },
        }}
      >
        <DialogContent sx={{ padding: 0 }}>
          <Card sx={{ padding: "20px", width: "100%" }}>
            <CardHeader
              title={<Typography variant="h5">Announcements</Typography>}
              action={
                <Button
                  onClick={() => {
                    notedHandler(viewAnnouncement.id);
                  }}
                  disabled={markAsRead}
                >
                  Mark As Read
                </Button>
              }
              sx={{
                pb: "10px",
                borderBottom: 1,
                borderColor: "grey.300",
              }}
            />

            <CardContent>
              <Typography color="text.secondary">
                {dayjs(viewAnnouncement?.from).format("MMM D, YYYY")}
              </Typography>
              <Typography variant={"h4"}>
                {" "}
                {viewAnnouncement?.title}{" "}
              </Typography>
            </CardContent>
            <CardContent sx={{ pt: 0, pr: 2, pl: 2 }}>
              {viewAnnouncement?.message}
            </CardContent>

            <CardActions sx={{ margin: "0px 10px 10px" }}>
              <LoadingButton
                loading={false}
                size="small"
                onClick={(e) => setOpen(false)}
                disableElevation
                variant="contained"
                sx={{
                  "&:hover": { bgcolor: "#ca5764" },
                  bgcolor: "#D06A76",
                  marginLeft: "auto",
                  textTransform: "none",
                  px: "16px",
                  py: "6px",
                }}
                startIcon={<BlockIcon />}
              >
                Close
              </LoadingButton>

              {viewAnnouncement?.path && (
                <LoadingButton
                  loading={false}
                  size="small"
                  type="submit"
                  className="timest-button"
                  disableElevation
                  variant="contained"
                  sx={{ marginLeft: "auto" }}
                  startIcon={<OpenInNewIcon />}
                  onClick={(e) => window.open(viewAnnouncement.path, "_blank")}
                >
                  Open Document
                </LoadingButton>
              )}
            </CardActions>
          </Card>
        </DialogContent>
      </Dialog>
      <Card
        sx={{ margin: "15px", py: "14px", px: "1px", height: "260.75px" }}
        className="announcement-card"
      >
        <CardHeader
          sx={{ pb: "5px" }}
          title={
            <Typography sx={{ pl: "14px" }} variant="h3" className="h3-bar">
              Announcements
            </Typography>
          }
          className="event-head"
        />
        {announcements && announcements.length > 0 ? (
          <Carousel
            navButtonsProps={{
              style: {
                textAlign: "right",
                padding: "5px",
                fontSize: "5px",
                marginTop: "-30px",
              },
            }}
            sx={{ margin: "0", padding: "0", height: "238.75px" }}
            autoPlay={true}
            stopAutoPlayOnHover={true}
            indicatorContainerProps={{
              style: {
                zIndex: 999,
                position: "absolute",
                bottom: "0",
                right: "0",
                paddingBottom: "50px",
              },
            }}
          >
            {announcements.map((announcement) => (
              <Card
                sx={{
                  display: "flex",
                  paddingLeft: "20px",
                  paddingTop: "10px",
                  paddingRight: "10px",
                  height: "290.75px",
                }}
              >
                <Box>
                  <CardMedia
                    sx={{
                      width: "135px",
                      height: "135px",
                      borderRadius: "15px",
                    }}
                    component="img"
                    image={AnnouncementViewImg}
                    alt="Handbook"
                  />
                </Box>
                <CardContent sx={{ padding: 0, paddingLeft: "15px" }}>
                  <Box>
                    <Chip
                      sx={{ bgcolor: "#00C292", color: "white" }}
                      label={dayjs(announcement?.from).format("MMM D, YYYY")}
                    />

                    <Box
                      sx={{
                        maxHeight: "55px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          mt: 1,
                          fontWeight: "bold",
                          ml: 0.5,
                        }}
                      >
                        {announcement?.title}
                      </Typography>
                    </Box>

                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      <Typography sx={{ mt: "1px", ml: 0.5 }} variant="body2">
                        {announcement?.message?.length > 40
                          ? announcement?.message?.substring(0, 40) + "... "
                          : announcement?.message + " "}
                        <Link
                          sx={{
                            ":hover": {
                              cursor: "pointer",
                            },
                            color: "#00C292",
                            marginTop: "2.5px",
                          }}
                          onClick={(e) => vewAnnouncement(announcement)}
                        >
                          Read more
                        </Link>
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Carousel>
        ) : (
          <Box sx={{ px: "14px", py: "0", margin: "0", height: "290.75px" }}>
            <CardContent
              sx={{
                paddingBottom: "0px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography>No New Announcements</Typography>
            </CardContent>
            <Box>
              <CardMedia
                component="img"
                image={AnnouncementImg}
                alt="Handbook"
              />
            </Box>
          </Box>
        )}
      </Card>
    </>
  );
};
export default Announcement;
