import React from "react";
import { MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";
import "../../styles/Sidebar/sidebar.scss";
import Tooltip from "@mui/material/Tooltip";
import { getRoles } from "../../hooks/customHooks/auth";
import _ from "lodash";
import SidebarSubMenuItem from "./SidebarSubMenuItem";

const SidebarMenuItem = ({ sidebarItem, collapsed, index }) => {
  const toolTipprop = {
    arrow: true,
    leaveDelay: 200,
    placement: "right",
  };

  const roles = getRoles();
  const user = JSON.parse(localStorage.getItem("user"));

  const isToRender = () => {
    return roles.some((role) => {
      if (sidebarItem.roles.includes("all")) {
        return true;
      } else {
        let isAdmin = true;
        if (!_.isUndefined(sidebarItem?.isAdmin)) {
          isAdmin = sidebarItem.isAdmin === user.isAdmin;
        }

        return sidebarItem.roles.includes(role) && isAdmin;
      }
    });
  };

  return (
    <React.Fragment key={index}>
      {isToRender() &&
        (!sidebarItem.children ? (
          <Tooltip
            key={index}
            title={collapsed ? sidebarItem.title : ""}
            {...toolTipprop}
          >
            {sidebarItem.isButton !== "true" ? (
              <MenuItem icon={sidebarItem.icon}>
                <Link to={sidebarItem.linkto}>{sidebarItem.title}</Link>
              </MenuItem>
            ) : (
              <MenuItem icon={sidebarItem.icon} onClick={sidebarItem.action}>
                {sidebarItem.title}
              </MenuItem>
            )}
          </Tooltip>
        ) : (
          <SidebarSubMenuItem
            key={index}
            sidebarItem={sidebarItem}
            index={index}
            collapsed={collapsed}
          />
        ))}
    </React.Fragment>
  );
};

export default SidebarMenuItem;
