export const tabList = [
  {
    label: "USER PERMISSION",
    width: "32.6%",
    maxWidth: "unset",
  },
  {
    label: "USER ROLE",
    width: "32.6%",
    maxWidth: "unset",
  },
  {
    label: "ROLE PERMISSION",
    width: "32.6%",
    maxWidth: "unset",
  },
];

// convert to array of strings to object
export const addObject = (data) =>
  data?.map((item) =>
    Object.fromEntries([
      ["name", item],
      ["shortname", item],
    ])
  );

export const firstObj = { id: 0, shortname: "name", name: "User" };

export const note =
  "Note: Some permissions are pre-assigned and non-revocable.";
