import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { getStartEndWeek } from "../../../../helper/Evaluation/TimesheetWeeklyReport";

const WeeklyPagination = ({
  skip,
  limit,
  maxLimit,
  remainder,
  isDecrementEnabled,
  isIncrementEnabled,
  handleDecrementSkip,
  handleIncrementSkip,
}) => {
  const { start: startingWeek, end: endingWeek } = getStartEndWeek(
    skip,
    limit,
    maxLimit,
    remainder
  );

  const paginationString =
    limit > 1
      ? `Week ${startingWeek} to 
    ${endingWeek}`
      : `Week ${startingWeek}`;

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <IconButton
        aria-label="previous"
        disabled={!isDecrementEnabled}
        onClick={handleDecrementSkip}
        sx={{
          color: "#21293C",
        }}
      >
        <ChevronLeftIcon fontSize="large" color="inherit" />
      </IconButton>

      <Typography sx={{ fontSize: "12px" }}>{paginationString}</Typography>

      <IconButton
        aria-label="next"
        disabled={!isIncrementEnabled}
        onClick={handleIncrementSkip}
        sx={{
          color: "#21293C",
        }}
      >
        <ChevronRightIcon fontSize="large" color="inherit" />
      </IconButton>
    </Stack>
  );
};

export default WeeklyPagination;
