import { useState, useCallback, useEffect } from "react";

import { useQuery, useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";

import {
  getPendingTimesheetEntries,
  submitPendingTimesheetEntryAction,
} from "../../../../api/pendingTimesheetEntries";
import {
  PENDING_ENTRIES_QUERY_KEY,
  HEADER_CELL_ID,
  ORDER,
} from "../../../../lib/settingsTimesheet/PendingTimesheetEntries";
import { getComparator } from "../../../../helper/SettingsTimesheet/PendingTimesheetEntries";

export const usePendingEntries = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchString, setSearchString] = useState("");
  const [order, setOrder] = useState(ORDER.DESCENDING);
  const [orderBy, setOrderBy] = useState(HEADER_CELL_ID.DATE);

  useEffect(() => {
    setPage(1);
  }, [searchString, pageSize]);

  const sortFn = useCallback(
    (data) => {
      const sortedData = [...data.data].sort(getComparator(order, orderBy));
      return {
        ...data,
        data: sortedData,
      };
    },
    [order, orderBy]
  );

  const pendingEntriesQuery = useQuery(
    [PENDING_ENTRIES_QUERY_KEY, page, pageSize, searchString],
    getPendingTimesheetEntries.bind(null, page, pageSize, searchString),
    {
      select: sortFn,
    }
  );

  const changePage = (newPage) => {
    setPage(newPage);
  };

  const changePageSize = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const searchFilter = useCallback((filterString) => {
    setSearchString(filterString);
  }, []);

  const changeOrder = (newOrder) => {
    setOrder(newOrder);
  };

  const changeOrderBy = (newOrderBy) => {
    setOrderBy(newOrderBy);
  };

  return {
    ...pendingEntriesQuery,
    order,
    orderBy,
    page,
    pageSize,
    searchString,
    changePage,
    changePageSize,
    searchFilter,
    changeOrder,
    changeOrderBy,
  };
};

export const usePrefetchPendingEntries = (
  hasNextPage,
  page,
  pageSize,
  searchString
) => {
  const queryClient = useQueryClient();

  if (!hasNextPage) return;

  const nextPage = page + 1;

  queryClient.prefetchQuery(
    [PENDING_ENTRIES_QUERY_KEY, nextPage, pageSize, searchString],
    getPendingTimesheetEntries.bind(null, nextPage, pageSize, searchString),
    {
      staleTime: 5000,
    }
  );
};

export const usePendingEntryAction = () => {
  const queryClient = useQueryClient();

  const pendingEntryMutation = useMutation(submitPendingTimesheetEntryAction, {
    onSuccess: () => {
      toast.success("Action successfully applied.");
      queryClient.resetQueries(PENDING_ENTRIES_QUERY_KEY);
    },
    onError: () => {
      toast.error("Error submitting action");
    },
  });

  return pendingEntryMutation;
};
