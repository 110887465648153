export const TableSettings = { rows: 25 };

export const TableHeader = [
  { label: "Schedule Name", data: "sched_name", type: "text" },
  { label: "Monday", data: "mon", type: "select", options: "shift" },
  { label: "Tuesday", data: "tue", type: "select", options: "shift" },
  { label: "Wednesday", data: "wed", type: "select", options: "shift" },
  { label: "Thursday", data: "thu", type: "select", options: "shift" },
  { label: "Friday", data: "fri", type: "select", options: "shift" },
  { label: "Saturday", data: "sat", type: "select", options: "shift" },
  { label: "Sunday", data: "sun", type: "select", options: "shift" },
];
