import React, { useState, useEffect } from "react";
import moment from "moment";
import reactQuery from "../../../hooks/react-query/reportsDashboard";
import {
  TableBody,
  TablePagination,
  Toolbar,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import TableHeader from "../../../component/Reports/TableHeader";
import { DashboardActivityTableHeader } from "../../../lib/reports/dashboard";
import ActivityLists from "./ActivityList";
import DashboardTableContainer from "../../../component/Reports/Dashboard/DashboardTableContainer";

const ActivityTable = ({ startDate, endDate }) => {
  const [activityTableData, setActivityTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [orderDirection, setOrderDirection] = useState("desc");

  const { data: activityReportData } = reactQuery.useActivityReport({
    criteria: { page, limit, startDate, endDate },
    payload: {
      fromDate: moment(startDate).format("YYYY-MM-DD"),
      toDate: moment(endDate).format("YYYY-MM-DD"),
      page_size: limit,
      page: page,
    },
  });

  useEffect(() => {
    if (activityReportData) {
      setActivityTableData(activityReportData.data);
    }
  }, [activityReportData]);

  const handleChangePage = (page) => {
    setPage(page + 1);
  };

  const handleChangeRowsPerPage = (e) => {
    setPage(1);
    setLimit(e.target.value);
  };

  const handleSortRequest = (key) => {
    let direction = "asc";
    direction = orderDirection === "desc" ? "asc" : "desc";
    setOrderDirection(direction);
  };

  return (
    <>
      <Paper sx={{ width: "100%", mb: 2, borderRadius: "10px" }}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}
        >
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Project Activity Summary
          </Typography>
        </Toolbar>
        <DashboardTableContainer>
          <TableHeader
            isCollapsable={true}
            headerList={DashboardActivityTableHeader}
            handleSortRequest={handleSortRequest}
            orderDirection={orderDirection}
          />
          <TableBody>
            {activityTableData?.map((item, index) => {
              return (
                <ActivityLists key={`${index}_${item.row_id}`} item={item} />
              );
            })}
          </TableBody>
        </DashboardTableContainer>
        <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
            count={activityReportData?.total || 0}
            page={
              activityReportData?.current_page
                ? activityReportData.current_page - 1
                : 0
            }
            onPageChange={(e, page) => {
              handleChangePage(page);
            }}
            showFirstButton={true}
            showLastButton={true}
            rowsPerPage={limit}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Paper>
    </>
  );
};

export default ActivityTable;
