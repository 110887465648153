import Button, { buttonClasses } from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)(() => ({
  [`&.${buttonClasses.containedPrimary}`]: {
    backgroundColor: "#21293C",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#3b4a6c",
    },
    "&:disabled": {
      backgroundColor: "#e0e0e0",
      color: "#a6a6a6",
    },
  },
  [`&.${buttonClasses.outlinedPrimary}`]: {
    backgroundColor: "#fff",
    textTransform: "capitalize",
    borderColor: "#21293C",
    color: "#21293C",
    "&:hover": {
      backgroundColor: "rgba(33, 63, 133, 0.1)",
    },
  },
}));

export default StyledButton;
