import React from "react";
import {
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import VerifiedIcon from "@mui/icons-material/Verified";
import BlockIcon from "@mui/icons-material/Block";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const WfhTemplateRow = ({
  columns,
  row,
  handleViewTemplate,
  handleEditTemplate,
  handleActivate,
  handleDelete,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableRow sx={{ backgroundColor: "#FFF" }}>
      {columns &&
        columns.map((column, index) => (
          <TableCell key={index} scope="row">
            {column.type === "text" && (
              <Typography
                sx={{
                  py: 2.5,
                  fontSize: 14,
                  wordWrap: "break-word",
                  whitepace: "normal",
                  textTransform: "none",
                }}
              >
                {row[column.data]}
              </Typography>
            )}

            {column.type === "date" && (
              <Typography
                sx={{
                  py: 2.5,
                  fontSize: 14,
                  wordWrap: "break-word",
                  whitepace: "normal",
                  textTransform: "none",
                }}
              >
                {row[column.data] && moment(row[column.data]).format("LL")}
              </Typography>
            )}

            {column.type === "select" && (
              <Typography
                sx={{
                  py: 2.5,
                  fontSize: 14,
                  wordWrap: "break-word",
                  whitepace: "normal",
                  textTransform: "none",
                }}
              >
                {row[column.data] === 1 ? "Active" : "Inactive"}
              </Typography>
            )}
          </TableCell>
        ))}
      <TableCell>
        <Tooltip title="View template">
          <IconButton
            aria-label="view template"
            onClick={(e) => {
              handleViewTemplate(row.content);
            }}
          >
            <VisibilityIcon sx={{ fill: "blue" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title={row.isActive === 1 ? "Deactivate" : "Activate"}>
          <IconButton
            aria-label="activate"
            onClick={(e) => {
              handleActivate(row.id, row.isActive);
            }}
          >
            {row.isActive === 1 ? (
              <VerifiedIcon sx={{ fill: "green" }} />
            ) : (
              <BlockIcon sx={{ fill: "red" }} />
            )}
          </IconButton>
        </Tooltip>
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          aria-label="delete"
        >
          <MoreHorizIcon />
        </IconButton>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={(e) => {
              handleClose();
              handleEditTemplate(row);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              handleDelete(row.id);
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export default WfhTemplateRow;
