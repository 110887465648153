import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";

const ConfirmButtonCircularProgress = styled(CircularProgress)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: "-12px",
  marginLeft: "-12px",
}));

export default ConfirmButtonCircularProgress;
