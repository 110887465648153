import { createContext, useEffect, useMemo, useState } from "react";
import {
  Box,
  Table,
  TableContainer,
  Typography,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination,
  Tooltip,
} from "@mui/material";
import { manageFeedbackHeader } from "../../lib/manageFeedback";
import ManageFeedbackFilter from "./ManageFeedbackFilter";
import hooks from "../../hooks/react-query/ManageFeedback";
import ManageFeedbackList from "./ManageFeedbackList";
import Loader from "../../component/Loader";
import { sortArrayTable } from "../../helper/UserAppSettings";
import EmptyData from "../../component/Reports/EmptyData";

export const mngFeedbackCntxt = createContext({});
const ManageFeedback = () => {
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [orderDirection, setOrderDirection] = useState("desc");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState("");
  const { data, isFetching } = hooks.useGetFeedBack({
    criteria: { page, rowsPerPage, search },
    payload: {
      per_page: rowsPerPage,
      page: page,
      search: search,
    },
  });

  useEffect(() => {
    if (data) setTableData(data?.data.data);
  }, [data]);

  const handleSortRequest = (key, parentKey) => {
    let direction = "asc";
    direction = orderDirection === "desc" ? "asc" : "desc";
    setOrderDirection(direction);
    setTableData((prev) => sortArrayTable(prev, direction, key, parentKey));
  };
  const handleChangePage = (page) => {
    setPage(page + 1);
  };

  const handleChangeRowsPerPage = (e) => {
    setPage(1);
    setRowsPerPage(e.target.value);
  };

  const value = useMemo(
    () => ({
      search,
      setSearch,
      isFetching,
    }),
    [search, setSearch, isFetching]
  );

  return (
    <div>
      <Loader isLoading={isFetching} />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "0px 15px",
          cursor: "context-menu",
        }}
      >
        <Tooltip title={"Manage companys feedback"} placement={"right"} arrow>
          <Typography variant="h4" component="div" sx={{ padding: "1rem" }}>
            Manage Feedback
          </Typography>
        </Tooltip>
      </Box>
      <mngFeedbackCntxt.Provider value={value}>
        <ManageFeedbackFilter />
        {!tableData.length && !isFetching ? (
          <EmptyData />
        ) : (
          <TableContainer component={Paper} sx={{ padding: "1em" }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {manageFeedbackHeader.map((header, index) => {
                    return (
                      <TableCell
                        onClick={() =>
                          handleSortRequest(
                            header.key,
                            header?.parentKey ?? null
                          )
                        }
                        key={index}
                        align={header.align ? header.align : undefined}
                      >
                        <TableSortLabel
                          sx={{ fontWeight: "700", fontSize: "0.8rem" }}
                        >
                          {header.label}
                        </TableSortLabel>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData?.map((item, i) => {
                  return <ManageFeedbackList item={item} key={item?.id} />;
                })}
              </TableBody>
            </Table>
            <TablePagination
              sx={{ display: "flex", justifyContent: "end" }}
              rowsPerPageOptions={[25, 50, 100]}
              count={data?.data.total || 0}
              page={data?.data.current_page ? data?.data.current_page - 1 : 0}
              showFirstButton={true}
              showLastButton={true}
              rowsPerPage={rowsPerPage}
              onPageChange={(e, page) => {
                handleChangePage(page);
              }}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
      </mngFeedbackCntxt.Provider>
    </div>
  );
};

export default ManageFeedback;
