import apiService from "../axios";

export const getFeedback = async ({ per_page = 25, search, page = 1 }) => {
  try {
    let response = await apiService.get(
      `/v5/get/feedback?per_page=${per_page}&search=${search}&page=${page}`,
      {
        Accept: `application/json`,
        api: true,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
