import { useContext } from "react";

import TableContainer from "@mui/material/TableContainer";

import LeaveConversionApplicationsContext from "../../../../globalstate/LeaveManagement/LeaveConversionApplications/LeaveConversionApplicationsContext";
import TableWrapper from "../../../../component/LeaveManagement/LeaveConversionApplications/wrapper/TableWrapper";
import SearchBar from "../../../../component/LeaveManagement/LeaveConversionApplications/filter/SearchBar";
import StyledTable from "../../../../component/LeaveManagement/LeaveConversionApplications/table/StyledTable";
import TableHeader from "../../../../component/LeaveManagement/LeaveConversionApplications/table/TableHeader";
import TableBody from "../../../../component/LeaveManagement/LeaveConversionApplications/table/TableBody";
import Pagination from "../../../../component/LeaveManagement/LeaveConversionApplications/table/Pagination";
import ErrorDisplay from "../error/ErrorDisplay";

const Table = () => {
  const {
    query: { isError },
  } = useContext(LeaveConversionApplicationsContext);

  if (isError) return <ErrorDisplay />;

  return (
    <TableWrapper>
      <SearchBar />

      <TableContainer>
        <StyledTable stickyHeader>
          <TableHeader />

          <TableBody />
        </StyledTable>
      </TableContainer>

      <Pagination />
    </TableWrapper>
  );
};

export default Table;
