import React, { useEffect, useState, useCallback } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import hooks from "../../hooks/react-query/SettingsSytem/WfhTemplate";
import Content from "../../pages/SettingsSystem/WfhTemplate/Content";

const WfhAgreement = ({ handleProceed, checkBox, wfhLink }) => {
  const [wfh, setWFH] = useState(false);

  const { data } = hooks.useWfhTemplate();

  const initWFHtemplate = useCallback(() => {
    if (data) {
      console.log("data.data");
      console.log(data.data.content);
      setWFH(data.data.content || []);
    }
  }, [data]);

  useEffect(() => {
    initWFHtemplate();
  }, [initWFHtemplate]);

  return (
    <Box
      className="box-agree"
      sx={{
        "> p": {
          fontSize: "0.8rem",
        },
      }}
    >
      <Content content={wfh} />
      <FormControlLabel
        control={
          <Checkbox size="small" onChange={handleProceed} checked={checkBox} />
        }
        label="I accept the terms and conditions"
        sx={{
          "span.MuiTypography-root": {
            fontSize: "0.8rem",
          },
        }}
      />
    </Box>
  );
};

export default WfhAgreement;
