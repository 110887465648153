import React from "react";
import { TableHead, TableRow, TableSortLabel } from "@mui/material";
import { TableCell } from "@material-ui/core";

const TableHeader = ({
  isCollapsable,
  handleSortRequest,
  orderDirection,
  headerList,
}) => {
  return (
    <TableHead>
      <TableRow>
        {isCollapsable && <TableCell></TableCell>}
        {headerList.map((header, index) => {
          return (
            <TableCell
              onClick={() =>
                handleSortRequest(header.key, header?.parentKey ?? null)
              }
              key={index}
              align={header.align ? header.align : undefined}
            >
              <TableSortLabel
                direction={orderDirection}
                sx={{ fontWeight: "700", fontSize: "0.8rem" }}
              >
                {header.label}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
