import React, { useRef, useState } from "react";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import {
  Dialog as MuiDialog,
  DialogContent,
  Box,
  FormControl,
  TextField,
  FormGroup,
  Button,
} from "@mui/material";
import "../../styles/Auth/login.css";
import { forgotPassword } from "../../api/auth";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

const Profile = ({ open, handleClose, show }) => {
  const form = useRef(null);
  const [errors, setErrors] = useState({});
  const [sending, setSending] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSending(true);
    let formData = new FormData(form.current);
    password.mutate(formData);
  };

  const password = useMutation((formData) => forgotPassword(formData), {
    onSuccess: async (response) => {
      setSending(false);
      toast.success("Password reset email sent");
      show(false);
    },
    onError: (response) => {
      setSending(false);
      setErrors(response.response.data || {});
      if (!response.response.data.status) {
        toast.error("Something went wrong");
      }
    },
  });

  return (
    <>
      <MuiDialog maxWidth="sm" open={open} fullWidth={true}>
        <DialogContent>
          <form ref={form} onSubmit={handleSubmit}>
            <Box>
              <FormControl
                fullWidth
                sx={{
                  margin: "15px 0px 5px",
                }}
              >
                <TextField
                  InputProps={{
                    startAdornment: <EmailRoundedIcon sx={{ mr: 2 }} />,
                  }}
                  variant="outlined"
                  name="email"
                  id="email"
                  type="text"
                  fullWidth
                  placeholder="Email"
                  error={!!errors.status}
                  helperText={errors.status}
                />
              </FormControl>

              <Box className="log-box">
                <FormGroup></FormGroup>
              </Box>
              <LoadingButton
                variant="contained"
                sx={{
                  textTransform: "none",
                  backgroundColor: "#22A9D8",
                  color: "#fff",
                  height: "56px",
                  mt: 2,
                }}
                fullWidth
                disableElevation
                size="large"
                className="log-sign"
                type="submit"
                loadingPosition="start"
                startIcon={<SaveIcon />}
                loading={sending}
              >
                <span>Send Password Reset Email</span>
              </LoadingButton>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  backgroundColor: "white",
                  color: "#22A9D8",
                  height: "56px",
                  mt: 1,
                  border: 1,
                  borderColor: "#22A9D8",
                }}
                fullWidth
                disableElevation
                size="large"
                onClick={(e) => {
                  setErrors("");
                  handleClose(false);
                }}
                className="log-sign"
              >
                Cancel
              </Button>
            </Box>
          </form>
        </DialogContent>
      </MuiDialog>
    </>
  );
};

export default Profile;
