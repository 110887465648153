import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, Button, Tooltip } from "@mui/material";
import HandbookImg from "../../../images/Handbook-Freepik2.png";

const employeeHandbook = ({ imgUrl }) => {
  return (
    <Card className="handbook-card">
      <CardHeader
        title={
          <Typography variant="h3" className="h3-bar">
            Employee Handbook
          </Typography>
        }
      />

      <CardContent sx={{ display: "flex", paddingTop: "0" }}>
        <Box
          onClick={(e) =>
            window.open(
              "https://drive.google.com/file/d/1Q-Ujm9Tqb8lydsn1JX-AZM7KNXvDeqWY/view?usp=sharing",
              "_blank"
            )
          }
          sx={{
            ":hover": {
              cursor: "pointer",
            },
            height: "130px",
            padding: "8px",
            width: "100px",
            borderRadius: "8px",
            justifyContent: "center",
            flexDirection: "start",
            alignItems: "center",
            display: "flex",
            borderColor: "grey.300",
          }}
        >
          <CardMedia
            sx={{ marginTop: "-60px" }}
            component="img"
            image={HandbookImg}
            alt="Handbook"
          />
        </Box>
        <Box sx={{ paddingX: 4 }}>
          <Typography variant="h2" className="greeting-h4">
            Handbook Updated{" "}
          </Typography>
          <Tooltip title="Open Document">
            <Button
              sx={{ marginTop: "5px" }}
              size="small"
              className="timest-button"
              disableElevation
              variant="contained"
              onClick={(e) =>
                window.open(
                  "https://drive.google.com/file/d/1Q-Ujm9Tqb8lydsn1JX-AZM7KNXvDeqWY/view?usp=sharing",
                  "_blank"
                )
              }
            >
              View Handbook
            </Button>
          </Tooltip>
        </Box>
      </CardContent>
    </Card>
  );
};

export default employeeHandbook;
