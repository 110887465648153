import NoData from "./NoData";
import Fetching from "./Fetching";
import React from "react";

const Rows = ({ data = [], isFetching = true, columns, Row }) => {
  return (
    <>
      {isFetching ? (
        <Fetching />
      ) : data && data.length === 0 ? (
        <NoData />
      ) : (
        data &&
        data.map((row, index) => (
          <React.Fragment key={index}>
            <Row columns={columns} row={row} />
          </React.Fragment>
        ))
      )}
    </>
  );
};

export default Rows;
