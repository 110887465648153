import apiService from "../../axios";

export const getWfhTemplates = async (url) => {
  try {
    let response = await apiService.get(`v5/settings/get-wfh-templates` + url, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(url),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getWfhTemplate = async (url) => {
  try {
    let response = await apiService.post(
      `v1/settings/get-active-wfh-template`,
      {
        Accept: `application/json`,
        "Content-Type": `multipart/form-data`,
        body: JSON.stringify(url),
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addWfhTemplate = async (data) => {
  try {
    await apiService.post(`v1/settings/add-wfh-template`, data, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(data),
    });
  } catch (error) {
    throw error;
  }
};

export const deleteWfhTemplate = async (data) => {
  try {
    await apiService.post(`v1/settings/delete-wfh-template`, data, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(data),
    });
  } catch (error) {
    throw error;
  }
};

export const activateWfhTemplate = async (data) => {
  try {
    await apiService.post(`v1/settings/set-active-wfh-template`, data, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(data),
    });
  } catch (error) {
    throw error;
  }
};
