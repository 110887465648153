import Typography from "@mui/material/Typography";
import { Tooltip } from "@mui/material";
import { Box } from "@mui/system";

const Title = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        cursor: "context-menu",
      }}
    >
      <Tooltip
        title={"Manage employees leave conversion applications"}
        placement={"right"}
        arrow
      >
        <Typography
          variant="h4"
          sx={{ fontSize: "clamp(24px, 5vw, 32px)", color: "#21293C" }}
        >
          Leave Conversion Applications
        </Typography>
      </Tooltip>
    </Box>
  );
};

export default Title;
