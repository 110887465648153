import { useState, useRef, useMemo, useEffect } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const ClampedLongText = ({
  children,
  lineClamp = 1,
  maxWidth = 35,
  sxContainer = {},
  sxTypography = {},
  sxButton = {},
}) => {
  const [showMoreText, setShowMoreText] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const typographyRef = useRef();

  useEffect(() => {
    if (
      typographyRef?.current?.offsetHeight <
      typographyRef?.current?.scrollHeight
    )
      return setIsOverflow(true);

    setIsOverflow(false);
  }, [typographyRef]);

  const WEBKIT_CLAMP_STYLES = useMemo(
    () => ({
      width: "fit-content",
      maxWidth: `${maxWidth}ch`,
      display: "-webkit-box",
      wordBreak: "break-all",
      WebkitLineClamp: `${lineClamp}`,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
    }),
    [maxWidth, lineClamp]
  );

  const showMoreTextHandler = () => {
    setShowMoreText((prev) => !prev);
  };

  const showMoreTextStyles = showMoreText ? { display: "block" } : {};

  return (
    <Box
      sx={{
        width: "max-content",
        display: "flex",
        flexDirection: "column",
        ...sxContainer,
      }}
    >
      <Typography
        ref={typographyRef}
        sx={{ ...WEBKIT_CLAMP_STYLES, ...showMoreTextStyles, ...sxTypography }}
      >
        {children}
      </Typography>

      <Button
        variant="text"
        sx={{
          alignSelf: "flex-start",
          padding: 0,
          display: isOverflow ? "block" : "none",
          textTransform: "capitalize",
          "&:hover": {
            backgroundColor: "transparent",
            textDecoration: "underline",
          },
          ...sxButton,
        }}
        onClick={showMoreTextHandler}
      >
        {showMoreText ? "show less" : "show more"}
      </Button>
    </Box>
  );
};

export default ClampedLongText;
