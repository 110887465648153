export const USER_APP_QUERY_KEY = "user-applications-list";

export const WORKPLACE_ATTACHMENT_IMG_KEY = "work-from-home-attachment-image";

export const APP_TYPE = {
  leave: {
    id: "leave",
    title: "Leave",
    alternateTitle: "Leave",
    uri: "get-leave-applications",
  },
  ot: {
    id: "ot",
    title: "Overtime/Undertime",
    alternateTitle: "OT/UT",
    uri: "get-overtime-applications",
  },
  wfh: {
    id: "wfh",
    title: "Work From Home",
    alternateTitle: "WFH",
    uri: "get-wfh-applications",
  },
  forgotTimeInOut: {
    id: "forgotTimeInOut",
    isHR: true,
    title: "Forgot Time In/Out",
    alternateTitle: "Forgot Time In/Out",
    uri: "get-timeinout-applications",
  },
  document: {
    id: "document",
    isHR: true,
    title: "Document",
    alternateTitle: "Document",
    uri: "document",
  },
};

export const HEADER_CELL_ID = {
  NAME: "name",
  APPLICATION: "leaveType",
  DATE_FILED: "created_at",
  FROM: "startDate",
  TO: "endDate",
  NO_OF_DAYS: "length",
  DURATION: "duration",
  PURPOSE: "purpose",
  WFH_WORKPLACE: "workplace",
  WFH_ATTACHMENT: "images",
  WFH_TELECOMMUTE: "wfh_template",
  LEAVE_CREDITS: "credits",
  STATUS: "status",
  ACTION: "action",
  REASON: "reason",
};

export const ORDER = {
  ASCENDING: "asc",
  DESCENDING: "desc",
};

export const headerCells = [
  {
    id: HEADER_CELL_ID.NAME,
    label: "Name",
    isSortable: true,
  },
  {
    id: HEADER_CELL_ID.DATE_FILED,
    label: "Date Filed",
    isSortable: true,
  },
  { id: HEADER_CELL_ID.PURPOSE, label: "Purpose", isSortable: false },
  { id: HEADER_CELL_ID.REASON, label: "Reason", isSortable: false },
  {
    id: HEADER_CELL_ID.WFH_WORKPLACE,
    label: "(WFH) Workplace",
    isSortable: false,
  },
  { id: HEADER_CELL_ID.STATUS, label: "Status", isSortable: true },
  { id: HEADER_CELL_ID.ACTION, label: "Action", isSortable: false },
];

export const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100];

export const ACTION_TYPE = {
  APPROVE: "approve",
  DENY: "disapprove",
};

export const IMAGE_UNAVAILABLE = [{ original: "", thumbnail: "" }];
