import React from "react";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import IconButton from "@mui/material/IconButton";
import Grow from "@mui/material/Grow";
import CardContent from "@mui/material/CardContent";
import { useState } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Paper,
  TextField,
  Skeleton,
} from "@mui/material";
import { format } from "date-fns";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import {
  useEditRemarks,
  useGetHistory,
} from "../../../hooks/react-query/Settings/timsheet";
import CircularProgress from "@mui/material/CircularProgress";

const TableHeader = () => {
  const headers = [
    { name: "Date", minW: 150 },
    { name: "User", minW: 150 },
    { name: "Next budget", minW: 150, align: "center" },
    { name: "Previous budget", minW: 150 },
    { name: "Current balance", minW: 150 },
    { name: "remarks", minW: 250 },
    { name: "Action", minW: 150, align: "center" },
  ];
  return headers;
};
const initEdit = { remarks: null, editInd: null };

const History = ({ open, handleClose, id }) => {
  const [edit, setEdit] = useState(initEdit);
  const { data, isFetching } = useGetHistory({ id: id });
  const { mutate, isLoading } = useEditRemarks();

  return (
    <Modal open={open}>
      <Grow
        in={open}
        style={{ transformOrigin: "0 0 0" }}
        {...(open ? { timeout: 500 } : {})}
        unmountOnExit
      >
        <Card className="login-modal leave1-modal" sx={{ minWidth: 800 }}>
          <CardHeader
            title={<Typography variant="h4">History</Typography>}
            action={
              <IconButton onClick={handleClose}>
                <CloseOutlinedIcon sx={{ width: "15px", height: "15px" }} />
              </IconButton>
            }
          />
          <Paper style={{ borderRadius: "10px" }}>
            <CardContent sx={{ margin: "10px" }}>
              {!isFetching ? (
                <Grid container rowSpacing={3} columnSpacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="h5">
                      {data?.data?.description}
                    </Typography>
                  </Grid>
                  <TableContainer
                    sx={{
                      maxHeight: 430,
                      padding: "0px 0px 20px 0px",
                      marginTop: 6,
                    }}
                  >
                    <Table stickyHeader size="small">
                      <TableHead>
                        <TableRow>
                          {TableHeader().map((header, key) => (
                            <TableCell
                              key={key}
                              style={{
                                minWidth: header.minW,
                                fontWeight: "bold",
                              }}
                              align={header.align}
                            >
                              {header.name}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.data?.items?.length === 0 && (
                          <TableRow>
                            <TableCell colSpan={7} align="center">
                              No records found.
                            </TableCell>
                          </TableRow>
                        )}
                        {data?.data?.items?.map((items, key) => (
                          <TableRow key={key}>
                            <TableCell>
                              {format(new Date(items.created_at), "PP")}
                            </TableCell>
                            <TableCell>{items.name}</TableCell>
                            <TableCell align="center">
                              {items.next_budget}
                            </TableCell>
                            <TableCell align="center">
                              {items.prev_budget}
                            </TableCell>
                            <TableCell align="center">
                              {items.current_balance}
                            </TableCell>
                            {edit.editInd === key ? (
                              <TableCell>
                                <TextField
                                  defaultValue={items.remarks}
                                  onChange={(e) =>
                                    setEdit({
                                      ...edit,
                                      remarks: e.target.value,
                                    })
                                  }
                                  variant="standard"
                                  multiline
                                  minRows={2}
                                  style={{ fontSize: "0.80rem", minWidth: 250 }}
                                  InputProps={{
                                    style: { fontSize: "0.75rem" },
                                  }}
                                />
                              </TableCell>
                            ) : (
                              <TableCell>{items.remarks}</TableCell>
                            )}
                            {edit.editInd === key ? (
                              <TableCell align="center">
                                <IconButton
                                  onClick={() => {
                                    mutate({
                                      id: items.id,
                                      remarks: edit.remarks,
                                    });
                                    setEdit(initEdit);
                                  }}
                                  sx={{ color: "#61a644" }}
                                  disabled={
                                    edit.remarks === null || edit.remarks === ""
                                  }
                                >
                                  <CheckIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={() => setEdit(initEdit)}
                                  sx={{ color: "rgb(228, 106, 118)" }}
                                >
                                  <CloseIcon fontSize="small" />
                                </IconButton>
                              </TableCell>
                            ) : isLoading ? (
                              <TableCell align="center">
                                <CircularProgress />
                              </TableCell>
                            ) : (
                              <TableCell align="center">
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    setEdit({ ...edit, editInd: key })
                                  }
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              ) : (
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={1}
                  style={{ width: "100%" }}
                >
                  <Grid item style={{ width: "100%" }}>
                    <Typography variant="h4" style={{ width: "100%" }}>
                      {[0, 1, 2, 3, 4, 5, 6].map((key) => (
                        <Skeleton key={key} />
                      ))}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Paper>
        </Card>
      </Grow>
    </Modal>
  );
};

export default History;
