import { useCallback, useEffect, useState } from "react";

import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";

import { useApproveLeaveApplicationConversion } from "../../../../../hooks/react-query/LeaveManagement/LeaveConversionApplications";
import BaseDialog from "../base/BaseDialog";
import BaseDialogTitle from "../base/BaseDialogTitle";
import CreditsRow from "./CreditsRow";
import RequestedCreditsRow from "./RequestedCreditsRow";
import ConfirmationButton from "../../button/ConfirmationButton";
import { toast } from "react-toastify";

const ApproveActionDialog = ({ data = {}, open = false, onClose }) => {
  const { earned, request: requested, id, converted, vl_credits } = data;

  const [requestedCredits, setRequestedCredits] = useState(requested);

  const {
    mutate: approveConversion,
    isLoading,
    isSuccess,
  } = useApproveLeaveApplicationConversion();

  const handleChangeRequestedCredits = useCallback((newRequestedCredit) => {
    setRequestedCredits(newRequestedCredit);
  }, []);

  const handleCancel = () => {
    onClose();
  };

  const handleConfirm = () => {
    if (vl_credits?.credits > 0) {
      const payload = { id, conversion: requestedCredits };
      approveConversion(payload);
    } else {
      toast.error(
        "Cannot proceed leave conversion. The user have no available VL credits."
      );
      onClose();
    }
  };

  // ? This will cause the dialog to close automatically when the approval is successful.
  useEffect(() => {
    isSuccess && onClose();
  }, [isSuccess, onClose]);

  const isRequestedCreditsValid =
    requestedCredits >= 0.5 && requestedCredits <= earned;

  return (
    <BaseDialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <BaseDialogTitle onClose={handleCancel}>
        Approve Conversion Request
      </BaseDialogTitle>

      <DialogContent>
        <Stack spacing={2}>
          <CreditsRow earned={earned} converted={converted} />

          <RequestedCreditsRow
            originalRequested={requested}
            requested={requestedCredits}
            earned={earned}
            isValidRequested={isRequestedCreditsValid}
            changeRequestedCredits={handleChangeRequestedCredits}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>

        <ConfirmationButton
          isValid={isRequestedCreditsValid}
          isLoading={isLoading}
          onClick={handleConfirm}
        >
          Confirm
        </ConfirmationButton>
      </DialogActions>
    </BaseDialog>
  );
};

export default ApproveActionDialog;
