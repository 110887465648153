import React, { useState, useEffect, createContext, useMemo } from "react";
import HeaderLeaveManagement from "../../../component/LeaveConversionLogs/HeaderLeaveManagement";
import TableContainter from "../../../component/LeaveConversionLogs/TableContainer";
import Loader from "../../../component/Loader";
import EmptyData from "../../../component/Reports/EmptyData";
import reactQuery from "../../../hooks/react-query/LeaveConversionLogs";
import LeaveConversionLogsFilter from "./LeaveConversionLogsFilter";
import TableHeader from "../../../component/LeaveConversionLogs/TableHeader";
import { LeaveConversionLogsTableHeader } from "../../../lib/leaveManagement/leaveConversionLogs";
import {
  TableBody,
  TableRow,
  TablePagination,
  Box,
  Tooltip,
} from "@mui/material";
import ConversionLogList from "./ConversionLogList";

export const LeaveConversionLogsContext = createContext({});

const LeaveConversionLogs = () => {
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [orderDirection, setOrderDirection] = useState("desc");

  const {
    data: leaveConversionLogsData,
    refetch,
    isFetching,
  } = reactQuery.useConversionLogs({
    criteria: { page, limit, search },
    payload: {
      page_size: limit,
      page: page,
      search: search,
    },
  });

  useEffect(() => {
    if (leaveConversionLogsData) {
      setTableData(leaveConversionLogsData?.data || []);
    }
  }, [leaveConversionLogsData]);
  const handleSortRequest = (key) => {
    let direction = "asc";
    direction = orderDirection === "desc" ? "asc" : "desc";
    setOrderDirection(direction);
  };

  const handleChangePage = (page) => {
    setPage(page + 1);
  };

  const handleChangeRowsPerPage = (e) => {
    setPage(1);
    setLimit(e.target.value);
  };

  const value = useMemo(
    () => ({
      setSearch,
      refetch,
      setPage,
    }),
    [setSearch, refetch, setPage]
  );

  return (
    <>
      <Loader isLoading={isFetching} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          cursor: "context-menu",
        }}
      >
        <Tooltip
          title={"View company leave conversion logs"}
          placement={"right"}
          arrow
        >
          <Box>
            <HeaderLeaveManagement text={"Leave Conversion Logs"} />
          </Box>
        </Tooltip>
      </Box>
      <LeaveConversionLogsContext.Provider value={value}>
        <LeaveConversionLogsFilter />
        <Box sx={{ p: 2 }}>
          {!tableData?.length && !isFetching ? (
            <EmptyData />
          ) : (
            <TableContainter>
              <TableHeader
                isCollapsable={true}
                headerList={LeaveConversionLogsTableHeader}
                handleSortRequest={handleSortRequest}
                orderDirection={orderDirection}
              />
              <TableBody>
                {tableData?.map((item) => {
                  return <ConversionLogList key={item.id} item={item} />;
                })}
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 30, 50, 100]}
                    count={leaveConversionLogsData?.total || 0}
                    page={
                      leaveConversionLogsData?.current_page
                        ? leaveConversionLogsData.current_page - 1
                        : 0
                    }
                    onPageChange={(e, page) => {
                      handleChangePage(page);
                    }}
                    showFirstButton={true}
                    showLastButton={true}
                    rowsPerPage={limit}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableBody>
            </TableContainter>
          )}
        </Box>
      </LeaveConversionLogsContext.Provider>
    </>
  );
};

export default LeaveConversionLogs;
