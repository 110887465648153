import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ReactComponent as ErrorIcon } from "../../../../images/error.svg";

const CONTAINER_STYLES = {
  marginTop: "24px",
  display: "flex",
  flexDirection: "column",
  height: "65%",
  maxHeight: "calc(100% - 52px)",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
};

const SVG_STYLE = {
  height: "auto",
  width: "50%",
  maxWidth: "256px",
  filter: "sepia(65%) saturate(0%) brightness(220%)",
};

const TEXT_STYLE = { color: "#868e96", textAlign: "center" };

const ErrorDisplay = () => {
  return (
    <Box sx={CONTAINER_STYLES}>
      <ErrorIcon style={SVG_STYLE} />

      <Typography variant="h3" mt={3.2} sx={TEXT_STYLE}>
        Oops!
      </Typography>

      <Typography mt={2.4} sx={TEXT_STYLE}>
        An error has occured, please refresh the page and try again.
      </Typography>
    </Box>
  );
};

export default ErrorDisplay;
