import { Fragment } from "react";

import Skeleton from "@mui/material/Skeleton";

import StyledTableCell from "../tableComponents/StyledTableCell";
import {
  generateRandomNum,
  getApplicationType,
} from "../../../../helper/UserApplicationMgmt/UserApplications";

const NameCellSkeleton = ({ type }) => {
  const { isLeave, isOvertime, isWfh } = getApplicationType(type);

  return (
    <StyledTableCell sx={{ verticalAlign: "top" }}>
      {isLeave && (
        <Fragment>
          <Skeleton
            variant="text"
            width={generateRandomNum(136, 142)}
            sx={{ fontSize: "20px" }}
          />
          <Skeleton
            variant="text"
            width={generateRandomNum(120, 148)}
            sx={{ fontSize: "20px" }}
          />
          <Skeleton variant="text" width={132} sx={{ fontSize: "20px" }} />
          <Skeleton variant="text" width={20} sx={{ fontSize: "20px" }} />
        </Fragment>
      )}

      {(isOvertime || isWfh) && (
        <Fragment>
          <Skeleton
            variant="text"
            width={generateRandomNum(136, 142)}
            sx={{ fontSize: "20px" }}
          />
          <Skeleton
            variant="text"
            width={generateRandomNum(120, 148)}
            sx={{ fontSize: "20px" }}
          />
        </Fragment>
      )}
    </StyledTableCell>
  );
};

export default NameCellSkeleton;
