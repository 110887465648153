import { useState, useContext } from "react";
import { TableRow, TableCell } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import hooks from "../../../hooks/react-query/ComplianceCheck";
import { ComplianceCheckCntxt } from ".";

const ComplianceCheckList = ({ item, columns }) => {
  const [compliance, setCompliance] = useState(false); // eslint-disable-line
  const { startDate } = useContext(ComplianceCheckCntxt);
  const updateMutation = hooks.useAddDidNotComply();
  const updateComplianceHandler = (e, value) => {
    const data = {
      id: item?.employee_id,
      date: startDate,
      data: e.target.name,
      value: value === false ? true : false,
    };
    updateMutation.mutate(data);
  };

  return (
    <>
      <TableRow sx={{ height: "4em" }}>
        <TableCell align="left">
          <div
            style={{
              fontSize: "0.7rem",
              width: 300,
              wordWrap: "break-word",
            }}
            dangerouslySetInnerHTML={{
              __html: item.name,
            }}
          />
        </TableCell>
        {columns?.map((column, i) => {
          return (
            <TableCell align="center">
              <Checkbox
                checked={compliance}
                name={column.item}
                defaultChecked={item[`${column.item}`]}
                icon={
                  item[`${column.item}`] === false ? (
                    <CheckCircleIcon sx={{ color: "#17A2B8" }} />
                  ) : (
                    <DoDisturbIcon sx={{ color: "#FF0000" }} />
                  )
                }
                onChange={(e, value) => updateComplianceHandler(e, value)}
              />
            </TableCell>
          );
        })}
      </TableRow>
    </>
  );
};

export default ComplianceCheckList;
