import apiService from "../../axios";
export const addDocumentRequestAPI = async (payload) => {
  try {
    const req = await apiService.post(
      "v5/applications/add-request-document",
      payload,
      {
        accept: "application/json",
        body: JSON.stringify(payload),
      }
    );
    return req.data;
  } catch (error) {
    throw error;
  }
};

export const myDocumentRequestAPI = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const employee = user.employee;
  try {
    const req = apiService.get(`/get-emp-request-document/${employee.id}`, {
      accept: "application/json",
    });
    return req;
  } catch (error) {
    throw error;
  }
};
