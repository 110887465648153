import React, { useState } from "react";
import { HexColorPicker, HexColorInput } from "react-colorful";
import { Box, IconButton, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
const ColorPicker = ({ color, onChange, disabled, reused }) => {
  const [willShowPicker, setWillShowPicker] = useState(false);

  const DisplayInput = () => {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        onClick={() => !disabled && setWillShowPicker(!willShowPicker)}
        disabled
      >
        <div
          style={{
            backgroundColor: color,
            width: "100%",
            borderRadius: 3,
            cursor: "pointer",
            border: 0.1,
            borderStyle: "dotted",
          }}
        >
          <p style={{ paddingLeft: 10, color: "lavender" }}>{color}</p>
        </div>
      </a>
    );
  };

  const DisplayInputDepartment = () => {
    return (
      <Box style={{ display: "flex", alignItems: "center" }}>
        <Typography style={{ fontSize: "0.75rem" }}>{reused} Color:</Typography>
        <IconButton
          onClick={() => !disabled && setWillShowPicker(!willShowPicker)}
          style={{ color: color === null ? "#000000" : color }}
        >
          <CircleIcon />
        </IconButton>
      </Box>
    );
  };
  return (
    <>
      {reused ? <DisplayInputDepartment /> : <DisplayInput />}
      {willShowPicker && (
        <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
          <div>
            <HexColorPicker
              color={color === null ? "#000000" : color}
              onChange={onChange}
              onMouseLeave={() => reused && setWillShowPicker(!willShowPicker)}
            />
          </div>
          {!reused && (
            <div>
              <HexColorInput
                color={color}
                prefixed={true}
                onChange={onChange}
                style={{
                  padding: 10,
                  marginLeft: 10,
                  borderColor: "lavender",
                  border: 0.1,
                  borderStyle: "solid",
                }}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ColorPicker;
