import { useQuery } from "react-query";
import { getComplianceReport } from "../../../../api/compliance/complianceReport";

const useComplianceReport = (
  QueryParam,
  search = "",
  pageSize = 10,
  page = 1,
  fetch
) => {
  return useQuery(
    ["get-compliance-report", search, pageSize, page, fetch],
    () => getComplianceReport(QueryParam)
  );
};

const queries = {
  useComplianceReport,
};

export default queries;
