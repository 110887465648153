import React, { useState } from "react";
import ExportModal from "./ExportModal";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ButtonCustomColor } from "../../../helper/ButtonCustomColor";
import { useMutation } from "react-query";
import { getManagementSummary } from "../../../api/userApplicationSummary";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

const ExportComponent = ({ data }) => {
  const [exportModal, setExportModal] = useState(false);
  const [exportData, setExportData] = useState();
  const [isFetchingExport, setIsFetchingExport] = useState(false);

  const getExportData = useMutation(
    () =>
      getManagementSummary(
        data.select,
        data.startDate,
        data.endDate,
        data.status,
        data.summaryTotal,
        1,
        data.search,
        data.type
      ),
    {
      onSuccess: async (result) => {
        setExportModal(true);
        setExportData(result.data);
        setIsFetchingExport(false);
      },
    }
  );

  const OpenExportModal = () => {
    setIsFetchingExport(true);
    getExportData.mutate();
  };

  return (
    <>
      {isFetchingExport ? (
        <LoadingButton
          loading
          loadingPosition="end"
          endIcon={<SaveIcon />}
          variant="outlined"
        >
          Export
        </LoadingButton>
      ) : (
        <ButtonCustomColor
          variant="contained"
          endIcon={<FileDownloadIcon />}
          onClick={() => {
            OpenExportModal();
          }}
        >
          Export
        </ButtonCustomColor>
      )}
      <ExportModal
        select={data.select}
        data={exportData}
        exportModal={exportModal}
        setExportModal={setExportModal}
      />
    </>
  );
};
export default ExportComponent;
