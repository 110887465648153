import React, { useState, useEffect, useCallback } from "react";
import Chart from "react-apexcharts";
import { Box, Paper, Typography } from "@mui/material";

const ProjectChart = ({ projectData }) => {
  const [chartData, setChartData] = useState([0]);
  const [newChartData, setNewChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([""]);

  useEffect(() => {
    if (projectData?.length > 0) {
      let chart_data = [];
      let labels = [];
      let max_cnt = 0;

      for (let i in projectData) {
        let effort = projectData[i].hours;

        labels.push(projectData[i].time_code.name);
        chart_data.push(effort);
        max_cnt = max_cnt > effort ? max_cnt : effort;
      }

      const charDataMinimumCount = 7;
      if (chart_data.length < charDataMinimumCount) {
        const emptyArray = new Array(charDataMinimumCount - chart_data.length);
        const emptyLabel = new Array(charDataMinimumCount - labels.length);
        emptyArray.fill(0, 0, emptyArray.length);
        emptyLabel.fill("", 0, emptyLabel.length);
        labels = labels.concat(emptyLabel);
        chart_data = chart_data.concat(emptyArray);
      }

      setChartLabels(labels);
      setChartData(chart_data);
    }
  }, [projectData]);

  const removeZero = useCallback(async () => {
    if (chartData.length > 0) {
      const result = chartData.map((data) => (data !== 0 ? data : null));
      setNewChartData(result);
    }
  }, [chartData]);

  useEffect(() => {
    removeZero();
  }, [removeZero]);

  const chartInfo = {
    options: {
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: 0,
        offsetY: -16,
        style: {
          colors: ["#333"],
        },
      },

      chart: {
        id: "top-10-project",
      },
      yaxis: {
        title: {
          text: "H O U R S",
        },
        tickAmount: 8,
      },
      xaxis: {
        categories: chartLabels,
      },
    },
    series: [
      {
        name: "Hours",
        data: newChartData,
      },
    ],
  };

  return (
    <>
      <Paper
        sx={{
          borderRadius: "10px",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Top 10 Projects
          </Typography>
        </Box>
        <Box sx={{ pl: 2 }}>
          <Chart
            options={chartInfo.options}
            series={chartInfo.series}
            type="bar"
            width="100%"
            height={500}
          />
        </Box>
      </Paper>
    </>
  );
};

export default ProjectChart;
