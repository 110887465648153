import { useState, useCallback, useEffect } from "react";

import { useQuery, useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";
import { isAfter } from "date-fns";

import {
  getAllPendingTimesheetEntries,
  submitAllStatusPendingTimesheetEntryAction,
} from "../../../../api/allPendingTimesheetEntries";
import {
  getComparator,
  getStartEndDateOfWeek,
} from "../../../../helper/SettingsTimesheet/AllPendingTimesheetEntries";
import {
  ORDER,
  HEADER_CELL_ID,
  ALL_PENDING_ENTRIES_QUERY_KEY,
} from "../../../../lib/settingsTimesheet/allPendingTimesheetEntries";

export const useAllPendingTimesheetEntries = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchString, setSearchString] = useState("");
  const [order, setOrder] = useState(ORDER.DESCENDING);
  const [orderBy, setOrderBy] = useState(HEADER_CELL_ID.DATE);
  const [status, setStatus] = useState(false);
  const [fromDate, setFromDate] = useState(getStartEndDateOfWeek().startOfWeek);
  const [toDate, setToDate] = useState(getStartEndDateOfWeek().endOfWeek);

  useEffect(() => {
    setPage(1);
  }, [searchString, pageSize, status, fromDate, toDate]);

  const sortFn = useCallback(
    (data) => {
      const sortedData = [...data.data].sort(getComparator(order, orderBy));
      return {
        ...data,
        data: sortedData,
      };
    },
    [order, orderBy]
  );

  const allPendingEntriesQuery = useQuery(
    [
      ALL_PENDING_ENTRIES_QUERY_KEY,
      page,
      pageSize,
      searchString,
      status,
      fromDate,
      toDate,
    ],
    getAllPendingTimesheetEntries.bind(
      null,
      page,
      pageSize,
      searchString,
      status,
      fromDate,
      toDate
    ),
    {
      select: sortFn,
    }
  );

  const changePage = (newPage) => {
    setPage(newPage);
  };

  const changePageSize = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const searchFilter = useCallback((filterString) => {
    setSearchString(filterString);
  }, []);

  const changeOrder = (newOrder) => {
    setOrder(newOrder);
  };

  const changeOrderBy = (newOrderBy) => {
    setOrderBy(newOrderBy);
  };

  const changeStatus = () => {
    setStatus((prev) => !prev);
  };

  const changeFromDate = (newDate) => {
    if (isAfter(newDate, toDate)) setToDate(newDate);
    setFromDate(newDate);
  };

  const changeToDate = (newDate) => {
    if (isAfter(fromDate, newDate)) setFromDate(newDate);
    setToDate(newDate);
  };

  return {
    ...allPendingEntriesQuery,
    order,
    orderBy,
    page,
    pageSize,
    searchString,
    status,
    fromDate,
    toDate,
    changePage,
    changePageSize,
    searchFilter,
    changeOrder,
    changeOrderBy,
    changeStatus,
    changeFromDate,
    changeToDate,
    query: allPendingEntriesQuery,
    state: {
      order,
      orderBy,
      page,
      pageSize,
      searchString,
      status,
      fromDate,
      toDate,
    },
    handler: {
      changePage,
      changePageSize,
      searchFilter,
      changeOrder,
      changeOrderBy,
      changeStatus,
      changeFromDate,
      changeToDate,
    },
  };
};

export const usePrefetchAllPendingTimesheetEntries = async (
  hasNextPage,
  page,
  pageSize,
  searchString,
  status,
  fromDate,
  toDate
) => {
  const queryClient = useQueryClient();

  if (!hasNextPage) return;

  const nextPage = page + 1;

  queryClient.prefetchQuery(
    [
      ALL_PENDING_ENTRIES_QUERY_KEY,
      nextPage,
      pageSize,
      searchString,
      status,
      fromDate,
      toDate,
    ],
    getAllPendingTimesheetEntries.bind(
      null,
      nextPage,
      pageSize,
      searchString,
      status,
      fromDate,
      toDate
    ),
    {
      staleTime: 5000,
    }
  );
};

export const useAllPendingTimesheetEntriesAction = () => {
  const queryClient = useQueryClient();

  const allPendingEntriesAction = useMutation(
    submitAllStatusPendingTimesheetEntryAction,
    {
      onSuccess: (data) => {
        const {
          data: { message },
        } = data;
        toast.success(message);
        queryClient.resetQueries(ALL_PENDING_ENTRIES_QUERY_KEY);
      },
      onError: () => {
        toast.error("Error submitting action");
      },
    }
  );

  return allPendingEntriesAction;
};
