import * as React from "react";
import {
  Grid,
  Typography,
  Avatar,
  IconButton,
  Divider,
  Chip,
  Card,
  CardContent,
  CardHeader,
  Box,
  Button,
  CardActions,
} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import ChatBubbleRoundedIcon from "@mui/icons-material/ChatBubbleRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import LaptopMacOutlinedIcon from "@mui/icons-material/LaptopMacOutlined";
import LinearProgressWithLabel from "./LinearProgress";
import ReactionMenu from "./reactionMenu";
import Skills from "./skills";
import SkillHeader from "./skillHeader";
import ReactionCount from "./reactionCount";
import SkillPopper from "./skillPopper";
const { format, parseISO } = require("date-fns");
const ColleagueCard = React.forwardRef(
  (
    {
      colleague,
      expand,
      expandGrid,
      expandItem1,
      expandItem2,
      skillSet,
      submitReaction,
      page,
      inOutFilter,
      handleOpen,
      tiles,
    },
    ref
  ) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleOnChange = (event) => {
      anchorEl === null ? setAnchorEl(event.currentTarget) : setAnchorEl(null);
    };
    const [alignment, setAlignment] = React.useState("linear");

    const handleChange = (event, newAlignment) => {
      setAlignment(newAlignment);
    };

    const sendToGmail = (email) => {
      let url = "https://mail.google.com/mail/?view=cm&fs=1&to=" + email;
      let win = window.open(url, "_blank");
      win.focus();
    };
    const empId = colleague.id;
    const timeIn =
      inOutFilter === "out"
        ? colleague.time_out?.time
        : colleague.time_in?.time;
    const timeInGps =
      inOutFilter === "out"
        ? colleague.time_out?.gps_address
        : colleague.time_in?.gps_address;
    const timeInAvatar =
      inOutFilter === "out"
        ? colleague.time_out?.custom_photo_path
        : colleague.time_in?.custom_photo_path;
    const timeInPlatform = colleague.time_in?.platform;

    const bgcolorMatch =
      inOutFilter === "out"
        ? colleague?.time_out?.time_status === "undertime"
          ? "#FFF4E5 !important"
          : ""
        : colleague?.time_in?.time_status === "late"
        ? "#FDF3F5 !important"
        : colleague?.time_in?.time_status === "grace"
        ? "#FCF4E5 !important"
        : "";

    const colorMatch =
      inOutFilter === "out"
        ? colleague?.time_out?.time_status === "undertime"
          ? "#FEC90E !important"
          : ""
        : colleague?.time_in?.time_status === "late"
        ? "#EE6A6C !important"
        : colleague?.time_in?.time_status === "grace"
        ? "#F2C910 !important"
        : "";
    const cardBody = (
      <Card
        sx={{
          borderRadius: "10px",
          overflow: "unset",
          position: "relative",
          minHeight: "220px",
        }}
      >
        <Chip
          size="small"
          label={
            <Typography
              variant="overline"
              sx={{
                fontSize: "0.7rem",
                color: "#444",
                padding: "0px 5px",
              }}
            >
              {colleague.department?.name}
            </Typography>
          }
          sx={{
            marginTop: "-12px",
            display: "flex",
            width: "fit-content",
            position: "absolute",
            left: "0px",
            right: "0px",
            ml: "auto",
            mr: "auto",
            backgroundColor: colleague.department?.color,
          }}
        />
        {!expand ? (
          <CardHeader
            title={
              <Typography
                variant="caption"
                sx={{
                  fontSize: "0.87rem",
                  fontWeight: "500",
                }}
              >
                {colleague?.full_name}
              </Typography>
            }
            sx={{ textAlign: "center", pt: "35px" }}
            action={
              colleague.employee_skill.length > 0 && (
                <SkillPopper skillSet={colleague.employee_skill} />
              )
            }
          />
        ) : (
          <SkillHeader colleague={colleague} timeInAvatar={timeInAvatar} />
        )}

        <Box sx={{ position: "relative" }}>
          {expand && (
            <CardContent
              sx={{
                padding: "0px 16px 0px 16px",
                paddingBottom: "0px !important",
              }}
            >
              <Box>
                <Grid container>
                  <Grid item xs={expandItem2}>
                    <Divider sx={{ paddingTop: "5px" }} />
                  </Grid>
                  <Grid item xs={expandItem1} className="modal-detail">
                    <Typography variant="h6" className="card-typ">
                      Skills
                    </Typography>
                  </Grid>
                  <Grid item xs={expandItem2}>
                    <Divider sx={{ paddingTop: "5px" }} />
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  m: "6px 0px 10px",
                }}
              >
                {colleague.employee_skill.length > 0 ? (
                  <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    size="small"
                  >
                    <ToggleButton
                      value="linear"
                      sx={{ fontSize: "0.6rem", textTransform: "none" }}
                    >
                      Linear
                    </ToggleButton>
                    <ToggleButton
                      value="circular"
                      sx={{ fontSize: "0.6rem", textTransform: "none" }}
                    >
                      Circular
                    </ToggleButton>
                  </ToggleButtonGroup>
                ) : (
                  "N/A"
                )}
              </Box>
              {alignment === "linear" ? (
                colleague.employee_skill.map((es, index) => (
                  <LinearProgressWithLabel
                    key={index}
                    value={es.value * 10}
                    text={es.has_skill?.name}
                    bg={es.has_skill?.color}
                    bgl={`${es.has_skill?.color}99`}
                  />
                ))
              ) : (
                <Skills skillSet={colleague.employee_skill} />
              )}
            </CardContent>
          )}
          {!expand && (
            <Box
              sx={{
                position: "absolute",
                top: "-11px",
                left: "20px",
              }}
            >
              <IconButton onClick={handleOnChange}>
                <Avatar
                  src={timeInAvatar}
                  sx={{
                    width: 85,
                    height: 85,
                  }}
                />
              </IconButton>
              <ReactionMenu
                anchorEl={anchorEl}
                handleOnChange={handleOnChange}
                submitReaction={submitReaction}
                empId={empId}
                page={page}
                handleOpen={handleOpen}
              />
            </Box>
          )}
        </Box>
        <CardContent sx={{ pt: "0px" }}>
          <Grid container>
            <Grid item xs={expandGrid}></Grid>
            <Grid item xs={expandGrid}>
              <Box>
                <Box
                  sx={{
                    margin: expand ? "15px 0px 7px" : "10px 0px 7px",
                  }}
                >
                  <Grid container>
                    <Grid item xs={expandItem2}>
                      <Divider sx={{ paddingTop: "5px" }} />
                    </Grid>
                    <Grid item xs={expandItem1} className="modal-detail">
                      <Typography variant="h6" className="card-typ">
                        {expand
                          ? "Details"
                          : inOutFilter === "out"
                          ? "Time out "
                          : " Time in"}
                      </Typography>
                    </Grid>
                    <Grid item xs={expandItem2}>
                      <Divider sx={{ paddingTop: "5px" }} />
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: expand ? "" : "center",
                  }}
                >
                  {expand && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontSize: "0.75rem",
                      }}
                    >
                      Time-in:
                    </Typography>
                  )}
                  <IconButton
                    className="in"
                    size="small"
                    sx={{
                      margin: "0px 25px 5px",
                      paddingLeft: "10px",
                      paddingRight: "9px",
                      bgcolor: bgcolorMatch,
                      color: colorMatch,
                    }}
                  >
                    {expand &&
                      timeInPlatform &&
                      (timeInPlatform === "web" ? (
                        <LaptopMacOutlinedIcon
                          fontSize="small"
                          sx={{ mr: "7px" }}
                        />
                      ) : (
                        <PhoneIphoneOutlinedIcon
                          fontSize="small"
                          sx={{ mr: "7px" }}
                        />
                      ))}
                    <Typography
                      variant="h3"
                      className="card-typ0"
                      sx={{
                        fontSize: "0.75rem !important",
                        color: colorMatch,
                      }}
                    >
                      {timeIn ? format(parseISO(timeIn), "hh : mm a") : "N/A"}
                    </Typography>
                  </IconButton>
                </Box>
                {expand && (
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontSize: "0.75rem",
                      }}
                    >
                      Location:
                    </Typography>
                    <Typography
                      variant="h6"
                      className="card-typ"
                      sx={{
                        width: "fit-content",
                        margin: "6px 0px 10px 18px",
                        overflowY: "scroll",
                        height: "35px",
                      }}
                    >
                      {timeInGps ? timeInGps : "N/A"}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <ReactionCount
                expand={expand}
                colleague={colleague}
                submitReaction={submitReaction}
                page={page}
                empId={empId}
                handleOpen={handleOpen}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: "center", pb: "15px" }}>
          {expand && (
            <>
              <Button disableElevation size="small" sx={{ color: "#00C292" }}>
                <ChatBubbleRoundedIcon />
              </Button>
              <Button
                disableElevation
                size="small"
                sx={{ color: "#00C292" }}
                onClick={(e) => sendToGmail(colleague.email_work)}
              >
                <EmailRoundedIcon />
              </Button>
            </>
          )}
        </CardActions>
      </Card>
    );

    const content = ref ? (
      <Grid ref={ref} item xs={12} sm={6} md={tiles}>
        {cardBody}
      </Grid>
    ) : (
      <Grid item xs={12} sm={6} md={tiles}>
        {cardBody}
      </Grid>
    );

    return content;
  }
);

export default ColleagueCard;
