import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import TimesheetWeeklyReportTableCell from "./TimesheetWeeklyReportTableCell";
import { getSkipStartEnd } from "../../../../helper/Evaluation/TimesheetWeeklyReport";

const TableHeader = ({ skip, limit, maxLimit, remainder }) => {
  const { start } = getSkipStartEnd(skip, limit);

  const length =
    skip + 1 === maxLimit && limit > 2 && !!remainder ? remainder : limit;

  const weekendsArr = Array.from({ length }, (_, i) => start + i + 1);

  return (
    <TableHead>
      <TableRow>
        <TimesheetWeeklyReportTableCell>Year</TimesheetWeeklyReportTableCell>

        <TimesheetWeeklyReportTableCell>Average</TimesheetWeeklyReportTableCell>

        {weekendsArr.map((element) => (
          <TimesheetWeeklyReportTableCell key={element}>
            Week {element}
          </TimesheetWeeklyReportTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
