import apiService from "../../axios";

export const getLeaveConversionApplications = async (
  page,
  pageSize,
  search = ""
) => {
  const endpoint = `v5/leave-conversion/get-applications?page=${page}&page_size=${pageSize}&search=${search}`;

  try {
    const { data } = await apiService.get(endpoint);

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// ? payload: { id, conversion }
export const approveLeaveConversionApplication = async (payload) => {
  const endpoint = "v5/leave-conversion/approve-conversion-request";

  try {
    const response = await apiService.post(endpoint, payload);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// ? payload: { id, conversion }
export const declineLeaveConversionApplication = async (payload) => {
  const endpoint = "v5/leave-conversion/disapprove-conversion-request";

  try {
    const response = await apiService.post(endpoint, payload);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
