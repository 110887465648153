import { CardMedia } from "@mui/material";
import React from "react";
import { paddedString } from "../../helper/Dashboard";

const LeaveImg = ({ holidayNameArray }) => {
  const checkEvent = (holidayArray) => {
    const eventMap = {
      AUTUMN: [null, "FFF7E9"],
      // add more cases here
    };
    return eventMap[holidayArray] || [null, "FFF7E9"];
  };

  const returnedEvent = checkEvent(holidayNameArray?.[1]);
  return (
    <CardMedia
      component="img"
      height="194"
      style={{
        backgroundImage: `url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='750' height='500' viewBox='0 0 750 500'%3E%3Cdefs%3E%3CclipPath id='clip-Artboard_1'%3E%3Crect width='750' height='500'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Artboard_1' data-name='Artboard 1' clip-path='url(%23clip-Artboard_1)'%3E%3Crect width='750' height='500' fill='%23fff'/%3E%3Cg id='BACKGROUND'%3E%3Crect id='Rectangle_1' data-name='Rectangle 1' width='750' height='500' fill='%23${
          returnedEvent?.[1]
        }'/%3E%3C/g%3E%3Cg id='OBJECTS'%3E${
          returnedEvent?.[0]
        }%3Cg id='Group_32' data-name='Group 32' transform='translate(77.931 113)'%3E%3Cg id='Group_49' data-name='Group 49'%3E%3Cg id='Group_47' data-name='Group 47'%3E%3Ctext id='Employee_Appreciation' data-name='Employee Appreciation' transform='translate(-77.931 31.769)' fill='%23245472' font-size='56.982' font-family='SegoeUI, Segoe UI' letter-spacing='0.02em'%3E%3Ctspan x='50%' y='58' dominant-baseline='middle' text-anchor='middle'%3E${
          holidayNameArray?.[0] === undefined ? "" : holidayNameArray?.[0]
        }%3C/tspan%3E%3Ctspan x='50%' y='117.02' dominant-baseline='middle' text-anchor='middle'%3E${
          holidayNameArray?.[1] === undefined ? "" : holidayNameArray?.[1]
        }%3C/tspan%3E%3C/text%3E%3C/g%3E%3Cg id='Group_48' data-name='Group 48'%3E%3Ctext id='DAY' transform='translate(129.565 158.94)' fill='%23a73027' font-size='25.457' font-family='SegoeUI, Segoe UI' letter-spacing='0.34em'%3E%3Ctspan x='${paddedString(
          holidayNameArray?.[2],
          15.66,
          316.6
        )}' y='32'%3E${
          holidayNameArray[2] === undefined ? "" : holidayNameArray?.[2]
        }%3C/tspan%3E%3C/text%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
      }}
    />
  );
};

export default LeaveImg;
