import {
  Button,
  Table,
  TableContainer,
  Paper,
  Grid,
  Typography,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import BodyUI from "../../../component/AdminTables/BodyUI";
import HeaderUI from "../../../component/AdminTables/HeaderUI";
import SkeletonLoader from "../../../component/AdminTables/SkeletonLoader";
import ServerPagination from "../../../component/Pagination/ServerPagination";
import {
  HeaderContent,
  BodyContent,
} from "../../../helper/SettingsTimesheet/EmployeeToEmailTimesheetReport";
import { useGetReminders } from "../../../hooks/react-query/SettingsTimesheet/EmployeeToEmailTimesheetReport";
import Actions from "./Actions";
import AddModal from "./AddModal";

const initAddEmployeeState = {
  modal: false,
  employee: null,
  status: null,
};
const initPagination = {
  current_page: 1,
  per_page: 25,
};
const EmployeeToEmailTimesheetReport = () => {
  const [addEmployee, setAddEmployee] = useState(initAddEmployeeState);
  const [pagination, setPagination] = useState(initPagination);
  const { data: reminders, isFetching } = useGetReminders(pagination.per_page);
  const handleOpenModal = () => {
    setAddEmployee({ ...addEmployee, modal: true });
  };
  const handleClose = () => {
    setAddEmployee(initAddEmployeeState);
  };
  const startPage = () => {
    setPagination({ ...pagination, current_page: 1 });
  };
  const lastPage = () => {
    !isFetching &&
      setPagination({ ...pagination, current_page: reminders?.last_page });
  };
  const nextPage = () => {
    setPagination({ ...pagination, current_page: pagination.current_page + 1 });
  };
  const backPage = () => {
    setPagination({ ...pagination, current_page: pagination.current_page - 1 });
  };

  const presentEmployee = reminders?.data.map((data) => data.user_id);
  return (
    <Grid container sx={{ padding: "15px" }}>
      <Tooltip
        sx={{ cursor: "context-menu" }}
        title={"Manage employee to remind timesheet"}
        placement={"right"}
        arrow
      >
        <Typography variant="h5">
          List of employee to remind timesheet report
        </Typography>
      </Tooltip>
      <Paper style={{ width: "100%", padding: "20px", marginTop: "20px" }}>
        <Button onClick={handleOpenModal}>Add employee</Button>
        <TableContainer
          sx={{
            padding: "0px 0px 20px 0px",
            overflowX: "auto",
            maxHeight: "calc(100vh - 280px)",
          }}
        >
          <Table stickyHeader style={{ overflowX: "auto" }}>
            <HeaderUI headers={HeaderContent} />
            {!isFetching ? (
              <BodyUI
                cols={BodyContent}
                rows={reminders?.data}
                Action={Actions}
              />
            ) : (
              <SkeletonLoader colSpan={HeaderContent.length} />
            )}
          </Table>
        </TableContainer>
        {!isFetching && (
          <ServerPagination
            field={"per_page"}
            from={reminders?.from}
            to={reminders?.to}
            total={reminders?.total}
            pageData={{
              to: reminders?.to,
              from: reminders?.from,
              total: reminders?.total,
              perPage: reminders?.per_page,
            }}
            setPerPage={setPagination}
            startPage={startPage}
            lastPage={lastPage}
            nextPage={nextPage}
            backPage={backPage}
          />
        )}
      </Paper>
      <AddModal
        open={addEmployee.modal}
        handleClose={handleClose}
        excludeEmp={presentEmployee}
      />
    </Grid>
  );
};

export default EmployeeToEmailTimesheetReport;
