export const firstObj = { id: 0, shortname: "name", name: "User" };
export const lastObj = { id: "", shortname: "action", name: "Actions" };

export const editableFields = [
  // restrict some fields to be edited
  "Earned Vacation Leave",
  "Converted Vacation Leave",
];

export const addEdittoArray = (data) =>
  data?.map((v) => Object.assign(v, { action: "Edit" }));

//Year range filter
const nextYear = new Date().getFullYear() + 1;
const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
export const yearRange = range(nextYear, nextYear - 5, -1);

export const updateArray = (data, arrayToUpdate, ref_id) =>
  data.map(
    (obj) => [arrayToUpdate].find((o) => o[ref_id] === obj[ref_id]) || obj
  );
