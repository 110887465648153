import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const TableWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  backgroundColor: "#FFF",
  borderRadius: "12px",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.05)",
}));

export default TableWrapper;
