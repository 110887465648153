import React, { useContext, useState, useEffect } from "react";
import { TextField, Box, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useDebounce from "../../../hooks/customHooks/debounce";
import FilterHeader from "../../../component/LeaveConversionLogs/FilterHeader";
import CloseIcon from "@mui/icons-material/Close";

import { LeaveConversionLogsContext } from ".";

const LeaveConversionLogsFilter = () => {
  const { setPage, isFetching, search, setSearch } = useContext(
    LeaveConversionLogsContext
  );
  const [searchString, setSearchString] = useState(search);
  const debounceValue = useDebounce(searchString, 700);

  useEffect(() => {
    setPage(1);
    setSearch(debounceValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue]);

  return (
    <FilterHeader>
      <Box></Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        <TextField
          value={searchString}
          onChange={(e) => {
            if (isFetching) return;
            setSearchString(e.target.value);
          }}
          sx={{ margin: "5px" }}
          size="small"
          placeholder="Search"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CloseIcon
                  style={{ color: "#000000", cursor: "pointer" }}
                  onClick={() => setSearchString("")}
                />
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </FilterHeader>
  );
};

export default LeaveConversionLogsFilter;
