import { TextField, Grid } from "@mui/material";
import React from "react";

const UpSingle = ({ register, errors, ind, ttSingle }) => {
  const handleSingleInput = () => {
    if (ttSingle !== "") {
      return ttSingle[0].answer;
    } else {
      return "";
    }
  };

  register(`answerType${ind}`, { value: "single" });
  const single = `single${ind}`;
  return (
    <TextField
      variant="outlined"
      label="Enter answer here"
      defaultValue={handleSingleInput()}
      fullWidth
      size="small"
      {...register(single)}
      required
    />
  );
};

export default UpSingle;
