import * as React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

export const AlertComponent = ({ severity, message, handleOpenReaction }) => {
  // SEVERITY TYPES
  // *error
  // *success
  // *info
  // *warning
  return (
    <Stack sx={{ width: "fit-content", position: "sticky", bottom: 0 }}>
      <Alert severity={severity} onClose={handleOpenReaction}>
        {message}
      </Alert>
    </Stack>
  );
};
