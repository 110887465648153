import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Tooltip } from "@mui/material";

const UserApplicationsHeader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        cursor: "context-menu",
      }}
    >
      <Tooltip title={"Manage employees application"} placement={"right"} arrow>
        <Typography
          variant="h4"
          sx={{ fontSize: "clamp(24px, 5vw, 32px)", color: "#21293C" }}
        >
          User Application
        </Typography>
      </Tooltip>
    </Box>
  );
};

export default UserApplicationsHeader;
