import apiService from "../../axios";
export const GetEmployees = async (per_page) => {
  try {
    const request = await apiService.get(`/v5/employee/get-all-active`);
    return request.data;
  } catch (error) {
    throw error;
  }
};

export const GetReminders = async (per_page) => {
  try {
    const request = await apiService.get(
      `/v5/timesheet/reminders?per_page=${per_page}`
    );
    return request.data;
  } catch (error) {
    throw error;
  }
};

export const AddReminder = async (payload) => {
  try {
    const request = await apiService.post(
      "/v5/timesheet/reminders-add",
      payload,
      {
        Accept: "application/json",
        body: JSON.stringify(payload),
      }
    );
    return request;
  } catch (error) {
    throw error;
  }
};

export const RemoveReminder = async (payload) => {
  try {
    const request = await apiService.post(
      "/v5/timesheet/reminders-remove",
      payload,
      {
        Accept: "application/json",
        body: JSON.stringify(payload),
      }
    );
    return request;
  } catch (error) {
    throw error;
  }
};
