import React, { useCallback, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import Grow from "@mui/material/Grow";
import Camera from "./camera";
import TimeinChild from "./timeinChild";
import { format } from "date-fns";
import RequestAddress from "../../hooks/react-query/geolocation";
import hooks from "../../hooks/react-query/queryHooks";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useGeolocated } from "react-geolocated";

const TimeinModal = ({
  timeinout,
  handleTimeInOut,
  employee,
  tab,
  handleViewSurvey2,
  isCameraEnabled,
}) => {
  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
  });

  const locationObj = coords
    ? {
        lat: coords.latitude,
        lng: coords.longitude,
      }
    : null;
  const gpsPayload = { api: true, location: locationObj };
  const { data, isLoading } = RequestAddress(gpsPayload, coords);
  const { data: getPsychSurvey } = hooks.useGetSettings();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const webcamRef = useRef(null);
  const captureAnimation = useRef("20%");
  const [childOpen, setChildOpen] = useState(false);
  const [url, setUrl] = useState(null);
  const [timeinoutloader, setTimeinoutloader] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  const handleChildLoader = (data) => {
    setTimeinoutloader(data);
  };
  const handleChild = () => {
    childOpen ? setChildOpen(false) : setChildOpen(true);
  };
  const observeSpinner = () => {
    handleChild();
    const box = document.querySelector("#boxRef");
    const observer = new ResizeObserver((entries) => {
      const spinner = entries[0];
      const maxWidth = spinner.contentRect.width === 175;
      if (maxWidth) {
        capturePhoto();
        captureAnimation.current = "20%";
      }
    });
    observer.observe(box);
  };

  const handleCapture = () => {
    captureAnimation.current = "100%";
    observeSpinner();
  };

  const capturePhoto = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setUrl(imageSrc);
  }, [webcamRef]);

  const onUserMedia = (e) => {
    console.log(e);
  };

  const {
    data: TimeInOutData,
    mutate,
    reset,
  } = hooks.useUpdateTimeInOut(
    setChildOpen,
    handleTimeInOut,
    handleChildLoader,
    setUrl,
    setAlertOpen,
    handleViewSurvey2,
    getPsychSurvey
  );

  const resetTimeDataMutation = () => {
    setAlertOpen(false);
    reset();
    handleTimeInOut();
    navigate(`/timesheet`);
  };
  const navColleagues = () => {
    queryClient.invalidateQueries(["user-dashboard"]);
    navigate(`/colleagues`);
  };
  const navDashboard = () => {
    setChildOpen(false);
    handleChildLoader(false);
    setUrl(null);
    queryClient.invalidateQueries(["user-dashboard"]);
    handleTimeInOut();
  };
  const handleSubmitEntry = async () => {
    const location = JSON.stringify(gpsPayload.location);
    mutate({ action: tab, url: url, location: location });
  };

  return (
    <Modal open={timeinout}>
      <Grow
        in={timeinout}
        style={{ transformOrigin: "0 0 0" }}
        {...(timeinout ? { timeout: 500 } : {})}
        unmountOnExit
      >
        <Card className="login-modal">
          <CardHeader
            title={
              <Typography variant="h3" className="h3-bar">
                {tab === "in" ? "Time in" : "Time out"}
              </Typography>
            }
            action={
              <IconButton
                onClick={() => {
                  handleTimeInOut(null);
                }}
              >
                <CloseOutlinedIcon sx={{ width: "15px", height: "15px" }} />
              </IconButton>
            }
          />
          <CardContent
            sx={{
              pt: "0px",
            }}
          >
            <Grid container>
              <Grid item xs={12} lg={5}>
                <Box
                  sx={{
                    minWidth: "200px",
                    minHeight: "171px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: "20px",
                  }}
                >
                  <Box
                    color="text.secondary"
                    sx={{
                      position: "absolute",
                      zIndex: 1,
                    }}
                  >
                    <PhotoCameraOutlinedIcon style={{ fontSize: 100 }} />
                  </Box>

                  <Box className="box-drop">
                    <Box
                      id="boxRef"
                      className="box-ch"
                      sx={{
                        width: captureAnimation.current,
                      }}
                    ></Box>
                  </Box>

                  <Card className="card-webcam">
                    <Box
                      sx={{ transform: "scale(0.34)", zIndex: 2 }}
                      className="box-cam"
                    >
                      <Camera onUserMedia={onUserMedia} webcamRef={webcamRef} />
                    </Box>
                  </Card>
                </Box>
              </Grid>
              <Grid item xs={12} lg={7}>
                <Box sx={{ marginLeft: "35px" }}>
                  <Typography variant="body1">
                    Hi, {employee.employee.firstname}{" "}
                    {employee.employee.lastname}
                  </Typography>
                  <Box>
                    <Box
                      sx={{
                        margin: "20px 0px 7px",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={4}>
                          <Divider sx={{ paddingTop: "5px" }} />
                        </Grid>
                        <Grid item xs={4} className="modal-detail">
                          <Typography variant="h6" className="card-typ">
                            Details
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Divider sx={{ paddingTop: "5px" }} />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <Typography variant="body2" color="text.secondary">
                        Time :
                      </Typography>
                      <IconButton
                        className="in"
                        size="small"
                        sx={{
                          margin: "5px 35px 5px",
                          paddingLeft: "10px",
                          paddingRight: "9px",
                        }}
                      >
                        <Typography variant="h3" className="card-typ0">
                          {format(new Date(), "p")}
                        </Typography>
                      </IconButton>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <Typography variant="body2" color="text.secondary">
                        Location:
                      </Typography>
                      {coords === undefined && (
                        <Typography
                          variant="h6"
                          className="card-typ"
                          sx={{
                            width: "120px",
                            margin: "5px 14px 14px",
                            color: "red!important",
                          }}
                        >
                          Please allow location access.
                        </Typography>
                      )}
                      {data?.status === "success" && coords !== undefined && (
                        <Typography
                          variant="h6"
                          className="card-typ"
                          sx={{
                            width: "120px",
                            margin: "5px 14px 14px",
                          }}
                        >
                          {data.address}
                        </Typography>
                      )}
                      {data?.status === "fail" && (
                        <Typography
                          variant="h6"
                          className="card-typ"
                          sx={{
                            color: "red!important",
                            width: "120px",
                            margin: "5px 14px 14px",
                          }}
                        >
                          {data.address}
                        </Typography>
                      )}
                    </Box>
                    {!isCameraEnabled && (
                      <Box sx={{ display: "flex" }}>
                        <Typography variant="body2" color="text.secondary">
                          Camera:
                        </Typography>

                        <Typography
                          variant="h6"
                          className="card-typ"
                          sx={{
                            width: "120px",
                            margin: "5px 14px 14px",
                            color: "red!important",
                          }}
                        >
                          Please allow camera access.
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              className="timest-button adjust-w"
              startIcon={<PhotoCameraOutlinedIcon />}
              disableElevation
              variant="contained"
              sx={{ marginLeft: "auto", width: "50%" }}
              onClick={handleCapture}
              disabled={
                isLoading ||
                data?.status === "fail" ||
                !isCameraEnabled ||
                coords === undefined
              }
            >
              {tab === "in" ? "Time in" : "Time out"}
            </Button>
          </CardActions>

          {url && (
            <TimeinChild
              childOpen={childOpen}
              url={url}
              handleChild={handleChild}
              setUrl={setUrl}
              handleSubmitEntry={handleSubmitEntry}
              timeinoutloader={timeinoutloader}
              handleChildLoader={handleChildLoader}
              handleTimeInOut={handleTimeInOut}
              navColleagues={navColleagues}
              navDashboard={navDashboard}
            />
          )}
          {alertOpen && TimeInOutData && (
            <Stack
              sx={{
                position: "unset",
                zIndex: 1,
                width: "100%",
                bottom: "10px",
              }}
            >
              <Alert
                severity="info"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={resetTimeDataMutation}
                  >
                    ADD TIMESHEET
                  </Button>
                }
              >
                <AlertTitle>
                  {TimeInOutData?.data?.status?.charAt(0).toUpperCase() +
                    TimeInOutData?.data?.status?.slice(1)}
                </AlertTitle>
                Expected{" "}
                {parseFloat(TimeInOutData.data?.hours_required).toFixed(2)}{" "}
                hours! — <strong>current {TimeInOutData.data?.hours}!</strong>
              </Alert>
            </Stack>
          )}
        </Card>
      </Grow>
    </Modal>
  );
};

export default TimeinModal;
