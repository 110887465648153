import React, { useState, useContext } from "react";
import { Autocomplete, TextField, Box } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { getValidTimesheetAutoComplete } from "../../helper/timesheet";
import { useEffect } from "react";
import { TimesheetContext } from "../../globalstate/UserContext/timesheetcontext";
const TableAutocomplete = ({
  index,
  hour,
  color,
  bgcolor,
  onClick,
  select,
  disabled,
}) => {
  const { setIsEntry } = useContext(TimesheetContext);
  const [hours, setHours] = useState({ label: "0.00" });
  const [restrictedCode, setRestrictedCode] = useState();
  useEffect(() => {
    setHours({ label: "0.00" });
    setRestrictedCode();
    hour.forEach(
      (data) =>
        data &&
        (setHours({ label: parseFloat(data.hours).toFixed(2) }),
        setRestrictedCode(data.status))
    );
  }, [hour]);
  const handleChange = (value) => {
    select(value, index);
  };
  return (
    <Autocomplete
      onChange={(event, value, reason) => {
        handleChange(value.label);
        onClick();
      }}
      disableClearable
      popupIcon={
        <KeyboardArrowDownRoundedIcon sx={{ height: "15px", width: "15px" }} />
      }
      options={getValidTimesheetAutoComplete()}
      value={hours}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box style={{ fontSize: "0.7rem" }} {...props}>
          {option.label}
        </Box>
      )}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          onClick={() => {
            hours.label !== "0.00" && !disabled && onClick();
          }}
        />
      )}
      onClose={() => hours.label === "0.00" && setIsEntry(false)}
      sx={{
        "button.MuiAutocomplete-popupIndicator": {
          color: color,
        },
        "input.MuiAutocomplete-input": {
          color: color,
        },
        "& div.MuiAutocomplete-endAdornment": {
          right: "2px !important",
          top: "calc(50% - 9px)",
        },
        "& .MuiOutlinedInput-root": {
          fontSize: "0.7rem !important",
          padding: "6px 0px !important",
          bgcolor:
            restrictedCode === 2
              ? "#ff5252"
              : hours.label === "0.00"
              ? ""
              : "#00C292",
        },
        fontSize: "0.1rem",
      }}
    />
  );
};
export default TableAutocomplete;
