import React from "react";
import Pagination from "./Pagination";
import SearchComponent from "./SearchComponent";
import {
  Paper,
  Box,
  Table as MuiTable,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
} from "@mui/material";

const Table = ({
  reference,
  header,
  Row,
  limit = 10,
  setLimit,
  setOffset,
  offset = 1,
  setKeyword,
  keyword,
  total = 0,
  Buttons,
  isFetching = false,
  onPagination,
  tableOptions,
}) => {
  return (
    <>
      <Box>{tableOptions}</Box>

      <TableContainer sx={{ mt: 1.5 }} component={Paper}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          {Buttons &&
            Buttons.map((Button, index) => {
              return Button.Icon;
            })}
          <SearchComponent
            setKeyword={setKeyword}
            keyword={keyword}
            setOffset={setOffset}
            isFetching={isFetching}
            onPagination={(e) => {}}
          />
        </Box>
        <MuiTable ref={reference} size="small">
          <TableHead>
            <TableRow>
              {header?.map((column, index) => (
                <TableCell key={index} component="th">
                  <b>{column?.label}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{Row}</TableBody>
        </MuiTable>
      </TableContainer>
      <TableContainer component={Paper}>
        <MuiTable>
          <TableBody>
            <TableRow>
              <Pagination
                onPageChange={setOffset}
                limit={limit}
                offset={offset}
                total={total}
                onChange={setLimit}
                onPagination={(e) => {}}
              />
            </TableRow>
          </TableBody>
        </MuiTable>
      </TableContainer>
    </>
  );
};

export default Table;
