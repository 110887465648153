import React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const DatePickerComponent = ({ field, form, setForm, errors }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={field.label}
        value={form[field.payload] || ""}
        onChange={(e) => {
          setForm({
            ...form,
            [field.payload]: e,
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ mt: 1 }}
            fullWidth
            helperText={errors[field.helperText]}
            error={errors[field.helperText] && true}
          />
        )}
        className="datepicker"
        inputProps={{ readOnly: true }}
      />
    </LocalizationProvider>
  );
};

export default DatePickerComponent;
