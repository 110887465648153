/**
 * Hook
 * Get the saved theme color from local storage
 */
import { useState, useEffect } from "react";
/**
 * @returns appbarColorLocal, bgcolorPrimaryLocal, bgcolorSecondaryLocal
 */
export const useThemeColor = () => {
  const [appbarColorLocal, setAppbarLocal] = useState(null);
  const [bgcolorPrimaryLocal, setBgcolorPrimary] = useState(null);
  const [bgcolorSecondaryLocal, setBgcolorSecondary] = useState(null);

  useEffect(() => {
    window.addEventListener("storage", () => {
      const stored_data = JSON.parse(localStorage.getItem("company_settings"));
      setAppbarLocal(stored_data.bgcolor_appbar);
      setBgcolorPrimary(stored_data.bgcolor_primary);
      setBgcolorSecondary(stored_data.bgcolor_secondary);
    });
  }, []);

  return { appbarColorLocal, bgcolorPrimaryLocal, bgcolorSecondaryLocal };
};
