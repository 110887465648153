import apiService from "../../axios";
import { toast } from "react-toastify";

export const getUserListAPI = async () => {
  try {
    let response = await apiService.get(
      `/v5/user/get-users?page_size=${2000}&inActives=${false}`,
      {
        Accept: `application/json`,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const AddTimesheet = async (data) => {
  try {
    const request = await apiService.post(`v5/timesheet/add-entry`, data, {
      Accept: `application/json`,
      "Content-Type": `multipart/form-data`,
      body: JSON.stringify(data),
    });
    toast.success("Timesheet added successfully!");
    return request.data;
  } catch (error) {
    toast.error("Something went wrong, please try again");
    return error;
  }
};
