import React, { useState, useContext, useEffect, useCallback } from "react";
import {
  Collapse,
  IconButton,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { PerProjectActivityV5Context } from ".";
import { getReportPerCodeProjectPerTimesheet } from "../../../api/perProjectActivity";
import { TimesheetListTableHeader } from "../../../lib/reports/perProjectActivityV5";
import moment from "moment";
import Loader from "../../../component/Loader";
import { sortArrayTable } from "../../../helper/Reports";
import TimesheetList from "./TimesheetList";
import EmptyData from "../../../component/Reports/EmptyData";
import TableHeader from "../../../component/Reports/TableHeader";
import ReportTableContainter from "../../../component/Reports/TableContainter";

const StaffList = ({ item, codeId, activityId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [limit, setLimit] = useState(30);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [timesheetData, setTimesheetData] = useState([]);
  const { startDate, endDate, isWeekends, isInactiveUsers } = useContext(
    PerProjectActivityV5Context
  );
  const [orderDirection, setOrderDirection] = useState("desc");
  const [tableData, setTableData] = useState([]);

  const handleChangeRowsPerPage = (e) => {
    setPage(1);
    setLimit(e.target.value);
  };

  const handleChangePage = (page) => {
    setPage(page + 1);
  };

  const getTimesheetData = useCallback(async () => {
    if (isOpen) {
      setIsLoading(true);
      const response = await getReportPerCodeProjectPerTimesheet({
        fromDate: moment(startDate).format("YYYY-MM-DD"),
        toDate: moment(endDate).format("YYYY-MM-DD"),
        page_size: limit,
        id: codeId,
        id2: activityId,
        id3: item.time_employee.id,
        page: page,
        inActives: isInactiveUsers,
        weekends: isWeekends,
      });

      setTimesheetData(response);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page, item, isOpen]);

  const openCollapsable = async () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      await getTimesheetData();
      setIsOpen(true);
    }
  };

  useEffect(() => {
    getTimesheetData();
  }, [getTimesheetData]);

  useEffect(() => {
    if (timesheetData) {
      setTableData(timesheetData?.data || []);
    }
  }, [timesheetData]);

  const handleSortRequest = (key, parentKey) => {
    let direction = "asc";
    direction = orderDirection === "desc" ? "asc" : "desc";
    setOrderDirection(direction);
    setTableData((prev) => sortArrayTable(prev, direction, key, parentKey));
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <TableRow>
        <TableCell>
          <IconButton
            onClick={async () => {
              await openCollapsable();
            }}
          >
            {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.time_employee.full_name,
            }}
          />
        </TableCell>
        <TableCell align={"right"}>
          <div
            style={{ fontSize: "0.7rem" }}
            dangerouslySetInnerHTML={{
              __html: item.hrs,
            }}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} sx={{ background: "#dbe9f4" }}>
          <Collapse in={isOpen} unmountOnExit>
            {!timesheetData?.data?.length ? (
              <EmptyData />
            ) : (
              <ReportTableContainter>
                <TableHeader
                  isCollapsable={false}
                  headerList={TimesheetListTableHeader}
                  handleSortRequest={handleSortRequest}
                  orderDirection={orderDirection}
                />
                <TableBody>
                  {tableData?.map((codeItem, index) => {
                    return <TimesheetList key={index} item={codeItem} />;
                  })}
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[1, 5, 10, 30]}
                      count={timesheetData?.total || 0}
                      page={
                        timesheetData?.current_page
                          ? timesheetData?.current_page - 1
                          : 0
                      }
                      onPageChange={(e, page) => {
                        handleChangePage(page);
                      }}
                      rowsPerPage={limit}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      showFirstButton={true}
                      showLastButton={true}
                    />
                  </TableRow>
                </TableBody>
              </ReportTableContainter>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default StaffList;
