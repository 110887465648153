import { getISOWeek } from "date-fns";

export const generateYearsArray = (dateHired) => {
  const minYear = new Date(dateHired).getFullYear();
  const maxYear = new Date().getFullYear();

  const yearsArr = Array.from(
    { length: maxYear - minYear + 1 },
    (_, i) => minYear + i
  ).reverse();

  return yearsArr;
};

export const generateDefaultWeeklyReport = (maxHoursPerWeek) => {
  const weeklyReport = Array.from({ length: 52 }, (_, i) => ({
    week: i + 1,
    hoursForTheWeek: maxHoursPerWeek,
    maxHoursPerWeek,
    average: 100,
    isHired: true,
  }));

  return weeklyReport;
};

// TODO: Refactor
export const generateFullReport = (data, maxHoursPerWeek) => {
  const { years, report, dateHired, weekHired } = data;

  const yearHired = new Date(dateHired).getFullYear();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentWeek = getISOWeek(currentDate);

  const fullReport = {};

  years.forEach((year) => {
    const weeklyReport = generateDefaultWeeklyReport(maxHoursPerWeek);

    weeklyReport.forEach((week) => {
      if (year === yearHired && week.week <= weekHired) {
        week.hoursForTheWeek = 0;
        week.maxHoursPerWeek = 0;
        week.isHired = false;
        week.average = null;
        return;
      }

      if (year === currentYear && week.week >= currentWeek) {
        week.hoursForTheWeek = 0;
        week.maxHoursPerWeek = 0;
        week.average = null;
        return;
      }

      const incompleteWeek = report[year]?.find(
        (element) => +element.week === week.week
      );

      if (!incompleteWeek) return;

      week.hoursForTheWeek = incompleteWeek.hours;
      week.average = (week.hoursForTheWeek / week.maxHoursPerWeek) * 100;
    });

    const { totalHoursPerYear, totalIncompleteTime } = weeklyReport.reduce(
      (accumulator, current) => {
        const currentTotalHoursPerYear =
          accumulator.totalHoursPerYear + current.maxHoursPerWeek;

        const incompleteTime =
          current.maxHoursPerWeek - current.hoursForTheWeek;

        const currentTotalIncompleteTime =
          accumulator.totalIncompleteTime + incompleteTime;

        return {
          totalHoursPerYear: currentTotalHoursPerYear,
          totalIncompleteTime: currentTotalIncompleteTime,
        };
      },
      {
        totalHoursPerYear: 0,
        totalIncompleteTime: 0,
      }
    );

    let averageWorkedHoursPerYear =
      ((totalHoursPerYear - totalIncompleteTime) / totalHoursPerYear) * 100;

    if (totalHoursPerYear === 0 && totalIncompleteTime === 0)
      averageWorkedHoursPerYear = 100;

    fullReport[`${year}`] = {
      year,
      totalHoursPerYear,
      totalIncompleteTime,
      weeklyReport,
      averageWorkedHoursPerYear,
    };
  });

  return fullReport;
};

export const getSkipStartEnd = (skip, limit) => {
  const start = skip * limit;
  const end = start + limit;
  return {
    start,
    end,
  };
};

export const getStartEndWeek = (skip, limit, maxLimit, remainder) => {
  const { start: startSkip, end: endSkip } = getSkipStartEnd(skip, limit);

  const start = startSkip + 1;
  const end =
    skip + 1 === maxLimit && limit > 2 && !!remainder
      ? endSkip - (limit - remainder)
      : endSkip;

  return { start, end };
};
