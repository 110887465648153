import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";

const StyledTable = styled(Table)(() => ({
  tableLayout: "auto",
  minWidth: "max-content",
  width: "100%",
}));

export default StyledTable;
