import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  AddReminder,
  GetEmployees,
  GetReminders,
  RemoveReminder,
} from "../../../../api/settingsTimesheet/EmployeeToEmailTimesheetReport";

export const useGetEmployees = (per_page) => {
  return useQuery(["get-employee"], () => GetEmployees(per_page));
};

export const useGetReminders = (per_page) => {
  return useQuery(["get-reminders", per_page], () => GetReminders(per_page));
};

export const useAddReminder = () => {
  const queryClient = useQueryClient();
  return useMutation(AddReminder, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["get-reminders"]);
    },
  });
};

export const useRemoveReminder = () => {
  const queryClient = useQueryClient();
  return useMutation(RemoveReminder, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["get-reminders"]);
    },
  });
};
