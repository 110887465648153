import React from "react";
import "./ck.scss";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const CkEditor = ({ data, setData }) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={data ? data : ""}
      onChange={(_, editor) => {
        setData(editor.getData());
      }}
      config={{
        link: {
          decorators: {
            addTargetToExternalLinks: {
              mode: "automatic",
              callback: (url) => /^(https?:)?\/\//.test(url),
              attributes: {
                target: "_blank",
                rel: "noopener noreferrer",
              },
            },
          },
        },
        toolbar: [
          "undo",
          "redo",
          "bold",
          "italic",
          "heading",
          "link",
          "numberedList",
          "bulletedList",
          "insertTable",
          "tableColumn",
          "tableRow",
          "mergeTableCells",
        ],
      }}
    />
  );
};
export default CkEditor;
