import { useState, Fragment } from "react";

import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";

import StyledTableCell from "../../../UserApplicationMgmt/UserApplications/tableComponents/StyledTableCell";
import LongTextTableCell from "./LongTextTableCell";
import ActionModal from "../Modal/ActionModal";
import { ACTION_TYPE } from "../../../../lib/settingsTimesheet/PendingTimesheetEntries";

const PendingEntryTableRow = ({ data }) => {
  const {
    id,
    name,
    type,
    code,
    activity,
    code_status,
    code_limit,
    used,
    hours,
    details,
    formatted_date,
  } = data;

  const [showActionModal, setShowActionModal] = useState(false);
  const [action, setAction] = useState("");

  const closeModalHandler = () => {
    setShowActionModal(false);
  };

  const approveActionHandler = () => {
    setShowActionModal(true);
    setAction(ACTION_TYPE.APPROVE);
  };

  const denyActionHandler = () => {
    setShowActionModal(true);
    setAction(ACTION_TYPE.DENY);
  };

  const boxStyle = {
    cont: {
      textAlign: "left",
    },
    txt: {
      fontSize: "0.82rem",
    },
  };
  return (
    <Fragment>
      <TableRow hover>
        {/* Name */}
        <StyledTableCell>{name}</StyledTableCell>

        {/* Date */}
        <StyledTableCell align="center">
          <Box sx={boxStyle.cont}>
            <Box>
              <strong>Filed on {formatted_date}</strong>
            </Box>
            <Box sx={boxStyle.txt}>
              <strong>Details</strong>
            </Box>
            <Box sx={boxStyle.txt}>
              <strong>Type</strong> : {type}
            </Box>
            <Box sx={boxStyle.txt}>
              {" "}
              <strong>Activity</strong> : {activity}
            </Box>
          </Box>
        </StyledTableCell>
        {/* Type */}
        {/* <StyledTableCell align="center">{type}</StyledTableCell> */}

        {/* Code */}
        {/* <StyledTableCell align="center">{code}</StyledTableCell> */}

        {/* Activity */}
        {/* <StyledTableCell align="center">{activity}</StyledTableCell> */}

        {/* Project Code Status */}
        <StyledTableCell align="center">
          <Box sx={boxStyle.cont}>
            <Box>
              <strong>Code</strong> : {code}
            </Box>
            <Box sx={boxStyle.txt}>
              <strong>Status</strong> : {code_status}
            </Box>
            <Box sx={boxStyle.txt}>
              <strong>Monthly Limit</strong> : {code_limit}
            </Box>
            <Box sx={boxStyle.txt}>
              {" "}
              <strong>Used</strong> : {used}
            </Box>
          </Box>
        </StyledTableCell>

        {/* Project Code Monthly Limit */}
        {/* <StyledTableCell align="center">{code_limit}</StyledTableCell> */}

        {/* Currently Used */}
        {/* <StyledTableCell align="center">{used}</StyledTableCell> */}

        {/* Details */}
        <LongTextTableCell>{details}</LongTextTableCell>

        {/* Hours Rendered */}
        <StyledTableCell align="center">{hours}</StyledTableCell>

        {/* Actions */}
        <StyledTableCell align="center">
          <Stack
            direction="row"
            spacing={1}
            divider={<Divider orientation="vertical" flexItem />}
            justifyContent="center"
          >
            {/* APPROVE BUTTON */}
            <Tooltip title="Approve">
              <IconButton onClick={approveActionHandler}>
                <ThumbUpIcon
                  sx={{
                    color: "#099269ce",
                  }}
                />
              </IconButton>
            </Tooltip>

            {/* DENY BUTTON */}
            <Tooltip title="Deny">
              <IconButton onClick={denyActionHandler}>
                <ThumbDownIcon sx={{ color: "#fa5252ce" }} />
              </IconButton>
            </Tooltip>
          </Stack>
        </StyledTableCell>
      </TableRow>

      <ActionModal
        open={showActionModal}
        onClose={closeModalHandler}
        id={id}
        action={action}
      />
    </Fragment>
  );
};

export default PendingEntryTableRow;
