import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";

import TableHeader from "../table/TableHeader";

const TABLE_CONTAINER_STYLES = {
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  backgroundColor: "#FFF",
};

const TABLE_STYLES = {
  tableLayout: "auto",
  minWidth: "100%",
  width: "100%",
};

const TimesheetWeeklyReportTableContainer = ({
  children,
  skip,
  limit,
  maxLimit,
  remainder,
}) => {
  return (
    <TableContainer sx={TABLE_CONTAINER_STYLES}>
      <Table stickyHeader sx={TABLE_STYLES}>
        <TableHeader
          skip={skip}
          limit={limit}
          maxLimit={maxLimit}
          remainder={remainder}
        />

        <TableBody
          sx={{
            "& .MuiTableRow-root:last-child": {
              "& .MuiTableCell-root": {
                borderBottom: 0,
              },
            },
          }}
        >
          {children}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TimesheetWeeklyReportTableContainer;
