import React, { useContext, useState, useEffect } from "react";
import { TextField, InputAdornment, FormControlLabel } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import SearchIcon from "@mui/icons-material/Search";

import Checkbox from "@mui/material/Checkbox";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { ProjectCodeSummaryContext } from ".";
import useDebounce from "../../../hooks/customHooks/debounce";
import { isAfter, isBefore } from "date-fns";
import { toast } from "react-toastify";
import FilterHeader from "../../../component/Reports/FilterHeader";

const ProjectCodeSummaryFilter = () => {
  const {
    isInactive,
    setIsInactive,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    search,
    setSearch,
    isFetching,
    setPage,
  } = useContext(ProjectCodeSummaryContext);

  const [searchString, setSearchString] = useState(search);
  const debounceValue = useDebounce(searchString, 700);

  useEffect(() => {
    setPage(1);
    setSearch(debounceValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue]);

  return (
    <FilterHeader>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={startDate}
          label={"Start"}
          onChange={(newValue) => {
            if (isAfter(newValue, new Date(endDate))) {
              toast.error("Start date should not be after end date.");
              return;
            }
            setStartDate(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              sx={{
                margin: "5px",
              }}
            />
          )}
          className="datepicker"
          inputProps={{ readOnly: true }}
        />
        <DatePicker
          value={endDate}
          label={"End"}
          onChange={(newValue) => {
            if (isBefore(newValue, new Date(startDate))) {
              toast.error("End date should not be before start date.");
              return;
            }
            setEndDate(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              sx={{
                margin: "5px",
              }}
            />
          )}
          className="datepicker"
          inputProps={{ readOnly: true }}
        />
      </LocalizationProvider>

      <TextField
        value={searchString}
        onChange={(e) => {
          if (isFetching) return;
          setSearchString(e.target.value);
          // setSearch(e.target.value);
        }}
        sx={{ margin: "5px" }}
        size="small"
        placeholder="Search"
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              {" "}
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <FormControlLabel
        label="Show Inactive"
        control={
          <Checkbox
            value={isInactive}
            onChange={() => setIsInactive((prev) => !prev)}
          />
        }
      />
    </FilterHeader>
  );
};

export default ProjectCodeSummaryFilter;
